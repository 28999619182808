import React, { useEffect } from 'react'
import { getUserSelector } from '../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { useSelector } from 'react-redux';
import { DashBoardSS } from '../Children/DashboardFlowRouting/Carrier/DashBoardSS';
import { DashBoardSSWH } from '../Children/DashboardFlowRouting/Warehouse/DashBoardSSWH';

const DashboardWrapper = (props) => {
    const userPermission = useSelector(getUserSelector);
    const { userType} = userPermission;

  return (
      <>
          {
              userType === 'CARRIER' ? <DashBoardSS {...props} /> : <DashBoardSSWH {...props} />
      }
      </>
  )
}

export default DashboardWrapper