import _ from 'lodash';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DateSelectorReducer, { getSelectedDateSelector } from '../../../../../../../../../redux/reducers/reducerSlices/DateSelectorReducer';
import { getDefaultDashboardSelector } from '../../../../../../../../../redux/reducers/reducerSlices/DefaultDashboardReducer';
import { sagaActions } from '../../../../../../../../../sagas/sagaActions';
import { AppConstant } from '../../../../../../../../Assests/AppConstant';
import { modifyPODisplay } from '../../../../../../../../Assests/Utility';
import DrilldownTable from '../../Common/DrilldownTable';
import { getFormattedDate, YYYY_MM_DD_FORMAT } from '../../../../../../../../../Utils/DateUtils';
import { getUserSelector } from '../../../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { CustomPaginationSS } from '../../../../../../../Common/CustomPaginationSS/CustomPaginationSS';
import { getRemoteConfigSelector } from '../../../../../../../../../redux/reducers/reducerSlices/RemoteConfigReducer';

const DrillDownLoadStatusOverview = (props) => {
    const { themeSelected, loaderArray, subRoot, dockIdArr, secondSubRoot } = props;
    const dispatch = useDispatch();
    const [drilldownData, setDrilldownData] = useState([]);
    const defaultDashboard = useSelector(getDefaultDashboardSelector);
    const { dashboardLoadStatusDrillDownReport, arrangeOrder, arrangeKey, initiateDrillDownReport, selectedButtonStatusOverview, dateRangeObj } = defaultDashboard;
    const getSelectedDateSelectorObj = useSelector(getSelectedDateSelector);
    const { selectedDate } = getSelectedDateSelectorObj;
    const userPermission = useSelector(getUserSelector);
    const { selectedWarehouse } = userPermission;
    const remoteConfig = useSelector(getRemoteConfigSelector);
    const { allConfigration } = remoteConfig;
    const { PaginationDefaultLimit } = allConfigration

    const getHeader = () => {
        const { subPathName } = subRoot
        if (subPathName === "Not Arrived") {
            return [
                { id: 'PoNum', name: 'PO' },
                { id: 'CarrierName', name: 'Carrier' },
                { id: 'Vendorname', name: 'Vendor' },
                { id: 'Appointment', name: 'Appt Time' },
            ]
        } else if (subPathName === "Staging") {
            return [
                { id: 'PoNum', name: 'PO' },
                { id: 'CarrierName', name: 'Carrier' },
                { id: 'Vendorname', name: 'Vendor' },
                { id: 'Appointment', name: 'Appt Time' },
                { id: 'CheckedIn', name: 'Checked in' },
                { id: 'StagingDwell', name: 'Staging Dwl (mins)' },
            ]
        } else if (subPathName === "Docking") {
            return [
                { id: 'PoNum', name: 'PO' },
                { id: 'CarrierName', name: 'Carrier' },
                { id: 'Vendorname', name: 'Vendor' },
                { id: 'Appointment', name: 'Appt Time' },
                { id: 'CheckedIn', name: 'Checked in' },
                { id: 'DoorAssigned', name: 'Door Assigned' },
                { id: 'DockingDwell', name: 'Dock Dwl (mins)' },
            ]
        } else if (subPathName === "Unloading") {
            return [
                { id: 'PoNum', name: 'PO' },
                { id: 'CarrierName', name: 'Carrier' },
                { id: 'Vendorname', name: 'Vendor' },
                { id: 'Appointment', name: 'Appt Time' },
                { id: 'CheckedIn', name: 'Checked in' },
                { id: 'DoorAssigned', name: 'Door Assigned' },
                { id: 'UnloadingStart', name: 'Unloading Start' },
                { id: 'UnloadingDwell', name: 'Unloading Dwl (mins)' },
            ]
        } else if (subPathName === "Paperwork") {
            return [
                { id: 'PoNum', name: 'PO' },
                { id: 'CarrierName', name: 'Carrier' },
                { id: 'Vendorname', name: 'Vendor' },
                { id: 'Appointment', name: 'Appt Time' },
                { id: 'CheckedIn', name: 'Checked in' },
                { id: 'DoorAssigned', name: 'Door Assigned' },
                { id: 'UnloadingStart', name: 'Unloading Start' },
                { id: 'UnloadingEnd', name: 'Unloading End' },
                { id: 'PaperWorkDwell', name: 'Paperwork Dwl (mins)' }
            ]
        } else {
            return [
                { id: 'PoNum', name: 'PO' },
                { id: 'CarrierName', name: 'Carrier' },
                { id: 'Vendorname', name: 'Vendor' },
                { id: 'Appointment', name: 'Appt Time' },
                { id: 'CheckedIn', name: 'Checked in' },
                { id: 'DoorAssigned', name: 'Door Assigned' },
                { id: 'UnloadingStart', name: 'Unloading Start' },
                { id: 'UnloadingEnd', name: 'Unloading End' },
                { id: 'Released', name: 'Released' },
                { id: 'StagingDwell', name: 'Staging Dwl (mins)' },
                { id: 'DockingDwell', name: 'Dock Dwl (mins)' },
                { id: 'UnloadingDwell', name: 'Unloading Dwl (mins)' },
                { id: 'PaperWorkDwell', name: 'Paperwork Dwl (mins)' }
            ]
        }

    }

    let unloadingHoursTitleDD = getHeader()

    const createPayload = (loadStr, pageNo=1,limit=PaginationDefaultLimit) => {
            return {
                "dock_id": dockIdArr.toString() === '' ? null : dockIdArr.toString(),
                "warehouse_id": selectedWarehouse.ID,
                "date": getFormattedDate(YYYY_MM_DD_FORMAT, selectedDate),
                "load_type": loadStr,
                "isDrillDown": "1",
                "drillDownType": secondSubRoot,
                pageNo,
                limit
            }      
    }
    const hitAPI = (loadStr, pageNo=1,limit=PaginationDefaultLimit) => {
        dispatch({ type: sagaActions.INITIATE_DRILLDOWN_REPORT, payload: true })
        dispatch({
            type: sagaActions.GET_DASHBOARD_STATUS_DRILLDOWN, payload: createPayload(loadStr, pageNo, limit)
        })
    }

    useEffect(() => {
        hitAPI(selectedButtonStatusOverview)
    }, [dateRangeObj])

    useEffect(() => {
        hitAPI(selectedButtonStatusOverview)
        dispatch({ type: sagaActions.INITIATE_DRILLDOWN_REPORT, payload: true })

    }, [])

    useEffect(() => {
        setDrilldownData([])
        if (!_.isEmpty(dashboardLoadStatusDrillDownReport) && dashboardLoadStatusDrillDownReport?.data?.length) {

            let data = formatDrillDownData(dashboardLoadStatusDrillDownReport?.data)
            setTimeout(() => {
                setDrilldownData(data)
            }, 10)
        }
    }, [dashboardLoadStatusDrillDownReport])

    const getBody = (splitArray, el) => {
        const { subPathName } = subRoot
        if (subPathName === "Not Arrived") {
            return {
                0: modifyPODisplay(splitArray) || '-',
                1: el.CarrierName || '-',
                2: el.Vendorname || '-',
                3: el.Appointment || '-'
            }

        } else if (subPathName === "Staging") {
            return {
                0: modifyPODisplay(splitArray) || '-',
                1: el.CarrierName || '-',
                2: el.Vendorname || '-',
                3: el.Appointment || '-',
                4: el.CheckedIn || '-',
                5: el.StagingDwell ? el.StagingDwell : '-',
            }

        } else if (subPathName === "Docking") {
            return {
                0: modifyPODisplay(splitArray) || '-',
                1: el.CarrierName || '-',
                2: el.Vendorname || '-',
                3: el.Appointment || '-',
                4: el.CheckedIn || '-',
                5: el.DoorAssigned || '-',
                6: el.DockingDwell ? el.DockingDwell : '-',
            }

        } else if (subPathName === "Unloading") {
            return {
                0: modifyPODisplay(splitArray) || '-',
                1: el.CarrierName || '-',
                2: el.Vendorname || '-',
                3: el.Appointment || '-',
                4: el.CheckedIn || '-',
                5: el.DoorAssigned || '-',
                6: el.UnloadingStart || '-',
                7: el.UnloadingDwell ? el.UnloadingDwell : '-',
            }

        } else if (subPathName === "Paperwork") {
            return {
                0: modifyPODisplay(splitArray) || '-',
                1: el.CarrierName || '-',
                2: el.Vendorname || '-',
                3: el.Appointment || '-',
                4: el.CheckedIn || '-',
                5: el.DoorAssigned || '-',
                6: el.UnloadingStart || '-',
                7: el.UnloadingEnd || '-',
                8: el.PaperWorkDwell ? el.PaperWorkDwell : '-',
            }

        } else {
            return {
                0: modifyPODisplay(splitArray) || '-',
                1: el.CarrierName || '-',
                2: el.Vendorname || '-',
                3: el.Appointment || '-',
                4: el.CheckedIn || '-',
                5: el.DoorAssigned || '-',
                6: el.UnloadingStart || '-',
                7: el.UnloadingEnd || '-',
                8: el.Released || '-',
                9: el.StagingDwell ? el.StagingDwell : '-',
                10: el.DockingDwell ? el.DockingDwell : '-',
                11: el.UnloadingDwell ? el.UnloadingDwell : '-',
                12: el.PaperWorkDwell ? el.PaperWorkDwell : '-',

            }
        }
    }

    const formatDrillDownData = (data) => {
        return data.map(el => {
            let splitArray = []
            if (el.PoNum) {
                splitArray = el.PoNum.split(',')
            }
            return getBody(splitArray, el)
        })
    }

    const toggleLoadsData = (loadStr) => {
        if (loadStr !== selectedButtonStatusOverview) {
            dispatch({
                type: sagaActions.UPDATE_SELECTED_BUTTON, payload: {
                    key: 'selectedButtonStatusOverview',
                    loadStr: loadStr
                }
            })
            createBtn()
            dispatch({ type: sagaActions.INITIATE_DRILLDOWN_REPORT, payload: true })
            hitAPI(loadStr)
        }
    }
    const createBtn = () => {
        return <>
            <button type="button" className={`buttonFunnelDash ${selectedButtonStatusOverview === 'all' ? 'selectedButton' : 'unselectedButton'}  me-2`} onClick={() => toggleLoadsData('all')}>{AppConstant.dashboard.defaultdashboard.funnelGraph.sectionTitle.allLoadsButtonTitle}</button>
            <button type="button" className={`buttonFunnelDash ${selectedButtonStatusOverview === 'late' ? 'selectedButton' : 'unselectedButton'} me-2`} onClick={() => toggleLoadsData('late')}>{AppConstant.dashboard.defaultdashboard.funnelGraph.sectionTitle.lateLoadsButtonTitle}</button>
        </>
    }

    const pageChange = (pageNo, limit) => {
        hitAPI(selectedButtonStatusOverview, pageNo, limit)
    }

    return (
        <>
            <div className='d-flex justify-content-start my-1'>
                {createBtn()}
            </div>
            <DrilldownTable sortKey={true} showUTCTime={true} themeSelected={themeSelected} bodyData={drilldownData} titleData={unloadingHoursTitleDD} showCollapse={false} showInput={true} showSort={true} arrangeOrder={arrangeOrder} arrangeKey={arrangeKey} drillDownReport={dashboardLoadStatusDrillDownReport?.data} initiateDrillDownReport={initiateDrillDownReport} loaderArray={unloadingHoursTitleDD.length} subRoot={subRoot} sortingAction={sagaActions.SORT_DASHBOARD_STATUS_DRILLDOWN} />
            {!_.isEmpty(dashboardLoadStatusDrillDownReport) && dashboardLoadStatusDrillDownReport?.data?.length ?
                <div className='f-14 detention-status-pagination'>
                    <CustomPaginationSS
                        pageNo={dashboardLoadStatusDrillDownReport.pageNo}
                        pageSize={dashboardLoadStatusDrillDownReport.limit}
                        first={dashboardLoadStatusDrillDownReport.first}
                        last={dashboardLoadStatusDrillDownReport.last}
                        totalElements={dashboardLoadStatusDrillDownReport.totalElements}
                        totalPages={dashboardLoadStatusDrillDownReport.totalPages}
                        pageChange={(pageNo, limit) => { pageChange(pageNo, limit) }}
                    />
                </div> : ''
            }

        </>
    );
}

export default DrillDownLoadStatusOverview;