import React, { useDebugValue, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sagaActions } from "../../../../../sagas/sagaActions";
import { getSuperAdminSelector } from '../../../../../redux/reducers/reducerSlices/SuperAdminReducerSlice';
import { AppConstant } from "../../../../Assests/AppConstant";
import DrilldownTable from '../../../LandingPage/Components/Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTable';
import AdjustableTextComponent from "../../../Common/AdjustableTextComponent/AdjustableTextComponent";
import Popupold from '../../../Common/Popup/Popupold';
import { dateAndTimeUTCFormat, dateAndTimeLocal } from "../../../../Assests/Utility";
import { initiateSearch } from '../../../../Assests/Utility';
import moment from "moment";

/**
 * This components render Help and feedback table in list view
 * @param {*} props 
 * @returns 
 */
const HelpAndFeedbackUserTable = (props) => {

    const { currentSection, themeSelected, searchStr, feedbacksList, issuesList,isDataLoading } = props;
    const dispatch = useDispatch();
    const superAdminSagaObj = useSelector(getSuperAdminSelector);
    const [currentArr, setCurrentArr] = useState([])
    const { arrangeOrder, arrangeKey, requestsReceivedDropdownsList } = superAdminSagaObj;
    const [columnTitle, setColumnTitle] = useState({});
    const [showAllText, setShowAllText] = useState([]);
    const [requestIconsArr, setRequestIconsArr] = useState([]);
    const [carouselIndex, setCarouselIndex] = useState(0);
   
    /** useEffect for search component list data */
    useEffect(() => {
        if (searchStr.length > 3) {
            const searchedArr = initiateSearch(
                currentSection === 'Feedback' ? feedbacksList : issuesList,
                searchStr,
                currentSection === 'Feedback' ? 'feedBack' : 'issue')
            setCurrentArr(searchedArr);
        } else if (searchStr === '') {
            setCurrentArr(currentSection === 'Feedback' ? feedbacksList : issuesList)
        }
    }, [searchStr])

    /**
     * useEffect to set table column title
     */
    useEffect(() => {
        resetData();
     
      
        setTimeout(() => {
            setColumnTitle([
                { id: '', name: AppConstant.superUserAdmin.companyGroup.tableHeader.serialNum },
                { id: 'name', name: AppConstant.superUserAdmin.warehouseAdmin.warehouseTable.nameColTitle },
                { id: 'phoneNumber', name: AppConstant.superUserAdmin.companyGroup.tableHeader.contactNum },
                { id: 'email', name: AppConstant.superUserAdmin.companyGroup.tableHeader.email },
                { id: currentSection, name: currentSection },
                { id: 'createdOn', name: 'Created On' },
                { id: '', name: 'Images' },
                { id: '', name: 'Status' },
            ]
            )
        }, 50)

    }, [currentSection]);

 
    /**
     * Reset table data
     */
    const resetData = () => {
        // Reset table header title
        setColumnTitle([]);
        // Reset table heading texct
        setShowAllText('');
        // Reset table list data
        setCurrentArr([]);
    }
    /**
     * useEffect for issue list table data
     */
    useEffect(() => {
        if (currentSection === AppConstant.help.formName) {
            setIssuesList();
        }
    }, [issuesList])
    /**
     * function to set issue list to state variable currentArr
     */
    const setIssuesList = () => {
       setShowAllText('');
        setCurrentArr([]);
        if (issuesList && issuesList.length) {
            setCurrentArr(issuesList);
            const updatedArr = issuesList.map((reqObj, index) => {
                return ({ showExpanded: false, id: index })
            })
            setShowAllText(updatedArr)
        }
    }
     /**
     * useEffect for feedback list table data
     */
    
    useEffect(() => {
        if (currentSection === 'Feedback') {
            setFeedbacksList();
        }
    }, [feedbacksList])

    /**
     * function to set feedback list to state variable currentArr
     */
    const setFeedbacksList = () => {
        dispatch({ type: sagaActions.INITIATE_SUPER_ADMIN_LOADING, payload: false })
       setShowAllText('');
        setCurrentArr([]);
        if (feedbacksList && feedbacksList.length) {
            setCurrentArr(feedbacksList);
            const updatedArr = feedbacksList.map((reqObj, index) => {
                return ({ showExpanded: false, id: index })
            })
            setShowAllText(updatedArr)
        }
    }
    
    const handleSelect = (selectedIndex, e) => {
        setCarouselIndex(selectedIndex);
    };

    const getImageCarouselContent = () => {
        return (
            <div className="reqExpandedImages">
                <div id="carouselExampleFade" className={`carousel ${themeSelected === 'dark-mode' ? 'carousel-dark' : ''} slide`} data-bs-ride="carousel" activeIndex={carouselIndex} onSelect={handleSelect}>
                    <div className="carousel-inner">
                        {requestIconsArr.map((slide, i) => {
                            return (
                                <div className="carousel-item active">
                                    <img src={slide} className="d-block w-100" alt="" />
                                </div>

                            )
                        })}
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>

            </div>
        )
    }

    /**
     * 
     * @returns show Carousel popup for Image on full screen
     */
    const showImagesCarouselPopup = () => {

        const popupObj = {
            id: 'imageCarouselFormPopup1',
            modalLabel: 'imageCarouselFormPopup1',
            showClose: true,
            showfooter: false,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title: currentSection === AppConstant.help.formName ? 'Issues' : 'Feedback',
            bodyText: getImageCarouselContent(),
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: false,
                    text: AppConstant.commonStrings.close
                },
                btn2: {
                    show: false,
                    text: AppConstant.commonStrings.save
                }
            },
        }

        return <Popupold {...popupObj}
            popupBtnHandler={() => () => { }} closepopup={() => { }} themeSelected={themeSelected} />
    }

    const showIcons = (iconsArr, index) => {
        const icons = iconsArr && iconsArr.map((icon) => {
            return (
                <div className="d-flex justify-content-center"
                    onClick={() => setRequestIconsArr(iconsArr)}
                    data-bs-toggle="modal"
                    data-bs-target={`#imageCarouselFormPopup1`} >
                    <img className="requestIcons me-1 p-1 rounded-2" src={icon} alt='' />
                </div>
            )
        })

        return (<div className="d-flex justify-content-center ">
            {icons && icons.splice(0, 2)}
        </div>)
    }

    const expandAllText = (indexFaq, index) => {
        showAllText[index].showExpanded = !showAllText[index].showExpanded;
        setShowAllText([...showAllText])

    }

    /**
     * function for adjusting long text with reusable component AdjustableTextComponent
     * @param {*} text1 
     * @param {*} index 
     * @param {*} showAllButton 
     * @returns 
     */
    const adjustLongText = (text1, index, showAllButton) => {
        const text = text1;
        return (
            text && text.length ?
                <div className="adjustedText">
                    <AdjustableTextComponent
                        showAllButton={text.length > 100 && showAllButton ? true : false}
                        indexFaq={index} className={'adjustedText'}
                        text={text} wordlLimit={100} showAll={showAllText}
                        onClickHandler={(indexFaq) => expandAllText(indexFaq, index)} />
                </div>
                :
                <div />
        )

    }
    /**
     * function to adjust list data  data/time
     * @param {*} timeStr 
     * @returns 
     */
    const getLocalTime = (timeStr) => {
        let m = moment.tz(timeStr, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]', "America/Denver");
        m.tz(moment.tz.guess()).format();
        return dateAndTimeLocal(m.toString())
    }
    /**
     * format drilldown list data to render in table
     * Reusable component DrilldownTable needs data always in this format
     * @param {*} data 
     * @returns 
     */
    const formatListDataDrillDown = (data) => {
        return data.map((el, index) => {
            return {
                id: el.RoleID,
                0: index + 1,
                1: el.name,
                2: el.phoneNumber,
                3: el.email,
                4: (currentSection === AppConstant.help.formName ? adjustLongText(el.issue, index, true) : adjustLongText(el.feedBack, index, true)),
                5: getLocalTime(el.createdOn).date + '\n' + getLocalTime(el.createdOn).time,
                6: showIcons(el.files, index),
                7: el.status,
               
            }
        })
    }


    return (
        <div className="requestTableContainer">
            <DrilldownTable
                themeSelected={themeSelected}
                bodyData={(currentArr && currentArr.length) ? formatListDataDrillDown(currentArr) : []}
                titleData={columnTitle}
                showCollapse={false}
                showInput={false}
                showSort={true}
                arrangeOrder={arrangeOrder}
                arrangeKey={arrangeKey}
                drillDownReport={currentSection === AppConstant.help.formName ? issuesList : feedbacksList}
                initiateDrillDownReport={isDataLoading}
                loaderArray={9}
                showUTCTime={false}
                sortingAction={sagaActions.SORT_SUPER_ADMIN_TABLE}
                subRoot={{ pathName: currentSection === AppConstant.help.formName ? 'issuesAdminList' : 'feedbackAdminList' }}
            />
            {showImagesCarouselPopup()}
        </div>
    )
}

export default HelpAndFeedbackUserTable;