import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { RecommendationSS } from '../../../../../SmartSchedule/SchedulePro/Components/DashBoard/Children/RecommendationSS/RecommendationSS';
import SuccessAppointScreenSS from '../SuccessAppointment/SuccessAppointScreenSS';
import ManualOverride from '../../../../../SmartSchedule/SchedulePro/Components/DashBoard/Children/ManualOverride/ManualOverride';
import { ManualOverrideRecommendation } from '../../../../../SmartSchedule/SchedulePro/Components/DashBoard/Children/ManualOverride/Children/ManualOverrideRecommendation';
import SelectRescheuleReason from '../SelectReasonForRescheduleOrCancel/SelectRescheuleReason';
import { getRemoteConfigSelector } from '../../../../../../../redux/reducers/reducerSlices/RemoteConfigReducer';
import { useSelector } from 'react-redux';
import { isRescheduleCase } from '../../../../../../Assests/Utility';
import SSAppointmentCard from '../../../../../SmartSchedule/SchedulePro/Components/DashBoard/Children/SSAppointmentCard/SSAppointmentCard';
import SSAppointmentHistory from '../../../../../SmartSchedule/SchedulePro/Components/DashBoard/Children/AppointmentHistory/SSAppointmentHistory';
import { sagaActions } from '../../../../../../../sagas/sagaActions';
import { smartScheduleSelector } from '../../../../../../../redux/reducers/reducerSlices/SmartScheduleReducer';

const InitalAppointmentSelectionScreen = (props) => {
    const {rowData} = props;
    const dispatch = useDispatch();
    const ss = useSelector(smartScheduleSelector);
    const { appointmentHistory,initateLoading, selectedDateForApptUpdates } = ss;
    const [selectedOption, setSelectedOption] = useState('');
    const [rescheduleReason, setRescheduleReason] = useState(null);
    const [showRescheduleReason, setShowRescheduleReason] = useState(false);

    useEffect(()=>{
        
        if (_.size(rowData)) {
            let payload={
                "PoNumber": rowData[0].PoNumber,
                "IDWarehouse": rowData[0].IDWarehouse,
                "ReceivingZipCode": rowData[0].ReceivingZipCode,
                "IDDock": rowData[0].IDDock,
                "CRUDTYPE": "S"
            }
            dispatch({type:sagaActions.GET_APPOINTMENT_HISTORY,payload:payload})
        }
       
    },[rowData])

    useEffect(()=>{
        if (props.rowData && props.rowData.length && isRescheduleCase(props.rowData[0],isCarrier()) && !rescheduleReason) {
            setShowRescheduleReason(true)
        }
    },[props])
    
    useEffect(() => {
        if (isCarrier()) {
            setSelectedOption('R')
        } else {
            
            setSelectedOption('')
        }
        return () => {
            setSelectedOption('')
        }
    },[])

    const handleOptionChange = (type) => {
        setSelectedOption(type);
    }

    const callSuccessPage = () => {
        props.setShowSucces(true)
    }

    const isCarrier = () => {
        return props.userType === 'CARRIER' ? true : false
    }

    /**
     * Function to rendrer appt details and appt history
     * @returns 
     */
    function renderApptDetailsAndReasonDD(){
        return(
            <div className=' h-100'>
            <div>
            <SSAppointmentCard apptData={(props.rowData && _.size(props.rowData)) ? props?.rowData[0]:[]}/>
            <SSAppointmentHistory data={appointmentHistory} loading={initateLoading}/>
            </div>
            <div className='mt-2'>
            <SelectRescheuleReason rescheduleReason={rescheduleReason} setRescheduleReason={setRescheduleReason} onSubmit={()=>{setShowRescheduleReason(false)}} showButton={true} type='Reschedule'/>
            </div>
            </div>
        )
    }

    const renderApptScheduleOptions= ( )=>{
        if (_.upperCase(props?.userType) === 'CARRIER' && selectedOption === 'R') {
            return  <RecommendationSS isCarrier={isCarrier}
            rowData={props.rowData} 
            callSuccessPage={callSuccessPage} 
            rescheduleReason={rescheduleReason}/> 
        } else if (selectedOption === '' ) {
            return    <ManualOverride apptHistory={appointmentHistory} 
            handleOptionChangeParent={(type) =>  handleOptionChange(type)} 
            rescheduleReason={rescheduleReason} isCarrier={isCarrier}  rowData={props.rowData} />
        } else if (selectedOption === 'R') {
            return  <RecommendationSS isCarrier={isCarrier} 
            rowData={props.rowData} callSuccessPage={callSuccessPage} 
            handleOptionChangeParent={(type) =>  handleOptionChange(type)} 
            rescheduleReason={rescheduleReason}/> 
        } else{
            return <ManualOverrideRecommendation isCarrier={isCarrier} 
            rowData={props.rowData} callSuccessPage={callSuccessPage} 
            handleOptionChangeParent={(type) =>  handleOptionChange(type)} 
            rescheduleReason={rescheduleReason}/>
        }
    }

    return (
        <>
            {/* <div className={isCarrier() ? "row w-inherit" : "schedule-manual row p-2"}> */}
            <div className="row w-inherit po-schedule-right-ss">
                {props.showSuccess && _.size(props?.rowData) ? <SuccessAppointScreenSS {...props} selectedDateForApptUpdates={selectedDateForApptUpdates} /> :
                    <>
                        {/* <div className={isCarrier() ? "col-sm-6 hidden" : "col-sm-6"}>
                            <input
                                type="radio"
                                value={''}
                                name={AppConstant.commonStrings.recommendations}
                                className="form-check-input"
                                placeholder={''}
                                onBlur={(data) => { }}
                                onInput={(data) => { }}
                                onChange={() => handleOptionChange('R')}
                                checked={selectedOption === 'R'}
                            />
                            <label className={_.upperCase(props?.userType) === 'CARRIER' ? 'bigText px-2 hidden' : 'subText px-2 '} htmlFor="AppConstant.commonStrings.recommendations">My Recommended Appointments</label>
                        </div> */}
                        {/* <div className={isCarrier() ? "col-sm-6 hidden" : "col-sm-6"}>
                            <input
                                type="radio"
                                value={''}
                                name={'Manual'}
                                className={props?.disableManual ? "form-check-input disabled" : "form-check-input"}
                                placeholder={''}
                                onBlur={(data) => { }}
                                onInput={(data) => { }}
                                onChange={() => {
                                    if (props?.disableManual) {
                                        return;
                                    }
                                    handleOptionChange('M')
                                }}
                                checked={selectedOption === 'M'}
                            />
                            <label className={_.upperCase(props?.userType) === 'CARRIER' ? 'bigText px-2' : 'subText px-2'} htmlFor="Manual">I would like to schedule manually</label>
                        </div> */}
                        {showRescheduleReason ? 
                        <div className='p-3 pt-1'>{renderApptDetailsAndReasonDD()}</div>
                         :renderApptScheduleOptions()
                        }
                    </>}
            </div>

        </>
    );
}

export default InitalAppointmentSelectionScreen;