import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppConstant } from '../../../../../../Assests/AppConstant';
import { sagaActions } from '../../../../../../../sagas/sagaActions';
import Popup from '../../../../../Common/Popup/Popup';
import RolesList from './RolesList/RolesList';
import { random } from "lodash-es";
import { getUserSelector } from '../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { CustomDynamicForm } from '../../../../../Common/CustomForm/CustomDynamicForm';
import { shouldHavePermission } from '../../../../../../Assests/Utility';
import { getSuperAdminSelector } from '../../../../../../../redux/reducers/reducerSlices/SuperAdminReducerSlice';
import WarehouseSearch from '../../../../../SuperWarehouse/Components/SearchWarehouse/WarehouseSearch';

import './UserRoleAdminScreen.css';

const UserRoleAdminScreen = (props) => {

    const dispatch = useDispatch();
    const { themeSelected } = props;
    const [loading, setLoading] = useState(false);
    const [editData, setEditData] = useState({});
    const [resetFormData, setResetFormData] = useState(0);
    const superAdminSelector = useSelector(getSuperAdminSelector);
    const { closeRolesForm, error } = superAdminSelector;
    const user = useSelector(getUserSelector);
    const { permissions } = user;
    const [searchStr, setSearchStr] = useState('');
    const [allowPermissionA, setAllowPermissionA] = useState(false);
    const [allowPermissionC, setAllowPermissionC] = useState(false);
    const [allowPermissionE, setAllowPermissionE] = useState(false);

    useEffect(() => {
        if (permissions && permissions.length) {
            let tempC = shouldHavePermission(permissions, '401')
            setAllowPermissionC(tempC);
            let tempE = shouldHavePermission(permissions, '402')
            setAllowPermissionE(tempE);
            let tempA = shouldHavePermission(permissions, '403')
            setAllowPermissionA(tempA);
        }
    }, [permissions])

    useEffect(() => {
        setLoading(false);
        if (closeRolesForm) {
            document.getElementById('close-btn')?.click();
            dispatch({ type: sagaActions.UPDATE_ROLES_FORM_POPUP, payload: false });
            closepopup()
        }
    }, [closeRolesForm])

    useEffect(() => {
        if (error) {
            setLoading(false);
            dispatch({ type: sagaActions.ROLE_LIST_ERROR, payload: false });
        }
    }, [error])

    const editRole = (data) => {
        const editData1 = {
            "role_name": data.RoleName,
            "role_des": data.RoleDescription,
            "CreatedBy": data.CreatedBy,
            "email": data.Email,
            "CreatedOn": data.CreatedOn,
            "EnableStatus": data.EnableStatus,
            "RoleID": data.RoleID,
        }
        setShowModal(true);
        setEditData(editData1);
    }

    const cancelbtndata = {
        show: true,
        text: AppConstant.comments.resetbutton,
    };

    const submitbtnData = {
        show: true,
        text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm
            .saveButtonTitle,
    };

    const getSummitedFormData = (data) => {
        setLoading(true);
        dispatch({ type: sagaActions.UPDATE_ROLES_FORM_POPUP, payload: false });
        if (data) {
            const extraParams = {
                role_id: null,
                role_name: null,
                role_des: null,
            }
            if (data.RoleID) {
                extraParams.role_id = data.RoleID;
                extraParams.enable_status = data.EnableStatus;
                extraParams.role_name = data.role_name;
                extraParams.role_des = data.role_des;
                extraParams.crud_type = 'U';
            } else {
                extraParams.role_name = data.role_name;
                extraParams.role_des = data.role_des;
                extraParams.crud_type = 'I';
                extraParams.enable_status = false;
            }

            const apiParams = { ...data, ...extraParams };

            dispatch({ type: sagaActions.GET_ROLES_LIST, payload: apiParams });
        }
    };

    const createForm = () => {
        return (
            <CustomDynamicForm
                formFieldList={formFieldList}
                sort={true}
                cancelBtn={cancelbtndata}
                submitbtn={submitbtnData}
                themeSelected={themeSelected}
                getSummitedFormData={getSummitedFormData}
                editFormData={editData}
                loading={loading}
                resetFormData={resetFormData}
            />
        );
    };

    const formFieldList = [
        {
            name: "role_name",
            type: "text",
            size: "col-sm-12 my-2",
            position: 1,
            title:
                AppConstant.superUserAdmin.rolesAdmin.createRolesForm.roleNameTitleStr,
            formValidation: {
                required: true,
            },
        },
        {
            name: "role_des",
            type: "text",
            size: "col-sm-12 my-2",
            position: 2,
            title:
                AppConstant.superUserAdmin.rolesAdmin.createRolesForm.roleDescriptionTitleStr,
            formValidation: {
                required: true,
                lengthValidation: {
                },
            },
        },
    ]
    const [showModal, setShowModal] = useState(false);
    const closepopup = () => {

        setEditData({});
        setShowModal(false);
    }
    const showCreateRolesPopup = () => {

        const popupObj = {
            id: 'createRolesFormPopup1',
            modalLabel: 'createRolesFormPopup1',
            showClose: true,
            showfooter: false,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'l',
            title: editData ? AppConstant.superUserAdmin.rolesAdmin.buttonTitles.editRolesButtonTitle : AppConstant.superUserAdmin.rolesAdmin.buttonTitles.createRolesButtonTitle,
            bodyText: createForm(),
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.comments.cancelCommentButtonTitle
                },
                btn2: {
                    show: true,
                    text: AppConstant.commonStrings.save
                }
            },
        }

        return <Popup {...popupObj}
            popupBtnHandler={(text) => { }} themeSelected={themeSelected} closepopup={closepopup} showModal={showModal} />
    }



    return (
        <div className='mt-2'>
            {showCreateRolesPopup()}
            <div className='container-fluid d-flex py-2 text-start justify-content-between mt-1'>
                <button
                    data-bs-toggle="modal"
                    data-bs-target={`#createRolesFormPopup1`}
                    onClick={() => setShowModal(true)}
                    className={allowPermissionC ? 'addButton' : 'addButton disabled'}
                    disabled={allowPermissionC ? '' : 'disabled'}
                >
                    {AppConstant.superUserAdmin.rolesAdmin.buttonTitles.createRolesButtonTitle}
                </button>
                <div className='w-25'>
                    <WarehouseSearch
                        placeholder={'Search Roles'}
                        inputValue={searchStr}
                        cancelSearch={() => setSearchStr('')}
                        onChangeHandler={(text) => setSearchStr(text)} />
                </div>
            </div>
            <div className="roleListTable mt-1">
                <RolesList searchStr={searchStr} editRoleAction={(roleObj) => editRole(roleObj)} themeSelected={themeSelected}
                    allowPermissionE={allowPermissionE} allowPermissionA={allowPermissionA} />
            </div>
        </div>
    )
}

export default UserRoleAdminScreen;