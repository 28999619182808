import React from 'react'
import '../../SSAppointmentTracker/SSAppointmentTracker.css'
import '../../../../../../../LandingPage/Components/Dashboard/DefaultDashboard/Wrapper/DefaultDashboardWrapper.css'

const NoDataTracker = () => {
    return (
        <div className='appt-tracker-ss-nodata'>
            <div>🔅</div>
            <div className='boldText'>You have no appointments</div>
            <div className='text-center subText'>Get started by creating a new appointment</div>
        </div>
    )
}

export default NoDataTracker