import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as NoDataLightMode } from '../../../../../../Assests/Svg/NoDataLightMode.svg';
import { ReactComponent as NoDataDarkMode } from '../../../../../../Assests/Svg/NoDataDarkMode.svg';
import FaqTableRow from '../FAQSection/Component/FaqTableRow/FaqTableRow';
import { sagaActions } from '../../../../../../../sagas/sagaActions';
import { AppConstant } from '../../../../../../Assests/AppConstant';
import WarehouseSearch from '../../../../../SuperWarehouse/Components/SearchWarehouse/WarehouseSearch'
import { displayLoader, initiateSearch } from '../../../../../../Assests/Utility';
import { getSuperAdminSelector } from '../../../../../../../redux/reducers/reducerSlices/SuperAdminReducerSlice';
import './FAQSection.css';

const FaqSection = (props) => {

    const { themeSelected } = props
    const dispatch = useDispatch();
    const superAdminUser = useSelector(getSuperAdminSelector);
    const { faqList,isListLoading } = superAdminUser;
    const [faqArr, setFaqArr] = useState([]);
    const [searchStr, setSearchStr] = useState('');

    useEffect(() => {
        if (faqList && faqList.length) {
            setFaqArr(getEnabledFAQs(faqList));
        }
    }, [faqList])

    useEffect(() => {
        if (searchStr.length > 3) {
            const searchedArr = initiateSearch(getEnabledFAQs(faqList), searchStr, '');
            setFaqArr(searchedArr);
        } else if (searchStr === '') {
            setFaqArr(getEnabledFAQs(faqList))
        }
    }, [searchStr])

    const getEnabledFAQs = (arr) => (
        arr.filter((faqObj) => {
            if (faqObj.EnableStatus) {
                return faqObj
            }
        })
    )

    useEffect(() => {
        getFAQList();
    }, [])

    const getFAQList = () => {
        const apiParams = {
            "CUID": null,
            "EnableStatus": null,
            "CRUDTYPE": "S",
            "QuestionText": null,
            "AnswerText": null,
            "HelpFeedbackID": null,
            "AppType":window.location.pathname==='/faqadmin'?null:AppConstant.helpSupportFaq.appTypeText1.value
        }
        dispatch({ type: sagaActions.GET_FAQ_LIST, payload: apiParams });
    }

    const getTableRow = () => {
        return faqArr.map((faqObj, index) => {
            return <FaqTableRow data={faqObj} index={index} key={index} />
        })
    }

    const createFaqTable = () => {
        return (
            <div className='tableContainer'>
                <table>
                    <thead />
                    <tbody>
                        {getTableRow()}
                    </tbody>
                </table>
            </div>
        )
    }

    const showErrorFunc = () => {
        return (
            <div className='d-flex flex-column align-items-center noDataAvailable'>
                <span className='no-data-svg'>{themeSelected === 'light-mode' ? <NoDataLightMode /> : <NoDataDarkMode />}</span>
                <span className={`no-data-text1 ${themeSelected}`}>{AppConstant.poManagement.emptyTable.text1}</span>
            </div>
        )
    }

    return (
        <div className='container-fluid faqSection'>
            <div className='d-flex justify-content-between'>
                {AppConstant.helpSupportFaq.faqTitle}
                <div className='w-25 my-3 me-4 '>
                    <WarehouseSearch
                        placeholder={"Search FAQ's"}
                        inputValue={searchStr}
                        cancelSearch={() => setSearchStr('')}
                        onChangeHandler={(text) => setSearchStr(text)} />
                </div>
            </div>
            {faqArr.length && !isListLoading? createFaqTable() : isListLoading?displayLoader(themeSelected,50,10):showErrorFunc()}
        </div>
    )
}

export default FaqSection;