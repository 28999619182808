import React from 'react'
import { ReactComponent as PassedStageIcon } from '../../../../../../../../../Assests/Svg/passed-stage-icon.svg';
import { ReactComponent as PassedStageIconRed } from '../../../../../../../../../Assests/Svg/passed-stage-icon-red.svg';
import { ReactComponent as CurrentStageIcon } from '../../../../../../../../../Assests/Svg/current-stage-icon.svg';
import { ReactComponent as FutureStageIcon } from '../../../../../../../../../Assests/Svg/future-stage-icon.svg';
import './StatusColorIcon.css';

const StatusColorIcon = (props) => {

    const { currentStage, showLine, themeSelected, timeSpent, thisStage, currentStageIndex , isTruckLate} = props

    const setIcon = () => {
        if (!currentStageIndex) {
            return <FutureStageIcon />;
        } else if (currentStageIndex === thisStage) {
            return <CurrentStageIcon />;
        } else if (thisStage < currentStageIndex) {
            return thisStage === 1 && isTruckLate ? <PassedStageIconRed  /> : <PassedStageIcon />;
        } else {
            return <FutureStageIcon />;
        }
    }

    return (
        <span>
            <div className='d-flex align-items-start my-2'>
                {setIcon()}
                {thisStage < currentStageIndex ? <div className='container-fluid m-0 p-0'>{timeSpent}</div> : null}
            </div>
            <div className={`${showLine ? 'showLine' : 'hideLine'} line-${themeSelected}`} />
        </span>
    )
}

export default StatusColorIcon;