import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash-es';
import { sagaActions } from '../../../../../../../../sagas/sagaActions';
import { AppConstant } from '../../../../../../../Assests/AppConstant';
import { showAlert } from '../../../../../../../Assests/Utility';
import { toast } from 'react-toastify';
import { getUserSelector } from '../../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { getWhMetaDataReducerSlice } from '../../../../../../../../redux/reducers/reducerSlices/WhMetaDataReducerSlice';
import moment from 'moment';
import './FacilityClosureConflictForm.css';
import DrilldownTableSS from '../../../../../Components/Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTableSS';
import CustomButtonSS from '../../../../../../Common/CustomButton/CustomButtonSS';
import SSPopup from '../../../../../../Common/Popup/SSPopup/SSPopup';

const FacilityClosureConflictFormSS = (props) => {

    const { themeSelected, dockPayload } = props;
    const dispatch = useDispatch();
    const whMetaReducer = useSelector(getWhMetaDataReducerSlice);
    const { operationObj, arrangeOrder, arrangeKey, dockConflictObj } = whMetaReducer;
    const [isListLoading, setListLoading] = useState(false);
    const [metaTimeslotArr, setMetaTimeslotArr] = useState([]);
    const [dockDoorConflictArr, setdockDoorConflictArr] = useState([]);
    const user = useSelector(getUserSelector);
    const { cu_id } = user;
    const [currentItemIndex, setCurrentItemIndex] = useState('NA');
    const [currentDeleteIndex, setCurrentDeleteIndex] = useState('NA');
    const [selectedObj, setSelectedObj] = useState(null)
    const [selectedIndex, setSelectedIndex] = useState(null)
    const [showDeleteModel, setShowDeleteModel] = useState(false);
    const [showLoading, setShowLoading] = useState(true);


    const dockLeavesTitleDD = [
        { id: '', name: AppConstant.superUserAdmin.companyGroup.tableHeader.serialNum },
        { id: 'LeaveStart', name: AppConstant.reports.adfatReport.dropdownLabels.startDate },
        { id: 'LeaveEnd', name: AppConstant.reports.adfatReport.dropdownLabels.endDate },
        { id: 'Description', name: AppConstant.smartScheduler.docksTimeSlots.tableHeaders.description },
        { id: 'CreatedOn', name: AppConstant.superUserAdmin.companyGroup.tableHeader.createdOn },
        { id: 'status', name: AppConstant.superUserAdmin.companyGroup.tableHeader.status },
        { id: '', name: AppConstant.superUserAdmin.warehouseAdmin.warehouseTable.actionsColTitle },
    ]

    useEffect(() => {
        if (dockConflictObj && dockConflictObj.leaves.length) {
            setShowLoading(false);
            setdockDoorConflictArr(dockConflictObj.leaves)
        } else {
            setdockDoorConflictArr([])
            setShowLoading(false)
        }

    }, [dockConflictObj.leaves])

    /**
     * used to handle API response
     */
    useEffect(() => {
        if (operationObj.type) {
            switch (operationObj.type) {
                case 'Delete Leaves':
                    if (operationObj.status) {
                        handleApiResponse(AppConstant.notifications.dataAddedSuccess, AppConstant.smartScheduler.leaves.deleteLeaveSuccess);
                    } else {
                        handleApiResponse(AppConstant.commonStrings.failure, AppConstant.smartScheduler.leaves.deleteLeaveFailure);
                    }
                    break;
                default:
            }
        }
    }, [operationObj])

    /**
     * used to handle API responses based on success or failure
     * @param {*} result 
     * @param {*} message 
     */
    const handleApiResponse = (result, message) => {
        setCurrentItemIndex('NA')
        setCurrentDeleteIndex('NA')
        if (result === AppConstant.notifications.dataAddedSuccess) {
            showAlert(toast.TYPE.SUCCESS, message, themeSelected);
            dispatchDockConflict()
        } else {
            showAlert(toast.TYPE.ERROR, message, themeSelected);
        }
        setListLoading(false);
        dispatch({ type: sagaActions.RESET_OPERATION_OBJECT_WH });
    }

    const dispatchDockConflict = () => {
        dispatch({ type: sagaActions.GET_DOCK_CONFLICT_DETAILS, payload: dockPayload });
    }

    const deleteHandler = (data, index) => {
        setSelectedObj(data, index)
        setSelectedIndex(index)
        setShowDeleteModel(true)
    }
    /**
     * used to call API
     * @param {*} text 
     */
    const popupBtnHandler = (text) => {
        if (text === AppConstant.commonStrings.no) {
            setShowDeleteModel(false)
            setSelectedObj(null)
            setSelectedIndex(null)
        }
        else {
            setCurrentDeleteIndex(selectedIndex)
            const apiParams = {
                // "IDWHLeave": selectedObj.IDWHLeave,
                // "ID": selectedObj.ID,
                "IDWarehouse": selectedObj.IDWarehouse,
                // "Purpose": selectedObj.Purpose,
                "LeaveStart": selectedObj.LeaveStart,
                "LeaveEnd": selectedObj.LeaveEnd,
                "Status": true,
                "CreatedOn": selectedObj.CreatedOn,
                "CreatedBy": cu_id,
                "UpdatedOn": selectedObj.UpdatedOn,
                "UpdatedBy": cu_id,
                "CRUDTYPE": "R",
                "IDDockLeave": selectedObj.IDDockLeave,
                "IDDock": selectedObj.IDDock,
                "Description": selectedObj.Description
            }
            const operationObj = {
                type: 'Delete Leaves',
                status: ''
            }


            dispatch({ type: sagaActions.WAREHOUSE_META_DOCKS_LEAVES_LIST_ACTION, payload: apiParams, operationObj: operationObj })
        }
        setShowDeleteModel(false)
    }
    /**
     * used to create a pop
     * @returns 
     */
    const showDeleteConfirmation = () => {
        const popupObj = {
            id: 'showDeleteConfirmation',
            modalLabel: 'showDeleteConfirmation',
            showClose: true,
            showfooter: true,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title: AppConstant.smartScheduler.leaves.deleteLeaveTitle,
            bodyText: `${AppConstant.commonStrings.areYouSure} ${AppConstant.smartScheduler.leaves.deleteLeaveTitle}`,
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.commonStrings.yes
                },
                btn2: {
                    show: true,
                    text: AppConstant.commonStrings.no
                }
            },
        }

        return <SSPopup {...popupObj} popupBtnHandler={(text) => { popupBtnHandler(text) }} closepopup={() => { setShowDeleteModel(false) }} themeSelected={themeSelected} showModal={showDeleteModel} />
    }

    /** used to create custon buttom */
    const actionsButton = (groupObj, index) => {
        return (
            <div>
                <span className='d-flex justify-content-center align-items-center gap-2' >
                    <CustomButtonSS
                        disabled={false}
                        isLoading={currentDeleteIndex !== 'NA' && currentDeleteIndex === index ? true : false}
                        className={`no-border no-bg`}
                        onClick={() => { deleteHandler(groupObj, index) }}
                        isIcon={true}
                        iconClass={"fa-regular fa-trash-can fa-lg"}
                    />
                </span>
            </div>
        )
    }

    /**
     * format the data
     * @param {*} data 
     * @returns 
     */
    const formatListDataDrillDown = (data) => {
        return data.map((el, index) => {
            return {
                id: el.RoleID,
                0: index + 1,
                // 1: getFormattedDate(AppConstant.commonStrings.MM_DD_YYYY_dateFormat_with_Space, el.LeaveStart),
                // 2: getFormattedDate(AppConstant.commonStrings.MM_DD_YYYY_dateFormat_with_Space, el.LeaveEnd),
                1: el.LeaveStart ? moment(el.LeaveStart).utc().format(AppConstant.commonStrings.MM_DD_YYYY_dateFormat_with_Space) : "-",
                2: el.LeaveEnd ? moment(el.LeaveEnd).utc().format(AppConstant.commonStrings.MM_DD_YYYY_dateFormat_with_Space) : "-",
                3: el.Description,
                4: `${moment(el.CreatedOn).format(AppConstant.commonStrings.MM_DD_YYYY_dateFormat)}\n${moment(el.CreatedOn).format(AppConstant.commonStrings.HH_mm_dateFormat)}` || AppConstant.commonStrings.hypen,
                5: el.Status ? 'Enabled' : 'Disabled',
                6: actionsButton(el, index),
            }
        })
    }

    return (
        <>
            {dockDoorConflictArr &&
                <DrilldownTableSS
                    themeSelected={themeSelected}
                    bodyData={dockDoorConflictArr.length && formatListDataDrillDown(dockDoorConflictArr)}
                    titleData={dockLeavesTitleDD}
                    showCollapse={false}
                    showInput={false}
                    showSort={true}
                    arrangeOrder={arrangeOrder}
                    arrangeKey={arrangeKey}
                    drillDownReport={dockDoorConflictArr}
                    initiateDrillDownReport={isListLoading}
                    loaderArray={7}
                    sortingAction={sagaActions.SORT_WH_META_TABLE_ACTION}
                    subRoot={{ pathName: 'whMetaTimeslotArr' }}
                    showUTCTime={false}
                />}
            {showDeleteConfirmation()}
        </>
    )

}

export default FacilityClosureConflictFormSS;