import CustomButton from '../../../../../Common/CustomButton/CustomButton';
import InputWithTtile from '../../../../../Common/CustomInput/InputWithTtile';
import { AppConstant } from '../../../../../../Assests/AppConstant';
import './SearchAppointmentsInput.css';

const SearchAppointmentsInput = (props) => {
    
    const { searchAppointment, onEmailChange } = props;

    return (
        <div className='mt-3 justify-content-center p-3'>
            <div className='d-flex'>
                <div className='m-2 w-5'>
                    <InputWithTtile
                        errorMessage={AppConstant.smartScheduler.searchAppointments.enterCorectPOErrorMsg}
                        showError={false}
                        title={AppConstant.smartScheduler.searchAppointments.enterPoTitle} placeHolder={AppConstant.smartScheduler.searchAppointments.enterPOPlaceholder}
                        onEmailChange={() => onEmailChange()} />
                </div>
                <div className='ms-5 ps-4 mt-auto mb-1'>
                <CustomButton
                    isLoading={false}
                    title={AppConstant.smartScheduler.searchAppointments.searchApptsTitle}
                    className={"loginButton"}
                    onClick={() => searchAppointment()}
                />
            </div>
            </div>
            
        </div>
    )
}

export default SearchAppointmentsInput;