import React, { useDebugValue, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sagaActions } from "../../../../../../../sagas/sagaActions";
import { getSuperAdminSelector } from '../../../../../../../redux/reducers/reducerSlices/SuperAdminReducerSlice';
import { AppConstant } from "../../../../../../Assests/AppConstant";
import CustomDropDown from "../../../../../Common/CustomDropDown/CustomDropDown";
import DrilldownTable from '../../../Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTable';
import AdjustableTextComponent from "../../../../../Common/AdjustableTextComponent/AdjustableTextComponent";
import Popupold from '../../../../../Common/Popup/Popupold';
import { dateAndTimeUTCFormat, dateAndTimeLocal } from "../../../../../../Assests/Utility";
import { initiateSearch } from '../../../../../../Assests/Utility';
import moment from "moment";
import './RequestsReceivedTableSection.css';

const RequestsReceivedTableSection = (props) => {

    const { currentSection, themeSelected, searchStr, feedbacksList, issuesList } = props;
    const dispatch = useDispatch();
    const superAdminSagaObj = useSelector(getSuperAdminSelector);
    const [currentArr, setCurrentArr] = useState([])
    const { arrangeOrder, arrangeKey, requestsReceivedDropdownsList } = superAdminSagaObj;
    const [columnTitle, setColumnTitle] = useState({});
    const [selectDropDown1, setSelectDropDown1] = useState('');
    const [selectDropDown2, setSelectDropDown2] = useState('');
    const [showAllText, setShowAllText] = useState([]);
    const [requestIconsArr, setRequestIconsArr] = useState([]);
    const [carouselIndex, setCarouselIndex] = useState(0);
    const [isDataLoading, setIsDataLoading] = useState(false);

    useEffect(() => {
        if (searchStr.length > 3) {
            const searchedArr = initiateSearch(
                currentSection === 'Feedback' ? feedbacksList : issuesList,
                searchStr,
                currentSection === 'Feedback' ? 'feedBack' : 'issue')
            setCurrentArr(searchedArr);
        } else if (searchStr === '') {
            setCurrentArr(currentSection === 'Feedback' ? feedbacksList : issuesList)
        }
    }, [searchStr])

    useEffect(() => {
        resetData();
        getRequestsDropDownsList();
        setIsDataLoading(true);
        setTimeout(() => {
            setColumnTitle([
                { id: '', name: AppConstant.superUserAdmin.companyGroup.tableHeader.serialNum },
                { id: 'name', name: AppConstant.superUserAdmin.warehouseAdmin.warehouseTable.nameColTitle },
                { id: 'phoneNumber', name: AppConstant.superUserAdmin.companyGroup.tableHeader.contactNum },
                { id: 'email', name: AppConstant.superUserAdmin.companyGroup.tableHeader.email },
                { id: currentSection, name: currentSection },
                { id: 'createdOn', name: 'Created On' },
                { id: '', name: 'Images' },
                { id: 'ownedByArr', name: AppConstant.requestsReceived.tableHeaders.processedBy },
                { id: 'status', name: AppConstant.requestsReceived.tableHeaders.action },
            ]
            )
        }, 50)

    }, [currentSection]);

    const getRequestsDropDownsList = () => {
        dispatch({ type: sagaActions.GET_REQUESTS_RECEIVED_DROPDOWNS_LIST, payload: {} });
    }

    const resetData = () => {
        setColumnTitle([]);
        setSelectDropDown1('');
        setSelectDropDown2('');
        setShowAllText('');
        setCurrentArr([]);
    }

    useEffect(() => {
        if (currentSection === 'Issues') {
            setIssuesList();
        }
    }, [issuesList])

    const setIssuesList = () => {
        dispatch({ type: sagaActions.INITIATE_SUPER_ADMIN_LOADING, payload: false });
        setSelectDropDown1('');
        setSelectDropDown2('');
        setShowAllText('');
        setCurrentArr([]);
        setIsDataLoading(false);
        setTimeout(() => {
            if (issuesList && issuesList.length) {
                setCurrentArr(issuesList);
                const updatedArr = issuesList.map((reqObj, index) => {
                    return ({ showExpanded: false, id: index })
                })
                setShowAllText(updatedArr)
            }
        }, 50)
    }

    useEffect(() => {
        if (currentSection === 'Feedback') {
            setFeedbacksList();
        }
    }, [feedbacksList])

    const setFeedbacksList = () => {
        
        dispatch({ type: sagaActions.INITIATE_SUPER_ADMIN_LOADING, payload: false })
        setSelectDropDown1('');
        setSelectDropDown2('');
        setShowAllText('');
        setCurrentArr([]);
        setIsDataLoading(false);
        setTimeout(() => {
            if (feedbacksList && feedbacksList.length) {
                setCurrentArr(feedbacksList);
                const updatedArr = feedbacksList.map((reqObj, index) => {
                    return ({ showExpanded: false, id: index })
                })
                setShowAllText(updatedArr)
            }
        }, 10)
    }

    const onProcessorSelected = (data, obj, index) => {
        const apiParam = {
            "Id": obj.Id,
            "name": obj.name,
            "email": obj.email,
            "phoneNumber": obj.phoneNumber,
            "issue": currentSection === 'Issues' ? obj.issue : null,
            "feedback": currentSection === 'Feedback' ? obj.feedBack : null,
            "fileOne": obj.fileone ? obj.fileone : null,
            "fileTwo": obj.fileTwo,
            "ownedBy": data.dock_name,
            "status": obj.status,
            "type": currentSection === 'Feedback' ? 'Feedback' : "Issue",
            "crud_type": "U"
        }

        setSelectDropDown1(data.dock_name);
        dispatch({ type: sagaActions.UPDATE_ISSUES_LIST, payload: apiParam });
        const tempArr = [...currentArr];
        const tempObj = { ...tempArr[index] };
        tempObj.ownedBy = data.dock_name;
        tempArr[index] = tempObj;
        setCurrentArr([...tempArr]);
    }

    const onActionSelected = (data, obj, index) => {
        const apiParam = {
            "Id": obj.Id,
            "name": obj.name,
            "email": obj.email,
            "phoneNumber": obj.phoneNumber,
            "issue": currentSection === 'Issues' ? obj.issue : null,
            "feedback": currentSection === 'Feedback' ? obj.feedBack : null,
            "fileOne": obj.fileone ? obj.fileone : null,
            "fileTwo": obj.fileTwo,
            "ownedBy": obj.ownedBy,
            "status": data.dock_name,
            "type": currentSection === 'Feedback' ? 'Feedback' : "Issue",
            "crud_type": "U",
        }

        setSelectDropDown2(data.dock_name)
        dispatch({ type: sagaActions.UPDATE_ISSUES_LIST, payload: apiParam });
        const tempArr = [...currentArr];
        const tempObj = { ...tempArr[index] };
        tempObj.status = data.dock_name;
        tempArr[index] = tempObj;
        setCurrentArr([...tempArr]);

    }

    const getDropDown = (obj, index, dataArr, placeHolder, onItemSelected) => {
        return <CustomDropDown themeSelected={'light-mode'} data={dataArr} placeHolder={placeHolder ? placeHolder : 'Select'} onSelect={(event) => onItemSelected(event, obj, index)} keyStr={'dock_name'} />
    }

    const handleSelect = (selectedIndex, e) => {
        setCarouselIndex(selectedIndex);
    };

    const getImageCarouselContent = () => {
        return (
            <div className="reqExpandedImages">
                <div id="carouselExampleFade" className={`carousel ${themeSelected === 'dark-mode' ? 'carousel-dark' : ''} slide`} data-bs-ride="carousel" activeIndex={carouselIndex} onSelect={handleSelect}>
                    <div className="carousel-inner">
                        {requestIconsArr.map((slide, i) => {
                            return (
                                <div className="carousel-item active">
                                    <img src={slide} className="d-block w-100" alt="" />
                                </div>

                            )
                        })}
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>

            </div>
        )
    }

    const showImagesCarouselPopup = () => {

        const popupObj = {
            id: 'imageCarouselFormPopup1',
            modalLabel: 'imageCarouselFormPopup1',
            showClose: true,
            showfooter: false,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title: currentSection === 'Issues' ? 'Issues' : 'Feedback',
            bodyText: getImageCarouselContent(),
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: false,
                    text: AppConstant.commonStrings.close
                },
                btn2: {
                    show: false,
                    text: AppConstant.commonStrings.save
                }
            },
        }

        return <Popupold {...popupObj}
            popupBtnHandler={() => () => { }} closepopup={() => { }} themeSelected={themeSelected} />
    }

    const showIcons = (iconsArr, index) => {
        const icons = iconsArr && iconsArr.map((icon) => {
            return (
                <div className="d-flex justify-content-center"
                    onClick={() => setRequestIconsArr(iconsArr)}
                    data-bs-toggle="modal"
                    data-bs-target={`#imageCarouselFormPopup1`} >
                    <img className="requestIcons me-1 p-1 rounded-2" src={icon} alt='' />
                </div>
            )
        })

        return (<div className="d-flex justify-content-center ">
            {icons && icons.splice(0, 2)}
        </div>)
    }

    const expandAllText = (indexFaq, index) => {
        showAllText[index].showExpanded = !showAllText[index].showExpanded;
        setShowAllText([...showAllText])

    }

    const adjustLongText = (text1, index, showAllButton) => {
        const text = text1;
        return (
            text && text.length ?
                <div className="adjustedText">
                    <AdjustableTextComponent
                        showAllButton={text.length > 100 && showAllButton ? true : false}
                        indexFaq={index} className={'adjustedText'}
                        text={text} wordlLimit={100} showAll={showAllText}
                        onClickHandler={(indexFaq) => expandAllText(indexFaq, index)} />
                </div>
                :
                <div />
        )

    }

    const getLocalTime = (timeStr) => {
        let m = moment.tz(timeStr, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]', "America/Denver");
        m.tz(moment.tz.guess()).format();
        return dateAndTimeLocal(m.toString())
    }

    const formatListDataDrillDown = (data) => {
        return data.map((el, index) => {
            return {
                id: el.RoleID,
                0: (<div className="serialNumColumn">{index + 1}</div>),
                1: (<div className="nameColumn">{el.name}</div>),
                2: (<div className="contactNumColumn">{el.phoneNumber}</div>),
                3: (<div className="emailColumn">{el.email}</div>),
                4: (currentSection === 'Issues' ? adjustLongText(el.issue, index, true) : adjustLongText(el.feedBack, index, true)),
                5: (<div className="createdOnColumn">{getLocalTime(el.createdOn).date + '\n' + getLocalTime(el.createdOn).time}</div>),
                6: showIcons(el.files, index),
                7: requestsReceivedDropdownsList && requestsReceivedDropdownsList.ownedByArr && getDropDown(el, index, requestsReceivedDropdownsList.ownedByArr, el.ownedBy ? el.ownedBy : selectDropDown1, onProcessorSelected),
                8: requestsReceivedDropdownsList && requestsReceivedDropdownsList.statusArr && getDropDown(el, index, requestsReceivedDropdownsList.statusArr, el.status ? el.status : selectDropDown2, onActionSelected),
            }
        })
    }


    return (
        <div className="requestTableContainer">
            <DrilldownTable
                themeSelected={themeSelected}
                bodyData={(currentArr && currentArr.length) ? formatListDataDrillDown(currentArr) : []}
                titleData={columnTitle}
                showCollapse={false}
                showInput={false}
                showSort={true}
                arrangeOrder={arrangeOrder}
                arrangeKey={arrangeKey}
                drillDownReport={currentSection === 'Issues' ? issuesList : feedbacksList}
                initiateDrillDownReport={isDataLoading}
                loaderArray={9}
                showUTCTime={false}
                sortingAction={sagaActions.SORT_SUPER_ADMIN_TABLE}
                subRoot={{ pathName: currentSection === 'Issues' ? 'issuesAdminList' : 'feedbackAdminList' }}
            />
            {showImagesCarouselPopup()}
        </div>
    )
}

export default RequestsReceivedTableSection;