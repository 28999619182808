import React from 'react';
import { ReactComponent as NoDataLightMode } from '../../../../../../../Assests/Svg/NoDataLightMode.svg';
import { ReactComponent as NoDataDarkMode } from '../../../../../../../Assests/Svg/NoDataDarkMode.svg';
import { AppConstant } from '../../../../../../../Assests/AppConstant';
import NoDataImg from '../../../../../../../Assests/Images/nodata-xsm.png'
import './DrilldownTableSS.css'
const NoDataSS = (props) => {
    const { themeSelected } = props;
    return (
        <tr className='text-center align-middle' data-testid={AppConstant.dataTestId.emptyList}>
            <td colSpan={21} className='no-data-wrappper-ss p-5'>
                <div className='d-flex flex-column align-items-center justify-content-center'>
                    <img src={NoDataImg} alt='Opps....' />
                    <br />
                    <h6>{AppConstant.poManagement.emptyTable.text1}</h6>
                </div>
            </td>
        </tr>
    );
}

export default NoDataSS;