import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from "lodash-es";
import { AppConstant } from '../../../../../../../Assests/AppConstant';
import { sagaActions } from '../../../../../../../../sagas/sagaActions';
import Popup from '../../../../../../Common/Popup/Popup';
import CompanyList from '../CompanyList/CompanyList';
import { CustomDynamicForm } from '../../../../../../Common/CustomForm/CustomDynamicForm';
import { getCompanyGroupReducer } from '../../../../../../../../redux/reducers/reducerSlices/CompanyGroupReducer';
import { getCountryList } from '../../../../../../../../redux/reducers/reducerSlices/GetCountryListReducer';
import { getUserSelector } from '../../../../../../../../redux/reducers/reducerSlices/UserReducer';
import WarehouseSearch from '../../../../../../SuperWarehouse/Components/SearchWarehouse/WarehouseSearch';
import { shouldHavePermission, sortArrByKey } from '../../../../../../../Assests/Utility';
import { Auth } from 'aws-amplify';
import { LocationClient, SearchPlaceIndexForTextCommand } from "@aws-sdk/client-location";
import './CompanyAdminScreen.css';

const CompanyAdminScreen = (props) => {

    const dispatch = useDispatch();
    const { themeSelected } = props;
    const [countryDataArr, setCountryDataArrArr] = useState([]);
    const companyGroupData = useSelector(getCompanyGroupReducer);
    const [comapnyGroupArr, setComapnyGroupArr] = useState([]);
    const countryData = useSelector(getCountryList);
    const [loading, setLoading] = useState(false);
    const [editData, setEditData] = useState({});
    const user = useSelector(getUserSelector);
    const { closeCompanyForm, error, permissions } = user;
    const [resetFormData, setResetFormData] = useState(0);
    const [searchStr, setSearchStr] = useState('');
    const [allowPermissionC, setAllowPermissionC] = useState(false)
    const [allowPermissionE, setAllowPermissionE] = useState(false)
    const [allowPermissionD, setAllowPermissionD] = useState(false)
    const [reterivedLocationInfo, setReterivedLocationInfo] = useState({});
    const [particularFieldValue, setParticularFieldValue] = useState("");

    useEffect(() => {
        dispatch({ type: sagaActions.COMPANY_GROUP, payload: { crud_type: "S" } });
        dispatch({ type: sagaActions.GET_COUNTRY_DATA });
        if (permissions.length) {
            let tempC = shouldHavePermission(permissions, '201')
            setAllowPermissionC(tempC);
            let tempE = shouldHavePermission(permissions, '202')
            setAllowPermissionE(tempE);
            let tempD = shouldHavePermission(permissions, '203')
            setAllowPermissionD(tempD);
        }
    }, [])

    useEffect(() => {
        if (closeCompanyForm) {
            setLoading(false)
            closepopup();
            setShowModal(false);
          dispatch({ type: sagaActions.UPDATE_COMPANY_FORM_POPUP, payload: false });
        }
    }, [closeCompanyForm])

    useEffect(() => {
        if(error){
            setLoading(false);
            dispatch({ type: sagaActions.ROLE_LIST_ERROR, payload: false });
        }
    }, [error])

    useEffect(() => {
        if (reterivedLocationInfo && Object.keys(reterivedLocationInfo).length !== 0) {
            const getCountry = () => {
                if(reterivedLocationInfo.Place.Country === "USA"){
                    return 'United States'
                } else if(reterivedLocationInfo.Place.Country === "CAN"){
                    return 'Canada'
                } else if(reterivedLocationInfo.Place.Country === "MEX"){
                    return "Mexico"
                }
            }
            setParticularFieldValue({
                'locale': reterivedLocationInfo.Place.Municipality || reterivedLocationInfo.Place.Region,
                'state': reterivedLocationInfo.Place.Region,
                'country': getCountry(),
            });
        } else {
            setParticularFieldValue({
                'locale': '',
                'state': '',
                'country': ''
            });
        }
    }, [reterivedLocationInfo])

    useEffect(() => {
        if (countryData.data) {
            setCountryDataArrArr(sortArrByKey(countryData.data, 'country_name'));
        }
    }, [countryData.data]);

    useEffect(() => {
        if (companyGroupData.data) {
            const tempArr = companyGroupData.data.filter((companyGroupObj) => {
                if (companyGroupObj.EnableStatus) {
                    return companyGroupObj
                }
            })
            setComapnyGroupArr(sortArrByKey(tempArr, 'GroupName'));
        }
    }, [companyGroupData.data]);

    const getCompanyGroupList = () => {
        return (
            comapnyGroupArr.map((data, index) => {
                return (
                    <option key={data.CompanyGroupId} value={data.CompanyGroupId}>
                        {data.GroupName}
                    </option>
                );
            })
        );
    };

    const getCountryDataList = () => {
        return (
            countryDataArr &&
            countryDataArr.map((data, index) => {
                return (
                    <option key={data.country_name} value={data.country_name}>
                        {data.country_name}
                    </option>
                );
            })
        );
    };

    const cancelbtndata = {
        show: true,
        text: AppConstant.comments.resetbutton,
    };

    const submitbtnData = {
        show: true,
        text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm
            .saveButtonTitle,
    };

    const getLocationInfo = async (zipCode) => {

        const credentials = await Auth.currentUserCredentials();

        const client = new LocationClient({
            credentials,
            region: "us-west-2"
        });
        const params = {
            "IndexName": "RDC",
            "Text": zipCode,
            "MaxResults": 1,
        }
        const command = new SearchPlaceIndexForTextCommand(params)
        try {
            const data = await client.send(command);
            const result = data.Results[0]
            setReterivedLocationInfo(result);
        } catch (error) {
        }
    }

    const editClicked = (data, index) => {

        let editData1 = {
            "company_group_id": data.CompanyGroupId,
            "company_name": data.CompanyName,
            "email": data.Email,
            "contact_number": data.ContactNumber,
            "address": data.Address,
            "locale": data.Locale,
            "city": data.city,
            "zip": data.Zip,
            "state": data.State,
            "country": data.Country,
            "company_logo": data.CompanyLogo,
            "company_id": data.CompanyId,
            "enable_status": data.EnableStatus,
            "crud_type": "U",
            "WHTable": data.WHTable || 'TT'
        }
        setShowModal(true)
        setEditData(editData1);
        setParticularFieldValue(editData1);
    }

    const getSummitedFormData = (data) => {
        setLoading(true)
        dispatch({ type: sagaActions.UPDATE_COMPANY_FORM_POPUP, payload: false });
        if (data) {
            const extraParams = {
                company_id: null,
                enable_status: null,
                crud_type: null
            }
            if (data.company_id) {
                extraParams.company_id = data.company_id;
                extraParams.enable_status = data.enable_status
                extraParams.crud_type = 'U';
            } else {
                extraParams.company_id = null;
                extraParams.enable_status = 0;
                extraParams.crud_type = 'I';
            }
            const apiParams = { ...data, ...extraParams };
            dispatch({ type: sagaActions.UPDATE_COMPANY, payload: apiParams });
        }
    };

    const getFormFieldData = (data, name) => {
        if (name === 'zip') {
            if (data.length >= 2) {
                getLocationInfo(data)
            }
        }
    };

    const formFieldList = [
        {
            name: "company_group_id",
            type: "select",
            size: "col-sm-6",
            position: 1,
            title:
                AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm
                    .companyGroup,
            formValidation: {
                required: true,
            },

            getSelectData: getCompanyGroupList,
        },
        {
            name: "company_name",
            type: "text",
            size: "col-sm-6",
            position: 2,
            title: AppConstant.superUserAdmin.company.createCompanyForm.companyNameTitleStr,
            formValidation: {
                required: true,
            },
        },
        {
            name: "email",
            type: "text",
            size: "col-sm-6",
            position: 3,
            title: AppConstant.login.email,
            formValidation: {
                required: true,
                validateEmail: true,
            },
        },
        {
            name: "contact_number",
            type: "text",
            size: "col-sm-6",
            position: 4,
            placeholder: AppConstant.user.popup.createUser.phone_placeHolder,
            title: AppConstant.superUserAdmin.company.createCompanyForm.contact,
            formValidation: {
                required: true,
                validateContactNum: true,
                customError: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.contactNumErrorMessage
            },

        },
        {
            name: "address",
            type: "textarea",
            size: "col-sm-6",
            position: 5,
            title:
                AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.address,
            formValidation: {
                required: true,
            },
        },{
            name: "zip",
            type: "text",
            size: "col-sm-6",
            position: 6,
            title: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.zip,
            formValidation: {
                required: true,
                lengthValidation: {
                    customError: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.zipCodeErrorMessage
                },
            },
        },{
            name: "locale",
            type: "text",
            size: "col-sm-6",
            position: 7,
            title: AppConstant.superUserAdmin.city,
            formValidation: {
                required: true,
            },

        },
        {
            name: "state",
            type: "text",
            size: "col-sm-6",
            position: 8,
            title:
                AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.state,
            formValidation: {
                required: true,
            },
        },
        {
            name: "country",
            type: "select",
            size: "col-sm-6",
            position: 9,
            title:
                AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.country,
            formValidation: {
                required: true,
            },
            getSelectData: getCountryDataList,
        },
        {
            name: "company_logo",
            type: "file",
            size: "col-sm-6 mt-3",
            position: 10,
            accept: "image/png,image/jpeg",
            title: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.imageLogo,
            formValidation: {
                required: false,
            },
        },
        {
            name: "WHTable",
            type: "radio",
            size: "col-sm-6 mt-3 radioButtonText",
            position: 10,
            title: 'Company Type',
            formValidation: {
                required: true
            },
            disabledField: !_.isEmpty(editData) ? true: false,
            radiconfig: [{
                label: "Turn Time",
                value: 'TT'
            },
            {
                label: "Schedule Pro",
                value: "SPRO",
            }]
    
        },
    ];

    const createForm = () => {
        return (
            <CustomDynamicForm
                formFieldList={formFieldList}
                sort={true}
                cancelBtn={null}
                submitbtn={submitbtnData}
                themeSelected={themeSelected}
                getSummitedFormData={getSummitedFormData}
                editFormData={editData}
                loading={loading}
                getFormFieldData={getFormFieldData}
                resetFormData={resetFormData}
                setParticularFieldValue={particularFieldValue}
            />
        );
    };

    const [showModal,setShowModal]=useState(false);
    const closepopup = () => {
    
       setEditData({});
       setShowModal(false);
     }
    const showCompanyPopup = () => {

        const popupObj = {
            id: 'createCompanyFormPopup1',
            modalLabel: 'createCompanyFormPopup1',
            showClose: true,
            showfooter: false,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'xl',
            title: Object.keys(editData).length ? AppConstant.superUserAdmin.company.editCompanyTitle : AppConstant.superUserAdmin.company.createCompanyTitle,
            bodyText: createForm(),
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.comments.cancelCommentButtonTitle
                },
                btn2: {
                    show: true,
                    text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.saveButtonTitle
                }
            },
        }

        return <Popup {...popupObj} popupBtnHandler={(text) => () => ''} closepopup={closepopup} themeSelected={themeSelected}  showModal={showModal} />
    }

    const openCreateForm = () => {
        setShowModal(true)
        setParticularFieldValue({})
    }

    return (
        <div>

            <div className='container-fluid d-flex py-2 text-start justify-content-between mt-1'>
                <button
                className={allowPermissionC ? 'addButton my-1' : 'addButton my-1 disabled'}
                    // data-bs-toggle="modal"
                    // data-bs-target={`#createCompanyFormPopup1`}
                    onClick={()=> openCreateForm()}
                    disabled={allowPermissionC ? '' : 'disabled'}
                >
                    {AppConstant.superUserAdmin.company.createCompanyTitle}
                </button>
                <div className='w-25'>
                    <WarehouseSearch
                        placeholder={'Search Companies'}
                        inputValue={searchStr}
                        cancelSearch={() => setSearchStr('')}
                        onChangeHandler={(text) => setSearchStr(text)} />
                </div>
            </div>
            <div className="d-flex justify-content-center companyListTableContainer">
                <CompanyList searchStr={searchStr} editCompanyAction={(companyObj, index) => editClicked(companyObj, index)} themeSelected={themeSelected} allowPermissionE={allowPermissionE} allowPermissionD ={allowPermissionD} />
                {showCompanyPopup()}
            </div>
        </div>
    )
}

export default CompanyAdminScreen;