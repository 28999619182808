import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Header from '../../../Header/Header';
import Navigationbar from '../../../Navigationbar/Navigationbar';

import { areObjectsEqual, displayLoader, getFormattedContactNum, showAlert } from '../../../../Assests/Utility';
// @ts-ignore
import { toast } from 'react-toastify';
import { sagaActions } from '../../../../../sagas/sagaActions';
import { smartScheduleSelector } from '../../../../../redux/reducers/reducerSlices/SmartScheduleReducer';
import { getUserSelector } from '../../../../../redux/reducers/reducerSlices/UserReducer';

import BookApptsGuardsSearch from './BookApptsGuardsSearch/BookApptsGuardsSearch';
import GuardsApptsListScreen from './ShowApptsList/GuardsApptsListScreen';
import { AppConstant } from '../../../../Assests/AppConstant';
import Popup from '../../../Common/Popup/Popup';
import PDFViewer from '../../../Common/PdfViewer/PdfViewer';
import InitalCheckInForm from './InitalCheckInForm';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../Common/CustomButton/CustomButton';
import CustomButtonSS from '../../../Common/CustomButton/CustomButtonSS';
import { getDriverCheckinReducer } from '../../../../../redux/reducers/reducerSlices/DriverCheckinReducer';

const Guards = (props) => {

    const { themeSelected ,isCheckIn} = props;
    const dispatch = useDispatch();
    const userPermission = useSelector(getUserSelector);
    const {  userType, selectedWarehouse, cu_id } = userPermission;
    const ss = useSelector(smartScheduleSelector);
    const { initateLoading, guardSearchPoData, appointmentList } = ss;
    const [didSearched, setDidSearched] = useState(false);
  const [scheduleScreenType, setScheduleScreenType] = useState('')
    const [searchPoDataState, setSearchPoDataState] = useState(null)
const [inputData,setInputData]=useState();
const [callReset,setCallReset]=useState(false);
const [showHelpModal, setShowHelpModal] = useState(false);
const [selectedData,setSelectedData]=useState(null);
const [showSuccess,setShowSuccess]=useState(false);
const [showNext,setShowNext] = useState(false)
//Get Driver Data from Redux
const driverData = useSelector(getDriverCheckinReducer);

    useEffect(() => {
        setShowHelpModal(false);
        setDidSearched(false)
        dispatch({ type: sagaActions.INTIATE_SS_LOADER, payload: false })
        dispatch({ type: sagaActions.RESET_DRIVER_CHECKIN})
    }, [])

    useEffect(() => {
        dispatch({ type: sagaActions.INTIATE_SS_LOADER, payload: false });
        if (_.size(guardSearchPoData)) {
            setSearchPoDataState(guardSearchPoData)
        }
    }, [guardSearchPoData])
    useEffect(()=>{
        if(searchPoDataState&& searchPoDataState.loads && searchPoDataState.loads.length && inputData){
            setSelectedData(searchPoDataState.loads[0]?.pos.filter(e=>e.po_number===inputData.po));   
        }
        
    },[searchPoDataState])
const clearScreen=()=>{
    setCallReset(true);
}
    const showFindPOPopup = () => {
        const popupObj = {
            id: 'createCompanyGroupFormPopup1',
            modalLabel: 'createCompanyGroupFormPopup1',
            showClose: true,
            showfooter: false,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'xl',
            title: 'Trouble locating Driver PO Number ?',
            bodyText: createForm(),
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.comments.cancelCommentButtonTitle
                },
                btn2: {
                    show: true,
                    text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.saveButtonTitle
                }
            },
        }

        return <Popup {...popupObj} popupBtnHandler={(text) => () => ''} closepopup={closeCurrentpopup} themeSelected={themeSelected} showModal={showHelpModal} />
    }

    const closeCurrentpopup = () => {
        setShowHelpModal(false);
    }
    const createForm = () => {
        const poHelperArr = [
            'The PO number may also be listed as a Customer Order Number.',
            'PO numbers are usually 6 or 7-digits long and may include numbers and letters.',
            'If the Driver has more than one PO or Customer Order Number, enter the first PO listed. ',
        ]
        return (
            <div className=''>
                <ol>
                    {
                        poHelperArr.map((ele) => {
                            return <li className='fs-6'>{ele}</li>
                        })
                    }
                    
                </ol>
                <PDFViewer pdfUrl={AppConstant.commonStrings.findPoPdfHelperFile} width={'100%'} height={"500px"} />
            </div>
        );
    };


    const displayAppointmentsList = (inputs) => {
        setSearchPoDataState([])
        setCallReset(false);
            setDidSearched(true)
            setInputData(inputs);
            if (inputs.transType===AppConstant.commonStrings.inBoundLoadTitle || !isCheckIn) {
              dispatch({ type: sagaActions.INTIATE_SS_LOADER, payload: true })
              // dispatch({ type: sagaActions.INTIATE_SS_SEARCH_PO, payload: { PoNumber: po_number, ReceivingZipCode: receivingZipcode, UserType: userType, ID: userType === 'CARRIER' ? userAccountDetails?.IDCarrier : selectedWarehouse?.ID } })
              dispatch({ type: sagaActions.INTIATE_GUARD_SEARCH_PO, payload: { PoNumber: inputs.po, IDWarehouse:selectedWarehouse.ID,"PhoneNumber":getFormattedContactNum(inputs.phoneNo)} })
              !isCheckIn && setShowNext(true)
            }
            else{
              setShowNext(true)
            }
    }

    useEffect(()=>{
      if (driverData && driverData.data.message===AppConstant.driver.checkInSuccess) {
        setShowNext(false)
      }
      if (driverData && driverData.data.message===AppConstant.driver.checkOutSuccess) {
        setShowNext(false)
      }
    },[driverData.data])

    return (
      <div className="container-fluid m-1">
        <Header {...props} />
        <Navigationbar {...props} />
        <div
          className={"d-flex justify-content-center"}
          style={{ overflowY: "scroll", height: "80vh" }}
        >
          <div className={""}>
            {!didSearched && !initateLoading && (
              <BookApptsGuardsSearch
                {...props}
                searchAppointment={displayAppointmentsList}
                initateLoading={initateLoading}
                showPdfHelperPopup={() => setShowHelpModal(true)}
                scheduleScreenType={scheduleScreenType}
                setScheduleScreenType={setScheduleScreenType}
                callReset={callReset}
                didSearched={didSearched}
                inputData={inputData}
              />
            )}
            <div className="w-100 d-flex">
              {initateLoading || driverData.loading ? (
                <div className="w-80vw">
                  {displayLoader(themeSelected, 50, 10)}
                </div>
              ) : (((
                didSearched &&
                guardSearchPoData) || showNext) && (
                    <div className='w-35 p-3'>
                        <CustomButtonSS
                isLoading={false}
                title={"Back"}
                className={"no-border no-bg"}
                isIcon={true}
                iconClass={'fa-solid fa-arrow-left'}
                onClick={() => {
                   setDidSearched(false)
                   setCallReset(true)
                   setShowNext(false)
                   setSelectedData(null)
                   dispatch({ type: sagaActions.RESET_DRIVER_CHECKIN})
                    }}
              />
                  <GuardsApptsListScreen
                    data={searchPoDataState}
                    scheduleScreenType={scheduleScreenType}
                    setScheduleScreenType={setScheduleScreenType}
                    screenTypeClassname=""
                    inputData={inputData}
                    clearScreen={clearScreen}
                    callReset={callReset}
                    isCheckIn={isCheckIn}
                  />
                  </div>
                )
              )}
              {showFindPOPopup()}
       

            {((callReset === false &&
              selectedData &&
              guardSearchPoData?.load_count > 0) || (showNext && isCheckIn)) &&
              !initateLoading && (
                <div className="col-sm-8">
                  <InitalCheckInForm
                    showSuccess={showSuccess}
                    setShowSucces={setShowSuccess}
                    searchPoDataState={searchPoDataState}
                    warehouseData={selectedWarehouse}
                    DriverDetails={searchPoDataState?.DriverDetails}
                    selectedRowData={selectedData && selectedData.length?selectedData[0]:null}
                    inputData={inputData}
                    clearScreen={clearScreen}
                    didSearched={initateLoading}
                    isCheckIn={isCheckIn}
                  />
                </div>
              )}
                   </div>
          </div>
        </div>
      </div>
    );
}

export default Guards;