import React, { useState } from "react";
import './TruckWithText.css'
import '../LoginSS.css'
import HelpAndFeedbackSS from "./HelpAndFeedbackSS/HelpAndFeedbackSS";
import { AppConstant } from "../../../../../../../Assests/AppConstant";

// This component render static text with image on left side of new login screem
const TruckwithText = () => {
    const [showHelpAndFeedback, setShowHelpAndFeedback] = useState(false);
    const [formName, setFormName] = useState(false)
    const [modalHeadingObj, setModalHeadingObj] = useState({
      heading: AppConstant.help.titleTextForHelp,
      isIcon: true,
      iconClass: 'fa-solid fa-dharmachakra'
    })
    // Toggle(open/close) modal handler
    const toggleModal = () => {
      setShowHelpAndFeedback(!showHelpAndFeedback)
    }
    
    return (
        <div className="d-flex" id="left-block-twt">
            {/* <div className="inner_blocks" id="left-block"> */}
            {showHelpAndFeedback &&
        <HelpAndFeedbackSS toggleModal={() => { setShowHelpAndFeedback(!showHelpAndFeedback) }} {...modalHeadingObj} formName={formName} />
      }
            <div className="pos-rel">
                <div className="text-container-ss">
                    <p className="ss-signup-top-para">
                        <span className="fw-bolder">FreightSmith SchedulePro </span> is the ultimate solution
                        for streamlining and simplifying warehouse appointment scheduling.
                        Developed by inbound warehouse operation specialists, this dynamic and
                        budget-friendly tool enhances the inbound delivery experience.
                        Tailored for warehouses of all scales, FreightSmith SchedulePro offers
                        adaptability and personalization, enabling businesses to enhance their
                        receiving efficiency. Its user-friendly interface fosters streamlined
                        communication among carriers, shippers, and receivers, fostering
                        accountability and structured processes that mitigate costs and
                        frustrations.
                    </p>
                    {/* WP-2466 - Acme warehouse */}
                    {/* <p className="ss-signup-top-para">
                        Enjoy a monthly subscription at <span className="fw-bolder">$25 USD</span>, with the
                        flexibility to cancel anytime.
                    </p> */}
                    <p className="ss-signup-top-para">
                        <p id="bottom-para-ss text-light" >For Customer Support:</p>
                        <p>
                            <i className="fa-regular fa-envelope info-label-icon"></i>
                            <a href="mailto:info@freightsmith.net" className="contact-handles text-light mx-2 f-12">
                                {AppConstant.commonStrings.businessEmail}
                            </a>
                        </p>

                        <p>
                            <i className="fa-solid fa-phone info-label-icon"></i>

                            <a href="tel:+18445867729" className="contact-handles text-light mx-2 f-12">
                                1-844-586-7729
                            </a>
                        </p>
                    </p>
                    <hr />
                    <p className="bottom-text-content-ss text-light">
                        FreightSmith suite provide immediate ROI by enhancing communication,
                        digitizing operations, and offering delivery analytics for warehouses
                        and distribution centers. Explore all products here:
                    </p>
                    <ul className="bottom-text-content-ss text-light">
                        <li>
                            <i className="fa-solid fa-play fa-2xs"></i>
                            <span className="mx-2">FreightSmith IMS</span>
                        </li>
                        <li>
                            <i className="fa-solid fa-play fa-2xs"></i>
                            <span className="mx-2">FreightSmith Mobile App</span>
                        </li>
                        <li>
                            <i className="fa-solid fa-play fa-2xs"></i>
                            <span className="mx-2">FreightSmith SchedulePro</span>
                        </li>
                    </ul>
                    <div className="truckwithtext-bottom d-flex justify-content-between text-light w-100">
                        <span>Hey, looking for something specific ?</span>
                        <div id="btn-container">
                            <button
                                className="modal_btns"
                                onClick={() => {
                                    setModalHeadingObj({
                                      heading: AppConstant.help.titleTextForHelp,
                                      isIcon: true,
                                      iconClass: 'fa-solid fa-dharmachakra'
                                    })
                                    setFormName(AppConstant.help.formName);
                                    toggleModal()
                                  }}
                            >
                                <i className="fa-solid fa-dharmachakra"></i>
                                <span className="btn_title">Help</span>
                            </button>
                            <button
                                className="modal_btns"
                                onClick={() => {
                                    setModalHeadingObj({
                                      heading: AppConstant.help.titleTextForFeedback,
                                      isIcon: true,
                                      iconClass: 'fa-regular fa-message'
                                    })
                                    setFormName(AppConstant.feedback_text)
                                    toggleModal()
                                  }}
                            >
                                <i className="fa-regular fa-message"></i>
                                <span className="btn_title">Feedback</span>
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default TruckwithText;
