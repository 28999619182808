import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import _ from 'lodash-es';
import { AppConstant } from '../../../../../../Assests/AppConstant';
import { sagaActions } from '../../../../../../../sagas/sagaActions';
import { getDefaultDashboardSelector } from '../../../../../../../redux/reducers/reducerSlices/DefaultDashboardReducer';
import TaskItemsRow from './TaskItemsRow/TaskItemsRow';
import Loader from '../../../../../Loader/Loader';
import { ReactComponent as NoDataLightMode } from '../../../../../../Assests/Svg/NoDataLightMode.svg';
import { ReactComponent as NoDataDarkMode } from '../../../../../../Assests/Svg/NoDataDarkMode.svg';
import { ReactComponent as UnloadingTruckIcon } from '../../../../../../Assests/Svg/unloading-task-items.svg';
import { ReactComponent as DoorAssignedIcon } from '../../../../../../Assests/Svg/door-assigned-task-items.svg';
import { ReactComponent as AwaitingDriverConfirmIcon } from '../../../../../../Assests/Svg/awaiting-driver-confirm-task-items.svg';
import { ReactComponent as StagingIcon } from '../../../../../../Assests/Svg/staging-task-items.svg';
import { ReactComponent as POApprovedIcon } from '../../../../../../Assests/Svg/po-approved-task-items.svg';
import { ReactComponent as PendingApprovalIcon } from '../../../../../../Assests/Svg/pending-approval-task-items.svg';
import { usePrevious } from '../../../../../Common/CustomHooks/usePrevious';
import { getFormattedDate, YYYY_MM_DD_FORMAT, getCurrentDate } from '../../../../../../../Utils/DateUtils';
import { getSelectedDateSelector } from '../../../../../../../redux/reducers/reducerSlices/DateSelectorReducer';
import { getUserSelector } from '../../../../../../../redux/reducers/reducerSlices/UserReducer';
import './TaskItemsDue.css';
import FacilityRating from '../FacilityRating/FacilityRating';

const TaskItemsDue = (props) => {
    const { themeSelected } = props;
    const dispatch = useDispatch();
    const defaultDashboard = useSelector(getDefaultDashboardSelector);
    const { taskItemsDueObj, autoFetch, selectedTaskItem, dateRangeObj } = defaultDashboard;
    const appConstantsPath = AppConstant.dashboard.defaultdashboard.funnelGraph.taskItemDueRows;
    const [isFetchingData, setIsFetchingData] = useState(false);
    const getSelectedDateSelectorObj = useSelector(getSelectedDateSelector);
    const { selectedDate } = getSelectedDateSelectorObj;
    const previousSelectedTaskItem = usePrevious(selectedTaskItem);
    const navigate = useNavigate();
    const userPermission = useSelector(getUserSelector);
    const { selectedWarehouse } = userPermission;
    const [taskItemsApiRespArr, setTaskItemsApiRespArr] = useState([]);
    const [taskItemsDisabled, setTaskItemsDisabled] = useState(false);

    useEffect(() => {
        setTaskItemsDisabled(true);
        if (dateRangeObj.start && dateRangeObj.end) {
            const startDateCheck = _.isEqual(getFormattedDate('DD-MM-YYYY', dateRangeObj.start), getFormattedDate('DD-MM-YYYY', getCurrentDate()))
            const endDateCheck = _.isEqual(getFormattedDate('DD-MM-YYYY', dateRangeObj.end), getFormattedDate('DD-MM-YYYY', getCurrentDate()))

            if (startDateCheck && endDateCheck && Object.keys(taskItemsDueObj).length) {
                setTaskItemsDisabled(false)
                convertTaskItemObjToArr()
            } else {
                resetTaskItemsArray()
            }
        } else {
            resetTaskItemsArray()
        }

    }, [dateRangeObj, taskItemsDueObj])

    useEffect(() => {
        if (window.location.pathname === '/analytics' && selectedTaskItem !== '' && selectedTaskItem && previousSelectedTaskItem === '') {
            updatePoListApi();
        } else {
            resetTaskItemsArr();
        }
    }, [selectedTaskItem]);

    //Implemented realtime refresh
    // useEffect(() => {
    //     if (autoFetch) {
    //         hitTaskItemDueApi()
    //     }
    // }, [autoFetch])

    useEffect(() => {
        setIsFetchingData(false);
    }, [taskItemsDueObj])

    useEffect(() => {
        dispatch({ type: sagaActions.SET_TASK_ITEM, payload: '' });
        //Implemented realtime refresh
        // hitTaskItemDueApi();
        Object.keys(taskItemsDueObj).length ? setIsFetchingData(false) : setIsFetchingData(true);
        resetTaskItemsArr();
    }, [])

    //Implemented realtime refresh
    // const hitTaskItemDueApi = () => {
    //     const params = {
    //         "date": getFormattedDate(YYYY_MM_DD_FORMAT, selectedDate),
    //         "dock_id": null,
    //         "warehouse_id": selectedWarehouse.ID
    //     }
    //     dispatch({ type: sagaActions.GET_TASK_ITEMS_DUE_LIST, payload: params });
    // }

    const resetTaskItemsArray = () => {
        setTaskItemsApiRespArr([])
        const taskItemsApiRespArr1 = [
            {
                'number': 0,
                'title': appConstantsPath.unschedulePoTitle,
                'icon': <PendingApprovalIcon />
            },
            {
                'number': 0,
                'title': appConstantsPath.staggingTrucksTitle,
                'icon': <StagingIcon />,
            },
            {
                'number': 0,
                'title': appConstantsPath.awatingPaperworkDwell,
                'icon': <AwaitingDriverConfirmIcon />,
            },
            {
                'number': 0,
                'title': `${appConstantsPath.inDetention}`,
                'icon': <POApprovedIcon />
            },
            {
                'number': 0,
                'title': `${appConstantsPath.approachingDetention}`,
                'icon': <POApprovedIcon />
            },
        ];

        setTaskItemsApiRespArr(taskItemsApiRespArr1)
    }

    const convertTaskItemObjToArr = () => {
        setTaskItemsApiRespArr([])
        const taskItemsApiRespArr1 = [
            {
                'number': taskItemsDueObj.UnsheduledPO,
                'title': appConstantsPath.unschedulePoTitle,
                'icon': <PendingApprovalIcon />
            },
            {
                'number': taskItemsDueObj.Trunck_Staging,
                'title': appConstantsPath.staggingTrucksTitle,
                'icon': <StagingIcon />,
            },
            {
                'number': taskItemsDueObj.AwaitingPaperworkTruckCount,
                'title': appConstantsPath.awatingPaperworkDwell,
                'icon': <AwaitingDriverConfirmIcon />,
            },
            {
                'number': `${taskItemsDueObj.InDetentionCount}`,
                'title': `${appConstantsPath.inDetention}`,
                'icon': <POApprovedIcon />
            },
            {
                'number': `${taskItemsDueObj.DetentionApproachingCount}`,
                'title': `${appConstantsPath.approachingDetention}`,
                'icon': <POApprovedIcon />
            },
        ];
        setTaskItemsApiRespArr(taskItemsApiRespArr1)

    }

    const updatePoListApi = () => {
        if (selectedTaskItem) {
            // dispatch({ type: sagaActions.AUTO_FETCH_PO_LIST, payload: true });
            dispatch({ type: sagaActions.FETCH_TRUCK_LIST, payload: '' });
            navigate("/loadmanagement");
        }
    }

    const createPOsPendingApprovalArr = () => {
        dispatch({ type: sagaActions.SET_TASK_ITEM, payload: 'UnscheduledPO' });
    }

    const createStaggingArr = () => {
        dispatch({ type: sagaActions.SET_TASK_ITEM, payload: 'In_Staging' });
    }

    const createAwatingPaperworkArr = () => {
        dispatch({ type: sagaActions.SET_TASK_ITEM, payload: 'AwaitingPaperwork' });
    }

    const createInDetentionArr = () => {
        dispatch({ type: sagaActions.SET_TASK_ITEM, payload: 'in_detention' });
    }

    const createApproachingDetentionArr = () => {
        dispatch({ type: sagaActions.SET_TASK_ITEM, payload: 'approaching_detention' });
    }

    const resetTaskItemsArr = () => {
        dispatch({ type: sagaActions.SET_TASK_ITEM, payload: '' });
    }

    const onClickTaskItem = (e) => {
        if (e.title.includes(appConstantsPath.unschedulePoTitle) && e.number > 0) {
            createPOsPendingApprovalArr();
        } else if (e.title.includes(appConstantsPath.staggingTrucksTitle) && e.number > 0) {
            createStaggingArr();
        } else if (e.title.includes(appConstantsPath.awatingPaperworkDwell) && e.number > 0) {
            createAwatingPaperworkArr();
        } else if (e.title.includes(appConstantsPath.inDetention) && e.number > 0) {
            createInDetentionArr();
        } else if (e.title.includes(appConstantsPath.approachingDetention) && e.number > 0) {
            createApproachingDetentionArr();
        } else {
            resetTaskItemsArr();
        }
    }

    const showTaskItemDueTable = () => {
        const arr = taskItemsApiRespArr.map((element, i) => {
            const noOfTrucks = element.number;
            const title = element.title;
            const statusIcon = element.icon;
            return (
                <TaskItemsRow
                    key={`${title} ${i}`}
                    title={title}
                    noOfTrucks={noOfTrucks}
                    statusIcon={statusIcon}
                    themeSelected={themeSelected}
                    onClickTaskItem={() => onClickTaskItem(element)} />
            )

        });
        return arr;
    }

    const noDtataAvailable = () => {
        return (
            <div className='d-flex flex-column align-items-center'>
                <span className='no-data-svg'>{themeSelected === 'light-mode' ? <NoDataLightMode /> : <NoDataDarkMode />}</span>
                <span className={`no-data-text1 ${themeSelected}`}>{AppConstant.poManagement.emptyTable.text1}</span>
            </div>
        )
    }

    return (
        <div className={`ms-2 col-5 taskItemsSection ${themeSelected} m-0 p-0`}>
            <div className='m-0 p-0'>
                <div className={`ms-2 graphHeading ${themeSelected} me-5 taskItemTitle ${taskItemsDisabled ? 'disable' : ''}`}>{AppConstant.dashboard.defaultdashboard.funnelGraph.sectionTitle.taskItemDueTitle}</div>
                <div className='ms-3 py-0 taskItemsTable'>
                    {isFetchingData ?
                        <Loader height={280} count={1} highlightColor={themeSelected === 'dark-mode' ? '#151515' : ''}
                            baseColor={themeSelected === 'dark-mode' ? '#575757' : ''}
                        />
                        :
                        Object.keys(taskItemsDueObj).length ?
                            <table>
                                <thead />
                                <tbody>
                                    <tr>
                                        <td>
                                            {taskItemsApiRespArr && taskItemsApiRespArr.length ? showTaskItemDueTable() : noDtataAvailable()}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            :
                            noDtataAvailable()
                    }
                </div>
                <FacilityRating themeSelected={props.themeSelected}/>
            </div>
        </div>
    )
}

export default TaskItemsDue;