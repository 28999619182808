import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    paytraceClientId: '',
    authToken: '',
    paymentResponse: {},
    cancelSubscriptionObj: {},
    mySubscriptionList: [],
    operationObj: {},
    paymentLoading: false,
    updateCardDetailsObj: {}
}

export const PaytraceReducer = createSlice({
    name: 'PaytraceReducer',
    initialState,
    reducers: {
        getClientId: (state, action) => {
            state.paytraceClientId = action.payload;
        },
        getAuthToken: (state, action) => {
            state.authToken = action.payload;
        },
        getPaymentResponse: (state, action) => {
            state.paymentResponse = action.payload;
        },
        updateMySubscriptionListData: (state, action) => {
            state.mySubscriptionList = action.payload;
        },
        cancelSubscriptionData: (state, action) => {
            state.cancelSubscriptionObj = action.payload;
        },
        resetPaytraceData: (state, action) => {
            state.paymentResponse = {}
        },
        updateOperationObj: (state, action) => {
            state.operationObj = action.payload
        },
        updatePaymentLoading: (state, action) => {
            state.paymentLoading = action.payload
        },
        updateCardObj: (state, action) => {
            state.updateCardDetailsObj = action.payload
        },
    }
});

export const { getClientId, getAuthToken, getPaymentResponse, resetPaytraceData, cancelSubscriptionData, updateMySubscriptionListData, updateOperationObj, updatePaymentLoading, updateCardObj } = PaytraceReducer.actions;

// Selectors
export const paytraceReducer = (state) => {
    return state.PaytraceReducer;
};

export default PaytraceReducer.reducer;
// GET_PAYTRACE_CLIENT_ID