import React, { forwardRef, useEffect, useState } from 'react';
import { getFormattedDate } from '../../../../../Utils/DateUtils';
import { AppConstant } from '../../../../Assests/AppConstant';

const SchedulerDateInput = forwardRef(({ onClick, themeSelected, selectedDate, selectedDateFormat }, ref) => {

    return <button data-testid={AppConstant.dataTestId.dateSelection}
        onClick={onClick}
        className="d-flex flex-row justify-content-center align-items-center customInput border-1" ref={ref}>
        {
            <span data-testid={AppConstant.dataTestId.dayLabel}
                className={`dayText ${themeSelected}`}>
                {getFormattedDate(selectedDateFormat, selectedDate)}
            </span>
        }
        <div data-testid={AppConstant.dataTestId.calenderIcon} className="calenderContainer">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#006F9F" className="bi bi-calendar-check-fill" viewBox="0 0 16 16">
                <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zm-5.146-5.146-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
            </svg>
        </div>
    </button>
})

export default SchedulerDateInput;