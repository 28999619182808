import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import DateInput from './DateInput';
import { getCurrentDate } from '../../../Utils/DateUtils';
import { sagaActions } from '../../../sagas/sagaActions';
import { getAuthReducer } from '../../../redux/reducers/reducerSlices/AuthReducer';
import { removeInterceptor, interceptor } from '../../../redux/api/Interceptor';
import "./DatePicker.css"
import "react-datepicker/dist/react-datepicker.css";
import { AppConstant } from '../../Assests/AppConstant';
import { useLocation } from 'react-router-dom';
import { getRemoteConfigSelector } from '../../../redux/reducers/reducerSlices/RemoteConfigReducer';
import { getDefaultDashboardSelector } from '../../../redux/reducers/reducerSlices/DefaultDashboardReducer';
import { getUserSelector } from '../../../redux/reducers/reducerSlices/UserReducer';
import { usePrevious } from '../../Components/Common/CustomHooks/usePrevious';
import _ from 'lodash';
import { getReportsSelector } from '../../../redux/reducers/reducerSlices/ReportsReducerSlice';

const CustomDatePicker = (props) => {
    const { themeSelected } = props
    const [startDate, setStartDate] = useState(getCurrentDate());
    const [endDate, setEndDate] = useState('');
    const [intervalHandler, setIntervalHandler] = useState(null)
    const dispatch = useDispatch();
    const user = useSelector(getAuthReducer);
    const remoteConfig = useSelector(getRemoteConfigSelector);
    const { autoFetchExecutorInterval, autofetchDefDashboardInterval, autofetchCustomDashboardInterval, autofetchNotificatioInterval } = remoteConfig;
    const dashboard = useSelector(getDefaultDashboardSelector)
    const { dashboardType, dateRangeObj } = dashboard
    const location = useLocation();
    const userPermission = useSelector(getUserSelector);
    const { selectedWarehouse } = userPermission;
    const previousPoListInterval = usePrevious(intervalHandler);
    const reportsSelector = useSelector(getReportsSelector);
    const { selectedReportObj } = reportsSelector

    useEffect(() => {
        if (!dateRangeObj.start && !dateRangeObj.end) {
            setStartDate(new Date())
            setEndDate("")
        }
        else if (dateRangeObj.start === dateRangeObj.end) {
            setStartDate(new Date(dateRangeObj.start))
            setEndDate("")
        } else {
            setStartDate(new Date(dateRangeObj?.start))
            setEndDate(new Date(dateRangeObj?.end))
        }
    }, [])

    useEffect(() => {
        window.addEventListener("beforeunload", clearDateSelection);
        return () => {
            window.removeEventListener("beforeunload", clearDateSelection);
        };
    }, []);

    const clearDateSelection = (e) => {
        e.preventDefault();
        const dateRangeObjTemp = {
            'start': getCurrentDate(),
            'end': getCurrentDate()
        }
        dispatch({ type: sagaActions.UPDATE_DATE_RANGE, payload: dateRangeObjTemp })
    }

    useEffect(() => {
        const dateRangeObjTemp = {
            'start': '',
            'end': ''
        }
        if (getCurrentPath() === 'loadmanagement' || getCurrentPath() === 'scheduleappointments' || getCurrentPath() === 'sploadmanagement') {
            dateRangeObjTemp.start = startDate;
            dateRangeObjTemp.end = startDate;

        } else if (getCurrentPath() === 'analytics' || getCurrentPath() === 'reports' || getCurrentPath() === 'report') {
            dateRangeObjTemp.start = startDate;
            dateRangeObjTemp.end = endDate ? endDate : startDate;
        }
        dispatch({ type: sagaActions.UPDATE_DATE_RANGE, payload: dateRangeObjTemp })

    }, [startDate, endDate])

    useEffect(() => {

        if (user && user.sessionToken) {
            removeInterceptor()
            interceptor(user.sessionToken);
        }
        dispatch({ type: sagaActions.SORT_TRUCK_LIST, payload: AppConstant.commonStrings.arrange })
        dispatch({ type: sagaActions.RESET_LOAD_TRUCK_DETAILS })
        dispatch({ type: sagaActions.STORE_SELECTED_DATE, payload: { selectedDate: startDate } })
        if (dateRangeObj && dateRangeObj.start) {

            if (location.pathname === '/loadmanagement') {
                // fetchTruckList(true);
                // /* Auto Fetch PO-List after every 35 seconds */
                // if (intervalHandler) {
                //     setIntervalHandler(clearInterval(intervalHandler))
                // }
                // const settingInterval = setInterval(() => {
                //     /* To Resolve - http://10.10.0.10:8880/browse/WP-337
                //     More Door - Change Door consistently freezes screen after toggling between legend categories
                //     */
                //     const changeDoorPopupDOM = document.querySelector('#changeDoorPopup');
                //     if (changeDoorPopupDOM && !changeDoorPopupDOM.className.includes('show')) {
                //         if (intervalHandler !== previousPoListInterval) {
                //             fetchTruckList(false);
                //         }
                //     }
                // }, autoFetchExecutorInterval);
                // setIntervalHandler(settingInterval)
            } else if (location.pathname === '/analytics') {
                // fetchTruckList(false);
                let settingInterval = ''
                if (dashboardType === 'Custom') {
                    dispatch({
                        type: sagaActions.UPDATE_SELECTED_BUTTON, payload: {
                            key: 'dashboardType',
                            loadStr: 'Custom'
                        }
                    })
                    settingInterval = setInterval(() => {
                        intiateCustomDashboardAutoFetch()
                    },
                        autofetchCustomDashboardInterval);
                } else {
                    dispatch({
                        type: sagaActions.UPDATE_SELECTED_BUTTON, payload: {
                            key: 'dashboardType',
                            loadStr: 'Default'
                        }
                    })
                    settingInterval = setInterval(() => {
                        intiateDefaultDashboardAutoFetch()
                    },
                        autofetchDefDashboardInterval);
                }
                setIntervalHandler(settingInterval)
            }
        }
        return () => {
            clearInterval(intervalHandler)
        }
    }, [dateRangeObj])

    const fetchTruckList = (showLoader) => {

        // showLoader ? dispatch({ type: sagaActions.INTAITE_GET_TRUCK }) : ''
        // dispatch({ type: sagaActions.AUTO_FETCH_PO_LIST, payload: true });
        dispatch({ type: sagaActions.FETCH_TRUCK_LIST, payload: '' })
    }

    const intiateDefaultDashboardAutoFetch = () => {
        dispatch({ type: sagaActions.INTIATE_AUTO_FETCH, payload: true })
    }

    const intiateCustomDashboardAutoFetch = () => {
        dispatch({
            type: sagaActions.GET_DASHBOARD_WIDGET, payload: {
                warehouse_id: selectedWarehouse.ID,
                user_id: user.auth.attributes.email
            }
        })
    }

    const isDatePickerDisabled = () => {

        if (getCurrentPath() === 'analytics' || getCurrentPath() === 'loadmanagement' || getCurrentPath() === 'sploadmanagement' || getCurrentPath() === 'reports' || getCurrentPath() === 'scheduleappointments' || getCurrentPath() === 'report' && (selectedReportObj && selectedReportObj.ElementAccessID == '959' || selectedReportObj && selectedReportObj.ElementAccessID == '956' || selectedReportObj && selectedReportObj.ElementAccessID == '961')) {
            return false;
        } else {
            return true
        }
    }

    const getCurrentPath = () => {
        const path = window.location.pathname;

        if (path === '/analytics') {
            return 'analytics';
        } else if (path === '/loadmanagement') {
            return 'loadmanagement';
        } else if (_.includes(path, '/analytics/reports')) {
            return 'reports';
        } else if (path === '/scheduleappointments') {
            return 'scheduleappointments';
        } else if (path === '/report') {
            return 'report';
        } else if (path === '/sploadmanagement') {
            return 'sploadmanagement';
        }
    }

    const getCalenderLimit = () => {
        if (getCurrentPath() === 'analytics' || getCurrentPath() === 'reports') {
            return getCurrentDate().setMonth(getCurrentDate().getMonth() - 3);
        } else if (getCurrentPath() === 'report') {
            return getCurrentDate().setMonth(getCurrentDate().getMonth() - 12);
        } else if (getCurrentPath() === 'loadmanagement' || getCurrentPath() === 'scheduleappointments' || getCurrentPath() === 'sploadmanagement') {
            return null;
        }
    }

    const onDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }

    const setStartDateUI = () => {
        if (getCurrentPath() === 'analytics' || getCurrentPath() === 'reports' || getCurrentPath() === 'report') {
            return startDate
        } else {
            return ''
        }
    }

    const setEndDateUI = () => {
        if (getCurrentPath() === 'analytics' || getCurrentPath() === 'reports' || getCurrentPath() === 'scheduleappointments' || getCurrentPath() === 'report') {
            return endDate
        } else {
            return ''
        }
    }

    return (
        <div className='p-1 border border-3 rounded'>
            {
                <DatePicker

                    disabled={isDatePickerDisabled()}
                    maxDate={getCurrentDate()}
                    minDate={getCalenderLimit()}
                    calendarClassName={`datepicker ${themeSelected}`}
                    customInput={<DateInput
                        {...props}
                        disabled={isDatePickerDisabled()}
                        dateRangeObj={dateRangeObj}
                        currentLocation={getCurrentPath()}
                    />}
                    selected={startDate}
                    onChange={onDateChange}
                    startDate={setStartDateUI()}
                    endDate={setEndDateUI()}
                    selectsRange
                />
            }
        </div>
    )
}

export default CustomDatePicker
