import React from 'react';
import { AppConstant } from '../../../../../Assests/AppConstant';
import { Default, Mobile } from '../../../../../Lib/Media';

import './LoadHeader.css'

const LoadHeader = (props) => {
    const { themeSelected, viewChangeHandler } = props;

    return (
        <>
            <Default>
                <div className={`col loadHeading ps-2 py-1 ${themeSelected}`} >
                    <div className='text-start' data-testid={AppConstant.dataTestId.loadheadersTitle}>{AppConstant.loadManagement.loadheader}</div>
                </div>
            </Default>
            <Mobile>
                <div className={`col loadHeading ps-2 py-1 ${themeSelected}`} >
                    <div className='text-start' data-testid={AppConstant.dataTestId.loadheadersTitle}> {AppConstant.loadManagement.loadheader}</div>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        <button className="btn btn-primary me-md-2" type="button" data-testid={AppConstant.dataTestId.loadheadersBackBtn} onClick={() => viewChangeHandler()}>{AppConstant.loadManagement.loadheadersBackBtn}</button>
                    </div>
                </div>
            </Mobile>
        </>
    )
        ;
}

export default LoadHeader;