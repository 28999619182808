import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { AppConstant } from '../../../../../../../../Assests/AppConstant';
import NoData from '../../../DrillDownReports/Common/Nodata';
import CommonChart from '../../../Graphs/CommonChart';
import _ from 'lodash-es'

const AppointmentCompliance = (props) => {
    const { appointmentComplianceChart
        , themeSelected, showAsWidget } = props;
    const [showComplience, setshowComplience] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        if (appointmentComplianceChart && _.isArray(appointmentComplianceChart?.series)) {
            const sum = appointmentComplianceChart?.series.reduce((a, b) => {
                return a + b
            }, 0)
            if (sum > 0) {
                setshowComplience(true)
            } else {
                setshowComplience(false)
            }
        }
    }, [appointmentComplianceChart])

    const getSubRoot = () => {
        const status = localStorage.getItem("appointmentComplience")
        if (status === "0") {
            navigate('/analytics/reports/appointmentcompliance/ONTIME')
        } else if (status === "1") {
            navigate('/analytics/reports/appointmentcompliance/EARLY')
        } else if (status === "2") {
            navigate('/analytics/reports/appointmentcompliance/LATEGREATER')
        } else if (status === "3") {
            navigate('/analytics/reports/appointmentcompliance/LATELESS')
        }
    }

    return (
        <div className={`col gridSeparator ms-2 ${themeSelected} ${showAsWidget ? 'col-12 bg-transparent' : ''} `}>
            <div onClick={() => getSubRoot()} style={{ color: 'inherit', textDecoration: 'inherit' }}>
                <div className='col-12 pt-3'>
                    <span className='graphHeading'>{AppConstant.dashboard.defaultdashboard.appointmentCompliance.heading}</span><br />
                </div>
                <div className='d-flex flex-row pt-4 mt-3'>
                    <div className={`flex-grow-1 d-flex align-items-center justify-content-center responsive-chart detention-legend ${showAsWidget ? 'mt-5' : ''}`}>{
                        showComplience ? <CommonChart data={appointmentComplianceChart} /> : <NoData themeSelected={themeSelected} />}</div>
                </div>
            </div>
        </div>
    );
}

export default AppointmentCompliance;