import React, { useState, forwardRef, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import Header from "../../../Header/Header";
import Navigationbar from "../../../Navigationbar/Navigationbar";
import CustomButton from "../../../Common/CustomButton/CustomButton";
import InputWithTtile from "../../../Common/CustomInput/InputWithTtile";
import Popup from "../../../Common/Popup/Popup";
import _ from "lodash-es";
import SsPOListForUpload from "./SsPOList/SsPOListForUpload";

import CustomFileUploadWidget from "../../../Common/CustomFileUploadWidget/CustomFileUploadWidget";

import { CustomDynamicForm } from "../../../Common/CustomForm/CustomDynamicForm";
import { AppConstant } from "../../../../Assests/AppConstant";
import { sagaActions } from "../../../../../sagas/sagaActions";
import { getDockListSelector } from "../../../../../redux/reducers/reducerSlices/DockListReducer";
import { getUserSelector } from "../../../../../redux/reducers/reducerSlices/UserReducer";
import { getPoSSselector } from "../../../../../redux/reducers/reducerSlices/POSmartSchedulerReducer";

import { usePrevious } from "../../../Common/CustomHooks/usePrevious";
import { showAlert } from "../../../../Assests/Utility";
import { toast } from "react-toastify";
import "./CreatePo.css";
import ScheduleProWrapper from "../../../SmartSchedule/SchedulePro/ScheduleProWrapper";
import SsPOListForUploadSS from "./SsPOList/SsPOListForUploadSS";

const BulkUploadPOSS = (props) => {
  const dispatch = useDispatch();
  const { themeSelected } = props;
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const userPermission = useSelector(getUserSelector);
  const { selectedWarehouse } = userPermission;


  const divRef = useRef(null);
  const pOSS = useSelector(getPoSSselector);




  const { poSSArr, operationObj } = pOSS;
  const [typeShow, setTypeShow] = useState("success");
  const [uploadSuccessData, setUploadSuccessData] = useState([]);
  const [uploadFailData, setUploadFailData] = useState([]);


  useEffect(() => {
    if (operationObj.type) {
      switch (operationObj.type) {

        case "Upload PO":
          if (operationObj.status) {
            handleApiResponse(
              AppConstant.notifications.dataAddedSuccess,
              AppConstant.smartScheduler.poSection.uploadPoSuccess
            );
          } else {
            handleApiResponse(
              AppConstant.commonStrings.failure,
              AppConstant.smartScheduler.poSection.uploadPoFailure +
              `: ${operationObj.message}`
            );
          }
          break;
        default:
      }
    }
  }, [operationObj]);

  const handleApiResponse = (result, message) => {
    if (result === AppConstant.notifications.dataAddedSuccess) {
      showAlert(toast.TYPE.SUCCESS, message, themeSelected);
      if (poSSArr) {
        setUploadSuccessData(poSSArr.successRecord);
        setUploadFailData(poSSArr.failedRecord);
      }

      setShowModal(false);

    } else {
      showAlert(toast.TYPE.ERROR, message, themeSelected);
    }
    setLoading(false);
    dispatch({ type: sagaActions.RESET_SS_PO_OPERATION_OBJ });
  };

  const onFileChange = async (fileData, name) => {
    const fd = new FormData();
    fd.append("file", fileData);
    fd.append("IDWarehouse", selectedWarehouse.ID);
    fd.append("zipCode", selectedWarehouse.Zip)
    const operationObj = {
      type: "Upload PO",
      status: "",
    };
    setLoading(true);
    dispatch({
      type: sagaActions.SS_PO_EXCEL_UPLOAD_ACTION,
      payload: fd,
      operationObj: operationObj,
    });
  };

  const showRecords = (type) => {

    setTypeShow(type);
  }
  return (
    <ScheduleProWrapper>
      <div className="container-fluid m-1 bulk-upload-wrapper">
        {/* <Header {...props} />
      <Navigationbar {...props} /> */}
        <div className="d-flex justify-content-between">
          <div className="mt-3 align-items-center">
            <div className="d-flex mb-2 align-items-center">
              <Link
                className="nav-link text-center"
                aria-current="page"
                to="/pomanagement"
              >
                <div className="d-flex align-items-center fw-bold f-16">
                  <span className="font-default me-3 ">
                    <i className="fa-solid fa-arrow-left fa-2x"></i> </span> Bulk Upload
                </div>
              </Link>
              &nbsp;&nbsp;&nbsp;

            </div>
          </div>
        </div>
        <div className="mt-3 " ref={divRef}>
          <div className="d-flex justify-content-between my-3">
            <div className="container-fluid ">
              <div className="navbar-expand w-100" id="navbarNav">
                <div
                  className="d-flex justify-content-start"
                  style={{ borderRadius: "10px" }}
                >
                  <button
                    onClick={() => showRecords("success")}
                    className={typeShow === "success" ? "selected-button" : "unselected-button"}>
                    {'Success List'}
                  </button>
                  <button
                    onClick={() => showRecords("failed")}
                    className={typeShow === "failed" ? "selected-button" : "unselected-button"}>
                    {' Failed List'}
                  </button>
                </div>
              </div>
            </div>


            <div className='btn-highlighted d-flex justify-content-center align-items-center' >
              <form>
                <div className='d-flex w-auto'>
                  <label for="fileUploadInput" className='cursor-pointer'>Upload</label>
                  <input
                    className={`ps-2 pe-1 text-muted border-0 hidden cursor-pointer`}
                    type="file"
                    id='fileUploadInput'
                    name={'file'}
                    accept={".xlsx, .csv"}
                    onInput={(data) => onFileChange(data.target.files[0], data.target.name)}
                  />
                </div>
                {/* <label className='flex-column warningText text-danger cursor-pointer' for="fileUploadInput" >{warningText}</label> */}
              </form>
            </div>

            <a
              className="btn-download-template-ss ms-3"
              href={AppConstant.smartScheduler.poSection.downloadPOTemplateLink}
            >
              <i className="fa-solid fa-download"></i>&nbsp; {AppConstant.smartScheduler.poSection.downloadPOTemplate}
            </a>



            {/* 
            <CustomFileUploadWidget
              title={AppConstant.smartScheduler.poSection.browseFilesTitle}
              placeholderText={
                AppConstant.smartScheduler.poSection.uploadPOTemplate
              }
              warningText={
                AppConstant.smartScheduler.poSection.warningTextPOUplaod
              }
              sizeConfig={AppConstant.help.imageSizeConfig}
              onSelectFile={(fileData, fileName) =>
                onFileChange(fileData, fileName)
              }
              isLoading={loading}
              name="file"
              acceptFiles={".xlsx, .csv"}
              addedClassName={"inputFileUploadCreatePO"}
              clickHandler={() => ""}
            /> */}
          </div>
          {typeShow === "success" ? <SsPOListForUploadSS bodyData={uploadSuccessData} type={typeShow} themeSelected={themeSelected} /> : <SsPOListForUploadSS bodyData={uploadFailData} type={typeShow} themeSelected={themeSelected} />

          }

        </div>
      </div>
    </ScheduleProWrapper>
  );
};

export default BulkUploadPOSS;
