import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AppConstant } from '../../../../../../../../Assests/AppConstant';
import CommonChart from '../../../Graphs/CommonChart';
import moment from 'moment';

const DoorStatus = (props) => { 
    const navigate = useNavigate();
    const { doorStatusdonut, themeSelected } = props;
    
    const getSubRoot = () => {
        const status = localStorage.getItem("doorStatus")
        if (status === "0") {
            navigate('/analytics/reports/doorstatus/available')
        } else if (status === "1") {
            navigate('/analytics/reports/doorstatus/occupied')
        } else if (status === "2") {
            navigate('/analytics/reports/doorstatus/blocked')
        } else if (status === "3") {
            navigate('/analytics/reports/doorstatus/dropped')
        }else if (status === "4") {
            navigate('/analytics/reports/doorstatus/floor')
        } else if (status === "5") {
            navigate('/analytics/reports/doorstatus/trailer')
        } else if (status === "6") {
            navigate('/analytics/reports/doorstatus/cleaning')
        } else if (status === "7") {
            navigate('/analytics/reports/doorstatus/outoforder')
        } else if (status === "8") {
            navigate('/analytics/reports/doorstatus/inoperable')
        }
    
    }

    return ( 
        <div className={`col-12 me-2 ${themeSelected} bg-transparent`} style={{ marginRight: '10px' }}>
            <div onClick={() => getSubRoot()} style={{ color: 'inherit', textDecoration: 'inherit', cursor: 'pointer'}}>
            {/* <Link to="/analytics/reports/doorstatus" style={{ color: 'inherit', textDecoration: 'inherit'}}> */}
                    <div className='col-12 pt-3 '>
                    <span className='graphHeading'>{AppConstant.dashboard.defaultdashboard.doorStatus.heading}</span><br />
                    <span  className='boldText'>{ moment().format('dddd') + " " + moment().format('DD') + " " + moment().format('MMM')}</span>
                    <br />
                    </div>
                    <div className='d-flex flex-row justify-content-around doorStatusChart'>
                        <div className="responsive-chart">{doorStatusdonut ? <CommonChart data={doorStatusdonut} /> : ''}</div>
                </div>
                </div>
                {/* </Link> */}
                </div>
     );
}
 
export default DoorStatus;