import React, { useState } from "react";
import "./carousel-styles.css";
/**
 * This component renders carousel for Table row
 * @param {*} props 
 * items = data
 * @returns 
 */
export const Carousel = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { items } = props;

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = 0;
    } else if (newIndex >= items.length) {
      newIndex = items.length - 1;
    }
    setActiveIndex(newIndex);
  };
  /**
   * Show next content
   */
  const nextContent = () => {
    const newIndex = (activeIndex + 1) % items.length;
    updateIndex(newIndex);
  };
/**
   * Show previous content
   */
  const prevContent = () => {
    const newIndex = (activeIndex - 1 + items.length) % items.length;
    updateIndex(newIndex);
  };
  return (
    <div className="carousel">
      <div className="fixed-wrapper h-100">
        <div
          className="inner h-100"
          style={{
            transform: `translate(-${activeIndex * 100}%)`,
          }}
        >

          {items.map((item) => {
            return (
              <div className="cc-carousel-item h-100">
                {item}
              </div>
            )
          })}
          {/* {items} */}

        </div>
        {items.length > 1 &&
          <>
            <div className="cc-indicator">{"PO: " + (activeIndex + 1) + " of " + items.length}</div>
            <div className="carousel-btns">
              <button
                onClick={nextContent}
                id="next_btn"
                className={activeIndex === items.length - 1 ? "disabled" : "pointer"}
                disabled={activeIndex === items.length - 1}
              >
                <span>&#10097;</span>
              </button>
              <button
                onClick={prevContent}
                id="prev_btn"
                className={activeIndex === 0 ? "disabled" : "pointer"}
                disabled={activeIndex === 0}
              >
                <span>&#10096;</span>
              </button>
            </div>
          </>}
      </div>
    </div>
  );
};
