const donutType = {
    unloadingHoursdonut: 'UNLOADINGHOURSDONUT',
    unloadingHoursTruck: 'UNLOADINGHOURSTRUCK',
    unloadingHoursPallet: 'UNLOADINGHOURSPALLET',
    detentionStatusDonut: 'DETENTIONSTATUSDONUT',
    detentionStatusLine: 'DETENTIONSTATUSLINE',
    appointmentCompliance: 'APPOINTMENTCOMPLIANCE',
    top10LateLoadsData: 'TOP10LATELOADSDATA',
    unloadingTypeSummary: 'UNLOADINGTYPESUMMARY',
    doorUsageSummary: 'DOORUSAGESUMMARY',
    doorStatus: 'DOORSTATUS',
    unscheduledPOs: 'UNSCHEDULEDPOS',
    appointmentTrackerDonut: 'APPOINTMENTTRACKERDONUT',
    faciltyRatingsColumnGraph: 'FACILITYRATINGSCOLUMNGRAPH',
    userRatingsColumnGraph: 'USERRATINGSCOLUMNGRAPH',
    doorAssignmentColumnStackedGraph: 'DOORASSIGNMENTCOLUMNSTACKEDGRAPH',
    AppointmentReportSummary: 'APPOINTMENTREPORTSUMMARY'
}

const unloadingHoursGraphConstants = {

    colors: ['#19BAFF', '#0073A5',],
    legend: {
        show: true,
        position: 'right',
        offsetY: 25,
        // horizontalAlign: 'center', 
    },
    labels: ["Truck", "Pallets"],
    width: 350

}

const graphThemeLight = {
    mode: 'light',
    palette: 'palette1',
    monochrome: {
        enabled: false,
        color: '#255aee',
        shadeTo: 'light',
        shadeIntensity: 0.65
    },
}
const graphThemeDark = {
    mode: 'dark',
    palette: 'palette1',
    monochrome: {
        enabled: false,
        color: '#255aee',
        shadeTo: 'dark',
        shadeIntensity: 0.65
    },
}
const createChartsProps = (data, type, theme, graphData) => {
    let newTheme = theme === 'light-mode' ? graphThemeLight : graphThemeDark;

    switch (type) {
        case donutType.unloadingHoursdonut:
            // const { colors, legend, labels, width } = unloadingHoursGraphConstants
            // donutdata.options.colors = colors;
            // donutdata.options.legend = legend;
            // donutdata.options.labels = labels;
            graphData.options.theme = newTheme;
            graphData.series = data;
            // donutdata.width = width;
            break;
        case donutType.unloadingHoursTruck:
            // let lineDataTruck = { ...lineData }
            graphData.options.theme = newTheme;
            graphData.series = [{
                name: "Trucks",
                data: data
            }]
            break;
        case donutType.unloadingHoursPallet:
            // let lineDataPallet = { ...lineData }
            graphData.options.theme = newTheme;
            graphData.series = [{
                name: "Pallet",
                data: data
            }]
            break;
        case donutType.detentionStatusDonut:
            // let detentionDonutdataTemp = { ...detentionDonutdata }
            graphData.options.theme = newTheme;
            graphData.series = data;
            break;
        case donutType.detentionStatusLine:
            // let detentionLineDataTemp = { ...detentionLineData }
            graphData.options.theme = newTheme;
            graphData.series = data
            break;
        case donutType.appointmentCompliance:
            graphData.options.theme = newTheme;
            graphData.series = data
            break;
        case donutType.unloadingTypeSummary:
            graphData.options.theme = newTheme;
            graphData.series = data
            break;
        case donutType.doorUsageSummary:
            graphData.options.theme = newTheme;
            graphData.series = data
            break;
        case donutType.doorStatus:
            graphData.options.theme = newTheme;
            graphData.series = data
            break;
        case donutType.unscheduledPOs:
            graphData.options.theme = newTheme;
            graphData.series = data
            break;
        case donutType.appointmentTrackerDonut:
            graphData.options.theme = newTheme;
            graphData.series = data
            break;
        case donutType.faciltyRatingsColumnGraph:
            graphData.options.theme = newTheme;
            if (data) {
                const { series, categories } = data
                graphData.series = series;
                graphData.options.labels = categories
                graphData.options.xaxis = {
                    categories: categories
                }
            }
            break;
        case donutType.doorAssignmentColumnStackedGraph:
            graphData.options.theme = newTheme;
            if (data) {
                const { series, categories } = data
                graphData.series = series;
                // graphData.options.labels = categories
                graphData.options.xaxis = {
                    categories: categories
                }
            }
            break;
        case donutType.userRatingsColumnGraph:
                graphData.options.theme = newTheme;
                if (data) {
                    const { series, categories } = data
                    graphData.series = series;
                    graphData.options.labels = categories
                    graphData.options.xaxis = {
                        categories: categories
                    }
                }
                break;
        case donutType.AppointmentReportSummary:
                graphData.options.theme = newTheme;
                graphData.series = data
                break;
        default:
            break;
    }
    return { ...graphData }


}

const unloadingHoursAPIResponseFormatter = (res) => {
    let formattedRes = []
    Object.entries(res).forEach(
        ([key, value]) => {
            let obj = {}
            switch (key) {
                case 'within_30min_detention':
                    obj = {
                        name: 'Within 30 min of Detention',
                        data: value
                    }
                    formattedRes.push(obj)

                    break;
                case 'not_in_detention':
                    obj = {
                        name: 'Not in Detention',
                        data: value
                    }
                    formattedRes.push(obj)

                    break;
                case 'fully_in_detention':
                    obj = {
                        name: 'Fully in Detention',
                        data: value
                    }
                    formattedRes.push(obj)

                    break;

                default:
                    break;
            }
        }
    );
    return formattedRes
}

const detentionInPercentage = (res) => {

    let formattedRes = []
    Object.entries(res).forEach(
        ([key, value]) => {
            let obj = {}
            switch (key) {
                case 'per_30_of_detention':
                    obj = {
                        name: 'Percent Nearing Detention',
                        data: parseInt(value)
                    }
                    formattedRes.push(obj)
                    break;
                case 'per_fully_in_detention':
                    obj = {
                        name: 'Percent Fully in Detention',
                        data: parseInt(value)
                    }
                    formattedRes.push(obj)
                    break;
                case 'per_not_in_detention':
                    obj = {
                        name: 'Percent not in Detention',
                        data: parseInt(value)
                    }
                    formattedRes.push(obj)
                    break;

                default:
                    break;
            }
        }
    );
    return formattedRes
}

const doorStatusResponseFormatter = (res) => {

    let formattedRes = []

    Object.entries(res).forEach(
        ([key, value]) => {
            let obj = {}

            switch (value.DoorStatus) {
                case 'Operable':
                    obj = {
                        name: 'Available',
                        data: parseInt(value.DoorStatusCount)
                    }
                    formattedRes.push(obj)
                    break;
                case 'Occupied':
                    obj = {
                        name: 'Occupied',
                        data: parseInt(value.DoorStatusCount)
                    }
                    formattedRes.push(obj)
                    break;
                case 'Blocked':
                    obj = {
                        name: 'Blocked',
                        data: parseInt(value.DoorStatusCount)
                    }
                    formattedRes.push(obj)
                    break;
                case 'Inoperable':
                    obj = {
                        name: 'Inoperable',
                        data: parseInt(value.DoorStatusCount)
                    }
                    formattedRes.push(obj)
                    break;
                case 'Blocked - Backhaul/Dropped':
                    obj = {
                        name: 'Blocked - Backhaul/Dropped',
                        data: parseInt(value.DoorStatusCount)
                    }
                    formattedRes.push(obj)
                    break;
                case 'Blocked - Floor':
                    obj = {
                        name: 'Blocked - Floor',
                        data: parseInt(value.DoorStatusCount)
                    }
                    formattedRes.push(obj)
                    break;
                case 'Blocked - Shipping Trailer':
                    obj = {
                        name: 'Blocked - Shipping Trailer',
                        data: parseInt(value.DoorStatusCount)
                    }
                    formattedRes.push(obj)
                    break;
                case 'Cleaning':
                    obj = {
                        name: 'Cleaning',
                        data: parseInt(value.DoorStatusCount)
                    }
                    formattedRes.push(obj)
                    break;
                case 'Out of Order':
                    obj = {
                        name: 'Out of Order',
                        data: parseInt(value.DoorStatusCount)
                    }
                    formattedRes.push(obj)
                    break;
                default:
                    break;
            }
        }
    );
    return formattedRes
}

const appointmentComplianceResponseFormatter = (res) => {
    let formattedRes = []
    Object.entries(res).forEach(
        ([key, value]) => {
            let obj = {}
            switch (key) {
                case 'Early':
                    formattedRes.push(value || 0)

                    break;
                case 'Late_Above_1hr':
                    formattedRes.push(value || 0)
                    break;
                case 'Late_Below_1hr':
                    formattedRes.push(value || 0)
                    break;
                case 'On_Time':
                    formattedRes.push(value || 0)
                    break;

                default:
                    break;
            }
        }
    );
    return formattedRes

}

const unloadingTypeSummaryAPIResponseFormatter = (res) => {
    let formattedRes = []
    Object.entries(res).forEach(
        ([key, value]) => {
            formattedRes.push(value)
        }
    );
    return formattedRes
}
const formatHeatMapData = (res) => {
    const arr = res.map((el, i) => {
        const obj = {
            name: el.Door,
            data: [
                {
                    x: el.TruckCount,
                    y: el.PalletsCount
                }
            ]
        }
        return obj
    })

    return arr
}

const formatDoorUsageData = (res) => {

    const trucksArr = res.map((obj) => {
        return {
            x: obj.Door,
            y: obj.TruckCount
        }
    })

    const palletsArr = res.map((obj) => {
        return {
            x: obj.Door,
            y: obj.PalletsCount
        }
    });

    const series = {
        'trucks': {
            name: 'Trucks',
            data: [...trucksArr]
        },
        'pallets': {
            name: 'Pallets',
            data: [...palletsArr]
        },
    }



    return series

}

function numberWithCommas(number) {
    var parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}

const unscheduledPOsAPIResponseFormatter = (res) => {

    let formattedRes = []
    Object.entries(res).forEach(
        ([key, value]) => {
            let obj = {}
            switch (value.Type) {
                case 'UnscheduledPOs':
                    if (value.POStatus === 'Pending') {
                        obj = {
                            name: "UnscheduledPO's (Pending)",
                            data: parseInt(value.POCount)
                        }
                    } else {
                        obj = {
                            name: "UnscheduledPO's (Approved)",
                            data: parseInt(value.POCount)
                        }
                    }

                    formattedRes.push(obj)
                    break;
                case 'ScheduledPOs':
                    obj = {
                        name: "ScheduledPO's",
                        data: parseInt(value.POCount)
                    }
                    formattedRes.push(obj)
                    break;

                default:
                    break;
            }
        }
    );
    return formattedRes

}

export { donutType, createChartsProps, unloadingHoursAPIResponseFormatter, appointmentComplianceResponseFormatter, numberWithCommas, detentionInPercentage, unloadingTypeSummaryAPIResponseFormatter, doorStatusResponseFormatter, formatHeatMapData, formatDoorUsageData, unscheduledPOsAPIResponseFormatter }
