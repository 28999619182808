import React from 'react';
import { useSelector } from 'react-redux';
import Header from '../../../Header/Header';
import Navigationbar from '../../../Navigationbar/Navigationbar';
import { getUserSelector } from '../../../../../redux/reducers/reducerSlices/UserReducer'
import './CarrierLandingScreen.css';

const CarrierLandingScreen = (props) => {
    const user = useSelector(getUserSelector);
    const { userAccountDetails } = user;
    const { FirstName } = userAccountDetails;
    return (
        <div>
            <Header {...props} />
            <Navigationbar {...props} />
            <div className='p-4 fs-6 lh-lg landingScreenContainer'>
                <p className='m-0 p-0'>{`Hi ${FirstName},`}</p>
                <p className='m-0 p-0 fs-5'>Welcome to FreightSmith SchedulePro!</p>
                <p>
                    Get the most out of your subscription! FreightSmith ScheduePro includes...
                    <br />
                    <ul>
                        <li className='ms-4'>Real Time Digital Appointment Scheduling</li>
                        <li className='ms-4'>Allows for Modifications, Updates and Provides Schedule Visibility</li>
                        <li className='ms-4'>Simple and Streamlined User Experience</li>
                        <li className='ms-4'>Includes a Feature to Preset Reoccurring Appointment</li>
                        <li className='ms-4'>Allows for Users with Managed Permission Levels</li>
                        <li className='ms-4'>Auto Generated Email Confirmation of Scheduled Appointments and Changes</li>
                    </ul>
                    <p className='d-flex'>
                        For customer support, contact
                        <a className="ms-1 cursor-pointer" href={'mailto:info@freightsmith.net'}>info@freightsmith.net</a>
                        &nbsp;or&nbsp;
                        <div className="d-flex">call <a className="text-dark ms-1 fw-bold contactNum" href="tel:+1.844.586.7729">1.844.586.7729</a></div>
                    </p>
                    <div className='seperatorLanding' />
                    <p className='text-danger'>
                        <b>Important Note:</b> Please be sure to check your junk/spam email box when you get started. If you find an email from us in junk/spam, be sure to add it to your approved sender or domains. This can usually be done by right clicking the email in your junk/spam box for more options, then select the most appropriate option to add the domain or email address to your approved senders list.
                    </p>
                    <p className='text-danger'>
                        Appointment confirmation and update emails will come from <b className='text-decoration-underline'>schedulepro@freightsmith.net</b>
                    </p>
                    <p>Check out all FreightSmith products here:</p>
                    <ul>
                        <li className='ms-4'><a target="_blank" className="ms-1 cursor-pointer" href={'https://freightsmith.net/ims/'}>FreightSmith IMS</a></li>
                        <li className='ms-4'><a target="_blank" className="ms-1 cursor-pointer" href={'https://freightsmith.net/app/'}>FreightSmith Mobile App</a></li>
                        <li className='ms-4'><a target="_blank" className="ms-1 cursor-pointer" href={'https://freightsmith.net/schedulepro/'}>FreightSmith SchedulePro</a></li>
                    </ul>
                </p>
            </div>
        </div>
    )
}

export default CarrierLandingScreen;