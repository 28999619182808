import React from 'react';
import { AppConstant } from '../../../../../../../../../Assests/AppConstant';

const Top10LateLoadsTitle = (props) => {
    const { themeSelected, top10LoadSelection } = props;
    const appConstantPath = AppConstant.dashboard.customDashboard.top10LateLoads.tableHeaderTitle;

    return (
        <thead className={`text-center align-middle truck-list-title ${themeSelected} lateLoadsHeader`}>
            <tr>
                <th scope="col">
                    <span>{appConstantPath.serial_num}</span>
                </th>
                {
                    top10LoadSelection === 'VENDOR' ?
                        <th scope="col">
                            <span>{appConstantPath.vendor}</span>
                        </th> :

                        <th scope="col">
                            <span>{appConstantPath.carrier}</span>
                        </th>
                }
                  {/* Asked by Rajesh to comment - 10/13/2023 - EOD Call */}
                {/* <th scope="col">
                    <span>{appConstantPath.appointment_time}</span>
                </th>
                <th scope="col">
                    <span>{appConstantPath.check_in_time}</span>
                </th> */}
                <th scope="col" >
                    <span>{appConstantPath.fs_score_title}</span>
                </th>
            </tr>
        </thead>
    )
}

export default Top10LateLoadsTitle;