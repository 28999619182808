import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash-es';
import RenewSubscriptionComponent from './RenewSubscriptionComponent/RenewSubscriptionComponent';
import LeftInfoWithFormContainer from '../Container/LeftInfoWithFormContainer/LeftInfoWithFormContainer';
import { getUserSelector } from '../../../../redux/reducers/reducerSlices/UserReducer';
import { AppConstant } from '../../../Assests/AppConstant'
import { useNavigate } from 'react-router-dom';

/**
 * This component render renew carrier subscription form
 * @param {*} props 
 * @returns 
 */
const RenewCarrierSubscriptionsForm = (props) => {

    const { themeSelected = 'light-mode' } = props;
    const [formName, setFormName] = useState(AppConstant.smartScheduler.subscription.renewalSubscription);
    const [showFormModal, setShowFormModal] = useState(true);
    const [bgImage, setBgImage] = useState('');
    const userPermission = useSelector(getUserSelector);
    const { branding } = userPermission;
    const [warehouseLogo, setWarehouseLogo] = useState('');
    const navigate = useNavigate();

    /** 
     * useEffect for branding data
     */
    useEffect(() => {
        if (!_.isEmpty(branding)) {
            const { IsBranding, WarehouseLogo, BackgroundImage } = branding
            if (IsBranding) {
                setBgImage(BackgroundImage)
                setWarehouseLogo(WarehouseLogo)
            }
        }
    }, [branding])
    /**
     * function to close popup
     */
    const closeHelpModal = () => {
        setShowFormModal(false);
        navigate('/');
    };

    /**
     * function to render renew subscription component 
     * @returns 
     */
    const getCarrierSignUpForm = () => {
        return ( <RenewSubscriptionComponent {...props}/>)
    }

    return (
        <div>
            <div className='bgContainerLeftInfo'>
                <img className={'bgContainerImageLeftInfo'} src={bgImage ? bgImage : AppConstant.commonStrings.bkgImgLink} alt='Background Image' />
                <div className='parentContainerLeftInfo '>
                    <LeftInfoWithFormContainer warehouseLogo={warehouseLogo} formName={formName} closeHelpModal={() => closeHelpModal()} displayComponent={() => getCarrierSignUpForm()} />
                </div>
            </div>
        </div>
    )
}

export default RenewCarrierSubscriptionsForm;