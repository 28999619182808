import _ from "lodash";
import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

/**
 * Navidation component for mobile
 * @param {*} props 
 * @returns 
 */
const PrimaryNavigationMobile = (props) => {
  const {
    pNavList,
    getReportsArrayDropDown,
    location,
    showCustomSubNav,
    setShowCustomSubNav,
    onReportSelected,
    clickHandler,
  } = props;
  const catMenu = useRef(null);
  const navigate = useNavigate();
  const closeOpenMenus = (e) => {
    if (
      catMenu.current &&
      showCustomSubNav &&
      !catMenu.current.contains(e.target)
    ) {
      setShowCustomSubNav(false);
    }
  };
  document.addEventListener("mousedown", closeOpenMenus);
  return (
    <ul className="nav-list-mobile">
      {_.size(pNavList) &&
        pNavList.map((el, index) => {
          if (
            el.NavigationType === "Primary" &&
            el.PageAccess &&
            el.PageName !== "Reports"
          ) {
            return (
              <li
                className={
                  location?.pathname === el?.PageUrl
                    ? "d-flex align-items-center w-100 nav-list-selected"
                    : "d-flex align-items-center w-100"
                }
                key={el.PageDesc}
                onClick={() => {
                  if (el.PageName === "Reports") {
                    setShowCustomSubNav(!showCustomSubNav);
                  } else {
                    clickHandler(el);
                    navigate(el.PageUrl);
                  }
                }}
              >
                {/* To Show Icons */}
                {el?.FeatureIcon && (
                  <i className={el?.FeatureIcon} aria-hidden="true"></i>
                )}
                {el.PageName !== "Reports" ? (
                  <a className=" nav-link text-centerw-100" aria-current="page">
                    <div className="ms-1">{el.PageDesc}</div>
                  </a>
                ) : (
                  <div className="ms-1 d-flex justify-content-between w-100">
                    <span>{el.PageDesc}</span>
                    <span>
                      <i className="fa-solid fa-angle-right"></i>
                    </span>
                  </div>
                )}
              </li>
            );
          } else if (
            el.NavigationType === "Primary" &&
            el.PageAccess &&
            el.PageName === "Reports"
          ) {
            return (
              <>
                <li
                  className={
                    location?.pathname === el?.PageUrl
                      ? "d-flex align-items-center w-100 nav-list-selected"
                      : "d-flex align-items-center w-100"
                  }
                  key={el.PageDesc}
                  onClick={() => {
                    if (el.PageName === "Reports") {
                      setShowCustomSubNav(!showCustomSubNav);
                    } else {
                      clickHandler(el);
                      navigate(el.PageUrl);
                    }
                  }}
                >
                  {/* To Show Icons */}
                  {el?.FeatureIcon && (
                    <i className={el?.FeatureIcon} aria-hidden="true"></i>
                  )}
                  {el.PageName !== "Reports" ? (
                    <a
                      className=" nav-link text-centerw-100"
                      aria-current="page"
                    >
                      <div className="ms-1">{el.PageDesc}</div>
                    </a>
                  ) : (
                    <div className="ms-1 d-flex justify-content-between w-100">
                      <span>{el.PageDesc}</span>
                      <span>
                       {!showCustomSubNav ? <i className="fa-solid fa-angle-down"></i>:
                       <i className="fa-solid fa-angle-up"></i>}
                      </span>
                    </div>
                  )}
                </li>
                {el.featureRole.map((feature, index) => {
                  if (feature.Permission) {
                    return (
                      <>
                        {showCustomSubNav && (
                          <li
                            className="d-flex w-100"
                            key={feature?.ElementAccessID}
                            onClick={(e) => {
                              navigate(el.PageUrl);
                              onReportSelected(e, feature);
                            }}
                          >
                            {el?.FeatureIcon ? (
                              <i
                                className={el?.FeatureIcon}
                                aria-hidden="true"
                              ></i>
                            ) : (
                              <i
                                className="fa-solid fa-globe"
                                aria-hidden="true"
                              ></i>
                            )}
                            &nbsp;{feature?.ElementAccess}
                          </li>
                        )}
                      </>
                    );
                  }
                })}
              </>
            );
          }
        })}
    </ul>
  );
};

export default PrimaryNavigationMobile;
