import React from 'react';
import MyApptsTableRow from '../../../MyAppointments/Components/MyApptsTableRow/MyApptsTableRow';
import './ShowApptCard.css';

const ShowApptCard = (props) => {
    const apptCard = {
        'poNumber': '1',
        'warehouseName': 'Albertsons Tollesons',
        'warehouseZipCode': '54321',
        'vendorName': 'Tracy Groceries',
        'commodity': 'Groceries',
        'buyersName': 'FS Trucking',
        'appointmentDateTime': '10 May 2023, 7:00 AM',
        'isScheduled': false
    }

    const renderApptCard = () => {
        return (
            <div className={`m-2 mx-2 card p-2 shadow  bg-body rounded border-0`}>
                <MyApptsTableRow
                    rowData={apptCard}
                />
            </div>
        )
    }

    return (
        <div className=''>
            <div className='myAppointmentsTableContainer m-2'>
                {renderApptCard()}
            </div>
        </div>
    )
}

export default ShowApptCard;