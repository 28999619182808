import _ from 'lodash';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDefaultDashboardSelector } from '../../../../../../../../../redux/reducers/reducerSlices/DefaultDashboardReducer';
import { getUserSelector } from '../../../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { sagaActions } from '../../../../../../../../../sagas/sagaActions';
import { AppConstant } from '../../../../../../../../Assests/AppConstant';
import DrilldownTable from '../../Common/DrilldownTable';

const DrillDownUnscheduledPos = (props) => {
    const { themeSelected, loaderArray, subRoot, dockIdArr } = props;
    const dispatch = useDispatch();
    const [drilldownData, setDrilldownData] = useState([]);
    const defaultDashboard = useSelector(getDefaultDashboardSelector);
    const { unScheduledPOSummaryDrillDown, arrangeOrder, arrangeKey, initiateDrillDownReport, dateRangeObj } = defaultDashboard;
    const userPermission = useSelector(getUserSelector);
    const {selectedWarehouse} = userPermission;

    let  unloadingTypeSummaryTitleDD = [
        { id: 'Control', name: 'Control' },
        { id: 'CheckInTime', name: 'Checked in' },
        { id: 'ScheduledTime', name: 'Scheduled Time' },
        { id: 'DockName', name: 'Dock' },
        { id: 'Unscheduled_PO', name: 'Unscheduled PO' },
        { id: 'Unscheduled_POStatus', name: 'Unscheduled Po Status' },
        { id: 'UnscheduledType', name: 'Unscheduled Type' },
        { id: 'ScheduledPO', name: 'Scheduled PO' },
        { id: 'ScheduledPOStatus', name: 'Scheduled Po Status' },
        { id: 'ScheduledType', name: 'Scheduled Type' },
    ]
    const createPayload = () => {
        return {dock_id: dockIdArr.toString() === '' ? null : dockIdArr.toString(), isDrillDown: '1', warehouse_id: selectedWarehouse.ID}
    }
    useEffect(() => {
        dispatch({ type: sagaActions.INITIATE_DRILLDOWN_REPORT, payload: true})
        dispatch({ type: sagaActions.GET_UNSCHEDULED_POS_DRILLDOWN, payload: createPayload() });
    }, [])

    useEffect(() => {
        dispatch({ type: sagaActions.INITIATE_DRILLDOWN_REPORT, payload: true})
        dispatch({ type: sagaActions.GET_UNSCHEDULED_POS_DRILLDOWN, payload: createPayload() });
    }, [dateRangeObj])

    useEffect(() => {
        if (unScheduledPOSummaryDrillDown && unScheduledPOSummaryDrillDown.length) {
            let data = formatDrillDownData(unScheduledPOSummaryDrillDown)
            setDrilldownData(data)
            dispatch({ type: sagaActions.INITIATE_DRILLDOWN_REPORT, payload: false})
        }
    }, [unScheduledPOSummaryDrillDown])

    const formatDrillDownData = (data) => {
        return data.map(el => {
            return {
                id: el.ID,
                0: el.Control || '-',
                1: el.CheckInTime || '-',
                2: el.ScheduledTime || '-',
                3: el.DockName || '-',
                4: el.Unscheduled_PO || '-',
                5: el.Unscheduled_POStatus ||'-',
                6: el.UnscheduledType || '-',
                7: el.ScheduledPO || '-',
                8: el.ScheduledPOStatus || '-',
                9: el.ScheduledType ||  '-',
            }
        })
    }

    return (
        <DrilldownTable showUTCTime={true} themeSelected={themeSelected} bodyData={drilldownData} titleData={ unloadingTypeSummaryTitleDD} showCollapse={false} showInput={true} showSort={true} arrangeOrder={arrangeOrder} arrangeKey={arrangeKey} drillDownReport={unScheduledPOSummaryDrillDown} initiateDrillDownReport={initiateDrillDownReport} loaderArray={loaderArray} subRoot={subRoot} sortingAction={sagaActions.SORT_DRILLDOWN_REPORT}/>
    );
}

export default DrillDownUnscheduledPos;