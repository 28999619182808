import React from 'react'
import './CarrierApptCardSS.css'
import { getFormattedDate } from '../../../../../../../../../Utils/DateUtils';
import { AppConstant } from '../../../../../../../../Assests/AppConstant';
import { useDispatch } from 'react-redux';
import { sagaActions } from '../../../../../../../../../sagas/sagaActions';
import CustomButtonSS from '../../../../../../../Common/CustomButton/CustomButtonSS';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';

function CarrierApptCardSS(props) {
    const { rowData } = props;
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const getColor = () => {
        if (rowData[0]?.ApptScheStatus.toLowerCase() === 'rescheduled') {
            return 'aapt-card-rescheduled'
        }
        else if(rowData[0]?.ApptScheStatus.toLowerCase() === 'completed')
            {
                return 'aapt-card-completed'
            }
        else {
            return 'aapt-card-scheduled'
        }
    }


    return (
        <div className={getColor() + ' carrier-appt-card-wrapper my-2 d-flex justify-content-between flex-column'}>
            <div className='d-flex justify-content-between'>
                <p className='aapt-card-text'>
                    <span>PO No. : {rowData[0].PoNumber || '--'}</span><br />
                    <span className='f-10'>
                        Appt. No.: {rowData[0]?.AppointmentNumber || '--'}
                    </span>
                    <br />
                    <span className='f-10'>
                        {moment(rowData[0]?.ApptStartDate) ? moment(rowData[0]?.ApptStartDate).utc().format(AppConstant.commonStrings.MM_DD_YYYY_dateFormat) + " " + moment(rowData[0]?.ApptStartDate).utc().format(AppConstant.commonStrings.hh_mm_A_dateFormat) : '-'}
                        {/* {getFormattedDate(AppConstant.commonStrings.MM_DD_YYYY_dateFormat, rowData?.ApptStartDate) || '--'} */}
                    </span>
                </p>
                <CustomButtonSS
                    isIcon={true}
                    iconClass={'fa-solid fa-angle-right'}
                    title=''
                    className={"carrier-appt-card-btn"}
                    onClick={() => {
                        dispatch({ type: sagaActions.UPDATE_SS_SEARCH_PO, payload: [...rowData] })
                        // if (location?.pathname?.includes('myappointment')) {
                        navigate('/bookappointments', { state: { type: 'rescheduling' } });
                        // }
                    }}
                />
            </div>
            <div className='d-flex justify-content-end'>
                <p className='f-10 fw-bold'>{rowData[0] && rowData[0].ApptScheStatus.toUpperCase()}</p>
            </div>
        </div>
    )
}

export default CarrierApptCardSS