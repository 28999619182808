import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    checkInLoad: [],
    initiateLoading:false,
    isLoading: false,
    checkInError: null,
    checkInResponse:null
}

export const CheckInReducer = createSlice({
    name: 'getCheckIn',
    initialState,
    reducers: {
        updateCheckInLoader: (state,action) => {
            state.isLoading = action.payload
        },
        updateCheckInLoad: (state, action) => {
            state.checkInLoad = action.payload;
        },
        clearCheckInLoad: (state) => {
            state.checkInLoad = null;
        },
        clearCheckInError: (state) => {
            state.checkInError = null
        },
        updateCheckInResponse: (state, action) => {
            state.checkInResponse = action.payload;
        },
        updateInitiateLoading: (state, action) => {
            state.initiateLoading = action.payload;
        },
    }
});


export const { updateCheckInLoader,updateCheckInLoad,clearCheckInLoad,clearCheckInError,updateCheckInResponse,updateInitiateLoading } = CheckInReducer.actions;

// Selectors
export const CheckInSelector = (state) => {
    return state.getCheckIn;
};

export default CheckInReducer.reducer;
