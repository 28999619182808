import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  autofetchDefDashboardInterval: '' || 180000,
  autoFetchExecutorInterval: '' || 35000,
  doorAssignedTimer: '' || 300000,
  autofetchCustomDashboardInterval: '' || 180000,
  autofetchNotificatioInterval: '' || 3600000,
  allConfigration:{}
}

export const RemoteConfigReducerSlice = createSlice({
  name: 'remoteConfigReducer',
  initialState,
  reducers: {
    updateAutofetchDefDashboardInterval: (state, action) => {
      state.autofetchDefDashboardInterval = action.payload;
    },
    updateAutofetchCustomDashboardInterval: (state, action) => {
      state.autofetchCustomDashboardInterval = action.payload;
    },
    updateAutoFetchExecutorInterval: (state, action) => {
      state.autoFetchExecutorInterval = action.payload;
    },
    updateDoorAssignedTimer: (state, action) => {
      state.doorAssignedTimer = action.payload;
    },
    updateAutoFetchNotificatioInterval: (state, action) => {
      state.autofetchNotificatioInterval = action.payload
    },
    updateAllConfigration: (state, action) => {
      state.allConfigration = action.payload
    }
  }
});

export const { updateAutofetchDefDashboardInterval, updateAutoFetchExecutorInterval, updateDoorAssignedTimer, updateAutofetchCustomDashboardInterval, updateAutoFetchNotificatioInterval,updateAllConfigration } = RemoteConfigReducerSlice.actions;

// Selectors
export const getRemoteConfigSelector = (state) => {
  return state.remoteConfigReducer;
};


export default RemoteConfigReducerSlice.reducer;