import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { Uchain } from '../redux/api/agent';
import { sagaActions } from './sagaActions';
import { ssUpdatePoArrFunc, updateOperationObj, updateTableData, upcomingPastApptObjReducer, myApptsReducer, ssFinanceReportRedcuer, updateFacilityRatingsReportList, updateFacilityRatingsAllData, ssUpdatePoArrResponse, ssUpdateIsLoading,myApptsResponseRedcuer,updateUserRatingsAllData, updateUserRatingsReportList, apptsReportHistoryReducer } from '../redux/reducers/reducerSlices/POSmartSchedulerReducer';
import { convertGmtToDateRange } from '../Utils/DateUtils';
import _ from 'lodash-es'
import { showAlert } from '../App/Assests/Utility';
import { toast } from 'react-toastify';
import { AppConstant } from '../App/Assests/AppConstant';
 

function* ssUpdatePOListPOSaga(data){
    try{
        const tempStatusObj = {...data.operationObj}
        yield put(ssUpdateIsLoading(true))
        const response = yield call(Uchain.ssCreatePo, data.payload);
        if(tempStatusObj.type === 'Fetch POs' && response && response.data){
            tempStatusObj.status = true
            yield put(ssUpdatePoArrFunc(response.data));
            yield put(ssUpdatePoArrResponse(response))
            yield put(updateOperationObj(tempStatusObj));
        } else if(response.RESULT && response.RESULT === 'Successfully Inserted' || response.RESULT === 'Successfully Updated' || response.RESULT === 'Successfully Deleted'){
            tempStatusObj.status = true
            tempStatusObj.message = response;
            yield put(updateOperationObj(tempStatusObj));
        } else {
            tempStatusObj.status = false
            tempStatusObj.message = response.RESULT;
            yield put(updateOperationObj(tempStatusObj));
        }
        yield put(ssUpdateIsLoading(false))
    } catch(err) {
        const tempStatusObj = {...data.operationObj}
        tempStatusObj.message = err.response.data;
        tempStatusObj.status = false
        yield put(updateOperationObj(tempStatusObj));
    }
}
 
function* resetOperationObjSaga(data){
    try{
        yield put(updateOperationObj({type: '', status : ''}));
        
    } catch {
    }
}
 

function* sortSsPoTableSaga(data) {
  try {
    yield put(updateTableData(data.payload))
  } catch (error) {
  }
}
 
function* ssPoExcelUploadSaga(data) {
    try{
        const tempStatusObj = {...data.operationObj}
        const response = yield call(Uchain.uploadPoTemplateApi, data.payload);
        if(tempStatusObj.type === 'Upload PO' && response && response.message === 'File uploaded successfully!'){
            tempStatusObj.status = true
            yield put(ssUpdatePoArrFunc(response));
            yield put(updateOperationObj(tempStatusObj));
        } else {
            tempStatusObj.status = false
            tempStatusObj.message = response.message;
            yield put(updateOperationObj(tempStatusObj));
        }
    } catch(err) {
        const tempStatusObj = {...data.operationObj}
        tempStatusObj.status = false;
        tempStatusObj.message = err.response.data.message;
        yield put(updateOperationObj(tempStatusObj));
    }
}
 
 
function* ssUpcomingPastApptsSaga(data) {
    const tempStatusObj = {...data.operationObj}
    try {
        const response = yield call(Uchain.upcomingPastApptsApi, data.payload);
        tempStatusObj.status = true
        yield put(upcomingPastApptObjReducer(response));
        yield put(updateOperationObj(tempStatusObj));
    } catch (error) {
        tempStatusObj.message = error.response.data;
        yield put(updateOperationObj(tempStatusObj));
    }
}
 
function* ssApptsListSaga(data) {
    const tempStatusObj = {...data.operationObj}
    try {
        const response = yield call(Uchain.myAppointmentsApi, data.payload);
        tempStatusObj.status = true
        yield put(myApptsReducer(response.data));
        yield put(myApptsResponseRedcuer(response));
        yield put(updateOperationObj(tempStatusObj));
    } catch (error) {
        tempStatusObj.message = error.response.data;
        yield put(updateOperationObj(tempStatusObj));
    }
}
 
function* downloadFacilityRatingReportSaga(data) {
    try {
        const response = yield call(Uchain.downloadFacilitiyRatingReport, data.payload);
        if(response){
            window.open(response.fileUrl, '_blank');
        }else{
            showAlert(toast.TYPE.INFO, AppConstant.commonStrings.noDataFound, 'light')
        }
    } catch (error) {
        showAlert(toast.TYPE.ERROR, AppConstant.notifications.login.wentWrong, 'light')
    }
}
 


/**
 * This function is used to communicate with API and get ths user rating downlaodable fiel
 * @param {*} data 
 */
function* downloadUserRatingReportSaga(data) {
    try{
        const response = yield call(Uchain.downloadUserRatingReport, data.payload);
        if(response){
            window.open(response.fileUrl, '_blank');
        }else {
            showAlert(toast.TYPE.INFO, AppConstant.commonStrings.noDataFound, 'light')
        }
    } catch (error) {
        showAlert(toast.TYPE.ERROR, AppConstant.notifications.login.wentWrong, 'light')
    }
}

function* resetApptsListSaga() {
    try {
        yield put(myApptsReducer([]));
    } catch (error) {
    }
}
 
function* ssFinanceReportSaga(data) {
    const tempStatusObj = { ...data.operationObj }
    try {
        // const state = yield select();
        // const dateRangeObj = state.defaultDashboardReducer.dateRangeObj;
        // const start = dateRangeObj?.start ? moment(dateRangeObj.start).format('YYYY-MM-DD') : null;
        // const end = dateRangeObj?.end ? moment(dateRangeObj.end).format('YYYY-MM-DD'): null;
        // const start = convertGmtToDateRange(dateRangeObj).start;
        // const end = convertGmtToDateRange(dateRangeObj).end;
        // const payload = {
        //     ...data.payload,
        //     "fromDate": start !== "Invalid date" ? start : null,
        //     "toDate": end !== "Invalid date" ? end : null,
        // }
        const response = yield call(Uchain.ssFinanceReport, data.payload);
        tempStatusObj.status = true
        yield put(ssFinanceReportRedcuer(response));
        yield put(updateOperationObj(tempStatusObj));
    } catch (error) {
        tempStatusObj.message = error.response.data;
        yield put(updateOperationObj(tempStatusObj));
    }
}
 
function* resetssFinanceReportSaga() {
    try {
        yield put(ssFinanceReportRedcuer([]));
    } catch (error) {
    }
}
 
function* resetSSApptsListSaga() {
    try {
        yield put(upcomingPastApptObjReducer({}));
    } catch (error) {
    }
}
 
function* ssResetPOListPOSaga() {
    try {
        yield put(ssUpdatePoArrFunc([]));
    } catch (error) {
    }
}
 
function* updateFacilityRatingsSaga(data) {
    try {
        const response = yield call(Uchain.facilityRatings, data.payload);    
        if (_.upperCase(response.status) === 'SUCCESS') {
            if (data.payload.type === 'T') {
                yield put(updateFacilityRatingsReportList(response?.data?.Items));
            }
            yield put(updateFacilityRatingsAllData(response))
        } else {
            yield put(updateFacilityRatingsReportList([]));
            yield put(updateFacilityRatingsAllData(response))
        }
    }
    catch{
        yield put(updateFacilityRatingsReportList([]));
        yield put(updateFacilityRatingsAllData(null))   
    }
}
 
function* resetFacilityRatingsSaga(data) {
    try {
        yield put(updateFacilityRatingsReportList([]));
        yield put(updateFacilityRatingsAllData(null))
    }
    catch{
          
    }
}

/**
 * This function is used to communicate with API and get ths user rating details and update the list
 * @param {*} data 
 */
function* updateUserRatingSaga(data) {
    try{
        const response = yield call(Uchain.userRatings, data.payload);
        if (_.upperCase(response.status) === 'SUCCESS') {
            if (data.payload.type == 'T') {
                yield put(updateUserRatingsReportList(response?.data?.Items));
            } 
            yield put(updateUserRatingsAllData(response)) 
        }else {
            yield put(updateUserRatingsReportList([]));
            yield put(updateUserRatingsAllData(response)) 
        }
    }
    catch{
        yield put(updateUserRatingsReportList([]));
        yield put(updateUserRatingsAllData(null))  
    }
}

/**
 * This function is used to reset the user rating list to empty or null
 * @param {*} data 
 */
function* resetUserRatingsSaga(data) {
    try {
        yield put(updateUserRatingsReportList([]));
        yield put(updateUserRatingsAllData(null))
    }
    catch{
          
    }
}

/**
 * Function is used to communicate with API ang get the appointment report history details
 * @param {*} data 
 */
function* apptsReportHistoryListSaga(data) {
    try {
        yield put(ssUpdateIsLoading(true));
        const response = yield call(Uchain.appointmentReportHistory, data.payload);
        if(response){
        yield put(apptsReportHistoryReducer(response));
        } 
    } catch (error) {
        showAlert(toast.TYPE.ERROR, AppConstant.notifications.login.wentWrong, 'light')
    }
    finally{
        yield put(ssUpdateIsLoading(false));
    }
}

function* resetApptsReportHistoryListSaga() {
    try {
        yield put(apptsReportHistoryReducer([]));
    } catch (error) {
    }
}

 
 
export function* ssUpdatePOListWatcher() {
    yield takeEvery(sagaActions.UPDATE_PO_LIST, ssUpdatePOListPOSaga)
}
 
export function* ssResetPOListWatcher() {
    yield takeEvery(sagaActions.RESET_PO_LIST, ssResetPOListPOSaga)
}
 
export function* resetOperationObjWatcher() {
    yield takeEvery(sagaActions.RESET_SS_PO_OPERATION_OBJ, resetOperationObjSaga)
}
 
export function* sortSsPoTableWatcher() {
    yield takeEvery(sagaActions.SORT_SS_USER_TABLE, sortSsPoTableSaga)
}
 
export function* ssPoExcelUploadWatcher() {
    yield takeEvery(sagaActions.SS_PO_EXCEL_UPLOAD_ACTION, ssPoExcelUploadSaga)
}
 
export function* ssUpcomingPastApptsWatcher() {
    yield takeLatest(sagaActions.SS_UPCOMING_PAST_APPTS_ACTION, ssUpcomingPastApptsSaga)
}
 
export function* ssApptsListWatcher() {
    yield takeLatest(sagaActions.MY_APPTS_LIST_ACTION, ssApptsListSaga)
}
 
export function* resetApptsListWatcher() {
    yield takeEvery(sagaActions.RESET_APPTS_LIST_ACTION, resetApptsListSaga)
}
 
export function* resetSSApptsListWatcher() {
    yield takeEvery(sagaActions.RESET_SS_UPCOMING_PAST_APPTS_ACTION, resetSSApptsListSaga)
}
 
export function* ssFinanceReportWatcher() {
    yield takeEvery(sagaActions.SS_FINANCE_REPORT_ACTION, ssFinanceReportSaga)
}
 
export function* resetSSFinanceReportWatcher() {
    yield takeEvery(sagaActions.RESET_FINANCE_REPORT_ACTION, resetssFinanceReportSaga)
}
 
export function* updateFacilityRatingsWatcher() {
    yield takeEvery(sagaActions.UPDATE_FACILITY_RATINGS, updateFacilityRatingsSaga)
}
export function* resetFacilityRatingsWatcher() {
    yield takeEvery(sagaActions.RESET_FACILITY_RATINGS_ACTION, resetFacilityRatingsSaga)
}
export function* downloadFacilityRatingReportWatcher() {
    yield takeEvery(sagaActions.FACILITY_RATIING_DOWNLOAD, downloadFacilityRatingReportSaga)
}
export function* updateUserRatingsWatcher() {
    yield takeEvery(sagaActions.UPDATE_USER_RATINGS, updateUserRatingSaga)
}
export function* resetUserRatingsWatcher() {
    yield takeEvery(sagaActions.RESET_USER_RATINGS_ACTION, resetUserRatingsSaga)
}
export function* downloadUserRatingReportWatcher() {
    yield takeEvery(sagaActions.USER_RATIING_DOWNLOAD, downloadUserRatingReportSaga)
}
export function* appointmentReportHistoryListWatcher() {
    yield takeEvery(sagaActions.GET_APPOINTMENT_REPORT_HISTORY, apptsReportHistoryListSaga)
}
export function* resetAppointmentReportHistoryListWatcher() {
    yield takeEvery(sagaActions.RESET_APPOINTMENT_REPORT_HISTORY, resetApptsReportHistoryListSaga)
}
 