import React,{ useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as SearchPoLightIcon} from '../../../../../../Assests/Svg/search-po-light.svg';
import { ReactComponent as SearchPoDarkIcon} from '../../../../../../Assests/Svg/search-po-dark.svg';
import {AppConstant} from '../../../../../../Assests/AppConstant';
import { sagaActions } from '../../../../../../../sagas/sagaActions';
import { getTruckSelector } from '../../../../../../../redux/reducers/reducerSlices/getTruckReducer';
import { getSelectedDateSelector } from '../../../../../../../redux/reducers/reducerSlices/DateSelectorReducer';
import _ from 'lodash-es';
import './SearchPOTextInput.css';
import { getLoadSelector } from '../../../../../../../redux/reducers/reducerSlices/getLoadReducer';
import { getFilterViewExpanded } from '../../../../../../../redux/reducers/reducerSlices/FiltersReducerSlice';

const SearchPOTextInput = (props) => {
    const dispatch = useDispatch();
    const { themeSelected, categorySelected } = props;
    const [searchStr, setSearchStr] = useState('');
    const appConstPath = AppConstant.poManagement.searchPOSection;
    const getTruck = useSelector(getTruckSelector);
    const getSelectedDateSelectorObj = useSelector(getSelectedDateSelector);
    const { truckListMasterData, autoFetchPolist } = getTruck;
    const getLoad = useSelector(getLoadSelector);
    const { getLoadLoading } = getLoad;
    const {isFilterViewExpanded}  = useSelector(getFilterViewExpanded);

    const triggerSearchAction = (searchedData) => {
        dispatch({ type: sagaActions.UPDATE_TRUCK, payload: searchedData });
   }
   const debounceFunc = useCallback(_.debounce(triggerSearchAction, 1000), []);


    const resetOriginalTruckList = () => {
        dispatch({ type: sagaActions.UPDATE_TRUCK, payload: truckListMasterData });
    }

    useEffect(() => {
        /* Auto Fetch PO-List - when Auto Fetch Happens*/
        
        // dispatch({ type: sagaActions.AUTO_FETCH_PO_LIST, payload: true })
        const searchObj = {
            category : categorySelected,
            searchString : searchStr
        }
        searchStr && searchDebounceFunc(searchObj)
    }, [truckListMasterData])

    useEffect(() => {
        dispatch({ type: sagaActions.IS_SEARCHING, payload: false });
        setSearchStr('');
        resetOriginalTruckList();
    }, [categorySelected, getSelectedDateSelectorObj])

    useEffect(() => {
        dispatch({ type: sagaActions.IS_SEARCHING, payload: false });
        setSearchStr('');
    }, [isFilterViewExpanded])

    useEffect(() => {
        if(!searchStr) {
            dispatch({ type: sagaActions.IS_SEARCHING, payload: false });
            resetOriginalTruckList();
        }
    }, [searchStr])

    function searchDebounceFunc(searchObj) {
        dispatch({ type: sagaActions.RESET_FILTERS });
        dispatch({ type: sagaActions.IS_SEARCHING, payload: true });
        !autoFetchPolist && dispatch({ type: sagaActions.RESET_LOAD_TRUCK_DETAILS })
        switch (searchObj.category){
            case appConstPath.poTitleStr :
                searchPOList(searchObj.searchString);
            break;
            case appConstPath.carrierTitleStr:
                searchCarrier(searchObj.searchString);
            break;
            case appConstPath.vendorTitleStr:
                searchVendor(searchObj.searchString);
            break;
        }
    }

    const searchPOList = (searchStr) => {
        const searchedPO = () => truckListMasterData.filter((truckObj) => {
            const totalPoList = [...truckObj.scheduled_pos, ...truckObj.unscheduled_pos];
            const searched = totalPoList.filter((poStr) => {
                const poStrLower = poStr.toLowerCase()
                if(_.startsWith(poStrLower, searchStr.toLowerCase())){
                    return truckObj;
                }
            });
            if(searched.length){
                return searched
            }
        });
        dispatch({ type: sagaActions.UPDATE_TRUCK, payload: searchedPO() });
        // autoFetchPolist ? triggerSearchAction(searchedPO()): debounceFunc(searchedPO())
        // searchedPO().length === 0 && dispatch({type: sagaActions.RESET_LOAD_TRUCK_DETAILS})
    }

    const searchCarrier = (searchStr) => {

        const searchedCarrierList = () => truckListMasterData.filter((truckObj) => {
            if(truckObj.carrier && truckObj.carrier !== null){
                const carrierStr = truckObj.carrier.toLowerCase()
                if(carrierStr.includes(searchStr.toLowerCase())){
                    
                    return truckObj
                }
            }
        });
        autoFetchPolist ? triggerSearchAction(searchedCarrierList()):debounceFunc(searchedCarrierList());
        searchedCarrierList().length === 0 && dispatch({type: sagaActions.RESET_LOAD_TRUCK_DETAILS})
    }

    const searchVendor = (searchStr) => {
        const searchedVendorList = () => truckListMasterData.filter((truckObj) => {
            if(truckObj.vendor && truckObj.vendor !== null){
                const vendorStr = truckObj.vendor.toLowerCase();
                if(vendorStr.includes(searchStr.toLowerCase())){
                    return truckObj;
                }
            }
        });
        autoFetchPolist ? triggerSearchAction(searchedVendorList()): debounceFunc(searchedVendorList())
        searchedVendorList().length === 0 && dispatch({type: sagaActions.RESET_LOAD_TRUCK_DETAILS})
    }

    const setSearchIcon = () => {
        return (themeSelected === AppConstant.commonStrings.lightModeFilterString ? <SearchPoDarkIcon className='searchPOIcon' /> : <SearchPoLightIcon className='searchPOIcon' />);
    }

    const searchTruckList = (searchTypeEvent) => {
        // if(!getLoadLoading){
            dispatch({ type: sagaActions.IS_SEARCHING, payload: true });
            setSearchStr(searchTypeEvent.target.value);

        if([...searchTypeEvent.target.value].length > 1){
            const searchObj = {
                category : categorySelected,
                searchString : searchTypeEvent.target.value
            }
            searchDebounceFunc(searchObj);
        }
        // }
        
    }

    return (
        <div className={`rounded-end p-1 searchPOTextInput ${themeSelected}`}>
            <span>
                <input className={`searchPoInput ${themeSelected}` } 
                placeholder={appConstPath.searchTitleStr} 
                onChange={searchTypeEvent => searchTruckList(searchTypeEvent)}
                value={searchStr} 
                // disabled={getLoadLoading ? true: false}
                />
                <span className='me-0'>
                    {setSearchIcon()}
                </span>
            </span>
        </div>
    )
}

export default SearchPOTextInput;