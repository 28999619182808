import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { sagaActions } from '../../../../../../../../sagas/sagaActions';
import { getSelectedFiltersSelector, getDockFilterListSelector, getStatusFilterListSelector, getSelectedDockFilterListSelector, getSelectedStatusFilterListSelector, getIsSearchingStatusSelector } from '../../../../../../../../redux/reducers/reducerSlices/FiltersReducerSlice';
import { getTruckSelector } from '../../../../../../../../redux/reducers/reducerSlices/getTruckReducer';
import _ from 'lodash-es';
import { applyFilters, createStatusFilterList, getCurrentFilter } from '../../../FilterUtilities';
import { usePrevious } from '../../../../../../Common/CustomHooks/usePrevious';
import './PODockStatusButton.css';

const PODockStatusButton = (props) => {
    const { poFilterList, themeSelected } = props
    const dispatch = useDispatch();
    const selectedFiltersList = useSelector(getSelectedFiltersSelector);
    const dockFiltersList = useSelector(getDockFilterListSelector);
    const statusFiltersList = useSelector(getStatusFilterListSelector);
    const selectedDockFiltersList = useSelector(getSelectedDockFilterListSelector);
    const selectedStatusFiltersList = useSelector(getSelectedStatusFilterListSelector);
    const isSearching = useSelector(getIsSearchingStatusSelector);
    const getTruck = useSelector(getTruckSelector);
    const { truckListMasterData, autoFetchPolist, truckList } = getTruck;
    const [filterSelected, setFilterSelected] = useState(false);
    const [currentFiltersArr, setCurrentFiltersArr] = useState([]);
    const previousStateCurrentFiltersArr = usePrevious(currentFiltersArr);
    
    useEffect(() => {
        if (selectedFiltersList !== currentFiltersArr) {
            setCurrentFiltersArr([...selectedFiltersList])
        }
    }, [selectedFiltersList])

    useEffect(() => {
        let result = _.without([...currentFiltersArr], dockFiltersList[0]);
        result = _.without([...result], statusFiltersList[0]);
        
        if(!(_.isEqual(currentFiltersArr, previousStateCurrentFiltersArr))){
            if(_.isEqual(selectedDockFiltersList, result) && selectedDockFiltersList.length>0 && result.length>0 ){
                //Only Dock
                setCurrentFiltersArr([...result, statusFiltersList[0]]);
            } else if(_.isEqual(selectedStatusFiltersList, result) && selectedStatusFiltersList.length>0 && result.length>0){
                //Only Status
                setCurrentFiltersArr([...result, dockFiltersList[0]]);
            }
        }

        if (selectedFiltersList !== currentFiltersArr && selectedFiltersList.length > 0) {
            getPOFilters();
            
        } else {
            if(currentFiltersArr.length === 0){
                setCurrentFiltersArr([dockFiltersList[0], statusFiltersList[0]]);
            } 
        }
    }, [currentFiltersArr])

    useEffect(() => {
            updateStatusFiltersCount();
    }, [truckList, selectedDockFiltersList, selectedStatusFiltersList, selectedFiltersList])

    useEffect(() => {
        /* Auto Fetch PO-List - when Auto Fetch Happens*/
        // dispatch({ type: sagaActions.SET_DOCK_FILTERS_LIST, payload: [] });
        // dispatch({ type: sagaActions.SET_STATUS_FILTERS_LIST, payload: [] });
        if(!isSearching){
            // dispatch({ type: sagaActions.AUTO_FETCH_PO_LIST, payload: true });
            updateTruckList([...selectedFiltersList]);
        }
        
    }, [truckListMasterData, selectedFiltersList])

    const updateTruckList = (filtersArr) => {
        const filterList = applyFilters(filtersArr, truckListMasterData);
        !autoFetchPolist && dispatch({ type: sagaActions.RESET_LOAD_TRUCK_DETAILS });
        
        if (filtersArr.length === 0 && filterList.length === 0) {
            dispatch({ type: sagaActions.UPDATE_TRUCK, payload: truckListMasterData });
        } else {
            dispatch({ type: sagaActions.UPDATE_TRUCK, payload: filterList });
        }
    }

    const updateStatusFiltersCount = () => {

        if(_.isEqual(selectedDockFiltersList, selectedFiltersList) && selectedFiltersList.length && selectedDockFiltersList.length){
            // Dock only
            const statusFilterArr = createStatusFilterList(truckList);
            dispatch({ type: sagaActions.SET_STATUS_FILTERS_LIST, payload: statusFilterArr });
        } else if (_.isEqual([...selectedDockFiltersList, ...selectedStatusFiltersList].sort(), [...selectedFiltersList].sort()) && selectedFiltersList.length && selectedDockFiltersList.length && selectedStatusFiltersList.length){
            // dock and status
            const dockFiltersArr = applyFilters(selectedDockFiltersList, truckListMasterData);
            const statusFilterArr = createStatusFilterList(dockFiltersArr);
            dispatch({ type: sagaActions.SET_STATUS_FILTERS_LIST, payload: statusFilterArr });
        } else {
            // none OR Status only
            const statusFilterArr = createStatusFilterList(truckListMasterData);
            dispatch({ type: sagaActions.SET_STATUS_FILTERS_LIST, payload: statusFilterArr });
        }
    }

    const toggleFilterButton = (ele) => {
        setFilterSelected(!filterSelected);
        if(!ele.includes('All Docks') && !ele.includes('All Status')){
            // if (!selectedFiltersList.includes(ele)) {
                selectFilter(ele)
            // } else {
            //     deleteFitler(ele)
            // }
        }
    }

    const selectFilter = (ele) => {
        let currentSelFiltersList = []
        if(_.isEqual(selectedFiltersList, selectedStatusFiltersList) && dockFiltersList.includes(ele)){
            currentSelFiltersList = [];
        }
        setTimeout(() => {
            const currentFiltersList = [...currentSelFiltersList, ele];
            const updatedFiltersArray = [...new Set(currentFiltersList)];
            setCurrentFiltersArr(updatedFiltersArray);
            dispatch({ type: sagaActions.ADD_FILTER, payload: updatedFiltersArray });
            if(dockFiltersList.includes(ele)){
                const dockArr = [ele];
                dispatch({ type: sagaActions.SELECTED_DOCK_FILTERS_LIST, payload: dockArr});
                
            } else {
                const statusArr = [...selectedStatusFiltersList, ele];
                dispatch({ type: sagaActions.SELECTED_STATUS_FILTERS_LIST, payload: statusArr});
                
            }
            updateTruckList(updatedFiltersArray);

        }, 100)
    }

    const deleteFitler = (ele) => {

        let currentSelFiltersList = [...selectedFiltersList]
        if((_.intersection(selectedDockFiltersList,selectedFiltersList)).length>1 && selectedDockFiltersList.includes(ele)){
            // currentSelFiltersList = selectedFiltersList.filter(x => !selectedStatusFiltersList.includes(x));
            currentSelFiltersList = _.difference(selectedFiltersList, selectedStatusFiltersList);
        }

        setTimeout(() => {
            const currentList = [...currentSelFiltersList];
            let updatedArray = currentList.filter((currentElement) => {
                return currentElement === ele ? null : currentElement;
            })
            dispatch({ type: sagaActions.DELETE_FILTER, payload: updatedArray });

            if (selectedDockFiltersList.includes(ele)) {
                const dockArr = selectedDockFiltersList.filter(e => e !== ele);
                dispatch({ type: sagaActions.SELECTED_DOCK_FILTERS_LIST, payload: dockArr});
            } else {
                const statusArr = selectedStatusFiltersList.filter(e => e !== ele);
                dispatch({ type: sagaActions.SELECTED_STATUS_FILTERS_LIST, payload: statusArr});
            }
            updateTruckList(updatedArray)
        }, 100)
    }

    const getPOFilters = () => {
        
        const combinedArray = poFilterList.map((ele) => {
            return (
                <span
                    onClick={() => toggleFilterButton(ele)}
                    key={ele}
                    className={`badge badge-pill badge-secondary me-2 border border-2
                    border-dark rounded-5 text-capitalize pODockStatusButton ${themeSelected}-buttonsFilters pODockStatusButton 
                    ${currentFiltersArr.includes(ele) ? `selectedElement-${themeSelected}` : ''}`}>
                    {ele.replaceAll('_', ' ')}
                </span>
            )
        });
        const finalArr = [...new Set(combinedArray)];
        return (finalArr.map((e) => (e)))
    }

    return (
        <div>
            {getPOFilters()}
        </div>
    )
}

export default PODockStatusButton;