import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { sagaActions } from '../../../../../../../../../sagas/sagaActions';
import { AppConstant } from '../../../../../../../../Assests/AppConstant';
import Top10LateLoadsRow from './Top10LateLoadsRow/Top10LateLoadsRow';
import Top10LateLoadsTitle from './Top10LateLoadsTitle/Top10LateLoadsTitle';
import './Top10LateLoadsStatus.css';
import _ from 'lodash-es'
import NoData from '../../../DrillDownReports/Common/Nodata';

const Top10LateLoadsStatus = (props) => {
    const { toggleLateLoadsData, top10LoadSelection, top10LateLoads, themeSelected  } = props;
    const dispatch = useDispatch();
   
    const createBtn = () => {
        return (
            <div className='pt-5'>
                <button type="button" className={` buttonFunnelDash ${top10LoadSelection === 'VENDOR' ? 'selectedButton' : 'unselectedButton'}  me-1`} onClick={(event) => toggleLateLoadsData(event, 'VENDOR')}>{AppConstant.dashboard.defaultdashboard.top10LateLoads.vendorHeading}</button>
                <button type="button" className={` buttonFunnelDash ${top10LoadSelection === 'CARRIER' ? 'selectedButton' : 'unselectedButton'} me-1`} onClick={(event) => toggleLateLoadsData(event, 'CARRIER')}>{AppConstant.dashboard.defaultdashboard.top10LateLoads.carrierHeading}</button>
            </div>
        )
    }

    const selectRow = (load) => {
        if (load.AlwaysLate === 1) {
            dispatch({
                type: sagaActions.TOP_10_SELECTED_LATE_LOAD, payload: 'AlwaysLate'
            })
        } else if (load.SomeTimesLate === 1) {
            dispatch({
                type: sagaActions.TOP_10_SELECTED_LATE_LOAD, payload: 'SomeTimesLate'
            })
        } else {
            dispatch({
                type: sagaActions.TOP_10_SELECTED_LATE_LOAD, payload: 'FirstTimeLate'
            })
        }
    }

    const renderTop10TableRows = () => {
            return (
                <tbody>
                    {
                        top10LateLoads.map((load, i) => {
                            return(
                                <Top10LateLoadsRow load={load} index={i} themeSelected={themeSelected} top10LoadSelection={top10LoadSelection} onClick={(load) => selectRow(load)} />
                                )
                        })
                    }
                    
                </tbody>
            )
        
        // return top10LateArr
    }

    const showTop10LateLoadsTable = () => {
        if (top10LateLoads.length) {
            return (
                <div className='overflow-hidden lateLoadsTable w-100'>
                    <table className={`table table-hover ${themeSelected} m-0 p-0`}>
                        <Top10LateLoadsTitle themeSelected={themeSelected} top10LoadSelection={top10LoadSelection} />
                        {renderTop10TableRows()}
                    </table>
                </div>
            )
        }
    }

    return (
        <div className={`col-12 ${themeSelected}`}>
            {/* <Link to={`/analytics/reports/toplateloads/${_.lowerCase(top10LoadSelection)}`} style={{ color: 'inherit', textDecoration: 'inherit' }}> */}
                <div className='d-flex col-12 pt-3 justify-content-between'>
                    <span className='graphHeading'>{AppConstant.dashboard.defaultdashboard.top10LateLoads.heading}</span>
                    <span>{createBtn()}</span>
                </div>
                {/* </Link> */}
                <div className='pt-4'>
                    <div className="d-flex align-items-center justify-content-center">
                        {top10LateLoads?.length ? showTop10LateLoadsTable() : <NoData themeSelected={themeSelected || 'light-mode'} />}</div>
                </div>
          
        </div>
    );
}

export default Top10LateLoadsStatus;