import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { sagaActions } from './sagaActions';
import { Uchain } from '../redux/api/agent';
import { AppConstant } from '../App/Assests/AppConstant';
import { addSerialNumberToList, showAlert } from '../App/Assests/Utility';
import { toast } from 'react-toastify';
import {
    updateRolesList, updateRolesFeaturesList, assignFeaturesToRoles, updateInitiateLoader,
    updateTableData, updateIssuesList, updateFeedbackList, updateFaqList, update_rolesFormHandler
    , updateRequestsReceivedDropdownsList, updateOpenNotificationsList, updateError, updateIsListLoader
} from '../redux/reducers/reducerSlices/SuperAdminReducerSlice';

function* getRolesListSaga(data) {
    try {
        const response = yield call(Uchain.getRoleList, data.payload);
        if (response) {
            yield put(update_rolesFormHandler(true))
            if (data.payload.crud_type === 'U') {
                showAlert(toast.TYPE.SUCCESS, AppConstant.superUserAdmin.rolesAdmin.toast.updateRoleSuccess, 'light')
            } else if (data.payload.crud_type === 'I') {
                showAlert(toast.TYPE.SUCCESS, AppConstant.superUserAdmin.rolesAdmin.toast.createRoleSuccess, 'light')
            }
        } else if (!response || !response.length) {
            yield put(update_rolesFormHandler(true))
            yield put(updateInitiateLoader(false))

            showAlert(toast.TYPE.ERROR, AppConstant.superUserAdmin.rolesAdmin.toast.noDataAvailable, 'light')
        }
        const tempResponse = addSerialNumberToList(response)
        yield put(updateRolesList([...tempResponse]));
    } catch (error) {
        yield put(updateError(true))
        if (data.payload.crud_type === 'U') {
            yield put(updateInitiateLoader(false))
            showAlert(toast.TYPE.ERROR, error?.response?.data.split(': ').pop(), 'light')
        } else if (data.payload.crud_type === 'I') {
            yield put(updateInitiateLoader(false))
            showAlert(toast.TYPE.ERROR, error?.response?.data.split(': ').pop(), 'light')
        }
        yield put(update_rolesFormHandler(false))
    }
}

function* updateRolesPopupSaga(data) {
    try {
        yield put(update_rolesFormHandler(data.payload));
    } catch (error) {
    }
}

function* getRolesFeaturesListSaga(data) {
    try {
        const response = yield call(Uchain.getRolesFeaturesList, data.payload);
        yield put(updateRolesFeaturesList(response));
    } catch (error) {
    }
}

function* assignFeaturesToRolesSaga(data) {
    try {
        const response = yield call(Uchain.assignFeaturesToRole, data.payload);
        if (response) {
            if (data.payload[data.payload.length - 1].crud_type === 'U') {
                showAlert(toast.TYPE.SUCCESS, AppConstant.superUserAdmin.rolesAdmin.toast.updateRoleSuccess, 'light')
            }
        }
        yield put(assignFeaturesToRoles(response));
    } catch (error) {
        yield put(assignFeaturesToRoles([]));
        if (data.payload[data.payload.length - 1].crud_type === 'U') {
            showAlert(toast.TYPE.ERROR, error?.response?.data.split(': ').pop(), 'light')
        }
    }
}

function* updateInitiateLoaderSaga(data) {
    try {
        //   const response = yield call(Uchain.getUserType, data.payload);
        yield put(updateInitiateLoader(data.payload))
    } catch (error) {
    }
}

function* sortSuperAdminTableSaga(data) {
    try {
        yield put(updateTableData(data.payload))
    } catch (error) {
    }
}

function* updateFaqSaga(data) {
    try {
        yield put(updateIsListLoader(true))
        const response = yield call(Uchain.getFaqList, data.payload);
        const tempResponse = addSerialNumberToList(response)
        yield put(updateFaqList(tempResponse));
        if (data.payload.CRUDTYPE === 'U') {
            showAlert(toast.TYPE.SUCCESS, AppConstant.helpSupportFaq.alerts.updatedSuccessfully, 'light')
        }
        if (data.payload.CRUDTYPE === 'I') {
            showAlert(toast.TYPE.SUCCESS, AppConstant.helpSupportFaq.alerts.createdSuccessfully, 'light')
        }
        yield put(updateIsListLoader(false))
    } catch (error) {
        yield put(updateIsListLoader(false))
        if (data.payload.CRUDTYPE === 'U') {
            showAlert(toast.TYPE.ERROR, error.response.data.split(': ').pop(), 'light')
        }
        if (data.payload.CRUDTYPE === 'I') {
            showAlert(toast.TYPE.ERROR, error.response.data.split(': ').pop(), 'light')
        }
    }
}

function* updateIssuesListSaga(data) {
    try {
        const response = yield call(Uchain.addHelpRequest, data.payload);
        if (response && response.length) {
            yield put(updateIssuesList(response));
        }

        if (data.payload.crud_type === "U") {
            showAlert(toast.TYPE.SUCCESS, AppConstant.requestsReceived.alerts.updatedSuccessfully, 'light')
        }

    } catch (error) {
        if (data.payload.crud_type === "U") {
            showAlert(toast.TYPE.ERROR, AppConstant.requestsReceived.alerts.updateError + ' ' + error.response.data.split(': ').pop(), 'light')
        }
    }
}

function* updateFeedbackListSaga(data) {
    try {
        const response = yield call(Uchain.addHelpRequest, data.payload);
        yield put(updateFeedbackList(response));
        if (data.payload.crud_type === "U") {
            showAlert(toast.TYPE.SUCCESS, AppConstant.requestsReceived.alerts.updatedSuccessfully, 'light')
        }
    } catch (error) {
        if (data.payload.crud_type === "U") {
            showAlert(toast.TYPE.ERROR, AppConstant.requestsReceived.alerts.updateError + ' ' + error.response.data.split(': ').pop(), 'light')
        }
    }
}
function* updateErrorSaga(data) {
    try {
        yield put(updateError(data.payload))
    } catch (error) {
    }
}



function* getRequestsReceivedDropDownsListSaga(data) {
    try {
        const response = yield call(Uchain.getReqRecDropdownsList, data.payload);
        
        yield put(updateRequestsReceivedDropdownsList(response));
    } catch (error) {
    }
}

function* getOpenNotificationsListSaga(data) {
    try {
        const response = yield call(Uchain.getNotificationObj, data.payload);
        yield put(updateOpenNotificationsList(response));

    } catch (error) {
    }
}

export function* getRolesListWatcher() {
    yield takeEvery(sagaActions.GET_ROLES_LIST, getRolesListSaga)
}

export function* getRolesFeaturesListWatcher() {
    yield takeEvery(sagaActions.GET_ROLES_FEATURE_LIST, getRolesFeaturesListSaga)
}

export function* assignFeaturesToRolesWatcher() {
    yield takeEvery(sagaActions.ASSIGN_ROLES_FEATURE_LIST, assignFeaturesToRolesSaga)
}

export function* updateInitiateSuperAdminLoaderWatcher() {
    yield takeEvery(sagaActions.INITIATE_SUPER_ADMIN_LOADING, updateInitiateLoaderSaga)
}

export function* sortSuperAdminTableWatcher() {
    yield takeEvery(sagaActions.SORT_SUPER_ADMIN_TABLE, sortSuperAdminTableSaga)
}

export function* updateIssuesListWatcher() {
    yield takeEvery(sagaActions.UPDATE_ISSUES_LIST, updateIssuesListSaga)
}

export function* updateFeedbackListWatcher() {
    yield takeEvery(sagaActions.UPDATE_FEEDBACK_LIST, updateFeedbackListSaga)
}

export function* updateFaqWatcher() {
    yield takeEvery(sagaActions.GET_FAQ_LIST, updateFaqSaga)
}

export function* updateRolesPopupWatcher() {
    yield takeEvery(sagaActions.UPDATE_ROLES_FORM_POPUP, updateRolesPopupSaga)
}

export function* getRequestsReceivedDropDownsListWatcher() {
    yield takeEvery(sagaActions.GET_REQUESTS_RECEIVED_DROPDOWNS_LIST, getRequestsReceivedDropDownsListSaga)
}
export function* updateErrorWatcher() {
    yield takeEvery(sagaActions.ROLE_LIST_ERROR, updateErrorSaga)
}
export function* getOpenNotificationsListWatcher() {
    yield takeEvery(sagaActions.GET_OPEN_NOTIFICATION_LIST, getOpenNotificationsListSaga)
}