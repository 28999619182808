import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash-es';
import { showAlert, sortAndFilterArrByKey, sortArrByKey, uuidv4,sortArrAplhabeticalyByKey } from '../../../../../Assests/Utility';
import { AppConstant } from '../../../../../Assests/AppConstant'
import { sagaActions } from '../../../../../../sagas/sagaActions';
import WarehouseSearch from '../../../../SuperWarehouse/Components/SearchWarehouse/WarehouseSearch';
import "react-datepicker/dist/react-datepicker.css";
import "../../../../../Components/DatePicker/DatePicker.css"
import { CustomPaginationSS } from '../../../../Common/CustomPaginationSS/CustomPaginationSS';
import { getRemoteConfigSelector } from '../../../../../../redux/reducers/reducerSlices/RemoteConfigReducer';
import DrilldownTableSS from '../../Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTableSS';
import { getFsWarehouseselector } from '../../../../../../redux/reducers/reducerSlices/FsWarehouseReducer';
import { toast } from 'react-toastify';
import "./FsPayWarehouse.css";
import CustomButtonSS from '../../../../Common/CustomButton/CustomButtonSS';
import Popup from '../../../../Common/Popup/Popup';
import { CustomDynamicForm } from '../../../../Common/CustomForm/CustomDynamicForm';
import moment from 'moment-timezone';
import { getCompanyGroupReducer } from "../../../../../../redux/reducers/reducerSlices/CompanyGroupReducer";
import { getCompanyReducer } from "../../../../../../redux/reducers/reducerSlices/CompanyReducer";
/**
 * This component is used to get the FS pay warehouse details and perform actions like create, edit, delete and select. 
 * And also this screen has search with warehouse name and pagination
 * @param {*} props 
 * @returns 
 */
const FsPayWarehouse = (props) => {
    const { themeSelected = 'light-mode' } = props;
    const dispatch = useDispatch();
    const [searchStr, setSearchStr] = useState('');
    const divRef = useRef(null);
    const remoteConfig = useSelector(getRemoteConfigSelector);
    const { allConfigration } = remoteConfig;
    const { PaginationDefaultLimit } = allConfigration
    const fsWarehouseReducer = useSelector(getFsWarehouseselector)
    const { fsPayWarehouseData, isLoading, operationObj } = fsWarehouseReducer;
    const [fsPayWarehouseLoadArr, setFsPayWarehouseLoadArr] = useState([]);
    const [selectedObj, setSelectedObj] = useState(null)
    const [selectedIndex, setSelectedIndex] = useState(null)
    const [showDeleteModel, setShowDeleteModel] = useState(false);
    const [currentItemIndex, setCurrentItemIndex] = useState('NA');
    const [currentDeleteIndex, setCurrentDeleteIndex] = useState('NA');

    const [editData, setEditData] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [particularFieldValue, setParticularFieldValue] = useState(null);
    const [resetFormData, setResetFormData] = useState(0);
    const [isListLoading, setListLoading] = useState(false);
    const [selectedCreatedOnDate, setSelectedCreatedOnDate] = useState(null);
    const [comapnyArr, setComapnyArr] = useState([]);
    const [comapnyGroupArr, setComapnyGroupArr] = useState([]);
    const companyData = useSelector(getCompanyReducer);
    const companyGroupData = useSelector(getCompanyGroupReducer);


    /**
     * Tabular array of headers to display
     */
    const titleDD = [
        { id: 'warehouseName', name: 'Warehouse Name' },
        { id: 'Address', name: 'Address' },
        { id: 'relaysiteId', name: 'WH ID (Relay Site ID)' },
        // { id: 'coords', name: 'Co-ordinates' },
        { id: 'comdataSiteCode', name: 'Comdata Location ID' },
        // { id: 'prodcomdataSiteCode', name: 'Prod comdata Site Code' },
        // { id: 'terminalId', name: 'Terminal Id' }, 
        { id: 'tcheck_terminalId', name: 'TCHEK (Terminal ID)' },
        { id: 'tch_terminalId', name: 'EFS (Terminal ID)' },
        { id: 'createdOn', name: 'Created On' },
        { id: 'updatedOn', name: 'Updated On' },
        { id: '', name: AppConstant.superUserAdmin.warehouseAdmin.warehouseTable.actionsColTitle },
    ]

    /**
     * To handle API response types
     */
    useEffect(() => {
        if (operationObj.type) {
            switch (operationObj.type) {
                case 'Delete Warehouse':
                    if (operationObj.status) {
                        handleApiResponse(AppConstant.notifications.dataAddedSuccess, AppConstant.fsWarehouse.deleteSucess);
                    } else {
                        handleApiResponse(AppConstant.commonStrings.failure, AppConstant.fsWarehouse.deleteFail);
                    }
                    break;
                case 'Edit Warehouse':
                    if (operationObj.status) {
                        handleApiResponse(AppConstant.notifications.dataAddedSuccess, AppConstant.fsWarehouse.updateSucess);
                    } else {
                        handleApiResponse(AppConstant.commonStrings.failure, AppConstant.fsWarehouse.updateFail);
                    }
                    break;
                case 'Create Warehouse':
                    if (operationObj.status) {
                        handleApiResponse(AppConstant.notifications.dataAddedSuccess, AppConstant.fsWarehouse.insertSucess);
                    } else {
                        handleApiResponse(AppConstant.commonStrings.failure, AppConstant.fsWarehouse.insertFail);
                    }
                    break;
                case 'Fetch Warehouse':
                    if (!operationObj.status) {
                        setListLoading(false);
                        setFsPayWarehouseLoadArr([]);
                    }
                    break;
                default:
            }
        }
    }, [operationObj])


    /**
     * Used to get the company Group Details
     */
    useEffect(() => {
        dispatch({ type: sagaActions.RESET_COMPANY_DATA });
        dispatch({ type: sagaActions.COMPANY_GROUP, payload: { crud_type: "S" } });
    }, [])

    /**
     * Helper to handle to the API response
     * @param {*} result 
     * @param {*} message 
     */
    const handleApiResponse = (result, message) => {
        setListLoading(false);
        setCurrentItemIndex('NA')
        setCurrentDeleteIndex('NA')
        if (result === AppConstant.notifications.dataAddedSuccess) {
            showAlert(toast.TYPE.SUCCESS, message, themeSelected);
            closeCurrentpopup();

            fetchData();

        } else {
            showAlert(toast.TYPE.ERROR, message, themeSelected);

        }
        setLoading(false);
    }

    /**
   * used to filter and set the data to list if the search string is greater than 3 characters
   */
    useEffect(() => {
        if (searchStr.length > 3) {
            fetchData();
        } else if (searchStr === '') {
            fetchData();
        }
    }, [searchStr])

    /**
     * Used to set the fs warehouse data to the state variable
     */
    useEffect(() => {
        if (fsPayWarehouseData && fsPayWarehouseData?.data?.Items?.length) {
            setFsPayWarehouseLoadArr(fsPayWarehouseData)
        } else {
            setFsPayWarehouseLoadArr([])
        }

    }, [fsPayWarehouseData?.data?.Items])


    useEffect(() => {
        if (companyGroupData.data) {
            setComapnyGroupArr(sortArrByKey(companyGroupData.data, 'GroupName'));
        }
    }, [companyGroupData.data]);

    const getCompanyGroupList = () => {
        return (
            comapnyArr &&
            comapnyGroupArr.map((data, index) => {
                return (
                    data?.EnableStatus &&
                    <option key={data.CompanyGroupId} value={data.CompanyGroupId}>
                        {data.GroupName}
                    </option>
                );
            })
        );
    };

    useEffect(() => {
        if (companyData.data) {
            setComapnyArr(sortArrAplhabeticalyByKey(companyData.data, 'CompanyName'));
        }
        else {
            setComapnyArr([]);
        }
    }, [companyData.data]);

    const getCompanyList = () => {
        return (
            comapnyArr &&
            comapnyArr.map((data, index) => {
                return (
                    data?.EnableStatus &&
                    <option key={data.CompanyId} value={data.CompanyId}>
                        {data.CompanyName}
                    </option>
                );
            })
        );
    };

    const getFormFieldData = (data, name) => {

        if (name === "companyGroup") {
            dispatch({
                type: sagaActions.COMPANY_DATA,
                payload: { crud_type: "S", company_group_id: data },
            });
        }
    };

    /**
     * List of Items to show on the create and edit popup 
     */
    const formFieldList = [
        {
            name: "companyGroup",
            type: "select",
            size: "col-sm-6",
            position: 1,
            title:
                AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm
                    .companyGroup,
            formValidation: {
                required: true,
            },

            getSelectData: getCompanyGroupList,
        },
        {
            name: "company_id",
            type: "select",
            size: "col-sm-6",
            position: 2,
            title:
                AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.company,
            formValidation: {
                required: true,
            },
            getSelectData: getCompanyList,
        },
        {
            name: "whName",
            type: "text",
            size: "col-sm-6",
            position: 3,
            placeholder: '',
            title: AppConstant.fsWarehouse.whName,
            formValidation: {
                required: true,
            },
        },
        {
            name: "Address",
            type: "textarea",
            size: "col-sm-6",
            position: 4,
            title: AppConstant.fsWarehouse.address,
            formValidation: {
                required: true,
            },
        },
        {
            name: "relaysiteId",
            type: "text",
            size: "col-sm-6",
            position: 5,
            title: AppConstant.fsWarehouse.whId,
            formValidation: {
                required: true,
            },
            disabledField: Object.keys(editData).length ? true : false
        },
        {
            name: "comdataSiteCode",
            type: "text",
            size: "col-sm-6",
            position: 6,
            title: AppConstant.fsWarehouse.comdataLocationId,
            formValidation: {
                required: true,
            },
        },
        // {
        //     name: "prodcomdataSiteCode",
        //     type: "text",
        //     size: "col-sm-6",
        //     position: 7,
        //     title: AppConstant.fsWarehouse.prodcomdataSiteCode
        // },
        {
            name: "tcheck_terminalId",
            type: "text",
            size: "col-sm-6",
            position: 7,
            title: AppConstant.fsWarehouse.tcheck,
            formValidation: {
                required: true,
            },
        },
        {
            name: "tch_terminalId",
            type: "text",
            size: "col-sm-6",
            position: 8,
            title: AppConstant.fsWarehouse.efs_tcheck,
            formValidation: {
                required: true,
            },
        },
        // {
        //     name: "terminalId",
        //     type: "text",
        //     size: "col-sm-6",
        //     position: 10,
        //     title: AppConstant.fsWarehouse.terminalId,
        // },
        // {
        //     name: "LAT",
        //     type: "text",
        //     size: "col-sm-6",
        //     position: 13,
        //     title: AppConstant.fsWarehouse.lat
        // },
        // {
        //     name: "Long",
        //     type: "text",
        //     size: "col-sm-6",
        //     position: 14,
        //     title: AppConstant.fsWarehouse.lng
        // }
    ]


    /**
     * This function used to get FS pay warehouse list data by connecting with API
     */
    const fetchData = (pageNo = 1, limit = PaginationDefaultLimit) => {
        setTimeout(() => {
            const operationObj = {
                type: '',
                status: ''
            }

            const params = {
                "currentPage": pageNo,
                "itemsPerPage": limit,
                "searchParam": searchStr,
                "crudType": 'S'
            }

            operationObj.type = 'Fetch Warehouse'

            dispatch({ type: sagaActions.GET_FS_PAY_WAREHOUSE_DETAILS, payload: params, operationObj: operationObj });
        }, 0)
    }

    /**
     * Helper to delete
     * @param {*} data 
     * @param {*} index 
     */
    const deleteHandler = (data, index) => {
        setSelectedObj(data, index)
        setSelectedIndex(index)
        setShowDeleteModel(true)
    }

    /**
     * delete popup handler to delete the records based on the confirmation
     * @param {*} text 
     */
    const popupBtnHandler = (text) => {
        if (text === AppConstant.commonStrings.no) {
            setShowDeleteModel(false)
            setSelectedObj(null)
            setSelectedIndex(null)
        }
        else {
            setCurrentDeleteIndex(selectedIndex)
            const apiParams = {
                "relaysiteId": selectedObj.relaysiteId,
                "crudType": 'D'
            }

            const operationObj = {
                type: 'Delete Warehouse',
                status: ''
            }

            dispatch({ type: sagaActions.GET_FS_PAY_WAREHOUSE_DETAILS, payload: apiParams, operationObj: operationObj })
        }
        setShowDeleteModel(false)
    }

    /**
     * Helper to show the delete conformation pop-up
     * @returns 
     */
    const showDeleteConfirmation = () => {
        const popupObj = {
            id: 'showDeleteConfirmation',
            modalLabel: 'showDeleteConfirmation',
            showClose: true,
            showfooter: true,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title: AppConstant.fsWarehouse.deleteTimeslotTitle,
            bodyText: `${AppConstant.commonStrings.areYouSure} ${AppConstant.fsWarehouse.deleteTimeslotTitle}`,
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.commonStrings.yes
                },
                btn2: {
                    show: true,
                    text: AppConstant.commonStrings.no
                }
            },
        }

        return <Popup {...popupObj} popupBtnHandler={(text) => { popupBtnHandler(text) }} closepopup={() => { setShowDeleteModel(false) }} themeSelected={themeSelected} showModal={showDeleteModel} />
    }

    /**
     * Helper function to get the submited data and forms it to create or update
     * @param {*} data 
     */
    const getSummitedFormData = (data) => {
        setLoading(true);
        let apiParams = {}
        const operationObj = {
            type: '',
            status: ''
        }
        if (Object.keys(editData).length) {
            const apiTempParams1 = {
                "companyGroup_id": data.companyGroup,
                "company_id": data.company_id,
                "company": findTheNameBasedOntheID(comapnyArr, data.company_id, 'COMPANY'),
                "companyGroupName": findTheNameBasedOntheID(comapnyGroupArr, data.companyGroup, 'COMPANYGROUP'),
                "relaysiteId": data.relaysiteId,
                "Address": data.Address,
                "comdataSiteCode": data.comdataSiteCode,
                // "prodcomdataSiteCode": data.prodcomdataSiteCode,
                "tcheck_terminalId": data.tcheck_terminalId,
                "tch_terminalId": data.tch_terminalId,
                "createdOn": selectedCreatedOnDate ? selectedCreatedOnDate.trim() : null,
                // "coordinates": {
                //     "LAT": data.LAT,
                //     "Long": data.Long
                // },
                // "terminalId": data.terminalId,
                "warehouseName": data.whName
            }
            operationObj.type = 'Edit Warehouse'

            apiParams = {
                "crudType": 'U',
                "data": apiTempParams1
            }
        } else {
            const apiTempParams2 = {
                "companyGroup_id": data.companyGroup,
                "company_id": data.company_id,
                "company": findTheNameBasedOntheID(comapnyArr, data.company_id, 'COMPANY'),
                "companyGroupName": findTheNameBasedOntheID(comapnyGroupArr, data.companyGroup, 'COMPANYGROUP'),
                "id": uuidv4(),
                "relaysiteId": data.relaysiteId,
                "Address": data.Address,
                "comdataSiteCode": data.comdataSiteCode,
                // "prodcomdataSiteCode": data.prodcomdataSiteCode,
                "tcheck_terminalId": data.tcheck_terminalId,
                "tch_terminalId": data.tch_terminalId,
                // "coordinates": {
                //     "LAT": data.LAT,
                //     "Long": data.Long
                // },
                // "terminalId": data.terminalId,
                "warehouseName": data.whName
            }

            operationObj.type = 'Create Warehouse'

            apiParams = {
                "crudType": 'I',
                "data": apiTempParams2
            }
        }
        dispatch({ type: sagaActions.GET_FS_PAY_WAREHOUSE_DETAILS, payload: apiParams, operationObj: operationObj })
    };


    function findTheNameBasedOntheID(list, id, type) {

        if(type === 'COMPANY'){
            const idObject = list.find((obj) => obj.CompanyId === id);
            return idObject ? idObject.CompanyName : null;
        }else{
            const idObject = list.find((obj) => obj.CompanyGroupId === id);
            return idObject ? idObject.GroupName : null;
        } 
        
      }

    const closeCurrentpopup = () => {
        setShowModal(false);
    }

    /**
     * Helper function to show to popup to create the warehouse
     * @returns 
     */
    const showCreatePOPopup = () => {
        const popupObj = {
            id: 'createFsWarehouseFormPopup',
            modalLabel: 'createFsWarehouseFormPopup',
            showClose: true,
            showfooter: false,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'xl',
            title: !Object.keys(editData).length ? AppConstant.fsWarehouse.createFsWarehouse : AppConstant.fsWarehouse.editFsWarehouse,
            bodyText: createForm(),
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.comments.cancelCommentButtonTitle
                },
                btn2: {
                    show: true,
                    text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.saveButtonTitle
                }
            },
        }

        return <Popup {...popupObj} popupBtnHandler={(text) => () => ''} closepopup={closeCurrentpopup} themeSelected={themeSelected} showModal={showModal} />
    }

    const openCreateForm = () => {
        setShowModal(true);
        setEditData({});
    }
    const cancelbtndata = {
        show: true,
        text: AppConstant.comments.resetbutton,
    };
    const submitbtnData = {
        show: true,
        text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm
            .saveButtonTitle,
    };

    /**
     * function to create form
     * @returns 
     */
    const createForm = () => {
        return (
            <CustomDynamicForm
                formFieldList={formFieldList}
                sort={true}
                cancelBtn={cancelbtndata}
                submitbtn={submitbtnData}
                themeSelected={themeSelected}
                getSummitedFormData={getSummitedFormData}
                getFormFieldData={getFormFieldData}
                editFormData={editData}
                loading={loading}
                resetFormData={resetFormData}
                setParticularFieldValue={particularFieldValue}
            />
        );
    };

    /**
     * Helper to get the data and populate on the screen while editing
     * @param {*} data 
     */
    const editClicked = (data) => {
        dispatch({
            type: sagaActions.COMPANY_DATA,
            payload: { crud_type: "S", company_group_id: data.CompanyGroupId, },
          });

        let editData1 = {
            "companyGroup": data.companyGroup_id,
            "company_id": data.company_id,
            "relaysiteId": data?.relaysiteId,
            "Address": data?.Address,
            "comdataSiteCode": data?.comdataSiteCode,
            // "prodcomdataSiteCode": data?.prodcomdataSiteCode,
            "tcheck_terminalId": data?.tcheck_terminalId,
            "tch_terminalId": data?.tch_terminalId,
            // "LAT": data?.coordinates?.LAT,
            // "Long": data?.coordinates?.Long,
            // "terminalId": data?.terminalId,
            "whName": data?.warehouseName

        }
        setSelectedCreatedOnDate(data.createdOn);
        setEditData(editData1);
        setShowModal(true);
    }

    /**
     * Function to create action buttons
     * @param {*} groupObj 
     * @param {*} index 
     * @returns 
     */
    const actionsButton = (groupObj, index) => {
        return (
            <div>
                <span className='d-flex justify-content-center align-items-center gap-2' >
                    <button
                        className={'addButton'}
                        data-bs-toggle="modal"
                        data-bs-target={`#createFsWarehouseFormPopup`}
                        onClick={() => editClicked(groupObj)}
                    >
                        {AppConstant.commonStrings.edit}
                    </button>
                    <CustomButtonSS
                        disabled={false}
                        isLoading={currentDeleteIndex !== 'NA' && currentDeleteIndex === index ? true : false}
                        className={`no-border no-bg`}
                        onClick={() => { deleteHandler(groupObj, index) }}
                        isIcon={true}
                        iconClass={"fa-regular fa-trash-can fa-lg"}
                    />
                </span>
            </div>
        )
    }

    /**
     * function to show the formated list data on the screen
     * @param {*} data 
     * @returns 
     */
    const formatListDataDrillDown = (data) => {

        return data.map((el, index) => {
            return {
                0: el.warehouseName || '-',
                1: el.Address || '-',
                2: el.relaysiteId || '-',
                // 3: el.coordinates ? `lat: ${el.coordinates.LAT} \n lng: ${el.coordinates.Long}` : '-',
                3: el.comdataSiteCode || '-',
                4: el.tcheck_terminalId || '-',
                5: el.tch_terminalId || '-',
                // 5: el.prodcomdataSiteCode || '-',
                // 5: el.terminalId || '-',
                6: el.createdOn ? `${moment(el.createdOn).format(AppConstant.commonStrings.MM_DD_YYYY_dateFormat)}\n${moment(el.createdOn).format(AppConstant.commonStrings.HH_mm_dateFormat)}` : AppConstant.commonStrings.hypen,
                7: el.updatedOn ? `${moment(el.updatedOn).format(AppConstant.commonStrings.MM_DD_YYYY_dateFormat)}\n${moment(el.updatedOn).format(AppConstant.commonStrings.HH_mm_dateFormat)}` : AppConstant.commonStrings.hypen,
                8: actionsButton(el, index),
            }
        })
    }

    /**
     * This function is used to get the data and validate
     * @returns 
     */
    const getBodyData = () => {
        if (fsPayWarehouseLoadArr && fsPayWarehouseLoadArr?.data?.Items?.length) {
            return formatListDataDrillDown(fsPayWarehouseLoadArr?.data?.Items)
        }
        else {
            return []
        }
    }

    /**
     * Function used when page is changed in pagination then it fetches user rating deails
     * @param {*} pageNo 
     * @param {*} limit 
     */
    const pageChange = (pageNo, limit) => {
        fetchData(pageNo, limit)
    }

    return (
        <div className=''>
            <div className='d-flex justify-content-between my-2 mx-3'>
                <button
                    className={'addButton'}
                    data-bs-toggle="modal"
                    onClick={() => openCreateForm()}
                >
                    {AppConstant.fsWarehouse.addFsWarehouseTitle}
                </button>

                <div className='d-flex justify-content-end align-items-end'>
                    <div className='ms-2 w-100'>
                        <WarehouseSearch
                            placeholder={AppConstant.poManagement.searchPOSection.searchTitleWarehouseStr}
                            inputValue={searchStr}
                            cancelSearch={() => setSearchStr('')}
                            onChangeHandler={(text) => setSearchStr(text)} />
                    </div>

                </div>

            </div>
            <div className='fswarehouseContainer' ref={divRef}>
                <DrilldownTableSS
                    key={Math.random()}
                    themeSelected={themeSelected}
                    bodyData={getBodyData()}
                    titleData={titleDD}
                    showCollapse={false}
                    showInput={false}
                    showSort={true}
                    hasCollapsableContent={false}
                    collapsableData={[]}
                    accordionPrimaryIndex={1}
                    drillDownReport={fsPayWarehouseLoadArr?.data?.Items}
                    initiateDrillDownReport={isLoading}
                    loaderArray={9}
                    sortingAction={sagaActions.SORT_SS_USER_TABLE}
                    subRoot={{ pathName: 'FsWarehouseObj' }}
                    showUTCTime={true}
                />
                {showDeleteConfirmation()}

            </div>
            {showCreatePOPopup()}

            {!_.isEmpty(fsPayWarehouseLoadArr) && fsPayWarehouseLoadArr?.data?.Items?.length ?
                <div className='f-14'>
                    <CustomPaginationSS
                        pageNo={fsPayWarehouseLoadArr.data.currentPage}
                        pageSize={fsPayWarehouseLoadArr.data.itemsPerPage}
                        first={(Number(fsPayWarehouseLoadArr.data.currentPage) === 1) ? true : false}
                        last={Math.ceil(fsPayWarehouseLoadArr.data.totalCount / Number(fsPayWarehouseLoadArr.data.itemsPerPage)) === (Number(fsPayWarehouseLoadArr.data.currentPage)) ? true : false}
                        totalElements={fsPayWarehouseLoadArr.data.totalCount}
                        totalPages={fsPayWarehouseLoadArr.data.totalPages}
                        pageChange={(pageNo, limit) => { pageChange(pageNo, limit) }}
                    />
                </div> : ""
            }

        </div>
    )
}

export default FsPayWarehouse