import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash-es';
import { sagaActions } from '../../../../../../../../sagas/sagaActions';
import { AppConstant } from '../../../../../../../Assests/AppConstant';
import { getCurrentDate } from '../../../../../../../../Utils/DateUtils';
import { initiateSearch, showAlert, changeFormatWithoutTz } from '../../../../../../../Assests/Utility';
import { toast } from 'react-toastify';
import { getUserSelector } from '../../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { getWhMetaDataReducerSlice } from '../../../../../../../../redux/reducers/reducerSlices/WhMetaDataReducerSlice';
import './NotSchPosConflictForm.css';
import moment from 'moment';
import DrilldownTableSS from '../../../../../Components/Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTableSS';
import CustomButtonSS from '../../../../../../Common/CustomButton/CustomButtonSS';
import SSPopup from '../../../../../../Common/Popup/SSPopup/SSPopup';

const NotSchPosConflictFormSS = (props) => {

    const { themeSelected, dockPayload } = props;
    const dispatch = useDispatch();
    const whMetaReducer = useSelector(getWhMetaDataReducerSlice);
    const { operationObj, arrangeOrder, arrangeKey, dockConflictObj, isLoading } = whMetaReducer;
    const [isListLoading, setListLoading] = useState(false);
    const [metaTimeslotArr, setMetaTimeslotArr] = useState([]);
    const [dockDoorConflictArr, setdockDoorConflictArr] = useState([]);
    const user = useSelector(getUserSelector);
    const { cu_id } = user;
    const [currentItemIndex, setCurrentItemIndex] = useState('NA');
    const [currentDeleteIndex, setCurrentDeleteIndex] = useState('NA');
    const [selectedObj, setSelectedObj] = useState(null)
    const [selectedIndex, setSelectedIndex] = useState(null)
    const [showDeleteModel, setShowDeleteModel] = useState(false);
    const [showLoading, setShowLoading] = useState(true);
    const [selectedPoObj, setSelectedPoObj] = useState({});

    const createPoTitleDD = [
        { id: '', name: AppConstant.superUserAdmin.companyGroup.tableHeader.serialNum },
        { id: 'PoNumber', name: AppConstant.poManagement.tableHeader.po },
        { id: 'Pallets', name: AppConstant.smartScheduler.poSection.palletsCount },
        { id: 'PoCreatedOn', name: AppConstant.superUserAdmin.companyGroup.tableHeader.createdOn },
        { id: '', name: AppConstant.superUserAdmin.companyGroup.tableHeader.actions },
    ]

    /**
     * used to set the dock conflict object data
     */
    useEffect(() => {
        if (dockConflictObj && dockConflictObj.pos.length) {
            setShowLoading(false);
            setdockDoorConflictArr(dockConflictObj.pos)
        } else {
            setdockDoorConflictArr([])
            setShowLoading(false)
        }

    }, [dockConflictObj.pos])


    /**
     * Used to handle API responses
     */
    useEffect(() => {
        if (operationObj.type) {
            switch (operationObj.type) {
                case 'Delete POs':
                    if (operationObj.status) {
                        handleApiResponse(AppConstant.notifications.dataAddedSuccess, AppConstant.smartScheduler.poSection.deletePOSuccess);
                    } else {
                        handleApiResponse(AppConstant.commonStrings.failure, AppConstant.smartScheduler.poSection.deletePOFailure);
                    }
                    break;
                default:
            }
        }
    }, [operationObj])

    /**
     * Used to show the response based on success and failure response
     * @param {*} result 
     * @param {*} message 
     */
    const handleApiResponse = (result, message) => {
        setCurrentItemIndex('NA')
        setCurrentDeleteIndex('NA')
        if (result === AppConstant.notifications.dataAddedSuccess) {
            showAlert(toast.TYPE.SUCCESS, message, themeSelected);
            dispatchDockConflict()
        } else {
            showAlert(toast.TYPE.ERROR, message, themeSelected);
        }
        setListLoading(false);
        dispatch({ type: sagaActions.RESET_OPERATION_OBJECT_WH });
    }

    const dispatchDockConflict = () => {
        dispatch({ type: sagaActions.GET_DOCK_CONFLICT_DETAILS, payload: dockPayload });
    }
    /**
     * helper function
     * @param {*} data 
     * @param {*} index 
     */
    const deleteHandler = (data, index) => {
        setSelectedPoObj(data)
        setSelectedIndex(index)
        setShowDeleteModel(true)
    }
    /**
     * helper function
     * @param {*} text 
     */
    const popupBtnHandler = (text) => {
        if (text === AppConstant.commonStrings.no) {
            setShowDeleteModel(false)
            setSelectedPoObj({})
            setSelectedIndex(null)
        }
        else {
            setCurrentDeleteIndex(selectedIndex)
            deletePoAppt();
        }
    }

    /**
     * used to call API
     */
    const deletePoAppt = () => {
        const newItemPayload = {
            PoNumber: selectedPoObj.PORelationID,
            IDWarehouse: selectedPoObj.IDWarehouse,
            ReceivingZipCode: selectedPoObj.ReceivingZipCode,
            ApptCreatedBy: cu_id,
            PORelationID: selectedPoObj.PORelationID,
            IDDock: selectedPoObj.IDDock,
            CRUDTYPE: 'PD',
            IDPO: selectedPoObj.IDPO,
            rescheduleReason: 'Not Scheduled PO Deleted Due To Dock Deletion'
        }
        const operationObj = {
            type: 'Delete POs',
            status: ''
        }

        dispatch({ type: sagaActions.DELETE_POS_AND_APPOINTMENTS, payload: newItemPayload, operationObj: operationObj })
        setSelectedPoObj({})
        setShowDeleteModel(false);
    }

    /**
     * Function is used to show delete confirmation pop-up
     * @returns 
     */
    const showDeleteConfirmation = () => {
        const popupObj = {
            id: 'showDeleteConfirmation',
            modalLabel: 'showDeleteConfirmation',
            showClose: true,
            showfooter: true,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title: AppConstant.smartScheduler.poSection.deletePOTitle,
            bodyText: `${AppConstant.commonStrings.areYouSure} ${AppConstant.smartScheduler.poSection.deletePOTitle}`,
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.commonStrings.yes
                },
                btn2: {
                    show: true,
                    text: AppConstant.commonStrings.no
                }
            },
        }

        return <SSPopup {...popupObj} popupBtnHandler={(text) => { popupBtnHandler(text) }} closepopup={() => { setShowDeleteModel(false) }} themeSelected={themeSelected} showModal={showDeleteModel} />
    }

    /**
     * used to creat custom button
     * @param {*} groupObj 
     * @param {*} index 
     * @returns 
     */
    const actionsButton = (groupObj, index) => {
        return (
            <div>
                <span className='d-flex justify-content-center align-items-center gap-2' >
                    <CustomButtonSS
                        disabled={false}
                        isLoading={currentDeleteIndex !== 'NA' && currentDeleteIndex === index ? true : false}
                        className={`no-border no-bg`}
                        onClick={() => { deleteHandler(groupObj, index) }}
                        isIcon={true}
                        iconClass={"fa-regular fa-trash-can fa-lg"}
                    />
                </span>
            </div>
        )
    }

    /**
     * used to format the list
     * @param {*} data 
     * @returns 
     */
    const formatListDataDrillDown = (data) => {
        return data.map((el, index) => {
            return {
                id: el.RoleID,
                0: index + 1,
                1: el.PoNumber || AppConstant.commonStrings.hypen,
                2: el.Pallets || AppConstant.commonStrings.hypen,
                3: `${moment(el.PoCreatedOn).format(AppConstant.commonStrings.MM_DD_YYYY_dateFormat)}\n${moment(el.PoCreatedOn).format(AppConstant.commonStrings.HH_mm_dateFormat)}` || AppConstant.commonStrings.hypen,
                4: actionsButton(el, index),
            }
        })
    }

    return (
        <>
            {dockDoorConflictArr &&
                <DrilldownTableSS
                    themeSelected={themeSelected}
                    bodyData={dockDoorConflictArr.length && formatListDataDrillDown(dockDoorConflictArr)}
                    titleData={createPoTitleDD}
                    showCollapse={false}
                    showInput={false}
                    showSort={true}
                    arrangeOrder={arrangeOrder}
                    arrangeKey={arrangeKey}
                    drillDownReport={dockDoorConflictArr}
                    initiateDrillDownReport={isListLoading}
                    loaderArray={4}
                    sortingAction={sagaActions.SORT_WH_META_TABLE_ACTION}
                    subRoot={{ pathName: 'whMetaTimeslotArr' }}
                    showUTCTime={false}
                />}
            {showDeleteConfirmation()}
        </>
    )

}

export default NotSchPosConflictFormSS;