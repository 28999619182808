import React from 'react';
import './Popup.css';
import { AppConstant } from '../../../Assests/AppConstant';
/**
 * This is a reusable component for rendering Popup using react bootstrap with OLD UI
 * @param {*} props 
 * id = id for popup
 * modalLabel = modal label
 * showClose = show close button true/false
 * showfooter = show footer true/false
 * showHeader = show modal header true/false
 * footerBtn = footer buttons
 * title = modal title
 * bodyText = modal body text
 * popupBtnHandler = popup button handler
 * modalSize = modal size
 * subText = Modal Sub Text
 * sendDriverNotification = IMS Door assignment popup, checkbox to send driver notification
 * setSendDriverNotification = set driver notification
 * closepopup = close popup handler function
 * @returns 
 */
const Popupold = (props) => {
    const { id, modalLabel, showClose, showfooter, showHeader, footerBtn, title, bodyText, popupBtnHandler, themeSelected, modalSize, subText, sendDriverNotification, setSendDriverNotification, backdrop, keyboard, className, closepopup } = props;


    return (
        <div className="modal fade" id={id} tabIndex="-1" aria-labelledby={`${modalLabel}ModalLabel`} aria-hidden="true" data-bs-backdrop={backdrop} data-bs-keyboard={keyboard} data-testid={AppConstant.dataTestId.popup}>
            <div className={`modal-dialog modal-dialog-centered modal-${modalSize} ${themeSelected}`}>

                <div className={`modal-content my-modal-content  ${className}`}>
                    {/* <div className={`modal-content my-modal-content allowScrolling ${className}`}> */}
                    {showHeader ? <div className="modal-header my-modal-header">
                        <h5 className="modal-title" id={modalLabel}>{title}</h5>
                        {showClose ? <button type="button" id="close-btn" data-testid={AppConstant.dataTestId.closeBtn} className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closepopup}></button> : ''}
                    </div> : ''}
                    <div className="modal-body m-0 p-2 pt-1">
                        {bodyText}
                    </div>
                    {subText ? <div className="modal-body m-0 p-2 pt-1 d-flex">
                        <input checked={sendDriverNotification} onClick={() => { setSendDriverNotification(!sendDriverNotification) }} value={sendDriverNotification} type="checkbox" name={'ad'} />
                        <label className="px-2 heading" htmlFor={'ad'}>{subText}</label>
                    </div> : ''}
                    {showfooter ? <div className="modal-footer my-modal-footer">
                        <button type="button" className={`btn btn-reschedule ${themeSelected}`} data-bs-dismiss="modal" onClick={() => { popupBtnHandler(footerBtn.btn1.text) }}>{footerBtn.btn1.text}</button>
                        <button type="button" className={`btn btn-approve ${themeSelected}`} data-bs-dismiss="modal" onClick={() => { popupBtnHandler(footerBtn.btn2.text) }}>{footerBtn.btn2.text}</button>
                    </div> : ''}
                </div>
            </div>
        </div>
    );
}

export default Popupold;