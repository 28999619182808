import React, { useEffect, useState } from 'react'
import "../MainLogin/Login.css"
import CustomButton from '../../../Common/CustomButton/CustomButton';
import PasswordInput from '../../../Common/PasswordInput/PasswordInput';
import { sagaActions } from '../../../../../sagas/sagaActions';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthReducer } from '../../../../../redux/reducers/reducerSlices/AuthReducer';
import { useNavigate } from 'react-router-dom';
import { AppConstant } from '../../../../Assests/AppConstant';
import { isNil } from 'lodash-es';
import { isValid } from '../../utils';
import { removeInterceptor } from '../../../../../redux/api/Interceptor';
import _ from 'lodash-es';
import { getUserSelector } from '../../../../../redux/reducers/reducerSlices/UserReducer';

/**
 * This component renders verify password screen which helps user to reset password
 * @returns JSX
 */
const SetPassword = () => {
    const dispatch = useDispatch()
    const [newPassword, setnewPassword] = useState("")
    const [confirmPassword, setconfirmPassword] = useState("")
    const [error, setError] = useState(false)
    const [isloading, setisloading] = useState(false)
    const user = useSelector(getAuthReducer);
    const navigate = useNavigate();
    const [passwordError, setpasswordError] = useState(null)
    const userPermission = useSelector(getUserSelector);
    const { branding } = userPermission;
    const [bgImage, setBgImage] = useState('')
    const [warehouseLogo, setWarehouseLogo] = useState('')
    const backgroundImage = AppConstant.commonStrings.bkgImgLink;

    /**
     * This is handler function for reseting password
     * @returns 
     */
    const initiateSetPassword = () => {
        // Validate if new password and confirm password is same
        if (newPassword !== confirmPassword) {
            setError(true)
            return setpasswordError(AppConstant.login.passwordMismatch)
        }

        setError(false)

        const data = {
            user: user.auth,
            newPassword: newPassword
        }
        // dispatch action for new password
        dispatch({ type: sagaActions.SET_PASSWORD, payload: data })
    }
    /** 
     * useEffect for password status
     */
    useEffect(() => {
        if (!isNil(user.setPasswordStatus)) {
            dispatch({ type: sagaActions.CLEAR_SET_PASSWORD })
                removeInterceptor()
                dispatch({ type: sagaActions.CLEAR_SIGNEDIN_USER })
                dispatch({ type: sagaActions.RESET_USER })
                window.localStorage.clear();
                navigate("/")
        }
    }, [user.setPasswordStatus])
    /**
     * useEffect for reset password loader
     */
    useEffect(() => {
        setisloading(user.setPasswordLoading)
    }, [user.setPasswordLoading])

    /**
     * useEffect for reset password error
     */
    useEffect(() => {
        if (!isNil(user.setPasswordError)) {
            setError(true)
            dispatch({ type: sagaActions.CLEAR_SIGNIN_ERROR })
        }
    }, [user.setPasswordError])

    /**
     * useEffect to validate if new password and confirm password are same
     */
    useEffect(() => {
        if (isValid('password', newPassword) && isValid('password', confirmPassword)) {
            setError(false)
        } else {
            setError(true)
        }
    }, [newPassword, confirmPassword])

     /** 
     * useEffect for branding data
     */
    useEffect(() => {
        if(!_.isEmpty(branding)){
            const {IsBranding, WarehouseLogo, BackgroundImage, CompanyLogo, GroupLogo } = branding
            if(IsBranding){
                setBgImage(BackgroundImage)
            setWarehouseLogo(WarehouseLogo ? WarehouseLogo: CompanyLogo ? CompanyLogo: GroupLogo ? GroupLogo : AppConstant.commonStrings.logo_wordMark)
        }
    }
    }, [branding])

    return (
        <div className='d-flex justify-content-start align-items-center bgContainer px-0 mx-0'>
             <img className={'bgimage'} src={bgImage ? bgImage : backgroundImage} alt='Background Image' />
            <div className='d-flex parentContainer justify-content-center align-items-center'>
                <div className='d-flex flex-column loginContainer justify-content-center align-items-center'>
                    <div data-testid={AppConstant.dataTestId.logoContainer}
                        className='d-flex justify-content-center align-items-center logoContainer'>
                        <img className="login-logo" src={warehouseLogo ? warehouseLogo : AppConstant.commonStrings.logo_wordMark} />                  
                        </div>
                        {/* <span data-testid={AppConstant.dataTestId.title}>
                        {formatFirstCharBold(AppConstant.login.loginTitle)}
                    </span> */}
                    <div className='passwordDiv'>
                        <PasswordInput
                            showInfo={true}
                            passwordInfo={AppConstant.login.recover.passwordInfoTooltip}
                            errorMessage={passwordError || AppConstant.login.passwordValidation}
                            showError={error}
                            title={AppConstant.login.newPassword} placeHolder={AppConstant.login.enterPassword}
                            onPasswordChange={setnewPassword} />
                    </div>
                    <div className='passwordDiv'>
                        <PasswordInput
                            showInfo={true}
                            passwordInfo={AppConstant.login.recover.passwordInfoTooltip}
                            errorMessage={passwordError || AppConstant.login.passwordValidation}
                            showError={error}
                            title={AppConstant.login.confirmPassword} placeHolder={AppConstant.login.enterPassword}
                            onPasswordChange={setconfirmPassword} />
                    </div>
                    <div className='d-flex justify-content-end loginButtonContainer pt-2'>
                        <CustomButton
                            isLoading={isloading}
                            title={AppConstant.commonStrings.submit} className={"loginButton"}
                            onClick={() => initiateSetPassword()} />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default SetPassword
