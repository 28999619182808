import React, { useEffect, useRef, useState } from 'react'
import ScheduleProWrapper from '../../../../../../SmartSchedule/SchedulePro/ScheduleProWrapper'
import { useNavigate, useParams } from 'react-router-dom'
import { smartScheduleSelector } from '../../../../../../../../redux/reducers/reducerSlices/SmartScheduleReducer';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { sagaActions } from '../../../../../../../../sagas/sagaActions';
import { getUserSelector } from '../../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { AppConstant } from '../../../../../../../Assests/AppConstant';
import AppointmentReportSummarySubHeader from './Components/AppointmentReportSummarySubHeader';
import { CustomPaginationSS } from '../../../../../../Common/CustomPaginationSS/CustomPaginationSS';
import DrilldownTableSS from '../../../../Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTableSS';
import { getRemoteConfigSelector } from '../../../../../../../../redux/reducers/reducerSlices/RemoteConfigReducer';
import moment from 'moment';
import _ from 'lodash'
import WarehouseSearchSS from '../../../../../../SuperWarehouse/Components/SearchWarehouse/WarehouseSearchSS';
import ModalSS from '../../../../../../SmartSchedule/SchedulePro/Components/Common/ModalSS/ModalSS';
import AppointmentReportHistory from './Components/AppointmentReportHistory';
import './AllAppointmentsListSS.css';
import UploadSproDocumentsPopup from '../../../../../../Common/UploadSproDocumentsPopup/UploadSproDocumentsPopup';

/**
 * Reusable drill down report for appointment summary
 * @param {*} props 
 * @returns 
 */
const DrillDownAppointmetReportSummary = (props) => {
    const { themeSelected } = props;
    const {groupByType, startDate, endDate} = useParams();
    const ss = useSelector(smartScheduleSelector);
    const { appointmentReportSummary,initateLoading, appointmentDocumentsList, } = ss;
    const dispatch = useDispatch();
    const userPermission = useSelector(getUserSelector);
    const { selectedWarehouse,userType ,cu_id} = userPermission;
    const [selectedStartDate,setSelectedStartDate] = useState(null)
    const [selectedEndDate,setSelectedEndDate] = useState(null)
    const remoteConfig = useSelector(getRemoteConfigSelector);
    const { allConfigration } = remoteConfig;
    const { PaginationDefaultLimit } = allConfigration;
    const [allApptsArr, setAllApptsArr] = useState([]);
    const [searchStr, setSearchStr] = useState('');
    const first=useRef(true)
    const navigate = useNavigate();
    const [showAppointmentHistoryModal, setShowAppointmentHistoryModal] = useState(false);
    const [apptNumber, setapptNumber] = useState(null);
    const [showUploadDocsModal, setShowUploadDocsModal] = useState(false)

    /**
     * useEffect to form appointments data
     */
    useEffect(() => {
        if (appointmentReportSummary && appointmentReportSummary?.data && appointmentReportSummary?.data?.length) {
            setAllApptsArr(appointmentReportSummary.data)
        } else {
            setAllApptsArr([])
        }

    }, [appointmentReportSummary])

    /**
 * useEffect to set start date in redux
 */
    useEffect(() => {
        if(selectedStartDate){
            dispatch({ type: sagaActions.UPDATE_START_DATE, payload: selectedStartDate })
        }
    },[selectedStartDate])

/**
 * useEffect to set end date in redux
 */
    useEffect(() => {
        if (selectedEndDate) {
            dispatch({type:sagaActions.UPDATE_END_DATE, payload:selectedEndDate})
        }
    },[selectedEndDate])


    /**
     * useEffect to handle startDate and endDate
     */
    useEffect(()=>{
        if (startDate!='null' && endDate!='null') {
            setSelectedStartDate(new Date(startDate))
            setSelectedEndDate(new Date(endDate))
        }else{
            setSelectedStartDate(null)
            setSelectedEndDate(null)
        }
  
    }, [startDate, endDate])
    
    /**
     * useEffect to fetch data one start or end date is modified
     */
    
    useEffect(() => {
        if (selectedStartDate && selectedEndDate) {
            fetchApptByType()
        }
    },[selectedStartDate, selectedEndDate])

    // /**
    //  * useEffect to call fetch appointment by groupby type (warehosue/carrier)
    //  */
    // useEffect(()=>{
    //     if (groupByType) {
    //         setTimeout(()=>{
    //         fetchApptByType()
    //     },[0])
    //     }
    // },[groupByType])

    /**
     * useEffect to handle search 
     */
    useEffect(()=>{
        if (first.current) {
            first.current=false
            return
        }
        else{
            if (searchStr.length>=4) {
                setTimeout(()=>{
                    fetchApptByType()
                },[1000])
            }else if(searchStr===''){
                fetchApptByType()
            }
            
        }
    }, [searchStr])

    useEffect(() => {
        if (showUploadDocsModal) {
           fetchUploadDocsDataForAppt()
        }

    }, [showUploadDocsModal])

    const fetchUploadDocsDataForAppt = () => {
        if (apptNumber) {
            dispatch({type: sagaActions.INTIATE_SS_LOADER, payload: true})
            let fd = new FormData();
            fd.append('AppointmentNumber', apptNumber)
            fd.append('file', null); // Assuming 'file' is the file object
            fd.append('docName', null); // Append the document name
            fd.append('ApptDocsCreatedBy', null);
            fd.append('ApptDocsUpdatedBy', null);
            fd.append('CRUDTYPE', 'S');
            dispatch({
                type: sagaActions.UPLOAD_APPT_DOCS,
                payload: {
                    CRUDTYPE: 'S',
                    uploadData: fd
                }
            });
        }
    }

    const documentModelHandler = (el) => {
        if (showUploadDocsModal) {
            setapptNumber(null)
        } else {
            setapptNumber(el?.AppointmentNumber)
        }
        setShowUploadDocsModal(!showUploadDocsModal)
    }

    const toggleModal = () => {
        setShowAppointmentHistoryModal(!showAppointmentHistoryModal);
    }

    /**
     * Function to fetch appt data
     * @param {*} pageNo 
     * @param {*} limit 
     */
    const fetchApptByType=(pageNo=1,limit=PaginationDefaultLimit)=>{
        const payload={
            "WHID": selectedWarehouse.ID,
            "Type": "tabular",
            "drilldownType": groupByType.toUpperCase()===AppConstant.commonStrings.carrierStr?AppConstant.commonStrings.carrierStr:'USER',
            "pageNo": pageNo,
            "limit": limit,
            "startDate":selectedStartDate? moment(selectedStartDate).format(AppConstant.commonStrings.YYYY_MM_DD):null,
            "endDate": selectedEndDate?moment(selectedEndDate).format(AppConstant.commonStrings.YYYY_MM_DD):null,
            "searchParam": searchStr ? searchStr : null,
          }
        dispatch({ type: sagaActions.APPOINTMENT_REPORT_SUMMARY, payload: payload})

    }

    const getApptSchStatusColor = (str) => {
        if (str.toLowerCase() === 'completed') {
            return <p className='text-success'>{str}</p>
        } else if (str.toLowerCase() === 'scheduled') {
            return <p className='text-danger'>{str}</p>
        } else {
            return <p className='notScheStatusText'>{str}</p>
        }
    }


    const apptsTitleDDWH = [
        { id: 'serial_num', name: 'S.No' },
        { id: 'ApptNumber', name: 'Appt Number' },
        { id: 'PoNumber', name: 'PO' },
        { id: 'CarrierName', name: 'Carrier' },
        { id: 'DueDate', name: 'Due Date' },
        { id: 'ApptStartTime', name: 'Appt Date & Time' },
        { id: 'DockName', name: 'Dock' },
        { id: 'Pallets', name: 'Pallets' },
        { id: 'ItemsCases', name: AppConstant.smartScheduler.poSection.cases  },
        { id: 'ApptScheStatus', name: 'Status' },
        { id:'uploadDocs', name: 'Documents' },
        { id: 'Accordion', name: 'Multi PO' },
    ]

    /**
     * Function to form data for table
     * @param {*} data 
     * @returns 
     */
    const formatDrillDownData = (data) => {
        return _.isArray(data) && data.map((el,index) => {
            return {
                id: el.RoleID,
                0: index + 1,
                1:  <span style={{textDecoration: 'underline',color: 'blue'}} onClick={() => fetchApptsReportHistoryDetails(el.AppointmentNumber)}>{el.AppointmentNumber}</span>,
                2: el.PoNumber,
                3: el.CarrierName || '-',
                4: moment(el.DueDate).utc().format('MM/DD/YYYY') || '-',
                5: moment(el.ApptStartTime).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') || '-',
                6: el.DockName || '-',
                7: el.Pallets || '-',
                8: el.ItemsCases || '-',
                9: el.ApptScheStatus ? getApptSchStatusColor(el.ApptScheStatus) : '-',
                10: <span onClick={() => documentModelHandler(el)}><i className="fa-solid fa-circle-info text-grey fa-lg" title='Show Attachments'></i></span>
            }
        })
    }

    /**
     * Function to form data for multi po
     * @param {*} data 
     * @returns 
     */
    const formatdata = (data) => {
        // let result = []
        // const tempArr = data.map(ele => {
        //     if (ele.PORelationID === ele.PoNumber) {
        //         return ({ ...ele, masterPO: true })
        //     }
        //     else {
        //         return ({ ...ele, masterPO: false })
        //     }
        // })
        // let newlist = _.groupBy(tempArr, 'PORelationID')
        // let finaldata = Object.values(newlist);
        // finaldata.forEach(element => {
        //     let l = element.sort((a, b) => {
        //         if (a.masterPO) {
        //             return -1;
        //         } else {
        //             return 1;
        //         }
        //     })
        //     result.push(l)
        // });
        let result = data?.map(el => {
            if (el?.associatedPO && el?.associatedPO.length) {
                return [el, ...el?.associatedPO]
            } else {
                return [el]
            }
        })
        return result;
    }

    /**
     * Higher order function to form data for table
     * @returns 
     */
    const getBodyData = () => {
        if (allApptsArr.length) {
            const formatedData = formatdata(allApptsArr);
            return (formatedData.map((data, index) => {
                return (
                    formatDrillDownData(data, index)
                )
            }))
        }
        else {
            return []
        }
    }

    /**
     * Function to handle page change
     * @param {*} pageNo 
     * @param {*} limit 
     */
    const pageChange = (pageNo, limit = PaginationDefaultLimit) => {
        fetchApptByType(pageNo, limit)
    }

    const fetchApptsReportHistoryDetails = (apptNumber) => {
        setapptNumber(apptNumber)
        setShowAppointmentHistoryModal(true)
    }

  return (
    <ScheduleProWrapper showAppointments={false}>
        <div className='appointments-report-ss-wrapper p-3'>
            <div className='d-flex align-items-center justify-content-between'>
            
                <span className='f-16 fw-semobold d-flex align-items-center gap-2'>{<i className="fa-solid fa-arrow-left pointer" onClick={()=>navigate(-1)}></i>} {AppConstant.appointmentReportSummary.header}{` / ${groupByType}`}</span>
                <div className='d-flex align-items-center'>
                <AppointmentReportSummarySubHeader getData={fetchApptByType} selectedStartDate={selectedStartDate} setSelectedStartDate={setSelectedStartDate} selectedEndDate={selectedEndDate} setSelectedEndDate={setSelectedEndDate}
                  isDrillDown={true} subRoot={groupByType}/>
               
             
             
                    <div className='pos-rel ps-2'>
                        <WarehouseSearchSS
                            placeholder={AppConstant.poManagement.searchPOSection.searchTitleStr}
                            inputValue={searchStr}
                            cancelSearch={() => setSearchStr('')}
                            onChangeHandler={(text) => setSearchStr(text)} />
                    </div>
                    </div>
                    </div>
               <br/>
               <br/>
               <div className='appt-summary-list-ss'>
               {appointmentReportSummary &&
        <DrilldownTableSS
            themeSelected={themeSelected}
            key={Math.random()}
            sortKey={true}
            showUTCTime={true}
            bodyData={getBodyData()}
            titleData={apptsTitleDDWH}
            showCollapse={false}
            hasCollapsableContent={true}
            collapsableData={allApptsArr.length?formatdata(allApptsArr):[]}
            accordionPrimaryIndex={1}
            showInput={true}
            showSort={false}
            arrangeOrder={null}
            arrangeKey={null}
            drillDownReport={allApptsArr}
            initiateDrillDownReport={initateLoading}
            loaderArray={12}
        />
        }
        </div>
        {!_.isEmpty(appointmentReportSummary) && appointmentReportSummary?.data?.length ?
             
             <CustomPaginationSS
                 pageNo={appointmentReportSummary?.pageNo}
                 pageSize={appointmentReportSummary?.limit}
                 first={appointmentReportSummary?.first}
                 last={appointmentReportSummary?.last}
                 totalElements={appointmentReportSummary?.totalElements}
                 totalPages={appointmentReportSummary?.totalPages}
                 pageChange={(pageNo, limit) => { pageChange(pageNo, limit) }}
             />
          : ""
              }
              {showAppointmentHistoryModal &&
                        <ModalSS toggleModal={toggleModal}>
                            <AppointmentReportHistory  
                            themeSelected={themeSelected}
                            data={apptNumber}
                            />
                        </ModalSS>
              }
               {showUploadDocsModal ? <UploadSproDocumentsPopup showModal={showUploadDocsModal} setCloseModal={setShowUploadDocsModal} uploadDocsHandler={() => {}} docDeleteHandler={() => {} } appointmentDocumentsList={appointmentDocumentsList} showDelete={false} showUpload={false} initateLoading={initateLoading} /> : ''}
     </div>
    </ScheduleProWrapper>
  )
}

export default DrillDownAppointmetReportSummary