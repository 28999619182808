import React, { useEffect, useState } from 'react'
import { createChartsProps, donutType } from '../../../../../../../Assests/DashboardUtility'
import { appointmentReportSummaryDataPie } from '../../../../../../../Assests/GraphUtilities'
import _ from 'lodash'
import { AppConstant } from '../../../../../../../Assests/AppConstant'
import { smartScheduleSelector } from '../../../../../../../../redux/reducers/reducerSlices/SmartScheduleReducer'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { getUserSelector } from '../../../../../../../../redux/reducers/reducerSlices/UserReducer'
import { sagaActions } from '../../../../../../../../sagas/sagaActions'
import { getAuthReducer } from '../../../../../../../../redux/reducers/reducerSlices/AuthReducer'
import { displayLoader, formatDate_MM_DD_YYYY } from '../../../../../../../Assests/Utility'
import './AllAppointmentsListSS.css'
import AppointmentReportSummaryGraphical from './Components/AppointmentReportSummaryGraphical'
import AppointmentReportSummarySubHeader from './Components/AppointmentReportSummarySubHeader'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

/**
 * Component for appointment summary
 * @param {*} props 
 * @returns 
 */
const AppointmentReportSummary = (props) => {
    const {themeSelected='light-mode'} = props;
    const ss = useSelector(smartScheduleSelector);
    const { appointmentReportSummary ,initateLoading, startDate, endDate} = ss;
    const userPermission = useSelector(getUserSelector);
    const { selectedWarehouse } = userPermission;
    const dispatch = useDispatch()
    const [appointmentReportSummaryData,setAppointmentReportSummaryData] = useState(null)
    const [selectedStartDate,setSelectedStartDate] = useState(null)

    const [selectedEndDate,setSelectedEndDate] = useState(null)
    const navigate = useNavigate();

    /**
     * useEffect to call get appointment summary data function on load
     */
    useEffect(()=>{
        setSelectedStartDate(()=>{
            let d=new Date()
            d.setDate(d.getDate()-7)
            return d
        });
        setSelectedEndDate(new Date(endDate));

        // return(()=>{
        //     setSelectedStartDate(()=>{
        //         let d=new Date()
        //         d.setDate(d.getDate()-7)
        //         return d
        //     })
        //     setSelectedEndDate(new Date())
        // })
    },[])

/**
 * useEffect to set start date in redux
 */
    useEffect(()=>{
        dispatch({type:sagaActions.UPDATE_START_DATE, payload:new Date(selectedStartDate)})
        const getstartDate=()=>{
            let d=new Date()
        d.setDate(d.getDate()-7)
        return d
        }
        return (()=> dispatch({type:sagaActions.UPDATE_START_DATE, payload:getstartDate()}))
    },[selectedStartDate])

/**
 * useEffect to set end date in redux
 */
    useEffect(()=>{
        dispatch({type:sagaActions.UPDATE_END_DATE, payload:new Date(selectedEndDate)})
        return (()=> dispatch({type:sagaActions.UPDATE_END_DATE, payload:new Date()}))
    },[selectedEndDate])

    useEffect(() => {
        if (selectedStartDate && selectedEndDate) {
            setTimeout(() => {
                getApptSummary()
            }, [0])
        }
    },[selectedStartDate, selectedEndDate])

    /**
     * Function to fetch graph data for appointment summary
     */
    const getApptSummary=()=>{
        const payload={
            "WHID": selectedWarehouse.ID,
            "Type": "graphical",
            "startDate":selectedStartDate? moment(selectedStartDate).format(AppConstant.commonStrings.YYYY_MM_DD):null,
            "endDate": selectedEndDate?moment(selectedEndDate).format(AppConstant.commonStrings.YYYY_MM_DD):null
          }
        dispatch({ type: sagaActions.APPOINTMENT_REPORT_SUMMARY, payload: payload})

    }

    


/**
 * useEffect to form graph data
 */
    useEffect(()=>{
        if (appointmentReportSummary) {
            
            createAppointmentReportSummary()
        }
    },[appointmentReportSummary])

    /**
     * Function to form graph data
     */
    const createAppointmentReportSummary = () => {
        let summaryData=null;
        if (appointmentReportSummary) {
             summaryData=[
                {
                    name:"Warehouse",
                    data:appointmentReportSummary?.Warehouse 
                },
                {
                    name:"Carrier",
                    data:appointmentReportSummary?.Carrier
                }
            ]
        }
   
        if (summaryData && !_.isEmpty(summaryData)) {
            const dataDonutArray = AppConstant.appointmentReportSummary.labels
            const datadonut = dataDonutArray.map(el => {
                const filterIfdata = _.filter(summaryData, { name: el })
                if (_.size(filterIfdata)) {
                    return filterIfdata[0].data
                } else {
                    return 0
                }
            })
            const apptReportSummarydonut = createChartsProps(datadonut, donutType.AppointmentReportSummary, themeSelected, appointmentReportSummaryDataPie)
            let setData = { ...props, apptReportSummarydonut };
            setData.themeSelected = themeSelected;
            setAppointmentReportSummaryData(setData);
        }
    }

    /**
     * Function to handle navigation for drilldown report 
     */
    const getSubRoot = () => {
        const status = localStorage.getItem("appointmentReportSummary")
        if (status === "0") {
            navigate(`appointmentsummary/warehouse/${selectedStartDate}/${selectedEndDate}`)
        } else if (status === "1") {
            navigate(`appointmentsummary/carrier/${selectedStartDate}/${selectedEndDate}`)
        }
    
    }

  return (
    <div className='appointments-report-ss-wrapper p-3'>
        <div className='d-flex justify-content-between my-0 mx-3 appt-report-headers-full'>
        <span className='f-16 fw-semobold d-flex align-items-center gap-2'>{AppConstant.appointmentReportSummary.header}</span>
               <AppointmentReportSummarySubHeader getData={getApptSummary} selectedStartDate={selectedStartDate} setSelectedStartDate={setSelectedStartDate} selectedEndDate={selectedEndDate} setSelectedEndDate={setSelectedEndDate}/>
               </div>
                    <br />
                    <br/>
                    <br />
                    {!initateLoading?
              <AppointmentReportSummaryGraphical appointmentReportSummaryData={appointmentReportSummaryData} getSubRoot={getSubRoot}/>
                :
                <div className='w-100'>{displayLoader('light-mode', 50, 11)}</div>
                }
        </div>
  )
}

export default AppointmentReportSummary