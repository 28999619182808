import React, { useEffect, useState } from 'react'
import "./DoorAssignedTimer.css"
import InfoIcon from "../../../../../../Assests/Svg/InfoIcon"
import CountDownTimer from '../../../../../Common/CountDownTimer/CountDownTimer';
import { getCurrentTime } from '../../../../../../../Utils/DateUtils';
import { getLoadSelector } from '../../../../../../../redux/reducers/reducerSlices/getLoadReducer';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash-es';
import { sagaActions } from '../../../../../../../sagas/sagaActions';
import { zeroPad } from 'react-countdown';
import { driverAcknowleged, driverAcknowlegmentData, driverRejected, waitingForDriverAcknowlegment } from '../utils';
import { AppConstant } from '../../../../../../Assests/AppConstant';
import { getRemoteConfigSelector } from '../../../../../../../redux/reducers/reducerSlices/RemoteConfigReducer';

const DoorAssignedTimer = (props) => {
    let ref
    const getLoad = useSelector(getLoadSelector);
    const remoteConfig = useSelector(getRemoteConfigSelector)
    const {doorAssignedTimer} = remoteConfig
    const dispatch = useDispatch()

    const [bannerData, setBannerData] = useState(driverAcknowlegmentData)
    const [currentTime, setCurrentTime] = useState("")
    const [refetchedTruckList, setrefetchedTruckList] = useState(false)
    const [timerValue, settimerValue] = useState("")
    const intervals = [1000, 30000, 60000, 90000, 120000, 150000, 180000, 250000, 270000]

    const stopTimerAndAssingDefaultBanner = () => {
        stopTimer()
        setBannerData(driverAcknowlegmentData)
    }
    useEffect(() => {
        if (!isEmpty(getLoad.selectedLoad)) {
            stopTimerAndAssingDefaultBanner()
        }
    }, [getLoad.selectedLoad.load_id])

    useEffect(() => {
        if (isEmpty(getLoad.assignedDoor)) {
            stopTimerAndAssingDefaultBanner()
        }
    }, [getLoad.assignedDoor])

    useEffect(() => {
        if (!isEmpty(getLoad.selectedLoad) && getLoad.startDoorAcknowledgementTimer) {
            dispatch({ type: sagaActions.RESET_DOOR_ACKNOWLEDGMENT_TIMER })
            const data = waitingForDriverAcknowlegment
            setBannerData(data)
        }
    }, [getLoad.startDoorAcknowledgementTimer])

    useEffect(() => {
        if (!isEmpty(getLoad.selectedLoad.door) && ref &&  !ref.isStarted()) {
            checkForFinalDoorAcknowledgemnt()
        }
    }, [getLoad.selectedLoad.door])

    useEffect(() => {
        if (!isEmpty(getLoad.selectedLoad) && refetchedTruckList) {
            setrefetchedTruckList(false)
            if (timerValue === 1000) {
                return checkForFinalDoorAcknowledgemnt()
            }
            checkForDoorAcknowledgemnt()
        }
    }, [getLoad.selectedLoad])

    useEffect(() => {
        if (bannerData) {
            setCurrentTime(getCurrentTime())
            setTimerForLoad()
        }
    }, [bannerData])

    const checkForDoorAcknowledgemnt = () => {
        if (getLoad.selectedLoad.door_acknowledge) {
            stopTimer()
            setBannerData(driverAcknowleged)
        }
    }

    const checkForFinalDoorAcknowledgemnt = () => {
        if (getLoad.selectedLoad.door_acknowledge) {
            stopTimer()
            setBannerData(driverAcknowleged)
        } else {
            stopTimer()
            setBannerData(driverRejected)
        }
    }

    const showTimer = () => bannerData.status === waitingForDriverAcknowlegment.status || bannerData.status === driverAcknowlegmentData.status;

    const stopTimer = () => ref && ref.stop()

    const setTimerForLoad = () =>
        bannerData.status === waitingForDriverAcknowlegment.status && !ref.isStarted() && ref.start()

    const onTick = (data) => {
        if (intervals.includes(data.total)) {
            fetchTruckDetails(data.total)
        }
    }

    const getLoadID = () => {
        try {
            return getLoad.selectedLoad.load_id
        } catch {
            return ""
        }
    }

    const renderer = ({ minutes, seconds }) => (
        <span data-testid={AppConstant.dataTestId.timer_data} className={`timerCounter ${bannerData.status}`}>
            {`${zeroPad(minutes)}:${zeroPad(seconds)} Mins`}
        </span>
    );

    const setRef = (reference) => ref = reference

    const getTimer = () => {
        if (showTimer())
            return <div className={`d-flex flex-row align-items-center 
            justify-content-center border timerContainer ${bannerData.status}`}>
                <span className={`timerLabel ${bannerData.status}`}>{`Timer: `}</span>
                <CountDownTimer keyID={getLoadID()} onStart={() => { }} onTick={onTick}
                    setRef={setRef} countDown={parseFloat(doorAssignedTimer)} renderer={renderer} from={currentTime} />
            </div>
    }

    const fetchTruckDetails = (total) => {
        settimerValue(total)
        setrefetchedTruckList(true)
        dispatch({ type: sagaActions.LOAD_TRUCK_DETAILS, payload: getLoad.selectedLoad })
    }

    return (<div className={`d-flex flex-row mx-3 align-items-center timerBanner ${bannerData.status}`}>
        <InfoIcon status={bannerData.status} />
        <span data-testid={AppConstant.dataTestId.timer_banner_data} className={`timerTitle ${bannerData.status}`}>{bannerData.title}</span>
        {
            getTimer()
        }
    </div>);
}

export default DoorAssignedTimer