import moment from "moment"
import { AppConstant } from "../../../../../Assests/AppConstant"
import { formatDate_MM_DD_YYYY_HH_MM_SS } from "../../../../../Assests/Utility"

export const fetchSSApptListCommonFunc = (id, userType, selectedDate) => {

    const apiParams = {
        "ID": id,
        "type": userType,
        DateSelected: selectedDate ? formatDate_MM_DD_YYYY_HH_MM_SS(selectedDate) : null
    }

    const operationObj = {
        type: 'Fetch Appts',
        status: ''
    }

    return { apiParams: apiParams, operationObj: operationObj }
}