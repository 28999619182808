import _ from 'lodash';
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedDateSelector } from '../../../../../../../../../redux/reducers/reducerSlices/DateSelectorReducer';
import { getDefaultDashboardSelector } from '../../../../../../../../../redux/reducers/reducerSlices/DefaultDashboardReducer';
import { getUserSelector } from '../../../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { sagaActions } from '../../../../../../../../../sagas/sagaActions';
import { getFormattedDate, YYYY_MM_DD_FORMAT } from '../../../../../../../../../Utils/DateUtils';
import { AppConstant } from '../../../../../../../../Assests/AppConstant';
import { modifyPODisplay } from '../../../../../../../../Assests/Utility';
import DrilldownTable from '../../Common/DrilldownTable';
import { CustomPaginationSS } from '../../../../../../../Common/CustomPaginationSS/CustomPaginationSS';
import '../../DrillDownReports.css'
import { getRemoteConfigSelector } from '../../../../../../../../../redux/reducers/reducerSlices/RemoteConfigReducer';

const DrillDownDetentionStatus = (props) => {
    const { themeSelected, loaderArray, subRoot, dockIdArr } = props;
    const dispatch = useDispatch();
    const [drilldownData, setDrilldownData] = useState([]);
    const defaultDashboard = useSelector(getDefaultDashboardSelector);
    const { detentionStatusDrillDownReport, arrangeOrder, arrangeKey, initiateDrillDownReport, dateRangeObj, detentionStatusDrillDownReportResponse } = defaultDashboard;
    const getSelectedDateSelectorObj = useSelector(getSelectedDateSelector);
    const { selectedDate } = getSelectedDateSelectorObj;
    const userPermission = useSelector(getUserSelector);
    const { selectedWarehouse } = userPermission;
    const remoteConfig = useSelector(getRemoteConfigSelector);
    const { allConfigration } = remoteConfig;
    const { PaginationDefaultLimit } = allConfigration

    let unloadingHoursTitleDD = [
        { id: 'PoNumbers', name: 'PO' },
        { id: 'AppointmentDateTime', name: 'Appt Time' },
        { id: 'CheckInTime', name: 'Checked in' },
        { id: 'BillsOutTime', name: 'Bills Out Time' },
        { id: 'CarrierName', name: 'Carrier Name' },
        { id: 'Vendor', name: 'Vendor' },
        { id: 'DockName', name: 'Dock' },
        { id: 'Trailer', name: 'Trailer' },
        { id: 'Items', name: 'Items' },
        { id: 'Cases', name: 'Cases' },
        { id: 'Detention_Time', name: 'Detention (hr)' },
    ]
    const createPayload = (pageNo, limit) => {
        return { date: getFormattedDate(YYYY_MM_DD_FORMAT, selectedDate), dock_id: dockIdArr.toString() === '' ? null : dockIdArr.toString(), isDrillDown: '1', warehouse_id: selectedWarehouse.ID, drillDownType: subRoot.subPathName, pageNo, limit }
    }

    const fetchDetentionStatusData = (pageNo = 1, limit = PaginationDefaultLimit) => {
        dispatch({ type: sagaActions.INITIATE_DRILLDOWN_REPORT, payload: true })
        dispatch({
            type: sagaActions.GET_DETENSTION_STATUS_DRILLDOWN, payload: createPayload(pageNo, limit)
        });
    }
    useEffect(() => {
        fetchDetentionStatusData()
    }, [dateRangeObj])

    useEffect(() => {

        let reasignData = []
        if (detentionStatusDrillDownReportResponse && detentionStatusDrillDownReportResponse?.data?.length) {
            let data = formatDrillDownData(detentionStatusDrillDownReportResponse?.data)
            reasignData = data
        }
        setDrilldownData([...reasignData])
    }, [detentionStatusDrillDownReportResponse?.data])

    const formatDrillDownData = (data) => {
        return data.map(el => {
            let splitArray = []
            if (el.PoNumbers) {
                splitArray = el.PoNumbers.split(',')
            }
            return {
                id: el.id,
                0: modifyPODisplay(splitArray) || '-',
                1: el.AppointmentDateTime || '-',
                2: el.CheckInTime || '-',
                3: el.BillsOutTime || '-',
                4: el.CarrierName || '-',
                5: el.Vendor || '-',
                6: el.DockName || '-',
                7: el.Trailer || '-',
                8: el.Items || '-',
                9: el.Cases || '-',
                10: parseFloat(el.Detention_Time),
            }
        })
    }

    const pageChange = (pageNo, limit) => {
        fetchDetentionStatusData(pageNo, limit)
    }

    return (
        <>
        {drilldownData &&
        <DrilldownTable
            key={Math.random()}
            sortKey={true}
            showUTCTime={true}
            themeSelected={themeSelected}
            bodyData={drilldownData}
            titleData={unloadingHoursTitleDD}
            showCollapse={false}
            showInput={true}
            showSort={true}
            arrangeOrder={arrangeOrder}
            arrangeKey={arrangeKey}
            drillDownReport={detentionStatusDrillDownReportResponse.data}
            initiateDrillDownReport={initiateDrillDownReport}
            loaderArray={loaderArray}
            subRoot={subRoot}
            sortingAction={sagaActions.SORT_DRILLDOWN_REPORT} />
        }
            {!_.isEmpty(detentionStatusDrillDownReportResponse) && detentionStatusDrillDownReportResponse?.data?.length  ?
            <div className='f-14 detention-status-pagination'>
                <CustomPaginationSS
                pageNo={detentionStatusDrillDownReportResponse.pageNo}
                pageSize={detentionStatusDrillDownReportResponse.limit}
                first={detentionStatusDrillDownReportResponse.first}
                last={detentionStatusDrillDownReportResponse.last}
                totalElements={detentionStatusDrillDownReportResponse.totalElements}
                totalPages={detentionStatusDrillDownReportResponse.totalPages}
                pageChange={(pageNo,limit)=>{pageChange(pageNo,limit)}}
            />
        </div>:""
            }
            {!_.isEmpty(detentionStatusDrillDownReportResponse) && detentionStatusDrillDownReportResponse?.data?.length ?
                <div className='f-14 detention-status-pagination'>
                    <CustomPaginationSS
                        pageNo={detentionStatusDrillDownReportResponse?.pageNo}
                        pageSize={detentionStatusDrillDownReportResponse?.limit}
                        first={detentionStatusDrillDownReportResponse?.first}
                        last={detentionStatusDrillDownReportResponse?.last}
                        totalElements={detentionStatusDrillDownReportResponse?.totalElements}
                        totalPages={detentionStatusDrillDownReportResponse?.totalPages}
                        pageChange={(pageNo, limit) => { pageChange(pageNo, limit) }}
                    />
                </div> : ""
            }
        </>);
}

export default DrillDownDetentionStatus;