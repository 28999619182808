import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash-es';
import { useNavigate } from 'react-router-dom';
import { getUserSelector } from '../../../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { initiateSearch } from '../../../../../../../../Assests/Utility';
import { AppConstant } from '../../../../../../../../Assests/AppConstant'
import { sagaActions } from '../../../../../../../../../sagas/sagaActions';
import DrilldownTable from '../../../../../Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTable';
import WarehouseSearch from '../../../../../../../SuperWarehouse/Components/SearchWarehouse/WarehouseSearch';
import DownloadSection from '../../../../../DownloadSection/DownloadSection';
import { usePrevious } from '../../../../../../../Common/CustomHooks/usePrevious';
import moment from 'moment-timezone';
import { getPoSSselector } from '../../../../../../../../../redux/reducers/reducerSlices/POSmartSchedulerReducer';
import { getDefaultDashboardSelector } from '../../../../../../../../../redux/reducers/reducerSlices/DefaultDashboardReducer';
import CustomDropDown from '../../../../../../../Common/CustomDropDown/CustomDropDown';
import './UserRatingsTabularSS'
import PaginationIMS from '../../../../../../../Common/Pagination/PaginationIMS';
import "react-datepicker/dist/react-datepicker.css";
import "../../../../../../../../Components/DatePicker/DatePicker.css"
import CustomDatePickerMMYYYYonly from '../../../../../../../DatePicker/Month/CustomDatePickerMMYYYYonly';
import { CustomPaginationSS } from '../../../../../../../Common/CustomPaginationSS/CustomPaginationSS';
import { getRemoteConfigSelector } from '../../../../../../../../../redux/reducers/reducerSlices/RemoteConfigReducer';

const UserRatingsTabular = (props) => {
    const { themeSelected = 'light-mode', view, startDate, setStartDate, endDate, setEndDate, handleStartYearChange, handleEndYearChange, handleStartDateChange, handleEndDateChange, isError, setCurrentFilterDetails } = props;
    const dispatch = useDispatch();
    const [userRatingsList, setUserRatingsList] = useState([]);
    const pOSS = useSelector(getPoSSselector);
    const { userRatingsReportList, userRatingsAllData, arrangeOrder, arrangeKey } = pOSS;
    const userObj = useSelector(getUserSelector);
    const [searchStr, setSearchStr] = useState('');
    const { selectedWarehouse, cu_id, userType } = userObj;
    const [currentTable, setCurrentTable] = useState(null);
    const [currentTableRef, setCurrentTableRef] = useState(null);
    const divRef = useRef(null);
    const [showLoading, setShowLoading] = useState(true);
    const previousTable = usePrevious(currentTable);
    // moment.tz.setDefault(selectedWarehouse?.TimeZone);
    const [currentFilterSelected, setCurrentFilterSelected] = useState({ name: 'All' });
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
     //Get Config from store
     const remoteConfig=useSelector(getRemoteConfigSelector);
     const {allConfigration}=remoteConfig;
     const {PaginationDefaultLimit}=allConfigration

    /**
     * Used to set the current filtered details 
     */
    useEffect(()=>{
        setCurrentFilterDetails(currentFilterSelected)
    },[currentFilterSelected])

    /**
     * Tabular array of headers to display
     */
    const apptsTitleDD = [
        // { id: 'serial_num', name: 'S.No' },
        { id: 'first_name', name: 'First Name' },
        { id: 'last_name', name: 'Last Name' },
        { id: 'user_rating', name: 'Ratings' },
        { id: 'timeStamp', name: 'Rated on' }
    ]

    /**
     * Used to maintain the state of table
     */
    useEffect(() => {
        const container = divRef.current;
        const tables = container.querySelectorAll('table');
        if (tables && tables.length && _.isEqual(currentTable, previousTable)) {
            setCurrentTableRef(container);
            setCurrentTable(tables);
        }
    })

    /**
     * used to dispatch actions
     */
    useEffect(() => {
        dispatch({ type: sagaActions.RESET_USER_RATINGS_ACTION });
        setShowLoading(true);
        setUserRatingsList([]);
        return () => {
            dispatch({ type: sagaActions.RESET_USER_RATINGS_ACTION });
            setUserRatingsList([]);
        }
    }, [])

    /**
     * validates and gets the user rating details
     */
    useEffect(() => {
        if (!isError && startDate && endDate) {
            fetchUserRatings()
        }
    }, [startDate, endDate, currentFilterSelected, isError])

    /**
     * validate and set the user rating list
     */
    useEffect(() => {
        if (userRatingsReportList && userRatingsReportList.length) {
            setUserRatingsList(userRatingsReportList)
            if (searchStr && searchStr.length > 3) {
                const searchedArr = initiateSearch(userRatingsReportList, searchStr, 'GroupName');
                setUserRatingsList(searchedArr);
            }
        } else {
            setUserRatingsList([])
        }
    }, [userRatingsReportList])

    /**
     * set the user rating details after getting details successfuly from API
     */
    useEffect(() => {
        if (_.upperCase(userRatingsAllData?.status) === 'SUCCESS') {
            setShowLoading(false);
            if (userRatingsAllData?.data?.currentPage) {
                setCurrentPage(userRatingsAllData?.data?.currentPage || 1)
                setTotalPages(userRatingsAllData?.data?.totalPages || 1)
            }
        } else {
            setShowLoading(false)
            setCurrentPage(userRatingsAllData?.data?.currentPage || 1)
            setTotalPages(userRatingsAllData?.data?.totalPages || 1)
        }

    }, [userRatingsAllData])

    /**
     * This function used to fetch user rating details by connecting with API
     */
    const fetchUserRatings = (pageNo=1,limit=PaginationDefaultLimit) => {
        if (userType === 'USER') {
            setShowLoading(true);
            const eDate = moment(endDate).hours(23).minutes(59).seconds(59)
            const params = {
                "startDate": moment(startDate).format(AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
                "endDate": moment(eDate).format(AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
                // "currentPage": currentPage === 0 ? 1: currentPage,
                // "itemsPerPage": userRatingsAllData?.data?.itemsPerPage || 100,
                "currentPage": pageNo,
                "itemsPerPage": limit,
                "rating": currentFilterSelected?.name === 'All' ? null : currentFilterSelected?.name,
                "type": view
            }
            dispatch({ type: sagaActions.UPDATE_USER_RATINGS, payload: params });
        } else if (userType === 'SUPERADMIN') {
        } else {
        }
    }

    /**
     * used to filter and set the data to list if the search string is greater than 3 characters
     */
    useEffect(() => {
        if (searchStr.length > 3 && userRatingsReportList.length) {
            const searchedArr = initiateSearch(userRatingsReportList, searchStr, 'GroupName');
            setUserRatingsList(searchedArr);
        } else if (searchStr === '') {
            setUserRatingsList(userRatingsReportList)
        }
    }, [searchStr])

    /**
     * This function format the data
     * @param {*} data 
     * @returns 
     */
    const formatListDataDrillDown = (data) => {

        return data.map((el, index) => {
            return {
                id: el.load_id,
                // 0: index + 1,
                0: el.first_name,
                1: el.last_name,
                2: el.experience_rating,
                3: moment(el.timeStamp) || '-',
            }
        })
    }


    /**
     * This function is used to get the data and validate
     * @returns 
     */
    const getBodyData = () => {
        if (userRatingsList && userRatingsList.length) {
            return formatListDataDrillDown(userRatingsList)
        }
        else {
            return []
        }
    }

    /**
     * This function is used to show the custom drop down filter
     * @returns 
     */
    const showCustomDropDownForFilters = () => {
        const filterDataArr = [
            {
                name: 'All',
            },
            {
                name: 'Great',
            },
            {
                name: 'Good',
            },
            {
                name: 'Okay',
            }
        ]
        return (
            <div className='d-flex align-items-center'>
                <div className='me-2 titleFiltersLeaveMeta'>{AppConstant.commonStrings.select} :</div>
                <CustomDropDown themeSelected={themeSelected} data={filterDataArr} placeHolder={currentFilterSelected.name} onSelect={(event) => setCurrentFilterSelected(event)} keyStr={'name'} />
            </div>
        )
    }
    
    /**
     * Function used when page is changed in pagination then it fetches user rating deails
     * @param {*} pageNo 
     * @param {*} limit 
     */
    const pageChange=(pageNo,limit)=>{
        fetchUserRatings(pageNo,limit)
   }

    return (
        <div className=''>
            <div className='d-flex justify-content-between my-2 mx-3'>
                <div className='fw-bold'>{'User Ratings'}</div>


                <div className='d-flex justify-content-end align-items-end'>
                    <div className='d-flex justify-content-end align-items-center'>
                        <CustomDatePickerMMYYYYonly wrapperClassName='mx-2' date={startDate} setDate={setStartDate} maxDate={new Date()} handleCalendarChange={(d) => handleStartYearChange(d)} label='Start Date' themeSelected={themeSelected}
                            onChangeHandler={handleStartDateChange}
                        />

                        <CustomDatePickerMMYYYYonly wrapperClassName='' date={endDate} setDate={setEndDate} handleCalendarChange={(d) => handleEndYearChange(d)} label='End Date' maxDate={new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1)} themeSelected={themeSelected} onChangeHandler={handleEndDateChange} />
                    </div>
                    <div className='ms-2 w-30'>
                        {
                            showCustomDropDownForFilters()
                        }
                    </div>
                    <div className='ms-2 w-50'>
                        <WarehouseSearch
                            placeholder={AppConstant.poManagement.searchPOSection.searchTitleStr}
                            inputValue={searchStr}
                            cancelSearch={() => setSearchStr('')}
                            onChangeHandler={(text) => setSearchStr(text)} />
                    </div>
                    <div className='d-flex align-items-center mx-3'>
                        {/* <DownloadSection singlePageDownload={true} root={''} subRoot={''} name={'Facility Ratings'} themeSelected={themeSelected} currentTable={currentTable} currentTableRef={currentTableRef} /> */}
                    </div>
                </div>

            </div>
            <div className='userRatingsListContainer' ref={divRef}>
                <DrilldownTable
                    key={Math.random()}
                    themeSelected={themeSelected}
                    bodyData={getBodyData()}
                    titleData={apptsTitleDD}
                    showCollapse={false}
                    showInput={false}
                    showSort={true}
                    hasCollapsableContent={false}
                    collapsableData={[]}
                    accordionPrimaryIndex={1}
                    arrangeOrder={arrangeOrder}
                    arrangeKey={arrangeKey}
                    drillDownReport={userRatingsList}
                    initiateDrillDownReport={showLoading}
                    loaderArray={4}
                    sortingAction={sagaActions.SORT_SS_USER_TABLE}
                    subRoot={{ pathName: 'userRatingsObj' }}
                    showUTCTime={true}
                />
            </div>
            {/* {userRatingsList && userRatingsList.length ? 
                <PaginationIMS wrapperPositionClass="mx-1 d-flex justify-content-center" 
                currentPage={currentPage} setCurrentPage={setCurrentPage} 
                totalPages={totalPages} isLoading={ showLoading} /> : ''} */}

            {!_.isEmpty(userRatingsAllData) && userRatingsAllData?.data?.Items?.length  ?
            <div className='f-14 detention-status-pagination'>
                <CustomPaginationSS
                pageNo={userRatingsAllData.data.currentPage}
                pageSize={userRatingsAllData.data.itemsPerPage}
                first={(Number(userRatingsAllData.data.currentPage)===1)?true:false}
                last={Math.ceil(userRatingsAllData.data.totalCount/Number(userRatingsAllData.data.itemsPerPage))===(Number(userRatingsAllData.data.currentPage))?true:false}
                totalElements={userRatingsAllData.data.totalCount}
                totalPages={userRatingsAllData.data.totalPages}
                pageChange={(pageNo,limit)=>{pageChange(pageNo,limit)}}
            />
            </div>:""
            }
        
        </div>
    )
}

export default UserRatingsTabular