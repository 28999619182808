import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    poStatus: null,
    error: null,
    poActionLoading: false
}

export const POReducer = createSlice({
    name: 'poStatusReducer',
    initialState,
    reducers: {
        initiateApprovePO: (state) => {
            state.poActionLoading = true;
        },
        approvePOSuccess: (state, action) => {
            state.poActionLoading = false;
            state.poStatus = action.payload;
        },
        approvePOFailure: (state, action) => {
            state.poActionLoading = false;
            state.poStatus = null;
            state.error = action.payload
        },
        clearError: (state) => {
            state.poActionLoading = false;
            state.poStatus = null;
            state.error = null
        },
        clearApprovePO: (state) => {
            state.poActionLoading = false;
            state.poStatus = null;
            state.error = null
        }
    }
});

export const { initiateApprovePO, approvePOSuccess, approvePOFailure, clearError, clearApprovePO } = POReducer.actions;

// Selectors
export const getPOReducer = (state) => {
    return state.poReducer;
};

export default POReducer.reducer;
