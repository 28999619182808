import React, { useEffect } from 'react';
import DrilldownTableBody from './DrilldownTableBody';
import DrilldownTableHeader from './DrilldownTableHeader';

const DrilldownTable = (props) => {

    const { themeSelected, bodyData, titleData, showCollapse, showInput, showSort, arrangeOrder, arrangeKey, drillDownReport, initiateDrillDownReport, loaderArray, subRoot, sortingAction, showUTCTime, sortKey, hasCollapsableContent, collapsableData, accordionPrimaryIndex } = props;
    return (
        <table className={`table table-responsive table-hover ${themeSelected}`} id='pdf-content'>
            <DrilldownTableHeader data={titleData} dataB={bodyData} showInput={showInput} showSort={showSort} themeSelected={themeSelected} showCollapse={showCollapse} arrangeOrder={arrangeOrder} arrangeKey={arrangeKey} drillDownReport={drillDownReport}
                subRoot={subRoot || ''} sortingAction={sortingAction} />
            <DrilldownTableBody data={bodyData} showInput={showInput} showCollapse={showCollapse} themeSelected={themeSelected} initiateDrillDownReport={initiateDrillDownReport} loaderArray={loaderArray} showUTCTime={showUTCTime} sortKey={sortKey} hasCollapsableContent={hasCollapsableContent} collapsableData={collapsableData} accordionPrimaryIndex={accordionPrimaryIndex} />
        </table>
    );
}
export default DrilldownTable;