import React, { useEffect, useState } from "react"
import _ from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { getReportsSelector } from '../../../../../../../../redux/reducers/reducerSlices/ReportsReducerSlice';
import { sagaActions } from '../../../../../../../../sagas/sagaActions';
import { AppConstant } from "../../../../../../../Assests/AppConstant";
import ReportsInput from "../../Components/ReportsInputs";
import './ADFATReportList.css'
import DrilldownTableBody from "../../../../Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTableBody";
import moment from "moment";
import { CustomPaginationSS } from "../../../../../../Common/CustomPaginationSS/CustomPaginationSS";
import { getRemoteConfigSelector } from "../../../../../../../../redux/reducers/reducerSlices/RemoteConfigReducer";

const ADFATReportList = (props) => {

    const dispatch = useDispatch();
    const { themeSelected, divRef, apiParams, apiPayload } = props;
    const reports = useSelector(getReportsSelector);
    const { initateLoading, adfatReport,adfatReportResponse } = reports
    const [adfatReportArr, setAdfatReportArr] = useState([]);
    //Get Configration from store
    const remoteConfig=useSelector(getRemoteConfigSelector);
    const {allConfigration}=remoteConfig;
    const {PaginationDefaultLimit}=allConfigration

    const adfatReportParentTitleDD = [
        { id: 'dateAndLocation', name: AppConstant.reports.adfatReport.reportParentHeadingTitle.dateAndLocation, colSpan: 3 },
        { id: 'loadInformation', name: AppConstant.reports.adfatReport.reportParentHeadingTitle.loadInformation, colSpan: 3 },
        { id: 'countAndBreakdown', name: AppConstant.reports.adfatReport.reportParentHeadingTitle.countAndBreakdown, colSpan: 3 },
        { id: 'actualTime', name: AppConstant.reports.adfatReport.reportParentHeadingTitle.actualTime, colSpan: 7 },
        { id: 'elapsedTime', name: AppConstant.reports.adfatReport.reportParentHeadingTitle.elapsedTime, colSpan: 4 },
    ]
    const adfatReportTitleDD = [
        { id: 'TruckDate', name: AppConstant.reports.adfatReport.reportHeadingTitle.date },
        { id: 'Dock', name: AppConstant.reports.adfatReport.reportHeadingTitle.dock },
        { id: 'Door', name: AppConstant.reports.adfatReport.reportHeadingTitle.door },
        { id: 'Carrier', name: AppConstant.reports.adfatReport.reportHeadingTitle.carrier },
        { id: 'AllPOs', name: AppConstant.reports.adfatReport.reportHeadingTitle.po },
        { id: 'Vendor', name: AppConstant.reports.adfatReport.reportHeadingTitle.vendor },
        { id: 'LumpItems', name: AppConstant.reports.adfatReport.reportHeadingTitle.count },
        { id: 'LumpCases', name: AppConstant.reports.adfatReport.reportHeadingTitle.cases },
        { id: 'startendchep', name: AppConstant.reports.adfatReport.reportHeadingTitle.startEndChep },
        { id: 'ScheduledTime', name: AppConstant.reports.adfatReport.reportHeadingTitle.scheduled },
        { id: 'ArrivalTime', name: AppConstant.reports.adfatReport.reportHeadingTitle.arrival },
        { id: 'CheckInTime', name: AppConstant.reports.adfatReport.reportHeadingTitle.checkIn },
        { id: 'InDoorTime', name: AppConstant.reports.adfatReport.reportHeadingTitle.doorAssign },
        { id: 'LumpStartTime', name: AppConstant.reports.adfatReport.reportHeadingTitle.lumpStart },
        { id: 'LumpEndTime', name: AppConstant.reports.adfatReport.reportHeadingTitle.lumpEnd },
        { id: 'BillsOutTime', name: AppConstant.reports.adfatReport.reportHeadingTitle.billsOut },
        { id: 'LumpTime', name: AppConstant.reports.adfatReport.reportHeadingTitle.unload },
        { id: 'PaperworkTime', name: AppConstant.reports.adfatReport.reportHeadingTitle.paperWork },
        { id: 'Detention', name: AppConstant.reports.adfatReport.reportHeadingTitle.detention },
        { id: 'ActiveDoorTime', name: AppConstant.reports.adfatReport.reportHeadingTitle.activeDoor },
    ]

    useEffect(() => {
        if (adfatReportResponse && adfatReportResponse?.data?.length) {
            let data = formatDrillDownData(adfatReportResponse.data)
            setAdfatReportArr(data)
            dispatch({ type: sagaActions.INTIATE_LOADER_REPORTS, payload: false })
        } else {
            setAdfatReportArr([])
            dispatch({ type: sagaActions.INTIATE_LOADER_REPORTS, payload: false })
        }
    }, [adfatReportResponse?.data])

    const formatDrillDownData = (data) => {

        return data.map(el => {
            let detention = '-';
            if (el.AppointmenttoBillstoDrivertMinutes) {
                detention = moment.utc().startOf('day').add(el.AppointmenttoBillstoDrivertMinutes, 'minutes').format('HH:mm')
            }
            let lumpTime = '-';
            if (el.LumpTime) {
                let lumpTimeSplit = el.LumpTime.split(':');
                lumpTime = `${lumpTimeSplit[0]}:${lumpTimeSplit[1]}`

            }
            return {
                id: el.TruckID,
                0: el.TruckDate || '-',
                1: el.Dock || '-',
                2: el.Door || '-',
                3: el.Carrier || '-',
                4: el.AllPOs || '-',
                5: el.Vendor || '-',
                6: el.LumpItems || '-',
                7: el.LumpCases || '-',
                8: `${el.StartPallets}/${el.EndPallets}/${el.StartChepPallets}` || '-',
                9: el.ScheduledTime || '-',
                10: el.ArrivalTime || '-',
                11: el.CheckInTime || '-',
                12: el.InDoorTime || '-',
                13: el.LumpStartTime || '-',
                14: el.LumpEndTime || '-',
                15: el.BillsOutTime || '-',
                16: lumpTime || '-',
                17: el.PaperworkTime || '-',
                18: detention || '-',
                19: el.ActiveDoorTime || '-',
            }
        })
    }

    const fetchAdfatData=(pageNo=1,limit=PaginationDefaultLimit)=>{
        dispatch({ type: sagaActions.INTIATE_LOADER_REPORTS, payload: true });
        dispatch({ type: sagaActions.GET_ADFAT_REPORT_LIST, payload: {...apiPayload,pageNo,limit} })
    }

    const pageChange=(pageNo,limit)=>{
        fetchAdfatData(pageNo,limit)
    }

    return (
        <div className="adafatreport m-1" ref={divRef}>
            <div className="mx-2 container-fluid text-center graphHeading">{AppConstant.reports.adfatReport.titleReportFullTitle}</div>
            <ReportsInput apiParams={apiParams} />
            <table className="mt-2 adfatReportWrapper">
                <thead>
                    <tr className={`headingrow1 text-center align-middle ${themeSelected}`} >
                        {
                            adfatReportParentTitleDD.map(el => {
                                return (
                                    <th colSpan={el.colSpan} scope="col" className={`col px-0 ${themeSelected}`} id={el.id} key={el.id}>
                                        <span className='text-center'>{el.name}</span>
                                    </th>
                                )
                            })
                        }
                    </tr>
                    <tr className={`headingrow2 text-center align-middle ${themeSelected}`} >
                        {
                            adfatReportTitleDD.length && adfatReportTitleDD.map(el => {
                                return (
                                    <th scope="col" className={`col px-0 ${themeSelected}`} id={el.id} key={el.id}>
                                        <span>{el.name}</span>
                                    </th>
                                )
                            })
                        }
                    </tr>
                </thead>
                <DrilldownTableBody showUTCTime={true} data={adfatReportArr} showInput={true} showCollapse={false} themeSelected={themeSelected} initiateDrillDownReport={initateLoading} loaderArray={21} sortKey={true} />
            </table>
            {!_.isEmpty(adfatReportResponse) && adfatReportResponse?.data?.length  ?
            <div className='f-14 detention-status-pagination'>
                <CustomPaginationSS
                pageNo={adfatReportResponse.pageNo}
                pageSize={adfatReportResponse.limit}
                first={adfatReportResponse.first}
                last={adfatReportResponse.last}
                totalElements={adfatReportResponse.totalElements}
                totalPages={adfatReportResponse.totalPages}
                pageChange={(pageNo,limit)=>{pageChange(pageNo,limit)}}
            />
        </div>:""
            }
        </div>
    )
}

export default ADFATReportList