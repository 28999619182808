import React, { useEffect, useState } from 'react'
import InputWithTtile from '../../../Common/CustomInput/InputWithTtile';
import CustomButton from '../../../Common/CustomButton/CustomButton';
import Seperator from '../../../Common/Seperator/Seperator';
import { sagaActions } from '../../../../../sagas/sagaActions';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthReducer } from '../../../../../redux/reducers/reducerSlices/AuthReducer';
import { useNavigate } from 'react-router-dom';
import { AppConstant } from '../../../../Assests/AppConstant';
import { isValid } from '../../utils';
import "./Recover.css"
import TryLogin from '../../components/TryLogin';
import { toast } from 'react-toastify';
import { showAlert, formatFirstCharBold } from '../../../../Assests/Utility';
import { getUserSelector } from '../../../../../redux/reducers/reducerSlices/UserReducer';
import _ from 'lodash-es';
/**
 * This component is used to render the forgot password which helps users to reset password in case he forgot password
 * @param {*} props 
 * @returns JSX
 */
const Recover = (props) => {
    const dispatch = useDispatch();
    const { themeSelected } = props;
    const [email, setEmail] = useState("")
    const [emailError, setemailError] = useState(false)
    const [isloading, setisloading] = useState(false)
    const { passwordRecover, error } = useSelector(getAuthReducer);
    const navigate = useNavigate();
    const userPermission = useSelector(getUserSelector);
    const { branding } = userPermission;
    const [bgImage, setBgImage] = useState('')
    const [warehouseLogo, setWarehouseLogo] = useState('')
    const backgroundImage = AppConstant.commonStrings.bkgImgLink;

     /**
     * Function to handle email change 
     * @param {*} emailId 
     * @returns 
     */
    const onEmailChange = (emailId) => {
        setEmail(emailId)
        if (isValid('email', emailId)) {
            return setemailError(false)
        }
        return setemailError(true)
    }
    /**
     * useEffect for successfull password reset, if verfication code is sent then redirect user to verfiy passsword
     */
    useEffect(() => {
        if (passwordRecover) {
            showAlert(toast.TYPE.SUCCESS, AppConstant.notifications.login.recoverEmailSent, themeSelected);
            dispatch({ type: sagaActions.RECOVER_PASSWORD_SUCCESS })
            // redirect verifypassword screen
            navigate("/verifypassword");
        } else if (error) {
            if (error && error.code === 'NotAuthorizedException') {
                showAlert(toast.TYPE.ERROR, AppConstant.notifications.login.followInitialLoginStep);
                navigate('/')
            } else if(error?.message) {
                showAlert(toast.TYPE.ERROR, error?.message);
            }
            else {
                showAlert(toast.TYPE.ERROR, AppConstant.notifications.login.wentWrong);
            }
            dispatch({ type: sagaActions.CLEAR_SIGNIN_ERROR })
        }
    }, [passwordRecover, error])

    /** 
     * useEffect for branding data
     */
    useEffect(() => {
        if(!_.isEmpty(branding)){
            const {IsBranding, WarehouseLogo, BackgroundImage } = branding
            if (IsBranding) {
            // if branding is true then use branding image and logo
            setBgImage(BackgroundImage)
            setWarehouseLogo(WarehouseLogo)
        }
    }
    }, [branding])

    // Recover password handler function
    const initiateRecoverPassword = () => {
        if (email) dispatch({ type: sagaActions.RECOVER_PASSWORD_INITIATED, payload: email })
        else showAlert(toast.TYPE.ERROR, AppConstant.notifications.login.blankEmail);
    }

    return (
        <div className='d-flex justify-content-start align-items-center bgContainer px-0 mx-0'>
             <img className={'bgimage'} src={bgImage ? bgImage : backgroundImage} alt='Background Image' />
            <div className='d-flex parentContainer justify-content-center align-items-center'>
                <div className='d-flex flex-column loginContainer justify-content-center align-items-center'>
                    <div data-testid={AppConstant.dataTestId.logoContainer}
                        className='d-flex justify-content-center align-items-center logoContainer'>
                        <img className="login-logo" src={warehouseLogo ? warehouseLogo : AppConstant.commonStrings.logo_small_wordMark} />    
                    </div>
                    {/* <span data-testid={AppConstant.dataTestId.title}>
                        {formatFirstCharBold(AppConstant.login.loginTitle)}
                    </span> */}
                    <div className='my-3 recovertitle'>
                        <div className='t1'>
                            {AppConstant.login.recover.t1}
                        </div>
                        <div className='t2'>
                            {AppConstant.login.recover.t2}
                        </div>

                    </div>
                    <div className='emailDiv mt-0'>
                        <InputWithTtile
                            errorMessage={AppConstant.login.emailIncorrect}
                            showError={emailError}
                            title={AppConstant.login.email} placeHolder={AppConstant.login.enterEmail}
                            onEmailChange={onEmailChange} />
                    </div>
                    <div className='d-flex justify-content-end loginButtonContainer mt-4'>
                        <CustomButton
                            isLoading={isloading}
                            title={AppConstant.commonStrings.submit} className={"loginButton"} onClick={() => initiateRecoverPassword()}
                        />
                    </div>
                    <Seperator seperatorStyles={
                        { marginTop: "8%", width: "90%", height: "1px", backgroundColor: "#7E7E7E" }} />
                    <div className='d-flex flex-column justify-content-between align-items-center bottomContainer'>
                        <span className='tryLoginText'>{AppConstant.login.recover.tryLoginText}</span>
                        <TryLogin />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Recover;
