import React from 'react';
import { useEffect } from 'react';
import '../PODockStatusFiltersButton/PODockStatusFilterButton'
import PODockStatusButton from '../PODockStatusFiltersButton/PODockStatusFilterButton';
import './PODockFilterSection.css'

const PODockFilterSection = (props) => {
    
    const { title, poFilterList, themeSelected } = props

    return (
        <div className={`pODockFiltersSection bg-transparent ${themeSelected} dock-filters`}>
            <p className=' ms-2 poDockStatusTitle'>
                {title}
            </p>
            <PODockStatusButton poFilterList={poFilterList} themeSelected={themeSelected}/>
        </div>
    )

}

export default PODockFilterSection;