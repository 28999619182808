import React from 'react';
import './Footer.css'
import { AppConstant } from '../../Assests/AppConstant';
/**
 * This is a reusable component for Footer for whole application
 * @param {*} props 
 * @returns 
 */
function Footer(props) {
  const { themeSelected } = props
  return (
    <footer className={`my-footer ${themeSelected}`}>
      <div className={`powered-by`}>
        <p className='mx-2'>{AppConstant.login.poweredBy}</p>
        <img src={AppConstant.commonStrings.wordMark} alt="Powered By" />
      </div>

    </footer>
  );
}

export default Footer;
