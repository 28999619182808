import React from 'react';
import './TaskItemsRow.css'

const TaskItemsRow = (props) => {

    const { title, noOfTrucks, statusIcon, themeSelected, onClickTaskItem } = props;

    return (
        <div className={`d-flex ${themeSelected} taskItemsTableRow my-2 me-5 ${noOfTrucks > 0 ? '' : 'disabled'}`} onClick={onClickTaskItem}>
            <div className='d-flex justify-content-start ms-2 align-items-center'>
                <div className='taskItemIcon'>{statusIcon}</div>
                <div className='ms-2 taskItemTitle'>{`${noOfTrucks} ${title}`}</div>
            </div>
        </div>
    )
}

export default TaskItemsRow;