import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash-es';
import LeftInfoWithFormContainer from '../Container/LeftInfoWithFormContainer/LeftInfoWithFormContainer';
import { getUserSelector } from '../../../../redux/reducers/reducerSlices/UserReducer';
import { AppConstant } from '../../../Assests/AppConstant'
import { useNavigate } from 'react-router-dom';
import FormCarrierSignUp from './FormCarrierSignUp/FormCarrierSignUp';
/** This is wrapper component for Carrier Signup */
const CarrierSignUpContainer = (props) => {

    const { themeSelected = 'light-mode' } = props;
    const [formName, setFormName] = useState(AppConstant.login.CarrierRegistration);
    const [showFormModal, setShowFormModal] = useState(true);
    const [bgImage, setBgImage] = useState('');
    const userPermission = useSelector(getUserSelector);
    const { branding } = userPermission;
    const navigate = useNavigate();
    /** 
     * useEffect for branding data
     */
    useEffect(() => {
        if (!_.isEmpty(branding)) {
            const { IsBranding, WarehouseLogo, BackgroundImage } = branding
            if (IsBranding) {
                setBgImage(BackgroundImage)
            }
        }
    }, [branding])
 /**
     * function to close popup
     */
    const closeHelpModal = () => {
        setShowFormModal(false);
        navigate('/');
    };
    /**
     * 
     * @returns Component to render Carrier Form Signup
     */
    const getCarrierSignUpForm = () => {
        return (
            <div>
                <FormCarrierSignUp bgImage={bgImage} backgroundImage={AppConstant.commonStrings.bkgImgLink} closeHelpModal={closeHelpModal} />
            </div>
        )
    }

    return (
        <div>
            <div className='bgContainerLeftInfo'>
                <img className={'bgContainerImageLeftInfo'} src={bgImage ? bgImage : AppConstant.commonStrings.bkgImgLink} alt='Background Image' />
                <div className='parentContainerLeftInfo '>
                    <LeftInfoWithFormContainer warehouseLogo={AppConstant.commonStrings.schedulerProLogo} formName={formName} closeHelpModal={() => closeHelpModal()} displayComponent={() => getCarrierSignUpForm()} />
                </div>
            </div>
        </div>

    )

}

export default CarrierSignUpContainer;