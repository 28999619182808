import { a } from "aws-amplify";
import _ from "lodash";
import { AppConstant } from '../../../../Assests/AppConstant'

const appConstantsPath = AppConstant.poManagement.poFiltersSection;


export const createDocksIdArr = (selectedDockFiltersList, dockList) => {
    const dockIdArrTemp = [];
    selectedDockFiltersList.map((filter) => {
        dockList.map((dock) => {
            if (dock.dock_name === getCurrentFilter(filter) || dock.dock_name === filter) {
                dockIdArrTemp.push(dock.dock_id);
            }
        })
    })
    return dockIdArrTemp
}

export const getCurrentFilter = (filter) => {

    const indexOfFirstSpace = filter.indexOf(' ');
    const indexOfSecondSpace = filter.indexOf(' ', (indexOfFirstSpace + 1));
    if (indexOfSecondSpace === -1) {
        // one space only - 2 words
        return filter.slice(0, indexOfFirstSpace);
    } else if (indexOfSecondSpace !== -1) {
        // two spaces atleast - 3 words
        return filter.slice(0, indexOfSecondSpace);
    } else if (indexOfFirstSpace === -1) {
        //no space - one word
        return filter;
    }
    return filter;
}

export const applyFilters = (filtersArr, truckList) => {

    let filterList = [];
    let dockArr = [];
    let statusArr = [];
    truckList.length && truckList.map((truckObj) => {

        const currentDockFilterArr = filtersArr.map((filterStr) => {
            const currentFilter = getCurrentFilter(filterStr);
            if (truckObj.dock === currentFilter) {
                if (!dockArr.includes(truckObj)) {
                    dockArr.push(truckObj)
                }
                return truckObj;
            } else {
                return null
            }
        });

        let currentStatusFilterArr = filtersArr.map((filterStr) => {
            const currentFilter = getCurrentFilter(filterStr);
            if (truckObj.truck_load_status === currentFilter) {
                if (!statusArr.includes(truckObj)) {
                    statusArr.push(truckObj)
                }
                return truckObj;
            } else {
                return null
            }
        });

        if (!(dockArr.length > 0 && statusArr.length > 0)) {
            filterList = [...filterList, ...statusArr, ...dockArr];
        }

    })

    const commonArray = []
    if ((dockArr.length > 0 && statusArr.length > 0)) {
        dockArr.map((dockTruck) => {
            statusArr.map((statusTruck) => {
                if (dockTruck.truck_load_status === statusTruck.truck_load_status) {
                    if (!commonArray.includes(dockTruck)) {
                        commonArray.push(dockTruck);
                        return dockTruck;
                    }
                }
            })
        })
    } else {
        filterList = [...new Set(filterList)];
    }

    return (dockArr.length > 0 && statusArr.length > 0) ? [...new Set(commonArray)] : filterList;
}

const createFiltersCountArray = (filtersArray, completeArray) => {
    return filtersArray.map((filterEle) => {
        let counter = 0;
        completeArray.map((filterNew) => {
            counter += filterEle === filterNew ? 1 : 0;
        });
        return ({ 'filter': filterEle, 'count': counter })
    });
}

const createFiltersUIString = (filtersArray, location) => {
    return filtersArray.map((ele) => {
        if (location == "/analytics") {
            return ele.filter
        }
        return `${ele.filter} (${ele.count})`
    });
}

const setPriorityAndSortStatusArr = (statusArr) => {
    const setPriorityArr = statusArr.map((el) => {
        switch (el) {
            case appConstantsPath.poFiltersNotArrivedString:
                return '1' + el;
            case appConstantsPath.poFiltersAppointmentString:
                return '2' + el;
            case appConstantsPath.poFiltersCheckedInString:
                return '3' + el;
            case appConstantsPath.poFiltersDoorAssignedString:
                return '4' + el;
            case appConstantsPath.poFiltersUnloadingStartString:
                return '5' + el;
            case appConstantsPath.poFiltersUnloadingEndString:
                return '6' + el;
            case appConstantsPath.poFiltersReleasedString:
                return '7' + el;
            default:
                return '8' + el;
        }
    })
    const sortedStagesArr = setPriorityArr.sort();
    return sortedStagesArr.map((el) => {
        return el.replace(/[0-9]/g, '');
    })
}

//DOCK FILTERS
export const createDockFilterList = (truckListMasterData, location) => {
    let allDockFiltersAvailable = truckListMasterData.map((dockFilter) => {
        if (dockFilter.dock) {
            return dockFilter.dock;
        }
    })

    allDockFiltersAvailable = _.compact(allDockFiltersAvailable);
    const docksFilterListTemp = [...new Set(allDockFiltersAvailable)];

    const filterCount = createFiltersCountArray(docksFilterListTemp, allDockFiltersAvailable);

    const totalCount = filterCount.reduce((partialSum, element) => partialSum + element.count, 0);
    if (totalCount > 0) {
        filterCount.unshift({ 'filter': 'All Docks', 'count': totalCount })
    }

    const finalDockFiltersArray = createFiltersUIString(filterCount, location);
    return finalDockFiltersArray;
}

//STATUS FILTERS
export const createStatusFilterList = (currentTrucksArray) => {

    let statusFiltersAvailable = currentTrucksArray.map((truckStatusFilter) => {
        if (truckStatusFilter.truck_load_status) {
            return truckStatusFilter.truck_load_status;
        }
    });

    statusFiltersAvailable = _.compact(statusFiltersAvailable);
    const statusFilterListTemp = [...new Set(statusFiltersAvailable)];

    const sortedStatusArr = setPriorityAndSortStatusArr(statusFilterListTemp);
    const statusFilterCount = createFiltersCountArray(sortedStatusArr, statusFiltersAvailable);

    const totalCount = statusFilterCount.reduce((partialSum, element) => partialSum + element.count, 0);
    if (totalCount > 0) {
        statusFilterCount.unshift({ 'filter': 'All Status', 'count': totalCount });
    }

    const statusFiltersArr = createFiltersUIString(statusFilterCount)

    return statusFiltersArr
}