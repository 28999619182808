import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserSelector } from "../../../../redux/reducers/reducerSlices/UserReducer";
import { paytraceReducer } from "../../../../redux/reducers/reducerSlices/PaytraceReducer";
import CustomButton from "../../Common/CustomButton/CustomButton";
import Popup from '../../Common/Popup/Popup';
import { showAlert } from '../../../Assests/Utility';
import { AppConstant } from '../../../Assests/AppConstant';
import { sagaActions } from "../../../../sagas/sagaActions";
import { toast } from 'react-toastify';
// import { getUserPaymentsReducerSlice } from "../../../../redux/reducers/reducerSlices/UserPaymentsReducerSlice";
import DrilldownTable from "../../LandingPage/Components/Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTable";
/** This component renders user subscription in table view */
const UserPaymentsList = (props) => {

    const { themeSelected, searchStr } = props;
    const dispatch = useDispatch();
    const user = useSelector(getUserSelector);
    const { cu_id } = user;
    const [isListLoading, setListLoading] = useState(false);
    const [currentItemIndex, setCurrentItemIndex] = useState('NA');
    const [selectedPaymenyObj, setSelectedPaymenyObj] = useState({});
    const { mySubscriptionList, operationObj } = useSelector(paytraceReducer);
    const [showModal, setShowModal] = useState(false);
    const [userPaymentsArr, setUserPaymentsArr] = useState([]);
     /** Initial useEffect */
    useEffect(() => {
        setShowModal(false)
        setSelectedPaymenyObj({})
        fetchMySubscriptions();
    }, [])
     /**
     * useEffect to render mySubscriptionList and set mySubscriptionList to local variable
     */
    useEffect(() => {
        if(mySubscriptionList && mySubscriptionList.SubscriptionList && mySubscriptionList.SubscriptionList.length){
            setUserPaymentsArr(mySubscriptionList.SubscriptionList)
        } else {
            setUserPaymentsArr([])
        }
    }, [mySubscriptionList])

    useEffect(() => {
        if(operationObj.type && operationObj.type === 'Fetch Payments'){
            setListLoading(false);
            if(!operationObj.status){
                showAlert(toast.TYPE.ERROR, "Unable to fetch subscriptions.", themeSelected);
            }
        }
    }, [operationObj])
      /**
     * Subscription list data title header array
     */
    const userPaymentsTitleDD = [
        { id: '', name: 'Serial Num' },
        { id: '', name: 'Subscription ID' },
        { id: '', name: 'Payment Mode' },
        { id: '', name: 'Reference Number' },
        { id: '', name: 'Payment Status' },
        { id: '', name: 'Amount ($)' },
        { id: '', name: 'Transaction Type' },
        { id: '', name: 'Date & Time' },
    ]
    /**
     * function to fetch subscription payments
     */
    const fetchMySubscriptions = () => {
        setListLoading(true)
        const apiParams = {
            "CuID": cu_id
        }

        const operationObj = {
            type: 'Fetch Payments',
            status: false
        }

        dispatch({ type: sagaActions.MY_SUBSCRIPTIONS_LIST_ACTION, payload: apiParams, operationObj: operationObj });
    }
    /**
     * function to view payments
     * @param {*} groupObj 
     */
    const viewPayment = (groupObj) => {
        setSelectedPaymenyObj(groupObj)
    }

    const actionsButton = (groupObj, index) => {
        return (
            <div>
                <span className=''>
                    <CustomButton
                        isLoading={currentItemIndex !== 'NA' && currentItemIndex === index ? true : false}
                        title={`View`}
                        className={`carrierApptBtn btnAction ${!groupObj.Status ? 'btnDelete' : 'btnEnable'}  mx-1`}
                        onClick={() => viewPayment(groupObj, index)}
                    />
                </span>
            </div>
        )
    }
    /**
     * function to set payment popup
     * @param {*} obj 
     */
    const viewPaymentPopup = (obj) => {
        setSelectedPaymenyObj(obj)
        setShowModal(true)
    }

    const actionButtons = (el, index) => {
        return (
            <div className='d-flex justify-content-center my-2'>
                <CustomButton
                    isLoading={currentItemIndex !== 'NA' && currentItemIndex === index ? true : false}
                    title={`View`}
                    data-bs-toggle="modal"
                    className={`carrierApptBtn btnAction scheduled mx-1`}
                    onClick={() => viewPaymentPopup(el, index)}
                />
            </div>
        )
    }
    /**
     * function to show Payment Popup
     * @returns 
     */
    const showPaymentPopup = () => {
        const popupObj = {
            id: 'createCompanyGroupFormPopup1',
            modalLabel: 'createCompanyGroupFormPopup1',
            showClose: true,
            showfooter: false,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title: AppConstant.smartScheduler.subscription.paymentSlip,
            bodyText: createPaymentPopup(),
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.comments.cancelCommentButtonTitle
                },
                btn2: {
                    show: true,
                    text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.saveButtonTitle
                }
            },
        }

        return <Popup {...popupObj} popupBtnHandler={(text) => () => ''} closepopup={closeCurrentpopup} themeSelected={themeSelected} showModal={showModal} />
    }

    /**
     * format drilldown list data to render in table
     * Reusable component DrilldownTable needs data always in this format
     * @param {*} data 
     * @returns 
     */
    const formatListDataDrillDown = (data) => {
        return data.map((el, index) => {
            return {
                id: index,
                0: index + 1,
                1: el.IDSubs,
                2: el.PaymentMode,
                3: el.PaymentRefernceNumber,
                4: el.PaymentStatus === "success" ? "Sucessful" : el.PaymentError,
                5: '25',
                6: el.TransType || '-',
                7: el.PaymentDate
            }
        })
    }
    /**
     * Close popup handler
     */
    const closeCurrentpopup = () => {
        setSelectedPaymenyObj({})
        setShowModal(false)
    }

    const createPaymentPopup = () => {
        return (
            <div>
                <p>{`${AppConstant.smartScheduler.subscription.paymentIdTitle} ${selectedPaymenyObj.paymentId}`}</p>
                <p>{`${AppConstant.smartScheduler.subscription.paymentTitle} ${selectedPaymenyObj.payment}`}</p>
                <p>{`${AppConstant.smartScheduler.subscription.dateTitle} ${selectedPaymenyObj.date + ' ' + selectedPaymenyObj.time}`}</p>
            </div>
        )
    }

    return (
        <div className="mt-2 subscriptionList">
            <DrilldownTable
                themeSelected={themeSelected}
                bodyData={userPaymentsArr.length && formatListDataDrillDown(userPaymentsArr)}
                titleData={userPaymentsTitleDD}
                showCollapse={false}
                showInput={false}
                showSort={true}
                arrangeOrder={'arrangeOrder'}
                arrangeKey={'arrangeKey'}
                drillDownReport={userPaymentsArr}
                initiateDrillDownReport={isListLoading}
                loaderArray={8}
                sortingAction={''}
                subRoot={{ pathName: '' }}
                showUTCTime={false}
            />
            {showPaymentPopup()}
        </div>
    )
}

export default UserPaymentsList;