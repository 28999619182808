import { createContext } from "react";

export const themes = {
  dark: "night-mode",
  light: "day-mode",
};

export const ThemeContext = createContext({
  theme: themes.dark,
  changeTheme: () => {},
});