import React, { useEffect } from 'react';
import Header from '../Header/Header';
import ChooseWarehouse from '../ChooseWarehouse/ChooseWarehouse';
import Navigationbar from '../Navigationbar/Navigationbar';
import { useDispatch } from 'react-redux';
import { sagaActions } from '../../../sagas/sagaActions';
import './SuperWarehouse.css';

/**
 * This is a wrapper component for warehouse management feature
 * This component renders a list of warehouse card with proceed button
 * to allow SUPERADMIN to use any warehouse with some permissions
 * @param {*} props 
 * @returns 
 */
const SuperWarehouse = (props) => {
    /**
     * Initial dispatch
     */
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({ type: sagaActions.INITIATE_LOADING, payload: true })
        dispatch({ type: sagaActions.UPDATE_SELECTED_WAREHOUSE, payload: {} })
        dispatch({ type: sagaActions.GET_ALL_WAREHOUSE })
    }, [])
    return (
        <div className='container-fluid executor'>
            <Header {...props} />
            <Navigationbar {...props} />
            <ChooseWarehouse  {...props} showWarehouseSearch={true} />
        </div>
    );
}

export default SuperWarehouse;