import React, { useEffect, useState } from 'react'
import { sagaActions } from '../../../../../sagas/sagaActions';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthReducer } from '../../../../../redux/reducers/reducerSlices/AuthReducer';
import { useNavigate } from 'react-router-dom';
import { AppConstant } from '../../../../Assests/AppConstant';
import { isValid } from '../../utils';
import "../Recover/Recover.css"
import { getUserSelector } from '../../../../../redux/reducers/reducerSlices/UserReducer';
import _ from 'lodash-es';
import CustomButtonSS from '../../../Common/CustomButton/CustomButtonSS';
import TruckComponent from '../MainLogin/SchedulePro/MainLogin/Components/TruckComponent';
import { removeInterceptor } from '../../../../../redux/api/Interceptor';
import PasswordInputSS from '../../../Common/PasswordInput/PasswordInputSS';
/**
 * This component renders new UI verify password screen which helps user to reset password
 * @returns JSX
 */
const SetPasswordSS = (props) => {
    const dispatch = useDispatch()
    const [newPassword, setnewPassword] = useState("")
    const [confirmPassword, setconfirmPassword] = useState("")
    const [error, setError] = useState(false)
    const [isloading, setisloading] = useState(false)
    const user = useSelector(getAuthReducer);
    const navigate = useNavigate();
    const [passwordError, setpasswordError] = useState(null)
    const userPermission = useSelector(getUserSelector);
    const { branding } = userPermission;
    const [bgImage, setBgImage] = useState('')
    const [warehouseLogo, setWarehouseLogo] = useState('')
    const backgroundImage = AppConstant.commonStrings.bkgImgLink;

    /**
     * This is handler function for reseting password
     * @returns 
     */
    const initiateSetPassword = () => {
         // Validate if new password and confirm password is same
        if (newPassword !== confirmPassword) {
            setError(true)
            return setpasswordError(AppConstant.login.passwordMismatch)
        }

        setError(false)

        const data = {
            user: user.auth,
            newPassword: newPassword
        }
         // dispatch action for new password
        dispatch({ type: sagaActions.SET_PASSWORD, payload: data })
    }

     /** 
     * useEffect for password status
     */
    useEffect(() => {
        if (!_.isNil(user.setPasswordStatus)) {
            dispatch({ type: sagaActions.CLEAR_SET_PASSWORD })
            removeInterceptor()
            dispatch({ type: sagaActions.CLEAR_SIGNEDIN_USER })
            dispatch({ type: sagaActions.RESET_USER })
            window.localStorage.clear();
            navigate("/")
        }
    }, [user.setPasswordStatus])

    /**
     * useEffect for reset password loader
     */
    useEffect(() => {
        setisloading(user.setPasswordLoading)
    }, [user.setPasswordLoading])

     /**
     * useEffect for reset password error
     */
    useEffect(() => {
        if (!_.isNil(user.setPasswordError)) {
            setError(true)
            dispatch({ type: sagaActions.CLEAR_SIGNIN_ERROR })
        }
    }, [user.setPasswordError])

    /**
     * useEffect to validate if new password and confirm password are same
     */
    useEffect(() => {
        if (isValid('password', newPassword) && isValid('password', confirmPassword)) {
            setError(false)
        } else {
            setError(true)
        }
    }, [newPassword, confirmPassword])

     /** 
     * useEffect for branding data
     */
    useEffect(() => {
        if (!_.isEmpty(branding)) {
            const { IsBranding, WarehouseLogo, BackgroundImage, CompanyLogo, GroupLogo } = branding
            if (IsBranding) {
                setBgImage(BackgroundImage)
                setWarehouseLogo(WarehouseLogo ? WarehouseLogo : CompanyLogo ? CompanyLogo : GroupLogo ? GroupLogo : AppConstant.commonStrings.logo_wordMark)
            }
        }
    }, [branding])

    return (
        <div className='login-wrapper'>
            <div
                className="login-container-ss container-fluid d-flex justify-content-between p-4"
                id="container"
            >
                
                <div className="inner_blocks" id="right-block">
                    <div id="logo-holder">
                        <img src={AppConstant.commonStrings.schedulerProLogo} alt="FrieghtSmith logo" />
                    </div>
                    {/* -------------------- Form Section ------------------------*/}
                    <div className="form-section">
                        <div className='d-flex flex-column justify-content-start align-items-start'>
                            <div className='my-4'>
                                <h5>{AppConstant.login.setPassword}</h5>
                                <p id="login-sub-header">{AppConstant.login.recover.t4}</p>
                            </div>
                            <div className='w-100'>
                                <PasswordInputSS
                                    showInfo={true}
                                    passwordInfo={AppConstant.login.recover.passwordInfoTooltip}
                                    errorMessage={passwordError || AppConstant.login.passwordValidation}
                                    showError={error}
                                    title={AppConstant.login.newPassword} placeHolder={AppConstant.login.enterPassword}
                                    onPasswordChange={setnewPassword} />
                                <PasswordInputSS
                                    showInfo={true}
                                    passwordInfo={AppConstant.login.recover.passwordInfoTooltip}
                                    errorMessage={passwordError || AppConstant.login.passwordValidation}
                                    showError={error}
                                    title={AppConstant.login.confirmPassword} placeHolder={AppConstant.login.enterPassword}
                                    onPasswordChange={setconfirmPassword} />
                                <div className="d-flex justify-content-between w-100 my-5">
                                    <CustomButtonSS
                                        isLoading={isloading}
                                        title={AppConstant.commonStrings.submit} className={"btn-submit"}
                                        onClick={() => initiateSetPassword()}
                                    />
                                </div>
                                <div className="fs-10 d-flex flex-column justify-content-center align-items-center p-0 my-5">
                                    <p>{AppConstant.login.recover.tryLoginText}</p>
                                    <span>
                                        <a
                                            href="/"
                                            style={{ color: "#10C6F5" }}
                                            className="mx-1 fw-semibold"
                                        >
                                            {AppConstant.login.recover.tryLoginButtonTitle}
                                        </a>
                                    </span>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <TruckComponent />
            </div>
        </div>
    )
}

export default SetPasswordSS