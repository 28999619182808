import React from 'react';
import { Link } from 'react-router-dom';
import { AppConstant } from '../../../../Assests/AppConstant';
/**
 * This component return JSX for heading
 * @param {*} props 
 * @returns 
 */
const SettingHeading = (props) => {
    const { themeSelected } = props;
    return (

        <div className={`px-3 py-1 setBorderBottom sub dd-subnav ${themeSelected}`}>
           <div className='pt-2'>
           <Link to="/" style={{ color: 'inherit', textDecoration: 'inherit'}}>
            <span><i className='fa fa-arrow-left'></i></span>
            </Link>
            <span className='ps-2 boldText'>{AppConstant.settings.heading}</span>
            
           </div>
        </div>
    )
}

export default SettingHeading;