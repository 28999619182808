const driverAcknowlegmentData = {
    title: "Driver Acknowledgement will Appear here",
    status: "driverAcknowlegment"
}

const waitingForDriverAcknowlegment = {
    title: "Waiting for Driver Acknowledgement",
    status: "waitingForDriverAcknowlegment"
}

const driverAcknowleged = {
    title: "Driver Acknowledged, Proceeding to Door",
    status: "driverAcknowleged"
}

const driverRejected = {
    title: "Driver has not Acknowledged",
    status: "driverRejected"
}

export { driverAcknowlegmentData, waitingForDriverAcknowlegment, driverAcknowleged, driverRejected }