import React, { useState } from 'react';
import { CustomDynamicForm } from '../../../../../Common/CustomForm/CustomDynamicForm';
import { AppConstant } from '../../../../../../Assests/AppConstant';
import { useNavigate } from 'react-router-dom';

const CreditCardForm = (props) => {

    const { themeSelected } = props;
    const [loading, setLoading] = useState(false);
    const [resetFormData, setResetFormData] = useState(0);
    const [editData, setEditData] = useState({});
    const navigate = useNavigate();

    const formFieldList = [
        {
            name: "card_number",
            type: "number",
            size: "col-sm-12",
            position: 1,
            title: AppConstant.smartScheduler.carrierSignUp.creditCardForm.cardNumber,
            formValidation: {
            },
        },
        {
            name: "expiration_date",
            type: "text",
            size: "col-sm-12",
            position: 2,
            title: AppConstant.smartScheduler.carrierSignUp.creditCardForm.cardExpirationDate,
            formValidation: {
            },
        },
        {
            name: "csc_security_code",
            type: "text",
            size: "col-sm-12",
            position: 3,
            title: AppConstant.smartScheduler.carrierSignUp.creditCardForm.cscSecurityCode,
            formValidation: {
            },
        },
    ]

    const cancelbtndata = {
        show: true,
        text: AppConstant.comments.resetbutton,
    };

    const submitbtnData = {
        show: true,
        text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm
            .saveButtonTitle,
    };

    const getFormFieldData = (data, name) => {

    };

    const getSummitedFormData = (data, name) => {

    };

    return (
        <div>
            <CustomDynamicForm
                formFieldList={formFieldList}
                sort={true}
                cancelBtn={cancelbtndata}
                submitbtn={submitbtnData}
                themeSelected={themeSelected}
                getFormFieldData={getFormFieldData}
                getSummitedFormData={getSummitedFormData}
                editFormData={editData}
                loading={loading}
                resetFormData={resetFormData}
            />
        </div>
    )
}

export default CreditCardForm;