import React,{useState, useEffect} from 'react'
import './TableFilterButtons.css'

const TableFilterButtons = (props) => {
    const {title, themeSelected, isActive} = props
    const [themeStr, setThemeStr] = useState('')

    useEffect(() => {
        const str = themeSelected.replace(/ /g,'')+'-poListFilters';
        setThemeStr(str)
    },[themeSelected])

    return(
        <span className='tableFilterButtons'>
            <button type="button" className={`btn  btn-outline-secondary
            ms-0 me-2 px-2 rounded-3 h-auto ${themeStr} buttonPOFilters ${isActive ? 'selectedButton' : ''}`} onClick={(filter) => props.clicked(filter)}>
                {title}
            </button>
        </span>
        
    )
}

export default TableFilterButtons;