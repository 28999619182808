import React from 'react';
import { ReactComponent as NoDataLightMode } from '../../../../../../../Assests/Svg/NoDataLightMode.svg';
import { ReactComponent as NoDataDarkMode } from '../../../../../../../Assests/Svg/NoDataDarkMode.svg';
import { AppConstant } from '../../../../../../../Assests/AppConstant';
const NoData = (props) => {
    const {themeSelected} = props;
    return ( 
        <tr className='text-center align-middle' data-testid={AppConstant.dataTestId.emptyList}>
                <td colSpan={21} className='no-data-wrappper'>
                    <div className='d-flex flex-column align-items-center justify-content-center'>
                        <span className='no-data-svg'>{themeSelected === 'light-mode' ? <NoDataLightMode /> : <NoDataDarkMode />}</span>
                        <span className={`no-data-text1 ${themeSelected}`}>{AppConstant.poManagement.emptyTable.text1}</span>

                    </div>
                </td>
            </tr>
     );
}
 
export default NoData;