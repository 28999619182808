import React, { useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux'
import { AppConstant } from '../../../../Assests/AppConstant';
import TruckLoadSectionHeaders from '../../../Common/Headers/TruckLoadSection/TruckLoadSectionHeaders';
import CommentsListSection from '../Comments/CommentsListSection/CommentsListSection';
import {getLoadSelector} from '../../../../../redux/reducers/reducerSlices/getLoadReducer';
import './CommentsSection.css';
import InputSectionCommentsIMSSP from './InputComments/InputCommentsIMSSP';
import InputSectionComments from './InputComments/InputComments'
const CommentsSection = (props) => {
    const {themeSelected, type} = props;
    const dispatch = useDispatch();
    const getLoad = useSelector(getLoadSelector);
    const { selectedLoad } = getLoad;
    const {load_control_num} = selectedLoad;

    return (
        load_control_num &&
        <div className='commentsSection'>
            <TruckLoadSectionHeaders title={AppConstant.comments.commentsTitleStr}/>
            {type ==='IMS' ? <InputSectionComments load_control_num={load_control_num} themeSelected={themeSelected} /> : <InputSectionCommentsIMSSP load_control_num={load_control_num} themeSelected={themeSelected}/>}
            <CommentsListSection load_control_num={load_control_num} themeSelected={themeSelected}/>
        </div>
    )
}

export default CommentsSection;