import _ from 'lodash';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { AppConstant } from '../App/Assests/AppConstant';
import { Uchain } from '../redux/api/agent';
import {
    getLoadSuccess, getLoadFailure, initiateGetLoad, getDoorListSuccess, assignDoorSuccess, assignDoorFailure, resetTimer, enableDoor, getDoorListFailure, alreadyAssignDoorSuccess, updateDockID, releaseDoorReducer, confirmDoorLoader, confirmDoor, updateStartDoorAcknowledgementTimer
} from '../redux/reducers/reducerSlices/getLoadReducer';
import { sagaActions } from './sagaActions';
import { showAlert } from '../App/Assests/Utility';
import { toast } from 'react-toastify';

function* loadTruckDetailsHandler(data) {
    const { load_control_num, dock_id, truck_load_status } = data.payload
    try {
        const response = yield call(Uchain.getLoadDetails, { load_control_num });
        if (typeof response === 'string') {
            yield put(getLoadFailure());
            return
        }
        const newResponse = {
            response,
            dock_id,
            truck_load_status
        }
        yield put(getLoadSuccess(newResponse));
    } catch {
        yield put(getLoadFailure());
        // yield put(getDoorListFailure());
    }
}

function* initateLoader() {
    try {
        yield put(initiateGetLoad())
    } catch (error) {
        yield put(getLoadFailure());
        // yield put(getDoorListFailure());

    }
}

function* resetSelectedLoad() {
    try {
        yield put(getLoadFailure())
    } catch (error) {
        yield put(getLoadFailure());

    }
}

function* updateDockIDSaga(data) {
    try {
        yield put(updateDockID(data.payload))
    } catch { }
}

function* getDoorListSaga(data) {
    try {
        const { order, key } = AppConstant.commonStrings.arrangeDoor;
        const { dock_id } = data.payload;
        let getAllDoors = yield call(Uchain.getDoors, { dock_id })
        getAllDoors = _.orderBy(getAllDoors, [key], [order]);
        yield put(getDoorListSuccess({ doorList: getAllDoors }));
        // const response = yield call(Uchain.getDoors, data)
        // yield put(getUpdateListSuccess(response));
    } catch {
        yield put(getDoorListFailure());
    }
}

function* assignDoorSaga(data) {
    try {
        const response = yield call(Uchain.assignDoor, data.payload[0]);
        const check_door_status = _.has(response, 'door_status')
        if (!check_door_status || check_door_status && response.door_status !== AppConstant.doorManagement.assignDoorResponseText.alreadyAssigned) {
            const nRes = { ...data.payload[0], ...response }
            nRes.door_status = AppConstant.doorManagement.assignDoorText.display_door_status_assigned;
            yield put(assignDoorSuccess([nRes]));
        }
        else {
            yield put(assignDoorFailure([]))
        }
    } catch {
        yield put(assignDoorFailure(data.payload))
    }
}

function* alredayHasAssignedDoorSaga(data) {
    try {
        yield put(alreadyAssignDoorSuccess([data.payload]));
    } catch {
        // yield put(assignDoorFailure(data.payload))
    }
}

function* changeDoorSaga(data){
    try{
        // const response = yield call(Uchain.assignDoor, data.payload[0])
        // if(response.door) yield put(assignDoorFailure([]))
        // else{
        //     yield put(assignDoorFailure([]));
        // }
        yield put(assignDoorFailure([]))
    }catch{
        yield put(assignDoorFailure([]))
    }
}

function* removeAssignDoorSaga() {
    try {
        yield put(assignDoorFailure([]))
    } catch {
        yield put(assignDoorFailure([]))
    }
}

function* resetDoorAcknowlegmentTimerSaga() {
    try {
        yield put(resetTimer());
    } catch { }
}

function* enableDoorSectionSaga(data) {
    try {
        yield put(enableDoor(data.payload))
    } catch (error) { }
}

function* releaseDoorSaga(data) {
    try {
        const response = yield call(Uchain.releaseDoorApi, data.payload);
        if(response.isDoor_free === 1){
            yield put(releaseDoorReducer(true));
        }
        
    } catch (error) { }
}

function* resetDoorSaga() {
    try {
        yield put(releaseDoorReducer(false));
    } catch (error) { }
}

function* initiateConfirmDoorSaga(data) {
    try {
        const response = yield call(Uchain.confirmDoor, data.payload);
        if (response?.status === 'Success') {
            yield put(confirmDoor(true))
            showAlert(toast.TYPE.SUCCESS, response?.message);
            yield put(updateStartDoorAcknowledgementTimer(true))
        } else {
            yield put(confirmDoor(false))
            yield put(confirmDoorLoader(false))
            yield put(updateStartDoorAcknowledgementTimer(false))
        }
    } catch (error) {
        yield put(confirmDoor(false))
        yield put(confirmDoorLoader(false))
        yield put(updateStartDoorAcknowledgementTimer(false))
     }
}

function* updateConfirmDoorSaga(data) {
    try {
        yield put(confirmDoor(data.payload))
    } catch (error) {
       
     }
}

function* updateConfirmDoorLoaderSaga(data) {
    try {
        yield put(confirmDoorLoader(data.payload))
    } catch (error) { }
}


export function* enableDoorSection() {
    yield takeLatest(sagaActions.ENABLE_DOOR_SECTION, enableDoorSectionSaga)
}

export function* initiateLoadLoader() {
    yield takeLatest(sagaActions.INTIATE_TRUCK_DETAILS, initateLoader)
}

export function* loadTruckDetails() {
    yield takeLatest(sagaActions.LOAD_TRUCK_DETAILS, loadTruckDetailsHandler)
}

export function* resetLoadTruckDetails() {
    yield takeLatest(sagaActions.RESET_LOAD_TRUCK_DETAILS, resetSelectedLoad)
}
export function* getDoorList() {
    yield takeLatest(sagaActions.GET_DOOR, getDoorListSaga)
}

export function* assignDoor() {
    yield takeLatest(sagaActions.ASSIGN_DOOR, assignDoorSaga)
}

export function* alreadyAssignedDoor() {
    yield takeLatest(sagaActions.ALREADY_ASSIGNED_DOOR, alredayHasAssignedDoorSaga)

}

export function* resetDoorAcknowlegmentTimer() {
    yield takeLatest(sagaActions.RESET_DOOR_ACKNOWLEDGMENT_TIMER, resetDoorAcknowlegmentTimerSaga)
}

export function* changeDoor() {
    yield takeLatest(sagaActions.CHANGE_DOOR, changeDoorSaga)
}
export function* removeAssignDoor() {
    yield takeLatest(sagaActions.REMOVE_ASSIGN_DOOR, removeAssignDoorSaga)
}
export function* updateDockId() {
    yield takeLatest(sagaActions.UPDATE_DOCK_ID, updateDockIDSaga)
}
export function* releaseDoor() {
    yield takeLatest(sagaActions.RELEASE_DOOR, releaseDoorSaga)
}
export function* resetReleaseDoor() {
    yield takeLatest(sagaActions.RESET_RELEASE_DOOR, resetDoorSaga)
}
export function* initiateConfirmDoorWatcher() {
    yield takeLatest(sagaActions.INITIATE_CONFIRM_DOOR, initiateConfirmDoorSaga)
}
export function* updateConfirmDoorLoaderWatcher() {
    yield takeLatest(sagaActions.UPDATE_CONFIRM_DOOR_LOADER, updateConfirmDoorLoaderSaga)
}
export function* updateConfirmDoorWatcher() {
    yield takeLatest(sagaActions.UPDATE_CONFIRM_DOOR, updateConfirmDoorSaga)
}