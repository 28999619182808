import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUserSelector } from '../../../../../../redux/reducers/reducerSlices/UserReducer';
import DrilldownTable from '../../../Components/Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTable';
import { AppConstant } from '../../../../../Assests/AppConstant';
import { sagaActions } from '../../../../../../sagas/sagaActions';
import { initiateSearch, showAlert, changeFormatWithoutTz } from '../../../../../Assests/Utility';
import { getPoSSselector } from '../../../../../../redux/reducers/reducerSlices/POSmartSchedulerReducer';
import EditLight from '../../../../../Assests/Images/editLight.png';
import EditDark from '../../../../../Assests/Images/editDark.png';
import EnabledIcon from '../../../../../Assests/Images/enablePO.png';
import DisabledIcon from '../../../../../Assests/Images/disablePO.png';
import EditDisabledIcon from '../../../../../Assests/Images/editDisabled.png';
import DisabledPOIcon from '../../../../../Assests/Images/disabledPO.png';
import CustomTooltip from '../../../../Common/Tooltip/CustomTooltip';
import { fetchPoListCommonFunc } from '../Common/FetchPOList';
import { getCurrentDate } from '../../../../../../Utils/DateUtils';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import _ from 'lodash-es';
import './SsPOList.css';

const SsPOList = (props) => {

    const { themeSelected, editPOAction, searchStr } = props;
    const dispatch = useDispatch();
    const user = useSelector(getUserSelector);
    const [poArr, setPoArr] = useState([]);
    const { selectedWarehouse, cu_id } = user;
    const [isListLoading, setListLoading] = useState(false);
    const [currentItemIndex, setCurrentItemIndex] = useState('NA');
    const pOSS = useSelector(getPoSSselector);
    const { poSSArr, operationObj, arrangeOrder, arrangeKey } = pOSS;

    const createPoTitleDD = [
        { id: '', name: AppConstant.smartScheduler.poSection.serial_num },
        { id: 'PoNumber', name: AppConstant.poManagement.tableHeader.po },
        { id: 'DockName', name: AppConstant.smartScheduler.poSection.produceType },
        { id: 'Pallets', name: AppConstant.smartScheduler.poSection.palletsCount },
        { id: 'PoCreatedOn', name: AppConstant.superUserAdmin.companyGroup.tableHeader.createdOn },
        { id: 'ApptStartTime', name: AppConstant.superUserAdmin.companyGroup.tableHeader.apptTime },
        { id: 'CarrierName', name: AppConstant.poManagement.tableHeader.carrier },
        { id: 'ApptScheStatus', name: AppConstant.helpSupportFaq.tableHeaders.statusTitle },
        { id: '', name: AppConstant.superUserAdmin.companyGroup.tableHeader.actions },
    ]

    useEffect(() => {
        if (operationObj.type) {
            switch (operationObj.type) {
                case 'Update status':
                    if (operationObj.status) {
                        handleApiResponse(AppConstant.notifications.dataAddedSuccess, AppConstant.smartScheduler.poSection.updatePoStatusSuccess);
                    } else {
                        handleApiResponse(AppConstant.commonStrings.failure, AppConstant.smartScheduler.poSection.updatePoStatusFailure + `: ${operationObj.message}`);
                    }
                    break;
                case 'Fetch POs':
                    if (!operationObj.status) {
                        setListLoading(false);
                    }
                    break;
                default:
            }
        }
    }, [operationObj])

    const handleApiResponse = (result, message) => {
        if (result === AppConstant.notifications.dataAddedSuccess) {
            showAlert(toast.TYPE.SUCCESS, message, themeSelected);
            fetchPoList()
        } else {
            showAlert(toast.TYPE.ERROR, message, themeSelected);
        }
        dispatch({ type: sagaActions.RESET_SS_PO_OPERATION_OBJ });
    }

    useEffect(() => {

        setPoArr([]);
        if (poSSArr && poSSArr.length) {
            setListLoading(false);
            setCurrentItemIndex('NA')
            const poTempArr = poSSArr.map((ele) => {
                if (!ele.CarrierName && ele.ApptScheStatus === 'Not Scheduled') {
                    const tempObj = { ...ele, "CarrierName": "-" };
                    return tempObj
                }
                return ele;
            })
            setPoArr(poTempArr);
        } else {
            if (!operationObj.status) {
                setListLoading(false);
            }
        }
    }, [poSSArr])

    useEffect(() => {
        if (searchStr && searchStr.length > 3) {
            const searchedArr = initiateSearch(poSSArr, searchStr, '');
            setPoArr(searchedArr);
        } else if (searchStr === '') {
            setPoArr(poSSArr)
        }
    }, [searchStr])

    useEffect(() => {
        dispatch({ type: sagaActions.RESET_PO_LIST });
        setListLoading(true);
        setPoArr([]);
        setTimeout(() => {
            fetchPoList();
        }, [1000])
    }, [])

    const fetchPoList = () => {
        const { apiParams, operationObj } = fetchPoListCommonFunc(selectedWarehouse);
        dispatch({ type: sagaActions.UPDATE_PO_LIST, payload: apiParams, operationObj: operationObj });
    }

    const deletePOAction = (obj, index) => {
        setCurrentItemIndex(index)
        let tempObj = {
            "PoNumber": obj.PoNumber,
            "CRUDTYPE": "D",
            "IDPO": obj.IDPO,
            "PoStatus": !obj.PoStatus,
            "CreatedOn": obj.PoCreatedOn,
            "CreatedBy": cu_id,
            "UpdatedOn": changeFormatWithoutTz(getCurrentDate(), AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
            "UpdatedBy": cu_id
        }
        const operationObj = {
            type: 'Update status',
            status: ''
        }
        setListLoading(true);
        dispatch({ type: sagaActions.UPDATE_PO_LIST, payload: { ...obj, ...tempObj }, operationObj: operationObj })
    }

    const editPO = (el, index) => {
        editPOAction(el, index);
    }

    const actionButtons = (el, index) => {
        return (
            <div className='d-flex justify-content-center my-2'>
                <img src={el.ApptScheStatus !== 'Not Scheduled' ?
                    EditDisabledIcon : themeSelected === 'light-mode' ?
                        EditLight : EditDark}
                    className={`editIconPO me-2 ${el.ApptScheStatus === 'Not Scheduled' ? '' : 'disabled'}`}
                    alt="Edit Po"
                    data-tip={el.ApptScheStatus !== 'Not Scheduled' ? '' : 'Edit PO'}
                    data-for={'tooltip_id'}
                    onClick={el.ApptScheStatus === 'Not Scheduled' ? () => editPO(el, index) : () => ''} />
                <img

                    src={el.ApptScheStatus !== 'Not Scheduled' ?
                        DisabledPOIcon : el.PoStatus ? EnabledIcon : DisabledIcon}
                    data-tip={el.ApptScheStatus !== 'Not Scheduled' ? '' : el.PoStatus ? 'Enabled' : 'Disabled'}
                    data-for={'tooltip_id'}
                    className={`editIconPO ms-2 ${el.ApptScheStatus === 'Not Scheduled' ?
                        '' : 'disabled'}`} alt="Change Status"
                    onClick={el.ApptScheStatus === 'Not Scheduled' ? () => deletePOAction(el, index) : () => ''} />
                <CustomTooltip
                    id={'tooltip_id'}
                    position='top'
                    type={themeSelected === AppConstant.commonStrings.lightModeFilterString ? 'light' : 'dark'}
                    multiline={false} />
            </div>
        )
    }

    const getApptSchStatusColor = (str) => {
        if (str === 'Completed') {
            return <p className='text-success'>{str}</p>
        } else if (str === 'Scheduled') {
            return <p className='text-danger'>{str}</p>
        } else {
            return <p className='notScheStatusText'>{str}</p>
        }
    }

    const formatListDataDrillDown = (data) => {
        return data.map((el, index) => {
            return {
                id: el.RoleID,
                0: (index + 1),
                1: el.PoNumber || '-',
                2: el.DockName || '-',
                3: el.Pallets || '-',
                4: `${moment.utc(el.PoCreatedOn).local().format(AppConstant.commonStrings.MM_DD_YYYY_dateFormat)}\n${moment.utc(el.PoCreatedOn).local().format(AppConstant.commonStrings.HH_mm_dateFormat)}` || '-',
                5: el?.ApptStartTime ?`${moment.utc(el.ApptStartTime).local().format(AppConstant.commonStrings.MM_DD_YYYY_dateFormat)}\n${moment.utc(el.ApptStartTime).local().format(AppConstant.commonStrings.HH_mm_dateFormat)}` || '-': '-',
                6: el.CarrierName,
                7: el.ApptScheStatus ? getApptSchStatusColor(el.ApptScheStatus) : '-',
                8: actionButtons(el, index)
            }
        })
    }

    const getPaginationArr = () => {
        return [1, 2, 3, 4, 5].map((item, index) => {
            return <li className="page-item"><a className="page-link text-dark" href="#">{item}</a></li>
        })
    }

    return (
        <div className=''>
            {
                <div>
                    <DrilldownTable
                        themeSelected={themeSelected}
                        bodyData={poArr && poArr.length && formatListDataDrillDown(poArr)}
                        titleData={createPoTitleDD}
                        showCollapse={false}
                        showInput={false}
                        showSort={true}
                        sortKey={true}
                        arrangeOrder={arrangeOrder}
                        arrangeKey={arrangeKey}
                        drillDownReport={poArr}
                        initiateDrillDownReport={isListLoading}
                        loaderArray={9}
                        sortingAction={sagaActions.SORT_SS_USER_TABLE}
                        subRoot={{ pathName: 'poSSList' }}
                        showUTCTime={true}
                    />
                    {/* PAGINATION UI COMPONENT <div className='sticky-bottom mt-auto justify-content-end pb-3 bg-transparent'>
                        <nav className='mx-5 paginationSection bg-transparent'>
                            <ul className="pagination justify-content-end w-100 bg-transparent">
                                <li className="page-item bg-transparent">
                                    <a className="page-link text-dark" href="#">
                                        <span aria-hidden="true">&laquo;</span>
                                    </a>
                                </li>
                                {getPaginationArr()}
                                <li className="page-item">
                                    <a className="page-link text-dark" href="#">
                                        <span aria-hidden="true">&raquo;</span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div> */}
                </div>
            }
        </div>
    )

}
export default SsPOList;