import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { sagaActions } from '../../../../../sagas/sagaActions';
import { getUserSelector } from "../../../../../redux/reducers/reducerSlices/UserReducer";
import Header from "../../../Header/Header";
import Navigationbar from "../../../Navigationbar/Navigationbar";
import { AppConstant } from "../../../../Assests/AppConstant";
import HelpAndFeedbackCoponent from "../../components/helpAndFeedbackComponent";
import Popup from "../../../Common/Popup/Popup";
import "./help.css";
import { useNavigate } from 'react-router-dom';
import HelpAndFeedbackUserTable from "./HelpAndFeedbackUserTable";
import WarehouseSearch from '../../../SuperWarehouse/Components/SearchWarehouse/WarehouseSearch';
import { getSuperAdminSelector } from '../../../../../redux/reducers/reducerSlices/SuperAdminReducerSlice';
const HelpAndFeedInside = (props) => {
  const navigate = useNavigate();
  const { themeSelected } = props;
  const dispatch = useDispatch();
  const superAdminSagaObj = useSelector(getSuperAdminSelector);
  const { issuesList, feedbackList } = superAdminSagaObj;
  const userPermission = useSelector(getUserSelector);
  const { selectedWarehouse, userAccountDetails } = userPermission;
  const [formName, setFormName] = useState(AppConstant.help.formName);
  const [searchStr, setSearchStr] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [feedbackApiList, setFeedbackApiList] = useState([]);
  const [issuesApiList, setIssuesApiList] = useState([]);

  const [isloading,setIsDataLoading]=useState(false);
  

  useEffect(() => {
    getFeedbacksAndIssuesList();
}, [formName])
useEffect(()=>{
  if(!selectedWarehouse.CUID){
    alert("warehouse is not selected");
    navigate("/");
  }
},[selectedWarehouse])
useEffect(() => {
    if (feedbackList) {
      setIsDataLoading(false);
        setFeedbackApiList(feedbackList)
    }
}, [feedbackList])

useEffect(() => {
    if (issuesList) {
      setIsDataLoading(false);
        setIssuesApiList(issuesList)
    }
}, [issuesList])
const getFeedbacksAndIssuesList = () => {
  const apiIssuesParams = {
      "Id": null,
      "name": null,
      "email": null,
      "phoneNumber": null,
      "issue": null,
      "feedback": null,
      "fileOne": null,
      "fileTwo": null,
      "ownedBy": null,
      "status": null,
      "type": "Issue",
      "userId":userAccountDetails.UserID,
      "warehouseId":selectedWarehouse.CUID,
      "crud_type": "SU"
  }
  setIsDataLoading(true);
  dispatch({ type: sagaActions.UPDATE_ISSUES_LIST, payload: apiIssuesParams });

  const apiFeedbackParams = {
      "Id": null,
      "name": null,
      "email": null,
      "phoneNumber": null,
      "issue": null,
      "feedback": null,
      "fileOne": null,
      "fileTwo": null,
      "ownedBy": null,
      "status": null,
      "type": "Feedback",
      "userId":userAccountDetails.UserID,
      "warehouseId":selectedWarehouse.CUID,
      "crud_type": "SU"
  }
  setIsDataLoading(true);
  dispatch({ type: sagaActions.UPDATE_FEEDBACK_LIST, payload: apiFeedbackParams });
}
  const closepopup = () => {
    setShowModal(false);
  };
  const closeHelpModal = () => {setShowModal(false); setTimeout(()=>{getFeedbacksAndIssuesList();},10) };
  const clickHandler = (el) => {
    if (el === AppConstant.help.formName) {
      setFormName(AppConstant.help.formName);
 } else {
      setFormName("Feedback");
    }
  };
 
  const createForm = () => {
    return (
      <HelpAndFeedbackCoponent
        formName={formName}
        closeHelpModal={closeHelpModal}
        userId={userAccountDetails.UserID}
        warehouseId={selectedWarehouse.CUID}
       
      />
    );
  };
  const popupObj = {
    id: "createHelpAndFeedPopup1",
    modalLabel: "createHelpAndFeedFormPopup1",
    showClose: true,
    showfooter: true,
    showHeader: true,
    sendDriverNotification: false,
    modalSize: "lg",
    title:
      formName === AppConstant.help.formName
        ? AppConstant.help.titleTextForHelp
        : AppConstant.help.titleTextForFeedback,
    bodyText: createForm(),
    subText: "",
    keyboard: false,
    backdrop: "static",
  };

  return (
    <div className="container-fluid executor">
      <Header {...props} />
      <Navigationbar {...props} />
      <div className="d-flex align-items-center mt-2 justify-content-between">
       <div className="d-flex">
        <div
          className={
            formName === AppConstant.help.formName
              ? "buttonReport mx-1 selectedButton"
              : "buttonReport mx-1 unselectedButton"
          }
          onClick={() => clickHandler("Help")}
        >
          {AppConstant.help.titleTextForHelp}
        </div>
        <div
          className={
            formName !== AppConstant.help.formName
              ? "buttonReport mx-1 selectedButton"
              : "buttonReport mx-1 unselectedButton"
          }
          onClick={() => clickHandler("Feedback")}
        >
          {AppConstant.help.titleTextForFeedback}
        </div>
        </div>
        <div className='w-25'>
                    <WarehouseSearch
                        placeholder={formName === AppConstant.help.formName ?'Search Help': 'Search Feedback'  }
                        inputValue={searchStr}
                        cancelSearch={() => setSearchStr('')}
                        onChangeHandler={(text) => setSearchStr(text)} />
                </div>
      </div>
     
      <Popup
        {...popupObj}
        showModal={showModal}
        showfooter={false}
        closepopup={closepopup}
        themeSelected={themeSelected}
      />
      <div className="d-flex align-items-center mt-2 mb-2 justify-content-between">
       <div className="d-flex">
         <div
          className= "buttonReport mx-1 selectedButton"
          onClick={() => setShowModal(true)}
        >
          { formName === AppConstant.help.formName?AppConstant.help.createTextForHelp:AppConstant.help.createTextForFeedback}
        </div>
        </div>
        </div>
       <HelpAndFeedbackUserTable
                     isDataLoading={isloading}
                        searchStr={searchStr}
                        feedbacksList={feedbackApiList}
                        issuesList={issuesApiList}
                        currentSection={formName}
                        {...props} />
    </div>
  );
};

export default HelpAndFeedInside;
