import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash-es';
import { sagaActions } from '../../../../../../../../sagas/sagaActions';
import { AppConstant } from '../../../../../../../Assests/AppConstant';
import { getCurrentDate } from '../../../../../../../../Utils/DateUtils';
import { initiateSearch, showAlert, changeFormatWithoutTz } from '../../../../../../../Assests/Utility';
import { toast } from 'react-toastify';
import { getUserSelector } from '../../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { getWhMetaDataReducerSlice } from '../../../../../../../../redux/reducers/reducerSlices/WhMetaDataReducerSlice';
import { fetchTimeslotListCommonFunc } from '../Common/FetchTimeslotsList';
import CommonToggleSwitch from '../../../../../../Common/CommonToggleSwitch/CommonToggleSwitch';
import DrilldownTable from '../../../../../Components/Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTable';
import CustomButton from '../../../../../../Common/CustomButton/CustomButton';
import moment from 'moment';
import './TimeslotMetaList.css';
import DrilldownTableSS from '../../../../../Components/Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTableSS';
import CustomButtonSS from '../../../../../../Common/CustomButton/CustomButtonSS';
import EditIcon from '../../../../../../../Assests/Images/edit-icon-new.png'
import SSPopup from '../../../../../../Common/Popup/SSPopup/SSPopup';

const TimeslotMetaListSS = (props) => {

    const { themeSelected, editTimeSlotAction, searchStr, allowPermissionE, allowPermissionD, whObj,getSummitedFormData } = props;
    const dispatch = useDispatch();
    const whMetaReducer = useSelector(getWhMetaDataReducerSlice);
    const { whMetaTimeslotList, operationObj, arrangeOrder, arrangeKey,preCheckList } = whMetaReducer;
    const [isListLoading, setListLoading] = useState(false);
    const [metaTimeslotArr, setMetaTimeslotArr] = useState([]);
    const user = useSelector(getUserSelector);
    const { cu_id } = user;
    const [currentItemIndex, setCurrentItemIndex] = useState('NA');
    const [currentDeleteIndex, setCurrentDeleteIndex] = useState('NA');
    const [selectedObj,setSelectedObj]=useState(null)
    const [selectedIndex,setSelectedIndex]=useState(null)
    const [showDeleteModel,setShowDeleteModel]=useState(false);

    useEffect(()=>{
        if (preCheckList && preCheckList.length>0) {
            setCurrentItemIndex("NA")
            setCurrentDeleteIndex('NA')
        }
    },[preCheckList])

    const dockTimeslotsTitleDD = [
        { id: '', name: AppConstant.superUserAdmin.companyGroup.tableHeader.serialNum },
        { id: 'Name', name: AppConstant.poManagement.tableHeader.dock },
        { id: 'TimeSlotName', name: AppConstant.smartScheduler.docksTimeSlots.tableHeaders.timeslot },
        // { id: 'DisplayOrderNo', name: AppConstant.smartScheduler.docksTimeSlots.tableHeaders.displayOrderNum },
        { id: 'CreatedDate', name: AppConstant.smartScheduler.docksTimeSlots.tableHeaders.CreatedOn },
        { id: 'status', name: AppConstant.smartScheduler.docksTimeSlots.tableHeaders.status },
        { id: '', name: AppConstant.superUserAdmin.warehouseAdmin.warehouseTable.actionsColTitle },
    ]

    useEffect(() => {
        setListLoading(true);
        setMetaTimeslotArr([]);
        dispatch({ type: sagaActions.WAREHOUSE_META_RESET_TIMESLOT_ACTION, operationObj: operationObj })
        setTimeout(() => {
            fetchTimeslotList();
        }, [700])
    }, [])

    useEffect(() => {
        if (operationObj.type) {
            switch (operationObj.type) {
                case 'Update status':
                    if (operationObj.status) {
                        handleApiResponse(AppConstant.notifications.dataAddedSuccess, AppConstant.smartScheduler.timeslots.updateTimeslotStatusSuccess);
                    } else {
                        handleApiResponse(AppConstant.commonStrings.failure, AppConstant.smartScheduler.timeslots.updateTimeslotStatusSuccess);
                    }
                    break;
                case 'Delete Timeslot':
                        if (operationObj.status) {
                                handleApiResponse(AppConstant.notifications.dataAddedSuccess, AppConstant.smartScheduler.timeslots.deleteTimeslotSuccess);
                        } else {
                                handleApiResponse(AppConstant.commonStrings.failure, AppConstant.smartScheduler.timeslots.deleteTimeslotFailure);
                            }
                         break;
                case 'Fetch Timeslot':
                    if (!operationObj.status) {
                        setListLoading(false);
                        setMetaTimeslotArr([]);
                        // handleApiResponse(AppConstant.commonStrings.failure, AppConstant.smartScheduler.timeslots.fetchTimeslotListFailure);
                    }
                    break;
                default:
            }
        }
    }, [operationObj])


    const handleApiResponse = (result, message) => {
        setCurrentItemIndex('NA')
        setCurrentDeleteIndex('NA')
        if (result === AppConstant.notifications.dataAddedSuccess) {
            showAlert(toast.TYPE.SUCCESS, message, themeSelected);
            fetchTimeslotList()
        } else {
            showAlert(toast.TYPE.ERROR, message, themeSelected);
        }
        dispatch({ type: sagaActions.RESET_SS_PO_OPERATION_OBJ });
    }

    const fetchTimeslotList = () => {

        const { apiParams, operationObj } = fetchTimeslotListCommonFunc(whObj);
        dispatch({ type: sagaActions.WAREHOUSE_META_TIMESLOT_ACTION, payload: apiParams, operationObj: operationObj })
    }

    useEffect(() => {
        setMetaTimeslotArr([])
        if (whMetaTimeslotList && whMetaTimeslotList.length) {
            setListLoading(false);
            setCurrentItemIndex('NA');
            setCurrentDeleteIndex('NA')
            setMetaTimeslotArr(whMetaTimeslotList);
        }
    }, [whMetaTimeslotList])

    useEffect(() => {
        if (searchStr.length > 3) {
            const searchedArr = initiateSearch(whMetaTimeslotList, searchStr, 'GroupName');
            setMetaTimeslotArr(searchedArr);
        } else if (searchStr === '') {
            setMetaTimeslotArr(whMetaTimeslotList)
        }
    }, [searchStr])

    const disableTimeslotAction = (data, index) => {
        setCurrentItemIndex(index);
        const apiParams = {
            "Warehouse": data.Warehouse,
            "Dock": data.Name,
            "DoorType": data.DoorType,
            "TimeSlotName": data.TimeSlotName,
            "DisplayOrderNo": data.DisplayOrderNo,
            "DoorStatus": !data.DoorStatus,
            "IDDock": data.IDDock,
            "ID": data.ID,
            "IDWarehouse": data.IDWarehouse,
            "CRUDTYPE": "U",
            "TimeSlotStatus": data.TimeSlotStatus,
            "CreatedOn": data.CreatedDate,
            "CreatedBy": data.CreatedBy,
            "UpdatedBy": cu_id,
            "UpdatedOn": changeFormatWithoutTz(getCurrentDate(), AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
        }

        const operationObj = {
            type: 'Update status',
            status: ''
        }

        dispatch({ type: sagaActions.WAREHOUSE_META_TIMESLOT_ACTION, payload: apiParams, operationObj: operationObj })
    }

    const deleteHandler=(data,index)=>{
        setSelectedObj(data,index)
        setSelectedIndex(index)
        setShowDeleteModel(true)
    }
    const popupBtnHandler=(text)=>{
        if (text===AppConstant.commonStrings.no) {
            setShowDeleteModel(false)
            setSelectedObj(null)
            setSelectedIndex(null)
        }
        else{
            setCurrentDeleteIndex(selectedIndex)
            const apiParams = {
                "Warehouse": selectedObj.Warehouse,
                "Dock": selectedObj.Name,
                "DoorType": selectedObj.DoorType,
                "TimeSlotName": selectedObj.TimeSlotName,
                "DisplayOrderNo": selectedObj.DisplayOrderNo,
                "DoorStatus": true,
                "IDDock": selectedObj.IDDock,
                "ID": selectedObj.ID,
                "IDWarehouse": selectedObj.IDWarehouse,
                "CRUDTYPE": "D",
                "TimeSlotStatus": selectedObj.TimeSlotStatus,
                "CreatedOn": selectedObj.CreatedDate,
                "CreatedBy": selectedObj.CreatedBy,
                "UpdatedBy": cu_id,
                "UpdatedOn": changeFormatWithoutTz(getCurrentDate(), AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
            }
    
            const operationObj = {
                type: 'Delete Timeslot',
                status: ''
            }
    
            dispatch({ type: sagaActions.WAREHOUSE_META_TIMESLOT_ACTION, payload: apiParams, operationObj: operationObj })
        }
        setShowDeleteModel(false)
    }
    const showDeleteConfirmation = () => {
        const popupObj = {
            id: 'showDeleteConfirmation',
            modalLabel: 'showDeleteConfirmation',
            showClose: true,
            showfooter: true,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title:  AppConstant.smartScheduler.timeslots.deleteTimeslotTitle,
            bodyText: `${AppConstant.commonStrings.areYouSure} ${AppConstant.smartScheduler.timeslots.deleteTimeslotTitle}`,            
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.commonStrings.yes
                },
                btn2: {
                    show: true,
                    text: AppConstant.commonStrings.no
                }
            },
        }

        return <SSPopup {...popupObj} popupBtnHandler={(text) => {popupBtnHandler(text)} } closepopup={()=>{setShowDeleteModel(false)}} themeSelected={themeSelected} showModal={showDeleteModel} />
    }


    const actionsButton = (groupObj, index) => {
        return (
            <div>
                <span className='d-flex justify-content-center align-items-center gap-2' >
                    <img src={EditIcon} alt='edit' className='editIconPO-ss' data-bs-toggle="modal"
                        data-bs-target={`#createCompanyGroupFormPopup1`}
                        disabled={allowPermissionE ? '' : 'disabled'}
                        onClick={() => editTimeSlotAction(groupObj)}
                    />
                    {/* <button
                        className={allowPermissionE ? 'btn-highlighted f-12' : 'btn-highlighted f-12 disabled'}
                        data-bs-toggle="modal"
                        data-bs-target={`#createCompanyGroupFormPopup1`}
                        disabled={allowPermissionE ? '' : 'disabled'}
                        onClick={() => editTimeSlotAction(groupObj)}
                    >
                        {AppConstant.commonStrings.edit}
                    </button> */}
                      <CustomButtonSS
                                disabled={false}
                                isLoading={currentDeleteIndex !== 'NA' && currentDeleteIndex === index ? true : false}
                                className={`no-border no-bg`}
                                onClick={() => {deleteHandler(groupObj,index)}}
                                isIcon={true}
                                iconClass={"fa-regular fa-trash-can fa-lg"}
                            />
                    <CustomButtonSS
                        isLoading={currentItemIndex !== 'NA' && currentItemIndex === index ? true : false}
                        title={`${!groupObj.DoorStatus ? AppConstant.commonStrings.enable : AppConstant.commonStrings.disable}`}
                        className={`${!groupObj.DoorStatus ? ' btnEnable-ss ' : 'btnDisable-ss'} mx-2`}
                        onClick={() => disableTimeslotAction(groupObj, index)}
                    />
                </span>
            </div>
        )
    }

    const formatListDataDrillDown = (data) => {
        return data.map((el, index) => {
            return {
                id: el.RoleID,
                0: index + 1,
                1: el.Name,
                2: el.TimeSlotName || '-',
                // 3: el.DisplayOrderNo || '-',
                3: `${moment(el.CreatedDate).format(AppConstant.commonStrings.MM_DD_YYYY_dateFormat)}\n${moment(el.CreatedDate).format(AppConstant.commonStrings.HH_mm_dateFormat)}` || '-',
                4: el.DoorStatus?'Enabled':'Disabled',
                5: actionsButton(el, index),
            }
        })
    }

    return (
        <>
        {metaTimeslotArr &&
        <DrilldownTableSS
            themeSelected={themeSelected}
            bodyData={metaTimeslotArr.length && formatListDataDrillDown(metaTimeslotArr)}
            titleData={dockTimeslotsTitleDD}
            showCollapse={false}
            showInput={false}
            showSort={true}
            arrangeOrder={arrangeOrder}
            arrangeKey={arrangeKey}
            drillDownReport={metaTimeslotArr}
            initiateDrillDownReport={isListLoading}
            loaderArray={6}
            sortingAction={sagaActions.SORT_WH_META_TABLE_ACTION}
            subRoot={{ pathName: 'whMetaTimeslotArr' }}
            showUTCTime={false}
        />}
        {showDeleteConfirmation()}
        </>
    )

}

export default TimeslotMetaListSS;