import React, { useEffect, useState } from 'react'
import { AppConstant } from '../../../Assests/AppConstant'
import "./TextAreaWithTitleSS.css"
/**
 * This is a reusbale component to render textarea element with new UI
 * @param {*} props 
 * title = title for input element
 * placeHolder = placeholder text
 * showError = show/hide error variable
 * errorMessage = variable to display error message
 * value = value for HTML input element
 * name = required by HTML input element
 * required = if element is required
 * @returns 
 */
const TextAreaWithTtileSS = (props) => {
    const { title, placeHolder, showError, errorMessage, value, name, required, customClass, titleIcon, titleIconClass } = props
    const [error, seterror] = useState(showError)
    /**
     * useEffect for error
     */
    useEffect(() => {
        seterror(showError)
    }, [showError])
    /**
     * function for input class if error
     */
    const getInputClass = () => error ? "customTextArea-ss customTextAreaError" : "customTextArea-ss"

    return (
        <div className="d-flex flex-column">
            <span data-testid={AppConstant.dataTestId.customInputTitle}
                className='btn_title'>
                {titleIcon &&
                    <i className={titleIconClass} data-testid={AppConstant.dataTestId.titleIcon}></i>
                }
                <span className='ms-1'>{title}</span>&nbsp;{required && <span className='color-red'>*</span>}
            </span>
            <textarea
                cols={6}
                rows={5}
                data-testid={AppConstant.dataTestId.customInput}
                value={value}
                name={name}
                className={getInputClass()}
                placeholder={placeHolder}
                onBlur={(data) => props.handleErrors(data.target.name)}
                onChange={(data) => props.onEmailChange(data.target.value, data.target.name)}
            />
            {
                error &&
                <span className='errorMessage' data-testid={AppConstant.dataTestId.validationError}>
                    {errorMessage ? errorMessage : error}
                </span>
            }

        </div>
    )
}

export default TextAreaWithTtileSS
