import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getFileUploadReducer } from '../../../../../redux/reducers/reducerSlices/FileuploadReducer';
import { getUserSelector } from '../../../../../redux/reducers/reducerSlices/UserReducer';
import { sagaActions } from '../../../../../sagas/sagaActions';
import { AppConstant } from '../../../../Assests/AppConstant';
import DefaultProfilePic from '../../../../Assests/Images/defaultProfilePic.png'
import { showAlert } from '../../../../Assests/Utility';
import './UserInformation.css'
/**
 * This component render user information
 * First name/Last Name/Location/Role/Email/Contact No.
 * @param {*} props 
 * @returns 
 */
const UserInformation = (props) => {
    const { themeSelected } = props;
    const dispatch = useDispatch()
    const user = useSelector(getUserSelector);
    const fileUploadData = useSelector(getFileUploadReducer);
    const {userAccountDetails, cu_id, userType} = user
    const { FirstName, WarehouseName, RoleName, Email, Phone, ProfilePicture } = userAccountDetails;
    const [imageUploading, setImageUploading] = useState({ loading: false, name: "" });
  /**
   * useEffect for file upload
   */
    useEffect(() => {
        if (fileUploadData?.data) {
          setImageUploading({ loading: false, name: "" });
          if (fileUploadData.data.name) {
            dispatch({type: sagaActions.GET_USER_ACCOUNT_DETAILS, payload: {
                cu_id: cu_id,
                crud_type: 'U',
                profile_picture: fileUploadData.data.fileUrl
            }})
            dispatch({ type: sagaActions.RESET_FILE_UPLOAD });
          }
        } else {
          if (fileUploadData?.error) {
            setImageUploading({ loading: false, name: "" });
            showAlert(toast.TYPE.ERROR, fileUploadData.error.message);
          }
        }
    
      }, [fileUploadData]);
      /**
       * file upload handler function
       * @param {*} fileData 
       * @param {*} name 
       */
      const onFileChange = async (fileData, name) => {
        setImageUploading({ loading: true, name: name });
        const fd = new FormData();
        fd.append("file", fileData);
        let fileUploadData = {
          name,
          fd
        }
        dispatch({ type: sagaActions.FILE_UPLOAD, payload: fileUploadData });
    
      };
    return (
        <div className="d-flex flex-column">
            <div>
                <div className='img-wrapper d-flex align-items-center'>
                    <div className="profile-pic">
                        <label className="-label subText" htmlFor="file">
                            <span><i className="fa fa-camera" aria-hidden="true"></i></span>
                            <span>{AppConstant.settings.userAccountTitle.changeProfilePic}</span>
                        </label>
                        <input id="file" type="file" name="pic" accept={`.jpg,.png,.jpeg`} onInput={(data) => onFileChange(data.target.files[0], data.target.name)} />
                        <img src={ProfilePicture ? ProfilePicture : DefaultProfilePic} id="output" width="200" />
                    </div>        
                    <span className='ms-4'>{FirstName || '-'}</span>
                </div>
                
            </div>
            <div className='d-flex justify-content-start align-items-center mx-4'>
            <i className="fa fa-light fa-warehouse"></i>
                <div className='mx-4 my-2'>
                <div className='subText'>{AppConstant.settings.userAccountTitle.location}</div>
                <div className='bolderText'>{userType === AppConstant.userPermission.usertypes.user ?  WarehouseName ? WarehouseName.slice(2) : '-': WarehouseName}</div>
                </div>
            </div>
            <div className='d-flex justify-content-start align-items-center mx-4'>
                <i className="fa fa-solid fa-user"></i>
                <div className='mx-4 my-2'>
                <div className='subText'>{AppConstant.settings.userAccountTitle.role}</div>
                <div className='bolderText'>{userType === AppConstant.userPermission.usertypes.user ? RoleName ? RoleName.slice(2) : '-': RoleName}</div>
                </div>
            </div>
            <div className='d-flex justify-content-start align-items-center mx-4'>
                <i className="fa-solid fa-envelope"></i>
                <div className='mx-4 my-2'>
                <div className='subText'>{AppConstant.settings.userAccountTitle.email}</div>
                <div className='bolderText'>{Email  || '-'}</div>
                </div>
            </div>
            <div className='d-flex justify-content-start align-items-center mx-4'>
                <i className="fa fa-light fa-phone"></i>
                <div className='mx-4 my-2'>
                <div className='subText'>{AppConstant.settings.userAccountTitle.phone}</div>
                <div className='bolderText'>{Phone  || '-'}</div>
                </div>
            </div>
            
        </div>
    );
}

export default UserInformation;