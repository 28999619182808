import _ from 'lodash';

import moment from 'moment';

import React from 'react'

import { AppConstant } from '../../../../../../../Assests/AppConstant';



const RecommendedTimeslotSS = (props) => {

    const { selectedDate, ssRecommendationsArr, loading, errorMsg, handleTimeSlotClick, timeSlot } = props;

    return (

        <>
            <p className='p-2'>{selectedDate ? `${moment(selectedDate).tz(moment.tz.guess()).format('MMM')}, ${moment(selectedDate).tz(moment.tz.guess()).format('DD')}` : '--'}</p>
            <div className='d-flex justify-content-between flex-column gap-2 p-2 slots-wrapper'>

                {

                    _.size(ssRecommendationsArr) ? ssRecommendationsArr.map(el => {

                        return (

                            <div className={timeSlot?.TimeSlotName === el?.TimeSlotName && timeSlot?.ApptStartTime === el?.ApptStartTime ? 'time-slot-ss-selected' : 'time-slot-ss'} onClick={(e) => handleTimeSlotClick(e, el)}> {el.ApptStartTime ? moment(el.ApptStartTime).utc().format(AppConstant.commonStrings.hh_mm_A_dateFormat) : '-'}</div>

                        )

                    }) : 'No data Found'

                }

            </div>

        </>

    );

}



export default RecommendedTimeslotSS;