import { Uchain } from "../redux/api/agent";
import { call, put, takeEvery } from 'redux-saga/effects';
import { sagaActions } from './sagaActions';
import { getWhMetaDockList, getWhMetaLeavesList,
  updateOperationObj, getWhMetaBreaksList,
  getWhMetaCapacityList, getWhMetaTimeslotList, updateTableData, updatePreCheckList, updateMetaLoading, updatePreCheckObj,dockConflictDetailsReducer, updateIsLoading } from '../redux/reducers/reducerSlices/WhMetaDataReducerSlice';
import { showAlert } from "../App/Assests/Utility";
import { toast } from 'react-toastify';
import { AppConstant } from "../App/Assests/AppConstant";


function* dockOperationHandler(data){
  let tempStatusObj = { ...data.operationObj }
  const response = yield call(Uchain.getWhMetaDockListApi, data.payload);
  if (tempStatusObj.type === 'Fetch Dock' && response) {
    tempStatusObj.status = true
    yield put(getWhMetaDockList(response));
    yield put(updateOperationObj(tempStatusObj));
  } else if (response.RESULT && response.RESULT === 'Successfully Inserted' || response.RESULT === 'Successfully Updated') {
    tempStatusObj.status = true
    yield put(updateOperationObj(tempStatusObj));
  } else {
    tempStatusObj.status = false
    yield put(updateOperationObj(tempStatusObj));
  }
}

function* docksListRequestHandlerSaga(data) {
  try {
    const tempStatusObj = { ...data.operationObj }
    if ((tempStatusObj.type === "Edit Dock" || tempStatusObj.type==="Update status"|| tempStatusObj.type==="Delete Dock")) {
      let payload={...data.payload};
      payload.Type="Dock";
      payload.DockStatus=tempStatusObj.type==="Delete Dock"?false:data.payload.DockStatus
       let preCheck = yield call(Uchain.superAdminUpdatePreCheck,payload);
      if (preCheck.length>0) {
        yield put(updatePreCheckList(preCheck))
        yield put(updatePreCheckObj(payload))
      }
      else{
        yield dockOperationHandler(data)
      }
    }
    else{
      yield dockOperationHandler(data);
    }
   
  } catch (error) {
    const tempStatusObj = { ...data.operationObj }
    tempStatusObj.status = false
    yield put(updateOperationObj(tempStatusObj));
  }
}

function* leavesListRequestHandler(data){
  const tempStatusObj = { ...data.operationObj }
  const response = yield call(Uchain.getWhMetaLeavesListApi, data.payload);
  if (tempStatusObj.type === 'Fetch Leaves' && response && response.length ) {
    tempStatusObj.status = true
    yield put(getWhMetaLeavesList(response));
    yield put(updateOperationObj(tempStatusObj));
  } else if (response[0].RESULT && response[0].RESULT === 'Successfully Inserted' || response[0].RESULT === 'Successfully Updated' || response[0].RESULT === 'Successfully Deleted') {
    tempStatusObj.status = true
    yield put(updateOperationObj(tempStatusObj));
  } else {
    tempStatusObj.status = false
    yield put(updateOperationObj(tempStatusObj));
  }
}

function* leavesListRequestHandlerSaga(data) {
  try {
    const tempStatusObj = { ...data.operationObj }
    if (tempStatusObj.type === "Edit Leave" || tempStatusObj.type==="Update status" || tempStatusObj.type==="Add Leave"|| tempStatusObj.type==="Delete Leaves") {
      let payload={};
      payload.Type="Leave";
      payload.ID=data.payload.IDWarehouse;
      payload.IDWarehouse=data.payload.IDWarehouse;
      payload.LeaveStartDate=data.payload.LeaveStart.split(" ")[0];
      payload.LeaveEndDate=data.payload.LeaveEnd.split(" ")[0];
      payload.DockStatus=data.payload.Status;
      let preCheck = yield call(Uchain.superAdminUpdatePreCheck,payload);
      if (preCheck.length===0 || preCheck[0].RESULT===0) {
        yield leavesListRequestHandler(data)
      }
      else{
        yield put(updatePreCheckList(preCheck))
        yield put(updatePreCheckObj(payload))
      }
    }
    else{
      yield leavesListRequestHandler(data);
    }
  } catch (error) {
    const tempStatusObj = { ...data.operationObj }
    tempStatusObj.status = false
    yield put(updateOperationObj(tempStatusObj));
  }
}

function* leavesDocksListRequestHandler(data){
  const tempStatusObj = { ...data.operationObj }
    const response = yield call(Uchain.leavesForDocksApi, data.payload);
    if (tempStatusObj.type === 'Fetch Leaves' && response && response.length ) {
      tempStatusObj.status = true
      yield put(getWhMetaLeavesList(response));
      yield put(updateOperationObj(tempStatusObj));
    } else if (response[0].RESULT && response[0].RESULT === 'Successfully Inserted' || response[0].RESULT === 'Successfully Updated') {
      tempStatusObj.status = true
      yield put(updateOperationObj(tempStatusObj));
    } else {
      tempStatusObj.status = false
      yield put(updateOperationObj(tempStatusObj));
    }
}

function* leavesDocksListRequestHandlerSaga(data) {
  try {
    const tempStatusObj = { ...data.operationObj }
    if ((tempStatusObj.type === "Edit Leave" || tempStatusObj.type==="Update status" || tempStatusObj.type==="Add Leave") && !tempStatusObj.confirm) {
      let payload={};
      payload.Type="Leave";
      payload.ID=data.payload.IDDock;
      payload.IDWarehouse=data.payload.IDWarehouse;
      payload.LeaveStartDate=data.payload.LeaveStart.split(" ")[0];
      payload.LeaveEndDate=data.payload.LeaveEnd.split(" ")[0];
      payload.DockStatus=data.payload.Status;
      let preCheck = yield call(Uchain.superAdminUpdatePreCheck,payload);
      if (preCheck.length===0 || preCheck[0].RESULT===0) {
        yield leavesDocksListRequestHandler(data)
      }
      // if (preCheck.length>0) {
        
      // }
      else{
        yield put(updatePreCheckList(preCheck))
        yield put(updatePreCheckObj(payload))
      }
    }
    else{
      yield leavesDocksListRequestHandler(data);
    }
  } catch (error) {
    const tempStatusObj = { ...data.operationObj }
    tempStatusObj.status = false
    yield put(updateOperationObj(tempStatusObj));
  }
}

function* breaksListRequestHandler(data){
  const tempStatusObj = { ...data.operationObj }
  const response = yield call(Uchain.getWhMetaBreaksListApi, data.payload);
  if (tempStatusObj.type === 'Fetch Breaks' && response && response.length) {
    tempStatusObj.status = true
    yield put(getWhMetaBreaksList(response));
    yield put(updateOperationObj(tempStatusObj));
  } else if (response[0].RESULT && response[0].RESULT === 'Successfully Inserted' || response[0].RESULT === 'Successfully Updated' || response[0].RESULT === 'Successfully Deleted') {
    tempStatusObj.status = true
    yield put(updateOperationObj(tempStatusObj));
  } else {
    tempStatusObj.status = false
    yield put(updateOperationObj(tempStatusObj));
  }
}

function* breaksListRequestHandlerSaga(data) {
  try{
  const tempStatusObj = { ...data.operationObj }
  if ((tempStatusObj.type === "Edit Break" || tempStatusObj.type==="Update status" || tempStatusObj.type === "Add Break" || tempStatusObj.type === "Delete Breaks")) {
    let payload={};
    payload.Type="Break";
    payload.ID=data.payload.IDWarehouse;
    payload.IDWarehouse=data.payload.IDWarehouse;
    payload.BreakStartTime=data.payload.BreakStart;
    payload.BreakEndTime=data.payload.BreakEnd;
    payload.DockStatus=data.payload.Status;
    let preCheck = yield call(Uchain.superAdminUpdatePreCheck,payload);
    if (preCheck.length===0 || preCheck[0].RESULT===0) {
      yield breaksListRequestHandler(data)
    }
    else{
      yield put(updatePreCheckList(preCheck))
      yield put(updatePreCheckObj(payload))
    }
  }
  else{
    yield breaksListRequestHandler(data);
  }
  } catch (error) {
    const tempStatusObj = { ...data.operationObj }
    tempStatusObj.status = false
    yield put(updateOperationObj(tempStatusObj));
  }
}

function* breaksDocksListRequestHandler(data){
  const tempStatusObj = { ...data.operationObj }
  const response = yield call(Uchain.breaksForDocksApi, data.payload);
  if (tempStatusObj.type === 'Fetch Breaks' && response && response.length) {
    tempStatusObj.status = true
    yield put(getWhMetaBreaksList(response));
    yield put(updateOperationObj(tempStatusObj));
  } else if (response[0].RESULT && response[0].RESULT === 'Successfully Inserted' || response[0].RESULT === 'Successfully Updated') {
    tempStatusObj.status = true
    yield put(updateOperationObj(tempStatusObj));
  } else {
    tempStatusObj.status = false
    yield put(updateOperationObj(tempStatusObj));
  }
}

function* breaksDocksListRequestHandlerSaga(data) {
  try {
    const tempStatusObj = { ...data.operationObj }
    if ((tempStatusObj.type === "Edit Break" || tempStatusObj.type==="Update status" || tempStatusObj.type === "Add Break") && !tempStatusObj.confirm) {
      let payload={};
      payload.Type="Break";
      payload.ID=data.payload.IDDock;
      payload.IDWarehouse=data.payload.IDWarehouse;
      payload.BreakStartTime=data.payload.BreakStart;
      payload.BreakEndTime=data.payload.BreakEnd;
      payload.DockStatus=data.payload.Status;
      let preCheck = yield call(Uchain.superAdminUpdatePreCheck,payload);
      if (preCheck.length===0 || preCheck[0].RESULT===0) {
        yield breaksDocksListRequestHandler(data)
      }
      else{
        yield put(updatePreCheckList(preCheck))
        yield put(updatePreCheckObj(payload))
      }
    }
    else{
      yield breaksDocksListRequestHandler(data);
    }
  } catch (error) {
    const tempStatusObj = { ...data.operationObj }
    tempStatusObj.status = false
    yield put(updateOperationObj(tempStatusObj));
  }
}

function* whCapacityRequestHandlerSaga(data) {
  try {
    const tempStatusObj = { ...data.operationObj }
    const response = yield call(Uchain.getWhMetaWhCapacityApi, data.payload);
    if (tempStatusObj.type === 'Fetch Wh Capacity' && response && response.length) {
      tempStatusObj.status = true
      yield put(getWhMetaCapacityList(response));
      yield put(updateOperationObj(tempStatusObj));
    } else if (response[0].RESULT && response[0].RESULT === 'Successfully Inserted' || response[0].RESULT === 'Successfully Updated') {
      tempStatusObj.status = true
      yield put(updateOperationObj(tempStatusObj));
    } else {
      tempStatusObj.status = false
      yield put(updateOperationObj(tempStatusObj));
    }
  } catch (error) {
    const tempStatusObj = { ...data.operationObj }
    tempStatusObj.status = false
    yield put(updateOperationObj(tempStatusObj));
  }
}

function* whTimeslotHandler(data){
  const tempStatusObj = { ...data.operationObj }
  const response = yield call(Uchain.getWhMetaTimeslotApi, data.payload);
  if (tempStatusObj.type === 'Fetch Timeslot' && response && response.length) {
    tempStatusObj.status = true
    yield put(getWhMetaTimeslotList(response));
    yield put(updateOperationObj(tempStatusObj));
  } else if (response.RESULT && response.RESULT === 'Successfully Inserted' || response.RESULT === 'Successfully Updated') {
    tempStatusObj.status = true
    yield put(updateOperationObj(tempStatusObj));
  } else {
    tempStatusObj.status = false
    yield put(updateOperationObj(tempStatusObj));
  }
}

function* whTimeslotHandlerSaga(data) {
  try {
    const tempStatusObj = { ...data.operationObj }
    if ((tempStatusObj.type === "Edit Timeslot" || tempStatusObj.type==="Update status") || tempStatusObj.type==="Delete Timeslot") {
      let payload={};
      payload.Type="TimeSlot";
      payload.ID=data.payload.IDDock;
      payload.IDWarehouse=data.payload.IDWarehouse;
      payload.LeaveStartDate=data.payload.TimeSlotName;
      payload.DockStatus=tempStatusObj.type==="Delete Timeslot"?false:data.payload.DoorStatus
      let preCheck = yield call(Uchain.superAdminUpdatePreCheck,payload);
      if (preCheck.length>0) {
        yield put(updatePreCheckList(preCheck))
        yield put(updatePreCheckObj(payload))
      }
      else{
        yield whTimeslotHandler(data)
      }
    }
    else{
      yield whTimeslotHandler(data);
    }
    
  } catch (error) {
    const tempStatusObj = { ...data.operationObj }
    tempStatusObj.status = false
    yield put(updateOperationObj(tempStatusObj));
  }
}

function* whResetTimeslotHandlerSaga(data) {
  try {
    yield put(getWhMetaTimeslotList([]));
    const tempStatusObj = { ...data.operationObj }
    yield put(updateOperationObj({}));
  } catch (error) {
  }
}

function* resetWHCapacityHandlerSaga() {
  try {
    yield put(getWhMetaCapacityList([]));
  } catch (error) {  }
}

function* sortWhMetaTableSaga(data) {
  try {
    yield put(updateTableData(data.payload))
  } catch (error) {
  }
}

function* resetDocksListRequestHandlerSaga() {
  try {
    yield put(getWhMetaDockList([]));
  } catch (error) {  }
}

function* updatePreCheckListSaga(){
  try{
    yield put(updatePreCheckList([]))
  }catch(error){}
}

function* preCheckForAdminOperationSaga(data){
  let preCheck = yield call(Uchain.superAdminUpdatePreCheck,data.payload);
  if (preCheck.length>0) {
    yield put(updatePreCheckList(preCheck))
    yield put(updatePreCheckObj(data.payload))
  }
  else{
    yield put(updatePreCheckList(preCheck))
    yield put(updatePreCheckObj({}))
  }
}

function* getDockConflictDetailsSaga(data) {
  try {
    yield put(updateIsLoading(true));
    const response = yield call(Uchain.getDockConflictDetails, data.payload);
    if(response){
    yield put(dockConflictDetailsReducer(response));
    } 
} catch (error) {
    showAlert(toast.TYPE.ERROR, AppConstant.notifications.login.wentWrong, 'light')
}
finally{
    yield put(updateIsLoading(false));
}
}

function* resetDockConflictDetailsSaga() {
  try {
      yield put(dockConflictDetailsReducer({}));
  } catch (error) {
  }
}


function* deletePosAndApptsRequestHandler(data){
  try {
  const tempStatusObj = { ...data.operationObj }
    const response = yield call(Uchain.deletePosAndAppointments, data.payload);
    if (tempStatusObj.type === 'Delete Appts' && response ) {
      tempStatusObj.status = true
      yield put(updateOperationObj(tempStatusObj));
    }else if (tempStatusObj.type === 'Delete POs' && response ) {
      tempStatusObj.status = true
      yield put(updateOperationObj(tempStatusObj));
    }else{
      tempStatusObj.status = false
      yield put(updateOperationObj(tempStatusObj));
    }
  } catch (error) {
        const tempStatusObj = {...data.operationObj}
        tempStatusObj.status = false;
        yield put(updateOperationObj(tempStatusObj));
  }
}

function* resetOperationObjectWhSaga(){
  try{
    yield put(updateOperationObj({type: '', status : ''}));
    
} catch {
}
}

export function* docksListRequestHandlerWatcher() {
  yield takeEvery(sagaActions.WAREHOUSE_META_DOCK_LIST_ACTION, docksListRequestHandlerSaga)
}

export function* resetDocksListRequestHandlerWatcher() {
  yield takeEvery(sagaActions.RESET_WAREHOUSE_META_DOCK_LIST_ACTION, resetDocksListRequestHandlerSaga)
}

export function* leavesListRequestHandlerWatcher() {
  yield takeEvery(sagaActions.WAREHOUSE_META_LEAVES_LIST_ACTION, leavesListRequestHandlerSaga)
}

export function* leavesDockListRequestHandlerWatcher() {
  yield takeEvery(sagaActions.WAREHOUSE_META_DOCKS_LEAVES_LIST_ACTION, leavesDocksListRequestHandlerSaga)
}

export function* breaksListRequestHandlerWatcher() {
  yield takeEvery(sagaActions.WAREHOUSE_META_BREAKS_LIST_ACTION, breaksListRequestHandlerSaga)
}

export function* breaksDocksListRequestHandlerWatcher() {
  yield takeEvery(sagaActions.WAREHOUSE_META_DOCKS_BREAKS_LIST_ACTION, breaksDocksListRequestHandlerSaga)
}

export function* whCapacityHandlerWatcher() {
  yield takeEvery(sagaActions.WAREHOUSE_META_CAPACITY_ACTION, whCapacityRequestHandlerSaga)
}

export function* resetWHCapacityHandlerWatcher() {
  yield takeEvery(sagaActions.WAREHOUSE_META_CAPACITY_ACTION, resetWHCapacityHandlerSaga)
}

export function* whTimeslotHandlerWatcher() {
  yield takeEvery(sagaActions.WAREHOUSE_META_TIMESLOT_ACTION, whTimeslotHandlerSaga)
}

export function* whResetTimeslotHandlerWatcher() {
  yield takeEvery(sagaActions.WAREHOUSE_META_RESET_TIMESLOT_ACTION, whResetTimeslotHandlerSaga)
}

export function* sortWhMetaTableWatcher() {
  yield takeEvery(sagaActions.SORT_WH_META_TABLE_ACTION, sortWhMetaTableSaga)
}

export function* updatePreCheckListWatcher() {
  yield takeEvery(sagaActions.UPDATE_PRE_CHECK_LIST, updatePreCheckListSaga)
}

export function* preCheckForAdminOperationWatcher() {
  yield takeEvery(sagaActions.PRE_CHECK_FOR_ADMIN_OPERATION, preCheckForAdminOperationSaga)
}

export function* dockConflictDetailsWatcher() {
  yield takeEvery(sagaActions.GET_DOCK_CONFLICT_DETAILS, getDockConflictDetailsSaga)
}

export function* resetDockConflictDetailsWatcher() {
  yield takeEvery(sagaActions.RESET_DOCK_CONFLICT_DETAILS, resetDockConflictDetailsSaga)
}

export function* whTimeslotDeleteHandlerWatcher() {
  yield takeEvery(sagaActions.DELETE_TIMESLOT_ACTION, whTimeslotHandler)
}

export function* deletePOsAndApptsHandlerWatcher() {
  yield takeEvery(sagaActions.DELETE_POS_AND_APPOINTMENTS, deletePosAndApptsRequestHandler)
}


export function* resetOperationObjectWhWatcher() {
  yield takeEvery(sagaActions.RESET_OPERATION_OBJECT_WH, resetOperationObjectWhSaga)
}
