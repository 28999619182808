import _ from 'lodash';
import { toast } from 'react-toastify';
import { call, put, takeEvery, delay, select } from 'redux-saga/effects';
import { AppConstant } from '../App/Assests/AppConstant';
import { addSerialNumberToList, createCarrierSubUserPayload, createUserPayload, showAlert, updateUserWarehouseListRespone } from '../App/Assests/Utility';
import { Uchain } from '../redux/api/agent';
import {
  updateNavigationBarList, updateUserPermissions, updateUserType, resetUserPermission,
  updateSelectedWarehouse, updateUserWarehouseList, updateUserWarehouseEmptyList, updateInitiateLoader,
  updateUserList, updateUserRoleDetailsList, update_cu_id, update_selectedUser, update_companyList,
  update_companyGroupList, updateAllWarehouseList, updateTableData,
  resetUser, setCompanyList, update_companyFormHandler, update_companyGroupFormHandler, update_parent_warehouse, get_user_account_details, updateError, updateBranding, updateBrandingSuccess, updateBrandingError, updateBrandingLoader, updateResendEmail,
  updateNavigationList,
  updateResetUserPass, createCarrierFromSignUp, clearBranding, resetUserWithoutBranding, updateCarrierSubUserList, updateActionLoader, updateAddCarrierSpro
} from '../redux/reducers/reducerSlices/UserReducer';
import { sagaActions } from './sagaActions';


function* getUserTypeSaga(data) {
  try {
    const response = yield call(Uchain.getUserType, data.payload);
    if (response?.UserType) {
      yield put(updateUserType(response.UserType))
    } else {
      yield put(updateUserType(response.Result))
    }
    yield put(update_cu_id(data.payload?.cu_id))
  } catch (error) {
    showAlert(toast.TYPE.WARNING, AppConstant.notifications.login.wentWrong, 'light')
    yield put(resetUserPermission())
  }
}

function* getUserPermissionSaga(data) {
  try {
    const response = yield call(Uchain.getUserPermission, data.payload);
    yield put(updateUserPermissions(response.sort((a, b) => parseInt(a.OrderNumber) - parseInt(b.OrderNumber))))
  } catch (error) {
    showAlert(toast.TYPE.WARNING, AppConstant.notifications.login.wentWrong, 'light')
    yield put(resetUserPermission())
  }
}

function* updateNavBarSaga(data) {
  try {
    yield put(updateNavigationBarList(data.payload))
  } catch (error) {
  }
}

function* resetUserSaga() {
  try {
    yield put(resetUser())
  } catch (error) {
  }
}
function* resetUserSagaWithoutBranding() {
  try {
    yield put(resetUserWithoutBranding())
  } catch (error) {
  }
}
function* resetUserPermissionSaga() {
  try {
    yield put(resetUserPermission())
  } catch (error) {
  }
}

function* updatUserWarehouseListSaga(data) {
  try {
    const response = yield call(Uchain.getUserAccessWarehouse, data.payload);
    if (response.length) {
      yield put(updateUserWarehouseList(updateUserWarehouseListRespone(response)))
    } else {
      yield put(updateUserWarehouseList(response))
      yield put(updateUserWarehouseEmptyList(true))
    }
    yield put(updateInitiateLoader(false))

  } catch (error) {
    yield put(updateUserWarehouseList([]))
    yield put(updateUserWarehouseEmptyList(true))
    yield put(updateInitiateLoader(false))
  }
}

function* updateSelectedWarehouseSaga(data) {
  try {
    //   const response = yield call(Uchain.getUserType, data.payload);
    yield put(updateSelectedWarehouse(data.payload))
  } catch (error) {
  }
}

function* updateParentWarehouseSaga(data) {
  try {
    //   const response = yield call(Uchain.getUserType, data.payload);
    yield put(update_parent_warehouse(data.payload))

  } catch (error) {
  }
}


function* updateInitiateLoaderSaga(data) {
  try {
    //   const response = yield call(Uchain.getUserType, data.payload);
    yield put(updateInitiateLoader(data.payload))
  } catch (error) {
  }
}

function* updatUserListSaga(data) {
  try {
    const response = yield call(Uchain.updateUser, data.payload);
    const newdata = _.orderBy(response, ['CreatedOn'], ['desc']);
    const tempResponse = addSerialNumberToList(newdata)
    yield put(updateUserList(tempResponse))
    if (data?.payload?.crud_type === "U" || data?.payload?.crud_type === "D") {
      showAlert(toast.TYPE.SUCCESS, 'User updated successfully')
      yield put(update_companyFormHandler(true))
    } else if (data?.payload?.crud_type === "R") {
      showAlert(toast.TYPE.SUCCESS, 'User warehouse access removed successfully')
      yield put(update_companyFormHandler(true))
    }
  } catch (error) {
    yield put(update_companyFormHandler(false))
    if (error?.response?.data) {
      showAlert(toast.TYPE.ERROR, error?.response?.data?.message || error?.response?.data)
      if (data?.payload?.crud_type === "D") {
        const state = yield select();
        const userList = state.userReducer.userList;
        // yield put(updateUserList([]))
        yield put(updateUserList(userList))
      }
    }
  }
}

function* updateUserRoleAssignSaga(data) {
  try {
    const response = yield call(Uchain.getUserWarehouseRoleDetails, data.payload);
    if (response) {
      const newdata = _.orderBy(response, ['CreatedOn'], ['desc']);
      yield put(updateUserRoleDetailsList(newdata))
      if (data.payload.crud_type === 'I') {
        showAlert(toast.TYPE.SUCCESS, 'Role Assignment Successfully')

      } else if (data.payload.crud_type === 'U' || data.payload.crud_type === 'D') {
        showAlert(toast.TYPE.SUCCESS, 'Role Updated Successfully')
      }
    }
  } catch (error) {
    yield put(updateInitiateLoader(false))
    showAlert(toast.TYPE.ERROR, error?.response?.data.split(': ').pop(), 'light')

  }
}

function* resetUserRoleAssignSaga() {
  try {
    yield put(updateUserRoleDetailsList([]))
  } catch (error) {

  }
}

function* updateCompanyGroupSaga(data) {
  try {
    const response = yield call(Uchain.updateCompanyGroup, data.payload);
    const tempResponse = addSerialNumberToList(response)
    yield put(update_companyGroupList(tempResponse))
    yield put(update_companyList([]))
    yield put(updateAllWarehouseList([]))

    yield put(update_companyGroupFormHandler(true))
    if (data.payload.crud_type === 'U') {
      showAlert(toast.TYPE.SUCCESS, AppConstant.superUserAdmin.companyGroup.updatedSuccessResponse, 'light')
    }
  } catch (error) {
    yield put(updateInitiateLoader(false))
    yield put(updateError(true))
    yield put(update_companyGroupFormHandler(false))
    if (error?.code === "ERR_NETWORK") {
      showAlert(toast.TYPE.ERROR, error?.message, 'light')
    } else {

      showAlert(toast.TYPE.ERROR, error?.response?.data.split(': ').pop(), 'light')
    }
  }
}
function* updateCompanySaga(data) {
  try {
    const response = yield call(Uchain.updateCompany, data.payload);
    yield put(update_companyList(response))
    yield put(updateAllWarehouseList([]))
    yield put(update_companyFormHandler(true))
    if (data.payload.crud_type === 'I') {
      showAlert(toast.TYPE.SUCCESS, AppConstant.superUserAdmin.company.createCompanyForm.createdCompanySuccessfully, 'light')
    } else if (data.payload.crud_type === 'U') {
      showAlert(toast.TYPE.SUCCESS, AppConstant.superUserAdmin.companyGroup.updatedSuccessResponse, 'light')
    }
  } catch (error) {
    yield put(updateError(true))
    yield put(updateInitiateLoader(false))
    yield put(update_companyFormHandler(false))
    if (data.payload.crud_type === 'I' || data.payload.crud_type === 'U') {
      showAlert(toast.TYPE.ERROR, error?.response?.data.split(': ').pop(), 'light')
      const response = yield call(Uchain.getCompanyList, {company_group_id: null});
    if (response) {
      const tempResponse = addSerialNumberToList(response)
      yield put(setCompanyList(tempResponse))
    }
    } else {
      showAlert(toast.TYPE.ERROR, AppConstant.superUserAdmin.company.errorMessages.updateFailure, 'light')
    }
  }
}
function* updateSelectedUserSaga(data) {
  try {
    yield put(update_selectedUser(data.payload))
  } catch (error) {
  }
}

function* updateAllWarehouseSaga(data) {
  try {
    const response = yield call(Uchain.getAllWarehouse, data.payload);
    if (response) {
      yield put(updateAllWarehouseList(response))
    }
  } catch (error) {
  }
}

function* sortUserTableSaga(data) {
  try {
    yield put(updateTableData(data.payload))
  } catch (error) {
  }
}

function* updateCompanyListSaga(data) {
  try {
    const response = yield call(Uchain.getCompanyList, data.payload);
    if (response) {
      const tempResponse = addSerialNumberToList(response)
      yield put(setCompanyList(tempResponse))
    }
  } catch (error) {
    yield put(updateInitiateLoader(false))
    showAlert(toast.TYPE.WARNING, error?.response?.data.split(': ').pop(), 'light')
  }
}

function* registerUserSaga(data) {
  try {
    const response = yield call(Uchain.createUser, data.payload)
    if (response?.User?.Username) {
      const state = yield select();
      const userAccountDetailsTemp = state.userReducer.userAccountDetails;
      let isSPuser = false;
      if (userAccountDetailsTemp?.RoleName?.includes('SSADMIN')) {
        isSPuser = true
      }
      const payload = createUserPayload(response, data.payload, isSPuser)
      const response1 = yield call(Uchain.updateUser, payload)
      if (response1) {
        yield put(updateInitiateLoader(true))
        const newdata = _.orderBy(response1, ['CreatedOn'], ['desc']);
        const tempResponse = addSerialNumberToList(newdata)
        yield put(updateUserList(tempResponse))
        yield put(update_companyFormHandler(true))
        showAlert(toast.TYPE.SUCCESS, AppConstant.user.toast.createUserSuccess)
      } else {
        showAlert(toast.TYPE.ERROR, AppConstant.user.toast.createUserFailure)
      }
    } else {
      showAlert(toast.TYPE.ERROR, AppConstant.user.toast.createUserFailure)
    }
  } catch (error) {
    yield put(updateError(true))
    if (error?.response?.data) {
      showAlert(toast.TYPE.ERROR, error?.response?.data?.message ||  error?.response?.data)
    } else {
      showAlert(toast.TYPE.ERROR, AppConstant.user.toast.createUserFailure)
    }
    yield put(update_companyFormHandler(false))
    yield put(updateInitiateLoader(false))
  }
}

function* updateCompanyPopupSaga(data) {
  try {
    yield put(update_companyFormHandler(data.payload))
  } catch (error) {
  }
}

function* updateCompanyGroupPopupSaga(data) {
  try {
    yield put(update_companyGroupFormHandler(data.payload))

  } catch (error) {
  }
}

function* getAllWarehouseSaga() {
  try {
    const response = yield call(Uchain.getAllSuperWarehouse)
    if (response) {
      yield put(updateAllWarehouseList(response))
    }
  } catch (error) {
  }
}

function* updatetUserAccountDetailsSaga(data) {
  try {
    const response = yield call(Uchain.userAccountDetails, data.payload)
    if (data.payload.crud_type === 'S') {
      yield put(get_user_account_details(response))
    } else if (data.payload.crud_type === 'U') {
      showAlert(toast.TYPE.SUCCESS, response.RESULT);
      const response1 = yield call(Uchain.userAccountDetails, {
        cu_id: data.payload.cu_id, crud_type: 'S',
        profile_picture: null
      })
      yield put(get_user_account_details(response1))
    }

  } catch (error) {
    yield put(updateError(true))
  }
}

function* updatetUserAccountDetailsFromRenewSaga(data) {
  try {

    yield put(get_user_account_details(data.payload))

  } catch (error) {
  }
}

function* updateUserErrorSaga(data) {
  try {
    yield put(updateError(data.payload))
  } catch (error) {
  }
}

function* initiateBrandingSaga(data) {
  try {
    const response = yield call(Uchain.branding, data.payload)
    if (_.size(response)) {
      yield put(updateBranding(response[0]))
      yield put(updateBrandingSuccess(true))
    } else {
      yield put(updateBranding({}))
      yield put(updateBrandingSuccess(true))
    }

  } catch (error) {
    yield put(updateBranding(null))
    yield put(updateBrandingError(true))
    yield put(updateBrandingSuccess(false))
  }
}
function* brandingSagaSuccess(data) {
  try {
    yield put(updateBrandingSuccess(data.payload))
  } catch (error) {
  }
}

function* brandingSagaError(data) {
  try {
    yield put(updateBrandingError(data.payload))
  } catch (error) {
  }
}

function* brandingSagaLoader(data) {
  try {
    yield put(updateBrandingLoader(data.payload))
  } catch (error) {
  }
}

function* brandingSagaClear(data) {
  try {
    yield put(clearBranding())
  } catch (error) {
  }
}


function* updateResendEmailSaga(data) {
  try {
    yield put(updateResendEmail(data.payload))
  } catch (error) {
  }
}

function* initiateResendEmailSaga(data) {
  try {
    const response = yield call(Uchain.resendEmail, data.payload)
    yield put(updateResendEmail(true))
    showAlert(toast.TYPE.SUCCESS, response.message, 'light')
  } catch (error) {
    if (error.response.status === 400) {
      showAlert(toast.TYPE.ERROR, error.response.data.message, 'light')
    } else {
      showAlert(toast.TYPE.ERROR, error.message, 'light')
    }
    yield put(updateResendEmail(true))
  }
}

function* updateNavigationSaga() {
  try {
    const response = yield call(Uchain.navigationApi)
    yield put(updateNavigationList(response))
  } catch (error) {
  }
}
function* initiaiteResetuserPassSaga(data) {
  try {
    const response = yield call(Uchain.resetUserPassword, data.payload)
    showAlert(toast.TYPE.SUCCESS, response.message, 'light')
    yield put(updateResetUserPass(true))
  } catch (error) {
    if (error.response.status === 400) {
      showAlert(toast.TYPE.ERROR, error.response.data.message, 'light')
    } else {
      showAlert(toast.TYPE.ERROR, error.message, 'light')
    }
    yield put(updateResetUserPass(true))
  }
}

function* updateResetuserPassSaga(data) {
  try {
    yield put(updateResetUserPass(data.payload))
  } catch (error) {
  }
}

function* updateLastLogonSaga(data) {
  try {
    yield call(Uchain.updateLastLogon, data.payload)
  } catch (error) {
  }
}

function* createCarrierFromSignUpSaga(data) {
  try {
    const response = yield call(Uchain.hitAddCarrierApi, data.payload);
    yield put(createCarrierFromSignUp(response))
  } catch (error) {
    yield put(createCarrierFromSignUp(error))
  }
}



function* registerCarrierSubUserSaga(data) {
  try {
    yield put(updateActionLoader(true))
    const response = yield call(Uchain.createUser, data.payload)
    if (response?.User?.Username) {
      const payload = createCarrierSubUserPayload(response, data.payload, 'I')
      const response1 = yield call(Uchain.updateCarrierSubUser, payload)
      if (response1[0]?.RESULT) {
        yield put(updateActionLoader(false))
        showAlert(toast.TYPE.ERROR, response1?.RESULT)
      }
      else {
        showAlert(toast.TYPE.SUCCESS, AppConstant.user.toast.createUserSuccess)
        yield put(updateActionLoader(false))
        yield put(updateCarrierSubUserList(response1))
      }
    } else {
      yield put(updateActionLoader(false))
      showAlert(toast.TYPE.ERROR, AppConstant.user.toast.createUserFailure)
    }
    yield put(updateActionLoader(false))
  } catch (error) {
    yield put(updateActionLoader(false))
    // yield put(updateError(true))
    if (error?.response?.data?.code) {
      showAlert(toast.TYPE.ERROR, error?.response?.data.message)
    } else {
      showAlert(toast.TYPE.ERROR, AppConstant.user.toast.createUserFailure)
    }
    // yield put(update_companyFormHandler(false))
  }
}

function* updatCarrierSubUserListSaga(data) {
  try {
    yield put(updateActionLoader(true))
    const response = yield call(Uchain.updateCarrierSubUser, data.payload);
    yield put(updateCarrierSubUserList(response))
    if (response[0]?.RESULT || data?.payload?.CRUDTYPE === "U") {
      showAlert(toast.TYPE.SUCCESS, 'User updated successfully')
    }
    yield put(updateActionLoader(false))
  } catch (error) {
    showAlert(toast.TYPE.ERROR, 'Something went wrong')
    yield put(updateActionLoader(false))
  }
}

function* resetCarrierSignUpTempDataSaga() {
  yield put(createCarrierFromSignUp({}))
}
function* addCarrierSproSaga(data) {
  const { type, name } = data.payload;
  if (type === 'A') {
    const response = yield call(Uchain.addCarrierSpro, data.payload)
    if (response) {
      yield put(updateAddCarrierSpro({
        carrier_name: name,
        carrier_id: response?.result
      }))
    }
  }else{
    yield put(updateAddCarrierSpro(null))
  }
}

export function* getUserTypeWatcher() {
  yield takeEvery(sagaActions.GET_USER_TYPE, getUserTypeSaga)
}
export function* getUserPermissionWatcher() {
  yield takeEvery(sagaActions.GET_USER_PERMISSIONS, getUserPermissionSaga)
}
export function* updateNavBarWatcher() {
  yield takeEvery(sagaActions.UPDATE_NAV_BAR, updateNavBarSaga)
}
export function* resetUserWatcher() {
  yield takeEvery(sagaActions.RESET_USER, resetUserSaga)
}
export function* resetUserWatcherWithoutBranding() {
  yield takeEvery(sagaActions.RESET_USER_WITHOUT_BRANDING, resetUserSagaWithoutBranding)
}

export function* resetUserPermissionWatcher() {
  yield takeEvery(sagaActions.RESET_USER_PERMISSIONS, resetUserPermissionSaga)
}
export function* updatUserWarehouseListWatcher() {
  yield takeEvery(sagaActions.UPDATE_WAREHOUSE_LIST, updatUserWarehouseListSaga)
}
export function* updateSelectedWarehouseWatcher() {
  yield takeEvery(sagaActions.UPDATE_SELECTED_WAREHOUSE, updateSelectedWarehouseSaga)
}
export function* updateInitiateLoaderWatcher() {
  yield takeEvery(sagaActions.INITIATE_LOADING, updateInitiateLoaderSaga)
}
export function* updatUserListWatcher() {
  yield takeEvery(sagaActions.UPDATE_USER_LIST, updatUserListSaga)
}
export function* updateUserRoleAssignWatcher() {
  yield takeEvery(sagaActions.UPDATE_USER_ROLE_ASSIGN, updateUserRoleAssignSaga)
}
export function* updateCompanyGroupWatcher() {
  yield takeEvery(sagaActions.UPDATE_COMPANY_GROUP, updateCompanyGroupSaga)
}
export function* updateCompanyWatcher() {
  yield takeEvery(sagaActions.UPDATE_COMPANY, updateCompanySaga)
}
export function* updateSelectedUserWatcher() {
  yield takeEvery(sagaActions.UPDATE_SELECTED_USER, updateSelectedUserSaga)
}
export function* updateAllWarehouseWatcher() {
  yield takeEvery(sagaActions.UPDATE_ALL_WAREHOUSE, updateAllWarehouseSaga)
}
export function* sortUserTableWatcher() {
  yield takeEvery(sagaActions.SORT_USER_TABLE, sortUserTableSaga)
}
export function* registerUserWatcher() {
  yield takeEvery(sagaActions.REGISTER_USER, registerUserSaga)
}
export function* resetUserRoleAssignWatcher() {
  yield takeEvery(sagaActions.RESET_USER_ROLE_ASSIGN, resetUserRoleAssignSaga)
}
export function* getCompanyListWatcher() {
  yield takeEvery(sagaActions.GET_COMPANY_LIST, updateCompanyListSaga)
}
export function* updateCompanyPopupWatcher() {
  yield takeEvery(sagaActions.UPDATE_COMPANY_FORM_POPUP, updateCompanyPopupSaga)
}
export function* updateCompanyGroupPopupWatcher() {
  yield takeEvery(sagaActions.UPDATE_COMPANY_GROUP_FORM_POPUP, updateCompanyGroupPopupSaga)
}
export function* updateParentWarehouseWatcher() {
  yield takeEvery(sagaActions.PARENT_WAREHOUSE, updateParentWarehouseSaga)
}
export function* getAllWarehouseWatcher() {
  yield takeEvery(sagaActions.GET_ALL_WAREHOUSE, getAllWarehouseSaga)
}
export function* updateUserAccountDetailsWatcher() {
  yield takeEvery(sagaActions.GET_USER_ACCOUNT_DETAILS, updatetUserAccountDetailsSaga)
}
export function* updateUserAccountRenewDetailsWatcher() {
  yield takeEvery(sagaActions.UPDATE_USER_ACCOUNT_DETAILS_RENEW, updatetUserAccountDetailsFromRenewSaga)
}
export function* updateUserErrorWatcher() {
  yield takeEvery(sagaActions.UPDATE_USER_ERROR, updateUserErrorSaga)
}
export function* initiateBrandingWatcher() {
  yield takeEvery(sagaActions.INITIATE_BRANDING, initiateBrandingSaga)
}
export function* brandingSuccessWatcher() {
  yield takeEvery(sagaActions.INITIATE_BRANDING_SUCCESS, brandingSagaSuccess)
}
export function* brandingErrorWatcher() {
  yield takeEvery(sagaActions.INITIATE_BRANDING_ERROR, brandingSagaError)
}
export function* brandingLoaderWatcher() {
  yield takeEvery(sagaActions.INITIATE_BRANDING_LOADER, brandingSagaLoader)
}
export function* brandingClearWatcher() {
  yield takeEvery(sagaActions.CLEAR_BRANING, brandingSagaClear)
}
export function* initiateResendEmailWatcher() {
  yield takeEvery(sagaActions.INITIATE_RESEND_EMAIL, initiateResendEmailSaga)
}
export function* updateResendEmailWatcher() {
  yield takeEvery(sagaActions.UPDATE_RESEND_EMAIL, updateResendEmailSaga)
}
export function* updateNavigationApiWatcher() {
  yield takeEvery(sagaActions.UPDATE_NAVIGATION_API, updateNavigationSaga)
}
export function* initiaiteResetuserPassWatcher() {
  yield takeEvery(sagaActions.INITIATE_RESET_USER_PASSWORD, initiaiteResetuserPassSaga)
}
export function* updateResetuserPassWatcher() {
  yield takeEvery(sagaActions.UPDATE_RESET_USER_PASSWORD, updateResetuserPassSaga)
}
export function* updateLastLogonWatcher() {
  yield takeEvery(sagaActions.UPDATE_LAST_LOGON, updateLastLogonSaga)
}

export function* createCarrierFromSignUpWatcher() {
  yield takeEvery(sagaActions.CREATE_CARRIER_FROM_SIGNUP, createCarrierFromSignUpSaga)
}

export function* resetCarrierSignUpTempDataWatcher() {
  yield takeEvery(sagaActions.RESET_CARRIER_FROM_SIGNUP_TEMP_DATA, resetCarrierSignUpTempDataSaga)
}

export function* registerCarrierSubUserSagaWatcher() {
  yield takeEvery(sagaActions.REGISTER_CARRIER_SUB_USER, registerCarrierSubUserSaga)
}
export function* updateCarrierSubUserSagaWatcher() {
  yield takeEvery(sagaActions.UPDATE_CARRIER_SUB_USER, updatCarrierSubUserListSaga)
}
export function* addCarrierSproWatcher() {
  yield takeEvery(sagaActions.ADD_CARRIER_SCHEDULE_PRO, addCarrierSproSaga)
}