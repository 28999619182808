import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sagaActions } from '../../../../../../sagas/sagaActions';
import { getTruckSelector } from '../../../../../../redux/reducers/reducerSlices/getTruckReducer';
import { getPOListFilterSelector, getSelectedFiltersSelector } from '../../../../../../redux/reducers/reducerSlices/FiltersReducerSlice';
import { getDefaultDashboardSelector } from '../../../../../../redux/reducers/reducerSlices/DefaultDashboardReducer';
import TableFilterButtons from './Components/TableFilterButtons';
import { applyFilters } from '../FilterUtilities'
import { AppConstant } from '../../../../../Assests/AppConstant';
import TaskItemsDueLabel from './Components/TaskItemsDueLabel/TaskItemsDueLabel';
import { getSelectedDateSelector } from '../../../../../../redux/reducers/reducerSlices/DateSelectorReducer'
import './POListFilters.css';
import { getCurrentDate, changeDateToGivenFormat } from '../../../../../../Utils/DateUtils';

const POListFilters = (props) => {
    const dispatch = useDispatch();
    const getTruck = useSelector(getTruckSelector);
    const { truckList, truckListMasterData, autoFetchPolist } = getTruck;
    const getSelectedFilter = useSelector(getPOListFilterSelector);
    const selectedFiltersList = useSelector(getSelectedFiltersSelector);
    const getSelectedDateSelectorObj = useSelector(getSelectedDateSelector);
    const defaultDashboard = useSelector(getDefaultDashboardSelector);
    const { selectedTaskItem, dateRangeObj } = defaultDashboard;
    const { themeSelected } = props;
    const [allTruckList, setAllTruckList] = useState([]);
    const [scheduledList, setScheduledList] = useState([]);
    const [unscheduledList, setUnscheduledList] = useState([]);
    const appConstantPath = AppConstant.poManagement.poFiltersSection;
    
    useEffect(( ) => {
        if(changeDateToGivenFormat(dateRangeObj.start, 'MM-DD-YYYY') !== changeDateToGivenFormat(getCurrentDate(), 'MM-DD-YYYY')){
            removeTaskItem()
        }
    }, [dateRangeObj])

    useEffect(() => {
        createFilterList();
    }, [selectedFiltersList, getSelectedDateSelectorObj, truckListMasterData])

    useEffect(() => {
        dispatch({ type: sagaActions.SET_PO_LIST_FILTERS, payload: appConstantPath.poListAllTitleString });
    }, [getSelectedDateSelectorObj])

    useEffect(() => {
        if (getSelectedFilter !== appConstantPath.poListAllTitleString) {
            setPOListFilterData(getSelectedFilter);
        }
    }, [unscheduledList, scheduledList])

    const createUnscheduledList = (currentSelectedFiltersList) => {
        return currentSelectedFiltersList.filter((truck) => {
            if (truck.unscheduled_pos.length > 0 && truck.scheduled_pos.length === 0) {
                return truck;
            } else if (truck.unscheduled_pos.length > 0 && truck.scheduled_pos.length > 0) {
                //NOTE : If both scheds and unsceds are present we have added it to unscheduleds only
                return truck
            }
        });
    }

    const createScheduledList = (currentSelectedFiltersList) => {
        return currentSelectedFiltersList.filter((truck) => {
            if (truck.scheduled_pos.length > 0 && truck.unscheduled_pos.length === 0) {
                return truck;
            }
        });
    }


    const createFilterList = () => {

        const filterList = applyFilters(selectedFiltersList, truckList);

        const currentSelectedFiltersList = (selectedFiltersList.length === 0) ? truckListMasterData : filterList;

        setAllTruckList(currentSelectedFiltersList);

        setUnscheduledList([...new Set(createUnscheduledList(currentSelectedFiltersList))]);

        setScheduledList([...new Set(createScheduledList(currentSelectedFiltersList))]);

    }

    const setPOListFilter = (filter) => {
        const selectedFilter = filter;

        const [str1] = selectedFilter.split(' ');

        switch (str1) {
            case appConstantPath.poListAllTitleString:
                selectAllTrucks();
                break;

            case appConstantPath.poListScheduledTitleString:
                !autoFetchPolist && dispatch({ type: sagaActions.RESET_LOAD_TRUCK_DETAILS })
                dispatch({ type: sagaActions.SET_PO_LIST_FILTERS, payload: appConstantPath.poListScheduledTitleString });
                dispatch({ type: sagaActions.UPDATE_TRUCK, payload: scheduledList })
                break;

            case appConstantPath.poListUnscheduledTitleString:
                !autoFetchPolist && dispatch({ type: sagaActions.RESET_LOAD_TRUCK_DETAILS })
                dispatch({ type: sagaActions.SET_PO_LIST_FILTERS, payload: appConstantPath.poListUnscheduledTitleString });
                dispatch({ type: sagaActions.UPDATE_TRUCK, payload: unscheduledList })
                break;

            default:
        }

    }

    const setPOListFilterData = (filter) => {
        const selectedFilter = filter;

        const [str1] = selectedFilter.split(' ');

        switch (str1) {
            case appConstantPath.poListAllTitleString:
                selectAllTrucks();
                break;

            case appConstantPath.poListScheduledTitleString:
                // !autoFetchPolist && dispatch({ type: sagaActions.RESET_LOAD_TRUCK_DETAILS })
                dispatch({ type: sagaActions.SET_PO_LIST_FILTERS, payload: appConstantPath.poListScheduledTitleString });
                // dispatch({ type: sagaActions.AUTO_FETCH_PO_LIST, payload: true })
                dispatch({ type: sagaActions.UPDATE_TRUCK, payload: scheduledList })
                break;

            case appConstantPath.poListUnscheduledTitleString:
                // !autoFetchPolist && dispatch({ type: sagaActions.RESET_LOAD_TRUCK_DETAILS })
                dispatch({ type: sagaActions.SET_PO_LIST_FILTERS, payload: appConstantPath.poListUnscheduledTitleString });
                // dispatch({ type: sagaActions.AUTO_FETCH_PO_LIST, payload: true })
                dispatch({ type: sagaActions.UPDATE_TRUCK, payload: unscheduledList })
                break;

            default:
        }

    }

    const selectAllTrucks = () => {
        !autoFetchPolist && dispatch({ type: sagaActions.RESET_LOAD_TRUCK_DETAILS })
        dispatch({ type: sagaActions.SET_PO_LIST_FILTERS, payload: appConstantPath.poListAllTitleString });
        dispatch({ type: sagaActions.UPDATE_TRUCK, payload: allTruckList });
    }

    const setTaskItemHeading = () => {

        switch (selectedTaskItem) {
            case 'UnscheduledPO':
                return 'POs Overdue';
            case 'In_Staging':
                return 'Staging';
            case 'AwaitingPaperwork':
                return 'Awaiting Paperwork';
            case 'in_detention':
                return 'In Detention';
            case 'approaching_detention':
                return 'Nearing Detention';
            default:
                return ''
        }
    }

    const removeTaskItem = () => {
        dispatch({ type: sagaActions.SET_TASK_ITEM, payload: null });
    }

    return (
        <div className='mb-3 p-0 pOListFilters'>


            <div
                className=' container-fluid d-flex flex-row 
                justify-content-start m-0 p-0 pOListFilters w-100'>

                <TableFilterButtons title={`${appConstantPath.poListAllTitleString} (${allTruckList.length})`}
                    clicked={(filter) => setPOListFilter(filter.target.innerHTML)}
                    themeSelected={themeSelected}
                    isActive={getSelectedFilter === appConstantPath.poListAllTitleString ? true : false} />

                <TableFilterButtons title={`${appConstantPath.poListScheduledTitleString} (${scheduledList.length})`}
                    clicked={(filter) => setPOListFilter(filter.target.innerHTML)}
                    themeSelected={themeSelected}
                    isActive={getSelectedFilter === appConstantPath.poListScheduledTitleString ? true : false} />

                <TableFilterButtons title={`${appConstantPath.poListUnscheduledTitleString} (${unscheduledList.length})`}
                    clicked={(filter) => setPOListFilter(filter.target.innerHTML)}
                    themeSelected={themeSelected}
                    isActive={getSelectedFilter === appConstantPath.poListUnscheduledTitleString ? true : false} />

                {selectedTaskItem ? <TaskItemsDueLabel onCancelTaskItem={() => {
                    removeTaskItem();
                    dispatch({ type: sagaActions.FETCH_TRUCK_LIST, payload: '' });
                }} title={`${AppConstant.dashboard.defaultdashboard.funnelGraph.taskItemDueRows.taskItemsLoadManagementHeaders.taskItemTitle} ${setTaskItemHeading()}`} />
                    : ''}
            </div>



        </div>
    )
}

export default POListFilters;