import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import InputWithTtile from "../../Common/CustomInput/InputWithTtile";
import TextAreaWithTtile from "../../Common/CustomTextArea/TextAreaWithTtile";
import CustomButton from "../../Common/CustomButton/CustomButton";
import { AppConstant } from "../../../Assests/AppConstant";
import FileUpload from "../../Common/CommanFileUpload/fileUpload";
import { toast } from "react-toastify";
import { showAlert, getFormattedContactNum } from "../../../Assests/Utility";
import { ReactComponent as FileIcon } from "../../../Assests/Svg/file_icon.svg";
import { ReactComponent as Closemodal } from "../../../Assests/Svg/times-close.svg";
import { validEmailRegex, validContactNumRegex } from "../../../../Utils/validationRegex";
import { sagaActions } from '../../../../sagas/sagaActions';
import { getHelpAndFeedbackReducer } from "../../../../redux/reducers/reducerSlices/HelpAndFeedbackReducer";
import { getFileUploadReducer } from "../../../../redux/reducers/reducerSlices/FileuploadReducer";
const HelpAndFeedbackCoponent = ({ formName, closeHelpModal,userId,warehouseId }) => {
  const [loading, setLoading] = useState(false);
  const [imageUploading, setImageUploading] = useState(false);
  const dispatch = useDispatch();
  const helpData = useSelector(getHelpAndFeedbackReducer);
  const fileUploadData = useSelector(getFileUploadReducer);
  const [inputs, setInputs] = useState({
    email: "",
    name: "",
    phoneNo: "",
    issue: "",
    feedback: "",
    file: [],
    userId:userId,
    warehouseId:warehouseId
  });
  //validation code
  let [errors, setErrors] = useState({
    email: [],
    name: [],
    phoneNo: [],
    issue: [],
    feedback: [],
  });

  let [dirty, setDirty] = useState({
    email: false,
    name: false,
    phoneNo: false,
    issue: false,
    feedback: false,
  });

  const { email, name, phoneNo, issue, feedback } = inputs;

  let handleChange = (data, name) => {
    setInputs((inputs) => ({ ...inputs, [name]: data }));
  };
  const handleErrors = (name) => {
    setDirty((dirty) => ({ ...dirty, [name]: true }));
    validate();
  }
  
  let validate = () => {
    let validArray = [];
   
    let errorData = {
      email: [],
      name: [],
      phoneNo: [],
      issue: [],
      feedback: [],
    };
   
      let valid = true;
      
    valid = validateName(inputs.name, errorData);
    validArray.push(valid);
    valid = validateEmail(inputs.email, errorData);
    validArray.push(valid);
    valid = validatePhoneNo(inputs.phoneNo, errorData);
    validArray.push(valid);
    if (formName === AppConstant.help.formName) {
      valid = validateIssue(inputs.issue, errorData);
      validArray.push(valid);
    } else {
      valid = validateFeedback(inputs.feedback, errorData);
      validArray.push(valid);
    }
      setErrors(errorData);
  
     if (validArray.includes(false)) {
      return false;
    }
    else {
      return true
    }
  };

  const validateEmail = (email, errorData) => {
    if (!email) {
      errorData.email.push(AppConstant.help.required);
      return false;
    } else {
      if (!validEmailRegex.test(email)) {
        errorData.email.push(AppConstant.help.emailIncorrect);
        return false;
      }
      return true;
    }
  };
  const validatePhoneNo = (phoneNo, errorData) => {
    if (!phoneNo) {
      errorData.phoneNo.push(AppConstant.help.required);
      return false;
    } else{
      if (!validContactNumRegex.test(phoneNo)) {
        errorData.phoneNo.push(AppConstant.help.contactIncorrect);
        return false;
      }
    }
    return true;
  };
  const validateFeedback = (feedback, errorData) => {
    if (!feedback) {
      errorData.feedback.push(AppConstant.help.required);
      return false;
    } else {
      if (feedback.length > 500) {
        errorData.feedback.push(AppConstant.help.lengthExceeded);
        return false;
      }
      return true;
    }
  };
  const validateIssue = (issue, errorData) => {
    if (!issue) {
      errorData.issue.push(AppConstant.help.required);
      return false;
    } else {
      if (issue.length > 500) {
        errorData.issue.push(AppConstant.help.lengthExceeded);
        return false;
      }
      return true;
    }
  };
  const validateName = (name, errorData) => {
    if (!name) {
      errorData.name.push(AppConstant.help.required);
      return false;
    }
    return true;
  };
  const onSubmit = async () => {
    let dirtyData = dirty;
    Object.keys(dirty).forEach((control) => {
      dirtyData[control] = true;
    });
    setDirty(dirtyData);
   if (validate()) {
      setLoading(true);
      const params = {
        ...inputs,
        phoneNumber: inputs.phoneNo,
        fileOne: inputs.file.length ? inputs.file[0].fileUrl : null,
        fileTwo: (inputs.file.length && inputs.file.length > 1) ? inputs.file[1].fileUrl : null,
        ownedBy: null,
        status: "OPEN",
        crud_type: 'I'
      }
      if (formName === AppConstant.help.formName) {
        const issueParams = {...params, feedback: null, type: 'Issue'}
        dispatch({ type: sagaActions.ADD_HELP, payload: issueParams });

      } else {
        const feedbackParams = {...params, issue: null, type: 'FeedBack'}
        dispatch({ type: sagaActions.ADD_FEEDBACK, payload: feedbackParams });

      }
    }
  };
  useEffect(() => {
    onLoad();
  }, [helpData]);
  const onLoad = () => {
    if (helpData.loading === false) {
      setLoading(false);
      if (helpData.error) {
        showAlert(toast.TYPE.ERROR, helpData.error.message);
      }
      if (helpData.data) {
        closeHelpModal();
        if (formName === AppConstant.help.formName) {
          showAlert(toast.TYPE.SUCCESS, AppConstant.notifications.help.help);
          dispatch({ type: sagaActions.RESET_HELP_FEED });
        } else {
          showAlert(toast.TYPE.SUCCESS, AppConstant.notifications.help.feedback);
          dispatch({ type: sagaActions.RESET_HELP_FEED });
        }
      }
    }

  };
  useEffect(() => {
    if (fileUploadData?.data) {
      setImageUploading(false);
      const imageName = fileUploadData?.data?.fileUrl.split("/").pop();
      setInputs({
        ...inputs,
        file: [...inputs.file, { fileUrl: fileUploadData?.data?.fileUrl, imageName }],
      });
      dispatch({ type: sagaActions.RESET_FILE_UPLOAD });

    }
    if (fileUploadData?.error) {
      setImageUploading(false);
      showAlert(toast.TYPE.ERROR, helpData?.error?.message);
    }
  }, [fileUploadData]);
  const onFileChange = async (fileData, name) => {
    if (inputs.file.length < 2) {
      setImageUploading(true);
      const fd = new FormData();
      fd.append("file", fileData);
      let fileUploadData = {
        name,
        fd
      }
      dispatch({ type: sagaActions.FILE_UPLOAD, payload: fileUploadData });
    } else {
      showAlert(toast.TYPE.ERROR, AppConstant.notifications.help.errorExceeded);
    }
  };


  return (
    <>
      <div className="help-form-group">
        <div className="inputDiv">
          <InputWithTtile
            showError={dirty["name"] && errors["name"][0] ? errors["name"] : ""}
            handleErrors={handleErrors}
            name="name"
            required={true}
            value={name ? name : ""}
            title={AppConstant.help.name}
            placeHolder={AppConstant.help.enterName}
            onEmailChange={handleChange}
          />
        </div>
        <div className="inputDiv">
          <InputWithTtile
            showError={
              dirty["email"] && errors["email"][0] ? errors["email"] : ""
            }
            required={true}
            handleErrors={handleErrors}
            name="email"
            value={email ? email : ""}
            title={AppConstant.help.email}
            placeHolder={AppConstant.help.enterEmail}
            onEmailChange={handleChange}
          />
        </div>
        <div className="inputDiv">
          <InputWithTtile
            showError={
              dirty["phoneNo"] && errors["phoneNo"][0] ? errors["phoneNo"] : ""
            }
            required={true}
            handleErrors={handleErrors}
            name="phoneNo"
            value={phoneNo ? getFormattedContactNum(phoneNo) : ""}
            title={AppConstant.help.phone}
            placeHolder={AppConstant.user.popup.createUser.phone_placeHolder}
            onEmailChange={handleChange}
          />
        </div>

        <div className="inputDiv">
          {formName === AppConstant.help.formName ? (
            <TextAreaWithTtile
              showError={
                dirty["issue"] && errors["issue"][0] ? errors["issue"] : ""
              }
              required={true}
              handleErrors={handleErrors}
              name="issue"
              value={issue ? issue : ""}
              title={AppConstant.help.issue}
              placeHolder={AppConstant.help.enterIssue}
              onEmailChange={handleChange}
            />
          ) : (
            <TextAreaWithTtile
              showError={
                dirty["feedback"] && errors["feedback"][0]
                  ? errors["feedback"]
                  : ""
              }
              required={true}
              name="feedback"
              handleErrors={handleErrors}
              value={feedback ? feedback : ""}
              title={AppConstant.help.feedback}
              placeHolder={AppConstant.help.enterIssue}
              onEmailChange={handleChange}
            />
          )}
        </div>
        <div className="inputDiv">
          <FileUpload
            accept={"image/png,image/jpeg,.pdf"}
            sizeConfig={AppConstant.help.imageSizeConfig}
            onFileChange={onFileChange}
            name="file"
            isLoading={imageUploading}
          />
        </div>
        {inputs.file.map((imgData, index) => (
          <div className="border-box" key={index}>
            <span className="box-main-area">
              <FileIcon />
              <span className="pdlft-2">{imgData.imageName}</span>
            </span>
            <span
              className="cursor-pointer"
              onClick={() => {
                inputs.file.splice(index, 1);
                setInputs({ ...inputs });
              }}
            >
              <Closemodal />
            </span>
          </div>
        ))}
      </div>

      <div className="d-flex justify-content-end loginButtonContainer">
        <CustomButton
          isLoading={loading}
          title={AppConstant.help.helpButtonTitle}
          className={"loginButton"}
          onClick={onSubmit}
        />
      </div>
    </>
  );
};
export default HelpAndFeedbackCoponent;
