import React, { useEffect, useState } from 'react';
import { AppConstant } from '../../../../../../Assests/AppConstant';
import "./FacilityRating.css"
import { useDispatch } from 'react-redux';
import { sagaActions } from '../../../../../../../sagas/sagaActions';
import { getUserSelector } from '../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getPoSSselector } from '../../../../../../../redux/reducers/reducerSlices/POSmartSchedulerReducer';
import good from '../../../../../../Assests/Images/good.png'
import okay from '../../../../../../Assests/Images/okay.png'
import great from '../../../../../../Assests/Images/great.png'
import _ from 'lodash-es';
import { getCurrentDate } from '../../../../../../../Utils/DateUtils';

const FacilityRating = (themeSelected) => {
    const dispatch = useDispatch();
    const userObj = useSelector(getUserSelector);
    const { selectedWarehouse } = userObj;
    const pOSS = useSelector(getPoSSselector);
    const { facilityRatingsAllData } = pOSS;
    const [sumGreat, setsumGreat] = useState(0)
    const [sumGood, setsumGood] = useState(0)
    const [sumOkay, setsumOkay] = useState(0)

    useEffect(() => {
        if (facilityRatingsAllData && _.upperCase(facilityRatingsAllData?.status) === 'SUCCESS') {
            setsumGreat(0)
            setsumGood(0)
            setsumOkay(0)
            let great = 0
            let good = 0
            let okay = 0
            facilityRatingsAllData?.data?.counts?.map((el) => {
                great += el.count.Great;
                good += el.count.Good;
                okay += el.count.Okay;
            })
            setsumGreat(great)
            setsumGood(good)
            setsumOkay(okay)
        }
    }, [facilityRatingsAllData])

    useEffect(() => {
        dispatch({ type: sagaActions.RESET_FACILITY_RATINGS_ACTION });
        const d = new Date()
        const start = new Date(d.getFullYear(), d.getMonth(), 1)
        const params = {
            "warehouse_id": selectedWarehouse.ID,
            "startDate": moment(start).format(AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
            "endDate": getCurrentDate(),
            "currentPage": 1,
            "itemsPerPage": 10,
            "rating": null,
            "type": 'G'
        }
        dispatch({ type: sagaActions.UPDATE_FACILITY_RATINGS, payload: params });
    }, [])

    const RatingsContainer = (props) => {
        return <div className='d-flex flex-column w-25 justify-content-center align-items-center'>
            <img src={props.rating} />
            <span className={`graphHeading ${themeSelected} ratingTitle`}>{props.title}</span>
            <div className='ratingCountContainer'>
                {props.ratingCount}
            </div>
        </div>
    }


    return <div><div className={`ms-2 graphHeading ${themeSelected} me-5 taskItemTitle`}>{AppConstant.dashboard.defaultdashboard.funnelGraph.sectionTitle.facilityRatings}</div>
        <div className={`graphHeading ${themeSelected} subTitle`}>{AppConstant.dashboard.defaultdashboard.funnelGraph.sectionTitle.currentMonth}</div>
        <div className='ms-3 py-0 ratingContainer'>
            <RatingsContainer rating={okay} ratingCount={sumOkay} title={AppConstant.dashboard.defaultdashboard.funnelGraph.ratings.okay} />
            <RatingsContainer rating={good} ratingCount={sumGood} title={AppConstant.dashboard.defaultdashboard.funnelGraph.ratings.good} />
            <RatingsContainer rating={great} ratingCount={sumGreat} title={AppConstant.dashboard.defaultdashboard.funnelGraph.ratings.great} />
        </div>
    </div>
}

export default FacilityRating