import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dateTimeFormatter } from '../../../../../Assests/Utility';
import { sagaActions } from '../../../../../../sagas/sagaActions';
import { getTruckSelector } from '../../../../../../redux/reducers/reducerSlices/getTruckReducer';
import { getCommentsListSelector } from '../../../../../../redux/reducers/reducerSlices/CommentsReducerSlice';
import CommentRow from './CommentRow/CommentRow';
import { format } from 'date-fns';
import './CommentsListSection.css';

const CommentsListSection = (props) => {

    const dispatch = useDispatch();
    const { load_control_num, themeSelected } = props;
    const commentsList = useSelector(getCommentsListSelector);
    const getTruck = useSelector(getTruckSelector);
    const { truckListMasterData } = getTruck;
    
    useEffect(() => {
        const paramsObj = { 'load_control_num': load_control_num };
        dispatch({ type: sagaActions.GET_ALL_COMMENTS, payload: paramsObj });
    }, [truckListMasterData]);

    const tableRow = () => {
        const arr = commentsList.map((element,i) => {
             let dateFinal = '';
            if(element.create_date){
            const commentDate = dateTimeFormatter(new Date(element.create_date))
            const formattedDate = (format(new Date(commentDate.date), 'MMM, dd'))
            dateFinal = commentDate.time + ' ' + ordinal(formattedDate)
            }
            return <CommentRow key={element.create_date || i} name={element.created_by} comment={element.notes_text} date={dateFinal || '-'} themeSelected={themeSelected} />
        });
        return arr;
    }

    const ordinal = (n) => {
        var s = ["th", "st", "nd", "rd"];
        var v = n % 100;
        return n + (s[(v - 20) % 10] || s[v] || s[0]);
    }

    return (
        <div className={`container-fluid d-flex justify-content-start m-0 p-0`}>
            <table>
                <thead />
                <tbody className='commentsTableBody container-fluid m-0 p-0'>{tableRow()}</tbody>
            </table>
        </div>
    )

}

export default CommentsListSection;