import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppConstant } from '../../../../../Assests/AppConstant';
import { sagaActions } from '../../../../../../sagas/sagaActions';
import { createChartsProps, donutType } from '../../../../../Assests/DashboardUtility';
import { getFormattedDate, YYYY_MM_DD_FORMAT, convertGmtToDateRange } from '../../../../../../Utils/DateUtils';
import { getDefaultDashboardSelector } from '../../../../../../redux/reducers/reducerSlices/DefaultDashboardReducer';
import { getSelectedDateSelector } from '../../../../../../redux/reducers/reducerSlices/DateSelectorReducer';
import {
    detentionLineData, detentionDonutdata, appointmentComplianceData, top10LateLoadsData,
    funnelGraphData, unloadingTypeSummaryDonutData, doorStatusData,
    doorUsageData, unscheduledPOData, doorStatusDataPie, doorAssignmentColumnStackedGraph
} from '../../../../../Assests/GraphUtilities';
import { ReactComponent as NoDataDarkMode } from '../../../../../Assests/Svg/NoDataDarkMode.svg';
import { ReactComponent as NoDataLightMode } from '../../../../../Assests/Svg/NoDataLightMode.svg'
import _ from 'lodash';
import Loader from '../../../../Loader/Loader';
import AppointmentCompliance from '../DefaultDashboard/Wrapper/Children/AppointmentCompliance/AppointmentCompliance';
import DoorStatus from '../DefaultDashboard/Wrapper/Children/DoorStatus/DoorStatus';
import DetentionStatus from '../DefaultDashboard/Wrapper/Children/DetentionStatus/DetentionStatus';
import DoorUsageSummary from '../DefaultDashboard/Wrapper/Children/DoorUsageSummary/DoorUsageSummary';
import Top10LateLoadsStatus from '../DefaultDashboard/Wrapper/Children/Top10LateLoadsWidget/Top10LateLoadsStatus';
import FunnelGraphWidget from '../DefaultDashboard/Graphs/FunnelGraphWidget/FunnelGraphWidget';
import UnloadingTypeSummary from '../DefaultDashboard/Wrapper/Children/UnloadingTypeSummary/UnloadingTypeSummary';
import UnscheduledPosWidget from '../DefaultDashboard/Wrapper/Children/UnscheduledPosWidget/UnscheduledPosWidget';
import './WidgetsWrapper.css';
import { Link } from 'react-router-dom';
import { getUserSelector } from '../../../../../../redux/reducers/reducerSlices/UserReducer';
import DoorAssignment from '../DefaultDashboard/Wrapper/Children/DoorAssignment/DoorAssignment';

const WidgetsWrapper = (props) => {

    const { themeSelected, selectedWidget, height, width } = props;
    const dispatch = useDispatch();
    const componentRef = useRef();
    // const [currentWidgetData, setCurrentWidgetData] = useState({});
    const [doorUsageSummaryData, setDoorUsageSummaryData] = useState({ ...props, doorUsageData })
    const [detentionStatusData, setDetentionStatusData] = useState({ ...props, detentionDonutdata, detentionLineData });
    const [appointmentComplianceState, setAppointmentComplianceState] = useState({ ...props, appointmentComplianceData });
    const [doorStatusState, setDoorStatusState] = useState({ ...props, doorStatusData });
    const [unScheduledPOState, setUnScheduledPOState] = useState({ ...props, unscheduledPOData });
    const [funnelGraphObj, setFunnelGraphObj] = useState({});
    const [lateFunnelGraphObj, setLateFunnelGraphObj] = useState({});
    const [top10LateLoadsState, setTop10LateLoadsState] = useState({ ...props, top10LateLoadsData });
    const [unloadingTypeSummaryState, setUnloadingTypeSummaryState] = useState({ ...props, unloadingTypeSummaryDonutData });
    const defaultDashboard = useSelector(getDefaultDashboardSelector);
    const { detentionStatus, appointmentCompliance, top10LateLoads,
        dashboardLoadStatusObj, unloadingTypeSummary, doorUsageSummary,
        doorStatusWidget, unScheduledPOSummaryObj, dateRangeObj, customDashboardDockSelectedList, doorAssignmentReportData,doorAssignmentLoader, webSocketChange } = defaultDashboard;
    const getSelectedDateSelectorObj = useSelector(getSelectedDateSelector);
    const { selectedDate } = getSelectedDateSelectorObj;
    const [dockIdArr, setDockIdArr] = useState([]);
    const [doorUsageObject, setDoorUsageObject] = useState('Trucks');
    const [dockIdError, setDockIdError] = useState(false);
    const userPermission = useSelector(getUserSelector);
    const { selectedWarehouse } = userPermission;
    const [top10LoadSelection, setTop10LoadSelection] = useState('VENDOR');
    const [doorStatusSelectedDockState, setDoorStatusSelectedDockState] = useState(null)
    const [doorUsageSummarySelectedDockState, setDoorUsageSummarySelectedDockState] = useState(null)
    const [doorAssignmentSelectedDockState, setDoorAssignmentSelectedDockState] = useState(null)
    const [doorAssignmentData, setDoorAssignmentData] = useState({ ...props, doorAssignmentColumnStackedGraph })
    /**
     * Initial useEffect
     */
    useEffect(() => {
        dispatch({ type: sagaActions.DOCK_LIST, payload: { warehouse_id: selectedWarehouse.ID } })
    }, [])

    /**
     * useEffect for webSocketChange - Trigger API as per change
     */
    useEffect(() => {
        if (webSocketChange) {
            onUpdateSocketReceived(webSocketChange)
        }
    },[webSocketChange])

    useEffect(() => {
        if (_.size(customDashboardDockSelectedList)) {
            const filterDoorStatus = _.filter(customDashboardDockSelectedList, { name: 'Door Status' })
            const filterDoorUsageSummary = _.filter(customDashboardDockSelectedList, { name: 'Door Usage Summary' })
            const filterDoorAssignment = _.filter(customDashboardDockSelectedList, { name: 'Door Assignment' })
            if (_.size(filterDoorStatus)) {
                setDoorStatusSelectedDockState(filterDoorStatus[0])
            }
            if (_.size(filterDoorUsageSummary)) {
                setDoorUsageSummarySelectedDockState(filterDoorUsageSummary[0])
            }
            if (_.size(filterDoorAssignment)) {
                setDoorAssignmentSelectedDockState(filterDoorAssignment[0])
            }
        }
    }, [customDashboardDockSelectedList])

    // useEffect(() => {
    //     setDockIdArr([]);
    //     if (!_.isEmpty(doorStatusDockSelected)) {
    //         // setDockIdArr(createDocksIdArr(selectedDockFiltersList, dockList));
    //         setDockIdError(false)
    //     }
    // }, [selectedDockFiltersList])

    useEffect(() => {
        dispatch({
            type: sagaActions.RESET_FUNNEL_GRAPH_DATA, payload: ({})
        })
        showSelectedGraph();
    }, [selectedWidget, selectedWarehouse.ID, dockIdArr])
    /**
    * useEffect if DOOR STATUS DOCK CHANGES
     */
    useEffect(() => {
        if (!_.isEmpty(doorStatusSelectedDockState) && doorStatusSelectedDockState?.name === 'Door Status')
        dispatch({
            type: sagaActions.GET_DOOR_STATUS_WIDGET, payload: createWidgetsMenuPayload('Door Status')
        })
    }, [doorStatusSelectedDockState])
    /**
    * useEffect if Door Usage Summary DOCK CHANGES
     */
    useEffect(() => {
        if (!_.isEmpty(doorUsageSummarySelectedDockState) && doorUsageSummarySelectedDockState?.name === 'Door Usage Summary') {
            dispatch({
                type: sagaActions.UPDATE_DOOR_USAGE_SUMMARY, payload: createWidgetsMenuPayload('Door Usage Summary')
            })
        } else {
            setDockIdError(true)
        }
    }, [doorUsageSummarySelectedDockState])
    /**
    * useEffect if Door Assignment DOCK CHANGES
     */
    useEffect(() => {
        if (!_.isEmpty(doorAssignmentSelectedDockState) && doorAssignmentSelectedDockState?.name === 'Door Assignment') {
            dispatch({
                type: sagaActions.DOOR_ASSIGNMENT_LOADER, payload: true
            })
            dispatch({
                type: sagaActions.GET_DOOR_ASSIGNMENT, payload: createWidgetsMenuPayload("Door Assignment")
            })
        } else {
            setDockIdError(true)
        }
    },[doorAssignmentSelectedDockState])

    useEffect(() => {
        createDoorStatusWidget();
    }, [doorStatusWidget])

    useEffect(() => {
        createDetentionWidget();
    }, [detentionStatus])

    useEffect(() => {
        createAppointmentComplianceWidget();
    }, [appointmentCompliance])

    useEffect(() => {
        if (!_.isEmpty(top10LateLoads)) {
            setTop10LateLoadsState(top10LateLoads);
        }
    }, [top10LateLoads])

    useEffect(() => {
        createUnloadingTypeSummaryWidget();
    }, [unloadingTypeSummary])

    useEffect(() => {
        showSelectedGraph()
    }, [height, width])

    useEffect(() => {
        createFunnelGraphData()
    }, [dashboardLoadStatusObj])

    useEffect(() => {
        createDoorUsageSummaryWidget();
    }, [doorUsageSummary, doorUsageObject])

    useEffect(() => {
        createDoorAssignmentSummaryWidget()
    },[doorAssignmentReportData])

    useEffect(() => {
        createUnScheduledPOSummaryWidget();
    }, [unScheduledPOSummaryObj])

    const toggleLateLoadsData = (event, loadSelectedStr) => {
        event.preventDefault()
        setTop10LoadSelection(_.upperCase(loadSelectedStr))
    }

    const createWidgetsMenuPayload = (widget) => {
        const start = convertGmtToDateRange(dateRangeObj).start;
        const end = convertGmtToDateRange(dateRangeObj).end;
        switch (widget) {
            case 'Appointments':
                return {
                    "date": getFormattedDate(YYYY_MM_DD_FORMAT, selectedDate),
                    "dock_id": dockIdArr.toString() === '' ? null : dockIdArr.toString(),
                    "warehouse_id": selectedWarehouse.ID,
                    "isDrillDown": "0"
                }
            case 'Detention':
                return {
                    "date": getFormattedDate(YYYY_MM_DD_FORMAT, selectedDate),
                    "dock_id": dockIdArr.toString() === '' ? null : dockIdArr.toString(),
                    "warehouse_id": selectedWarehouse.ID,
                    "isDrillDown": "0",

                }
            case 'Top 10 Late Loads':
                return {
                    "dock_id": dockIdArr.toString() === '' ? null : dockIdArr.toString(),
                    "warehouse_id": selectedWarehouse.ID,
                    'isDrillDown': '0',
                    'late_status': null,
                    "type": top10LoadSelection,
                    "filterByID": null
                }
            case 'Load Status Summary':

                return {
                    "dock_id": dockIdArr.toString() === '' ? null : dockIdArr.toString(),
                    "warehouse_id": selectedWarehouse.ID,
                    "date": getFormattedDate(YYYY_MM_DD_FORMAT, selectedDate),
                    "isDrillDown": "0"
                }
            case 'Late Load Summary':
                return {
                    "dock_id": null,
                    "warehouse_id": selectedWarehouse.ID,
                    "date": getFormattedDate(YYYY_MM_DD_FORMAT, selectedDate),
                    "isDrillDown": "0"
                }
            case 'Unload Type Summary':
                return {
                    "dock_id": dockIdArr.toString() === '' ? null : dockIdArr.toString(),
                    "warehouse_id": selectedWarehouse.ID,
                    "isDrillDown": "0",

                }
            case 'Door Usage Summary':
                return {
                    "date": getFormattedDate(YYYY_MM_DD_FORMAT, selectedDate),
                    "dock_id": doorUsageSummarySelectedDockState ? doorUsageSummarySelectedDockState?.dock_id : null,
                    "isDrillDown": "0",
                    "warehouse_id": selectedWarehouse.ID,
                }
            case 'Door Status':
                return {
                    "dock_id": doorStatusSelectedDockState ? doorStatusSelectedDockState?.dock_id : null,
                    "isDrillDown": "0",
                    "warehouse_id": selectedWarehouse.ID,
                    "date": start !== "Invalid date" ? start : null,
                    "drillDownType": null
                }
            case "Unschedules PO's":
                return {
                    "dock_id": dockIdArr.toString() === '' ? null : dockIdArr.toString(),
                    "isDrillDown": "0",
                    "warehouse_id": selectedWarehouse.ID,
                }
            case 'Door Assignment':
                return {
                    "dock_id": doorAssignmentSelectedDockState ? doorAssignmentSelectedDockState?.dock_id : null,
                    "isDrillDown": "0",
                    "warehouse_id": selectedWarehouse.ID,
                    "fromDate": start !== "Invalid date" ? start : null,
                    "endDate": end !== "Invalid date" ? end : null,
                    "drillDownType": null,
                    "selectedDoor": null,
                    "pageNo": 1,  
                    "limit": null
                }
            default:
                return {}

        }
    }

    const showSelectedGraph = () => {
        switch (selectedWidget.widget) {
            case 'Appointments':
                dispatch({
                    type: sagaActions.GET_APPOINTMENT_COMPLIANCE, payload: createWidgetsMenuPayload('Appointments')
                })
                break;
            case 'Detention':
                dispatch({
                    type: sagaActions.GET_DETENSTION_STATUS, payload: createWidgetsMenuPayload('Detention')
                })
                break;
            case 'Top 10 Late Loads':
                dispatch({
                    type: sagaActions.TOP_10_LATE_LOADS, payload: createWidgetsMenuPayload('Top 10 Late Loads')
                })
                break;
            case 'Load Status Summary':
                dispatch({
                    type: sagaActions.GET_DASHBOARD_STATUS_TRUCK_LIST, payload: createWidgetsMenuPayload('Load Status Summary')
                })
                break;
            case 'Late Load Summary':
                dispatch({
                    type: sagaActions.GET_DASHBOARD_STATUS_TRUCK_LIST, payload: createWidgetsMenuPayload('Late Load Summary')
                })
            case 'Unload Type Summary':
                dispatch({
                    type: sagaActions.GET_UNLOADING_TYPE_SUMMARY, payload: createWidgetsMenuPayload('Unload Type Summary')
                })
                break;
            case 'Door Usage Summary':
                if (!_.isEmpty(doorUsageSummarySelectedDockState) && doorUsageSummarySelectedDockState?.name === 'Door Usage Summary') {
                    dispatch({
                        type: sagaActions.UPDATE_DOOR_USAGE_SUMMARY, payload: createWidgetsMenuPayload('Door Usage Summary')
                    })
                } else {
                    setDockIdError(true)
                }
                break;
            case 'Door Status':
                if (!_.isEmpty(doorStatusSelectedDockState) && doorStatusSelectedDockState?.name === 'Door Status')
                    dispatch({
                        type: sagaActions.GET_DOOR_STATUS_WIDGET, payload: createWidgetsMenuPayload('Door Status')
                    })
                break;
            case "Unscheduled PO's":
                dispatch({
                    type: sagaActions.WIDGET_UNSCHEDULED_POS, payload: createWidgetsMenuPayload("Unschedules PO's")
                })
                break;
            case "Door Assignment":  
                if (!_.isEmpty(doorAssignmentSelectedDockState) && doorAssignmentSelectedDockState?.name === 'Door Assignment') {
                    dispatch({
                        type: sagaActions.DOOR_ASSIGNMENT_LOADER, payload: true
                    })
                    dispatch({
                        type: sagaActions.GET_DOOR_ASSIGNMENT, payload: createWidgetsMenuPayload("Door Assignment")
                    })
                } else {
                    setDockIdError(true)
                }
                
                break;
            default:
        }
    }

    const createDetentionWidget = () => {
        if (!_.isEmpty(detentionStatus)) {
            const datadonut = detentionStatus.map(el => _.sum(el.data))
            const updatedData = { ...detentionDonutdata }
            // updatedData.width = 600;
            const detentionDonut = createChartsProps(datadonut, donutType.detentionStatusDonut, themeSelected, updatedData)
            const detentionLine = createChartsProps(detentionStatus, donutType.detentionStatusLine, themeSelected, detentionLineData)
            let newdetentionStatusData = { ...props, detentionDonut, detentionLine }
            setDetentionStatusData(newdetentionStatusData)
        }
    }

    const createAppointmentComplianceWidget = () => {
        if (!_.isEmpty(appointmentComplianceData)) {
            const updatedData = { ...appointmentComplianceData }
            // updatedData.width = 450;
            const appointmentComplianceChart = createChartsProps(appointmentCompliance, donutType.appointmentCompliance, themeSelected, updatedData)
            let setData = { ...props, appointmentComplianceChart };
            setData.themeSelected = themeSelected;
            setAppointmentComplianceState(setData);
        }
    }

    const createDoorUsageSummaryWidget = () => {
        if (_.size(doorUsageSummary)) {
            let truckCounts = [];
            let palletsCounts = [];
            let labels = []
            Array.from(doorUsageSummary).forEach(el => {
                truckCounts.push(el.TruckCount);
                palletsCounts.push(el.PalletsCount);
                labels.push(el?.Door)
            })
            const updatedSeries = [{
                name: 'Trucks',
                type: 'column',
                data: truckCounts
            }, {
                name: 'Pallets',
                type: 'line',
                data: palletsCounts
            }];
            const doorUsageSummaryChart = createChartsProps(updatedSeries, donutType.doorUsageSummary, themeSelected, doorUsageData);
            doorUsageSummaryChart.options.labels = labels;
            let setData = { ...props, doorUsageSummaryChart };
            setData.themeSelected = themeSelected;
            setDoorUsageSummaryData(setData);
        }
    }

    const createDoorAssignmentSummaryWidget = () => {
        if (_.size(doorAssignmentReportData)) {
           const categories = []
            const newChartArrayObj = {
                avg_InDoorTime_to_LumpStartTime: [],
                avg_LumpStartTime_to_LumpEndTime: [],
                avg_LumpEndime_to_BillsOutTime: [],
                notInDetention: [],
                inDetention: [],
                nearingDetention: []
            }
            
            Array.from(doorAssignmentReportData).forEach(el => {
                let keys = Object.keys(newChartArrayObj);
                for (let key of keys) {
                    newChartArrayObj[key].push(el[key] || 0)
                }
                categories.push(el.Door)
            })

            const updatedSeries = [{
                name: 'Door Assigned to Lump Start',
                data: newChartArrayObj.avg_InDoorTime_to_LumpStartTime
            }, {
                name: 'Lump Start to Lump End',
                data: newChartArrayObj.avg_LumpStartTime_to_LumpEndTime
            }, {
                name: 'Lump End to Bills Out',
                data: newChartArrayObj.avg_LumpEndime_to_BillsOutTime
            }, {
                name: 'In Detention',
                data: newChartArrayObj.inDetention
            }, {
                name: 'Not In Detention',
                data: newChartArrayObj.notInDetention
            }, {
                name: 'Nearing Detention',
                data: newChartArrayObj.nearingDetention
                }];
            const doorAssignmentChart = createChartsProps({series: updatedSeries, categories}, donutType.doorAssignmentColumnStackedGraph, themeSelected, doorAssignmentColumnStackedGraph);
            // doorUsageSummaryChart.options.labels = labels;
            let setData = { ...props, doorAssignmentChart };
            setData.themeSelected = themeSelected;
            setDoorAssignmentData(setData);
        }
    }

    const createDoorStatusWidget = () => {

        if (!_.isEmpty(doorStatusWidget)) {
            const dataDonutArray = AppConstant.dashboard.defaultdashboard.doorStatus.labels
            const datadonut = dataDonutArray.map(el => {
                const filterIfdata = _.filter(doorStatusWidget, { name: el })
                if (_.size(filterIfdata)) {
                    return filterIfdata[0].data
                } else {
                    return 0
                }
            })
            const doorStatusdonut = createChartsProps(datadonut, donutType.doorStatus, themeSelected, doorStatusDataPie)
            let setData = { ...props, doorStatusdonut };
            setData.themeSelected = themeSelected;
            setDoorStatusState(setData);
        }
    }

    const createUnloadingTypeSummaryWidget = () => {
        if (_.size(unloadingTypeSummary)) {
            const unloadingTypeSummaryChart = createChartsProps(unloadingTypeSummary, donutType.unloadingTypeSummary, themeSelected, unloadingTypeSummaryDonutData)
            let setData = { ...props, unloadingTypeSummaryChart };
            setData.themeSelected = themeSelected;
            setUnloadingTypeSummaryState(setData);
        }
    }

    const createFunnelGraphData = () => {
        if ((Object.keys(dashboardLoadStatusObj).length !== 0)) {
            const currentObj = funnelGraphData(dashboardLoadStatusObj, themeSelected);
            if (selectedWidget.widget === 'Load Status Summary' && dashboardLoadStatusObj.load_type === 'all') {
                setFunnelGraphObj({})
                setTimeout(() => {
                    setFunnelGraphObj(currentObj)
                }, [500])
            }
            if (selectedWidget.widget === 'Late Load Summary' && dashboardLoadStatusObj.load_type === 'late') {
                setLateFunnelGraphObj({})
                setTimeout(() => {
                    setLateFunnelGraphObj(currentObj)
                }, [500])
            }
        }
    }

    const showFunnelGraphWidget = (graphObj) => {
        return (
            <div>
                <Link to="/analytics/reports/loadstatus" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                    <div className='col-12 pt-3'>
                        <span className='graphHeading'>{selectedWidget.widget}</span><br />
                        <div className='mt-3 pt-5'>
                        </div>
                        <FunnelGraphWidget
                            containerId={(selectedWidget.widget + selectedWidget.parent + selectedWidget.child).replaceAll(' ', '_')}
                            legendsArr={graphObj.legendsArr}
                            quantityArr={graphObj.quantityArr}
                            timeArr={graphObj.timeArr}
                            colorArr={graphObj.colorsArr}
                            height={height / 2}
                            width={width / 1.1}
                            themeSelected={themeSelected} />
                    </div>
                </Link>
            </div>

        )
    }

    const createUnScheduledPOSummaryWidget = () => {
        if (!_.isEmpty(unScheduledPOSummaryObj)) {
            const datadonut = unScheduledPOSummaryObj.map(el => (el.data))
            const unScheduledPOdonut = createChartsProps(datadonut, donutType.unscheduledPOs, themeSelected, unscheduledPOData)
            let setData = { ...props, unScheduledPOdonut };
            setData.themeSelected = themeSelected;
            setUnScheduledPOState(setData);
        }
    }

    const showLoader = () => {
        return (
            <div className='mt-5 pt-5'>
                <Loader height={height / 2} count={1} highlightColor={themeSelected === 'dark-mode' ? '#151515' : ''}
                    baseColor={themeSelected === 'dark-mode' ? '#575757' : ''}
                />
            </div>
        )
    }

    const showCurrentGraph = () => {
        if (selectedWidget.widget) {
            switch (selectedWidget.widget) {
                case 'Appointments':
                    return <AppointmentCompliance {...appointmentComplianceState} showAsWidget={true} />
                case 'Detention':
                    if (_.size(detentionStatusData?.detentionDonut?.series)) {
                        return <DetentionStatus {...detentionStatusData} showAsWidget={true} />
                        // return <DetentionStatus {...detentionStatusData} showAsWidget={width > 1000 ? false : true} />
                    } else {
                        return noDataWidget()
                    }
                case 'Top 10 Late Loads':
                    return <Top10LateLoadsStatus top10LateLoads={top10LateLoads} toggleLateLoadsData={(event, loadSelectedStr) => toggleLateLoadsData(event, loadSelectedStr)} top10LoadSelection={top10LoadSelection} />
                // if (_.size(top10LateLoads)) {
                // } else {
                //     return noDataWidget()
                // }
                case 'Load Status Summary':
                    if ((Object.keys(funnelGraphObj).length !== 0)) {
                        if (_.compact(funnelGraphObj.quantityArr).length && _.compact(funnelGraphObj.timeArr).length) {
                            return (showFunnelGraphWidget(funnelGraphObj))
                        } else {
                            return (noDataWidget())
                        }
                    } else {
                        return (showLoader())
                    }
                case 'Late Load Summary':
                    if ((Object.keys(lateFunnelGraphObj).length !== 0)) {
                        if (_.compact(lateFunnelGraphObj.quantityArr).length && _.compact(lateFunnelGraphObj.timeArr).length) {
                            return (showFunnelGraphWidget(lateFunnelGraphObj))
                        } else {
                            return (noDataWidget())
                        }
                    } else {
                        return (showLoader())
                    }
                case 'Unload Type Summary': {
                    return <UnloadingTypeSummary {...unloadingTypeSummaryState} />
                }
                case 'Door Usage Summary':
                    if (doorUsageSummarySelectedDockState?.dock_id) {
                        if (!_.isEmpty(doorUsageSummary)) {
                            return <DoorUsageSummary {...doorUsageSummaryData} doorUsageData={doorUsageObject} toggleDoorUsageSummaryData={(doorUsageObject) => setDoorUsageObject(doorUsageObject)} doorUsageSummarySelectedDockState={doorUsageSummarySelectedDockState} />
                        } else {
                            return noDataWidget(true)
                        }
                    } else {
                        return noDataWidget()
                    }
                case 'Door Status':
                    if (!_.isEmpty(doorStatusWidget)) {
                        return <DoorStatus {...doorStatusState} />
                    } else {
                        return noDataWidget();
                    }
                case "Unscheduled PO's":
                    if (!_.isEmpty(unScheduledPOState)) {
                        return <UnscheduledPosWidget {...unScheduledPOState} />
                    } else {
                        return noDataWidget();
                    }
                case 'Door Assignment':
                    if (doorAssignmentSelectedDockState?.dock_id) {
                        if (_.size(doorAssignmentReportData)) {
                            if (doorAssignmentLoader) {
                                return (showLoader())
                            } else {     
                                return <DoorAssignment {...doorAssignmentData} doorAssignmentSelectedDockState={doorAssignmentSelectedDockState} />
                            }
                        } else {
                            return noDataWidget(true)
                        }
                    } else {
                        return noDataWidget()
                    }
                default:
                    return noDataWidget()
            }
        } else {
            return noDataWidget()
        }
    }

    const noDataWidget = (type) => {
        return (
            <div className='d-flex flex-column align-items-center noDataAvailable pt-5'>
                <span className='no-data-svg'>{themeSelected === 'light-mode' ? <NoDataLightMode /> : <NoDataDarkMode />}</span>
                {!type ? <span className={`no-data-text1 bg-transparent ${themeSelected}`}>{dockIdError ? AppConstant.poManagement.emptyTable.text3EmptyDock : AppConstant.poManagement.emptyTable.text1}</span> : <span className={`no-data-text1 bg-transparent ${themeSelected}`}>{AppConstant.poManagement.emptyTable.text1}</span>}
            </div >)
    }

     /**
    * Method is used to handle truck update and fetch latest truck data
    */
    const onUpdateSocketReceived = (message) => {
        if (message) {
            switch (message) {
                case 'CHECK_IN_TIME':
                    dispatch({
                        type: sagaActions.GET_APPOINTMENT_COMPLIANCE, payload: createWidgetsMenuPayload('Appointments')
                    });
                    dispatch({
                        type: sagaActions.GET_DETENSTION_STATUS, payload: createWidgetsMenuPayload('Detention')
                    })

                    break;
                case 'BILLS_OUT_TIME':
                    dispatch({
                        type: sagaActions.GET_APPOINTMENT_COMPLIANCE, payload: createWidgetsMenuPayload('Appointments')
                    });
                    dispatch({
                        type: sagaActions.GET_DETENSTION_STATUS, payload: createWidgetsMenuPayload('Detention')
                    })

                    break;
                case 'SCHEDULED_TIME':
                    dispatch({
                        type: sagaActions.GET_APPOINTMENT_COMPLIANCE, payload: createWidgetsMenuPayload('Appointments')
                    });
                    dispatch({
                        type: sagaActions.GET_DETENSTION_STATUS, payload: createWidgetsMenuPayload('Detention')
                    })


                    break;
                case 'DOOR':
                        dispatch({
                            type: sagaActions.UPDATE_DOOR_USAGE_SUMMARY, payload: createWidgetsMenuPayload('Door Usage Summary')
                        })
                        dispatch({
                            type: sagaActions.GET_DOOR_STATUS_WIDGET, payload: createWidgetsMenuPayload('Door Status')
                        })
                        break;
                case 'ENDSMALLPALLETS':
                        dispatch({
                            type: sagaActions.UPDATE_DOOR_USAGE_SUMMARY, payload: createWidgetsMenuPayload('Door Usage Summary')
                        })
                        break;
                case 'ENDLARGEPALLETS':
                        dispatch({
                            type: sagaActions.UPDATE_DOOR_USAGE_SUMMARY, payload: createWidgetsMenuPayload('Door Usage Summary')
                        })
                    break;
                    case 'OUT_DOOR_TIME':
                        dispatch({
                            type: sagaActions.GET_DOOR_STATUS_WIDGET, payload: createWidgetsMenuPayload('Door Status')
                        })
                    break;
                default:
                    break;
            }
        }
    }

    return (
        <div className='m-0 p-0' ref={componentRef}>
            {showCurrentGraph()}
        </div>
    )
}

export default WidgetsWrapper;