
import styles from "./global.module.css";


const GlobalModal = ({
children,
 showModal,
 }) => {
  return (
    <>
      {showModal ? (
        <div className={styles.modal}>
          <div className={styles.overlay}>
            <div className={styles.modalcontent}> {children}</div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default GlobalModal;


