import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppConstant } from "../../../../../../Assests/AppConstant";
import CustomButtonSS from "../../../../../Common/CustomButton/CustomButtonSS";

const AddMorePOPopupSS = ({ zipCode, actionHandler, initateLoading, poForAppt }) => {
  const dispatch = useDispatch();

  const [inputs, setInputs] = useState([
    {
      poNumber: "",
      ReceivingZipCode: zipCode
    }
  ]);
  //validation code
  let [errors, setErrors] = useState([]);

  let [dirty, setDirty] = useState([]);

  // const { poNumber, ReceivingZipCode } = inputs;

  let handleChange = (e, item) => {
    const { name, value } = e.target;
    item[name] = value;
    setInputs([...inputs])
  };

  useEffect(() => {
    let newArr = []
    if (poForAppt.length === 1 || poForAppt.length < 1) {
      newArr.push({ poNumber: '', ReceivingZipCode: zipCode })
    } else {
      poForAppt.forEach((el, i) => {
        if (i !== 0) {
          newArr.push({ poNumber: el.PoNumber, ReceivingZipCode: zipCode, disabled: true })
        }
      })
    }
    setInputs([...newArr])
    validationDynamic(newArr.length);
  }, [poForAppt])
  let validate = () => {

    let valid = true;
    for (let i = 0; i < inputs.length; i++) {
      Object.keys(errors[i]).forEach((con) => {
        errors[i][con] = [];
        if (con === "poNumber" || con === "ReceivingZipCode") {
          if (!inputs[i][con]) {
            errors[i][con].push("This field is required");
            valid = false;
          }
          else {
            // if (!validEmailRegex.test(inputs[i][con])) {
            // errors[i][con].push(AppConstant.help.emailIncorrect);
            // return false;
            // }
          }
        }
      });
    }
    setErrors([...errors]);
    return valid;
  };
  let validationDynamic = (length) => {
    let dirtyData = [];
    let errorsData = [];
    for (let i = 0; i < length; i++) {
      dirtyData[i] = {
        poNumber: false,
        ReceivingZipCode: false

      };
      errorsData[i] = {
        poNumber: [],
        ReceivingZipCode: []
      };
    }
    setDirty([...dirtyData]);
    setErrors([...errorsData]);
  };
  let blurChange = (e, index) => {
    let dirtyData = dirty[index];
    dirtyData[e.target.name] = true;
    setDirty([...dirty]);
    validate();
  };

  const onSubmit = async () => {

    for (let i = 0; i < inputs.length; i++) {
      Object.keys(dirty[i]).forEach((control) => {
        dirty[i][control] = true;
      });
    }
    setDirty([...dirty]);
    if (validate()) {
      actionHandler(inputs, 'ADD')
    }
  };

  const addMoreField = () => {
    setInputs([
      ...inputs,
      {
        poNumber: "",
        ReceivingZipCode: zipCode
      }
    ]);
    validationDynamic(inputs.length + 1);
  };

  const removeField = (index) => {
    let indexData = [...inputs];
    const removePo = inputs[index]
    indexData.splice(index, 1);
    setInputs(indexData);
    validationDynamic(inputs.length - 1);
    actionHandler(removePo, 'REMOVE')

  };

  return (
    <>
      <div className=" d-flex flex-column justify-content-center" data-testid='AddMorePOPopupSS'>

        <div className="">
          <div className="d-flex justify-content-around " style={{ width: '90%' }}>
            <div className="w-50"> {`Po Number`}&nbsp;{<span className="color-red">*</span>}
            </div>
            <div className="w-50">
              {`ZipCode`}&nbsp;{<span className="color-red">*</span>}
            </div>
          </div>
          {inputs.map((singData, index) => (
            <div className="">
              <div style={{}} className="d-flex justify-content-between align-items-center">
                <div className="">

                  <input
                    data-testid={AppConstant.dataTestId.customInput}
                    type={"text"}
                    value={singData.poNumber}
                    name="poNumber"
                    style={{ width: "100%" }}
                    className={
                      errors.length > 0 && dirty.length > 0 && dirty[index]["poNumber"] && errors[index]["poNumber"][0]
                        ? "customTextInput customTextInputError"
                        : "customTextInput"
                    }
                    placeholder={`Po's`}
                    onBlur={(e) => blurChange(e, index)}
                    onInput={(e) =>
                      handleChange(e, singData)
                    }
                    disabled={singData?.disabled}
                  /></div>
                <div className="">

                  <input
                    data-testid={AppConstant.dataTestId.customInput}
                    type={"text"}
                    value={singData.ReceivingZipCode}
                    name="ReceivingZipCode"
                    style={{ width: "100%" }}
                    className={
                      errors.length > 0 && dirty.length > 0 && dirty[index]["poNumber"] && errors[index]["poNumber"][0]
                        ? "customTextInput customTextInputError"
                        : "customTextInput"
                    }
                    placeholder={`Zipcode`}
                    onBlur={(e) => blurChange(e, index)}
                    onInput={(e) =>
                      handleChange(e, singData)
                    }
                    disabled={true}
                  /></div>
                <span className=" px-3 mt-2 d-flex justify-content-center align-items-center">
                  <i
                    className="fa fa-times fa-lg"
                    aria-hidden="true"
                    data-testid={AppConstant.dataTestId.closeBtn}
                    style={{ cursor: "pointer" }}
                    onClick={() => removeField(index)}
                  ></i>
                </span>
              </div>
              <span className="errorMessage">
                {errors.length > 0 && dirty.length > 0 && dirty[index]["poNumber"] && errors[index]["poNumber"][0]
                  ? <span data-testid={AppConstant.dataTestId.validationError}>{errors[index]["poNumber"][0]}</span>
                  : ""}
              </span>
            </div>
          ))}
        </div>
      </div>
      {/* <i
        className="fa fa-plus-square fa-lg "
        style={{ cursor: "pointer" }}
        aria-hidden="true"
        onClick={addMoreField}
      ></i> */}

      <div className="d-flex  justify-content-start mt-4">
      <div className="m-0 p-0">
          <CustomButtonSS
            isLoading={false}
            title={'+ Add More PO'}
            className={"btn-popup-ss-btn-secondary"}
            onClick={addMoreField}
          />
        </div>
        <div className="m-0 p-0">
          <CustomButtonSS
            isLoading={initateLoading}
            title={AppConstant.commonStrings.proceed}
            className={"btn-popup-ss-btn-1 ms-3"}
            onClick={onSubmit}
          />
        </div>
       
      </div>
    </>
  );
};
export default AddMorePOPopupSS;
