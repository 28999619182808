import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    error: null,
    loading: false,
    data:''
} 

export const CompanyGroupReducer = createSlice({
    name: 'CompanyGroupReducer',
    initialState,
    reducers: {
        initiateLoading: (state) => {
          state.loading = true
         },
        addSuccess: (state, action) => {
           state.loading = false;
          state.data = action.payload;
        },
        addFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload
        },
        resetData:(state,action)=>{
            state.loading = false;
            state.data = ''; 
        }
    }
});

export const { addSuccess, addFailure,initiateLoading,resetData} = CompanyGroupReducer.actions;

// Selectors
export const getCompanyGroupReducer = (state) => {
    return state.CompanyGroupReducer;
};

export default CompanyGroupReducer.reducer;
