import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash-es';
import { useNavigate } from 'react-router-dom';
import { getUserSelector } from '../../../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { sagaActions } from '../../../../../../../../../sagas/sagaActions';
import DownloadSection from '../../../../../DownloadSection/DownloadSection';
import { getPoSSselector } from '../../../../../../../../../redux/reducers/reducerSlices/POSmartSchedulerReducer';
import '../Tabular/FacilityRatingsTabular.css'
import CustomDatePickerMMYYYYonly from '../../../../../../../DatePicker/Month/CustomDatePickerMMYYYYonly';
import CommonChart from '../../../../../Dashboard/DefaultDashboard/Graphs/CommonChart';
import NoData from '../../../../../Dashboard/DefaultDashboard/DrillDownReports/Common/Nodata';
import { createChartsProps, donutType } from '../../../../../../../../Assests/DashboardUtility';
import { facilityRatingColumnGraph } from '../../../../../../../../Assests/GraphUtilities';
import moment from 'moment';
import { AppConstant } from '../../../../../../../../Assests/AppConstant';
import Loader from '../../../../../../../Loader/Loader';

const FacilityRatingsGraphical = (props) => {
    const { themeSelected = 'light-mode', view, startDate, setStartDate, endDate, setEndDate, handleStartYearChange, handleEndYearChange, handleStartDateChange, handleEndDateChange, isError } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const pOSS = useSelector(getPoSSselector);
    const { facilityRatingsAllData } = pOSS;
    const userObj = useSelector(getUserSelector);
    const { selectedWarehouse, userType } = userObj;
    const [currentTable, setCurrentTable] = useState(null);
    // moment.tz.setDefault(selectedWarehouse?.TimeZone);
    const [isLoading, setIsLoading] = useState(true);
    const [showGraph, setShowGraph] = useState(false)
    const [facilityRatingsColumnData, setFacilityRatingsColumnData] = useState({})

    useEffect(() => {
        dispatch({ type: sagaActions.RESET_FACILITY_RATINGS_ACTION });
        setIsLoading(false);
        setShowGraph(false)
        return () => {
            dispatch({ type: sagaActions.RESET_FACILITY_RATINGS_ACTION });
        }
    }, [])

    useEffect(() => {
        if (!isError && startDate && endDate) {
            fetchFacilityRatings()
        }
    }, [startDate, endDate, isError])

    useEffect(() => {
        if (!_.isEmpty(facilityRatingsColumnData)) {
            setIsLoading(false)
            setShowGraph(true)
        } else {
            setShowGraph(false)
        }
    }, [facilityRatingsColumnData])

    useEffect(() => {
        if (_.upperCase(facilityRatingsAllData?.status) === 'SUCCESS') {
            setIsLoading(false)
            setShowGraph(false)
            if (!_.isEmpty(facilityRatingsAllData?.data) && _.size(facilityRatingsAllData?.data?.counts)) {
                let dataGreat = [];
                let dataGood = [];
                let dataOkay = [];
                const categories = []

                Array.from(facilityRatingsAllData?.data?.counts).forEach(el => {
                    categories.push(el.monthName);
                    dataGreat.push(Math.round(el.percentages.Great));
                    dataGood.push(Math.round(el.percentages.Good));
                    dataOkay.push(Math.round(el.percentages.Okay));

                })

                const series = [{ name: 'Great', data: dataGreat }, { name: 'Good', data: dataGood }, { name: 'Okay', data: dataOkay }]
                const facilityRatingsGraphPayload = createChartsProps({ series: series, categories: categories }, donutType.faciltyRatingsColumnGraph, themeSelected, facilityRatingColumnGraph)
                setFacilityRatingsColumnData({ ...facilityRatingsGraphPayload })
            }
        } else if (_.upperCase(facilityRatingsAllData?.status) === 'FAIL') {
            setIsLoading(false)
            setShowGraph(false)
            dispatch({ type: sagaActions.RESET_FACILITY_RATINGS_ACTION });
        }
        else {

            setShowGraph(false)
        }

    }, [facilityRatingsAllData])


    const fetchFacilityRatings = () => {
        if (userType === 'USER') {
            setIsLoading(true);
            const eDate = moment(endDate).hours(23).minutes(59).seconds(59)
            const params = {
                "warehouse_id": selectedWarehouse.ID,
                "startDate": moment(startDate).format(AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
                "endDate": moment(eDate).format(AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
                "currentPage": 1,
                "itemsPerPage": facilityRatingsAllData?.data?.itemsPerPage || 100,
                "rating": null,
                "type": view
            }
            dispatch({ type: sagaActions.UPDATE_FACILITY_RATINGS, payload: params });
        } else if (userType === 'SUPERADMIN') {
            // console.log("🚀 SUPERADMIN: See what will happen in this case")
        } else {
            // console.log(userType, " 🚀: This report is not accessible")
        }
    }

    const displayLoader = (themeSelected, height, count) => {
        return (
            <>
                <div className='navText d-flex justify-content-center color-highlight'>
                    {AppConstant.reports.facilityRatings.graphLoaderText}
                </div>
                <Loader height={200} count={count || 1} highlightColor={themeSelected === 'dark-mode' ? '#151515' : ''}
                    baseColor={themeSelected === 'dark-mode' ? '#575757'
                        : ''}
                />
            </>
        )
    }

    return (
        <div>
            <div className='d-flex justify-content-between my-2 mx-3'>
                <div className='fw-bold'>{'Facility Ratings'}</div>
                <div className='d-flex justify-content-end align-items-end gap-2'>
                    <CustomDatePickerMMYYYYonly wrapperClassName='mx-2' date={startDate} setDate={setStartDate} maxDate={new Date()} handleCalendarChange={(d) => {
                        setShowGraph(false)
                        setIsLoading(false);
                        handleStartYearChange(d)
                    }} label='Start Date' themeSelected={themeSelected}
                        onChangeHandler={(d) => {
                            setIsLoading(false);
                            handleStartDateChange(d)
                        }}
                    />
                    <CustomDatePickerMMYYYYonly wrapperClassName='' date={endDate} setDate={setEndDate} handleCalendarChange={(d) => {
                        setIsLoading(false);
                        setShowGraph(false)
                        handleEndYearChange(d)
                    }} label='End Date' maxDate={new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1)} themeSelected={themeSelected} onChangeHandler={(d) => {
                        setIsLoading(false);
                        handleEndDateChange(d)
                    }} />
                    <div className='d-flex justify-content-end align-items-center'>
                    </div>
                </div>
            </div>
            <div className='d-flex justify-content-center m-4'>
                {
                    !isLoading ?
                        showGraph ?
                            <div id='bar-chart'><div className="responsive-chart-bar"> <CommonChart data={facilityRatingsColumnData} /> </div></div>
                            : <NoData themeSelected={themeSelected} />
                        : <div className='w-50'>{displayLoader(themeSelected)}</div>

                }
            </div>
        </div>
    );
}

export default FacilityRatingsGraphical;