import { call, put, takeEvery } from 'redux-saga/effects';
import { Uchain } from '../redux/api/agent';
import {
     getLoadSuccess, getLoadFailure, initiateGetLoad, getDoorListSuccess, assignDoorSuccess, assignDoorFailure
} from '../redux/reducers/reducerSlices/getLoadReducer';
import { approvePOFailure, approvePOSuccess, clearApprovePO, initiateApprovePO } from '../redux/reducers/reducerSlices/POReducer';
import { sagaActions } from './sagaActions';

function* loadTruckDetailsHandler(data){
    try{
        const response = yield call(Uchain.approvePO, data.payload);
        yield put(approvePOSuccess(response));
    }catch{
        yield put(approvePOFailure());
    }
}

function* initateLoader() {
    try {
        yield put(initiateApprovePO())
    } catch (error) {
        yield put(approvePOFailure());

    }
}

function* clearPOStatusSaga() {
    try {
        yield put(clearApprovePO())
    } catch (error) {

    }
}

export function* initiatePOLoader() {
    yield takeEvery(sagaActions.INITIATE_PO_STATUS, initateLoader)
}

export function* approvePO() {
    yield takeEvery(sagaActions.APPROVE_PO, loadTruckDetailsHandler)
}

export function* clearApprovePOStatus() {
    yield takeEvery(sagaActions.CLEAR_APPROVE_PO_STATUS, clearPOStatusSaga)
}