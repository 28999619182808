import { call, put, takeEvery } from "redux-saga/effects";
import { showAlert } from "../App/Assests/Utility";
import { toast } from "react-toastify";
import { sagaActions } from "./sagaActions";
import { Uchain } from "../redux/api/agent";
import { clearCheckInLoad, updateCheckInLoad, updateCheckInLoader, updateCheckInResponse, updateInitiateLoading } from "../redux/reducers/reducerSlices/CheckInReducer";
import { AppConstant } from "../App/Assests/AppConstant";

function* getCheckSearchPoSaga(data){
    try{
        yield put(updateInitiateLoading(true))
        const response = yield call(Uchain.getCheckInSearchedPo, data.payload)
        yield put(updateInitiateLoading(false))
        if (response[0].loads.lenght) {
            showAlert(toast.TYPE.ERROR, AppConstant.commonStrings.noDataFound, 'light');
        }
        else{
          
            yield put(updateCheckInLoad(response))
        }
    }catch(error){
        yield put(updateInitiateLoading(false))
        showAlert(toast.TYPE.ERROR, AppConstant.notifications.login.wentWrong, 'light');
    }
}

function* addCheckInSaga(data){
    try{
        yield put(updateCheckInResponse(null))
        yield put(updateCheckInLoader(true))
        const response = yield call(Uchain.addCheckIn, data.payload)
        yield put(updateCheckInLoader(false))
       
            yield put(updateCheckInResponse(response))
            showAlert(toast.TYPE.SUCCESS, response, 'light');
            yield put(clearCheckInLoad())
   
    }catch(error){
        yield put(updateCheckInLoader(false))
        showAlert(toast.TYPE.ERROR, AppConstant.notifications.login.wentWrong, 'light');
    }
}

export function* getCheckSearchPoWatcher() {
    yield takeEvery(sagaActions.INTIATE_CHECKIN_SEARCH_PO, getCheckSearchPoSaga)
  }
  
  export function* addCheckInWatcher() {
    yield takeEvery(sagaActions.ADD_CHECKIN, addCheckInSaga)
  }