import React from 'react';
import StatusColorIcon from '../StatusCircleIcon/StatusColorIcon';
import './StatusDescriptionText.css';
import { dateAndTimeUTCFormat } from '../../../../../../../../../Assests/Utility'

const StatusDescriptionText = (props) => {
    const { stage, themeSelected, isTruckLate } = props;
    const date_time_formated = stage.stageInitTime
        ? dateAndTimeUTCFormat( stage.stageInitTime)
        : { date: null, time: null };

    return (

        <div className='statusDescriptionText'>

            <StatusColorIcon timeSpent={stage.stageTimeSpent} thisStage={stage.thisStage} currentStageIndex={stage.currentStageIndex}
                showLine={stage.showLine} currentStage={stage.currentStage} themeSelected={themeSelected} isTruckLate={isTruckLate} />

            <div className='text-start mt-3 '>
                {stage.stageName}
            </div>
            <div className='text-start'>
                {date_time_formated.date}
            </div>
            <div className='text-start '>
                {date_time_formated.time}
            </div>

        </div>

    )
}

export default StatusDescriptionText;