import React, { useEffect, useRef, useState } from 'react'
import './NavigationBarUpdatedMobile.css'
import { AppConstant } from '../../Assests/AppConstant'
import user from '../../Assests/Images/user-nav.png'
import { getUserSelector } from '../../../redux/reducers/reducerSlices/UserReducer'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { sagaActions } from '../../../sagas/sagaActions'
import CustomDropDown from '../Common/CustomDropDown/CustomDropDown'
import _ from 'lodash'
import PrimaryNavigation from './Children/Primary/PrimaryNavigation'
import Popup from '../Common/Popup/Popup'
import SecondaryNavigation from './Children/Secondary/SecondaryNavigation'
import { removeInterceptor } from '../../../redux/api/Interceptor'
import { showAlert } from '../../Assests/Utility'
import { toast } from 'react-toastify'
import SSPopup from '../Common/Popup/SSPopup/SSPopup'
import ModalSS from '../SmartSchedule/SchedulePro/Components/Common/ModalSS/ModalSS'
import AccountsSettingsModalSS from '../SmartSchedule/SchedulePro/Components/AccountsSettingsModalSS/AccountsSettingsModalSS'
import DefaultProfilePic from '../../Assests/Images/defaultProfilePic.png'
import HelpAndFeedbackSSInside from '../Login/Container/MainLogin/SchedulePro/MainLogin/Components/HelpAndFeedbackSS/HelpAndFeedbackSSInside'
import PrimaryNavigationMobile from './Children/Primary/PrimaryNavigationMobile'

/**
 * Navidation bar for mobile
 * @param {*} props 
 * @returns 
 */
export const NavigationBarUpdatedMobile = (props) => {

    const { themeSelected } = props;
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const userPermission = useSelector(getUserSelector);
    const { navigationBarList, userType, parentWarehouse, permissions, branding, brandingSuccess, selectedWarehouse, userAccountDetails } = userPermission;
    const { FirstName, LastName, ProfilePicture, Email ,CarrierType} = userAccountDetails;
    const [isUserSubscribed, setIsUserSubscribed] = useState(true);
    const [pNavList, setPNavList] = useState([])
    const [sNavList, setSNavList] = useState([])
    const [showHelpAndFeedback, setShowHelpAndFeedback] = useState(false);
    const [showNavigation , setShowNavigation] = useState(false);

    const toggleHelpAndFeedbackModal = () => {
        setShowHelpAndFeedback(!showHelpAndFeedback)
      }

      /**
       * useEffect to form primary/secondary navigations
       */
    useEffect(() => {
        if (navigationBarList.length) {
            formPSList()
        }
    }, [navigationBarList])

    /**
     * Carrier user disabled when subs is inactive - commented due to WP-2466 - ACME | WP-2579
     */
    // useEffect(() => {
    //     if (userType === AppConstant.userPermission.usertypes.carrier && userAccountDetails.SubscriptionStatus === "INACTIVE") {
    //         setIsUserSubscribed(false)
    //     }
    // }, [isUserSubscribed, userAccountDetails])

    /**
     * Function to form primary/secondary navigations
     */
    const formPSList = () => {
        const getPList = _.flatten(_.filter(navigationBarList, { NavigationType: 'Primary' }))
        setPNavList([...getPList])
        const getSList = _.flatten(_.filter(navigationBarList, { NavigationType: 'Secondary' }))
        setSNavList([...getSList])
    }

    /**
     * useEffect to handle click on Home navigation
     * @param {*} el 
     */
    const clickHandler = (el) => {
        const { PageUrl, PageDesc } = el;
        if (PageDesc === 'Home' && PageUrl === '/warehousemanagement') {
            dispatch({ type: sagaActions.UPDATE_SELECTED_WAREHOUSE, payload: {} })
        }
        else if (PageDesc === 'Home' && PageUrl === '/') {
            dispatch({ type: sagaActions.RESET_USER_PERMISSIONS })
            dispatch({ type: sagaActions.PARENT_WAREHOUSE, payload: {} })
            dispatch({type: sagaActions.CLEAR_SMART_SCHEDULE_REDUCER_DATA})
        }
    }

    /**
     * Fucntion to naviagte to reports
     * @param {*} event 
     * @param {*} obj 
     * @param {*} index 
     */
    const onReportSelected = (event, obj, index) => {
        dispatch({ type: sagaActions.UPDATE_SELECTED_REPORT, payload: obj })
    }

    /**
     * Function to form report navigation
     * @param {*} obj 
     * @param {*} index 
     * @param {*} dataArr 
     * @param {*} placeHolder 
     * @param {*} onItemSelected 
     * @returns 
     */
    const getReportsArrayDropDown = (obj, index, dataArr, placeHolder, onItemSelected) => {
        const accessibleReportsArr = dataArr.filter((reportObj, index) => {
            if (reportObj.Permission) {
                return reportObj
            }
        })
        return accessibleReportsArr
        // return <CustomDropDown
        //     themeSelected={'light-mode'}
        //     data={accessibleReportsArr}
        //     titleStyle={'dropDownNavBarTitle'}
        //     placeHolder={placeHolder}
        //     onSelect={(event) => onItemSelected(event, obj, index)}
        //     keyStr={'ElementAccess'}
        //     hideSelectedItem={true} />
    }

    // const displayLogo = () => {
    //     if (location.pathname === '/') {
    //         if (brandingSuccess) {
    //             if (!_.isEmpty(branding) && branding.GroupLogo) {
    //                 return branding.GroupLogo
    //             } else {
    //                 return AppConstant.commonStrings.logo_small_wordMark
    //             }
    //         } else {
    //             return AppConstant.commonStrings.logo_small_wordMark
    //         }
    //     } else {
    //         if (brandingSuccess) {
    //             if (!_.isEmpty(branding) && branding.WarehouseLogo) {
    //                 return branding.WarehouseLogo
    //             } else {
    //                 return AppConstant.commonStrings.logo_small_wordMark
    //             }
    //         } else {
    //             return AppConstant.commonStrings.logo_small_wordMark
    //         }
    //     }

    // }

    //temporary for scheduler pro

    /**
     * Function to render logo
     * @returns 
     */
    const displayLogo = () => {

        if (location.pathname === '/') {

            if (brandingSuccess) {

                if (!_.isEmpty(branding) && branding.GroupLogo) {

                    return branding.GroupLogo

                } else {

                    return AppConstant.commonStrings.schedulerProLogo

                }

            } else {

                return AppConstant.commonStrings.schedulerProLogo

            }

        } else {

            if (brandingSuccess) {

                if (!_.isEmpty(branding) && branding.WarehouseLogo) {

                    return branding.WarehouseLogo

                } else if (!_.isEmpty(branding) && branding.CompanyLogo) {

                    return branding.CompanyLogo

                } else if (!_.isEmpty(branding) && branding.GroupLogo) {

                    return branding.GroupLogo

                } else {

                    return AppConstant.commonStrings.schedulerProLogo

                }

            } else {

                return AppConstant.commonStrings.schedulerProLogo

            }
        }
    }

    /**
     * Function to render warehosue logo
     * @returns 
     */
    const displayWHLogo=()=>{
        if (selectedWarehouse.WarehouseLogo) {
            return selectedWarehouse.WarehouseLogo
        }
        else{
            return AppConstant.commonStrings.schedulerProLogo
        }
    }

    /**
     * Function to render header logo
     * @returns 
     */
    const setHeaderLogo = () => {

        if (userType === AppConstant.userPermission.usertypes.carrier) {
            return (
                <img src={AppConstant.commonStrings.schedulerProLogo} alt="Primary Logo" className='pointer' onClick={() => navigate("/bookappointments")} />
            )
        } else if (selectedWarehouse && selectedWarehouse.WHType && selectedWarehouse.WHType.toString().toLowerCase() === 'ims') {
            return (
                <div className='d-flex'>
                    <img src={displayLogo()} alt="Primary Logo" />
                </div>
            )
        }
        else if (selectedWarehouse && selectedWarehouse.WHType && selectedWarehouse.WHType.toString().toLowerCase() === 'ss') {
            return (
                <div className='d-flex'>
                    <img src={AppConstant.commonStrings.schedulerProLogo} alt="Primary Logo" className='pointer' onClick={() => navigate("/bookappointments")} />
                </div>
            )
        }
        else if (selectedWarehouse && selectedWarehouse.WHType && selectedWarehouse.WHType.toString().toLowerCase() === 'ims,ss') {
            return (
                <div className='d-flex'>
                    <img className=' me-3' src={displayLogo()} alt="Primary Logo" />
                    <img className='' src={AppConstant.commonStrings.schedulerProLogo} alt="Primary Logo" />
                </div>
            )
        } else if (selectedWarehouse && selectedWarehouse.WHType && selectedWarehouse.WHType.toString().toLowerCase() === 'imssp') {
            return (
                <div className='d-flex'>
                    <img className=' me-3' src={displayWHLogo()} alt="Primary Logo" />
                </div>
            )
        }
         else {
            return (
                <img className='' src={AppConstant.commonStrings.schedulerProLogo} alt="Primary Logo" />
            )
        }

    }

    // const setHeaderLogo = () => {

    //     if (userType === AppConstant.userPermission.usertypes.carrier) {
    //         return (
    //             <img src={AppConstant.commonStrings.schedulerProLogo} alt="Primary Logo" className='pointer' onClick={() => navigate("/bookappointments")} />
    //         )
    //     } else if (selectedWarehouse && selectedWarehouse.WHType && selectedWarehouse.WHType.toString().toLowerCase() === 'ims') {
    //         return (
    //             <div className='d-flex'>
    //                 <img src={displayLogo()} alt="Primary Logo" />
    //             </div>
    //         )
    //     }
    //     else if (selectedWarehouse && selectedWarehouse.WHType && selectedWarehouse.WHType.toString().toLowerCase() === 'ss') {
    //         return (
    //             <div className='d-flex'>
    //                 <img src={AppConstant.commonStrings.schedulerProLogo} alt="Primary Logo" className='pointer' onClick={() => navigate("/bookappointments")} />
    //             </div>
    //         )
    //     }
    //     else if (selectedWarehouse && selectedWarehouse.WHType && selectedWarehouse.WHType.toString().toLowerCase() === 'ims,ss') {
    //         return (
    //             <div className='d-flex'>
    //                 <img className=' me-3' src={displayLogo()} alt="Primary Logo" />
    //                 <img className='' src={AppConstant.commonStrings.schedulerProLogo} alt="Primary Logo" />
    //             </div>
    //         )
    //     } else {
    //         return (
    //             <img className='' src={AppConstant.commonStrings.logo_small_wordMark} alt="Primary Logo" />
    //         )
    //     }

    // }

    const [showSubNav, setShowSubNav] = useState(false);
    const [showCustomSubNav, setShowCustomSubNav] = useState(false);

    /**
     * Function to toggle sub navigation
     */
    const toggleSubNav = () => {
        setShowSubNav(!showSubNav);
    }

    /**
     * Function to logout
     * @param {*} text 
     */
    const logoutPopupHandler = (text) => {
        if (text === AppConstant.commonStrings.yes) {
            removeInterceptor()
            dispatch({ type: sagaActions.CLEAR_SIGNEDIN_USER })
            dispatch({ type: sagaActions.RESET_USER })
            dispatch({type: sagaActions.CLEAR_SMART_SCHEDULE_REDUCER_DATA})
            showAlert(toast.TYPE.INFO, AppConstant.login.logout, 'light')
            window.localStorage.clear();
            navigate("/")
            // If logging out from UserWarehouse Page then trigger branding API again
            if (location.pathname === '/') {
                dispatch({ type: sagaActions.INITIATE_BRANDING_LOADER, payload: true })
                const subdomain = window.location.hostname.split(".")[0];
                dispatch({
                    type: sagaActions.INITIATE_BRANDING, payload: {
                        subdomain: subdomain
                    }
                });
            }

        }
    }
    const [showModal, setShowModal] = useState(false);

    const closepopup = () => {
        setShowModal(false);
    }

    /**
     * Function to show logout popup
     * @returns 
     */
    const showLogoutPopup = () => {
        return <SSPopup {...AppConstant.settings.logoutPopupAttr} popupBtnHandler={(text) => {
            setShowModal(false);
            logoutPopupHandler(text)
        }} themeSelected={themeSelected} showModal={showModal} closepopup={closepopup} />
    }

    const [showModalSS, setShowModalSS] = useState(false);
    const toggleModalSS = () => {
        setShowModalSS(!showModalSS)
    }
    const [showCommingSoon, setShowCommingSoon] = useState(false);

    /**
     * Function to show coming soon popup
     * @returns 
     */
    const showCommingSoonPopup = () => {
        const popupObj = {
            id: 'commingSoon',
            modalLabel: 'commingSoon',
            showClose: true,
            showfooter: false,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title: 'Live Chat',
            alignType: 'center',
            type: 'info',
            bodyText: AppConstant.commonStrings.commingSoon,
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.comments.cancelCommentButtonTitle
                },
                btn2: {
                    show: true,
                    text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.saveButtonTitle
                }
            },
        }

        return <SSPopup {...popupObj} popupBtnHandler={(text) => () => ''} closepopup={() => { setShowCommingSoon(false) }} themeSelected={themeSelected} showModal={showCommingSoon} />
    }

    const subMenu = useRef(null)
    const closeOpenMenus = (e) => {
        if (subMenu.current && showSubNav && !subMenu.current.contains(e.target)) {
            setShowSubNav(false)
        }
    }
    document.addEventListener('mousedown', closeOpenMenus)


    /**
     * Function to toggle navigation bar
     */
    function toggleNavbar() {
        setShowNavigation(!showNavigation)
      }

    return (
      <>
        <div className="navbar-mobile-header">
            <div className='px-3 d-flex justify-content-between w-100'>
                <div className='d-flex gap-2'>
                <div className='mobile-nav-toggle'>
          <button
            className="no-bg no-border nav-ham-mobile"
            onClick={toggleNavbar}
          >
            
    
    
            {!showNavigation?<i className="fa-solid fa-bars"></i>:<i className="fa-solid fa-xmark "></i>}
          </button>
          </div>
          <div className='mobile-header-logo'>
          {setHeaderLogo()}
          </div>
          </div>
          <ul className='nav-list py-0'>
            <li className='d-flex justify-content-between align-items-center account-nav account-nav-mobile pos-rel' onClick={toggleSubNav}>
                    <img src={ProfilePicture || DefaultProfilePic} alt='user' />
                    <div className='ms-2'>
                        <p className='account-nav-head'>{`${FirstName || 'User'} ${LastName}`}</p>
                        <p className='account-nav-sub'>{Email || '-'}</p>
                    </div>
                    {/* <i className="fa-solid fa-angle-right"></i> */}
                    {showSubNav &&
                        <div className='sub-nav-ss-mobile' ref={subMenu}>
                            <SecondaryNavigation sNavList={sNavList} isUserSubscribed={isUserSubscribed} setShowModal={setShowModal} clickHandler={clickHandler} showModalSS={showModalSS} toggleModalSS={toggleModalSS} CarrierType={CarrierType}
                            toggleHelpAndFeedbackModal={toggleHelpAndFeedbackModal} 
                            />
                        </div>
                    }
                </li>
            </ul>
          </div>
       
          <div className='navbar-mobile-body'>
        <div class="navbar-mobile">
        
            {showNavigation ? (
              <PrimaryNavigationMobile
                pNavList={pNavList}
                onReportSelected={(e, subEl) => onReportSelected(e, subEl)}
                getReportsArrayDropDown={(
                  el,
                  index,
                  featureRole,
                  PageDesc,
                  onReportSelected
                ) =>
                  getReportsArrayDropDown(
                    el,
                    index,
                    featureRole,
                    PageDesc,
                    onReportSelected
                  )
                }
                location={location}
                showCustomSubNav={showCustomSubNav}
                setShowCustomSubNav={setShowCustomSubNav}
                clickHandler={(el) => clickHandler(el)}
              />
            ) : (
              ""
            )}
        </div>
        </div>
        </div>
        {showLogoutPopup()}
            {showCommingSoonPopup()}
            {showHelpAndFeedback &&
            <HelpAndFeedbackSSInside toggleModal={toggleHelpAndFeedbackModal}/>
            }
            {
                showModalSS ?
                    <AccountsSettingsModalSS toggleModal={toggleModalSS} /> : ''
            }
      </>
    );
}
