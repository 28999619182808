import _ from 'lodash';
import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getDockListSelector } from '../../../../../../../../redux/reducers/reducerSlices/DockListReducer';
import { getLumpingFaciltyReducer } from '../../../../../../../../redux/reducers/reducerSlices/GetLumpingFaciltyReducer';
import { getReportsSelector } from '../../../../../../../../redux/reducers/reducerSlices/ReportsReducerSlice';
import { getUserSelector } from '../../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { sagaActions } from '../../../../../../../../sagas/sagaActions';
import { AppConstant } from '../../../../../../../Assests/AppConstant';
import { CustomDynamicForm } from '../../../../../../Common/CustomForm/CustomDynamicForm';
import { usePrevious } from '../../../../../../Common/CustomHooks/usePrevious';
import Popup from '../../../../../../Common/Popup/Popup';
import DownloadSection from '../../../../DownloadSection/DownloadSection';
import { changeDateToGivenFormat, convertGmtToDateRange, getCurrentDate } from '../../../../../../../../Utils/DateUtils'
import ADFATReportList from '../Children/ADFATReportList';
import { getRemoteConfigSelector } from '../../../../../../../../redux/reducers/reducerSlices/RemoteConfigReducer';
import DownloadSectionBECustomFeilds from '../../../../DownloadSection/DownloadSectionBECustomFeilds';
import { smartScheduleSelector } from '../../../../../../../../redux/reducers/reducerSlices/SmartScheduleReducer';

const ADFATwrapper = (props) => {
    const { themeSelected } = props;
    const dispatch = useDispatch();
    const lumpingFacilityData = useSelector(getLumpingFaciltyReducer);
    const user = useSelector(getUserSelector);
    const getDockList = useSelector(getDockListSelector);
    const reports = useSelector(getReportsSelector);
    const { vendorList, carrierList, initateLoading } = reports
    const { dockList } = getDockList;
    const { userType, parentWarehouse } = user;
    const [lumpingFacDataArr, setLumpingFacDataArr] = useState([]);
    const [selectedLumpingFacility, setSelectedLumpingFacility] = useState('');
    const [warehouseListState, setWarehouseListState] = useState([]);
    const [dockListState, setDockListState] = useState([]);
    const [currentCalendarType, setCurrentCalendarType] = useState('today');
    const [startDateState, setStartDateState] = useState(changeDateToGivenFormat(getCurrentDate(), AppConstant.commonStrings.MM_DD_YYYY_dateFormat))
    const [endDateState, setEndDateState] = useState(changeDateToGivenFormat(getCurrentDate(), AppConstant.commonStrings.MM_DD_YYYY_dateFormat))
    const [particularFieldValue, setParticularFieldValue] = useState("");
    const [resetFormData, setResetFormData] = useState(0);
    const [editData, setEditData] = useState({});
    const [showModal, setShowModal] = useState(false);
    const divRef = useRef(null);
    const [currentTable, setCurrentTable] = useState(null);
    const [currentTableRef, setCurrentTableRef] = useState(null);
    const previousTable = usePrevious(currentTable);
    const [apiParams, setApiParams] = useState([]);
    const [apiPayload, setApiPayload] = useState({})
      //Get Configration from store
      const remoteConfig=useSelector(getRemoteConfigSelector);
      const {allConfigration}=remoteConfig;
      const {PaginationDefaultLimit}=allConfigration;
      const ss = useSelector(smartScheduleSelector);
      const {isDownloadLoading}=ss;

    useEffect(() => {
        dispatch({type: sagaActions.CLEAR_ADFAT_REPORT_LIST});
        setSelectedLumpingFacility([])
        setWarehouseListState([])
        dispatch({ type: sagaActions.GET_LUMPING_FACILTY_DATA });
        setShowModal(true)
        const { userPermission } = AppConstant;
        if (userType === userPermission.usertypes.user) {
            setWarehouseListState([parentWarehouse])
            setParticularFieldValue({ warehouse_id: parentWarehouse.ID, lumping_facility_id: parentWarehouse.IDLumpingFacility, report_type: 'Details', exclude_dropped_back_haul: 'No', dock_id: AppConstant.reports.master_id_all, master_carrier_id: AppConstant.reports.master_id_all, master_vendor_id: AppConstant.reports.master_id_all, appointment: 'ALL', primary_group_by: 'None', secondary_group_by: 'None', tertiary_group_by: 'None', lump_time_min: '-1', late_for_appt_min: '-1', receive_time_min: '-1', lump_end_to_bills_out_min: '-1', door_assigned_to_bill_out_min: '-1', start_date: startDateState, end_date: endDateState });
            dispatch({ type: sagaActions.DOCK_LIST, payload: { warehouse_id: parentWarehouse.ID } })
            dispatch({ type: sagaActions.GET_CARRIER_LIST, payload: { warehouse_id: parentWarehouse.ID } })
            dispatch({ type: sagaActions.GET_VENDOR_LIST, payload: { warehouse_id: parentWarehouse.ID, dock_id: null } })
        }
        dispatch({ type: sagaActions.INTIATE_LOADER_REPORTS, payload: false });
    }, [])

    useEffect(() => {
        const container = divRef.current;
        const tables = container.querySelectorAll('table');
        if (tables && tables.length && _.isEqual(currentTable, previousTable)) {
            setCurrentTableRef(container);
            setCurrentTable(tables);
        }

    })

    useEffect(() => {
        if (lumpingFacilityData.data) {
            // GET Lumpiung Facility Id from parentWarehouse and filter array and then set array in state
            const { IDLumpingFacility } = parentWarehouse;
            const tempArr = lumpingFacilityData.data.filter(el => el.ID === IDLumpingFacility)
            setLumpingFacDataArr(tempArr);
        }
    }, [lumpingFacilityData.data])


    useEffect(() => {
        if (_.size(dockList)) {
            setDockListState(dockList)
        } else {
            dispatch({ type: sagaActions.DOCK_LIST, payload: { warehouse_id: parentWarehouse.ID } })
        }
    }, [dockList])

    const createReportTypeDD = () => {
        let data = ['Details', 'Summary']
        return data.map((data, index) => {
            return (
                <option key={data + '_' + index} value={data}>
                    {data}
                </option>
            );
        })
    }

    const getLumpingFacilityList = () => {
        return (
            lumpingFacDataArr &&
            lumpingFacDataArr.map((data, index) => {
                return (
                    <option key={data.ID} value={data.ID}>
                        {data.Description}
                    </option>
                );
            })
        );
    };
    const getWarehousesList = () => {
        return (
            warehouseListState &&
            warehouseListState.map((data, index) => {
                return (
                    <option key={data.ID} value={data.ID}>
                        {data.Name}
                    </option>
                );
            })
        );
    };

    const createDockListDD = () => {
        let dockListtemp = [{ dock_id: AppConstant.reports.master_id_all, dock_name: 'ALL' }, ...dockList]
        return dockListtemp.map((data, index) => {
            return (
                <option key={data.dock_id} value={data.dock_id}>
                    {data.dock_name}
                </option>
            );
        })
    }

    const yesAndNoDD = () => {
        const data = ['Yes', 'No']
        return data.map((data, index) => {
            return (
                <option key={data + '_' + index} value={data}>
                    {data}
                </option>
            );
        })
    }

    const createAppointmentListDD = () => {
        const data = ['ALL', 'Early or On-Time', 'Late', 'Unscheduled']
        return data.map((data, index) => {
            return (
                <option key={data + '_' + index} value={data}>
                    {data}
                </option>
            );
        })
    }

    const createGroupDD = () => {
        const data = ['None', 'Common Carrier', 'Dock', 'Lumping Facility', 'Scheduled Time', 'Vendor', 'Warehouse', 'Weekend']
        return data.map((data, index) => {
            return (
                <option key={data + '_' + index} value={data}>
                    {data}
                </option>
            );
        })
    }

    // const createBilledToDD = () => {
    //     const data = ['All', 'Driver Unload']
    //     return data.map((data, index) => {
    //         return (
    //             <option key={data+ '_' + index} value={data}>
    //                 {data}
    //             </option>
    //         );
    //     })
    // }

    const createCarrierDD = () => {
        return carrierList.length && carrierList.map((data) => {
            return (
                <option key={data.Id} value={data.Id}>
                    {data.Value}
                </option>
            );
        })
    }

    const createVendorDD = () => {
        return vendorList.length && vendorList.map((data) => {
            return (
                <option key={data.Id} value={data.Id}>
                    {data.Value}
                </option>
            );
        })
    }

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <button className="customDatePickerInput" onClick={onClick} ref={ref}>
            {value}
        </button>
    ));

    const closepopup = () => {
        // setEditData({});
        setShowModal(false);
        setStartDateState('')
        setEndDateState('')
    }

    const formFieldList = [
        {
            name: "report_type",
            type: "select",
            size: "col-sm-6",
            position: 1,
            title:
                AppConstant.reports.adfatReport.dropdownLabels.reportType,
            formValidation: {
                required: false,
            },
            getSelectData: createReportTypeDD,
        },
        {
            name: "exclude_dropped_back_haul",
            type: "select",
            size: "col-sm-6",
            position: 2,
            title:
                AppConstant.reports.adfatReport.dropdownLabels.droppedAndBackhaul,
            formValidation: {
                required: false,
            },
            getSelectData: yesAndNoDD,
        },
        {
            name: "lumping_facility_id",
            type: "select",
            size: "col-sm-6",
            position: 3,
            title:
                AppConstant.reports.adfatReport.dropdownLabels.lumpingCompany,
            formValidation: {
                required: true,
            },
            getSelectData: getLumpingFacilityList,
        },
        {
            name: "warehouse_id",
            type: "select",
            size: "col-sm-6",
            position: 4,
            title:
                AppConstant.userPermission.warehouseListDropDownDefualt,
            formValidation: {
                required: true,
            },
            getSelectData: getWarehousesList,
        },
        {
            name: "dock_id",
            type: "select",
            size: "col-sm-6",
            position: 5,
            title:
                AppConstant.reports.adfatReport.dropdownLabels.dock,
            formValidation: {
                required: false,
            },
            getSelectData: createDockListDD,
        },
        {
            name: "master_carrier_id",
            type: "select",
            size: "col-sm-6",
            position: 6,
            title:
                AppConstant.reports.adfatReport.dropdownLabels.carrier,
            formValidation: {
                required: false,
            },
            getSelectData: createCarrierDD,
        },
        {
            name: "master_vendor_id",
            type: "select",
            size: "col-sm-6",
            position: 7,
            title:
                AppConstant.reports.adfatReport.dropdownLabels.vendor,
            formValidation: {
                required: false,
            },
            getSelectData: createVendorDD,
        },
        {
            name: "delay_Detention",
            type: "select",
            size: "col-sm-6",
            position: 8,
            title:
                AppConstant.reports.adfatReport.dropdownLabels.delayDetention,
            formValidation: {
                required: true,
            },
            getSelectData: yesAndNoDD,
        },
        {
            size: "col-sm-6 mt-1",
            name: "start_date",
            type: "custom-date-picker",
            customDatePickerConfig: {
                calendarType: currentCalendarType,
                placeholder: '',
                selectedDate: startDateState,
                name: "start_date",
                customInput: <ExampleCustomInput />,
            },
            position: 9,
            value: startDateState,
            title:
                AppConstant.reports.adfatReport.dropdownLabels.startDate,
            formValidation: {
                required: true,
            }
        },
        {
            size: "col-sm-6 mt-1",
            name: "end_date",
            type: "custom-date-picker",
            customDatePickerConfig: {
                calendarType: currentCalendarType,
                placeholder: '',
                selectedDate: endDateState,
                startDate: startDateState,
                name: "end_date",
                customInput: <ExampleCustomInput />,
                disableCalendar: startDateState ? false : true,
            },
            position: 10,
            value: endDateState,
            title:
                AppConstant.reports.adfatReport.dropdownLabels.endDate,
            formValidation: {
                required: true,
            }
        },
        {
            name: "appointment",
            type: "select",
            size: "col-sm-6",
            position: 11,
            title:
                AppConstant.reports.adfatReport.dropdownLabels.appointment,
            formValidation: {
                required: false,
            },
            getSelectData: createAppointmentListDD,
        },
        // {
        //     name: "billed_to",
        //     type: "select",
        //     size: "col-sm-6",
        //     position: 12,
        //     title:
        //         AppConstant.reports.adfatReport.dropdownLabels.billedTo,
        //     formValidation: {
        //         required: false,
        //     },
        //     getSelectData: createBilledToDD,
        // },
        {
            name: "primary_group_by",
            type: "select",
            size: "col-sm-6",
            position: 12,
            title:
                AppConstant.reports.adfatReport.dropdownLabels.primaryGrpBy,
            formValidation: {
                required: false,
            },
            getSelectData: createGroupDD,
        },
        {
            name: "secondary_group_by",
            type: "select",
            size: "col-sm-6",
            position: 13,
            title:
                AppConstant.reports.adfatReport.dropdownLabels.secondaryGrpBy,
            formValidation: {
                required: false,
            },
            getSelectData: createGroupDD,
        },
        {
            name: "tertiary_group_by",
            type: "select",
            size: "col-sm-6",
            position: 14,
            title:
                AppConstant.reports.adfatReport.dropdownLabels.tertiaryGrpBy,
            formValidation: {
                required: false,
            },
            getSelectData: createGroupDD,
        },
        {
            name: "trailer",
            type: "text",
            size: "col-sm-6",
            position: 15,
            title:
                AppConstant.reports.adfatReport.dropdownLabels.trailer,
            formValidation: {
                required: false,
            },
        },
        {
            name: "tractor",
            type: "text",
            size: "col-sm-6",
            position: 16,
            title:
                AppConstant.reports.adfatReport.dropdownLabels.tractor,
            formValidation: {
                required: false,
            },
        },
        {
            name: "authorization_number",
            type: "text",
            size: "col-sm-6",
            position: 17,
            title:
                AppConstant.reports.adfatReport.dropdownLabels.authNum,
            formValidation: {
                required: false,
            },
        },
        {
            name: "po",
            type: "text",
            size: "col-sm-6",
            position: 18,
            title:
                AppConstant.reports.adfatReport.dropdownLabels.po,
            formValidation: {
                required: false,
            },
        },
        {
            name: "control",
            type: "text",
            size: "col-sm-6",
            position: 19,
            title:
                AppConstant.reports.adfatReport.dropdownLabels.controlNum,
            formValidation: {
                required: false,
            },
        },
        {
            name: "late_for_appt_min",
            type: "number",
            size: "col-sm-6",
            position: 20,
            title:
                AppConstant.reports.adfatReport.dropdownLabels.lateAppintThersh,
            formValidation: {
                required: false,
            },
        },
        {
            name: "lump_time_min",
            type: "number",
            size: "col-sm-6",
            position: 21,
            title:
                AppConstant.reports.adfatReport.dropdownLabels.longLumpTime,
            formValidation: {
                required: false,
            },
        },
        {
            name: "receive_time_min",
            type: "number",
            size: "col-sm-6",
            position: 22,
            title:
                AppConstant.reports.adfatReport.dropdownLabels.longPaperworkTime,
            formValidation: {
                required: false,
            },
        },
        // {
        //     name: "facility_time_min",
        //     type: "number",
        //     size: "col-sm-6",
        //     position: 24,
        //     title:
        //         AppConstant.reports.adfatReport.dropdownLabels.longFacTime,
        //     formValidation: {
        //         required: false,
        //     },
        // },
        {
            name: "lump_end_to_bills_out_min",
            type: "number",
            size: "col-sm-6",
            position: 23,
            title:
                AppConstant.reports.adfatReport.dropdownLabels.longLumpendToBillsOutThersh,
            formValidation: {
                required: false,
            },
        },
        {
            name: "door_assigned_to_bill_out_min",
            type: "number",
            size: "col-sm-6",
            position: 24,
            title:
                AppConstant.reports.adfatReport.dropdownLabels.doorAssgnToBillsOut,
            formValidation: {
                required: false,
            },
        }

    ]
    const getFormFieldData = (data, name) => {
        if (name === 'start_date') {
            setParticularFieldValue({...particularFieldValue ,start_date : data, end_date: ''})
            setStartDateState(data)
        } else if (name === 'end_date') {
            setEndDateState(data)
        }
        else if (name === 'dock_id') {
            dispatch({ type: sagaActions.GET_VENDOR_LIST, payload: { warehouse_id: parentWarehouse.ID, dock_id: data } })
        }
    };

    const createPayloadForAdfat=(data)=>{
            let obj={...data}
            if (!obj.start_date) {
                obj.start_date = new Date();
            }
            if (!obj.end_date) {
                obj.end_date = new Date();
            }
            obj.start_date = changeDateToGivenFormat(data.start_date, AppConstant.commonStrings.MM_DD_YYYY_dateFormat)
            obj.end_date = changeDateToGivenFormat(data.end_date, AppConstant.commonStrings.MM_DD_YYYY_dateFormat)
            return obj
        
    }
    const getSummitedFormData = (data) => {
        if (!_.isEmpty(data)) {
          let payload=createPayloadForAdfat(data)

            const selectedLumper = (_.find(lumpingFacDataArr, { ID: data.lumping_facility_id }));
            const selectedWarehouse = (_.find(warehouseListState, { ID: data.warehouse_id }));
            let dockListtemp = [{ dock_id: AppConstant.reports.master_id_all, dock_name: 'ALL' }, ...dockList]
            const selectedDock = (_.find(dockListtemp, { dock_id: data.dock_id }));
            const masterCarrier = (_.find(carrierList, { Id: data.master_carrier_id }));
            const masterVendor = (_.find(vendorList, { Id: data.master_vendor_id }));

            setApiParams([
                { "Warehouse": selectedWarehouse.Name ? selectedWarehouse.Name : '' },
                { "Unloading Facility": selectedLumper.Description ? selectedLumper.Description : '' },
                { "Report Type": data.report_type ? data.report_type : '' },
                { "Exclude Dropped Back Haul": data.exclude_dropped_back_haul ? data.exclude_dropped_back_haul : '' },
                { "Dock": selectedDock.dock_name ? selectedDock.dock_name : '' },
                { "Carrier": masterCarrier.Value ? masterCarrier.Value : '' },
                { "Vendor": masterVendor.Value ? masterVendor.Value : '' },
                { "Delay Detention": data.delay_Detention ? data.delay_Detention : '' },
                { "Appointment": data.appointment ? data.appointment : '' },
                { "Primary Group By": data.primary_group_by ? data.primary_group_by : '' },
                { "Secondary Group By": data.secondary_group_by ? data.secondary_group_by : '' },
                { "Tertiary Group By": data.tertiary_group_by ? data.tertiary_group_by : '' },
                { "Trailer": data.trailer ? data.trailer : '' },
                { "Tractor": data.tractor ? data.tractor : '' },
                { "Authorization Number": data.authorization_number ? data.authorization_number : '' },
                { "Authorization Number": data.authorization_number ? data.authorization_number : '' },
                { "PO": data.po ? data.po : '' },
                { "Control": data.control ? data.control : '' },
                { "Late For Appointment (in minutes)": data.late_for_appt_min && data.late_for_appt_min !== '-1' ? data.late_for_appt_min : '' },
                { "Door Assigned To Bills Out Time (in minutes)": data.door_assigned_to_bill_out_min && data.door_assigned_to_bill_out_min !== '-1' ? data.door_assigned_to_bill_out_min : '' },
                { "Date Range Reported": (data.start_date && data.end_date) ? `${changeDateToGivenFormat(data.start_date, AppConstant.commonStrings.MM_DD_YYYY_dateFormat)} to ${changeDateToGivenFormat(data.end_date, AppConstant.commonStrings.MM_DD_YYYY_dateFormat)}` : '' },

            ]);
            setApiPayload(payload)
            dispatch({ type: sagaActions.INTIATE_LOADER_REPORTS, payload: true });
            dispatch({ type: sagaActions.GET_ADFAT_REPORT_LIST, payload: {...payload,pageNo:1,limit:PaginationDefaultLimit }})
            
            setShowModal(false)
        }
    };

    const submitbtnData = {
        show: true,
        text: AppConstant.reports.generateReportButtonTitle,
    };
    const cancelbtndata = {
        show: true,
        text: AppConstant.comments.resetbutton,
    };
    const createForm = () => {
        return (
            <CustomDynamicForm
                formFieldList={formFieldList}
                sort={true}
                cancelBtn={cancelbtndata}
                submitbtn={submitbtnData}
                themeSelected={themeSelected}
                getSummitedFormData={getSummitedFormData}
                getFormFieldData={getFormFieldData}
                editFormData={editData}
                setParticularFieldValue={particularFieldValue}
                loading={initateLoading}
            />
        );
    };

    const popupObj = {
        id: "adfatReportPopup",
        modalLabel: "adfatReportPopup",
        showClose: true,
        showfooter: true,
        showHeader: true,
        sendDriverNotification: false,
        modalSize: "xl",
        title: AppConstant.reports.adfatReport.createReport,
        bodyText: createForm(),
        subText: "",
        keyboard: false,
        backdrop: "static",
        footerBtn: {
            btn1: {
                show: true,
                text: AppConstant.comments.cancelCommentButtonTitle,
            },
            btn2: {
                show: true,
                text: AppConstant.commonStrings.view,
            },
        },
    };
      //Call Download Report API
  const downloadReport = (payload) => {
    dispatch({ type: sagaActions.DOWNLOAD_REPORT_COMMON, payload });
  };

      //Get Payload for Fetch Data of Report
      const getPayloadForDownload = (type) => {
        let payload = {
            subDomain:"adfat",
            downloadType:type,
            options:apiPayload
        }
        return payload
      }

       //Colums List for download column specific report
       const columnList=[
        {
            name:"Date",
            value:"TruckDate",
        },
        {
            name:"Dock",
            value:"Dock"
        },
        {
            name:"Door",
            value:"Door",
        },
        {
            name:"Carrier",
            value:"Carrier"
        },
        {
            name:"PO",
            value:"AllPOs"
        },
        {
            name:"Vendor",
            value:"Vendor"
        },
        {
            name:"Count",
            value:"LumpItems"
        },
        {
            name:"Cases",
            value:"LumpCases"
        },
        {
            name:"Start/End/ Chep",
            value:"StartPallets/EndPallets/StartChepPallets",
            multiColumn: true
        },
        {
            name:"Scheduled",
            value:"ScheduledTime"
        },
        {
            name:"Arrival",
            value:"ArrivalTime"
        },
        {
            name:"Check-In",
            value:"CheckInTime"
        },
        {
            name:"Door-Assign",
            value:"InDoorTime"
        },
        {
            name:"Unload-Start",
            value:"LumpStartTime"
        },
        {
            name:"Unload-End",
            value:"LumpEndTime"
        },
        {
            name:"Bills-Out",
            value:"BillsOutTime"
        },
        {
            name:"Unload",
            value:"LumpTime"
        },
        {
            name:"Paperwork",
            value:"PaperworkTime"
        },
        {
            name:"Detention",
            value:"AppointmenttoBillstoDrivertMinutes",
            isCustomValue: true
        },
        {
            name:"Active-Door",
            value:"ActiveDoorTime"
        },
    ]

    return (
        <div className="pt-2">

            <div className='container-fluid d-flex py-2 text-start justify-content-between'>
                <button
                    className={'addButton disabled'}
                    onClick={() => {
                        setStartDateState('')
                        setEndDateState('')
                        setShowModal(true)
                    }}
                >
                    {
                        AppConstant.reports.adfatReport.createReport
                    }
                </button>
                <div className='d-flex align-items-center'>
                    {/* <DownloadSection root={''} subRoot={''} name={'ADFAT'} themeSelected={themeSelected} currentTable={currentTable} currentTableRef={currentTableRef} /> */}
                    <DownloadSectionBECustomFeilds
            downloadData={getPayloadForDownload}
            isLoading={isDownloadLoading}
            isDisabled={isDownloadLoading}
            columnList={columnList}
            downloadReport={downloadReport}
          />
                </div>
            </div>

            <div className="roleListTable mt-1">
                <Popup
                    {...popupObj}
                    showModal={showModal}
                    showfooter={false}
                    closepopup={closepopup}
                    themeSelected={themeSelected}
                />
            </div>

            <ADFATReportList {...props} divRef={divRef} showModal={showModal} setShowModal={setShowModal} apiParams={apiParams} apiPayload={apiPayload}/>
        </div>
    );
}

export default ADFATwrapper;