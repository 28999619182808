import { createSlice } from '@reduxjs/toolkit';
import { getCurrentDate } from '../../../Utils/DateUtils';

const initialState = {
    selectedDate: getCurrentDate(),
}

export const DateSelectorReducer = createSlice({
    name: 'dateSelectorReducer',
    initialState,
    reducers: {
        storeSelectedDate: (state, action) => {
            state.selectedDate = action.payload.selectedDate;
        },
    }
});

export const { storeSelectedDate } = DateSelectorReducer.actions;

// Selectors
export const getSelectedDateSelector = (state) => {
    return state.dateSelectorReducer;
};

export default DateSelectorReducer.reducer;
