import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppConstant } from "../../../../../../../Assests/AppConstant";
import { random } from "lodash-es";
import { CustomDynamicForm } from "../../../../../../Common/CustomForm/CustomDynamicForm";
import { sagaActions } from "../../../../../../../../sagas/sagaActions";
// import { getAuthReducer } from "../../../../../../../../redux/reducers/reducerSlices/AuthReducer";
import { getUserSelector } from "../../../../../../../../redux/reducers/reducerSlices/UserReducer";
import Popup from "../../../../../../Common/Popup/Popup";
import FaqList from '../FaqList/FaqList';
import { showAlert } from "../../../../../../../Assests/Utility";
import { toast } from 'react-toastify';
import './FaqAdminScreen.css'

const FaqAdminScreen = (props) => {

    const dispatch = useDispatch();
    const { themeSelected } = props
    const [allowPermission, setAllowPermission] = useState(false);
    const [resetFormData, setResetFormData] = useState(0);
    const [loading, setLoading] = useState(false);
    const [editData, setEditData] = useState({});
    // const user = useSelector(getAuthReducer);
    const user = useSelector(getUserSelector);
    const { cu_id } = user;
    const [showModal,setShowModal]=useState(false);
    const closepopup = () => {
       setEditData({});
       setShowModal(false);
     }
    const cancelbtndata = {
        show: true,
        text: AppConstant.comments.resetbutton,
    };

    const submitbtnData = {
        show: true,
        text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm
            .saveButtonTitle,
    };

    const getSummitedFormData = (faqObj) => {
        if (cu_id) {
            const apiParams = {
                // "CUID": user?.auth?.username,
                "CUID": cu_id,
                "QuestionText": faqObj.QuestionText,
                "AnswerText": faqObj.AnswerText,
                "AppType":faqObj.AppType
            }
            if (faqObj.HelpFeedbackID) {
                apiParams.EnableStatus = faqObj.EnableStatus;
                apiParams.CRUDTYPE = 'U';
                apiParams.HelpFeedbackID = faqObj.HelpFeedbackID;
                apiParams.AppType = faqObj.AppType;
            } else {
                apiParams.EnableStatus = null;
                apiParams.CRUDTYPE = 'I';
                apiParams.HelpFeedbackID = null;
                apiParams.UpdatedBy = null;
                apiParams.CreatedBy = null;
            }
            dispatch({ type: sagaActions.GET_FAQ_LIST, payload: apiParams });
          
            closepopup();
        } else {
            showAlert(toast.TYPE.ERROR, AppConstant.helpSupportFaq.alerts.cuIdError, 'light')
        }

    };

    const formFieldList = [
        {
            name: "AppType",
            type: "radio",
            size: "col-sm-12 mt-2 radioButtonText",
            position: 0,
            title: 'Select for:',
            formValidation: {
                required: true,
            },
            radiconfig: [{
                label: AppConstant.helpSupportFaq.appTypeText1.key,
                value: AppConstant.helpSupportFaq.appTypeText1.value
            },
            {
                label: AppConstant.helpSupportFaq.appTypeText2.key,
                value: AppConstant.helpSupportFaq.appTypeText2.value,
            }]

        },
        {
            name: "QuestionText",
            type: "textarea",
            size: "col-sm-12",
            position: 1,
            title: AppConstant.helpSupportFaq.questionStrTitle,
            formValidation: {
                required: true,
            },
        },
        {
            name: "AnswerText",
            type: "textarea",
            size: "col-sm-12",
            position: 1,
            title: AppConstant.helpSupportFaq.answerStrTitle,
            formValidation: {
                required: true,
            },
        },
    ]

    const editFaq = (faqObj) => {
        if (cu_id) {
            const editData1 = {
                "CUID": cu_id,
                "EnableStatus": faqObj.EnableStatus,
                "CRUDTYPE": "U",
                "QuestionText": faqObj.QuestionText,
                "AnswerText": faqObj.AnswerText,
                "HelpFeedbackID": faqObj.HelpFeedbackID,
                "AppType":faqObj.AppType
            }
            setShowModal(true);
            setEditData(editData1);
        } else {
            showAlert(toast.TYPE.ERROR, AppConstant.helpSupportFaq.alerts.cuIdError, 'light')
        }

    }

    const createForm = () => {
        return (
            <CustomDynamicForm
                formFieldList={formFieldList}
                sort={true}
                cancelBtn={cancelbtndata}
                submitbtn={submitbtnData}
                themeSelected={themeSelected}
                getSummitedFormData={getSummitedFormData}
                loading={loading}
                editFormData={editData}
                resetFormData={resetFormData}
            />
        );
    };

    const showCreateFaqPopup = () => {
        const popupObj = {
            id: 'createFaqFormPopup1',
            modalLabel: 'createFaqFormPopup1',
            showClose: true,
            showfooter: false,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'lg',
            title: Object.keys(editData).length ? AppConstant.helpSupportFaq.editFaqButtonTitle : AppConstant.helpSupportFaq.creatFaqButtonTitle,
            bodyText: createForm(),
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.comments.cancelCommentButtonTitle
                },
                btn2: {
                    show: true,
                    text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.saveButtonTitle
                }
            },
        }

        return <Popup {...popupObj} popupBtnHandler={(text) => () => ''} closepopup={closepopup} themeSelected={themeSelected}  showModal={showModal}/>
    }

    return (
        <div>
            <div className='d-flex m-2 mt-4'>
                <div
                    className={`buttonHelpSupport selectedButton mx-2`}
                    data-bs-toggle="modal"
                    data-bs-target={`#createFaqFormPopup1`}
                    onClick={()=> setShowModal(true)}
                >{AppConstant.helpSupportFaq.creatFaqButtonTitle}</div>
            </div>
            {showCreateFaqPopup()}
            <div className="faqTableContainer m-2 mx-3 px-0">
                <FaqList editFaqAction={(faqObj) => editFaq(faqObj)} />
            </div>
        </div>
    )
}

export default FaqAdminScreen;