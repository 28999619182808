import React from 'react';
import { AppConstant } from '../../../Assests/AppConstant';
import './RememberMe.css'

const RememberMe = (props) => {
    const {onChangeText, isChecked} = props
    return (
        <div className='d-flex align-items-center'>
            <input data-testid={AppConstant.dataTestId.rememberMeCheckBox} onChange={(e) => onChangeText(e)}
            className='checkBox check-box-input' defaultChecked={isChecked} type="checkbox" aria-label="Checkbox for following text input" />
            <span data-testid={AppConstant.dataTestId.rememberText}
                className='rememberMeText'>{AppConstant.login.rememberMe}</span>
        </div>
    )
}

export default RememberMe
