import { call, put, takeEvery } from 'redux-saga/effects';
import { updateAutofetchDefDashboardInterval, updateAutoFetchExecutorInterval, updateDoorAssignedTimer, updateAutofetchCustomDashboardInterval, updateAutoFetchNotificatioInterval, updateAllConfigration } from '../redux/reducers/reducerSlices/RemoteConfigReducer';
import { sagaActions } from './sagaActions';
import { Uchain } from '../redux/api/agent';
import { formatConfigData } from '../App/Assests/Utility';

function* updateExecutorInterval(data){
    try{
        yield put(updateAutoFetchExecutorInterval(data.payload));
    }catch{
    }
}

function* updateDefDashboardInterval(data){
    try{
        yield put(updateAutofetchDefDashboardInterval(data.payload));
    }catch{
    }
}

function* updateCustomDashboardInterval(data){
    try{
        yield put(updateAutofetchCustomDashboardInterval(data.payload));
    }catch{
    }
}

function* updateDoorAssignedTimerSaga(data){
    try{
        yield put(updateDoorAssignedTimer(data.payload));
    }catch{
    }
}

function* updateNotificationsTimerSaga(data){
    try{
        yield put(updateAutoFetchNotificatioInterval(data.payload));
    }catch{
    }
}
function* getConfigrationSaga(){
    try {
        const response = yield call(Uchain.getConfigration);
        yield put(updateAllConfigration(formatConfigData(response)));
    } catch {
        yield put(updateAllConfigration({}));
    }
}

export function* getExecutorInterval() {
    yield takeEvery(sagaActions.GET_AUTO_FETCH_EXECUTOR_INTERVAL, updateExecutorInterval)
}

export function* getDefDashboardInterval() {
    yield takeEvery(sagaActions.GET_AUTO_FETCH_DEF_DASHBOARD_INTERVAL, updateDefDashboardInterval)
}

export function* getCustomDashboardInterval() {
    yield takeEvery(sagaActions.GET_AUTO_FETCH_CUSTOM_DASHBOARD_INTERVAL, updateCustomDashboardInterval)
}

export function* getDoorAssignedTimer() {
    yield takeEvery(sagaActions.GET_DOOR_ASSIGNED_TIMER, updateDoorAssignedTimerSaga)
}

export function* updateNotificationsTimerWatcher() {
    yield takeEvery(sagaActions.GET_AUTO_FETCH_NOTIFICATION_INTERVAL, updateNotificationsTimerSaga)
}

export function* getConfigrationWatcher() {
    yield takeEvery(sagaActions.GET_CONFIGRATION, getConfigrationSaga)
}