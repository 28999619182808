import React from 'react'
import { AppConstant } from '../../../Assests/AppConstant'
import CustomTooltip from '../Tooltip/CustomTooltip'
/**
 * This component renders reusable button with new UI
 * @param {*} props 
 * title = Button title
 * className = user defined class
 * isLoading = Button Loader
 * disabled = Disable button
 * loadingTitle = AppConstant.commonStrings.loading
 * dataBsToggle = Bootstarp props
 * dataBsTarget = Bootstarp props
 * isIcon = show icon
 * iconClass = user defined icon class
 * isToolTip=false,
 * dataTip = tooltip props
 * dataFor = tooltip props
 * @returns 
 */
const CustomButtonSS = (props) => {
    const { title, className, isLoading, disabled, loadingTitle = AppConstant.commonStrings.loading, dataBsToggle, dataBsTarget, isIcon, iconClass,isToolTip=false,dataTip,dataFor,themeSelected='light-mode' } = props
    return (
        <>
        <button type='button' data-testid={AppConstant.dataTestId.customButton} onClick={() => props.onClick()} className={className} disabled={isLoading || disabled} data-bs-toggle={dataBsToggle ? dataBsToggle : ''}
            data-bs-target={dataBsTarget ? dataBsTarget : ''}  data-tip={isToolTip&&dataTip}
            data-for={isToolTip&&dataFor}>
            {
                isLoading &&
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" data-testid={AppConstant.dataTestId.loading}
                />
            }

            {isLoading ? loadingTitle : isIcon ? <><span className='font-default'> <i className={iconClass}></i> </span>{title}</> : title}
        </button>

        {isToolTip &&<CustomTooltip
              id={dataTip}
              position="top"
              type={
                themeSelected ===
                AppConstant.commonStrings.lightModeFilterString
                  ? "light"
                  : "dark"
              }
                multiline={false}
                data-testid={AppConstant.dataTestId.commonTooltip}
            />}
        </>
    )
}

export default CustomButtonSS
