import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    commentsList : [],
    isCommentUploaded : true
}

export const CommentsReducerSlice = createSlice({
    name: 'CommentsReducer',
  initialState,
  reducers : {
    getAllCommentsReducer : (state, action) => {
      state.commentsList = action.payload;
    },
    addCommentsReducer : (state, action) => {
      state.commentsList = [action.payload, ...state.commentsList];
      state.isCommentUploaded = true
    },
    addCommentFailure : (state, action) => {
      state.isCommentUploaded = false;
    },
    clearAllComments : (state, action) => {
      state.commentsList = [];
      state.isCommentUploaded = true
    }
  }
});

export const { getAllCommentsReducer, addCommentsReducer, addCommentFailure, clearAllComments } = CommentsReducerSlice.actions;

// Selectors
export const getCommentsListSelector = (state) => {
  return state.commentsReducer.commentsList;
};

export const addCommentFailureSelector = (state) => {
  return state.commentsReducer.isCommentUploaded;
};


export default CommentsReducerSlice.reducer;