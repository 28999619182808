import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";


import './MySubscriptionsSS.css';
import { sagaActions } from "../../../../../../sagas/sagaActions";
import { AppConstant } from "../../../../../Assests/AppConstant";
import SSPopup from "../../../../Common/Popup/SSPopup/SSPopup";
import ScheduleProWrapper from "../../ScheduleProWrapper";
import CustomButtonSS from "../../../../Common/CustomButton/CustomButtonSS";

import { toast } from "react-toastify";
import { paytraceReducer } from "../../../../../../redux/reducers/reducerSlices/PaytraceReducer";
import { getUserSelector } from "../../../../../../redux/reducers/reducerSlices/UserReducer";
import { showAlert } from "../../../../../Assests/Utility";
import { interceptor, removeInterceptor } from "../../../../../../redux/api/Interceptor";
import UserPaymentsListSS from "./UserPaymentsList/UserPaymentsListSS";
import RenewSubscriptionComponentSS from "../../../../Login/RenewCarrierSubscriptions/RenewSubscriptionComponent/RenewSubscriptionComponentSS";
import { darkStyles, lightStyles } from "../../../../Login/SignUpCarrier/FormCarrierSignUp/CreditCardFrameStyles/CreditCardFrameStyles";
import PaymentCardsImage from '../../../../../Assests/Images/paymentCardsAccepted.png';
import CustomTooltip from "../../../../Common/Tooltip/CustomTooltip";
import { getAuthReducer } from "../../../../../../redux/reducers/reducerSlices/AuthReducer";
import { isNil } from "lodash-es";
import DrilldownTableSS from "../../../../LandingPage/Components/Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTableSS";
import DownloadSectionSS from "../../../../LandingPage/Components/DownloadSection/DownloadSectionSS";
import _ from 'lodash'
import { usePrevious } from "../../../../Common/CustomHooks/usePrevious";

/** This component renders new UI subscription in table view */
const MySubscriptionsSS = (props) => {

    const { themeSelected = 'light-mode', showNavBar = true } = props;
    const dispatch = useDispatch();
    const [editData, setEditData] = useState({});
    const [searchStr, setSearchStr] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showSecondPopupModal, setShowSecondPopupModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { cancelSubscriptionObj, mySubscriptionList, paymentLoading, updateCardDetailsObj } = useSelector(paytraceReducer);
    const userObj = useSelector(getUserSelector);
    const { cu_id } = userObj;
    const [userSubsObj, setUserSubsObj] = useState({});
    const navigate = useNavigate();
    const location = useLocation();
    const [showRenew, setShowRenew] = useState(false);
    const [showConfirmRenewModal, setShowConfirmRenewModal] = useState(false)
    //card change
    const [showIFrame, setShowIFrame] = useState(false);
    const [isPaymentLoading, setIsPaymentLoading] = useState(false);
    const [showIFrameLoader, setShowIFrameLoader] = useState(true);
    const [showPaymentSuccessView, setShowPaymentSuccessView] = useState(false);

    const PTPayment = window.PTPayment;
    const [paymentInstance, setInstance] = useState(null);
    const [iFrameValidationErrors, setIframValidationErrors] = useState([]);
    const { paytraceClientId, paymentResponse } = useSelector(paytraceReducer);
    const [styles, setStyles] = useState(themeSelected === 'dark-mode' ? darkStyles : lightStyles);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const user = useSelector(getAuthReducer);
    //card change end
    const [selectedButton, setSelectedButton] = useState('subscriptionList')
    const [currentTable, setCurrentTable] = useState(null);
    const [currentTableRef, setCurrentTableRef] = useState(null);
    const [userPaymentsArr, setUserPaymentsArr] = useState([]);
    const divRef = useRef(null);
    const previousTable = usePrevious(currentTable);
    /** Initial useEffect */
    useEffect(() => {
        const container = divRef.current;
        const tables = container.querySelectorAll('table');
        if (tables && tables.length && _.isEqual(currentTable, previousTable)) {
            setCurrentTableRef(container);
            setCurrentTable(tables);
        }
    })
    /**
     * useEffect to render mySubscriptionList and set mySubscriptionList to local variable
     */
    useEffect(() => {
        if (mySubscriptionList && mySubscriptionList.SubscriptionList && mySubscriptionList.SubscriptionList.length) {
            setUserPaymentsArr(mySubscriptionList.SubscriptionList)
        } else {
            setUserPaymentsArr([])
        }
    }, [mySubscriptionList])
    /**
     * Subscription list data title header array
     */
    const userPaymentsTitleDD = [
        { id: '', name: 'Serial No.' },
        { id: '', name: 'Subscription ID' },
        { id: '', name: 'Account No.' },
        { id: '', name: 'Payment Mode' },
        { id: '', name: 'Card No.' },
        { id: '', name: 'Reference No.' },
        { id: '', name: 'Payment Status' },
        { id: '', name: 'Amount ($)' },
        { id: '', name: 'Subscription Start Date' },
        { id: '', name: 'Subscription End Date' },
        { id: '', name: 'Transaction Type' },
        { id: '', name: <span>Transaction<br /> Date & Time</span> },
    ]
    /**
     * format drilldown list data to render in table
     * Reusable component DrilldownTable needs data always in this format
     * @param {*} data 
     * @returns 
     */
    const formatListDataDrillDown = (data) => {

        return data.map((el, index) => {
            return {
                id: index,
                0: index + 1,
                1: el.IDSubs,
                2: el.AccountNumber,
                3: el.PaymentMode,
                4: el.CardNumber,
                5: el.PaymentRefernceNumber,
                6: el.PaymentStatus,
                7: el.Amount,
                8: el.SubsStart,
                9: el.SubsEnd,
                abc: el.TransType || '-',
                date: el.PaymentDate
            }
        })

    }
    /**
     * useEffect for update card details
     */
    useEffect(() => {
        setShowIFrame(false)
    }, [updateCardDetailsObj])

     /**
     * initial useEffect
     */
    useEffect(() => {
        dispatch({ type: sagaActions.RESET_CANCEL_SUBCRIPTION_OBJ });
    }, [])
    /**
     * useEffect for render my subscription list data
     */
    useEffect(() => {
        if (mySubscriptionList && mySubscriptionList.subscriptionData && Object.keys(mySubscriptionList.subscriptionData).length) {
            setUserSubsObj(mySubscriptionList.subscriptionData)
        } else {
            setUserSubsObj([])
        }
    }, [mySubscriptionList])
    /** useEffect for cancel Subscription object */
    useEffect(() => {
        if (Object.keys(cancelSubscriptionObj).length) {

            setIsLoading(false);
            if (!cancelSubscription.Status) {
                showAlert(toast.TYPE.SUCCESS, AppConstant.smartScheduler.subscription.cancelledSubscriptionSuccessfull, themeSelected);
                removeInterceptor()
                dispatch({ type: sagaActions.CLEAR_SIGNEDIN_USER })
                dispatch({ type: sagaActions.RESET_USER })
                dispatch({ type: sagaActions.RESET_CANCEL_SUBCRIPTION_OBJ })
                dispatch({ type: sagaActions.RESET_PAYTRACE_DATA });
                window.localStorage.clear();
                navigate("/")
                // If logging out from UserWarehouse Page then trigger branding API again
                if (location.pathname === '/') {
                    dispatch({ type: sagaActions.INITIATE_BRANDING_LOADER, payload: true })
                    const subdomain = window.location.hostname.split(".")[0];
                    dispatch({
                        type: sagaActions.INITIATE_BRANDING, payload: {
                            subdomain: subdomain
                        }
                    });
                }
            }
        }
    }, [cancelSubscriptionObj])

    /** Cancel subscription modal handler */
    const cancelSubscription = () => {
        setShowModal(true);
    }
    /** Returns Cancel subscription JSX */
    const cancelSubscriptionBody = () => {
        return (
            <div>{AppConstant.smartScheduler.subscription.cancelSubMessage1}</div>
        );
    };
     /** Cancel subscription handler */
    const cancelSubscriptionFunc = () => {
        setIsLoading(false);
        setShowModal(false);
        setShowSecondPopupModal(true);
    }
    /**
     * Cancel subscription confirm popup handler
     * @param {*} text 
     */
    const popupBtnActionHnadler = (text) => {
        if (text === 'No') {
            setShowModal(false)
            setShowSecondPopupModal(false)
        } else {
            cancelSubscriptionFunc()
        }
    }
    /**
     * 
     * @returns Cancel subscription popup object
     */
    const showCancelSubscriptionSecondPopup = () => {
        const popupObj = {
            id: 'createCompanyGroupFormPopup1',
            modalLabel: 'createCompanyGroupFormPopup1',
            showClose: true,
            showfooter: true,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'lg',
            alignType: 'center',
            type: 'info',
            title: 'Are you sure you want to cancel your subscription ?',
            bodyText: <div>{AppConstant.smartScheduler.subscription.popupBodyText}
                <br />{AppConstant.smartScheduler.subscription.popupBodyText2}</div>,
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.commonStrings.no
                },
                btn2: {
                    show: true,
                    text: AppConstant.commonStrings.yes
                }
            },
        }
        return <SSPopup {...popupObj} popupBtnHandler={(text) => popupSecBtnActionHandler(text)} closepopup={closeCurrentpopup} themeSelected={themeSelected} showModal={showSecondPopupModal} />
    }
    /**
     * Cancel subscription confirm popup handler
     * @param {*} text 
     */
    const popupSecBtnActionHandler = (btnStr) => {
        if (btnStr === AppConstant.commonStrings.yes) {
            setIsLoading(true);
            setShowModal(false);
            setShowSecondPopupModal(false);
            dispatch({ type: sagaActions.CANCEL_SUBSCRIPTION_ACTION, payload: { "IDCarrier": cu_id } });
        } else {
            closeCurrentpopup()
        }
    }

    /**
     * 
     * @returns Cancel subscription popup obj with new UI SSPopup component
     */
    const showCancelSubscriptionPopup = () => {
        const popupObj = {
            id: 'createCompanyGroupFormPopup1',
            modalLabel: 'createCompanyGroupFormPopup1',
            showClose: true,
            showfooter: true,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'lg',
            alignType: AppConstant.commonStrings.center,
            type: AppConstant.commonStrings.info,
            title: AppConstant.smartScheduler.subscription.cancleSubscription,
            bodyText: cancelSubscriptionBody(),
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.commonStrings.no
                },
                btn2: {
                    show: true,
                    text: AppConstant.commonStrings.yes
                }
            },
        }
        return <SSPopup {...popupObj} popupBtnHandler={(text) => popupBtnActionHnadler(text)} closepopup={closeCurrentpopup} themeSelected={themeSelected} showModal={showModal} />
    }
    /**
     * Close popup handler
     */
    const closeCurrentpopup = () => {
        setEditData({});
        setShowModal(false);
        setShowSecondPopupModal(false);
    }
    /**
     * 
     * @returns retunr number of days left for subscription expiry
     */
    const calRemainingDays = () => {
        if (parseInt(userSubsObj.SubDays) > 0) {
            return <div>{`${AppConstant.smartScheduler.subscription.daysRemainingMessageNew} ${userSubsObj.SubDays} days`}</div>
        } else {
            return <div>{`${AppConstant.smartScheduler.subscription.expiredPlanMessage}`}</div>
        }
    }
    /** Close confirm renew popup */
    const closeConfirmRenewpopup = () => {
        setShowConfirmRenewModal(false)
    }
     /** confirm renew popup button handler */
    const popUpRenewHandler = (text) => {
        if (text === AppConstant.commonStrings.yes) {
            setShowRenew(true);
            setShowConfirmRenewModal(false)
        }
        else {
            setShowConfirmRenewModal(false)
        }
    }
     /**
     * 
     * @returns Cancel subscription confirmation popup new UI
     */
    const showCancelConfirmationPopup = () => {
        const popupObj = {
            id: 'createCompanyGroupFormPopup1',
            modalLabel: 'createCompanyGroupFormPopup1',
            showClose: true,
            showfooter: true,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title: '',
            bodyText: `${AppConstant.commonStrings.areYouSure + ' ' + AppConstant.commonStrings.renew} ?`,
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.commonStrings.yes
                },
                btn2: {
                    show: true,
                    text: AppConstant.commonStrings.no
                }
            },
        }

        return <SSPopup {...popupObj} popupBtnHandler={(text) => popUpRenewHandler(text)} closepopup={() => closeConfirmRenewpopup()} themeSelected={themeSelected} showModal={showConfirmRenewModal} />
    }

    // card change


    /**
     * This function initializes paytrace with client key
     */
    const paytraceUIForm = () => {
        PTPayment.setup({
            styles,
            authorization: { clientKey: paytraceClientId }
        }).then((instance) => {
            setShowIFrameLoader(false);
            PTPayment.getControl("creditCard").label.text(AppConstant.smartScheduler.carrierSignUp.creditCardForm.cardNumber);
            setInstance(instance);
        })
            .catch((err) => {
                setShowIFrameLoader(false);
                showAlert(toast.TYPE.ERROR, AppConstant.smartScheduler.carrierSignUp.paytrace.initPaytraceError + err.toString(), 'light')
            });
    }

    /**
     * useEffect to set sessionToken
     */
    useEffect(() => {
        if (!isNil(user.auth) && user.sessionToken) {
            removeInterceptor();
            interceptor(user.sessionToken);
        }
    }, [user])

    /**
     * function to handle paytrace payment API
     */
    const hitPaymentApi = () => {
        if (paymentInstance) {
            paymentInstance.process()
                .then((resPaytrace) => {
                    const updateCardApiParams = {
                        "hpf_token": resPaytrace.message.hpf_token,
                        "enc_key": resPaytrace.message.enc_key,
                        "customer_id": user.auth.username,
                        "email": user.user_name
                    }
                    dispatch({ type: sagaActions.UPDATE_CARD_DETAILS, payload: updateCardApiParams });
                    setIsPaymentLoading(false)
                })
                .catch((err) => {
                    setIsPaymentLoading(false)
                    const errorStr = `${AppConstant.smartScheduler.carrierSignUp.paytrace.paymentFailureMessage}${err}`
                    showAlert(toast.TYPE.ERROR, errorStr, 'light')
                });
        }
    }
    /**
     * function to handle payment
     */
    const handlePayment = async () => {
        setIframValidationErrors([])
        PTPayment.validate(function (validationErrors) {
            if (validationErrors.length >= 1) {
                setIframValidationErrors(validationErrors)
                validationErrors.map((ele, index) => {
                    const errorStr = `${AppConstant.smartScheduler.carrierSignUp.paytrace.cardValidationError} ${ele.description}`;
                })
            } else {
                setIframValidationErrors([])
                setIsPaymentLoading(true);
                hitPaymentApi()
            }
        });
    }
    /**
     * 
     * @returns JSX to static signup text
     */
    const showCancelSignUpText = () => {
        const arr = ['Easy, Accurate & detailed Scheduling Appointment Calendar',
            'Integrated Warehouse & Carrier Calendar Schedules',
            'Bulk Purchase Order Upload Template or Manual PO Scheduling',
            'Appointment Management & Views of upcoming & past appointments',
            'Real-time email confirmation of Appointments for scheduled, rescheduled, or Deleted'
        ]

        return (
            arr.map((ele, i) => {
                return (
                    <li>{ele}</li>
                )
            })
        )
    }
     /**
     * 
     * @returns iframe error JSX
     */
    const showValidationErros = () => {
        return iFrameValidationErrors.map((ele, index) => {
            return (<div className='text-danger iFrameErrorText'>&bull; {ele.description} </div>)
        })
    }
    /**
     * 
     * @returns iframe Loader
     */
    const showIFrameLoaderUI = () => {
        return (<div className='placeholder-glow'>
            <span className="placeholder mt-3 col-12" />
            <span className="placeholder mt-2 col-6" />
            <span className="placeholder mt-2 col-5 ms-5" /> </div>
        )
    }
    /**
     * useEffect for paytraceClientId and showIFrame=true then display paytrace UI Form
     */
    useEffect(() => {
        if (paytraceClientId && showIFrame) {
            paytraceUIForm();
        }
    }, [paytraceClientId, showIFrame])

    /**
     * useEffect to get paytrace client id
     */
    useEffect(() => {
        if (showIFrame) {
            dispatch({ type: sagaActions.GET_PAYTRACE_CLIENT_ID })
        }
    }, [showIFrame])

    const showTilesList = () => {
        return (

            <div
                className="d-flex justify-content-start"
                style={{ borderRadius: "10px" }}
            >
                <button
                    onClick={() => { setSelectedButton('subscriptionList') }}
                    className={selectedButton === 'subscriptionList' ? "selected-button" : "unselected-button"}>
                    {'Subscriptions'}
                </button>
                <button
                    onClick={() => { setSelectedButton('paymentList') }}
                    className={selectedButton === 'paymentList' ? "selected-button" : "unselected-button"}>
                    {'Payments'}
                </button>
            </div>
        )
    }

    const filterPaymentList = (data) => {
        let newList = data?.filter((ele) => ele.CancledOn === null)
        return newList
    }

    return (
        <ScheduleProWrapper showAppointments={false} showNavBar={showNavBar}>
            <div className="my-subscription-ss-wrapper p-3">
                {/* <Header {...props} /> */}
                {/* <Navigationbar {...props} /> */}
                <div className="d-flex flex-column">
                    {showTilesList()}
                    <div className="d-flex my-2 SStextSub w-100 justify-content-between f-12">
                        <div className="">
                            <div className="subscriptions-ss-heading my-2 f-16">{selectedButton === 'subscriptionList' ? 'Subscriptions' : 'Payments'}</div>
                            {selectedButton === 'subscriptionList' &&
                                userSubsObj && Object.keys(userSubsObj).length && calRemainingDays()
                            }
                        </div>
                        <div className=" d-flex gap-2">

                            <DownloadSectionSS singlePageDownload={true} root={''} subRoot={''} name={'Payment Report'} themeSelected={themeSelected} currentTable={currentTable} currentTableRef={currentTableRef} />
                            
                            <CustomButtonSS
                                isLoading={isLoading}
                                title={"Change Card"}
                                className={`btn-highlighted mx-1`}
                                onClick={() => { setShowIFrame(true) }}
                            />
                            {((mySubscriptionList?.subscriptionData?.SubDays <= 0 || mySubscriptionList?.subscriptionData?.CancledOn != null) && !showRenew) &&
                                <CustomButtonSS
                                    isLoading={isLoading}
                                    title={AppConstant.commonStrings.renew}
                                    className={`cancel-subscriptions-ss ms-1`}
                                    onClick={() => mySubscriptionList?.subscriptionData?.SubDays > 0 ? setShowConfirmRenewModal(true) : setShowRenew(true)}
                                />}
                            {showRenew &&
                                <CustomButtonSS
                                    isLoading={isLoading}
                                    title={'Back'}
                                    className={`cancel-subscriptions-ss ms-1`}
                                    onClick={() => setShowRenew(false)}
                                />}
                            {
                                (mySubscriptionList?.subscriptionData?.SubDays > 0 && mySubscriptionList?.subscriptionData?.CancledOn === null) &&
                                <CustomButtonSS
                                    isLoading={isLoading}
                                    title={`${AppConstant.smartScheduler.subscription.cancleSubscription}`}
                                    className={`cancel-subscriptions-ss`}
                                    onClick={() => cancelSubscription()}
                                />
                            }
                            { }

                        </div>
                    </div>
                    {showCancelSubscriptionPopup()}
                    {showCancelSubscriptionSecondPopup()}
                    {showCancelConfirmationPopup()}
                    {!showIFrame ? showRenew ?
                        <RenewSubscriptionComponentSS /> :
                        <UserPaymentsListSS {...props} searchStr={searchStr} themeSelected={themeSelected} selectedTab={selectedButton} />
                        :
                        <div className='d-flex flex-column p-1'>

                            <div>
                                {!isPaymentLoading ?
                                <>
                                <svg onClick={() => setShowIFrame(false)} className='bg-transparent mb-4 cursor-pointer' id="i-arrow-left" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 32 32" width="25" height="25" fill="none"
                                    stroke="currentcolor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                                    <path d="M10 6 L2 16 10 26 M2 16 L30 16" />
                                </svg> <span className="fw-bold mx-3">Current Card:</span> {userPaymentsArr[userPaymentsArr.length-1].CardNumber}
                                </>
                                : ''}
                                {isPaymentLoading && <div className='my-2 fs-6 text-danger'>{AppConstant.smartScheduler.carrierSignUp.paytrace.updateCardText}</div>}
                                <div className='paytraceIFrame d-flex justify-content-center flex-column change-card-container'>
                                    {!paymentLoading ? <div id="pt_hpf_form" className='ms-1 p-0 mb-2' /> : <div>{AppConstant.smartScheduler.carrierSignUp.paytrace.updateCardText}</div>}
                                    {showIFrameLoader ? showIFrameLoaderUI() : ''}
                                    <div className='d-flex justify-content-between'>
                                        <div>
                                            {iFrameValidationErrors.length ? showValidationErros() : ''}
                                        </div>
                                        <div className='container-fluid d-flex justify-content-between m-0 p-0 mt-3 pe-4 '>
                                            <div>
                                                <img src={PaymentCardsImage} alt="Cards" className='cardsImage' />
                                            </div>
                                            <span data-tip={"Security Code"} data-for='SecurityCode'>
                                                <img src={AppConstant.commonStrings.cscReferenceImageLink} alt="Cards" className='cardsCVVImage' />
                                                <CustomTooltip id='SecurityCode' position='top' type='light' multiline={true} html={true} />
                                            </span>
                                            <div>
                                                <CustomButtonSS
                                                    type="submit"
                                                    className={`cancelPaymentButton me-4 iFrameButtons ${isPaymentLoading ? 'disabled' : ''}`}
                                                    data-bs-toggle="modal"
                                                    onClick={() => !isPaymentLoading && setShowIFrame(false)}
                                                    title={AppConstant.commonStrings.cancel}>
                                                </CustomButtonSS>
                                                <CustomButtonSS
                                                    type="submit"
                                                    isLoading={isPaymentLoading}
                                                    className={`btn-submit iFrameButtons color-white ${isPaymentLoading ? 'disabled' : ''}`}
                                                    onClick={() => !isPaymentLoading && handlePayment()}
                                                    title={AppConstant.commonStrings.save}></CustomButtonSS>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {showCancelConfirmationPopup()}

                        </div>
                    }
                </div>
                
                    <div className="hidden" ref={divRef}>
                        <DrilldownTableSS
                            themeSelected={themeSelected}
                            bodyData={formatListDataDrillDown(filterPaymentList(userPaymentsArr))}
                            titleData={userPaymentsTitleDD}
                            showCollapse={false}
                            showInput={false}
                            showSort={true}
                            hasCollapsableContent={false}
                            arrangeOrder={'arrangeOrder'}
                            arrangeKey={'arrangeKey'}
                            drillDownReport={filterPaymentList(userPaymentsArr)}
                            initiateDrillDownReport={false}
                            loaderArray={8}
                            sortingAction={''}
                            subRoot={{ pathName: '' }}
                            showUTCTime={false}
                            id={'paymentReport'}
                        />
                    </div>
            </div>
        </ScheduleProWrapper>
    )
}

export default MySubscriptionsSS;