import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom'; 
import _ from 'lodash-es';
import { getUserSelector } from '../../../../../../redux/reducers/reducerSlices/UserReducer';
import GloabalModal from '../../../components/Modal/GloabalModal';
import LeftInfoWithFormContainer from '../../../Container/LeftInfoWithFormContainer/LeftInfoWithFormContainer';
import CreditCardForm from './PaymentMethodForms/CreditCardForm';
import { AppConstant } from '../../../../../Assests/AppConstant';

const PaymentMethodContainer = (propsArgs) => {
    const { themeSelected } = propsArgs;
    const userPermission = useSelector(getUserSelector);
    const { branding } = userPermission;
    const location = useLocation();
    const navigate = useNavigate();
    const [warehouseLogo, setWarehouseLogo] = useState('');
    const [bgImage, setBgImage] = useState('');
    const dataLocation = location.state;
    const [paymentMethod, setPaymentMethod] = useState('')

    useEffect (() => {
        if(dataLocation && dataLocation.props){
            setPaymentMethod(dataLocation.props.payment_method)
        }
    }, [location])

    useEffect(() => {
        if (!_.isEmpty(branding)) {
            const { IsBranding, WarehouseLogo, BackgroundImage } = branding
            if (IsBranding) {
                setBgImage(BackgroundImage)
                setWarehouseLogo(WarehouseLogo)
            }
        }
    }, [branding])

    const getCurrentPaymentMethodForm = () => {
        if (paymentMethod) {
            switch (paymentMethod) {
                case 'Credit Card':
                    return <CreditCardForm />
                case 'Comdata Fuel Card':
                    return <div>Comdata Fuel Card</div>
                case 'Comdata Express Code':
                    return <div>Comdata Express Code</div>
                case 'EFX / WEX Money Code':
                    return <div>EFX / WEX Money Code</div>
                case 'EFX / WEX Fuel Card':
                    return <div>EFX / WEX Fuel Card</div>
                case 'Relay':
                    return <div>Relay</div>
                case 'Insta Money':
                    return <div>Insta Money</div>
                case 'US Bank':
                    return <div>US Bank</div>
                default:
                    return <div>No Data Found</div>
            }
        } else {
            return <div>No Data Found</div>
        }
    }

    const closeHelpModal = () => {
        navigate('/carriersignup');
    };

    return (
        <div>
            <div className='d-flex justify-content-end align-items-center bgContainer px-0 mx-0'>
                <img className={'bgimage'} src={bgImage ? bgImage : AppConstant.commonStrings.bkgImgLink} alt='Background Image' />
                <GloabalModal showModal={true}>
                    <LeftInfoWithFormContainer
                        formName={paymentMethod}
                        closeHelpModal={() => closeHelpModal()}
                        displayComponent={() => getCurrentPaymentMethodForm()}
                    />
                </GloabalModal>
            </div>
        </div>

    )
}

export default PaymentMethodContainer;