import _ from 'lodash';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedDateSelector } from '../../../../../../../../../redux/reducers/reducerSlices/DateSelectorReducer';
import { getDefaultDashboardSelector } from '../../../../../../../../../redux/reducers/reducerSlices/DefaultDashboardReducer';
import { getUserSelector } from '../../../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { sagaActions } from '../../../../../../../../../sagas/sagaActions';
import { AppConstant } from '../../../../../../../../Assests/AppConstant';
import { modifyPODisplay } from '../../../../../../../../Assests/Utility';
import DrilldownTable from '../../Common/DrilldownTable';

const DrillDownUnloadingTypeSummary = (props) => {
    const { themeSelected, loaderArray, subRoot, dockIdArr } = props;
    const dispatch = useDispatch();
    const [drilldownData, setDrilldownData] = useState([]);
    const defaultDashboard = useSelector(getDefaultDashboardSelector);
    const { unloadingTypeSummaryDrilldown, arrangeOrder, arrangeKey, initiateDrillDownReport, dateRangeObj } = defaultDashboard;
    const userPermission = useSelector(getUserSelector);
    const {selectedWarehouse} = userPermission;

    let  unloadingTypeSummaryTitleDD = [
        { id: 'PoNumbers', name: 'PO' },
        { id: 'NoOfPallets', name: 'No. of Pallets' },
        { id: 'AppointmentTime', name: 'Appt Time' },
        { id: 'UnloadingStartTime', name: 'Unloading Start' },
        { id: 'UnloadingEndTime', name: 'Unloading End' },
        { id: 'UnloadingDwell', name: 'Unloading Dwell' },
        { id : 'PalletsPerHr', name: 'Pallets per hr'},
        { id: 'DriverUnload', name: 'Driver Unload'}
    ]
    const createPayload = () => {
        return {dock_id: dockIdArr.toString() === '' ? null : dockIdArr.toString(), isDrillDown: '1', warehouse_id: selectedWarehouse.ID}
    }
    useEffect(() => {
        dispatch({type: sagaActions.INITIATE_DRILLDOWN_REPORT, payload: true})
        dispatch({
            type: sagaActions.GET_UNLOADING_TYPE_SUMMARY_DRILLDOWN, payload: createPayload() });
    }, [])

    useEffect(() => {
        dispatch({type: sagaActions.INITIATE_DRILLDOWN_REPORT, payload: true})
        dispatch({
            type: sagaActions.GET_UNLOADING_TYPE_SUMMARY_DRILLDOWN, payload: createPayload() });
    }, [dateRangeObj])

    useEffect(() => {
        if (unloadingTypeSummaryDrilldown && unloadingTypeSummaryDrilldown.length) {

            let data = formatDrillDownData(unloadingTypeSummaryDrilldown)
            setDrilldownData(data)
        }
    }, [unloadingTypeSummaryDrilldown])

    const formatDrillDownData = (data) => {
        return data.map(el => {
            let splitArray = []
            if(el.PoNumbers){
                splitArray = el.PoNumbers.split(',')
            }
            return {
                id: el.Id,
                0: modifyPODisplay(splitArray) || '-',
                1: el.NoOfPallets || '-',
                2: el.AppointmentTime || '-',
                3: el.UnloadingStartTime || '-',
                4: el.UnloadingEndTime || '-',
                5: el.UnloadingDwell ? el.UnloadingDwell + 'hr' : '-',
                6: el.PalletsPerHr,
                7: el.DriverUnload  ? 'Vendor Unload' : 'Lumper Unload'
            }
        })
    }

    return (
        <DrilldownTable showUTCTime={true} themeSelected={themeSelected} bodyData={drilldownData} titleData={ unloadingTypeSummaryTitleDD} showCollapse={false} showInput={true} showSort={true} arrangeOrder={arrangeOrder} arrangeKey={arrangeKey} drillDownReport={unloadingTypeSummaryDrilldown} initiateDrillDownReport={initiateDrillDownReport} loaderArray={loaderArray} subRoot={subRoot} sortingAction={sagaActions.SORT_DRILLDOWN_REPORT}/>
    );
}

export default DrillDownUnloadingTypeSummary;