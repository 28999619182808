import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    error: null,
    loading: false,
    data:''
} 

export const GetTimeZoneReducer = createSlice({
    name: 'GetTimeZoneReducer',
    initialState,
    reducers: {
        initiateLoading: (state) => {
          state.loading = true
         },
        addSuccess: (state, action) => {
           state.loading = false;
           state.data = action.payload;
        },
        addFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload
        },
        resetData:(state,action)=>{
            state.loading = false;
            state.data = ''; 
        }
    }
});

export const { addSuccess, addFailure,initiateLoading,resetData} = GetTimeZoneReducer.actions;

// Selectors
export const getTimeZoneReducer = (state) => {
    return state.GetTimeZoneReducer;
};

export default GetTimeZoneReducer.reducer;
