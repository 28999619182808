import React, { useEffect, useState } from 'react'
import "./PasswordInput.css"
import { ReactComponent as Eye } from "../../../Assests/Svg/eye.svg"
import { ReactComponent as EyeOpened } from "../../../Assests/Svg/eyeOpen.svg"
import { AppConstant } from '../../../Assests/AppConstant'
import {ReactComponent as PasswordInfo } from '../../../Assests/Svg/info.svg';
import CustomTooltip from '../Tooltip/CustomTooltip'
/**
 * This is a reusable component for Password
 * @param {*} props 
 * title = Password title
 * placeHolder = Placeholder text
 * showError = show/hide error variable
 * errorMessage = variable to display error message
 * disabled = if element is disabled
 * showInfo = show password info
 * passwordInfo = data tip password info
 * value = password value
 * @returns 
 */
const PasswordInput = (props) => {
    const { title, placeHolder, showError, errorMessage , disabled, showInfo, passwordInfo, value} = props

    const [error, seterror] = useState(showError)

    const [showPassword, setshowPassword] = useState(false)
     /**
     * useEffect for error
     */
    useEffect(() => {
        seterror(showError)
    }, [showError])
     /**
   * Show password handler function
   * @returns 
   */
    const handleShowPassword = () => setshowPassword(!showPassword)
    /**
     * handler function input element type to display password or hide
     * @returns input element type to display password or hide
     */
    const getType = () => showPassword ? "text" : "password"
    /**
     * function for input class if error
     */
    const getInputClass = () => error ? "passwordTextInput passwordTextInputError" : "passwordTextInput"
    /**
     * handler function for password icon
     * @returns EyeOpened or Eye
     */
    const getEyeImage = () => showPassword ? <span  data-testid={AppConstant.dataTestId.passwordEyeButton}><EyeOpened /></span>
    : <span  data-testid={AppConstant.dataTestId.passwordEyeButton}><Eye /></span>
    
    return (
        <div className="d-flex flex-column">
            <div>
            <span data-testid={AppConstant.dataTestId.passwordInputTitle} className='inputTitle pe-2'>
                {title}
            </span>
            {showInfo ? <span data-tip={passwordInfo} data-for='password_info'><PasswordInfo /><CustomTooltip id='password_info' position='top' type='light' multiline={true} html={true} /></span> : ''}
            </div>

            <div className='passwordContainer'>
                <input
                    data-testid={AppConstant.dataTestId.passwordInput}
                    type={getType()}
                    name='password'
                    className={getInputClass()}
                    placeholder={placeHolder}
                    value={value}
                    onInput={(data) => props.onPasswordChange(data.target.value)}
                    // disabled ={ disabled || false}
                />
                <button className='eyeimage' onClick={handleShowPassword}>
                    {
                        getEyeImage()
                    }
                </button>
            </div>
            {
                error &&
                <span className='errorMessage' data-testid={AppConstant.dataTestId.validationError}>
                    {errorMessage}
                </span>
            }

        </div>
    )
}

export default PasswordInput
