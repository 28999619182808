import React from 'react';
import FeaturedInfo from '../Funnel/funnel'

const FunnelGraphWidget = (props) => {

    const { themeSelected, legendsArr, quantityArr, timeArr, colorArr, height, width, containerId, onClickAction } = props;
    return (
        <div>
          
                <div className='py-2'>
                    <FeaturedInfo
                        containerId={containerId}
                        labelsArr={legendsArr}
                        valuesArr={quantityArr}
                        subLabelsArr={legendsArr}
                        labelsValue={timeArr}
                        colorArr={colorArr}
                        height={height / 3}
                        width={width}
                        themeSelected={themeSelected}
                        onClickAction={onClickAction}/>
                
                </div>

        </div>
    )
}

export default FunnelGraphWidget;