import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppConstant } from '../../../../Assests/AppConstant';
import { ReactComponent as DownloadIconLight } from '../../../../Assests/Svg/download-icon-light.svg';
import { ReactComponent as DownloadIconDark } from '../../../../Assests/Svg/download-icon-dark.svg';
import { sagaActions } from '../../../../../sagas/sagaActions';
import { getDefaultDashboardSelector } from '../../../../../redux/reducers/reducerSlices/DefaultDashboardReducer';
import html2pdf from 'html2pdf.js';
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import './DownloadSection.css';

const DownloadSection = (props) => {
    const dispatch = useDispatch();
    const { themeSelected, currentTableRef, name, singlePageDownload } = props;
    const dashboard = useSelector(getDefaultDashboardSelector);

    const downloadData = (formatStr) => {
        if (formatStr === 'pdf') {
            generatePdf()
        } else if (formatStr === 'excel') {
            generateExcel();
        }
    }

    const generatePdf = () => {

        const inputElement = currentTableRef.innerHTML
        const options = {
            margin: 0.1,
            filename: name ? name + '.pdf' : 'drilldown.pdf',
            image: { type: 'jpeg', quality: 1.0 },
            html2canvas: singlePageDownload ? { scale: 3 } : { scale: 3, width: parseInt(window.innerWidth * 2) },
            jsPDF: { unit: 'in', format: 'tabloid', orientation: 'landscape' },
        };

        html2pdf().from(inputElement).set(options).toPdf().get('pdf').then(function (pdf) {
            var totalPages = pdf.internal.getNumberOfPages();
            for (let i = 1; i <= totalPages; i++) {
                pdf.setPage(i);
                pdf.setFontSize(10);
                pdf.setTextColor(100);
                pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() / 2.3), (pdf.internal.pageSize.getHeight() - 0.8));
            }
        }).save();

    }

    const generateExcel = () => {
        const element = currentTableRef;
        const workbook = XLSX.utils.table_to_book(element);
        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
        FileSaver.saveAs(blob, name ? name + '.xlsx' : 'drilldown.xlsx');
    };

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) {
            view[i] = s.charCodeAt(i) & 0xff;
        }
        return buf;
    };

    return (
        <div className='dropdown border border-dark rounded-2 p-1 px-2'>

            <div className='d-flex justify-content-start align-items-center me-2 downloadSection' data-bs-toggle="dropdown" aria-expanded="false" >
                {themeSelected === 'light-mode' ? <DownloadIconLight className='me-2' /> : <DownloadIconDark className='me-2' />}
                <div className={`downloadButton ${themeSelected}`}>{AppConstant.downloadSection.downloadTitleStr}</div>
            </div>
            <ul className="dropdown-menu text-center dropDownDownItemloadSection">
                <li><a className="dropdown-item" onClick={() => downloadData('pdf')} href="#">{AppConstant.downloadSection.pdfDownloadString}</a></li>
                <div className='seperator' />
                <li><a className="dropdown-item" onClick={() => downloadData('excel')} href="#">{AppConstant.downloadSection.excelDownloadString}</a></li>
            </ul>
        </div>

    )
}

export default DownloadSection;