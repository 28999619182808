import _ from 'lodash';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDefaultDashboardSelector } from '../../../../../../../../../redux/reducers/reducerSlices/DefaultDashboardReducer';
import { getUserSelector } from '../../../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { sagaActions } from '../../../../../../../../../sagas/sagaActions';
import { AppConstant } from '../../../../../../../../Assests/AppConstant';
import DrilldownTable from '../../Common/DrilldownTable';
import { YYYY_MM_DD_FORMAT, getFormattedDate } from '../../../../../../../../../Utils/DateUtils';
import { getSelectedDateSelector } from '../../../../../../../../../redux/reducers/reducerSlices/DateSelectorReducer';

const DrillDownDoorUsageSummary = (props) => {
    const { themeSelected, loaderArray, subRoot, dockIdArr } = props;
    const dispatch = useDispatch();
    const [drilldownData, setDrilldownData] = useState([]);
    const getSelectedDateSelectorObj = useSelector(getSelectedDateSelector);
    const { selectedDate } = getSelectedDateSelectorObj;
    const defaultDashboard = useSelector(getDefaultDashboardSelector);
    const { doorUsageSummaryDrillDown, arrangeOrder, arrangeKey, initiateDrillDownReport, customDashboardDockSelectedList } = defaultDashboard;
    const userPermission = useSelector(getUserSelector);
    const { selectedWarehouse } = userPermission;
    const [doorUsageSummarySelectedDockState, setDoorUsageSummarySelectedDockState] = useState(null)

    let  unloadingTypeSummaryTitleDD = [
        { id: 'Door', name: 'Door' },
        { id: 'DoorType', name: 'Door Type' },
        { id: 'DoorStatus', name: 'Status' },
        { id: 'PalletsCount', name: 'Pallets' },
        { id: 'TruckCount', name: 'Truck' },
    ]

    useEffect(() => {
        dispatch({ type: sagaActions.RESET_DOOR_USUAGE_SUMMARY_DRILLDOWN });
        dispatch({ type: sagaActions.INITIATE_DRILLDOWN_REPORT, payload: true })
        setDrilldownData([])
        return () => {
            dispatch({ type: sagaActions.RESET_DOOR_USUAGE_SUMMARY_DRILLDOWN });
        }
    }, [])

    useEffect(() => {
        if (_.size(customDashboardDockSelectedList)) {
            const filterDoorUsageSummary = _.filter(customDashboardDockSelectedList, {name: 'Door Usage Summary'})
            if (_.size(filterDoorUsageSummary)) {
                setDoorUsageSummarySelectedDockState(filterDoorUsageSummary[0])
            }
            
        }
    }, [customDashboardDockSelectedList])
    
    useEffect(() => {
        if (!_.isEmpty(doorUsageSummarySelectedDockState)) {
            dispatch({ type: sagaActions.UPDATE_DOOR_USAGE_SUMMARY, payload: createPayload() });
        }
    },[doorUsageSummarySelectedDockState])

    const createPayload = () => {
         return {
            "date": getFormattedDate(YYYY_MM_DD_FORMAT, selectedDate),
            "dock_id": doorUsageSummarySelectedDockState ? doorUsageSummarySelectedDockState?.dock_id : null,
            "isDrillDown": "1",
            "warehouse_id": selectedWarehouse.ID
        }
    }

    useEffect(() => {
        if (doorUsageSummaryDrillDown && doorUsageSummaryDrillDown.length) {
            let data = formatDrillDownData(doorUsageSummaryDrillDown)
            setDrilldownData(data)
            dispatch({ type: sagaActions.INITIATE_DRILLDOWN_REPORT, payload: false})
        } else {
            // setDrilldownData([])
            // initiateDrillDownReport && dispatch({ type: sagaActions.INITIATE_DRILLDOWN_REPORT, payload: false})
        }
    }, [doorUsageSummaryDrillDown])

    const formatDrillDownData = (data) => {
        return data.map(el => {
            return {
                id: `${el.Door}_${el.DoorType}`,
                0: el.Door || '-',
                1: el.DoorType || '-',
                2: el.DoorStatus || '-',
                3: el.PalletsCount || '-',
                4: el.TruckCount || '-',
            }
        })
    }

    return (
        <DrilldownTable showUTCTime={true} themeSelected={themeSelected} bodyData={drilldownData} titleData={ unloadingTypeSummaryTitleDD} showCollapse={false} showInput={true} showSort={true} arrangeOrder={arrangeOrder} arrangeKey={arrangeKey} drillDownReport={doorUsageSummaryDrillDown} initiateDrillDownReport={initiateDrillDownReport} loaderArray={loaderArray} subRoot={subRoot} sortingAction={sagaActions.SORT_DRILLDOWN_REPORT} />
    );
}

export default DrillDownDoorUsageSummary;