import React from 'react';
import LoadDetails from '../../LoadSection/LoadDetails/LoadDetails';
import LoadHeader from '../../LoadSection/LoadHeader/LoadHeader';
import LoadStatusOverview from '../../LoadSection/LoadStausOverview/LoadStausOverview';
import POSection from '../../LoadSection/POSection/POSection';
import CommentsSection from '../../Comments/CommentsSection';
import './TruckDetailsSection.css';
import DoorAssignedTimer from '../../LoadSection/DoorAssignment/DoorAssignmentTimer/DoorAssignedTimer';
import DoorAssingment from '../../LoadSection/DoorAssignment/DoorAssignment';
import DoorAssignmentIMSSP from '../../LoadSection/DoorAssignment/DoorAssignmentIMSSP';

const TruckDetailsSection = (props) => {
    const { themeSelected, type } = props;
    return (
        <div className={`pODetailsSection d-flex flex-column ${themeSelected}`}>
            <LoadHeader {...props} />
            <LoadDetails {...props} />
            <LoadStatusOverview {...props} />
            <POSection {...props} type={ type} />
            {type === 'IMS' ? <DoorAssingment {...props} />: <DoorAssignmentIMSSP {...props} />}
            <DoorAssignedTimer {...props} />
            <CommentsSection {...props} type={ type } />
        </div>
    )
}
export default TruckDetailsSection;