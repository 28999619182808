import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash-es';
import { useNavigate } from 'react-router-dom';
import { getUserSelector } from '../../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { formParticularFieldValueForAssociatedPO, showAlert } from '../../../../../../../Assests/Utility';
import { AppConstant } from '../../../../../../../Assests/AppConstant'
import { sagaActions } from '../../../../../../../../sagas/sagaActions';
import { getPoSSselector } from '../../../../../../../../redux/reducers/reducerSlices/POSmartSchedulerReducer';
import { usePrevious } from '../../../../../../Common/CustomHooks/usePrevious';
import './ApptConflictForm.css';
import moment from 'moment-timezone';
import { getRemoteConfigSelector } from '../../../../../../../../redux/reducers/reducerSlices/RemoteConfigReducer';
import DrilldownTableSS from '../../../../../Components/Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTableSS';
import CustomButtonSS from '../../../../../../Common/CustomButton/CustomButtonSS';
import { getWhMetaDataReducerSlice } from '../../../../../../../../redux/reducers/reducerSlices/WhMetaDataReducerSlice';
import { toast } from 'react-toastify';
import SSPopup from '../../../../../../Common/Popup/SSPopup/SSPopup';


const ApptConflictFormSS = (props) => {
    const { themeSelected = 'light-mode', dockPayload } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const pOSS = useSelector(getPoSSselector);
    const whMetaReducer = useSelector(getWhMetaDataReducerSlice);
    const { dockConflictObj, operationObj, arrangeOrder, arrangeKey, isLoading } = whMetaReducer;
    const userObj = useSelector(getUserSelector);
    const [searchStr, setSearchStr] = useState(null);
    const { selectedWarehouse, cu_id } = userObj;
    const [showLoading, setShowLoading] = useState(true);
    moment.tz.setDefault(selectedWarehouse?.TimeZone)
    const remoteConfig = useSelector(getRemoteConfigSelector);
    const { allConfigration } = remoteConfig;
    const [currentDeleteIndex, setCurrentDeleteIndex] = useState('NA');
    const [selectedIndex, setSelectedIndex] = useState(null)
    const [currentItemIndex, setCurrentItemIndex] = useState('NA');
    const [dockDoorConflictArr, setdockDoorConflictArr] = useState([]);
    const [isListLoading, setListLoading] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedPoObj, setSelectedPoObj] = useState({});
    const divRef = useRef(null);

    const apptsTitleDDWH = [
        { id: 'serial_num', name: 'S.No' },
        { id: 'ApptNumber', name: 'Appt Number' },
        { id: 'PoNumber', name: 'PO' },
        // { id: userType === 'CARRIER' ? 'WarehouseName' : 'CarrierName', name: userType === 'CARRIER' ? 'Warehouse' : 'Carrier' },
        { id: 'DueDate', name: 'Due Date' },
        { id: 'ApptStartTime', name: 'Appt Date & Time' },
        // { id: 'DockName', name: 'Dock' },
        { id: 'Pallets', name: 'Pallets' },
        { id: 'ApptScheStatus', name: 'Status' },
        { id: 'Reason', name: 'Reason' },
        { id: '', name: AppConstant.superUserAdmin.warehouseAdmin.warehouseTable.actionsColTitle },
        { id: 'Accordion', name: 'Multi PO' },
    ]

    /**
     * used to validate the dock conflict obj
     */
    useEffect(() => {
        if (dockConflictObj && dockConflictObj.appointments.length) {
            setShowLoading(false);
            setdockDoorConflictArr(dockConflictObj.appointments)
        } else {
            setdockDoorConflictArr([])
            setShowLoading(false)
        }

    }, [dockConflictObj.appointments])

    /**
     * used to handle API response after getting the result from API
     */
    useEffect(() => {
        if (operationObj.type) {
            switch (operationObj.type) {
                case 'Delete Appts':
                    if (operationObj.status) {
                        handleApiResponse(AppConstant.notifications.dataAddedSuccess, AppConstant.smartScheduler.myAppointments.deleteApptSuccess);
                    } else {
                        handleApiResponse(AppConstant.commonStrings.failure, AppConstant.smartScheduler.myAppointments.deleteApptFailure);
                    }
                    break;
                default:
            }
        }
    }, [operationObj])


    /**
     * hanlde api response based on success or failure
     * @param {*} result 
     * @param {*} message 
     */
    const handleApiResponse = (result, message) => {
        setCurrentItemIndex('NA')
        setCurrentDeleteIndex('NA')
        if (result === AppConstant.notifications.dataAddedSuccess) {
            showAlert(toast.TYPE.SUCCESS, message, themeSelected);
            dispatchDockConflict()
        } else {
            showAlert(toast.TYPE.ERROR, message, themeSelected);
        }
        setListLoading(false);
        dispatch({ type: sagaActions.RESET_OPERATION_OBJECT_WH });
    }

    /**
     * function to make API calls
     */
    const dispatchDockConflict = () => {

        dispatch({ type: sagaActions.GET_DOCK_CONFLICT_DETAILS, payload: dockPayload });
    }

    /**
     * He;per function to show the text color based on the provided string type
     * @param {} str 
     * @returns 
     */
    const getApptSchStatusColor = (str) => {
        if (str === 'Complete') {
            str = 'Completed';
            return <p className='text-success'>{str}</p>
        } else if (str === 'Schedule') {
            str = 'Scheduled';
            return <p className='notScheStatusText'>{str}</p>
        } else if (str === 'Delete') {
            return <p className='text-danger'>{cancelObj[str]}</p>
        } else if (str === 'Re-Schedule') {
            return <p className='notScheStatusText'>{cancelObj[str]}</p>
        } else {
            return <p className='notScheStatusText'>{str}</p>
        }
    }

    /**
     * Helper function
     */
    const cancelObj = {
        'Delete': 'Cancelled',
        'Re-Schedule': 'Rescheduled'
    }

    /**
     * Helper function to format the table data
     * @param {*} data 
     * @param {*} index 
     * @returns 
     */
    const formatListDataDrillDown = (data, index) => {

        return data.map((el, i) => {
            return {
                id: el.RoleID,
                0: index + 1,
                1: el.AppointmentNumber || AppConstant.commonStrings.hypen,
                2: el.PoNumber || AppConstant.commonStrings.hypen,
                // 3: userType === 'CARRIER' ? el.WarehouseDetails.Name || '-' : el.CarrierName || '-',
                3: el.DueDate ? moment(el.DueDate).utc().format(AppConstant.commonStrings.MM_DD_YYYY_dateFormat) : AppConstant.commonStrings.hypen,
                4: el.ApptStartTime ? moment(el.ApptStartTime).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : AppConstant.commonStrings.hypen,
                // 6: el.DockName || '-',
                5: el.Pallets || AppConstant.commonStrings.hypen,
                6: el.ApptScheStatus ? getApptSchStatusColor(el.ApptScheStatus) : AppConstant.commonStrings.hypen,
                7: el.Reason ? el.Reason : AppConstant.commonStrings.hypen,
                8: actionsButton(el, index)
            }
        })
    }

    /**
     * used to format the data based on parent and child po
     * @param {*} data 
     * @returns 
     */
    const formatdata = (data) => {
        let result = []
        const tempArr = data.map(ele => {
            if (ele.PORelationID === ele.PoNumber) {
                return ({ ...ele, masterPO: true })
            }
            else {
                return ({ ...ele, masterPO: false })
            }
        })
        let newlist = _.groupBy(tempArr, 'AppointmentNumber')
        let finaldata = Object.values(newlist);
        finaldata.forEach(element => {
            let l = element.sort((a, b) => {
                if (a.masterPO) {
                    return -1;
                } else {
                    return 1;
                }
            })
            result.push(l)
        });
        return result;
    }

    /**
     * Helper function
     * @returns 
     */
    const getBodyData = () => {
        if (dockDoorConflictArr.length) {
            const formatedData = formatdata(dockDoorConflictArr);
            return (formatedData.map((data, index) => {
                return (
                    formatListDataDrillDown(data, index)
                )
            }))
        }
        else {
            return []
        }
    }

    /**
     * returns the custom button
     * @param {*} groupObj 
     * @param {*} index 
     * @returns 
     */
    const actionsButton = (groupObj, index) => {
        return (
            <div>
                <span className='d-flex justify-content-center align-items-center gap-2' >
                    <CustomButtonSS
                        disabled={false}
                        isLoading={currentDeleteIndex !== 'NA' && currentDeleteIndex === index ? true : false}
                        className={`no-border no-bg`}
                        onClick={() => { deleteHandler(groupObj, index) }}
                        isIcon={true}
                        iconClass={"fa-regular fa-trash-can fa-lg"}
                    />
                </span>
            </div>
        )
    }

    /**
     * used to set helper values while deleting
     * @param {*} data 
     * @param {*} index 
     */
    const deleteHandler = (data, index) => {
        setSelectedPoObj(data)
        setCurrentDeleteIndex(index)
        setShowDeleteModal(true);
    }

    function createWarningText(mPO, associatedPO, arr) {
        return <div className='boldText'>
            <span> Are you sure, you want to delete this Appointment for PO: {mPO} </span>
            {arr.length > 1 ? <><span> and its associated POs :  </span>
                <span className='textRed'>{associatedPO} ?</span></> : <span>?</span>}
        </div>; // This will render HTML
    }

    /**
     *  function where it show pop up while delete
     * @returns 
     */
    const showDeletePopup = () => {
        if (_.size(dockDoorConflictArr)) {
            const filteredArray = dockDoorConflictArr.filter((el) => el.PORelationID === selectedPoObj.PORelationID)
            if (_.size(filteredArray)) {
                const masterPO = filteredArray[0]
                const fieldDataAssociated = formParticularFieldValueForAssociatedPO(filteredArray)
                const popupObj = {
                    id: 'deleteAppointment',
                    modalLabel: 'deleteEmail',
                    showClose: true,
                    showfooter: true,
                    showHeader: true,
                    sendDriverNotification: false,
                    modalSize: 'md',
                    title: AppConstant.smartScheduler.myAppointments.deleteApptTitle,
                    bodyText: createWarningText(masterPO?.PoNumber, fieldDataAssociated?.po_number, filteredArray),
                    subText: "",
                    keyboard: false,
                    backdrop: "static",
                    footerBtn: {
                        btn1: {
                            show: true,
                            text: AppConstant.commonStrings.yes
                        },
                        btn2: {
                            show: true,
                            text: AppConstant.commonStrings.no
                        }
                    },
                }

                return <SSPopup {...popupObj} popupBtnHandler={(text) => deletePoPopupHandler(text)} closepopup={closeDeletePopopup} themeSelected={themeSelected} showModal={showDeleteModal} />
            }
        }
    }

    /**
     * used to perform actions based on the pop up delete selected values
     * @param {*} text 
     */
    const deletePoPopupHandler = (text) => {
        if (text === AppConstant.commonStrings.yes) {
            deletePoAppt()

        } else {
            setShowDeleteModal(false)
            setSelectedPoObj({})
            setSelectedIndex(null)
        }
    }

    /**
     * Helper to close the popup
     */
    const closeDeletePopopup = () => {
        setShowDeleteModal(false);
        setCurrentDeleteIndex('NA')
    }

    /**
     * Used to call the API
     */
    const deletePoAppt = () => {
        const newItemPayload = {
            PoNumber: selectedPoObj.PORelationID,
            IDWarehouse: selectedPoObj.IDWarehouse,
            ReceivingZipCode: selectedPoObj.ReceivingZipCode,
            ApptCreatedBy: cu_id,
            PORelationID: selectedPoObj.PORelationID,
            IDDock: selectedPoObj.IDDock,
            CRUDTYPE: 'AD',
            IDPO: selectedPoObj.IDPO,
            rescheduleReason: 'Appointment Deleted Due To Dock Deletion'
        }
        const operationObj = {
            type: 'Delete Appts',
            status: ''
        }

        dispatch({ type: sagaActions.DELETE_POS_AND_APPOINTMENTS, payload: newItemPayload, operationObj: operationObj })
        setSelectedPoObj({})
        setShowDeleteModal(false);
    }


    return (
        <div className='appointments-report-ss-wrapper p-3'>
            <div className='allApptsListContainer-ss' ref={divRef}>
                <DrilldownTableSS
                    themeSelected={themeSelected}
                    bodyData={getBodyData()}
                    titleData={apptsTitleDDWH}
                    showCollapse={false}
                    showInput={false}
                    showSort={false}
                    hasCollapsableContent={true}
                    collapsableData={formatdata(dockDoorConflictArr)}
                    accordionPrimaryIndex={1}
                    arrangeOrder={arrangeOrder}
                    arrangeKey={arrangeKey}
                    drillDownReport={dockDoorConflictArr}
                    initiateDrillDownReport={isListLoading}
                    loaderArray={10}
                    sortingAction={sagaActions.SORT_SS_USER_TABLE}
                    subRoot={{ pathName: 'allApptsList' }}
                    showUTCTime={true}
                />
            </div>
            {showDeletePopup()}

        </div>
    )
}

export default ApptConflictFormSS