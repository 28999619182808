import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AppConstant } from '../../../../../../../../Assests/AppConstant';
import CommonChart from '../../../Graphs/CommonChart';
import './DoorAssignment.css'
import _ from 'lodash-es'

const DoorAssignment = (props) => { 
    const navigate = useNavigate();
    const { doorAssignmentChart, doorAssignmentSelectedDockState, themeSelected } = props;
    
    const getSubRoot = () => {
        if (!_.isEmpty(doorAssignmentSelectedDockState)) {
        navigate(`/analytics/reports/doorAssignment/${doorAssignmentSelectedDockState?.dock_name}`)
        }
    }

    return ( 
        <div className={`col-12 me-2 ${themeSelected} bg-transparent`} style={{ marginRight: '10px' }}>
            <div onClick={() => getSubRoot()} style={{ color: 'inherit', textDecoration: 'inherit', cursor: 'pointer'}}>
            {/* <Link to="/analytics/reports/doorstatus" style={{ color: 'inherit', textDecoration: 'inherit'}}> */}
                    <div className='col-12 pt-3 '>
                    <span className='graphHeading'>{AppConstant.dashboard.defaultdashboard.doorAssignment.heading}</span><br />
                    </div>
                    <div  id='stacked-bar-chart' className="container text-center">
                    <div className='mx-auto'>{doorAssignmentChart ? <CommonChart data={doorAssignmentChart} /> : ''}</div>
                </div>
                </div>
                {/* </Link> */}
                </div>
     );
}
 
export default DoorAssignment;