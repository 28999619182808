import React from 'react'
import { Link } from "react-router-dom";
import { AppConstant } from '../../../Assests/AppConstant'


const TryLogin = () => {
    return (
        <Link to="/">
        <button className='feedbackButton'>
            <span className='navText'>{AppConstant.login.recover.tryLoginButtonTitle}</span>
        </button>
        </Link>
    )
}

export default TryLogin
