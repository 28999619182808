export const fetchBreakListCommonFunc = (selectedWarehouse) => {

    const apiParams = {
        "IDWHBreak": null,
        "IDWarehouse": selectedWarehouse.ID,
        "BreakStart": null,
        "BreakEnd": null,
        "Description": null,
        "Status": null,
        "CRUDTYPE": "S",
        "CreatedOn": null,
        "CreatedBy": null,
        "UpdatedBy": null,
        "UpdatedOn": null,
    }

    const operationObj = {
        type: 'Fetch Breaks',
        status: ''
    }
    
    return {apiParams : apiParams, operationObj: operationObj}
}

export const fetchBreaksDocksListCommonFunc = (selectedWarehouse) => {

    const apiParams = {
        "IDDockBreak": null,
        "IDWHBreak": null,
        "IDWarehouse": selectedWarehouse.ID,
        "BreakStart": null,
        "BreakEnd": null,
        "Description": null,
        "Status": null,
        "CRUDTYPE": "S",
        "CreatedOn": null,
        "CreatedBy": null,
        "UpdatedBy": null,
        "UpdatedOn": null,
        "IDDock":null
    }
    
    const operationObj = {
        type: 'Fetch Breaks',
        status: ''
    }
    
    return {apiParams : apiParams, operationObj: operationObj}
}