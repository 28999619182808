import React from 'react'
import AppointmentReportSummaryChart from './AppointmentReportSummaryChart';
import { smartScheduleSelector } from '../../../../../../../../../redux/reducers/reducerSlices/SmartScheduleReducer';
import { useSelector } from 'react-redux';

/**
 * Component for appointment summary graph and summary
 * @param {*} props 
 * @returns 
 */
const AppointmentReportSummaryGraphical = (props) => {
    const {appointmentReportSummaryData,getSubRoot} =props;
    const ss = useSelector(smartScheduleSelector);
    const { appointmentReportSummary } = ss;
  return (
    <div className='d-flex justify-content-around align-items-center appt-report-graph'>
    <div className=''>
    <table className='table table-responsive table-hover table-summary'>
        <thead className='align-middle truck-list-title-ss'>
        <th className='col title-text-container f-14 fw-bold'>Appointment Summary</th>
        <th className='col title-text-container f-14'>Total</th>
        </thead>
        <tbody>
            <tr>
                <td className='col-md-auto'>Appointment by warehouse</td>
                <td className='col-md-auto'>{appointmentReportSummary?.Warehouse}</td>
            </tr>
            <tr>
                <td className='col-md-auto'>Appointment by carrier</td>
                <td className='col-md-auto'>{appointmentReportSummary?.Carrier}</td>
            </tr>
            <tr>
                <td className='col-md-auto fw-bold'>Total Appointments</td>
                <td className='col-md-auto fw-bold'>{appointmentReportSummary?.Carrier + appointmentReportSummary?.Warehouse}</td>
            </tr>
        </tbody>
    </table>
    </div>
<AppointmentReportSummaryChart {...appointmentReportSummaryData} getSubRoot={getSubRoot}/>
</div>
  )
}

export default AppointmentReportSummaryGraphical