import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppConstant } from '../../../../Assests/AppConstant';
import { lightStyles, darkStyles } from './IFrameStyles/iFrameStyles';
import { useNavigate, useHistory } from 'react-router-dom';
import { sagaActions } from '../../../../../sagas/sagaActions';
import { showAlert, createNavBar, removeLastCharacterFromString, formatContactNumForAWS } from '../../../../Assests/Utility';
import { toast } from 'react-toastify';
import { paytraceReducer } from '../../../../../redux/reducers/reducerSlices/PaytraceReducer';
import { getAuthReducer } from '../../../../../redux/reducers/reducerSlices/AuthReducer';
import { getUserSelector } from '../../../../../redux/reducers/reducerSlices/UserReducer';
import { interceptor, removeInterceptor } from '../../../../../redux/api/Interceptor';
import _, { isNil } from 'lodash-es';
import CustomButton from '../../../Common/CustomButton/CustomButton';
import Popup from '../../../Common/Popup/Popup';
import PaymentCardsImage from '../../../../Assests/Images/paymentCardsAccepted.png';
import "./RenewSubscriptionComponentSS.css";
import SSPopup from '../../../Common/Popup/SSPopup/SSPopup';
import CustomButtonSS from '../../../Common/CustomButton/CustomButtonSS';

/**
 * This component renders the new Paytrace UI for payment using iframe
 * @param {*} props 
 * @returns 
 */
const RenewSubscriptionComponentSS = (props) => {

    const dispatch = useDispatch();
    const { themeSelected } = props;
    const navigate = useNavigate();
    const PTPayment = window.PTPayment;
    const [loading, setLoading] = useState(false);
    const [showIFrame, setShowIFrame] = useState(false);
    const [isPaymentLoading, setIsPaymentLoading] = useState(false);
    const [showIFrameLoader, setShowIFrameLoader] = useState(false);
    const [showPaymentSuccessView, setShowPaymentSuccessView] = useState(false);
    const [iFramePaymentCompletedFlag, setIFramePaymentCompletedFlag] = useState(false);
    const [paymentInstance, setInstance] = useState(null);
    const [styles, setStyles] = useState(themeSelected === 'dark-mode' ? darkStyles : lightStyles);
    const [iFrameValidationErrors, setIframValidationErrors] = useState([]);
    const userPermission = useSelector(getUserSelector);
    const user = useSelector(getAuthReducer);
    const { paytraceClientId, paymentResponse } = useSelector(paytraceReducer);
    const { userType, permissions, userAccountDetails, error, carrierFromSignUpUserObj } = userPermission;

    const [showCancelModal, setShowCancelModal] = useState(false);

     /**
     * initial useEffect
     */
    useEffect(() => {
        dispatch({ type: sagaActions.RESET_PAYTRACE_DATA });
        dispatch({ type: sagaActions.GET_PAYTRACE_CLIENT_ID })
    }, [])
     /**
     * useEffect to render initialize paytrace UI when paytrace client exists
     */
    useEffect(() => {
        if (paytraceClientId) {
            paytraceUIForm();
        }
    }, [paytraceClientId])
    /**
     * This function initializes paytrace with client key
     */
    const paytraceUIForm = () => {
        PTPayment.setup({
            styles,
            authorization: { clientKey: paytraceClientId }
        }).then((instance) => {
            setShowIFrameLoader(false);
            PTPayment.getControl("creditCard").label.text(AppConstant.smartScheduler.carrierSignUp.creditCardForm.cardNumber);
            setInstance(instance);
        })
            .catch((err) => {
                setShowIFrameLoader(false);

                showAlert(toast.TYPE.ERROR, AppConstant.smartScheduler.carrierSignUp.paytrace.initPaytraceError + err.toString(), 'light')
            });
    }
    /**
     * 
     * @returns iframe error JSX
     */
    const showValidationErros = () => {
        return iFrameValidationErrors.map((ele, index) => {
            return (<div className='text-danger iFrameErrorText'>&bull; {ele.description} </div>)
        })
    }
     /**
     * 
     * @returns iframe Loader
     */
    const showIFrameLoaderUI = () => {
        return (<div className='placeholder-glow'>
            <span className="placeholder mt-3 col-12" />
            <span className="placeholder mt-2 col-6" />
            <span className="placeholder mt-2 col-5 ms-5" /> </div>
        )
    }
     /**
     * useEffect for paytrace payment success/fail
     */
    useEffect(() => {
        if (Object.keys(paymentResponse).length > 0) {
            if (paymentResponse && paymentResponse.response && paymentResponse.response.status !== 200) {
                showAlert(toast.TYPE.ERROR, `${AppConstant.smartScheduler.carrierSignUp.paytrace.paymentFailureMessage} ${AppConstant.smartScheduler.carrierSignUp.signUpAgainMessage}`, 'light')
            } else if (paymentResponse.success) {
                showAlert(toast.TYPE.SUCCESS, AppConstant.smartScheduler.carrierSignUp.paytrace.paymentSuccessMessage, 'light');
                dispatch({
                    type: sagaActions.UPDATE_USER_ACCOUNT_DETAILS_RENEW, payload: {
                        ...userAccountDetails, "SubscriptionStatus": "ACTIVE"
                    }
                })
                dispatch({
                    type: sagaActions.GET_USER_PERMISSIONS,
                    payload: {
                        warehouse_id: null,
                        user_type: userType,
                        cu_id: user?.auth?.username,
                    }
                })
            }
        }
    }, [paymentResponse])

    /** 
     * useEffect for to validate user subscription
     */
    useEffect(() => {
        if (user.sessionToken && !_.isEmpty(permissions)) {
            if (permissions?.message === AppConstant.userPermission.userPermissionNoData) {
                removeInterceptor()
                logoutUser()
                dispatch({ type: sagaActions.CLEAR_SIGNEDIN_USER })
                dispatch({ type: sagaActions.RESET_USER })
                navigate("/")
                showAlert(toast.TYPE.ERROR, AppConstant.userPermission.toast.userPermissionFail);
                window.localStorage.clear();
                return
            }
            const dynamicNav = createNavBar(permissions, userType)
            dispatch({ type: sagaActions.UPDATE_NAV_BAR, payload: dynamicNav })
            if (userAccountDetails.EnableStatus && userAccountDetails.SubscriptionStatus === "ACTIVE") {
                // redirectUser()
            } else {

            }
        } else {
        }
    }, [permissions])

    /**
     * Function to redirect user
     */
    const redirectUser = () => {
        const page = _.find(permissions, function (x) { return x.PageAccess === true && x.NavigationType === 'Primary' })
        if (!_.isEmpty(page)) {
            dispatch({ type: sagaActions.RESET_PAYTRACE_DATA });
            navigate(page.PageUrl);
        } else {
        }
    }
    /**
     * function to handle paytrace API for payment
     */
    const hitInhousePaymentsApi = () => {
        if (paymentInstance) {
            paymentInstance.process()
                .then((resPaytrace) => {
                    const paymentApiParams = {
                        "email": user.auth.attributes.email,
                        "hpf_token": resPaytrace.message.hpf_token,
                        "enc_key": resPaytrace.message.enc_key,
                        "customer_id": user.auth.username,
                        "csc": "",
                        "AccountNumber": carrierFromSignUpUserObj?.AccountNumber
                    }
                    dispatch({ type: sagaActions.HIT_PAYTRACE_PAYMENT_API, payload: paymentApiParams });
                    setIFramePaymentCompletedFlag(true);
                })
                .catch((err) => {
                    setIsPaymentLoading(false)
                    const errorStr = `${AppConstant.smartScheduler.carrierSignUp.paytrace.paymentFailureMessage}${err}`
                    showAlert(toast.TYPE.ERROR, errorStr, 'light')
                });
        }
    }
     /**
     * handler function for paytrace payment
     */
    const handlePayment = async () => {
        setIframValidationErrors([])
        PTPayment.validate(function (validationErrors) {
            if (validationErrors.length >= 1) {
                setIframValidationErrors(validationErrors)
                validationErrors.map((ele, index) => {
                    const errorStr = `${AppConstant.smartScheduler.carrierSignUp.paytrace.cardValidationError} ${ele.description}`;
                })
            } else {
                setIframValidationErrors([])
                setIsPaymentLoading(true);
                hitInhousePaymentsApi();
            }
        });
    }
    /**
     * Function to logout user in case of error in payment
     */
    const logoutUser = async () => {
        try {
            removeInterceptor()
            dispatch({ type: sagaActions.CLEAR_SIGNEDIN_USER })
            dispatch({ type: sagaActions.RESET_USER })
            setShowIFrame(false);
            setIFramePaymentCompletedFlag(false);
            setIsPaymentLoading(false);
            window.localStorage.clear();
            dispatch({ type: sagaActions.RESET_PAYTRACE_DATA });
        }
        catch (error) {
        }
    }
     /**
     * renders static data for payment process
     * @returns JSX 
     */
    const createForm = () => {
        return (
            <div className='fs-6 p-2'>
                <div className='fs-5 fw-bolder my-2'>
                    This is last step of sign up process,
                    if you cancel now you'll have to sign up again.<br />
                </div>
                <div className='fs-5 fw-bolder my-2'>
                    You'll miss out on the following.<br />
                </div>
                <div className='paymentInfoText '>
                    1. Visibility & remote management of all inbound assets.<br />
                    2. Instant ROI by removing delays in communication.<br />
                </div>
                <div className='fs-5 fw-bolder my-2'>
                    And many more benefits, on not signing up with FreightSmith<br />
                </div>
                <div className='fs-5 my-2'>
                    {AppConstant.smartScheduler.carrierSignUp.signOutConfirmMessage}
                </div>
            </div>
        );
    };

    /**
     * 
     * @returns Cancel subscription confirmation popup
     */
    const showCancelConfirmationPopup = () => {
        const popupObj = {
            id: 'createCompanyGroupFormPopup1',
            modalLabel: 'createCompanyGroupFormPopup1',
            showClose: false,
            showfooter: true,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'lg',
            title: AppConstant.smartScheduler.carrierSignUp.signOutConfirmMessage,
            bodyText: createForm(),
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.commonStrings.no
                },
                btn2: {
                    show: true,
                    text: AppConstant.commonStrings.yes
                }
            },
        }

        return <SSPopup {...popupObj} popupBtnHandler={(text) => popUpLogoutHandler(text)} closepopup={() => closeCurrentpopup()} themeSelected={themeSelected} showModal={showCancelModal} />
    }
    /**
     * function to close popup
     */
    const closeCurrentpopup = () => {
        showCancelModal(true);
    }
    /**
     * function for confirmation logout popup
     */
    const popUpLogoutHandler = (title) => {
        if (title === AppConstant.commonStrings.no) {
            setShowCancelModal(false);
        } else {
            navigate('/')
        }
    }
    /**
     * function for signup loader
     */
    const showFinalSignupLoader = () => {
        return (
            <div className="spinner-border" role="status"><span className="sr-only">{AppConstant.commonStrings.loading}...</span></div>
        )
    }

    const goBack = () => {
        logoutUser();
        navigate("/")
    }

    return (
        <div className='renewComponentParentContainer'>
            {/* <div className='fw-semibold mb-3'>{AppConstant.smartScheduler.carrierSignUp.introText1}</div> */}
            <div className='d-flex flex-column p-1'>
                {
                    !showPaymentSuccessView ?
                        <div className=''>
                            <div className='my-2 fs-6'>{AppConstant.smartScheduler.subscription.completeSubscriptionIntro1} <b className='fs-5'>: {AppConstant.smartScheduler.carrierSignUp.priceTitle}</b></div>
                            {isPaymentLoading && <div className='my-2 fs-6 text-danger'>{AppConstant.smartScheduler.carrierSignUp.paytrace.paymentWarning1}</div>}
                            <div className='mt-3 paytraceIFrameRenew-ss '>
                                {!iFramePaymentCompletedFlag ? <div id="pt_hpf_form" className='m-0 p-0 iFrameRenew' /> : <div>{AppConstant.smartScheduler.carrierSignUp.paytrace.paymentWarning2}</div>}
                                {showIFrameLoader ? showIFrameLoaderUI() : ''}
                                <div className='d-flex justify-content-start w-100'>
                                    <div>
                                        {iFrameValidationErrors.length ? showValidationErros() : ''}
                                    </div>
                                    <div className='container-fluid d-flex justify-content-between mt-3 w-100'>
                                        <div>
                                            <img src={PaymentCardsImage} alt="Cards" className='cardsImage ms-4' />
                                        </div>
                                        <div className='me-5 pe-4 d-flex gap-2'>
                                            <CustomButtonSS
                                                type="submit"
                                                className={`cancelPaymentButton me-4 iFrameButtons ${isPaymentLoading ? 'disabled' : ''}`}
                                                data-bs-toggle="modal"
                                                onClick={() => !isPaymentLoading && setShowCancelModal(true)}
                                                title={AppConstant.commonStrings.cancel}>
                                            </CustomButtonSS>
                                            <CustomButtonSS
                                                type="submit"
                                                isLoading={isPaymentLoading}
                                                className={`btn-highlighted iFrameButtons ${isPaymentLoading ? 'disabled' : ''}`}
                                                onClick={() => !isPaymentLoading && handlePayment()}
                                                title={AppConstant.commonStrings.subscribe}></CustomButtonSS>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className='mb-5 pb-5'>
                            <div className='text-start'>{`${AppConstant.smartScheduler.subscription.welcomeBack} ${userAccountDetails.FirstName},`}</div>
                            <div className='my-4 fs-6'>{AppConstant.smartScheduler.subscription.resubscribeMessage1} <b className='fs-5'>{AppConstant.commonStrings.freightSmith}</b></div>
                            <div className='mt-4 mb-3 fs-5'>{AppConstant.smartScheduler.carrierSignUp.signUpMessage2}</div>
                            {showFinalSignupLoader()}
                        </div>
                }
                {showCancelConfirmationPopup()}

            </div>

        </div>
    )
}

export default RenewSubscriptionComponentSS;