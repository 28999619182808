import React from 'react'
import { AppConstant } from '../../../Assests/AppConstant';
/**
 * This is reusable component for rendering input checkbox
 * @param {*} props 
 * onChangeValue = checkbox click handler
 * isEditable = if check box is editable or not
 * @returns 
 */
const CommonCheckBox = (props) => {
    const { onChangeValue, isEditable } = props;
    return (
        <div className={props.isAccessDisabled ? "input-group disabled" : "input-group"} data-testid={AppConstant.dataTestId.commonCheckBox}>
            <div className="input-group-text">
                {
                    isEditable && !props.isAccessDisabled ?
                        (
                            props.isEnabled ?
                                <input data-testid='common_check_box_checked' className="form-check-input mt-0" type="checkbox" checked={true} value="" aria-label="Checkbox for following text input" onChange={(rowData) => onChangeValue(rowData)} />
                                :
                                <input data-testid='common_check_box_unchecked' className="form-check-input mt-0" type="checkbox" checked={false} value="" aria-label="Checkbox for following text input" onChange={(rowData) => onChangeValue(rowData)} />
                        )
                        :
                        <input data-testid='common_check_box_disabled' className="form-check-input mt-0" type="checkbox" disabled checked={false} value="" aria-label="Checkbox for following text input" onChange={(rowData) => onChangeValue(rowData)} />
                }
            </div>
        </div>
    )
}

export default CommonCheckBox;