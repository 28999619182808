import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    error: null,
    loading: false,
    editLoading: false,
    data:'',
    arrangeOrder: 'desc',
    arrangeKey: 'checkindateandtime',
} 

export const DriverCheckinReducer = createSlice({
    name: 'driverCheckinReducer',
    initialState,
    reducers: {
        initiateLoading: (state) => {
          state.loading = true
         },
         driverCheckinSuccess: (state, action) => {
           state.loading = false;
            state.data = action.payload;
            state.error = null;
        },
        driverCheckinFailure: (state, action) => {
            state.loading = false;
            state.editLoading = false;
            state.error = action.payload;
            state.data = '';
        },
        resetData:(state,action)=>{
            state.loading = false;
            state.editLoading = false;
            state.data = ''; 
            state.error = null;
        },
       sortTableData: (state, action) => {
            const { drillDownReport, arrange,  } = action.payload;
            state.data.data = drillDownReport
            state.arrangeKey = arrange.key
            state.arrangeOrder = arrange.order
        },
        editDriverReportLoader: (state, action) => { 
            state.editLoading = action.payload
        }
    }
});

export const { driverCheckinSuccess, driverCheckinFailure,initiateLoading,resetData,sortTableData, editDriverReportLoader} = DriverCheckinReducer.actions;

// Selectors
export const getDriverCheckinReducer = (state) => {
    return state.DriverCheckinReducer;
};

export default DriverCheckinReducer.reducer;
