import { useSelector, useDispatch } from 'react-redux'
import { useState, useEffect } from 'react';
import { AppConstant } from '../../../../Assests/AppConstant';
import { CustomDynamicForm } from '../../../Common/CustomForm/CustomDynamicForm';
import { getUserSelector } from '../../../../../redux/reducers/reducerSlices/UserReducer';
import { sagaActions } from '../../../../../sagas/sagaActions';
import { getDriverCheckinReducer } from '../../../../../redux/reducers/reducerSlices/DriverCheckinReducer';
import _ from 'lodash';
import { customStylesLight, formDropDownDataforGaurd } from '../../../../Assests/Utility';
// @ts-ignore
import { getRemoteConfigSelector } from '../../../../../redux/reducers/reducerSlices/RemoteConfigReducer';
import { smartScheduleSelector } from '../../../../../redux/reducers/reducerSlices/SmartScheduleReducer';

export default function InitalCheckInForm(props) {
  const dispatch = useDispatch();
  const { themeSelected,didSearched,isCheckIn,searchPoDataState } = props;
 

  const user = useSelector(getUserSelector);
  const driverData = useSelector(getDriverCheckinReducer);
  //Get Config Data from Redux
  const remoteConfig = useSelector(getRemoteConfigSelector);
  const { allConfigration } = remoteConfig
  const { GuardDestination } = allConfigration

  const TransTypeList = allConfigration?.GuardSearchLoadTypeList?.slice(1, -1).split(',');
  const { PaginationDefaultLimit } = allConfigration
  const { cu_id, userAccountDetails, userType, selectedWarehouse } = user;
  const [editData, setEditData] = useState({});

  const [loading, setLoading] = useState(false);
  const [resetFormData, setResetFormData] = useState(0);
  const [particularFieldValue, setParticularFieldValue] = useState("");
  const [stateData, setStateData] = useState([]);
  const [selectedState, setSelectedState] = useState({})
  const ss = useSelector(smartScheduleSelector)
  const { cityData } = ss;
  //Fill Data in Form
  useEffect(() => {
    if (driverData.data) {
      setEditData({ ...driverData.data, phone_number: driverData.data.DPhoneNo, poStatus: driverData.data.poStatus, trailerNumber: driverData.data.trailerNumber,  originLocation: {
        label: driverData?.data.originLocation,
        value: driverData?.data.originLocation
    } })
    }
    else {
      setEditData({})
    }
  }, [driverData.data])
  useEffect(() => {
    dispatch({
      type: sagaActions.GET_STATE_WITH_ZIPCODE, payload: {
        Text: '',
        type: 'C'
    } })
    if (!_.size(driverData?.data) || driverData?.data) {
      setLoading(false)
      setTimeout(() => {
        if (props.selectedRowData) {
          fetchArrivalSingleData()
        }
        if (props.inputData.transType === TransTypeList[TransTypeList.length - 1]) {
          fetchArrivalTrailerData()
        }
      }, [500])
    }
    setStateData([])
  }, [])
 
  useEffect(() => {
    if (_.size(driverData?.data)) {
      if ((driverData.data.CRUDTYPE === "I" || driverData.data.CRUDTYPE === "IDO" || driverData.data.CRUDTYPE === "RC") && loading) {
        setLoading(false)
        //showAlert(toast.TYPE.SUCCESS, "Driver arrived successfully", themeSelected,toast.POSITION.TOP_CENTER);
        props.clearScreen();
        setTimeout(() => {
          if (props.selectedRowData) {
            fetchArrivalSingleData()
          }
          if (props.inputData.transType === TransTypeList[TransTypeList.length-1]) {
            fetchArrivalTrailerData()
          }
        }, [500])
      }
    }

  }, [driverData])

  /**
   * useeffect to get city via zip code
   */
  useEffect(() => {
    if (cityData && _.size(cityData)) {
      setStateData([...cityData])
    }
  }, [cityData])

    //Get PoType DropDown Data
    const getPOTypeList = () => formDropDownDataforGaurd(allConfigration, 'GuardPOTypeList')
     //Get Yard Location DropDown Data
    const getYardLocationList = () => formDropDownDataforGaurd(allConfigration, 'GuardYardLocationList')
  
  const onInputChangeHandler = (e) => {
    if (e.length >= 3) {
      dispatch({
        type: sagaActions.GET_STATE_WITH_ZIPCODE, payload: {
          Text: e,
          type: 'G'
      } })
    }
  }

  /** Drowdown change handler */
  const onChangeValue = (props) => {
    // console.log("🚀 ~ onChangeValue ~ props:", props)
    // setSelectedState(props)
}

  const formatCreateLabel = (props) => { };

    const formFieldList = [
        {
            size: "col-sm-4 mt-1 custom-form-input-highlighted",
            name: "DFirstName",
            type: "text",
             position: 1,
             title:AppConstant.driver.dfirstName,
            formValidation: {
              required: true,
              allowOnlyText:true,
            }
          },
          {
            size: "col-sm-4 mt-1 custom-form-input-highlighted",
            name: "DlastName",
            type: "text",
           position: 2,
            title: AppConstant.driver.dlastName,
            formValidation: {
              required: true,
              allowOnlyText:true,
            }
          },
       
        {
          name: "phone_number",
          type: "text",
          size: "col-sm-4 custom-form-input-highlighted",
          position: 3,
          placeholder: AppConstant.user.popup.createUser.phone_placeHolder,
          title:  AppConstant.driver.dPhone,
          formValidation: {
              required: true,
              validateContactNum: true,
              lengthValidation: {
                minLength: 10,
                customError: AppConstant.commonStrings.phoneCustomError
            },
              customError: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.contactNumErrorMessage
          },
        },
      
        {
            size: "col-sm-4 mt-1 custom-form-input-highlighted",
            name: "DLicenceNo",
            type: "text",
           position: 4,
            title:  AppConstant.driver.dLicence,
            formValidation: {
              required: true,
            }
          },
          {
            size: "col-sm-4 mt-1 custom-form-input-highlighted",
            name: "trailerNumber",
            type: "text",
           position: 4,
            title:  AppConstant.driver.trailerNumber,
            formValidation: {
              required: true,
            }
          },
          {
            name: "trailerType",
            type: "radio",
            size: "col-sm-4 mt-1 radioButtonText custom-form-input-highlighted",
            position: 4,
            title: 'Trailer Type:',

            formValidation: {
                required: true,
            },
            radiconfig: [{
              label: AppConstant.driver.trailerTypeRadioBtn.Dropped,
              value: AppConstant.driver.trailerTypeRadioBtn.Dropped
          },
          {
              label: AppConstant.driver.trailerTypeRadioBtn.Live,
              value: AppConstant.driver.trailerTypeRadioBtn.Live
          },
          // {
          //   label: "Neither",
          //   value: "Neither"
          //     }
            ]

        },
        {
          size: "col-sm-4 mt-1",
          name: "carrierCode",
          type: "text",
         position: 4,
          title:  AppConstant.driver.carrierCode,
          formValidation: {
            required: false,
          }
        },
          {
            size: "col-sm-4 mt-1",
            name: "shipmentId",
            type: "text",
           position: 4,
            title:  AppConstant.driver.vendor,
            formValidation: {
              required: false,
            }
      },
      {
        name: "originLocation",
        type: "select_with_input_search_add_button_withoutcreate",
        size: "col-sm-4 mb-3 fs-6",
        parentClass: "inputTitle",
        componentClassName: "mt-1 inputDiv border border-1 rounded-1 border-dark",
        inputTitle: AppConstant.driver.originLocation,
        position: 4,
        title: AppConstant.driver.originLocation,
        customStylesLight: customStylesLight,
        placeholderText: "Enter zip code",
        onInputChange: onInputChangeHandler,
        onChange: onChangeValue,
        defaultValue: !_.isEmpty(selectedState)
          ? selectedState
          : "Enter zip code",
        isLoading: false,
        isClearable: true,
        isSearchable: true,
        options: stateData,
        formatCreateLabel: formatCreateLabel,
        loadingMessage: "Please wait loading....",
        formValidation: {
          required: false,
        },
      },
          // {
          //   size: "col-sm-4 mt-1",
          //   name: "originLocation",
          //   type: "select_with_input_search_add_button",
          //  position: 4,
          //   title:  AppConstant.driver.originLocation,
          //   formValidation: {
          //     required: false,
          //   },
          //   // getSelectData: getOriginLocationList,
          // },
          //not required
          // {
          //   size: "col-sm-4 mt-1",
          //   name: "destination",
          //   type: "text",
          //  position: 4,
          //   title:  AppConstant.driver.destination,
          //   formValidation: {
          //     required: false,
          //   },
          //   isEditDisable: true
          // },
          {
            size: "col-sm-4 mt-1",
            name: "trailerName",
            type: "text",
           position: 4,
            title:  AppConstant.driver.trailerName,
            formValidation: {
              required: false,
            }
          },
          {
            size: "col-sm-4 mt-1",
            name: "yardLocation",
            type: "select",
           position: 4,
            title:  AppConstant.driver.yardLocation,
            formValidation: {
              required: false,
            },
            getSelectData: getYardLocationList,
          },
          // {
          //   size: "col-sm-4 mt-1",
          //   name: "poStatus",
          //   type: "select",
          //  position: 4,
          //   title:  AppConstant.driver.poStatus,
          //   formValidation: {
          //     required: false,
          //   },
          //   getSelectData: getPOStatusList,
          // },
          {
            size: "col-sm-4 mt-1",
            name: "poType",
            type: "select",
           position: 4,
            title:  AppConstant.driver.poType,
            formValidation: {
              required: false,
            },
            getSelectData: getPOTypeList,
          },
          // {
          //   size: "col-sm-4 mt-1",
          //   name: "memo",
          //   type: "select",
          //  position: 4,
          //   title:  AppConstant.driver.memo,
          //   formValidation: {
          //     required: false,
          //   },
          //   getSelectData: getMemoList
      // },
      {
        size: "col-sm-4 mt-1",
        name: "memo",
        type: "text",
       position: 4,
        title:  AppConstant.driver.comment,
        formValidation: {
          required: false,
        },
        // getSelectData: getMemoList
      },
      ]

    // Render Button For Check In and Check Out
      const submitbtnDataCheckIn = {
        show: true,
        text: driverData.data.checkindateandtime?"Re-Check in":AppConstant.driver.checkInNowTitle
      };
      const submitbtnDataCheckOut = {
        show: driverData.data.checkindateandtime?true:false,
        text: AppConstant.driver.checkOutNowTitle,
      };
      const getSubmitBtnData=()=>{
        if (!driverData.loading) {
        if (isCheckIn) {
          return submitbtnDataCheckIn
        }
        else{
          return driverData.data.checkindateandtime && !driverData.data.checkoutDateAndTime?submitbtnDataCheckOut:null
        }
      }
      }
      const getFormFieldData = (data, name, control,inputs) => {   

      
      };
      const getSummitedFormData = (data) => {
        let allPoslist=[];
        if (props.searchPoDataState && props.searchPoDataState.loads && props.searchPoDataState.loads.length && props.searchPoDataState?.loads[0]?.pos) {
          props.searchPoDataState?.loads[0]?.pos?.map((data)=>allPoslist.push(data.po_number))
        }
        else{
          allPoslist.push(data.trailerNumber)
        }

        let submittedData={
          ...data,
          originLocation: data?.originLocation?.label,
          DPhoneNo: data.phone_number || "",
          WhId: props.warehouseData.ID || "",
          poId: props.inputData.transType === AppConstant.commonStrings.inBoundLoadTitle ? props.selectedRowData.po_number : data.trailerNumber,
          createdby: userAccountDetails.CUID || "",
          CRUDTYPE: isCheckIn && !driverData?.data?.checkindateandtime ? "I" : isCheckIn && driverData?.data?.checkindateandtime ? "RC" : "IDO",
          TimeZone: selectedWarehouse.WarehouseRegion || "",
          WarehouseName: props.warehouseData.Name || "",
          Address: props.warehouseData.Address || "",
          State: props.warehouseData.State || "",
          City: props.warehouseData.City || "",
          checkoutDateAndTime: "",
          limit: PaginationDefaultLimit,
          idTruck: props.inputData.transType === AppConstant.commonStrings.inBoundLoadTitle ? props?.searchPoDataState?.loads[0]?.load_id : driverData?.data?.idTruck || '',
          allPoslist: allPoslist?.toString(),
          trailerType: data.trailerType || "",
          transType: props.inputData.transType === AppConstant.commonStrings.inBoundLoadTitle ? AppConstant.commonStrings.loadTitle : "Trailer",
          pageNo: 1,
        }
       setLoading(true);
       dispatch({ type: sagaActions.DRIVER_CHECKIN, payload: submittedData })
      };

      useEffect(() => {
        if(props){       
        dispatch({ type: sagaActions.RESET_DRIVER_CHECKIN})
          if(props.selectedRowData){
            fetchArrivalSingleData()
          }
          if (props.inputData.transType===TransTypeList[0]) {
            fetchArrivalTrailerData()
          }
        if(props.DriverDetails){
            let inputsData={
              ...props?.DriverDetails,
            phone_number:props.inputData.phoneNo,
            trailerType:"Dropped",
            
            } 
           inputsData.DlastName=props.DriverDetails.DLastName;
          inputsData.destination = GuardDestination
            setTimeout(() => {
              setEditData(inputsData);
          }, [500])
          
          }
          else{
            let inputsData={
              phone_number:props.inputData.phoneNo,
              trailerNumber:props.inputData.transType!==AppConstant.commonStrings.inBoundLoadTitle?props.inputData.po:"",
              trailerType:"Dropped",
            }  
          inputsData.destination = GuardDestination;
            setTimeout(() => {
              setEditData(inputsData);
          }, [500])
          }
        }
      
    },[props.selectedRowData])

    
    const fetchArrivalSingleData=()=>{
      let checkindata={
        WhId:props.warehouseData.ID,
        poId: props.selectedRowData.po_number ,
        CRUDTYPE:"SS",
        transType:"Load"
      }
       dispatch({ type: sagaActions.DRIVER_CHECKIN, payload: checkindata })
    }

    const fetchArrivalTrailerData=()=>{
      let checkindata={
        WhId:props.warehouseData.ID,
        poId:props.inputData.po,
        CRUDTYPE:"SS",
        transType:"Trailer"
      }
       dispatch({ type: sagaActions.DRIVER_CHECKIN, payload: checkindata })
    }
      useEffect(() => {
    
        if (_.size(driverData)) {
         if((driverData.data.CRUDTYPE==="I" || driverData.data.CRUDTYPE==="IDO" ||driverData.data.CRUDTYPE==="RC") && loading){
          setLoading(false)
          //showAlert(toast.TYPE.SUCCESS, "Driver arrived successfully", themeSelected,toast.POSITION.TOP_CENTER);
          props.clearScreen(); 
            setTimeout(() => {
              if(props.selectedRowData){
                fetchArrivalSingleData()
              }
              if (props.inputData.transType===TransTypeList[0]) {
                fetchArrivalTrailerData()
              }
          }, [500])
         
          
          }
        
      //  else{
      //   let inputs={
      //     ...driverData.data,
      //     phone_number:driverData.data.DPhoneNo
      //   } 
      //   setEditData(inputs);
      //  }
           
        } 
    },[driverData])
  return (
    <>
    <span className='f-16 textRed'>Please Verify Driver's Information:</span>
   <CustomDynamicForm
        formFieldList={props.inputData.transType!==AppConstant.commonStrings.inBoundLoadTitle?formFieldList:formFieldList.slice(0,-1)}
        sort={true}
        submitbtn={getSubmitBtnData()}
        themeSelected={themeSelected}
        getFormFieldData={getFormFieldData}
        getSummitedFormData={getSummitedFormData}
         editFormData={editData}
        loading={loading }
        resetFormData={resetFormData}
        customDisableSave={didSearched}
        setParticularFieldValue={particularFieldValue}
      />
        </>
  )
}
