import React from 'react'
/**
 * This is a reusable component for Separator on UI
 * @param {*} props 
 * @returns 
 */
const Seperator = (props) => {
    const { seperatorStyles } = props
    return (
        <div style={seperatorStyles} data-testid='seperator'/>
    )
}

export default Seperator
