import React, { useEffect, useState } from 'react'
import FacilityRatingsTabular from '../../Children/Tabular/FacilityRatingsTabular';
import FacilityRatingsGraphical from '../../Children/Graphical/FacilityRatingsGraphical';
import CustomButton from '../../../../../../../Common/CustomButton/CustomButton';
import { showAlert } from '../../../../../../../../Assests/Utility';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { sagaActions } from '../../../../../../../../../sagas/sagaActions';
import { AppConstant } from '../../../../../../../../Assests/AppConstant';
import { calculateMonthDifference } from '../../../../../../../../../Utils/DateUtils';
import DownloadSectionBE from '../../../../../DownloadSection/DownloadSectionBE';
import { getUserSelector } from '../../../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getCurrentDate } from '../../../../../../../../../Utils/DateUtils';


const FacilityRatingsWrapper = (props) => {
    const dispatch = useDispatch()
    const [view, setView] = useState('T');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const userObj = useSelector(getUserSelector);
    const { selectedWarehouse, cu_id, userType, userAccountDetails } = userObj;
    const [currentFilterSelected, setCurrentFilterSelected] = useState({ name: 'All' });

    const [isError, setIsError] = useState(false)
  
  useEffect(() => {
    const dStart = new Date()
    const dEnd = new Date()
    dEnd.setUTCHours(23, 59, 59, 999);
      setStartDate(new Date(dStart.getFullYear(), dStart.getMonth(), 1));
      setEndDate(new Date(dEnd.getFullYear(), dEnd.getMonth() + 1, 0))
    }, [])
    
  const handleStartYearChange = (year) => {

        const newStartDate = new Date(year, startDate.getMonth(), 1);
        if (newStartDate > endDate) {
          showAlert(toast.TYPE.ERROR, AppConstant.reports.facilityRatings.startGreaterThanEndError, 'light')
          setIsError(true)
          // return;
            // setEndDate(newStartDate);
        } else if (calculateMonthDifference(newStartDate, endDate) > 2) {
          showAlert(toast.TYPE.ERROR, AppConstant.reports.facilityRatings.dateDiffGreaterThan12Months, 'light')
          setIsError(true)
          // return;
            // setEndDate(newStartDate);
        } else {
          setIsError(false)
        }
        setStartDate(newStartDate);
      };
    
    const handleEndYearChange = (year) => {
      const newEndDate = new Date(year, endDate.getMonth() + 1, 0);
      newEndDate.setMonth(newEndDate.getMonth() + 1, 0);
        if (newEndDate < startDate) {
          showAlert(toast.TYPE.ERROR, AppConstant.reports.facilityRatings.endGreaterThanStartError, 'light')
          setIsError(true)
          // return;
            // setStartDate(newEndDate);
        }else if (calculateMonthDifference(startDate, newEndDate) > 2) {
          showAlert(toast.TYPE.ERROR, AppConstant.reports.facilityRatings.dateDiffGreaterThan12Months, 'light')
          setIsError(true)
          // return;
            // setStartDate(newEndDate);
        } else {
          setIsError(false)
      }
      setEndDate(newEndDate);
      };
    
    const handleStartDateChange = (date) => {
        if (date > endDate) {
          showAlert(toast.TYPE.ERROR, AppConstant.reports.facilityRatings.startGreaterThanEndError, 'light')
          setIsError(true)
          // return;
            // setEndDate(date);
        }else if (calculateMonthDifference(date, endDate) > 2) {
          showAlert(toast.TYPE.ERROR, AppConstant.reports.facilityRatings.dateDiffGreaterThan12Months, 'light')
          setIsError(true)
          // return;
            // setEndDate(date);
        } else {
      setIsError(false)
      }
       
      date.setDate(1);
      setStartDate(new Date(date.getFullYear(), date.getMonth(), 1));
      };
    
    const handleEndDateChange = (date) => {
        if (date < startDate) {
          showAlert(toast.TYPE.ERROR, AppConstant.reports.facilityRatings.endGreaterThanStartError, 'light')
          setIsError(true)
          // return;
            // setStartDate(date);
        }else if (calculateMonthDifference(startDate, date) > 2) {
          showAlert(toast.TYPE.ERROR, AppConstant.reports.facilityRatings.dateDiffGreaterThan12Months, 'light')
          setIsError(true)
          // return;
            // setStartDate(date);
        } else {
          setIsError(false)
      }
      date.setMonth(date.getMonth() + 1, 0);
      setEndDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));
      };

      const downloadData = (type)=>{
        const eDate = moment(endDate).hours(23).minutes(59).seconds(59)
        let payload = {
          fileType: type,
          fileName: "Facility-Ratings-" + userAccountDetails?.UserName,
          rating: currentFilterSelected?.name === 'All' ? null : currentFilterSelected?.name,
          startDate: moment(startDate).format(AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
          endDate: moment(eDate).format(AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
          warehouse_id: selectedWarehouse.ID,
        }
          dispatch({ type: sagaActions.FACILITY_RATIING_DOWNLOAD, payload });
      }
      
      const setCurrentFilterDetails =(data)=>{
        setCurrentFilterSelected(data);

      }
    
    return (
        <>
            <div className='m-2 d-flex justify-content-between'>
              <div>
                <CustomButton
                            disabled={view === 'T' ? true: false}
                            isLoading={false}
                            title={'Tabular'}
                            className={`addButton mx-1 ${view === 'T' ? '': 'disabledActionColor'}`}
                    onClick={() => {
                        dispatch({ type: sagaActions.RESET_FACILITY_RATINGS_ACTION });
                        setView('T')
                            }}>Tabular</CustomButton>
                <CustomButton
                             disabled={view === 'G' ? true: false}
                            isLoading={false}
                            title={'Graphical'}
                            className={`addButton mx-1 ${view === 'G' ? '': 'disabledActionColor'}`}
                    onClick={() => {
                        dispatch({ type: sagaActions.RESET_FACILITY_RATINGS_ACTION });
                        setView('G')
                    }}
                >Graphical</CustomButton>
                </div>
                <DownloadSectionBE downloadData={downloadData} isDisabled={isError} ></DownloadSectionBE>
            </div>
        {view === 'T' ? <FacilityRatingsTabular {...props} view={view} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} handleStartYearChange={(d) => handleStartYearChange(d)} handleEndYearChange={(d) => handleEndYearChange(d)} handleStartDateChange={handleStartDateChange} handleEndDateChange={handleEndDateChange} isError={ isError}setCurrentFilterDetails={setCurrentFilterDetails}/> : <FacilityRatingsGraphical {...props} view={view} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} handleStartYearChange={(d) => handleStartYearChange(d)} handleEndYearChange={(d) => handleEndYearChange(d)} handleStartDateChange={handleStartDateChange} handleEndDateChange={handleEndDateChange} isError={ isError} /> }
        </>
    );
}

export default FacilityRatingsWrapper;