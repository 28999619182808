import React, { useState } from "react";
import { AppConstant } from "../../../Assests/AppConstant";
import { ReactComponent as Feedback } from "../../../Assests/Svg/Feedback.svg";
import { ReactComponent as Help } from "../../../Assests/Svg/Help.svg";
import CustomTooltip from "../../Common/Tooltip/CustomTooltip";
import GlobalModal from "./Modal/GloabalModal";
import HelpContainer from "../Container/HelpAndFeedBack/HelpAndFeedBack";
const FeedbackAndHelp = () => {
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [formName, setFormName] = useState("Help");
  const closeHelpModal = () => {
    setShowHelpModal(false);
  };
  const openModal=(name)=>{
    setFormName(name);
    setShowHelpModal(true);
  }
  return (
    <>
      <GlobalModal showModal={showHelpModal}>
        <HelpContainer formName={formName} closeHelpModal={closeHelpModal} />
      </GlobalModal>

      <div className="d-flex flex-row">
        <button
          data-testid={AppConstant.dataTestId.feedback}
          id="feedback_tooltip"
          className="feedbackButton"
          data-tip={AppConstant.login.feedback_text}
          data-for={AppConstant.login.feedback_tooltip}
          onClick={() => {openModal("Feedback");}}
        >
          <Feedback />
          <CustomTooltip
            id={AppConstant.login.feedback_tooltip}
            position="top"
            type="dark"
            multiline={false}
          />
        </button>
        <button
          data-testid={AppConstant.dataTestId.help}
          className="helpButton cursor-pointer"
          data-tip={AppConstant.login.help_text}
          data-for={AppConstant.login.help_tooltip}
          onClick={() => {openModal("Help");
          }}
        >
          <Help />
          <CustomTooltip
            id={AppConstant.login.help_tooltip}
            position="top"
            type="dark"
            multiline={false}
          />
        </button>
      </div>
    </>
  );
};

export default FeedbackAndHelp;
