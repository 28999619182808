import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { getLoadSelector } from '../../../../../../../redux/reducers/reducerSlices/getLoadReducer';
import { AppConstant } from '../../../../../../Assests/AppConstant';
import { displayLoader } from '../../../../../../Assests/Utility';

import './POScheduled.css'

const POScheduled = (props) => {
    const { themeSelected } = props;
    const getLoad = useSelector(getLoadSelector);
    const { getLoadLoading, selectedLoad } = getLoad;
    const { scheduled_pos } = selectedLoad;

    const renderPOs = () => {
        if (getLoadLoading) {
            return renderScheduledPos(true)
        } else if (!getLoadLoading && !_.isEmpty(selectedLoad) && _.size(scheduled_pos)) {
            return renderScheduledPos(false)
        } else {
            return null
        }
    }

    const renderScheduledPos = (showLoader) => {
        return showLoader ? <div className='d-flex flex-row align-items-stretch justify-content-start m-3'>
            <div className={`col-4 px-3 py-2 ${themeSelected}`}>
                {displayLoader(themeSelected)}
            </div>
            <div className='col-8 px-3 py-2'>
                {displayLoader(themeSelected)}
            </div>

        </div> :

            <div className='poScheduled-wrapper d-md-flex flex-md-row d-sm-flex flex-sm-column align-items-stretch justify-content-start m-3'>
                <div className={`d-flex flex-column justify-content-center poHeading-wrapper col-md-4 col-sm-12 py-2 ${themeSelected}`}>
                    <div className='heading align-self-start ps-3'>{AppConstant.poManagement.poApproval.poScheduled}</div>
                    <div className='subText align-self-start ps-3'>{AppConstant.poManagement.poApproval.poScheduledSubText}</div>
                </div>
                <div className='col-md-8 col-sm-12 d-flex flex-row flex-wrap px-3 py-1'>
                    {scheduled_pos.map(el => {
                        return <div className='table-title-checkBox d-flex flex-row justify-content-center align-items-center pe-4 py-1' key={el}>
                            <input type="checkbox" name={el} checked disabled />
                            <label className="px-2 heading" htmlFor={el}>{el}</label>
                        </div>

                    })}
                </div>
            </div>

    }

    return (
        <>
            {
                renderPOs()
            }
        </>
    );
}

export default POScheduled;