import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import _ from 'lodash';
import { getUserSelector } from '../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { getAuthReducer } from '../../../../../../../redux/reducers/reducerSlices/AuthReducer';
import { sagaActions } from '../../../../../../../sagas/sagaActions';
import { formatContactNumNNN, initiateSearch, showAlert } from '../../../../../../Assests/Utility';
import { AppConstant } from '../../../../../../Assests/AppConstant';
import DrilldownTableSS from '../../../../../LandingPage/Components/Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTableSS';
import CustomButtonSS from '../../../../../Common/CustomButton/CustomButtonSS';
import CommonToggleSwitch from '../../../../../Common/CommonToggleSwitch/CommonToggleSwitch';
import EditIcon from '../../../../../../Assests/Images/edit-icon-new.png'
import { toast } from 'react-toastify';

const UserTableSS = (props) => {
    const { themeSelected, allowPermissionED, editUserAction, searchStr, } = props;
    const [userListData, setUserListData] = useState([]);
    const user = useSelector(getUserSelector)
    const { initateLoading, userList, carrierSubUserList, userType, arrangeOrder, arrangeKey, cu_id, userAccountDetails, actionLoader } = user;
    const userAuth = useSelector(getAuthReducer);
    const { user_name } = userAuth;
    const dispatch = useDispatch()
    const [currentIndex, setCurrentIndex] = useState(null)


    useEffect(() => {
        dispatch({ type: sagaActions.INITIATE_LOADING, payload: true })
        dispatch({ type: sagaActions.UPDATE_CARRIER_SUB_USER, payload: createPayload() })
    }, [])

    useEffect(() => {
        setCurrentIndex(null);
        if (carrierSubUserList) {
            initateLoading && dispatch({ type: sagaActions.INITIATE_LOADING, payload: false })
            if (searchStr) {
                const searchedArr = initiateSearch(carrierSubUserList, searchStr, 'UserName');
                setUserListData(searchedArr);
            } else {
                setUserListData(carrierSubUserList);
            }
        }
    }, [carrierSubUserList])

    useEffect(() => {
        if (searchStr.length > 3) {
            const searchedArr = initiateSearch(carrierSubUserList, searchStr, 'UserName');
            setUserListData(searchedArr);
        } else if (searchStr === '') {
            setUserListData(carrierSubUserList)
        }
    }, [searchStr])


    const createPayload = () => {
        return {
            UserName: "",
            Email: "",
            CreatedBy: "",
            UpdatedBy: "",
            EnableStatus: null,
            CUID: "",
            CRUDTYPE: "S",
            LastName: "",
            FirstName: "",
            phone: "",
            IDCarrier: userAccountDetails.IDCarrier,
            CarrierName: "",
            PrimaryUserCUID: cu_id
        }
    }

    const warehouseTitleDD = [
        { id: 'serial_num', name: 'S.No' },
        { id: 'UserName', name: 'Username' },
        { id: 'Email', name: 'Email' },
        { id: 'FirstName', name: 'First Name' },
        { id: 'LastName', name: 'Last Name' },
        { id: 'Phone', name: 'Phone Number' },
        { id: 'CreatedOn', name: 'Created on' },
        { id: 'actions', name: 'Actions' },
    ]


    const disableUserAction = (data, status) => {
        if ((data.EnableStatus === 0 || data.EnableStatus === false) && allowPermissionED() >= 2) {
            showAlert(toast.TYPE.ERROR, AppConstant.commonStrings.carrierSubUserLimitExceed, themeSelected)
        }
        else {
            setCurrentIndex(data.UserID)
            const finalData = { ...data }
            finalData.EnableStatus = finalData.EnableStatus ? 0 : 1;
            finalData.CRUDTYPE = 'U';
            finalData.PrimaryUserCUID = data.PrimaryUserID;
            finalData.phone = data.Phone
            dispatch({ type: sagaActions.UPDATE_CARRIER_SUB_USER, payload: finalData })
        }
    }


    const actionsButton = (el) => {
        return (
            <div className='d-flex flex-row align-items-center'>
                <span className='px-1'>
                    <img src={EditIcon} alt='edit' className='editIconPO-ss'
                        dataBsToggle="modal"
                        dataBsTarget={`#createUserFormPopup`}
                        disabled={false}
                        onClick={() => editUserAction(el)}
                    />
                </span>

                <span className='px-1'>
                    <CustomButtonSS
                        onClick={() => { disableUserAction(el) }}
                        title={el.EnableStatus ? AppConstant.commonStrings.disable : AppConstant.commonStrings.enable}
                        className={`${el.EnableStatus ? ' btnDisable-ss ' : 'btnEnable-ss'} mx-2`}
                        isLoading={el.UserID === currentIndex ? true : false}
                    />
                </span>

            </div>
        )
    }

    const formatListData = (data) => {
        return data.map((el, index) => {
            return {
                id: `${el.UserID}_${el.LastName}`,
                0: index + 1,
                1: el.UserName || '-',
                2: el.Email,
                3: el.FirstName || '-',
                4: el.LastName || '-',
                5: el.Phone ? formatContactNumNNN(el.Phone) : '-',
                6: el.CreatedOn,
                7: actionsButton(el),
            }
        })
    }

    return (
        <DrilldownTableSS
            themeSelected={themeSelected}
            bodyData={userListData && userListData?.length ? formatListData(userListData):[]}
            titleData={warehouseTitleDD}
            showCollapse={false}
            showInput={false}
            showSort={true}
            arrangeOrder={arrangeOrder}
            arrangeKey={arrangeKey}
            drillDownReport={userListData}
            initiateDrillDownReport={initateLoading}
            loaderArray={10}
            sortingAction={sagaActions.SORT_USER_TABLE}
            subRoot={{ pathName: 'user' }}
            showUTCTime={false}
        />
    )
}

export default UserTableSS;