import React, { useState } from 'react'
import './Tutorial.css'
import video from '../../../../../../../Assests/Images/video.png'
import SSPopup from '../../../../../../Common/Popup/SSPopup/SSPopup'
import { AppConstant } from '../../../../../../../Assests/AppConstant'
export const Tutorial = (props) => {
    
        const { themeSelected, userType } = props;
        const [showTutorialPopup, setShowTutorialPopup] = useState(false)
        
    const closeTutorialPopopup = () => {
        setShowTutorialPopup(false);
    }
    const showTutorialVideoPopup = () => {
                const popupObj = {
                    id: 'deleteAppointment',
                    modalLabel: 'deleteEmail',
                    showClose: true,
                    showfooter: true,
                    showHeader: true,
                    sendDriverNotification: false,
                    modalSize: 'lg',
                    title: 'SchedulePro Tutorial',
                    bodyText: <div><video controls width="100%" height="100%" autoPlay>
                    <source src={userType === AppConstant.userPermission.usertypes.carrier ? AppConstant.commonStrings.scheduleProCarrierTutorial : AppConstant.commonStrings.scheduleProWarehouseTutorial} type="video/mp4"/>
                    {/* You can add additional <source> elements for different video formats */}
                    Your browser does not support the video tag.
                  </video></div>,
                    subText: "",
                    keyboard: false,
                    backdrop: "static",
                    footerBtn: {
                        btn1: {
                            show: false,
                            text: AppConstant.commonStrings.yes
                        },
                        btn2: {
                            show: false,
                            text: AppConstant.commonStrings.no
                        }
                    },
                }

                return <SSPopup {...popupObj} popupBtnHandler={(text) => {}} closepopup={closeTutorialPopopup} themeSelected={themeSelected} showModal={showTutorialPopup} />

    }
    return (
        <div className='tutorial-container'>
            <div className='d-flex justify-content-between p-3'>
                <div>
                    <p className='tutorial-heading'>
                        Don’t know how to start?
                    </p>
                    <p className='tutorial-text'>View this brief explainer video
                        and start your journey
                        without any hassle.
                    </p>
                    <p className='tutorial-sub-text'>
                        Click the play icon to start the tutorial.
                    </p>
                </div>
                <img className="cursor-pointer" src={video} alt='video' onClick={() => {setShowTutorialPopup(true)}} />
            </div>
            {showTutorialVideoPopup()}
        </div>
    )
}
