import React from 'react'
import { AppConstant } from '../../../Assests/AppConstant'
/**
 * This component renders reusable button
 * @param {*} props 
 * title = Button title
 * className = user defined class
 * isLoading = Button Loader
 * disabled = Disable button
 * loadingTitle = AppConstant.commonStrings.loading
 * dataBsToggle = Bootstarp props
 * dataBsTarget = Bootstarp props
 * @returns 
 */
const CustomButton = (props) => {
    const { title, className, isLoading, disabled, loadingTitle = AppConstant.commonStrings.loading, dataBsToggle, dataBsTarget } = props
    return (
        <button data-testid={AppConstant.dataTestId.customButton} onClick={() => props.onClick()} className={className} disabled={isLoading || disabled}  data-bs-toggle={dataBsToggle? dataBsToggle: ''}
        data-bs-target={dataBsTarget? dataBsTarget: ''}>
            {
                isLoading &&
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" data-testid={AppConstant.dataTestId.loading}
                />
            }
            {isLoading ? loadingTitle : title}
        </button>
    )
}

export default CustomButton
