import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DrilldownTable from '../../../../Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTable';
import CustomButton from '../../../../../../Common/CustomButton/CustomButton';
import _ from 'lodash-es';
import { useDispatch, useSelector } from "react-redux";
import { sagaActions } from "../../../../../../../../sagas/sagaActions";
const WarehouseList = (props) => {
    const { userObj, themeSelected, warehouseListData, editClicked, changeWarehouseStatus, loaderId, warehouseListLoader, arrangeKey, arrangeOrder, propsObj } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const warehouseTitleDD = [
        { id: 'Id', name: 'S.No' },
        { id: 'CompanyGroupName', name: 'Company Group' },
        { id: 'CompanyName', name: 'Company Name' },
        { id: 'WarehouseName', name: 'Warehouse Name' },
        { id: 'Subdomain', name: 'SubDomain Name' },
        { id: 'CreatedDate', name: 'Created on' },
        { id: 'actions', name: 'Actions' },
    ]

    // useEffect(() => {
    //     if (!_.isEmpty(userObj.selectedWarehouse)) {
            
    //     }
    // }, [userObj.selectedWarehouse])

    // const editClicked = (test) => {
    //     dispatch({ type: sagaActions.RESET_EDIT_WAREHOUSE });
    //    dispatch({ type: sagaActions.EDIT_WAREHOUSE_DATA, payload: test });
    // }

    const navigateToWhMetaData = (warehouseObj) => {
        const warehouseParams = {
            ...propsObj,
            "CUID": userObj.cu_id,
            "UserType": userObj.userType,
            "ID": warehouseObj.ID,
            "Name": warehouseObj.WarehouseName,
            "Address": warehouseObj.Address,
            "City": warehouseObj.City,
            "State": warehouseObj.State,
            "Zip": warehouseObj.Zip,
            "Country": warehouseObj.Country,
            "WarehouseLogo": warehouseObj.WarehouseLogo,
            "IDLumpingFacility": warehouseObj.IDLumpingFacility,
            "RoleID": userObj.parentWarehouse.RoleID,
            "RoleName": userObj.parentWarehouse.RoleName,
        }
        // dispatch({ type: sagaActions.UPDATE_SELECTED_WAREHOUSE, payload: warehouseParams });
        navigate('/whmetadata', { state: { props: {...warehouseParams} } })
    }

    const getActivatedeactivateButtonClass = (test) => {
        if (test.DateDeactivated == null && props.allowPermissionD) {
            return 'deleteButton'
        } else if (test.DateDeactivated != null && props.allowPermissionD) {
            return 'addButton'
        } else if (test.DateDeactivated == null && props.allowPermissionD === false) {
            return 'deleteButton disabled'
        } else if (test.DateDeactivated != null && props.allowPermissionD === false) {
            return 'addButton disabled'
        }
    }

    const actionsButton = (test, index) => {
        return (
            <div className='m-0 p-0 d-flex justify-content-center'>
                <CustomButton
                    title={'Edit'}
                    className={props.allowPermissionE ? 'addButton' : 'addButton disabled'}
                    isLoading={false}
                    onClick={() => editClicked(test)}
                    disabled={props.allowPermissionE ? false : true}
                />
                &ensp;
                {
                    !index.WhType ?

                        <CustomButton
                            title={'Metadata'}
                            className={props.allowPermissionE ? 'addButton' : 'addButton disabled'}
                            isLoading={false}
                            onClick={() => navigateToWhMetaData(test)}
                            disabled={props.allowPermissionE ? false : true}
                        /> :
                        ''
                }

                &ensp;
                <CustomButton
                    title={test.DateDeactivated == null ? "Deactive" : "Activate"}
                    className={getActivatedeactivateButtonClass(test)}
                    isLoading={loaderId.id === test.ID ? loaderId.loading : false}
                    onClick={() => changeWarehouseStatus(test)}
                    disabled={props.allowPermissionD === true
                        ? (loaderId.id === test.ID ? loaderId.loading : false)
                        : true}>
                </CustomButton>

            </div>
        )
    }

    // useEffect(() => {
    //     let data = formatListData(getWarehouseList())
    //     setWarehouseListData(data)
    // }, [])

    const formatListData = (data) => {

        return data && data.map((el, index) => {
            return {
                id: el.Id,
                0: (index + 1),
                1: el.CompanyGroupName || '-',
                2: el.CompanyName || '-',
                3: el.WarehouseName || '-',
                4: el.Subdomain || '-',
                5: el.CreatedDate,
                6: actionsButton(el, index),
            }
        })
    }

    return (
        <DrilldownTable
            themeSelected={themeSelected}
            bodyData={warehouseListData && formatListData(warehouseListData)}
            titleData={warehouseTitleDD}
            showCollapse={false}
            showInput={false}
            showSort={true}
            arrangeOrder={arrangeOrder}
            arrangeKey={arrangeKey}
            drillDownReport={warehouseListData}
            initiateDrillDownReport={warehouseListLoader}
            loaderArray={7}
            sortingAction={sagaActions.WAREHOUSE_LIST_SORTING}
            subRoot={{ pathName: 'warehouseAdmin' }}
            showUTCTime={false}
        />
    )
}

export default WarehouseList;