import React from 'react'
import { Link } from "react-router-dom";
import { AppConstant } from '../../../Assests/AppConstant'


const ForgotPassword = () => {
    return (
        <Link to="/recover">
        <button className='feedbackButton'>
            <span data-testid={AppConstant.dataTestId.forgotPasswordText} className='forgotPasswordText'>{AppConstant.login.forgotPassword}</span>
        </button>
        </Link>
    )
}

export default ForgotPassword
