import React, { useEffect, useState } from 'react';
import CustomButton from '../../../../Common/CustomButton/CustomButton';
import InputWithTtile from '../../../../Common/CustomInput/InputWithTtile';
import './BookApptsGuardsSearch.css';
import { useDispatch } from 'react-redux';
import { sagaActions } from '../../../../../../sagas/sagaActions';
import { AppConstant } from '../../../../../Assests/AppConstant';
import CustomTooltip from '../../../../Common/Tooltip/CustomTooltip';
import { validContactNumRegex } from '../../../../../../Utils/validationRegex';
import { getFormattedContactNum } from '../../../../../Assests/Utility';
import CustomFieldWithTtile from '../../../../Common/CustomField/CustomFieldWithTtile';
import { useSelector } from 'react-redux';
import { getRemoteConfigSelector } from '../../../../../../redux/reducers/reducerSlices/RemoteConfigReducer';
import _ from 'lodash-es'


const BookApptsGuardsSearch = (props) => {

    const dispatch = useDispatch()
      //Get Config Data from Redux
  const remoteConfig=useSelector(getRemoteConfigSelector);
  const {allConfigration}=remoteConfig  
  // const TransTypeList = allConfigration?.GuardSearchLoadTypeList?.slice(1, -1).split(',');
  const TransTypeListMain = allConfigration?.GuardSearchLoadTypeList?.slice(1, -1).split(',');
  let TransTypeList = TransTypeListMain.map((el, i) => {
    if(i!==0) return el
  })
  TransTypeList = _.compact(TransTypeList)

    const { searchAppointment, onEmailChange, initateLoading, themeSelected = 'light-mode',didSearched,inputData } = props;
    const [isLoading, setIsLoading] = useState(initateLoading)
    const [inputs, setInputs] = useState({
      transType:TransTypeList && TransTypeList.length?TransTypeList[0] : "",
        po: "",
        phoneNo: "",
      
      });
    let [errors, setErrors] = useState({
      transType:[],
        po: [],
        phoneNo: []
       });
    
      let [dirty, setDirty] = useState({
        transType:false,
        po: false,
        phoneNo: false
       
      });

    useEffect(() => {
        // Clear Saerched PO results when components unmount
        return () => {
            dispatch({ type: sagaActions.CLEAR_SS_SEARCH_PO })
        }
    }, [])
    useEffect(() => {
        setIsLoading(initateLoading)
    }, [initateLoading])

    const emailAppiontment = () => {

    }
    let handleChange = (data, name) => {
      if(name==='phoneNo'){
        data=getFormattedContactNum(data);
      }
        setInputs((inputs) => ({ ...inputs, [name]: data }));
      };
    const handleErrors = (name) => {
        setDirty((dirty) => ({ ...dirty, [name]: true }));
        validate();
      }
     let validate = () => {
    let validArray = [];
   
    let errorData = {
      transType:[],
        po: [],
        phoneNo: [],
    };
   
      let valid = true;
      valid = validateLoadType(inputs.transType, errorData);
      validArray.push(valid);
    valid = validatePo(inputs.po, errorData);
    validArray.push(valid);
    valid = validatePhoneNo(inputs.phoneNo, errorData);
    validArray.push(valid);
   
      setErrors(errorData);
  
     if (validArray.includes(false)) {
      return false;
    }
    else {
      return true
    }
  };
  const validateLoadType = (transType, errorData) => {
    if (!transType) {
      errorData.transType.push(AppConstant.help.required);
      return false;
    } 
  };
  const validatePo = (po, errorData) => {
    if (!po) {
      errorData.po.push(AppConstant.help.required);
      return false;
    } 
  };
  const validatePhoneNo = (phoneNo, errorData) => {
    if (!phoneNo) {
      errorData.phoneNo.push(AppConstant.help.required);
      return false;
    } 
    else{
      if (!validContactNumRegex.test(phoneNo)) {
        errorData.phoneNo.push(AppConstant.help.contactIncorrect);
        return false;
      }
    }
    return true;
  };
  useEffect(()=>{
    if(props.callReset){
      reset()
    }
  
  },[props.callReset])
  const reset=()=>{
    setInputs({
      transType:inputData.transType || TransTypeList[0],
      po: "",
      phoneNo: "",
    
    });
    setErrors({
      transType:[],
      po: [],
      phoneNo: []
     });
  
     setDirty({
      transType:false,
      po: false,
      phoneNo: false
     
    });
  }
  const onSubmit = async () => {
    let dirtyData = dirty;
    Object.keys(dirty).forEach((control) => {
      dirtyData[control] = true;
    });
    setDirty(dirtyData);
   if (validate()) {
    searchAppointment(inputs);
    }
  };

  const getRadioBtnData=()=>{
    const radiconfig= []
    TransTypeList && TransTypeList.length && TransTypeList.forEach((el)=>{
      let obj={
      label: el,
      value: el,
      }
    radiconfig.push(obj)
    })
  return radiconfig
  }

    return (
      <div className='mt-2 w-80vw'>
            <div className={ (didSearched===false || isLoading===true || props.callReset===true) && 'd-flex justify-content-center'}>
            
            <div className='w-100 d-flex flex-column justify-content-center' >
           
                   
                    <div className='w-100 d-flex justify-content-center my-3 guardtextcenter'>
                                <CustomFieldWithTtile
                        type={'radio'}
                        showError={
                          dirty["transType"] && errors["transType"][0]
                            ? errors["transType"]
                            : ""
                        }
                        name={'transType'}
                        radiconfig={getRadioBtnData()}
                        value={inputs.transType}
                        handleErrors={handleErrors}
                        title={'Select a Type'}
                        required={true}
                        onChange={handleChange}
                        listData={getRadioBtnData().getSelectData}
                        placeHolder={''}
                        disabledField={''}
                        addIcon={''}
                        iconHandler={''}
                        className={'radioButtonText gap-5'}
                      />
                    </div>
                    
                    <div className='w-100 d-flex justify-content-between gap-5 my-3'>
                    <div className='w-100'>
                            <InputWithTtile
                              showError={dirty["po"] && errors["po"][0] ? errors["po"] : ""}
                                title={`Please enter Driver ${inputs.transType===AppConstant.commonStrings.inBoundLoadTitle?'PO':'Trailer'} Number here`}
                                subTitle={`Please enter Driver ${inputs.transType===AppConstant.commonStrings.inBoundLoadTitle?'PO':'Trailer'} Number here `}
                                titleToolTipText={''}
                                placeHolder={`Please enter Driver ${inputs.transType===AppConstant.commonStrings.inBoundLoadTitle?'PO':'Trailer'} Number here`}
                                superScriptText={
                                    <>&nbsp;<sup className='hoverSuperScript'>[?]</sup>&nbsp;:</>
                                }
                                toolTipId='po-search-tool-tip'
                                name="po"
                                required={true}
                                value={inputs.po ? inputs.po : ""}
                                handleErrors={handleErrors}
                                onEmailChange={handleChange}
                               />
                        
                            <CustomTooltip
                                id={'po-search-tool-tip'}
                                position='top'
                                title={inputs.transType===AppConstant.commonStrings.inBoundLoadTitle?
                                  <p>
                                      Enter the PO number of Driver load here.<br />
                                      The PO number may also be listed as a Customer Order Number.<br/>
                                      PO numbers are usually 6 or 7-digits long and may include numbers and letters.<br/>
                                      If the Driver has more than one PO or Customer Order Number, enter the first PO listed. 
                                  </p>:
                                    <p>
                                    Enter the Trailer number of Driver load here.
                                </p>
                              }
                                type={themeSelected === AppConstant.commonStrings.lightModeFilterString ? 'light' : 'dark'}
                                multiline={false} />
                        </div>
                        <div className='w-100'>
                            <InputWithTtile
                                showError={dirty["phoneNo"] && errors["phoneNo"][0] ? errors["phoneNo"] : ""}
                                title={'Driver Phone Number'}
                                 placeHolder={'Driver Phone Number'}
                                titleToolTipText={'Please enter Driver Phone Number here'}
                                superScriptText={<>&nbsp;<sup className='hoverSuperScript'>[?]</sup>&nbsp;:</>}
                                required={true}
                                name="phoneNo"
                                toolTipId='tooltip_id'
                                value={inputs.phoneNo ?inputs.phoneNo : ""}
                               
                                handleErrors={handleErrors} 
                                onEmailChange={handleChange} />
                            <CustomTooltip
                                id={'tooltip_id'}
                                position='top'
                                type={themeSelected === AppConstant.commonStrings.lightModeFilterString ? 'light' : 'dark'}
                                multiline={false} />
                        </div>
                     
                        </div>
                    {inputs.transType===AppConstant.commonStrings.inBoundLoadTitle && <div className={ didSearched===false && isLoading===false ? 'd-flex ms-2 mt-0 pt-0':'d-flex ml-3 mt-0 pt-0'}  >   <a href='#' className='poImageHyperlinkText text-danger m-0 p-0' onClick={() => props.showPdfHelperPopup()}>Need help locating PO on BOL?</a></div> }
                    <div className='mt-3 d-flex justify-content-center'>
                        <CustomButton
                            isLoading={isLoading}
                            title={'Search'}
                            className={"loginButton w-25"}
                            onClick={onSubmit}
                        />
                    </div>
                    </div>
                    </div>
                </div>
            

       
    )
}

export default BookApptsGuardsSearch;