import React, { useEffect, useState } from 'react'
import { AppConstant } from '../../../Assests/AppConstant'
import "./InputWithTitle.css"

/**
 * This is a reusable component to render input HTML based on type with new UI
 * @param {*} props 
 * title = title for input element
 * placeHolder = placeholder text
 * showError = show/hide error variable
 * errorMessage = variable to display error message
 * value = value for HTML input element
 * name = required by HTML input element
 * required = if element is required
 * disabled = if element is disabled
 * inputType = type of input element
 * superScriptText = subtext
 * titleToolTipText = Tooltip for element title
 * toolTipId = tooltip ID
 * type = ?
 * onEmailChange = ?
 * customClass = user defined class
 * titleIcon = 
 * titleIconClass = user defined icon class
 * @returns 
 */
const InputWithTtile = (props) => {
    const { title, placeHolder, showError, errorMessage, value, name, required, disabled, inputType, superScriptText, titleToolTipText, toolTipId } = props
    const [error, seterror] = useState(showError)
    /**
     * useEffect for error
     */
    useEffect(() => {
        seterror(showError)
    }, [showError])
    /**
     * function for input class if error
     */
    const getInputClass = () => error ? "customTextInput customTextInputError" : "customTextInput"

    return (
        <div className="d-flex flex-column">
            <span data-testid={AppConstant.dataTestId.customInputTitle}
                
                className='d-flex inputTitle'>
                {title}
                <div
                data-tip={titleToolTipText}
                data-for={toolTipId}
                >{superScriptText}</div>
                &nbsp;{required && <span className='color-red'>*</span>}
            </span>
          
            <input
                data-testid={AppConstant.dataTestId.customInput}
                type={inputType ? inputType : 'text'}
                value={value}
                name={name}
                className={getInputClass()}
                placeholder={placeHolder}
                onBlur={(data) => props.handleErrors(data.target.name)}
                onInput={(data) => props.onEmailChange(data.target.value, data.target.name)}
                disabled={disabled || ''}
            />
            {
                error &&
                <span className='errorMessage' data-testid={AppConstant.dataTestId.validationError}>
                    {errorMessage ? errorMessage : error}
                </span>
            }

        </div>
    )
}

export default InputWithTtile
