import _ from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { convertGmtToDateRange, getCurrentDate, changeDateToGivenFormat } from '../Utils/DateUtils';
import {
    appointmentComplianceResponseFormatter, unloadingHoursAPIResponseFormatter, detentionInPercentage,
    doorStatusResponseFormatter, formatDoorUsageData,
    unloadingTypeSummaryAPIResponseFormatter,
    unscheduledPOsAPIResponseFormatter
} from '../App/Assests/DashboardUtility';
import { Uchain } from '../redux/api/agent';
import {
    getUnloadingHours, updateDashboardLoadStatusObj, getDetentionStatusData, getAppointmentCompliance,
    updateAutoFetch, getTaskItemsDueObj, getUnloadingHoursDrillDown, drillDownReportData,
    updateDrillDownReport, initiateReport, getDashboardLoadStatusDrillDown, updateSelectedButton,
    getDetentionStatusDrillDown, getTaskItem, updateSectionData, updateGetWidgetList, updateTop10LateLoads,
    updateSelectedCustomWidgetsArr, getDetentionPercentageStatusData, updateLoadingTypeSummary,
    updateLoadingTypeSummaryDrilldown, updateSortkey, updateDoorStatusWidget, updateDoorUsageSummary,
    updateDoorUsageSummaryDrillDown, updateDoorStatusDrillDown, updateUnScheduledPOSummaryObj,
    updateUnScheduledPOSummaryDrillDown, updateTop10LateLoadsDrillDown,
    getAppointmentComplianceDrillDown, updateSelectedTop10LateLoad, updateDateRange, customDashboardDockSelectedList, updateCustomDashboardDockSelectedList, getDetentionStatusDrillDownResponse, updateDashboardLoadStatusDrillDownReport, updateDoorAssignmentObj, updateDoorAssignmentDrillDown, updateDoorAssignmentLoader, resetDrillDownDoorAssignment, updateDoorAssignmentReportDrillDown, getAppointmentComplianceDrillDownResponse, updateWebSocket
} from '../redux/reducers/reducerSlices/DefaultDashboardReducer';
import { sagaActions } from './sagaActions';
 
function* dashboardLoadStatusListSaga(data) {
    try {
        const state = yield select();
        const dateRangeObj = state.defaultDashboardReducer.dateRangeObj;
        const start = convertGmtToDateRange(dateRangeObj).start;
        const end = convertGmtToDateRange(dateRangeObj).end;
        const payload = {
            ...data.payload,
            "fromDate": start !== "Invalid date" ? start : null,
            "toDate": end !== "Invalid date" ? end : null,
            load_type: state.defaultDashboardReducer.selectedButtonStatusOverview
        }
        const response = yield call(Uchain.dashboardLoadStatusDetails, payload);
        if (payload.isDrillDown === '0') {
            yield put(updateDashboardLoadStatusObj({ ...response, ...payload }));
        }
        else {
            yield put(getDashboardLoadStatusDrillDown(response))
        }
 
    } catch (error) {
    }
}
 
function* getUnloadingHoursSaga(data) {
    try {
 
        const state = yield select();
        const dateRangeObj = state.defaultDashboardReducer.dateRangeObj;
        const start = convertGmtToDateRange(dateRangeObj).start;
        const end = convertGmtToDateRange(dateRangeObj).end;
 
        const payload = {
            ...data.payload,
            "fromDate": start !== "Invalid date" ? start : null,
            "toDate": end !== "Invalid date" ? end : null,
        }
 
        const response = yield call(Uchain.unloadingHours, payload);
        if (data.payload.isDrillDown === '0') {
            yield put(getUnloadingHours(response));
        }
        else {
            const state = yield select()
            const { arrangeOrder, arrangeKey } = state.defaultDashboardReducer
            const newRes = _.orderBy(response, [arrangeKey], [arrangeOrder]);
            yield put(getUnloadingHoursDrillDown(newRes));
        }
    } catch (error) {
 
    }
}
 
function* getDetentionPercentageStatusSaga(data) {
 
    try {
        let response = yield call(Uchain.detentionStatus, data.payload);
        if (data.payload.isDrillDown === '0') {
            // Format API response as per props provided to graph
            const dataFormatted = detentionInPercentage(response);
            yield put(getDetentionPercentageStatusData(dataFormatted));
        } else {
            yield put(getDetentionStatusDrillDown(response))
        }
 
    } catch (error) {
    }
}
 
function* getDetentionStatusSaga(data) {
    try {
        const state = yield select();
        const dateRangeObj = state.defaultDashboardReducer.dateRangeObj;
        const start = convertGmtToDateRange(dateRangeObj).start;
        const end = convertGmtToDateRange(dateRangeObj).end;
        const payload = {
            ...data.payload,
            "fromDate": start !== "Invalid date" ? start : null,
            "toDate": end !== "Invalid date" ? end : null,
        }
        let response = yield call(Uchain.detentionStatus, payload);
 
        if (payload.isDrillDown === '0') {
            // Format API response as per props provided to graph
            const dataFormatted = unloadingHoursAPIResponseFormatter(response)
            yield put(getDetentionStatusData(dataFormatted));
        } else {
            yield put(getDetentionStatusDrillDown(response))
            yield put(getDetentionStatusDrillDownResponse(response))
        }
 
    } catch (error) {
 
    }
}
 
function* getAppointmentComplianceSaga(data) {
    try {
        const state = yield select();
        const dateRangeObj = state.defaultDashboardReducer.dateRangeObj;
        const start = convertGmtToDateRange(dateRangeObj).start;
        const end = convertGmtToDateRange(dateRangeObj).end;
        const payload = {
            ...data.payload,
            "fromDate": start !== "Invalid date" ? start : null,
            "toDate": end !== "Invalid date" ? end : null,
        }
        let response = yield call(Uchain.appointmentCompliance, payload);
        if (payload.isDrillDown === '0') {
            // Format API response as per props provided to graph
            const dataFormatted = appointmentComplianceResponseFormatter(response)
            yield put(getAppointmentCompliance(dataFormatted));
        } else {
            // Add here
            yield put(getAppointmentComplianceDrillDownResponse(response))
        }
    } catch (error) {
    }
}
 
function* getTaskItemsDueSaga(data) {
    try {
 
        const currentDate = getCurrentDate();
        const formattedDate = changeDateToGivenFormat(currentDate, 'YYYY-MM-DD')
        const payload = {
            ...data.payload,
            "fromDate": formattedDate !== "Invalid date" ? formattedDate : null,
            "toDate": formattedDate !== "Invalid date" ? formattedDate : null,
        }
        let response = yield call(Uchain.taskItemsDue, payload);
        yield put(getTaskItemsDueObj(response));
    } catch (error) {
    }
}
 
function* updateAutoFetchSaga(data) {
    try {
        yield put(updateAutoFetch(data.payload))
    } catch (error) {
 
    }
}
function* sortDrillDownReportSaga(data) {
    try {
        yield put(updateDrillDownReport(data.payload))
    } catch (error) {
 
    }
}
 
function* initiateDrillDownReportSaga(data) {
    try {
        yield put(initiateReport(data.payload))
    } catch (error) {
 
    }
}
 
function* setTaskItemDueSaga(data) {
    try {
        yield put(getTaskItem(data.payload))
    } catch (error) {
 
    }
}
 
function* updateSelectedButtonSaga(data) {
    try {
        yield put(updateSelectedButton(data.payload))
    } catch (error) {
 
    }
}
 
function* updateSectionDataSaga(data) {
    try {
        // let response = yield call(Uchain.updateCustomDashboard, data.payload);
        yield put(updateSectionData(data.payload))
    } catch (error) {
 
    }
}
 
function* getTop10LoadsSaga(data) {
    try {
        const state = yield select();
        const dateRangeObj = state.defaultDashboardReducer.dateRangeObj;
        const start = convertGmtToDateRange(dateRangeObj).start;
        const end = convertGmtToDateRange(dateRangeObj).end;
        const payload = {
            ...data.payload,
            "fromDate": start !== "Invalid date" ? start : null,
            "toDate": end !== "Invalid date" ? end : null,
        }
 
        let response = yield call(Uchain.top10LateLoads, payload);
        if (payload.isDrillDown === '0') {
            yield put(updateTop10LateLoads(response));
        }
        else {
            yield put(updateTop10LateLoadsDrillDown(response))
 
        }
    } catch (error) {
    }
}
function* updateGetWidgetDataSaga(data) {
    try {
        const response = yield call(Uchain.getDashBoardWidget, data.payload);
        let { userSelectedWidgetDetails, widgets } = response;
        if (widgets.Count > 0) {
            yield put(updateGetWidgetList(widgets?.Items))
        }
        if (userSelectedWidgetDetails) {
            yield put(updateSectionData(userSelectedWidgetDetails?.name))
            yield put(updateSortkey(userSelectedWidgetDetails.sk))
        }
    } catch (error) {
 
    }
}
function* updateCustomDashboardSaga(data) {
    try {
        let response = yield call(Uchain.updateCustomDashboard, data.payload);
        if (response.sort_key) {
            yield put(updateSortkey(response.sort_key))
        }
    } catch (error) {
 
    }
}
 
function* updateSelectedCustomWidgetsSaga(data) {
    try {
        yield put(updateSelectedCustomWidgetsArr(data.payload))
    } catch (error) {
 
    }
}
 
function* resetFunnelGraphDataSaga() {
    try {
        yield put(updateDashboardLoadStatusObj({}));
    } catch (error) {
 
    }
}
 
function* getDoorStatusSaga(data) {
    try {
        let response = yield call(Uchain.getDoorStatusWidget, data.payload);
        if (data.payload.isDrillDown === '0') {
            const dataFormatted = doorStatusResponseFormatter(response);
            yield put(updateDoorStatusWidget(dataFormatted));
        }
        else {
            yield put(updateDoorStatusDrillDown(response));
        }
 
    } catch (error) {
    }
}
function* clearDoorStatusSaga(data) {
    try {
        yield put(updateDoorStatusDrillDown([]));
 
    } catch (error) {
        yield put(updateDoorStatusDrillDown([]));
    }
}
 
function* getDoorUsageSummarySaga(data) {
    try {
        const state = yield select();
        const dateRangeObj = state.defaultDashboardReducer.dateRangeObj;
        const start = convertGmtToDateRange(dateRangeObj).start;
        const end = convertGmtToDateRange(dateRangeObj).end;
        const payload = {
            ...data.payload,
            "fromDate": start !== "Invalid date" ? start : null,
            "toDate": end !== "Invalid date" ? end : null,
        }
        let response = yield call(Uchain.getDoorUsageSummary, payload);
        // Need data with DrillDown = 1 for both graph and drilldown
        if (payload.isDrillDown === '1') {
            // Format API response as per props provided to graph
            if (response.length) {
                const dataFormatted = formatDoorUsageData(response)
                yield put(updateDoorUsageSummary(dataFormatted))
                yield put(updateDoorUsageSummaryDrillDown(response))
            } else {
                yield put(updateDoorUsageSummary([]))
                yield put(updateDoorUsageSummaryDrillDown([]))
            }
        }
        else {
            yield put(updateDoorUsageSummary(response))
        }
 
    } catch (error) {
        yield put(updateDoorUsageSummary([]))
        yield put(updateDoorUsageSummaryDrillDown([]))
    }
}
 
function* resetDoorUsageSummarySaga() {
    try {
        yield put(updateDoorUsageSummary([]))
        yield put(updateDoorUsageSummaryDrillDown([]))
    } catch (error) {
        yield put(updateDoorUsageSummary([]))
        yield put(updateDoorUsageSummaryDrillDown([]))
    }
}
 
function* getUnloadingTypeSummarySaga(data) {
 
    try {
        const state = yield select();
        const dateRangeObj = state.defaultDashboardReducer.dateRangeObj;
        const start = convertGmtToDateRange(dateRangeObj).start;
        const end = convertGmtToDateRange(dateRangeObj).end;
        const payload = {
            ...data.payload,
            "fromDate": start !== "Invalid date" ? start : null,
            "toDate": end !== "Invalid date" ? end : null,
        }
 
        let response = yield call(Uchain.unloadingTypeSummary, payload);
        if (payload.isDrillDown === '0') {
            // Format API response as per props provided to graph
            const dataFormatted = unloadingTypeSummaryAPIResponseFormatter(response);
            yield put(updateLoadingTypeSummary(dataFormatted));
        } else {
            yield put(updateLoadingTypeSummaryDrilldown(response))
        }
 
    } catch (error) {
    }
}
 
 
function* getUnscheduledPoWidgetSaga(data) {
 
    try {
        const state = yield select();
        const dateRangeObj = state.defaultDashboardReducer.dateRangeObj;
        const start = convertGmtToDateRange(dateRangeObj).start;
        const end = convertGmtToDateRange(dateRangeObj).end;
        const payload = {
            ...data.payload,
            "fromDate": start !== "Invalid date" ? start : null,
            "toDate": end !== "Invalid date" ? end : null,
        }
        let response = yield call(Uchain.getUnscheduledPOWidget, payload);
        if (payload.isDrillDown === '0') {
            // Format API response as per props provided to graph
            const dataFormatted = unscheduledPOsAPIResponseFormatter(response);
            yield put(updateUnScheduledPOSummaryObj(dataFormatted));
        } else {
            yield put(updateUnScheduledPOSummaryDrillDown(response))
        }
 
    } catch (error) {
    }
}
 
function* setSelectedLateLoad(data) {
    try {
        yield put(updateSelectedTop10LateLoad(data.payload));
    } catch (error) {
    }
}
 
function* updateDateRangeSaga(data) {
    try {
        yield put(updateDateRange(data.payload));
    } catch (error) {
    }
}
 
function* updateCustomDashBoardDockSelectedSaga(data) {
    try {
        yield put(updateCustomDashboardDockSelectedList(data.payload));
    } catch (error) {
        yield put(updateCustomDashboardDockSelectedList({}));
    }
}
 
function* sortDashboardStatusDrillDownReportSaga(data) {
    try {
        const state = yield select();
        let { dashboardLoadStatusDrillDownReport } = state.defaultDashboardReducer;
        const dashboardLoadStatusDrillDownReportTemp = { ...dashboardLoadStatusDrillDownReport };
        dashboardLoadStatusDrillDownReportTemp.data = data.payload.drillDownReport
        let obj = {
          
                dashboardLoadStatusDrillDownReport: dashboardLoadStatusDrillDownReportTemp,
                arrange: {
                    key: data.payload.arrange.key,
                    order: data.payload.arrange.order,
                }
            
        }
        yield put(updateDashboardLoadStatusDrillDownReport(obj));
    } catch (error) {
        // yield put(updateDashboardLoadStatusDrillDownReport([]));
    }
}
 
function* getDoorAssignmentReportSaga(data) {
    try {
        let response = yield call(Uchain.getDoorAssignmentReport, data.payload);
        if (data.payload.isDrillDown === '0') {
            yield put(updateDoorAssignmentObj(response));
        }
        else {
            yield put(updateDoorAssignmentDrillDown(response));
        }
        yield put(updateDoorAssignmentLoader(false));
 
    } catch (error) {
        yield put(updateDoorAssignmentLoader(false));
 
    }
}
 
function* getDoorAssignmentLoaderSaga(data) {
    try {
            yield put(updateDoorAssignmentLoader(data.payload));
    } catch (error) {
    }
}
 
function* resetDoorAssignmentDrillDownSaga() {
    try {
            yield put(resetDrillDownDoorAssignment());
    } catch (error) {
    }
}
 
function* sortDoorAssignmentReportSaga(data) {
    try {
        const state = yield select();
        let { doorAssignmentReportDrillDown } = state.defaultDashboardReducer;
        const doorAssignmentReportDrillDownTemp = { ...doorAssignmentReportDrillDown };
        doorAssignmentReportDrillDownTemp.data = data.payload.drillDownReport
        let obj = {
          
                doorAssignmentReportDrillDown: doorAssignmentReportDrillDownTemp,
                arrange: {
                    key: data.payload.arrange.key,
                    order: data.payload.arrange.order,
                }
            
        }
        yield put(updateDoorAssignmentReportDrillDown(obj));
    } catch (error) {
        // yield put(updateDashboardLoadStatusDrillDownReport([]));
    }
}

function* updateWebSocketSaga(data) {
    try {
            yield put(updateWebSocket(data.payload));
    } catch (error) {
        yield put(updateWebSocket(null));
    }
}


export function* unloadingTypeSummaryWatcher() {
    yield takeLatest(sagaActions.GET_UNLOADING_TYPE_SUMMARY, getUnloadingTypeSummarySaga)
}
 
export function* unloadingTypeSummaryDrillDownWatcher() {
    yield takeLatest(sagaActions.GET_UNLOADING_TYPE_SUMMARY_DRILLDOWN, getUnloadingTypeSummarySaga)
}
 
export function* dashboarsLoadStatusList() {
    yield takeLatest(sagaActions.GET_DASHBOARD_STATUS_TRUCK_LIST, dashboardLoadStatusListSaga)
}
 
export function* dashboarsLoadStatusDrillDown() {
    yield takeLatest(sagaActions.GET_DASHBOARD_STATUS_DRILLDOWN, dashboardLoadStatusListSaga)
}
 
export function* getunloadingHours() {
    yield takeLatest(sagaActions.GET_UNLOADING_HOURS, getUnloadingHoursSaga)
}
 
export function* getunloadingHoursDrillDown() {
    yield takeLatest(sagaActions.GET_UNLOADING_HOURS_DRILLDOWN, getUnloadingHoursSaga)
}
 
export function* getDetentionStatus() {
    yield takeLatest(sagaActions.GET_DETENSTION_STATUS, getDetentionStatusSaga)
}
 
export function* getDetentionStatusDrillDownWatchcer() {
    yield takeLatest(sagaActions.GET_DETENSTION_STATUS_DRILLDOWN, getDetentionStatusSaga)
}
 
export function* getAppointmentComplianceWatcher() {
    yield takeLatest(sagaActions.GET_APPOINTMENT_COMPLIANCE, getAppointmentComplianceSaga)
}
 
export function* getAppointmentComplianceWatcherDrillDown() {
    yield takeLatest(sagaActions.GET_APPOINTMENT_COMPLIANCE_DRILLDOWN, getAppointmentComplianceSaga)
}
 
export function* getTaskItemsDue() {
    yield takeLatest(sagaActions.GET_TASK_ITEMS_DUE_LIST, getTaskItemsDueSaga)
}
 
export function* updateAutoFetchWatcher() {
    yield takeLatest(sagaActions.INTIATE_AUTO_FETCH, updateAutoFetchSaga)
}
 
export function* setTaskItemDue() {
    yield takeLatest(sagaActions.SET_TASK_ITEM, setTaskItemDueSaga)
}
 
export function* sortDrillDownReport() {
    yield takeLatest(sagaActions.SORT_DRILLDOWN_REPORT, sortDrillDownReportSaga)
}
 
export function* initiateDrillDownReport() {
    yield takeLatest(sagaActions.INITIATE_DRILLDOWN_REPORT, initiateDrillDownReportSaga)
}
 
export function* updateSelectedButtonStatusLoad() {
    yield takeLatest(sagaActions.UPDATE_SELECTED_BUTTON, updateSelectedButtonSaga)
}
 
export function* updateSectionDataWatcher() {
    yield takeLatest(sagaActions.UPDATE_SECTION_DATA, updateSectionDataSaga)
}
 
export function* getTop10Loads() {
 
    yield takeLatest(sagaActions.TOP_10_LATE_LOADS, getTop10LoadsSaga)
}
export function* updateGetWidgetDataWatcher() {
    yield takeLatest(sagaActions.GET_DASHBOARD_WIDGET, updateGetWidgetDataSaga)
}
 
export function* updateSectionDataAPIWatcher() {
    yield takeLatest(sagaActions.UPDATE_DASHBOARD_WIDGET, updateCustomDashboardSaga)
}
export function* updateSelectedCustomWidgetsWatcher() {
    yield takeLatest(sagaActions.UPDATE_SELECTED_CUSTOM_WIDGETS, updateSelectedCustomWidgetsSaga)
}
 
export function* resetFunnelGraphDataWatcher() {
    yield takeLatest(sagaActions.RESET_FUNNEL_GRAPH_DATA, resetFunnelGraphDataSaga)
}
 
export function* getDetentionPercentageWatcher() {
    yield takeLatest(sagaActions.GET_DETENTION_PERCENTAGE, getDetentionPercentageStatusSaga)
 
}
 
export function* getDoorUsageSummaryWatcher() {
    yield takeLatest(sagaActions.UPDATE_DOOR_USAGE_SUMMARY, getDoorUsageSummarySaga)
}
 
export function* resetDoorUsageSummaryWatcher() {
    yield takeLatest(sagaActions.RESET_DOOR_USUAGE_SUMMARY_DRILLDOWN, resetDoorUsageSummarySaga)
}
 
export function* getDoorStatusWatcher() {
    yield takeLatest(sagaActions.GET_DOOR_STATUS_WIDGET, getDoorStatusSaga)
}
 
export function* getDoorStatusDrillDownWatcher() {
    yield takeLatest(sagaActions.GET_DOOR_STATUS_DRILLDOWN, getDoorStatusSaga)
}
 
export function* getUnscheduledPOWidgetWatcher() {
    yield takeLatest(sagaActions.WIDGET_UNSCHEDULED_POS, getUnscheduledPoWidgetSaga)
}
 
export function* getUnscheduledPODrillDownWatcher() {
    yield takeLatest(sagaActions.GET_UNSCHEDULED_POS_DRILLDOWN, getUnscheduledPoWidgetSaga)
}
 
export function* getSelectedLateLoad() {
    yield takeLatest(sagaActions.TOP_10_SELECTED_LATE_LOAD, setSelectedLateLoad)
}
 
export function* getTop10LoadsDrillDownWatcher() {
    yield takeLatest(sagaActions.GET_TOP_10_LATE_LOADS_DRILLDOWN, getTop10LoadsSaga)
}
 
export function* updateDateRangeWatcher() {
    yield takeLatest(sagaActions.UPDATE_DATE_RANGE, updateDateRangeSaga)
}
 
export function* updateCustomDashBoardDockSelectedWatcher() {
    yield takeLatest(sagaActions.CUSTOM_DASHBOARD_DOCK_SELECTED, updateCustomDashBoardDockSelectedSaga)
}
export function* clearDoorStatusWatcher() {
    yield takeLatest(sagaActions.CLEAR_DOOR_STATUS_DRILLDOWN, clearDoorStatusSaga
        )
}
 
export function* sortDashboardStatusDrillDownReportWatcher() {
    yield takeLatest(sagaActions.SORT_DASHBOARD_STATUS_DRILLDOWN, sortDashboardStatusDrillDownReportSaga)
}
export function* getDoorAssignmentReportWatcher() {
    yield takeLatest(sagaActions.GET_DOOR_ASSIGNMENT, getDoorAssignmentReportSaga)
}
export function* getDoorAssignmentLoaderWatcher() {
    yield takeLatest(sagaActions.DOOR_ASSIGNMENT_LOADER, getDoorAssignmentLoaderSaga)
}
export function* resetDoorAssignmentDrillDownWatcher() {
    yield takeLatest(sagaActions.RESET_DOOR_ASSIGNMENT_DRILLDOWN, resetDoorAssignmentDrillDownSaga)
}
export function* sortDoorAssignmentReportWatcher() {
    yield takeLatest(sagaActions.SORT_DOOR_ASSIGNMENT_DRILLDOWN, sortDoorAssignmentReportSaga)
}
export function* updateWebSocketWatcher() {
    yield takeLatest(sagaActions.UPDATE_WEB_SOCKET, updateWebSocketSaga)
}
