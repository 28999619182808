import React, { useEffect, useState } from "react";
import { AppConstant } from "../../../Assests/AppConstant";
import { dateAndTimeUTCFormat, changeFormatWithoutTz } from "../../../Assests/Utility";
import moment from "moment";
import CustomButton from "../../Common/CustomButton/CustomButton";

const ViewAppointmentPopup = (props) => {
    const {
        title,
        placeHolder,
        showError,
        errorMessage,
        value,
        name,
        required,
        type,
        listData,
        radiconfig,
        // customDatePickerConfig,
        formFieldList,
        disabledField,
        footerBtn,
        initateLoading,
        timezone
    } = props;
    const [error, seterror] = useState(showError);

    useEffect(() => {
        seterror(showError);
    }, [showError]);

    const renderDateTime = (data) => {
        const utc = moment(data).tz(timezone).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        

            const defaultObj = {
                date: '-',
                time: '-'
            }

            const time_formated = utc ? utc : defaultObj
            return (
                <span>{`${time_formated.date}  ${time_formated.time} `}</span>
            )
    }

    return (
        <>
            <div className="d-flex justify-content-around">
                <div className="d-flex flex-column justify-content-center align-items-start">
                    {formFieldList && formFieldList.map((control, index) => {
                        return (
                            <div key={index + '_' + control.name }>
                                <span
                                    data-testid={AppConstant.dataTestId.customInputTitle}
                                    className="subText fw-bold"
                                >
                                    {`${control.name} `}&nbsp;{required && <span className="color-red">*</span>}
                                </span>
                            </div>)
                    })}
                </div>
                <div className="d-flex flex-column justify-content-center align-items-start">
                    {formFieldList && formFieldList.map((control, index) => {
                        return (
                            <div key={index + '_' + control.value }>
                                {control.value instanceof Date ? renderDateTime(control)
                                    :
                                    <span className="subText" disabled={disabledField}>{`${control.value || '-'}`}</span>
                                }
                            </div>)
                    })}
                </div>

                {error && (
                    <span className="errorMessage">
                        {errorMessage ? errorMessage : error}
                    </span>
                )}
            </div>
            <div className="d-flex justify-content-around mt-2">
                {
                    footerBtn.map((el) => {
                        return (
                            <CustomButton
                                key={el.text}
                                isLoading={el.text === 'Delete' ? initateLoading : false}
                                title={el.text}
                                className={"btn-main-new"}
                                onClick={(e) => el.handler(e, el.text)} />
                        )
                    })
                }
            </div>

        </>
    );
};

export default ViewAppointmentPopup;
