export const darkStyles = {
    'code': {
        'font_color': '#e3eaef',
        'border_color': '#47515d',
        'border_style': 'solid',
        'input_border_radius': '0.25rem',
        'font_size': '14px',
        'input_border_width': '1px',
        'input_font': 'inherit',
        'input_font_weight': '400',
        'input_margin': '5px 0px 5px 10px',
        'input_padding': '0px 5px 0px 5px',
        'label_color': '#aab8c5',
        'label_size': '16px',
        'label_width': '150px',
        'label_font': 'Poppins, sans-serif, arial, serif',
        'label_font_weight': 'normal',
        'label_margin': '5px 0px 0px 10px',
        'label_padding': '2px 5px 2px 5px',
        'label_border_style': 'none',
        'label_border_color': 'none',
        'label_border_radius': '0.25rem',
        'label_border_width': '2px',
        'background_color': 'transparent',
        'height': '35px',
        'width': '13rem',
        'padding_bottom': '2px'
    },
    'cc': {
        'font_color': '#e3eaef',
        'border_color': '#47515d',
        'border_style': 'solid',
        'font_size': '16px',
        'font_weight': '400',
        'input_border_radius': '0.25rem',
        'input_border_width': '1px',
        'input_font': 'inherit',
        'input_font_weight': '400',
        'input_margin': '5px 0px 5px 0px',
        'input_padding': '0px 5px 0px 5px',
        'label_color': '#aab8c5',
        'label_size': '16px',
        'label_width': '300px',
        'label_font': 'Poppins, sans-serif, serif',
        'label_font_weight': '400',
        'label_margin': '5px 0px 0px 0px',
        'label_padding': '0px 5px 0px 5px',
        'label_border_style': 'none',
        'label_border_color': 'none',
        'label_border_radius': '0.25rem',
        'label_border_width': '0px',
        'background_color': 'transparent',
        'height': '35px',
        'width': '35rem',
        'padding_bottom': '0px',
        'line-height': '23px'
    },
    'exp': {
        'font_color': '#e3eaef',
        'border_color': '#47515d',
        'border_style': 'solid',
        'font_size': '14px',
        'input_border_radius': '0.25rem',
        'input_border_width': '1px',
        'input_font': 'inherit',
        'input_font_weight': '400',
        'input_margin': '5px 0px 5px 0px',
        'input_padding': '0px 5px 0px 5px',
        'label_color': '#aab8c5',
        'label_size': '16px',
        'label_width': '150px',
        'label_font': 'inherit',
        'label_font_weight': 'normal',
        'label_margin': '5px 0px 0px 0px',
        'label_padding': '2px 5px 2px 5px',
        'label_border_style': 'dashed',
        'label_border_color': 'none',
        'label_border_radius': '0rem',
        'label_border_width': '0px',
        'background_color': 'transparent',
        'height': '35px',
        'width': '10rem',
        'padding_bottom': '2px',
        'type': 'dropdown'
    },
    'body': {
        'background_color': 'transparent',
        'padding_top': '100px'
    }
};

export const lightStyles = {
    'code': {
        'font_color': '#151515',
        'border_color': '#151515',
        'border_style': 'solid',
        'input_border_radius': '0.25rem',
        'font_size': '14px',
        'input_border_width': '1px',
        'input_font': 'inherit',
        'input_font_weight': '400',
        'input_margin': '10px 0px 5px 10px',
        'input_padding': '0px 5px 0px 5px',
        'label_color': '#151515',
        'label_size': '16px',
        'label_width': '150px',
        'label_font': 'Poppins, sans-serif, arial, serif',
        'label_font_weight': 'normal',
        'label_margin': '5px 0px 0px 10px',
        'label_padding': '2px 5px 2px 5px',
        'label_border_style': 'none',
        'label_border_color': 'none',
        'label_border_radius': '0.25rem',
        'label_border_width': '2px',
        'background_color': 'transparent',
        'height': '35px',
        'width': '18rem',
        'padding_bottom': '2px'
    },
    'cc': {
        'font_color': '#151515',
        'border_color': '#151515',
        'border_style': 'solid',
        'font_size': '16px',
        'font_weight': '400',
        'input_border_radius': '0.25rem',
        'input_border_width': '1px',
        'input_font': 'inherit',
        'input_font_weight': '400',
        'input_margin': '0px 0px 0px 0px',
        'input_padding': '0px 5px 0px 5px',
        'label_color': '#323a46',
        'label_size': '16px',
        'label_width': '200px',
        'label_font': 'Poppins, sans-serif, serif',
        'label_font_weight': '400',
        'label_margin': '10px 10px 5px 10px',
        'label_padding': '0px 0px 0px 0px',
        'label_border_style': 'none',
        'label_border_color': 'none',
        'label_border_radius': '0.25rem',
        'label_border_width': '0px',
        'background_color': 'transparent',
        'height': '35px',
        'width': '40rem',
        'padding_bottom': '0px',
        'line-height': '23px'
    },
    'exp': {
        'font_color': '#151515',
        'border_color': '#151515',
        'border_style': 'solid',
        'font_size': '14px',
        'input_border_radius': '0.25rem',
        'input_border_width': '1px',
        'input_font': 'inherit',
        'input_font_weight': '400',
        'input_margin': '10px 0px 5px 0px',
        'input_padding': '0px 5px 0px 5px',
        'label_color': '#323a46',
        'label_size': '16px',
        'label_width': '150px',
        'label_font': 'inherit',
        'label_font_weight': 'normal',
        'label_margin': '5px 0px 0px 0px',
        'label_padding': '2px 5px 2px 5px',
        'label_border_style': 'dashed',
        'label_border_color': 'none',
        'label_border_radius': '0rem',
        'label_border_width': '0px',
        'background_color': 'transparent',
        'height': '35px',
        'width': '10rem',
        'padding_bottom': '2px',
        'type': 'dropdown'
    },
    'body': {
        'background_color': 'transparent',
        'padding_top': '100px'
    }
};
