import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  poSSArr: [],
  poSSResponse: null,
  operationObj: {},
  arrangeOrder: 'asc',
  arrangeKey: 'PoCreatedOn',
  upcomingPastApptObj: {},
  myAppointmentsList: [],
  facilityRatingsReportList: [],
  facilityRatingsAllData: null,
  isLoading:false,
  myAppointmentsListResponse: null,
  userRatingsReportList: [],
  userRatingsAllData: null,
  apptsReportHistoryList:[]
}

export const POSSReducerSlice = createSlice({
  name: 'pOSSReducerSlice',
  initialState,
  reducers: {
    ssUpdatePoArrFunc: (state, action) => {
      state.poSSArr = action.payload
    },
      upcomingPastApptObjReducer: (state, action) => {
      state.upcomingPastApptObj = action.payload
    },
    updateOperationObj: (state, action) => {
      state.operationObj = action.payload
    },
    myApptsReducer: (state, action) => {
      state.myAppointmentsList = action.payload
    },
    myApptsResponseRedcuer: (state, action) => {
      state.myAppointmentsListResponse = action.payload
    },
    ssFinanceReportRedcuer: (state, action) => {
      state.ssFinanceReport = action.payload?.subscriptionList
      state.ssFinanceReportSummary = action.payload?.subscriptionsummary
    },
    updateFacilityRatingsReportList : (state, action) => {
      state.facilityRatingsReportList = action.payload
    },
    updateFacilityRatingsAllData : (state, action) => {
      state.facilityRatingsAllData = action.payload
    },
    updateUserRatingsReportList : (state, action) => {
      state.userRatingsReportList = action.payload
    },
    updateUserRatingsAllData : (state, action) => {
      state.userRatingsAllData = action.payload
    },
    updateTableData: (state, action) => {
      const { drillDownReport, arrange, key,isPaginated } = action.payload;
      if (isPaginated) {
        state[key].data = drillDownReport
      }
      else{
      state[key] = drillDownReport
    }
    state.arrangeKey = arrange.key
    state.arrangeOrder = arrange.order
    },
    ssUpdatePoArrResponse: (state, action) => {
      state.poSSResponse = action.payload
    },
    ssUpdateIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    ssUpdateIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    ssUpdatePoArrResponse: (state, action) => {
      state.poSSResponse = action.payload
    },
    myApptsResponseRedcuer: (state, action) => {
      state.myAppointmentsListResponse = action.payload
    },
    apptsReportHistoryReducer: (state, action) => {
      state.apptsReportHistoryList = action.payload
    }
  }
});

export const { ssUpdatePoArrFunc, updateOperationObj, updateTableData, upcomingPastApptObjReducer, myApptsReducer, ssFinanceReportRedcuer, updateFacilityRatingsReportList, updateFacilityRatingsAllData, ssUpdatePoArrResponse,ssUpdateIsLoading,myApptsResponseRedcuer,updateUserRatingsAllData,updateUserRatingsReportList,apptsReportHistoryReducer } = POSSReducerSlice.actions;

// Selectors
export const getPoSSselector = (state) => {
  return state.pOSSReducerSlice;
};


export default POSSReducerSlice.reducer;