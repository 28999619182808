import React from 'react'
import banner from '../../../../../../../Assests/Images/banner-image.png'
import UserPic from '../../../../../../../Assests/Images/user-new.png'
import './SSBanner.css'
import { getUserSelector } from '../../../../../../../../redux/reducers/reducerSlices/UserReducer'
import { useSelector } from 'react-redux'
import DefaultProfilePic from '../../../../../../../Assests/Images/defaultProfilePic.png'
import { AppConstant } from '../../../../../../../Assests/AppConstant'
export const SSBanner = ({ showApptSearch }) => {
    const user = useSelector(getUserSelector);
    const { userAccountDetails } = user;
    return (
        <>
            {showApptSearch ?
                <div className='book-appt-horizontal-container p-3'>
                    <div className='book-appt-horizontal'>
                        <div className='p-3'>
                            <i className="fa-regular fa-calendar"></i> Book Appointment
                        </div>
                        <hr />
                        <div className='p-3'>
                            <p className='mb-3 search-po-heading'>Search PO</p>
                            <div className='d-flex align-items-end justify-content-between'>
                                <div className='form-block-ss'>
                                    <p>PO Number<span className='text-danger'>*</span></p>
                                    <input type='text' className='form-control-ss-horizontal-appt' placeholder='Purchase Order' />
                                </div>
                                <div className='form-block-ss'>
                                    <p>Receiving ZIP Code<span className='text-danger'>*</span></p>
                                    <input type='text' className='form-control-ss-horizontal-appt' placeholder='Zip Code' />
                                </div>
                                <button className='submit-btn-ss-horizontal-appt'>Search</button>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className='banner-container d-flex justify-content-between'>
                    <div className='p-3 d-flex flex-column justify-content-around'>
                        <div className='banner-head d-flex align-items-center'>
                            <img src={userAccountDetails?.ProfilePicture ? userAccountDetails?.ProfilePicture : DefaultProfilePic} alt='user' />
                            <span className='banner-heading ms-2'>{` 👋 Hi ${userAccountDetails?.FirstName} ${userAccountDetails?.LastName}`} <br/>
                            {(userAccountDetails.UserType===AppConstant.commonStrings.carrierStr && userAccountDetails.CarrierName) && <span className='banner-heading f-12 ms-3'>{`  Carrier: ${userAccountDetails.CarrierName } `}</span>}
                            </span>
                           
                        </div>
                        <p className='banner-text mt-2'>Welcome to the appointment management system.
                            Here, you have the ability to view your existing
                            appointments and also schedule new ones.</p>
                        {/* <p className='banner-sub-text f-10 fw-bold ls-2'>Powered By: FreightSmith</p> */}

                    </div>
                    <img src={banner} alt='banner' className='banner-img' />
                </div>
            }
        </>

    )
}
