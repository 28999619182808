export const fetchTimeslotListCommonFunc = (selectedWarehouse) => {

    const apiParams = {
        "TimeSlotName": null,
        "DisplayOrderNo": null,
        "DoorStatus": null,
        "IDDock": null,
        "ID": null,
        "IDWarehouse": selectedWarehouse.ID,
        "CRUDTYPE": "S",
        "TimeSlotStatus": null,
        "CreatedOn": null,
        "CreatedBy": null,
        "UpdatedBy": null,
        "UpdatedOn": null,
    }

    const operationObj = {
        type: 'Fetch Timeslot',
        status: ''
    }

    return { apiParams: apiParams, operationObj: operationObj }
}