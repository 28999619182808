import { dayNames } from "./DatePickerSSData";

export const getNumberOfDaysInMonth = (year, month) => {
  return new Date(year, month + 1, 0).getDate();
};

export const getSordtedDays = (year, month) => {
  const dayIndex = new Date(year, month, 1).getDay() -1;
  const firstHalf = dayNames.slice(dayIndex);
  return [...firstHalf, ...dayNames.slice(0, dayIndex)];
 
};

export const range = (start, end) => {
  const length = Math.abs((end - start) / 1);
  const { result } = Array.from({ length }).reduce(
    ({ result, current }) => ({
      result: [...result, current],
      current: current + 1
    }),
    { result: [], current: start }
  );
  return result;
};

export const getTimeFromStart = (_day,currentMonth,currentYear) => {
  return new Date(currentYear, currentMonth, _day).getTime();
};
export function getDateSlots(currentMonth, currentYear) {
  const dateArray = range(1, getNumberOfDaysInMonth(currentYear, currentMonth) + 1);
  const slotSkipCount = new Date(currentYear,currentMonth,dateArray[0]).getDay();
  for (let i = 0; i < slotSkipCount; i++) {
    dateArray.unshift(null);
  }

  return dateArray;
}