import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getDefaultDashboardSelector } from '../../../../../../redux/reducers/reducerSlices/DefaultDashboardReducer';
import { sagaActions } from '../../../../../../sagas/sagaActions';
import { AppConstant } from '../../../../../Assests/AppConstant';
import { uuidv4 } from '../../../../../Assests/Utility';
import SectionWrapper from './Children/SectionWrapper';
import "./CustomDashboardWrapper.css"
import _ from 'lodash-es';
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useEffect } from 'react';
import { getAuthReducer } from '../../../../../../redux/reducers/reducerSlices/AuthReducer';
import { getUserSelector } from '../../../../../../redux/reducers/reducerSlices/UserReducer';
import { closeUpdatesSocketConnection, initializeUpdatesSocketConnection } from '../../../../../../Utils/updateSocket';
import { getFormattedDate, YYYY_MM_DD_FORMAT } from '../../../../../../Utils/DateUtils';
import { getSelectedDateSelector } from '../../../../../../redux/reducers/reducerSlices/DateSelectorReducer';


const CustomDashboardWrappper = (props) => {
    const { themeSelected = 'light-mode' } = props;
    const dispatch = useDispatch();
    const defaultDashboard = useSelector(getDefaultDashboardSelector)
    const user = useSelector(getAuthReducer);
    let { sectionData, widgetData, selectedCustomWidgetsArr, sk, autoFetch, dateRangeObj } = defaultDashboard;
    const [widgetListState, setWidgetListState] = useState([...widgetData])
    const [sectionDataState, setSectionDataState] = useState(sectionData && sectionData.length ? [...sectionData] : []);
    const userPermission = useSelector(getUserSelector);
    const {selectedWarehouse} = userPermission;
    const getSelectedDateSelectorObj = useSelector(getSelectedDateSelector);

    useEffect(() => {
        // Clear web socket stored data on component load
        dispatch({type: sagaActions.UPDATE_WEB_SOCKET, payload: null})
        getDashbaordWidget()
    }, [])

    useEffect(() => {
        const socketRef = initializeUpdatesSocketConnection(selectedWarehouse.ID, AppConstant.commonStrings.truckUpdateSocketUrl, onUpdateSocketReceived, onUpdateSocketError, onUpdateSocketClose)

        return () => closeUpdatesSocketConnection(socketRef, selectedWarehouse.ID)
    }, [])

    /**
    * Method is used to handle truck update and fetch latest truck data
    */
    const onUpdateSocketReceived = (message) => {
        dispatch({type: sagaActions.UPDATE_WEB_SOCKET, payload: null})
        let parsedMessage = JSON.parse(message)
        if (typeof parsedMessage === 'string') {
            parsedMessage = JSON.parse(parsedMessage)
        }
        if (!_.isEmpty(parsedMessage)) {
            const { type } = parsedMessage;
            dispatch({type: sagaActions.UPDATE_WEB_SOCKET, payload: type})
        }
    }

    /**
     * Method is used to captcure socket error and re initate the connection 
     * @param {*} error 
     */
    const onUpdateSocketError = (error) => {
        initializeUpdatesSocketConnection(selectedWarehouse.ID, AppConstant.commonStrings.truckUpdateSocketUrl, onUpdateSocketReceived, onUpdateSocketError, onUpdateSocketClose)
    }

    /**
     * Method is used to capture socket close
     * @param {*} data 
     */
    const onUpdateSocketClose = (data) => { }


    // useEffect(() => {
    //     if(autoFetch){
    //         getDashbaordWidget()
    //     }
    //   }, [autoFetch])

      useEffect(() => {
        getDashbaordWidget();
      }, [dateRangeObj])

      const getDashbaordWidget = () => {
        dispatch({
            type: sagaActions.GET_DASHBOARD_WIDGET, payload: {
                warehouse_id: selectedWarehouse.ID, user_id: user.auth.attributes.email
            }
        })
      }

    useEffect(() => {
        if(widgetData.length){
            filterWidgetData()
        } 
        if(sectionData && sectionData.length) {
            updateSelectedWigetItemAPI(sectionData)
            setSectionDataState([...sectionData])
        }
    },[widgetData, sectionData])

    useEffect(() => {
        if (selectedCustomWidgetsArr) {
            filterWidgetData()
        }
    }, [selectedCustomWidgetsArr])


    useEffect(() => {
        if (sectionDataState.length) {
            updateCustomDashboardAPI(sectionDataState)
        }
    }, [sectionDataState])

    const updateCustomDashboardAPI = (data) => {
        dispatch({
            type: sagaActions.UPDATE_DASHBOARD_WIDGET, payload: createPayload(data)
        });
    }

    const filterWidgetData = () => {
        const notCommonObjects = [];
        for (const obj1 of widgetData) {
            if (!selectedCustomWidgetsArr.some(obj2 => obj2.widget === obj1.name)) {
                notCommonObjects.push(obj1);
            }
        }
        setWidgetListState(notCommonObjects)
    }

    const createPayload = (data) => {
        return {
            warehouse_id: selectedWarehouse.ID, user_id: user.auth.attributes.email,
            name: data, sk: sk
        }
    }
    const addSection = () => {
        let data = { id: `wrapper-${uuidv4()}`, children: [{ name: '', id: `child-${uuidv4()}` }, { name: '', id: `child-${uuidv4()}` }] }
        let nData = [...sectionDataState, data]
        if (selectedCustomWidgetsArr.length < widgetData.length) {
            setSectionDataState([...nData])
            dispatch({
                type: sagaActions.UPDATE_SECTION_DATA, payload: nData
            });
        }
    }

    const addChildren = (el) => {
        const nData = sectionDataState.map(ele => {
            let tempEle = { ...ele }
            if (tempEle.id === el.id) {
                let childrens = [...tempEle.children]
                tempEle.children = [...childrens, { name: '', show: true, id: `child-${uuidv4()}` }]
            }
            return tempEle
        })
        setSectionDataState([...nData])
        dispatch({
            type: sagaActions.UPDATE_SECTION_DATA, payload: nData
        });
        // updateCustomDashboardAPI()
    }

    const deleteSection = (parent, child) => {
        let nData = [];
        if (parent.children.length === 1) {
            nData = sectionDataState.filter(ele => ele.id !== parent.id)
        } else if (parent.children.length > 1) {
            nData = sectionDataState.map(ele => {
                let tempEle = { ...ele }
                if (parent.id === ele.id) {
                    const children = [...tempEle.children];
                    tempEle.children = children.filter(iel => iel.id !== child.id)
                }
                return tempEle
            })

        }
        setSectionDataState([...nData])
        dispatch({
            type: sagaActions.UPDATE_SECTION_DATA, payload: nData
        });
        // updateCustomDashboardAPI()
    }

    const onDragEnd = (result) => {
        const { source, destination } = result;
        // If destination does not have anything
        if (!destination) return;
        //  If source and destination are same
        if (destination.droppableId === source.droppableId && destination.index === source.index) return;
        let add;
        let tempSectionData = [...sectionDataState];
        if (source.droppableId === 'droppable') {
            add = tempSectionData[source.index]
            tempSectionData.splice(source.index, 1)
        }
        if (destination.droppableId === 'droppable') {
            tempSectionData.splice(destination.index, 0, add)
        }
        setSectionDataState([...tempSectionData])
        dispatch({
            type: sagaActions.UPDATE_SECTION_DATA, payload: tempSectionData
        });
        // updateCustomDashboardAPI()
    }

    const selectWidgetsItems = (parent, child, widget) => {
        const selWidgetsObj = {
            parent: parent.id,
            child: child.id,
            widget: widget
        }
        const tempArr = [...addNewWidget(selWidgetsObj, selectedCustomWidgetsArr)];
        const selectedWidgetsArr = _.uniqWith(tempArr, _.isEqual)
        dispatch({
            type: sagaActions.UPDATE_SELECTED_CUSTOM_WIDGETS, payload: [...selectedWidgetsArr]
        });
        updateWidgetsApi(parent, child, widget)

    }
    // When Response received from API it should show respective graph as per response
    const updateSelectedWigetItemAPI = (data) => {
        let tempSelectedWidgetsArr  = []
        data.forEach(parent => {
             parent.children.forEach(child => {
                 tempSelectedWidgetsArr.push({
                    parent: parent.id,
                    child: child.id,
                    widget: child.name
                })
            })
        })
        dispatch({
            type: sagaActions.UPDATE_SELECTED_CUSTOM_WIDGETS, payload: [...tempSelectedWidgetsArr]
        });
    }

    const updateWidgetsApi = (parent, child, widget) => {
        const nData = sectionDataState.map((parentEle) => {
            if (parentEle.id === parent.id) {
               const updatedObjArr = parentEle.children.map((childEle, childIndex) => {
                    if (childEle.id === child.id) {
                        const newChildObj = { ...childEle };
                        newChildObj.name = widget;
                        newChildObj.show = true;
                        const newParentObj = { ...parentEle }
                        const newParentObjChildren = [...newParentObj.children]
                        newParentObjChildren[childIndex] = { ...newChildObj };
                        newParentObj.children = [...newParentObjChildren]
                        return(newParentObj)
                    } else {
                        return null
                    }
                })
                const updateObj = _.compact([...updatedObjArr])
                return updateObj[0]
            } else {
                return parentEle
            }
        })

        setSectionDataState([...nData])
        dispatch({
            type: sagaActions.UPDATE_SECTION_DATA, payload: nData
        });
    }

    const addNewWidget = (objToAdd, arr) => {
        const tempSelWidgetsArr = [objToAdd, ...arr]
        return [...new Set(tempSelWidgetsArr)]

    }

    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <button type="button" className={`buttonFunnelDash selectedButton selectedButtonCustomDashboard me-2 ${themeSelected}`} onClick={addSection}>{AppConstant.dashboard.customDashboard.addSectionBtnText}</button>
                <Droppable droppableId="droppable">
                    {(provided) => (

                        <div className='container-fluid dashboard customDashboard m-0 p-0' {...provided.droppableProps} ref={provided.innerRef}>
                            <div className='d-flex flex-column justify-content-center align-items-center p-2'>
                                {
                                    <SectionWrapper
                                        sectionData={sectionDataState}
                                        deleteSection={(el, cEl) => deleteSection(el, cEl)}
                                        selectWidgetsItems={(el, cEl, widget) => selectWidgetsItems(el, cEl, widget)}
                                        selectedWidgetsArr={selectedCustomWidgetsArr}
                                        themeSelected={themeSelected}
                                        addChildren={(el) => addChildren(el)}
                                        widgetList={widgetListState}
                                         />
                                }
                            </div>
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </>);
}

export default CustomDashboardWrappper;