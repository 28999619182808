import UchainAxios from './UchainAxios';
import FSAxios from './FSAxios'

export let baseURL = '';
let FSBaseURL = ''
const responsebody = (response) => response.data;
export const requests = {
  get: (url) => UchainAxios.get(url).then(responsebody),
  post: (url, body) => UchainAxios.post(url, body).then(responsebody),
  put: (url, body) => UchainAxios.put(url).then(responsebody),
  del: (url) => UchainAxios.delete(url).then(responsebody),
  fsPost: (url, body) => FSAxios.post(url, body).then(responsebody),
}

if (process.env.REACT_APP_TYPE === 'web') {
  let uri = process.env.REACT_APP_ENV === 'development' ? "https://soqihsvu8f.execute-api.us-west-2.amazonaws.com/dev" : "https://grztn5r3ac.execute-api.us-east-2.amazonaws.com/prod";
  // baseURL = uri + "/warehouseportal/v1";
  baseURL = uri;
  FSBaseURL = process.env.REACT_APP_ENV === 'development' ? "https://m0s7aksobh.execute-api.us-west-2.amazonaws.com/dev" : "https://mxs3az5as0.execute-api.us-east-2.amazonaws.com/prod"
}
else {
  baseURL = '';
  baseURL = '';
}

export const Uchain = {
  // Executor Page
  getTruck: (data) => requests.post(`${baseURL}/v2/executer/po-list`, data),
  getLoadDetails: (data) => requests.post(`${baseURL}/v2/executer/po-details`, data),
  getDoors: (data) => requests.post(`${baseURL}/v2/executer/door-details/door-list`, data),
  getComments: (data) => requests.post(`${baseURL}/v2/executer/comments/get`, data),
  addComment: (data) => requests.post(`${baseURL}/v2/executer/comments/add`, data),
  assignDoor: (data) => requests.post(`${baseURL}/v2/executer/door-details/assign-door`, data),
  approvePO: (data) => requests.post(`${baseURL}/v2/executer/po-approval`, data),
  getDockList: (data) => requests.post(`${baseURL}/v2/executer/dock-details/dock-list`, data),
  assignDock: (data) => requests.post(`${baseURL}/v2/executer/dock-details/assign-dock`, data),
  releaseDoorApi: (data) => requests.post(`${baseURL}/v2/executer/door-details/release-door`, data),
  // Default Dashborad
  dashboardLoadStatusDetails: (data) => requests.post(`${baseURL}/v2/admin/default-dashboard/load-status-overview`, data),
  unloadingHours: (data) => requests.post(`${baseURL}/v2/admin/default-dashboard/unloading-hours`, data),
  detentionStatus: (data) => requests.post(`${baseURL}/v2/admin/default-dashboard/detention-status`, data),
  appointmentCompliance: (data) => requests.post(`${baseURL}/v2/admin/default-dashboard/appointment-compliance`, data),
  taskItemsDue: (data) => requests.post(`${baseURL}/v2/admin/default-dashboard/task-item-due`, data),
  top10LateLoads: (data) => requests.post(`${baseURL}/v2/admin/default-dashboard/top-ten-late-loads`, data),
  unloadingTypeSummary: (data) => requests.post(`${baseURL}/v2/admin/default-dashboard/unloading-type-summery`, data),
  getDoorStatusWidget: (data) => requests.post(`${baseURL}/v2/admin/default-dashboard/door-status`, data),
  getUnscheduledPOWidget: (data) => requests.post(`${baseURL}/v2/admin/default-dashboard/unscheduled-pos`, data),
  getDoorAssignmentReport: (data) => requests.post(`${baseURL}/sc/SmartSchedule/DoorAssignmentReport`, data),
  // Custom Dashboard
  getDashBoardWidget: (data) => requests.post(`${baseURL}/v2/admin/default-dashboard/widgets/get-widgets`, data),
  updateCustomDashboard: (data) => requests.post(`${baseURL}/v2/admin/default-dashboard/widgets/update-widgets`, data),
  getDoorUsageSummary: (data) => requests.post(`${baseURL}/v2/admin/default-dashboard/door-usage-summary`, data),
  // User Permissions
  getUserType: (data) => requests.post(`${baseURL}/v2/admin/user-details/get-user-type`, data),
  getUserAccessWarehouse: (data) => requests.post(`${baseURL}/v2/admin/user-details/user-access-warehouse-list`, data),
  getUserPermission: (data) => requests.post(`${baseURL}/v2/admin/user-details/user-permission`, data),

  getRoleList: (data) => requests.post(`${baseURL}/v2/admin/role-details`, data),
  getRolesFeaturesList: (data) => requests.post(`${baseURL}/v2/admin/role-feature-details/role-feature-list`, data),
  assignFeaturesToRole: (data) => requests.post(`${baseURL}/v2/admin/role-feature-details/assign-feature-to-role`, data),

  //help and feedback
  addHelpRequest: (data) => requests.post(`${baseURL}/v2/helpAndFeedback/HelpAndFeedBackDetails`, data),
  addFeedbackRequest: (data) => requests.post(`${baseURL}/v2/helpAndFeedback/HelpAndFeedBackDetails`, data),
  getFaqList: (data) => requests.post(`${baseURL}/v2/helpAndFeedback/FAQs`, data),
  getReqRecDropdownsList: (data) => requests.get(`${baseURL}/v2/helpAndFeedback/RequestReceivedDropDownDetails`, data),
  getNotificationObj: (data) => requests.get(`${baseURL}/v2/helpAndFeedback/NotificationDetails`, data),

  //upload file
  uploadFile: (data) => requests.post(`${baseURL}/v2/executer/uploadFile`, data, { headers: { "content-type": "multipart/form-data" } }),
  //CompanyGroup data
  companyGroupData: (data) => requests.post(`${baseURL}/v2/admin/company-details/add-company-group-details`, data),
  //companyData
  companyData: (data) => requests.post(`${baseURL}/v2/admin/company-details/add-company-details`, data),
  //getWareHouseList
  getWareHouseList: (data) => requests.post(`${baseURL}/v2/admin/warehouse-details/warehouse-list`, data),
  getLumpingFacilityDetails: () => requests.get(`${baseURL}/v2/admin/warehouse-details/lumping-facility-details`),
  getTimeZoneDetails: () => requests.get(`${baseURL}/v2/admin/warehouse-details/time-zone-details`),
  getWarehouseLocationList: () => requests.get(`${baseURL}/v2/admin/warehouse-details/warehouse-location-list`),
  getTaxCodeDetails: (data) => requests.post(`${baseURL}/v2/admin/warehouse-details/tax-code-details`, data),
  getCountryList: () => requests.get(`${baseURL}/v2/country-codes`),
  addWarehouse: (data) => requests.post(`${baseURL}/v2/admin/warehouse-details/add-warehouse`, data),

  //user
  createUser: (data) => requests.post(`${baseURL}/v2/admin/user-details/create-user`, data),
  deleteUser: (data) => requests.post(`${baseURL}/v2/admin/user-details/create-user`, data),
  updateUserAWS: (data) => requests.post(`${baseURL}/v2/admin/user-details/update-user`, data),
  updateUser: (data) => requests.post(`${baseURL}/v2/admin/user-details/add-user-details`, data),
  updateCompanyGroup: (data) => requests.post(`${baseURL}/v2/admin/company-details/add-company-group-details`, data),
  updateCompany: (data) => requests.post(`${baseURL}/v2/admin/company-details/add-company-details`, data),
  getAllWarehouse: (data) => requests.post(`${baseURL}/v2/admin/warehouse-details/warehouse-list`, data),
  getUserWarehouseRoleDetails: (data) => requests.post(`${baseURL}/v2/admin/user-details/user-warehouse-role-details`, data),
  getCompanyList: (data) => requests.post(`${baseURL}/v2/admin/company-details/company-list`, data),
  getAllSuperWarehouse: () => requests.get(`${baseURL}/v2/admin/warehouse-details/active-warehouse-list`),
  getIssuesFeedbackList: () => requests.get(`${baseURL}/v2/admin/HelpAndFeedBackDetails`),
  userAccountDetails: (data) => requests.post(`${baseURL}/v2/admin/user-details/user-profile-details`, data),
  branding: (data) => requests.post(`${baseURL}/v2/admin/warehouse-details/branding`, data),
  resendEmail: (data) => requests.post(`${baseURL}/v2/admin/user-details/resend-welcome-mail`, data),
  navigationApi: () => requests.get(`${baseURL}/v2/admin/role-feature-details/get-navigation-list`),
  resetUserPassword: (data) => requests.post(`${baseURL}/v2/admin/user-details/admin-change-password`, data),
  updateLastLogon: (data) => requests.post(`${baseURL}/v2/admin/user-details/update-last-logon`, data),
  //reports
  adfatReport: (data) => requests.post(`${baseURL}/v2/reports/adfat-details`, data),
  getCarrier: (data) => requests.post(`${baseURL}/v2/reports/carrier-details`, data),
  getVendor: (data) => requests.post(`${baseURL}/v2/reports/vendor-details`, data),
  getTruckUnloadSummary: (data) => requests.post(`${baseURL}/v2/reports/truck-unload-summary-details`, data),
  getTruckUnloadStats: (data) => requests.post(`${baseURL}/v2/reports/truck-unload-statistics-details`, data),
  getScheduledLoadStatus: (data) => requests.post(`${baseURL}/v2/reports/scheduled-load-status-details`, data),
  ssFinanceReport: (data) => requests.post(`${baseURL.replaceAll('/v2', '')}/sc/SmartSchedule/getSubscriptionFinanceReport`, data),
  facilityRatings: (data) => requests.post(`${baseURL}/v2/reports/facilty-ratings`, data),
  userRatings: (data) => requests.post(`${baseURL}/sc/SmartSchedule/reports/user-ratings`, data),
  
  
  //Smart Schedule
  intiateSSPoSearch: (data) => requests.post(`${baseURL}/sc/SmartSchedule/SearchPo`, data),
  scheduleAppointment: (data) => requests.post(`${baseURL}/sc/SmartSchedule/ScheduleAppointments`, data),
  getScheduleData: (data) => requests.post(`${baseURL}/sc/SmartSchedule/DockAndTimeSlotDetails`, data),
  hitPaytraceApymentApi: (data) => requests.post(`${baseURL.replaceAll('/v2', '')}/sc/credit-card/payment`, data),
  hitAddCarrierApi: (data) => requests.post(`${baseURL.replaceAll('/v2', '')}/sc/SmartSchedule/CarrierSignup`, data),
  getPaytraceClientId: (data) => requests.get(`${baseURL.replaceAll('/v2', '')}/sc/credit-card/auth`),
  ssCreatePo: (data) => requests.post(`${baseURL.replaceAll('/v2', '')}/sc/SmartSchedule/PoManagement`, data),
  addCarrierSpro: (data) => requests.post(`${baseURL}/sc/SmartSchedule/addCarrierSPro`, data),
  sendEmailForAppointment: (data) => requests.post(`${baseURL}/sc/SmartSchedule/send-email-appointment`, data),
  getWhMetaDockListApi: (data) => requests.post(`${baseURL.replaceAll('/v2', '')}/sc/SmartSchedule/DockManagementDetails`, data),
  getWhMetaLeavesListApi: (data) => requests.post(`${baseURL.replaceAll('/v2', '')}/sc/SmartSchedule/AddWarehouseLeaveDetails`, data),
  getWhMetaBreaksListApi: (data) => requests.post(`${baseURL.replaceAll('/v2', '')}/sc/SmartSchedule/AddWarehouseBreakDetails`, data),
  getWhMetaWhCapacityApi: (data) => requests.post(`${baseURL.replaceAll('/v2', '')}/sc/SmartSchedule/UpdateWarehouseCapacity`, data),
  getWhMetaTimeslotApi: (data) => requests.post(`${baseURL.replaceAll('/v2', '')}/sc/SmartSchedule/DockDoorManagementDetails`, data),
  ssWarehouseCapacity: (data) => requests.post(`${baseURL}/sc/SmartSchedule/UpdateWarehouseCapacity`, data),
  ssWarehouseLeave: (data) => requests.post(`${baseURL}/sc/SmartSchedule/AddWarehouseLeaveDetails`, data),
  ssWarehouseBreak: (data) => requests.post(`${baseURL}/sc/SmartSchedule/AddWarehouseBreakDetails`, data),
  uploadPoTemplateApi: (data) => requests.post(`${baseURL}/sc/SmartSchedule/po-excel-upload`, data),
  cancelCarrierSubscriptionApi: (data) => requests.post(`${baseURL.replaceAll('/v2', '')}/sc/credit-card/CancelSubscription`, data),
  upcomingPastApptsApi: (data) => requests.post(`${baseURL}/sc/SmartSchedule/SSAppointmentDetails`, data),
  upcomingPastApptsCalendarApi: (data) => requests.post(`${baseURL}/sc/SmartSchedule/SSAppointmentDetailsCalendar`, data),
  addMorePos: (data) => requests.post(`${baseURL}/sc/SmartSchedule/ValidatePODetails`, data),
  ssRecommendationsApi: (data) => requests.post(`${baseURL}/sc/SmartSchedule/GetRecommendationDetails`, data),
  getCarriers: (data) => requests.post(`${baseURL}/sc/SmartSchedule/getCarriers`, data),
  mySubscriptionsApi: (data) => requests.post(`${baseURL}/sc/SmartSchedule/getSubscriptionDetails`, data),
  myAppointmentsApi: (data) => requests.post(`${baseURL}/sc/SmartSchedule/getAppointmentReportDetails`, data),
  leavesForDocksApi: (data) => requests.post(`${baseURL}/sc/SmartSchedule/addDockLeaveDetails`, data),
  breaksForDocksApi: (data) => requests.post(`${baseURL.replaceAll('/v2', '')}/sc/SmartSchedule/addDockBreakDetails`, data),
  driverCheckinApi: (data) => requests.post(`${baseURL.replaceAll('/v2', '')}/sc/driver-checkin`, data),
  getDriverPoDATA: (data) => requests.post(`${baseURL.replaceAll('/v2', '')}/sc/SmartSchedule/gaurdPoLookUp`, data),
  getDriverAppointDATA: (data) => requests.post(`${baseURL.replaceAll('/v2', '')}/sc/SmartSchedule/getPosByWarehouseInGaurdFlow  `, data),
  getDriverAppointDATA: (data) => requests.post(`${baseURL.replaceAll('/v2', '')}/sc/SmartSchedule/getPosByWarehouseInGaurdFlow`, data),
  getAppointmentTrackerDetails: (data) => requests.post(`${baseURL}/sc/SmartSchedule/getappointmentTrackerDetails`, data),
  getAppointmentsByDateApi: (data) => requests.post(`${baseURL}/sc/SmartSchedule/getAppointmentList`, data),
  updateCarrierSubUser: (data) => requests.post(`${baseURL}/sc/SmartSchedule/secondaryCarrierSignUp`, data),
  updateCardDetails: (data) => requests.post(`${baseURL}/sc/credit-card/updateCardDetails`, data),
  storeCardDetails: (data) => requests.post(`${baseURL}/sc/credit-card/creditCardDetails`, data),
  superAdminUpdatePreCheck: (data) => requests.post(`${baseURL}/sc/SmartSchedule/adminUpdatePreCheck`, data),
  downloadFacilityRatingReport: (data) => requests.post(`${baseURL}/v2/reports/download-facility-ratings`, data),
  downloadUserRatingReport: (data) => requests.post(`${baseURL}/sc/SmartSchedule/reports/download-user-ratings`, data),
  downloadFacilitiyRatingReport: (data) => requests.post(`${baseURL}/v2/reports/download-facility-ratings`, data),
  getCheckInSearchedPo: (data) => requests.post(`${baseURL}/v2/executer/poLookup`, data),
  addCheckIn: (data) => requests.post(`${baseURL}/v2/executer/checkin`, data),
  confirmDoor: (data) => requests.post(`${baseURL}/sc/SmartSchedule/confirm-door`, data),
  downloadCommonReports: (data) => requests.post(`${baseURL}/sc/SmartSchedule/downloadReport`, data),
  downloadCommonReportsOptimized: (data) => requests.post(`${baseURL}/sc/SmartSchedule/commanDownloadReport`, data),
  getConfigration: () => requests.get(`${baseURL}/sc/SmartSchedule/GetAppConfigDetails`),
  liveTruckReport: (data) => requests.post(`${baseURL}/sc/SmartSchedule/live-trucks`, data),
  getScheduleUploadDetails: (data) => requests.post(`${baseURL}/sc/SmartSchedule/getScheduleUploadStatus`, data),
  getAppointmentReportSummary: (data) => requests.post(`${baseURL}/sc/reports/appointmentReportSummary`, data),
  getCityWithZipcode:  (data) => requests.post(`${baseURL}/sc/SmartSchedule/findCitywithZipcode`, data),
  getAppointmentHistory: (data) => requests.post(`${baseURL}/sc/SmartSchedule/appointmentHistory`, data),
  updateIMSSPDefaultDock: (data) => requests.post(`${baseURL}/sc/SmartSchedule/updateIMSSpUserConfig`, data),
  getMCDetails:  (data) => requests.post(`${baseURL}/sc/SmartSchedule/getMCDetails`, data),
  appointmentReportHistory: (data) => requests.post(`${baseURL}/sc/SmartSchedule/appointmentReportHistory`, data),
  validateDateForAppointmentApi: (data) => requests.post(`${baseURL}/sc/SmartSchedule/validateDateForAppointment`, data),
  getDockConflictDetails: (data) => requests.post(`${baseURL}/sc/SmartSchedule/adminDockDeleteConflicts`, data),
  deletePosAndAppointments: (data) => requests.post(`${baseURL}/sc/SmartSchedule/deletePOsAndAppts`, data),
  uploadAppointmentDocs: (data) => requests.post(`${baseURL}/sc/SmartSchedule/uploadScheduleAppointmentDocs`, data),
  sproWarehouseManager: (data) => requests.post(`${baseURL}/sc/SmartSchedule/sproWarehouseManager`, data),

  
  
  //Eclipse Ops
  getEclipseOpsPaymentLoadDetails: (data) => requests.post(`${baseURL}/sc/EclipseOps/getEclipseOpsLoadPaymentData`, data),

  //FS Warehouse
  getFSWarehouse: (data) => requests.post(`${baseURL}/sc/IMS/getFSWarehouse`, data),
  fsPayWarehouseDetails: (data) => requests.post(`${baseURL}/sc/IMS/fsPayWarehouseDetails`, data),
  SendEclipseReceipt: (data) => requests.post(`${FSBaseURL}/sendEclipseReportEmail`, data),
  ManualPaymentApi: (data) => requests.fsPost(`${FSBaseURL}/relaypayment`, data),
  GetEclipseOpsServiceFee: (data) => requests.fsPost(`${FSBaseURL}/getEclipseOpsServiceFee/`, data),
  GetManualPaymentMethods: () => requests.get(`${baseURL}/sc/EclipseOps/getManualPaymentMethods`),
  getFSPOHistory: (data) => requests.post(`${baseURL}/sc/EclipseOps/getEclipsePODetails`, data),

  //Gatepass
  gatePassDetails: (data) => requests.post(`${baseURL}/sc/IMS/gatePassDetails`, data),
  getDocksForGatePass: (data) => requests.post(`${baseURL}/sc/IMS/getDocksForGatePass`, data),
  getWarehouseDetailsForGatePass: (data) => requests.post(`${baseURL}/sc/IMS/getWarehouseDetailsForgatePass`, data),
}