import { createSlice } from '@reduxjs/toolkit';
 
const initialState = {
    initiateDrillDownReport: false,
    arrangeOrder: 'asc',
    arrangeKey: 'AppointmentTime',
    dashboardLoadStatusObj: {},
    unloadingHours: {},
    detentionStatus: {},
    appointmentCompliance: {},
    isLoading: false,
    error: null,
    taskItemsDueObj: {},
    autoFetch: false,
    selectedTaskItem: null,
    unloadingHoursdrillDownReport: [],
    dashboardLoadStatusDrillDownReport: {},
    detentionStatusDrillDownReport: [],
    detentionStatusDrillDownReportResponse: {},
    appointmentCompliancesDrillDownReport: [],
    appointmentCompliancesDrillDownReportResponse: {},
    selectedButtonStatusOverview: 'all',
    top10LateLoads: [],
    dashboardType: 'Default',
    sectionData: [],
    widgetData: [],
    selectedCustomWidgetsArr: [],
    detentionPercentageStatus:{},
    unloadingTypeSummary: {},
    unloadingTypeSummaryDrilldown: [],
    doorUsageSummaryDrillDown: [],
    sk: '',
    doorUsageSummary:[],
    doorStatusWidget : [],
    doorStatusDrillDown: [],
    unScheduledPOSummaryObj: {},
    unScheduledPOSummaryDrillDown: [],
    top10LateLoadsDrillDown: [],
    selectedTop10LateLoad: '',
    dateRangeObj: {
        start: '',
        end: ''
    },
    customDashboardDockSelectedList: [],
    doorAssignmentReportData: [],
    doorAssignmentReportDrillDown: [],
    doorAssignmentLoader: false,
    webSocketChange: null 
}
 
export const DefaultDashboardReducer = createSlice({
    name: 'defaultDashboardReducer',
    initialState,
    reducers: {
        updateDashboardLoadStatusObj: (state, action) => {
            state.dashboardLoadStatusObj = action.payload;
        },
        getDashboardLoadStatusDrillDown: (state, action) => {
            state.dashboardLoadStatusDrillDownReport = action.payload
            state.initiateDrillDownReport = false
        },
        getUnloadingHours: (state, action) => {
            state.unloadingHours = action.payload
        },
        getUnloadingHoursDrillDown: (state, action) => {
            state.unloadingHoursdrillDownReport = action.payload
            state.initiateDrillDownReport = false
        },
        getDetentionStatusData: (state, action) => {
            state.detentionStatus = action.payload
        },
        getDetentionPercentageStatusData: (state, action) => {
            state.detentionPercentageStatus = action.payload
        },
        getDetentionStatusDrillDown: (state, action) => {
            state.detentionStatusDrillDownReport = action.payload
            state.initiateDrillDownReport = false
        },
        getDetentionStatusDrillDownResponse: (state, action) => {
            state.detentionStatusDrillDownReportResponse = action.payload
            state.initiateDrillDownReport = false
        },
        getAppointmentCompliance: (state, action) => {
            state.appointmentCompliance = action.payload
        },
        getAppointmentComplianceDrillDown: (state, action) => {
            state.appointmentCompliancesDrillDownReport = action.payload
            state.initiateDrillDownReport = false
        },
        getAppointmentComplianceDrillDownResponse: (state, action) => {
            state.appointmentCompliancesDrillDownReportResponse = action.payload
            state.initiateDrillDownReport = false
        },
        getTaskItemsDueObj: (state, action) => {
            state.taskItemsDueObj = action.payload
        },
        updateAutoFetch: (state, action) => {
            state.autoFetch = action.payload
        },
        getTaskItem: (state, action) => {
            state.selectedTaskItem = action.payload
        },
        updateDrillDownReport: (state, action) => {
            const { drillDownReport , arrange, key} = action.payload;
            state[key] = drillDownReport
            state.arrangeKey =  arrange.key
            state.arrangeOrder = arrange.order
        },
        initiateReport: (state, action) => {
            state.initiateDrillDownReport = action.payload
        },
        updateSelectedButtonStatusOverview: (state, action) => {
            state.selectedButtonStatusOverview = action.payload
        },
        updateTop10LateLoads: (state, action) => {
            state.top10LateLoads = action.payload
        },
        updateSelectedButton: (state, action) => {
            const { loadStr, key} = action.payload;
            state[key] = loadStr;
        },
        updateSectionData: (state, action) => {
            state.sectionData = action.payload
        },
        updateGetWidgetList: (state, action) => {
            state.widgetData = action.payload
        },
        updateSortkey: (state, action) => {
            state.sk = action.payload
        },
        updateSelectedCustomWidgetsArr: (state, action) => {
            state.selectedCustomWidgetsArr = action.payload
        },
        updateLoadingTypeSummary: (state, action) => {
            state.unloadingTypeSummary = action.payload
        },
        updateLoadingTypeSummaryDrilldown: (state, action) => {
            state.unloadingTypeSummaryDrilldown = action.payload;
            state.initiateDrillDownReport = false
        },
        updateDoorUsageSummaryDrillDown: (state, action) => {
            state.doorUsageSummaryDrillDown = action.payload;
            state.initiateDrillDownReport = false
        },
        updateDoorUsageSummary: (state, action) => {
            state.doorUsageSummary = action.payload
        },
        updateDoorStatusWidget: (state, action) => {
            state.doorStatusWidget = action.payload
        },
        updateDoorStatusDrillDown: (state, action) => {
            state.doorStatusDrillDown = action.payload;
            state.initiateDrillDownReport = false
        },
        updateUnScheduledPOSummaryObj: (state, action) => {
            state.unScheduledPOSummaryObj = action.payload
        },
        updateTop10LateLoadsDrillDown: (state, action) => {
            state.top10LateLoadsDrillDown = action.payload;
            state.initiateDrillDownReport = false
        },
        updateUnScheduledPOSummaryDrillDown: (state, action) => {
            state.unScheduledPOSummaryDrillDown = action.payload;
            state.initiateDrillDownReport = false
        },
        updateSelectedTop10LateLoad: (state, action) => {
            state.selectedTop10LateLoad = action.payload
        },
        updateDateRange: (state, action) => {
            state.dateRangeObj = action.payload;
        },
        updateCustomDashboardDockSelectedList: (state, action) => {
            state.customDashboardDockSelectedList = action.payload;
        },
        updateDashboardLoadStatusDrillDownReport: (state, action) => {
            const { dashboardLoadStatusDrillDownReport, arrange} = action.payload;
            state.dashboardLoadStatusDrillDownReport = dashboardLoadStatusDrillDownReport
            state.arrangeKey =  arrange.key
            state.arrangeOrder = arrange.order
        },
        updateDoorAssignmentObj: (state, action) => {
            state.doorAssignmentReportData = action.payload;
        },
        updateDoorAssignmentDrillDown: (state, action) => {
            state.doorAssignmentReportDrillDown = action.payload;
        },
        updateDoorAssignmentLoader: (state, action) => {
            state.doorAssignmentLoader = action.payload;
        },
        resetDrillDownDoorAssignment: (state) => {
            state.doorAssignmentReportDrillDown = []
        },
        updateDoorAssignmentReportDrillDown: (state, action) => {
            const { doorAssignmentReportDrillDown, arrange} = action.payload;
            state.doorAssignmentReportDrillDown = doorAssignmentReportDrillDown
            state.arrangeKey =  arrange.key
            state.arrangeOrder = arrange.order
        },
        updateWebSocket: (state, action) => {
            state.webSocketChange = action.payload;
        }
    }
      
});
 
 
export const { updateDashboardLoadStatusObj, getUnloadingHours, getDetentionStatusData, getAppointmentCompliance,updateAutoFetch, getTaskItemsDueObj, getUnloadingHoursDrillDown, getDashboardLoadStatusDrillDown, updateDrillDownReport, initiateReport, updateSelectedButton, getDetentionStatusDrillDown, getAppointmentComplianceDrillDown, getTaskItem, updateSectionData, updateTop10LateLoads,
updateGetWidgetList, updateSelectedCustomWidgetsArr, getDetentionPercentageStatusData,
updateLoadingTypeSummary, updateLoadingTypeSummaryDrilldown, updateSortkey,
updateDoorStatusWidget, updateDoorUsageSummary, updateDoorUsageSummaryDrillDown,
updateDoorStatusDrillDown, updateUnScheduledPOSummaryObj, updateTop10LateLoadsDrillDown,
updateUnScheduledPOSummaryDrillDown, updateSelectedTop10LateLoad, updateDateRange, updateCustomDashboardDockSelectedList,
getDetentionStatusDrillDownResponse, updateDashboardLoadStatusDrillDownReport, updateDoorAssignmentObj, updateDoorAssignmentDrillDown, updateDoorAssignmentLoader, resetDrillDownDoorAssignment, updateDoorAssignmentReportDrillDown,getAppointmentComplianceDrillDownResponse, updateWebSocket
} = DefaultDashboardReducer.actions;
 
// Selectors
export const getDefaultDashboardSelector = (state) => {
    return state.defaultDashboardReducer;
};
 
export default DefaultDashboardReducer.reducer;