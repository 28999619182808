import React, { useState, useEffect, forwardRef, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sagaActions } from '../../../../../../../sagas/sagaActions';
import _ from 'lodash-es';
import Popup from '../../../../../Common/Popup/Popup';
import { CustomDynamicForm } from '../../../../../Common/CustomForm/CustomDynamicForm';
import { getLumpingFaciltyReducer } from '../../../../../../../redux/reducers/reducerSlices/GetLumpingFaciltyReducer';
import { getUserSelector } from '../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { AppConstant } from '../../../../../../Assests/AppConstant';
import { sortArrByKey } from '../../../../../../Assests/Utility';
import { YYYY_MM_DD_FORMAT, getFormattedDate, changeDateToGivenFormat } from '../../../../../../../Utils/DateUtils';
import DownloadSection from '../../../DownloadSection/DownloadSection';
import { usePrevious } from '../../../../../Common/CustomHooks/usePrevious';
import TruckUnloadStatisticsList from './ListComponent/TruckUnloadStatisticsList';
import { getDockListSelector } from '../../../../../../../redux/reducers/reducerSlices/DockListReducer';
import ReportsInput from '../Components/ReportsInputs';
import './TruckUnloadStatistics.css';

const TruckUnloadStatistics = (props) => {
    const dispatch = useDispatch();
    const { themeSelected } = props;
    const [showModal, setShowModal] = useState(false);
    const lumpingFacilityData = useSelector(getLumpingFaciltyReducer);
    const user = useSelector(getUserSelector);
    const { userType, parentWarehouse } = user;
    const [lumpingFacDataArr, setLumpingFacDataArr] = useState([]);
    const [selectedWarehouse, setSelectedWarehouse] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [warehouseListState, setWarehouseListState] = useState([]);
    const [loading, setLoading] = useState(false);
    const divRef = useRef(null);
    const [currentTable, setCurrentTable] = useState(null);
    const [currentTableRef, setCurrentTableRef] = useState(null);
    const previousTable = usePrevious(currentTable);
    const getDockList = useSelector(getDockListSelector);
    const { dockList } = getDockList;
    const [editData, setEditData] = useState({});
    const [apiParams, setApiParams] = useState([]);
    const [dockListArr, setDockListArr] = useState([]);
    const [particularFieldValue, setParticularFieldValue] = useState("");

    useEffect(() => {
        const container = divRef.current;
        const tables = container.querySelectorAll('table');
        if (tables && tables.length && _.isEqual(currentTable, previousTable)) {
            setCurrentTableRef(container);
            setCurrentTable(tables);
        }
    })

    useEffect(() => {
        setWarehouseListState([])
        setLumpingFacDataArr([])
        setDockListArr([])
        setShowModal(true);
        const { userPermission } = AppConstant;
        dispatch({ type: sagaActions.GET_LUMPING_FACILTY_DATA });
        if (userType === userPermission.usertypes.user) {
            setWarehouseListState([parentWarehouse])
            dispatch({ type: sagaActions.DOCK_LIST, payload: { warehouse_id: parentWarehouse.ID } })
            setParticularFieldValue({ warehouse_id: parentWarehouse.ID, lumping_facility_id: parentWarehouse.IDLumpingFacility });
        }
    }, [])

    useEffect(() => {
        if (lumpingFacilityData.data) {
            const { IDLumpingFacility } = parentWarehouse;
            const tempArr = lumpingFacilityData.data.filter(el => el.ID === IDLumpingFacility)
            setLumpingFacDataArr(tempArr);
        }
    }, [lumpingFacilityData.data])

    useEffect(() => {
        if (_.size(dockList)) {
            let dockListtemp = [{ dock_id: AppConstant.reports.master_id_all, dock_name: 'ALL' }, ...dockList]
            setDockListArr(dockListtemp)
        }
    }, [dockList])

    const getLumpingFacilityList = () => {
        return (
            lumpingFacDataArr &&
            lumpingFacDataArr.map((data, index) => {
                return (
                    <option key={data.ID} value={data.ID}>
                        {data.Description}
                    </option>
                );
            })
        );
    };

    const getWarehousesList = () => {
        return (
            warehouseListState &&
            warehouseListState.map((data, index) => {
                return (
                    <option key={data.ID} value={data.ID}>
                        {data.Name}
                    </option>
                );
            })
        );
    };

    const getDocksList = () => {
        return (
            dockListArr &&
            dockListArr.map((data, index) => {
                return (
                    <option key={data.dock_id} value={data.dock_id}>
                        {data.dock_name}
                    </option>
                );
            })
        );
    };

    const TUSCustomInput = forwardRef(({ value, onClick }, ref) => (
        <button className="customDatePickerInput" onClick={onClick} ref={ref}>
            {value}
        </button>
    ));

    const getFormFieldData = (data, name) => {
        if (name === 'warehouse_id') {
            setSelectedWarehouse(_.find(warehouseListState, { ID: data }))
        }
        if (name === 'custom-date-picker') {
            setSelectedDate(changeDateToGivenFormat(data, AppConstant.commonStrings.MM_DD_YYYY_dateFormat))
        }
    };

    const getSummitedFormData = (data) => {

        const apiParams = {
            "IDLumpingFacility": data.lumping_facility_id,
            "IDWarehouse": data.warehouse_id,
            "PeriodType": data.period_id,
            "dock_id": data.dock_id === AppConstant.reports.master_id_all ? null : data.dock_id,
            "Date": getFormattedDate(YYYY_MM_DD_FORMAT, data['custom-date-picker'])
        }

        const selectedLumper = (_.find(lumpingFacDataArr, { ID: data.lumping_facility_id }));
        const selectedWarehouse = (_.find(warehouseListState, { ID: data.warehouse_id }));
        const selectedDock = (_.find(dockListArr, { dock_id: data.dock_id }));

        setApiParams(
            [{ "Lumping Facility ": selectedLumper.Description ? selectedLumper.Description : '' },
            { "Warehouse": selectedWarehouse.Name ? selectedWarehouse.Name : '' },
            { "Time Period": 'Weekly' },
            { "Dock": selectedDock.dock_name ? selectedDock.dock_name : '' },
            { "Date": data['custom-date-picker'] ? changeDateToGivenFormat(data['custom-date-picker'], AppConstant.commonStrings.MM_DD_YYYY_dateFormat) : '' }
            ]);

        dispatch({ type: sagaActions.GET_TRUCK_UNLOAD_STATS_LIST, payload: apiParams });
        setLoading(true)
        setShowModal(false);
    };

    const formFieldList = [
        {
            name: "lumping_facility_id",
            type: "select",
            size: "col-sm-6",
            position: 1,
            title:
                AppConstant.reports.truckUnloadSummaryReport.selectLumpingFacility,
            formValidation: {
                required: true,
            },
            getSelectData: getLumpingFacilityList,
        },
        {
            name: "warehouse_id",
            type: "select",
            size: "col-sm-6",
            position: 1,
            title:
                AppConstant.reports.truckUnloadSummaryReport.warehouse,
            formValidation: {
                required: true,
            },
            getSelectData: getWarehousesList,
        },
        {
            name: "dock_id",
            type: "select",
            size: "col-sm-6",
            position: 2,
            title:
                AppConstant.reports.truckUnloadStatisticsReport.selectDock,
            formValidation: {
                required: true,
            },
            getSelectData: getDocksList,
        },
        {
            size: "col-sm-6 mt-1",
            name: "custom-date-picker",
            type: "custom-date-picker",
            customDatePickerConfig: {
                calendarType: "Weekly",
                selectedDate: selectedDate ? selectedDate : AppConstant.commonStrings.MM_DD_YYYY_dateFormat,
                customInput: <TUSCustomInput />,
            },
            position: 2,
            value : selectedDate,
            title:
                AppConstant.reports.truckUnloadStatisticsReport.selectDate,
            formValidation: {
                required: true,
            }
        },
    ]

    const closepopup = () => {
        setEditData({});
        setShowModal(false);
    }

    const submitbtnData = {
        show: true,
        text: AppConstant.reports.generateReportButtonTitle,
    };
    const cancelbtndata = {
        show: true,
        text: AppConstant.comments.resetbutton,
    };

    const createForm = () => {
        return (
            <CustomDynamicForm
                formFieldList={formFieldList}
                sort={true}
                cancelBtn={cancelbtndata}
                submitbtn={submitbtnData}
                themeSelected={themeSelected}
                getSummitedFormData={getSummitedFormData}
                getFormFieldData={getFormFieldData}
                editFormData={editData}
                setParticularFieldValue={particularFieldValue}
                loading={loading}
            />
        );
    };

    const popupObj = {
        id: "createWarehouseFormPopup1",
        modalLabel: "createWarehouseFormPopup1",
        showClose: true,
        showfooter: true,
        showHeader: true,
        sendDriverNotification: false,
        modalSize: "xl",
        title: AppConstant.reports.truckUnloadStatisticsReport.createReport,
        bodyText: createForm(),
        subText: "",
        keyboard: false,
        backdrop: "static",
        footerBtn: {
            btn1: {
                show: true,
                text: AppConstant.comments.cancelCommentButtonTitle,
            },
            btn2: {
                show: true,
                text: AppConstant.reports.generateReportButtonTitle,
            },
        },
    };

    function showPopup() {
        setShowModal(true);
        setApiParams([]);
        setSelectedDate(AppConstant.commonStrings.MM_DD_YYYY_dateFormat);
    }

    return (
        <div className="pt-2">

            <div className='container-fluid d-flex py-2 text-start justify-content-between'>
                <button
                    className={'addButton'}
                    onClick={() => showPopup()}
                >
                    {
                        AppConstant.reports.truckUnloadStatisticsReport.createReport
                    }
                </button>
                <div className='d-flex align-items-center'>
                    <DownloadSection root={''} subRoot={''} name={'Truck Unload Statistics Report'} themeSelected={themeSelected} currentTable={currentTable} currentTableRef={currentTableRef} singlePageDownload={true} />
                </div>
            </div>

            <div className="mt-1">
                <Popup
                    {...popupObj}
                    showModal={showModal}
                    showfooter={false}
                    closepopup={closepopup}
                    themeSelected={themeSelected}
                />
            </div>

            <div ref={divRef} className='truckUnloadStatsTable'>
                {
                    (apiParams && apiParams.length) ?
                        <div>
                            <div className="mx-2 container-fluid text-center graphHeading">{AppConstant.reports.truckUnloadStatisticsReport.title}</div>
                            <ReportsInput apiParams={apiParams} />
                            <TruckUnloadStatisticsList selectedDate={selectedDate} selectedWarehouse={selectedWarehouse} loading={loading} setLoading={setLoading}/>
                        </div>
                        :
                        <div />
                }
            </div>
        </div>
    );

}

export default TruckUnloadStatistics;
