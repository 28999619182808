import React, { useEffect } from 'react';

import DrilldownTableHeaderSS from './DrilldownTableHeaderSS';
import DrilldownTableBodySS from './DrilldownTableBodySS';
import './DrilldownTableSS.css'

const DrilldownTableSS = (props) => {

    const { themeSelected, bodyData, titleData, showCollapse, showInput, showSort, arrangeOrder, arrangeKey, drillDownReport, initiateDrillDownReport, loaderArray, subRoot, sortingAction, showUTCTime, sortKey, hasCollapsableContent, collapsableData, accordionPrimaryIndex, id = 'pdf-content',isPaginated=false } = props;
    return (
        <table className={`table table-responsive table-hover ${themeSelected}`} id={id}>
            <DrilldownTableHeaderSS data={titleData} dataB={bodyData} showInput={showInput} showSort={showSort} themeSelected={themeSelected} showCollapse={showCollapse} arrangeOrder={arrangeOrder} arrangeKey={arrangeKey} drillDownReport={drillDownReport}
                subRoot={subRoot || ''} sortingAction={sortingAction} isPaginated={isPaginated}/>
            <DrilldownTableBodySS data={bodyData} showInput={showInput} showCollapse={showCollapse} themeSelected={themeSelected} initiateDrillDownReport={initiateDrillDownReport} loaderArray={loaderArray} showUTCTime={showUTCTime} sortKey={sortKey} hasCollapsableContent={hasCollapsableContent} collapsableData={collapsableData} accordionPrimaryIndex={accordionPrimaryIndex} />
        </table>
    );
}
export default DrilldownTableSS;