import { isEmpty } from "lodash-es";
import React, { useEffect, useState } from "react";
import _ from 'lodash';
import { AppConstant } from "../../../Assests/AppConstant";
import DownArrow from "../../../Assests/Svg/DownArrow";

import "./CustomDropDown.css";
/**
 * This is a reusable component to render dropdown
 * @param {*} props 
 * themeSelected = selected theme dark/light
 * data = dropdown data
 * placeHolder = dropdown placeholder text
 * onSelect = on dropdown value select handler
 * keyStr = ?
 * disable = if dropdown is disabled
 * hideSelectedItem = Hide dropdown items from list
 * titleStyle = user defined class
 * @returns 
 */
const CustomDropDown = (props) => {
  const { themeSelected, data, placeHolder, onSelect, keyStr, disabled, hideSelectedItem, titleStyle } = props;
  const [item, setItem] = useState("");
  const selectedItem = (selectItem) => {
    if(hideSelectedItem){
      setItem(placeHolder);
    } else {
      setItem(keyStr ? _.get(selectItem, keyStr) : selectItem.dock_name);
    }
    onSelect(selectItem);
    
  };

  useEffect(() => {
    setItem(placeHolder);
  }, []);

  const getDropdownTitle = (el) => {
    if(el && keyStr){
      return _.get(el, keyStr)
    } else {
      return el;
    }
  }

  return (
    <div className="dropdown rounded py-1 px-2 customDropdown" data-testid={AppConstant.dataTestId.commonDropDown}>
      <button
        className="btn border-0 m-0 p-0 w-100"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <div className=" d-flex justify-content-between align-items-center">
          <span className={`dropDownPlaceholder ${themeSelected} me-1 ${titleStyle}`}>{item}</span>

          <DownArrow themeSelected={themeSelected} />
        </div>
      </button>
      <ul
        className={`dropdown-menu ${
          themeSelected === AppConstant.commonStrings.darkModeFilterString &&
          "dropdown-menu-dark"
        }`}
      >
        {data.map((el) => {
          return (
            <li>
              <a className="dropdown-item" onClick={() => selectedItem(el)}  data-testid={"dropdown_link"}>
                {getDropdownTitle(el)}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default CustomDropDown;
