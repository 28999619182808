import React, { useState, useEffect, forwardRef, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sagaActions } from '../../../../../../../sagas/sagaActions';
import _ from 'lodash-es';
import Popup from '../../../../../Common/Popup/Popup';
import { CustomDynamicForm } from '../../../../../Common/CustomForm/CustomDynamicForm';
import { getLumpingFaciltyReducer } from '../../../../../../../redux/reducers/reducerSlices/GetLumpingFaciltyReducer';
import { getUserSelector } from '../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { AppConstant } from '../../../../../../Assests/AppConstant';
import { sortArrByKey } from '../../../../../../Assests/Utility';
import DownloadSection from '../../../DownloadSection/DownloadSection';
import { usePrevious } from '../../../../../Common/CustomHooks/usePrevious';
import { getDockListSelector } from '../../../../../../../redux/reducers/reducerSlices/DockListReducer';
import ReportsInput from '../Components/ReportsInputs';
import ScheduledLoadStatusList from './ListComponent/ScheduledLoadStatusList';
import './ScheduledLoadStatusReport.css';
import DownloadSectionBECustomFeilds from '../../../DownloadSection/DownloadSectionBECustomFeilds';
import { smartScheduleSelector } from '../../../../../../../redux/reducers/reducerSlices/SmartScheduleReducer';

const ScheduledLoadStatusReport = (props) => {

    const dispatch = useDispatch();
    const { themeSelected } = props;
    const [showModal, setShowModal] = useState(false);
    const lumpingFacilityData = useSelector(getLumpingFaciltyReducer);
    const user = useSelector(getUserSelector);
    const { userType, parentWarehouse } = user;
    const [lumpingFacDataArr, setLumpingFacDataArr] = useState([]);
    const [selectedWarehouse, setSelectedWarehouse] = useState('');
    const [selectedLumpingFacility, setSelectedLumpingFacility] = useState('');
    const [selectedDock, setSelectedDock] = useState('');
    const [warehouseListState, setWarehouseListState] = useState([]);
    const [loading, setLoading] = useState(false);
    const divRef = useRef(null);
    const [currentTable, setCurrentTable] = useState(null);
    const [currentTableRef, setCurrentTableRef] = useState(null);
    const previousTable = usePrevious(currentTable);
    const getDockList = useSelector(getDockListSelector);
    const { dockList } = getDockList;
    const [editData, setEditData] = useState({});
    const [dockListArr, setDockListArr] = useState([]);
    const [apiParams, setApiParams] = useState([]);
    const [particularFieldValue, setParticularFieldValue] = useState("");
    const [paylaod,setPayload] = useState(null)
    const ss = useSelector(smartScheduleSelector);
    const { isDownloadLoading } = ss;

    useEffect(() => {
        const container = divRef.current;
        const tables = container.querySelectorAll('table');
        if (tables && tables.length && _.isEqual(currentTable, previousTable)) {
            setCurrentTableRef(container);
            setCurrentTable(tables);
        }
    })

    useEffect(() => {
        dispatch({ type: sagaActions.GET_LUMPING_FACILTY_DATA });
        setWarehouseListState([])
        setLumpingFacDataArr([])
        setDockListArr([])
        setShowModal(true);
        const { userPermission } = AppConstant;
        dispatch({ type: sagaActions.GET_LUMPING_FACILTY_DATA });
        if (userType === userPermission.usertypes.user) {
            setWarehouseListState([parentWarehouse])
            dispatch({ type: sagaActions.DOCK_LIST, payload: { warehouse_id: parentWarehouse.ID } })
            setParticularFieldValue({ warehouse_id: parentWarehouse.ID, lumping_facility_id: parentWarehouse.IDLumpingFacility });
        }
    }, [])

    useEffect(() => {
        if (lumpingFacilityData.data) {
            const { IDLumpingFacility } = parentWarehouse;
            const tempArr = lumpingFacilityData.data.filter(el => el.ID === IDLumpingFacility)
            setLumpingFacDataArr(tempArr);
        }
    }, [lumpingFacilityData.data])

    useEffect(() => {
        if (_.size(dockList)) {
            let dockListtemp = [{dock_id: AppConstant.reports.master_id_all, dock_name: 'ALL'}, ...dockList]
            setDockListArr(dockListtemp)
        }
    }, [dockList])

    const getLumpingFacilityList = () => {
        return (
            lumpingFacDataArr &&
            lumpingFacDataArr.map((data, index) => {
                return (
                    <option key={data.ID} value={data.ID}>
                        {data.Description}
                    </option>
                );
            })
        );
    };

    const getWarehousesList = () => {
        return (
            warehouseListState &&
            warehouseListState.map((data, index) => {
                return (
                    <option key={data.ID} value={data.ID}>
                        {data.Name}
                    </option>
                );
            })
        );
    };

    const getDocksList = () => {
        
        return (
            dockListArr &&
            dockListArr.map((data, index) => {
                return (
                    <option key={data.dock_id} value={data.dock_id}>
                        {data.dock_name}
                    </option>
                );
            })
        );
    };

    const getBillingCodeList = () => {
        const billingCodeArr = ['ALL', 'DLVD', 'FOB']
        return (
            billingCodeArr.map((data, index) => {
                return (
                    <option key={data} value={data}>
                        {data}
                    </option>
                );
            })
        );
    }

    const getFormFieldData = (data, name) => {
        if (name === 'lumping_facility_id') {
            const selectedLumper = (_.find(lumpingFacDataArr, { ID: data }))
            setSelectedLumpingFacility(selectedLumper.Description)
        } else if (name === 'warehouse_id') {
            const selectedWarehouse = (_.find(warehouseListState, { ID: data }))
            setSelectedWarehouse(selectedWarehouse.Name)
        } else if (name === 'dock_id') {
            const selectedDock = (_.find(dockListArr, { dock_id: data }))
            setSelectedDock(selectedDock.dock_name)
        }
    };

    const getSummitedFormData = (data) => {
        
        const apiParams = {
            "IDLumpingFacility": data.lumping_facility_id,
            "IDWarehouse": data.warehouse_id,
            "BillingStatus": data.billing_code_id,
            "IDDock": data.dock_id === AppConstant.reports.master_id_all ? null : data.dock_id,
        }
        
        const selectedLumper = (_.find(lumpingFacDataArr, { ID: data.lumping_facility_id }));
        const selectedWarehouse = (_.find(warehouseListState, { ID: data.warehouse_id }));
        const selectedDock = (_.find(dockListArr, { dock_id: data.dock_id }));
        setPayload(apiParams)
        setApiParams(
            [{ "Lumping Facility ": selectedLumper.Description ? selectedLumper.Description : '' },
            { "Warehouse": selectedWarehouse.Name ? selectedWarehouse.Name : '' },
            { "Dock": selectedDock.dock_name ? selectedDock.dock_name : '' },
            { "Billing Type": data.billing_code_id},
            ]);
        dispatch({ type: sagaActions.GET_SCHEDULED_LOAD_STATUS_LIST, payload: apiParams });
        setShowModal(false);
    };

    const formFieldList = [
        {
            name: "lumping_facility_id",
            type: "select",
            size: "col-sm-6",
            position: 1,
            title:
                AppConstant.reports.truckUnloadSummaryReport.selectLumpingFacility,
            formValidation: {
                required: true,
            },
            getSelectData: getLumpingFacilityList,
        },
        {
            name: "warehouse_id",
            type: "select",
            size: "col-sm-6",
            position: 1,
            title:
                AppConstant.reports.truckUnloadSummaryReport.warehouse,
            formValidation: {
                required: true,
            },
            getSelectData: getWarehousesList,
        },
        {
            name: "dock_id",
            type: "select",
            size: "col-sm-6",
            position: 2,
            title:
                AppConstant.reports.truckUnloadStatisticsReport.selectDock,
            formValidation: {
                required: true,
            },
            getSelectData: getDocksList,
        },
        {
            name: "billing_code_id",
            type: "select",
            size: "col-sm-6",
            position: 2,
            title:
                AppConstant.reports.scheduledLoadStatus.selectBillingCode,
            formValidation: {
                required: true,
            },
            getSelectData: getBillingCodeList,
        },
    ]

    const closepopup = () => {
        setEditData({});
        setShowModal(false);
    }

    const submitbtnData = {
        show: true,
        text: AppConstant.reports.generateReportButtonTitle,
    };
    const cancelbtndata = {
        show: true,
        text: AppConstant.comments.resetbutton,
    };

    const createForm = () => {
        return (
            <CustomDynamicForm
                formFieldList={formFieldList}
                sort={true}
                cancelBtn={cancelbtndata}
                submitbtn={submitbtnData}
                themeSelected={themeSelected}
                getSummitedFormData={getSummitedFormData}
                getFormFieldData={getFormFieldData}
                editFormData={editData}
                loading={loading}
                setParticularFieldValue={particularFieldValue}
            />
        );
    };

    const popupObj = {
        id: "createScheduledLoadStatusFormPopup1",
        modalLabel: "createScheduledLoadStatusFormPopup1",
        showClose: true,
        showfooter: true,
        showHeader: true,
        sendDriverNotification: false,
        modalSize: "xl",
        title: AppConstant.reports.scheduledLoadStatus.createReport,
        bodyText: createForm(),
        subText: "",
        keyboard: false,
        backdrop: "static",
        footerBtn: {
            btn1: {
                show: true,
                text: AppConstant.comments.cancelCommentButtonTitle,
            },
            btn2: {
                show: true,
                text: AppConstant.reports.generateReportButtonTitle,
            },
        },
    };

    function showPopup() {
        setShowModal(true);
        setApiParams([]);
    }

    //Colums List for download column specific report
    const columnList = [
      { value: "BillingCode", name: "Billing Type" },
      {
        value: "ScheduledCarrier",
        name: "Scheduled Carrier",
      },
      {
        value: "Carrier",
        name: "Carrier Name",
      },
      {
        value: "ScheduledTime",
        name: "Scheduled Time",
      },
      {
        value: "CheckInTime",
        name: "Check-In Time",
      },
      { value: "POs", name: "PO's" },
    ];

    /**
     * Function to get payload for data
     * @param {*} type 
     * @returns 
     */
    const getPayloadForReport=(type)=>{
        let payload = {
            subDomain: "scheduledloadstatus",
            downloadType: type,
            options: paylaod
        }
        return payload
    }

        //Call Download Report API
        const downloadReport=(payload)=>{
            dispatch({ type: sagaActions.DOWNLOAD_REPORT_COMMON, payload });
          }
    
    return (
        <div className="pt-2">
            <div className='container-fluid d-flex py-2 text-start justify-content-between'>
                <button
                    className={'addButton'}
                    onClick={() => showPopup()}
                >
                    {
                        AppConstant.reports.scheduledLoadStatus.createReport
                    }
                </button>
                <div className='d-flex align-items-center'>
                <DownloadSectionBECustomFeilds
              downloadData={getPayloadForReport}
              isLoading={isDownloadLoading}
              isDisabled={isDownloadLoading}
              columnList={columnList}
              downloadReport={downloadReport}
            />
                    {/* <DownloadSection singlePageDownload={true} root={''} subRoot={''} name={'Scheduled Load Status Report'} themeSelected={themeSelected} currentTable={currentTable} currentTableRef={currentTableRef} /> */}
                </div>
            </div>
            <div className="mt-1">
                <Popup
                    {...popupObj}
                    showModal={showModal}
                    showfooter={false}
                    closepopup={closepopup}
                    themeSelected={themeSelected}
                />
            </div>

            <div ref={divRef}>
                {
                    (apiParams && apiParams.length) ?
                        <div className='scheduledLoadStatusListContainer mb-6'>
                            <div className="mx-2 container-fluid text-center graphHeading">{AppConstant.reports.scheduledLoadStatus.scheduledLoadStatusTitle}</div>
                            <ReportsInput apiParams={apiParams} />
                            <ScheduledLoadStatusList
                                selectedWarehouse={selectedWarehouse}
                                selectedLumpingFacility={selectedLumpingFacility}
                                selectedDock={selectedDock} />
                        </div>
                        :
                        <div />
                }

            </div>
        </div>
    )
}

export default ScheduledLoadStatusReport;