import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUserSelector } from '../../../../../../redux/reducers/reducerSlices/UserReducer';
import { AppConstant } from '../../../../../Assests/AppConstant';
import { sagaActions } from '../../../../../../sagas/sagaActions';
import { initiateSearch, showAlert, changeFormatWithoutTz } from '../../../../../Assests/Utility';
import { getPoSSselector } from '../../../../../../redux/reducers/reducerSlices/POSmartSchedulerReducer';
import EditIcon from '../../../../../Assests/Images/edit-icon-new.png';
import EnabledIcon from '../../../../../Assests/Images/enablePO.png';
import DisabledIcon from '../../../../../Assests/Images/disablePO.png';
import DisabledPOIcon from '../../../../../Assests/Images/disabledPO.png';
import CustomTooltip from '../../../../Common/Tooltip/CustomTooltip';
import { fetchPoListCommonFunc } from '../Common/FetchPOList';
import { getCurrentDate } from '../../../../../../Utils/DateUtils';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import CompleteIcon from '../../../../../Assests/Images/completed.png'
import ScheduleIcon from '../../../../../Assests/Images/schedule.png'
import RescheduleIcon from '../../../../../Assests/Images/reschedule.png'
import DeletedIcon from '../../../../../Assests/Images/deleted.png'
import WarningIcon from '../../../../../Assests/Images/warning-icon.png'
import _ from 'lodash-es';
import './SsPOList.css';
import DrilldownTableSS from '../../../Components/Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTableSS';
import { CustomPaginationSS } from '../../../../Common/CustomPaginationSS/CustomPaginationSS';
import { getRemoteConfigSelector } from '../../../../../../redux/reducers/reducerSlices/RemoteConfigReducer';
import { useNavigate } from 'react-router-dom';
import { smartScheduleSelector } from '../../../../../../redux/reducers/reducerSlices/SmartScheduleReducer';
import CustomButtonSS from '../../../../Common/CustomButton/CustomButtonSS';
import SSPopup from '../../../../Common/Popup/SSPopup/SSPopup';
 

const SsPOListSS = (props) => {
 
    const { themeSelected, editPOAction, searchStr, filterSelected } = props;
    const dispatch = useDispatch();
    const user = useSelector(getUserSelector);
    const [poArr, setPoArr] = useState([]);
    const { selectedWarehouse, cu_id } = user;
    const [isListLoading, setListLoading] = useState(false);
    const [currentItemIndex, setCurrentItemIndex] = useState('NA');
    const pOSS = useSelector(getPoSSselector);
    const { poSSArr, operationObj, arrangeOrder, arrangeKey,poSSResponse ,isLoading} = pOSS;
    const remoteConfig=useSelector(getRemoteConfigSelector);
    const {allConfigration}=remoteConfig;
    const {PaginationDefaultLimit}=allConfigration
    const navigate=useNavigate();
    const ss = useSelector(smartScheduleSelector);
    const { searchedPOData } = ss;
    const [selectedObj, setSelectedObj] = useState(null)
    const [showDeleteModel, setShowDeleteModel] = useState(false);
 

    const createPoTitleDD = [
        { id: 'PoNumber', name: AppConstant.poManagement.tableHeader.po },
        { id: 'LoadType', name: AppConstant.poManagement.tableHeader.loadType },
        { id: 'DockName', name: AppConstant.smartScheduler.poSection.produceType },
        { id: 'Pallets', name: AppConstant.smartScheduler.poSection.palletsCount },
        { id: 'ItemsCases', name: AppConstant.smartScheduler.poSection.cases },
        { id: 'CarrierName', name: AppConstant.poManagement.tableHeader.carrier },
        { id: 'ApptStartTime', name: AppConstant.superUserAdmin.companyGroup.tableHeader.apptTime },
        { id: 'PoCreatedOn', name: AppConstant.superUserAdmin.companyGroup.tableHeader.createdOn },
        { id: 'UpdatedDate', name: AppConstant.commonStrings.updatedOn},
        { id: 'ApptScheStatus', name: AppConstant.helpSupportFaq.tableHeaders.statusTitle },
        { id: '', name: AppConstant.superUserAdmin.companyGroup.tableHeader.actions },
    ]
 
    /**
     * useEffect to fetch po list on filter change
     */
    useEffect(()=>{
        if (filterSelected && filterSelected.length) {
            fetchPoList(searchStr)
        }
    },[filterSelected])

    useEffect(() => {
        if (operationObj.type) {
            switch (operationObj.type) {
                case 'Update status':
                    if (operationObj.status) {
                        handleApiResponse(AppConstant.notifications.dataAddedSuccess, AppConstant.smartScheduler.poSection.updatePoStatusSuccess);
                    } else {
                        handleApiResponse(AppConstant.commonStrings.failure, AppConstant.smartScheduler.poSection.updatePoStatusFailure + `: ${operationObj.message}`);
                    }
                    break;
                case 'Fetch POs':
                    if (!operationObj.status) {
                        setListLoading(false);
                    }
                    break
                case 'Delete PO':
                    if (operationObj.status) {
                        handleApiResponse(AppConstant.notifications.dataAddedSuccess, AppConstant.smartScheduler.poSection.deletePOSuccess);
                    } else {
                        handleApiResponse(AppConstant.commonStrings.failure, AppConstant.smartScheduler.poSection.deletePOFailure + `: ${operationObj.message}`);
                    }
                    break;
                default:
            }
        }
    }, [operationObj])
 
    const handleApiResponse = (result, message) => {
        if (result === AppConstant.notifications.dataAddedSuccess) {
            showAlert(toast.TYPE.SUCCESS, message, themeSelected);
            fetchPoList()
        } else {
            showAlert(toast.TYPE.ERROR, message, themeSelected);
        }
        dispatch({ type: sagaActions.RESET_SS_PO_OPERATION_OBJ });
    }
 
    useEffect(() => {
 
        setPoArr([]);
        if (poSSResponse && poSSResponse?.data?.length) {
            setListLoading(false);
            setCurrentItemIndex('NA')
            const poTempArr = poSSResponse.data.map((ele) => {
                if (!ele.CarrierName && ele.ApptScheStatus === 'Not Scheduled') {
                    const tempObj = { ...ele, "CarrierName": "-" };
                    return tempObj
                }
                return ele;
            })
            setPoArr(poTempArr);
        } else {
            if (!operationObj.status) {
                setListLoading(false);
            }
        }
    }, [poSSResponse])
 

    useEffect(() => {
        let tout;
        if (searchStr && searchStr.length > 3) {
            //const searchedArr = initiateSearch(poSSResponse.data, searchStr, '');
            tout= setTimeout(()=>{
            fetchPoList(searchStr)
        },[1000])
            //setPoArr(searchedArr);
        } else if(searchStr ==='')  {
        fetchPoList(null)
        }
        return(()=>clearTimeout(tout))
    }, [searchStr])
 
    useEffect(() => {
        dispatch({ type: sagaActions.RESET_PO_LIST });
        setListLoading(true);
        setPoArr([]);
        setTimeout(() => {
            fetchPoList();
        }, [1000])
    }, [])
 
    const fetchPoList = (searchParams=null,pageNo=1,limit=PaginationDefaultLimit,) => {
        const { apiParams, operationObj } = fetchPoListCommonFunc(selectedWarehouse,pageNo,limit,searchParams,filterSelected);
        dispatch({ type: sagaActions.UPDATE_PO_LIST, payload: apiParams, operationObj: operationObj });
    }
 
    const deletePOAction = (obj, index) => {
        setCurrentItemIndex(index)
        let tempObj = {
            "PoNumber": obj.PoNumber,
            "CRUDTYPE": "D",
            "IDPO": obj.IDPO,
            "PoStatus": !obj.PoStatus,
            "CreatedOn": obj.PoCreatedOn,
            "CreatedBy": cu_id,
            "UpdatedOn": changeFormatWithoutTz(getCurrentDate(), AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
            "UpdatedBy": cu_id
        }
        const operationObj = {
            type: 'Update status',
            status: ''
        }
        setListLoading(true);
        dispatch({ type: sagaActions.UPDATE_PO_LIST, payload: { ...obj, ...tempObj }, operationObj: operationObj })
    }
 
    const editPO = (el, index) => {
        editPOAction(el, index);
    }
/**
     * delete popup handler to delete the records based on the confirmation
     * @param {*} text 
     */
const popupBtnHandler = (text) => {
    if (text === AppConstant.commonStrings.no) {
        setShowDeleteModel(false)
        setSelectedObj(null)
        // setSelectedIndex(null)
    }
    else {
        let apiParams = {
            "PoNumber": selectedObj.PoNumber,
            "CRUDTYPE": "R",
            "IDPO": selectedObj.IDPO,
            // "PoStatus": !selectedObj.PoStatus,
            "CreatedOn": selectedObj.PoCreatedOn,
            "CreatedBy": cu_id,
            "UpdatedOn": changeFormatWithoutTz(getCurrentDate(), AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
            "UpdatedBy": cu_id,
            "WarehouseId":selectedObj.IDWarehouse,
            "PoStatus": null,
            "LoadType":null
        }
        
        const operationObj = {
            type: 'Delete PO',
            status: ''
        }
        setListLoading(true);
        dispatch({ type: sagaActions.UPDATE_PO_LIST, payload: apiParams , operationObj: operationObj })
    }
    setShowDeleteModel(false)
}
    /**
     * Helper to show the delete conformation pop-up
     * @returns 
     */
    const showDeleteConfirmation = () => {
        const popupObj = {
            id: 'showDeleteConfirmation',
            modalLabel: 'showDeleteConfirmation',
            showClose: true,
            showfooter: true,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title: AppConstant.poManagement.PO.poDelete,
            bodyText: `${AppConstant.commonStrings.areYouSure} ${AppConstant.poManagement.PO.poDelete} ${selectedObj?.PoNumber}`,
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.commonStrings.yes
                },
                btn2: {
                    show: true,
                    text: AppConstant.commonStrings.no
                }
            },
        }

        return <SSPopup {...popupObj} popupBtnHandler={(text) => { popupBtnHandler(text) }} closepopup={() => { setShowDeleteModel(false) }} themeSelected={themeSelected} showModal={showDeleteModel} />
    }

    /**
     * Helper to delete
     * @param {*} data 
     * @param {*} index 
     */
    const deleteHandler = (data, index) => {
        if (data.ApptScheStatus === 'Not Scheduled' || data.ApptScheStatus === 'Date Expired') {
            setSelectedObj(data, index)
            // setSelectedIndex(index)
            setShowDeleteModel(true)
        } else {
            return
        }
    }

    const actionButtons = (el, index) => {
        const dueDate = moment(el.DueDate).utc().endOf('day');
        const todayDate = moment().utc().startOf('day')
        dueDate.add(1, 'days');
        return (
            // <div className='d-flex justify-content-center my-2'>
            <div>
            <span className='d-flex justify-content-center align-items-center gap-2' >
                <img src={EditIcon}
                    className={`editIconPO-ss $${el.ApptScheStatus === 'Not Scheduled' ||  el.ApptScheStatus === 'Date Expired' && todayDate.isBefore(dueDate)? '' : 'disabled edit-disable'}`}
                    alt="Edit Po"
                    data-tip={el.ApptScheStatus === 'Not Scheduled' ||  el.ApptScheStatus === 'Date Expired' && todayDate.isBefore(dueDate) ? 'Edit PO' : ''}
                    data-for={'tooltip_id'}
                    onClick={el.ApptScheStatus === 'Not Scheduled' || el.ApptScheStatus === 'Date Expired' && todayDate.isBefore(dueDate) ? () => editPO(el, index) : () => ''} />
                <img
                    src={el.ApptScheStatus !== 'Not Scheduled' ?
                        DisabledPOIcon : el.PoStatus ? EnabledIcon : DisabledIcon}
                    data-tip={el.ApptScheStatus !== 'Not Scheduled' ? '' : el.PoStatus ? 'Enabled' : 'Disabled'}
                    data-for={'tooltip_id'}
                    className={`editIconPO-ss ${el.ApptScheStatus === 'Not Scheduled' ?
                        '' : 'disabled'}`} alt="Change Status"
                        onClick={el.ApptScheStatus === 'Not Scheduled' ? () => deletePOAction(el, index) : () => ''} />
            
                    <CustomButtonSS
                        disabled={el.ApptScheStatus === 'Not Scheduled' ||  el.ApptScheStatus === 'Date Expired' ? false : true}
                        className={el.ApptScheStatus === 'Not Scheduled' ||  el.ApptScheStatus === 'Date Expired' ? `no-border no-bg`: `no-border no-bg disabled`}
                        onClick={() => { deleteHandler(el, index) }}
                        isIcon={true}
                        iconClass={"fa-regular fa-trash-can fa-lg"}
                />
                <CustomTooltip
                    id={'tooltip_id'}
                    position='top'
                    type={themeSelected === AppConstant.commonStrings.lightModeFilterString ? 'light' : 'dark'}
                    multiline={false} />
                </span>
            </div>
        )
    }

    /**
     * Function to show alert on click of date expired
     */
    const showDateExpiredAlert=()=>{
        showAlert(toast.TYPE.WARNING, AppConstant.poManagement.dateExpired)
    }
 
    /**
     * Function to render appointment status
     * @param {*} el 
     * @returns 
     */
    const getApptSchStatusColor = (el) => {
        const dueDate = moment(el.DueDate).utc().startOf('day');
        const todayDate = moment().utc().startOf('day')
       if (el.ApptScheStatus === 'Completed') {
            return <div className='appt-status-block-ss disabled'><img src={CompleteIcon} alt=' ' /> {el.ApptScheStatus}</div>
        } else if (el.ApptScheStatus === 'Scheduled' && el.Rescheduled) {
            return <div className={`${!el.PoStatus && 'disabled'} appt-status-block-ss`} onClick={()=>goToSchedulerPage(el)}><img src={RescheduleIcon} alt=' ' /> {'Rescheduled'}</div>
       }
         else if (el.ApptScheStatus === 'Scheduled') {
            return <div className={`${!el.PoStatus && 'disabled'} appt-status-block-ss`} onClick={()=>goToSchedulerPage(el)}><img src={ScheduleIcon} alt=' ' /> {el.ApptScheStatus}</div>
       }
       else if (el.ApptScheStatus === 'Date Expired') {
          return <div className={`${!el.PoStatus && 'disabled'} appt-status-block-ss`} onClick={showDateExpiredAlert}><img src={WarningIcon} alt=' ' height={15} width={15}/> { AppConstant.poManagement.statusFilters.dateExpired}</div>
       }
        //    else if (new Date(moment(el.DueDate).utc().format(AppConstant.commonStrings.MM_DD_YYYY__HH_mm_ss)).getTime() < new Date().getTime()) {
        //       el.PoNumber === '70001' && console.log('----------', moment(el.DueDate),  moment())
        //         return <div className={`${!el.PoStatus && 'disabled'} appt-status-block-ss`} onClick={showDateExpiredAlert}><img src={WarningIcon} alt=' ' height={15} width={15}/> { AppConstant.poManagement.statusFilters.dateExpired}</div>
        //     }
         else {
            return <div className={`${!el.PoStatus && 'disabled'} appt-status-block-ss`} onClick={()=>goToSchedulerPage(el)}><img src={DeletedIcon} alt=' ' /> {el.ApptScheStatus}</div>
        }
    }
 
    const formatListDataDrillDown = (data) => {
        return data.map((el, index) => {
            return {
                id: el.RoleID,
                // 0: ((poSSResponse.pageNo-1)*poSSResponse.limit+(index + 1)),
                0: el.PoNumber || '-',
                1: el.LoadType || '-',
                2: el.DockName || '-',
                3: el.Pallets || '-',
                4: el.ItemsCases || '-',
                5: el.CarrierName,
                6: el?.ApptStartTime ? `${moment(el.ApptStartTime).utc().format(AppConstant.commonStrings.MM_DD_YYYY_dateFormat)}\n${moment(el.ApptStartTime).utc().format(AppConstant.commonStrings.HH_mm_dateFormat)}` || '-' : '-',
                7: `${moment(el.PoCreatedOn).utc().format(AppConstant.commonStrings.MM_DD_YYYY_dateFormat)}` || '-',
                8: el.UpdatedDate?moment(el.UpdatedDate).utc().format(AppConstant.commonStrings.MM_DD_YYYY_dateFormat) : '-',
                9: el.ApptScheStatus ? getApptSchStatusColor(el) : '-',
                10: actionButtons(el, index)
            }
        })
    }
 
    const getPaginationArr = () => {
        return [1, 2, 3, 4, 5].map((item, index) => {
            return <li className="page-item"><a className="page-link text-dark" href="#">{item}</a></li>
        })
    }
    const pageChange=(pageNo,limit=Number(PaginationDefaultLimit))=>{
        fetchPoList(searchStr,pageNo,limit)
    }

    /**
     * Function to update po data for sechedule/reschedule and navigate to book appointment page
     * @param {*} rowData 
     */
    const goToSchedulerPage = (rowData) => {
            if (rowData.PoStatus) {
                dispatch({ type: sagaActions.INTIATE_SS_SEARCH_PO, payload: { PoNumber: rowData?.PoNumber, ReceivingZipCode: rowData?.ReceivingZipCode, UserType: 'USER', ID: rowData?.IDWarehouse } })
                if (searchedPOData) {
                    dispatch({ type: sagaActions.UPDATE_SS_SEARCH_PO, payload: [rowData] })
                    navigate('/bookappointments');
            }else{
                showAlert(toast.TYPE.ERROR, AppConstant.notifications.login.wentWrong)
            }
            }   
    }     

 
    return (
        <div className=''>
            {
                <div className='mt-3 appointmentListTable'>
                    <DrilldownTableSS
                        themeSelected={themeSelected}
                        bodyData={poArr && poArr.length ? formatListDataDrillDown(poArr):[]}
                        titleData={createPoTitleDD}
                        showCollapse={false}
                        showInput={false}
                        showSort={true}
                        sortKey={true}
                        arrangeOrder={arrangeOrder}
                        arrangeKey={arrangeKey}
                        drillDownReport={poArr}
                        initiateDrillDownReport={isLoading}
                        loaderArray={11}
                        sortingAction={sagaActions.SORT_SS_USER_TABLE}
                        subRoot={{ pathName: 'poSSResponse' }}
                        showUTCTime={true}
                        isPaginated={true}
                    />
               
               {showDeleteConfirmation()}
 

                    {/* PAGINATION UI COMPONENT <div className='sticky-bottom mt-auto justify-content-end pb-3 bg-transparent'>
                        <nav className='mx-5 paginationSection bg-transparent'>
                            <ul className="pagination justify-content-end w-100 bg-transparent">
                                <li className="page-item bg-transparent">
                                    <a className="page-link text-dark" href="#">
                                        <span aria-hidden="true">&laquo;</span>
                                    </a>
                                </li>
                                {getPaginationArr()}
                                <li className="page-item">
                                    <a className="page-link text-dark" href="#">
                                        <span aria-hidden="true">&raquo;</span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div> */}
                </div>
            }
            {poSSResponse && poSSResponse.data && poSSResponse.data.length ?
                    <CustomPaginationSS
                    pageChange={(pageNo,limit)=>{pageChange(pageNo,limit)}}
                    pageNo={poSSResponse.pageNo}
                    pageSize={poSSResponse.limit}
                    totalElements={poSSResponse.totalElements}
                    totalPages={Math.ceil(poSSResponse.totalElements/poSSResponse.limit)}
                    last={poSSResponse.last}
                    first={poSSResponse.first}
                    data={poSSResponse.data}
                    isLoading={isLoading}
                />:""
            }
        </div>
    )
 
}
export default SsPOListSS;