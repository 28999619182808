import _ from 'lodash';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { AppConstant } from '../../../../../../Assests/AppConstant';
import { sagaActions } from '../../../../../../../sagas/sagaActions';
import { getTruckSelector } from '../../../../../../../redux/reducers/reducerSlices/getTruckReducer';

import './TruckListTitle.css';

const TruckListTitle = (props) => {

    const dispatch = useDispatch();
    const {themeSelected} = props;
    const { tableHeader } = AppConstant.poManagement;
    const getTruck = useSelector(getTruckSelector);
    const { truckList, arrangeOrder, arrangeKey } = getTruck;
    // const [order, setOrder] = useState(AppConstant.commonStrings.arrange);
    const [checkAll, setCheckAll] = useState(false);

    const titleClickHandler = e => {
        const getID = e.target.id ? e.target.id : e.target.closest('th').id
        let arrange = {
            key: getID
        }
        if (getID !== 'checkAll') {
            if (arrangeOrder === 'asc') {
                arrange.order = 'desc'
            } else {
                arrange.order = 'asc'
            }
            
            const newdata = _.orderBy(truckList, [arrange.key], [arrange.order]);
            // const data = {
            //     truckList: newdata,
            //     getListLoading

            // }
            dispatch({ type: sagaActions.SORT_TRUCK_LIST, payload: arrange })
            dispatch({ type: sagaActions.UPDATE_TRUCK, payload: newdata })
        }
    }

    const checkAllHandler = (e) => {
        e.stopPropagation();
        setCheckAll(!checkAll);
        let updatePOList = []
        if (checkAll) {
            updatePOList = truckList.map(el => {
                let newEl = { ...el };
                newEl.checked = false;
                return newEl
            })
        } else {
            updatePOList = truckList.map(el => {
                let newEl = { ...el };
                newEl.checked = true;
                return newEl;
            })
            }
        // const data = {
        //         truckList: updatePOList,
        //         getListLoading

        //     }
            dispatch({ type: sagaActions.UPDATE_TRUCK, payload: updatePOList })
        }

        return (
            <thead>
                <tr className={`text-center align-middle ${themeSelected} truck-list-title`} onClick={titleClickHandler}>
                    {/* Asked by Rajesh to comment */}
                    {/* <th className={`col-md-1 px-0 table-title-checkBox ${themeSelected}`} id="checkAll"> */}
                        {/* This was commented */}
                        {/* <div className="input-group mx-4"> */}
                        {/* <input type="checkbox" onChange={checkAllHandler} value={checkAll} data-testid={AppConstant.dataTestId.tableCheckAll} /> */}
                        {/* This was commented */}
                        {/* <div className='table-title-checkBox'>
                            <input type="checkbox" />
                        </div> */}
                        {/* </div> */}
                    {/* </th> */}
                    <th scope="col" className={`col-md-1 px-0 ${themeSelected}`}></th>
                    <th scope="col" className={`col-md-1 px-0 ${themeSelected}`} id='po'>
                        <span>{tableHeader.po}</span>
                        <i className="fa fa-fw fa-sort"></i>
                    </th>
                    <th scope="col" className={`col-md-2 px-0 ${themeSelected}`} id='appointment_time'>
                        <span>{tableHeader.appointment_time}</span>
                        <i className="fa fa-fw fa-sort"></i>
                    </th>
                    <th scope="col" className={`col-md-2 px-0 ${themeSelected}`} id='carrier'>
                        <span>{tableHeader.carrier}</span>
                        <i className="fa fa-fw fa-sort"></i>
                    </th>
                    <th scope="col" className={`col-md-2 px-0 ${themeSelected}`} id='check_in_time'>
                        <span>{tableHeader.check_in_time}</span>
                        <i className="fa fa-fw fa-sort"></i>
                    </th>
                    <th scope="col" className={`col-md-2 px-0 ${themeSelected}`} id='dock'>
                        <span>{tableHeader.dock}</span>
                        <i className="fa fa-fw fa-sort"></i>
                    </th>
                    <th scope="col" className={`col-md-1 px-0 ${themeSelected}`} id='door'>
                        <span>{tableHeader.door}</span>
                        <i className="fa fa-fw fa-sort"></i>
                    </th>
                </tr>
            </thead>
        )
    }

    export default TruckListTitle;