import {Uchain} from "../redux/api/agent";
import { call, put, takeEvery } from 'redux-saga/effects';
import { sagaActions } from './sagaActions';

import { addSendEmailForAppointmentSuccess, addSendEmailForAppointmentFailure,resetSendEmailForAppointmentData,initiateLoading } from '../redux/reducers/reducerSlices/sendEmailForAppointment';




function* addWSendMailRequestHandler(data){ 
  try{
   
    yield put(initiateLoading());
   
    const response = yield call(Uchain.sendEmailForAppointment, data.payload);
   yield put(addSendEmailForAppointmentSuccess(response));
  }catch(error){
   
   yield put(addSendEmailForAppointmentFailure(error));
  }
}


export function* addSendMailForAppointRequest() {
  yield takeEvery(sagaActions.SEND_MAIL_FOR_APPOINTMENT, addWSendMailRequestHandler)
}


function* resetSendMailForAppointHandler() {
  yield put(resetSendEmailForAppointmentData())
  
 }
 export function* resetSendMailForAppointRequest() {
  yield takeEvery(sagaActions.RESET_SEND_MAIL_FOR_APPOINTMENT, resetSendMailForAppointHandler)
}
