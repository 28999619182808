import React, { useEffect, useState } from 'react'
import { smartScheduleSelector } from '../../../../../../redux/reducers/reducerSlices/SmartScheduleReducer';
import { useSelector } from 'react-redux';
import { getUserSelector } from '../../../../../../redux/reducers/reducerSlices/UserReducer';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import _ from "lodash"
import DrilldownTable from '../../../Components/Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTable';
import { sagaActions } from '../../../../../../sagas/sagaActions';
import CustomButton from '../../../../Common/CustomButton/CustomButton';
import CustomButtonSS from '../../../../Common/CustomButton/CustomButtonSS';
import { CustomDynamicForm } from '../../../../Common/CustomForm/CustomDynamicForm';
import { AppConstant } from '../../../../../Assests/AppConstant';
import { customStylesLight, showAlert } from '../../../../../Assests/Utility';
import Popup from '../../../../Common/Popup/Popup';
import { toast } from 'react-toastify';
import { CustomDynamicFormSS } from '../../../../Common/CustomForm/CustomDynamicFormSS';
import { CheckInSelector } from '../../../../../../redux/reducers/reducerSlices/CheckInReducer';

const CheckInApptsListScreen = (props) => {

    const { data, themeSelected = 'light-mode', screenTypeClassname } = props;
  
    const [showSendMailModal, setShowSendEmailModal] = useState(false);
    const [selectedPoObj, setSelectedPoObj] = useState({});
    const ss = useSelector(smartScheduleSelector);
    const { searchedPOData,carrierList } = ss;
    const user = useSelector(getUserSelector);
    const { userType } = user;
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [selectedData,setSelectedData]=useState();
    const [resetFormData, setResetFormData] = useState(0);
    const [editData, setEditData] = useState({});
    const [showModal,setShowModal]=useState(false);
    const [showAddPoModal,setShowAddPoModal]=useState(false);
    const [showAddPoConfirmModal,setShowAddPoConfirmModal]=useState(false);
    const [showDeleteModal,setShowDeleteModal]=useState(false);
    const [showQusModal,setShowQusModal]=useState(false);
    const [selectedCarrier, setSelectedCarrier] = useState(null)
    const [carrierListState, setCarrierListState] = useState([]);
    const checkin=useSelector(CheckInSelector)
const {checkInLoad,isLoading,checkInError , checkInResponse,initiateLoading} = checkin;

    const closepopup = () => {
       setEditData({});
       setShowModal(false);
     }

     useEffect(()=>{
        if (checkInResponse) {
            closeQuspopup()
        }
     },[checkInResponse])

    
    useEffect(()=>{
        if(data.loads){
            let updatedList=data.loads[0]?.pos.map((el)=>{
                return {...el,po_status:"Approved"}
            })
            setSelectedData(updatedList);   
        }
        
    },[data])

    const cancelbtndata = {
        show: true,
        text: AppConstant.comments.resetbutton,
    };

    const submitbtnData = {
        show: true,
        text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm
            .saveButtonTitle,
    };

    const submitbtnDataForQus = {
        show: true,
        text: AppConstant.commonStrings.submit,
    };

    const formFieldList = [
        {
            name: "vendor",
            type: "text",
            size: "col-sm-12",
            position: 1,
            title: AppConstant.poManagement.searchPOSection.vendorTitleStr,
            formValidation: {
                required: false,
            },
        },
        {
            name: "items",
            type: "text",
            size: "col-sm-12",
            position: 2,
            title: AppConstant.loadManagement.purchaseOrderDetails.items,
            formValidation: {
                required: false,
            },
        },
        {
            name: "cases",
            type: "text",
            size: "col-sm-12",
            position: 3,
            title: AppConstant.loadManagement.purchaseOrderDetails.cases,
            formValidation: {
                required: false,
            },
        },
    ]

    const addPoFormFieldList = [
        {
            name: "po_number",
            type: "text",
            size: "col-sm-12",
            position: 1,
            title: AppConstant.smartScheduler.poSection.poNumTitle,
            formValidation: {
                required: false,
            },
        },
        {
            name: "vendor",
            type: "text",
            size: "col-sm-12",
            position: 1,
            title: AppConstant.poManagement.searchPOSection.vendorTitleStr,
            formValidation: {
                required: false,
            },
        },
        {
            name: "items",
            type: "text",
            size: "col-sm-12",
            position: 2,
            title: AppConstant.loadManagement.purchaseOrderDetails.items,
            formValidation: {
                required: false,
            },
        },
        {
            name: "cases",
            type: "text",
            size: "col-sm-12",
            position: 3,
            title: AppConstant.loadManagement.purchaseOrderDetails.cases,
            formValidation: {
                required: false,
            },
        },
    ]


    const onCarrierSelcted = (props) => {
        if (props.length > 2) {
            dispatch({ type: sagaActions.SS_GET_CARRIER_LIST, payload: { carrier: props } })
        }
    }

    const onChangeValue = (props) => {
        setSelectedCarrier(props)
    }
    const formatCreateLabel = (props) => {
    }
    useEffect(() => {
        if (_.size(carrierList)) {
            formCarrierListData()
        }
    }, [carrierList])

    const formCarrierListData = () => {
        if (_.size(carrierList)) {
            const carrierData = carrierList.map(el => {
                return {
                    value: el.carrier_name,
                    label: el.carrier_name
                }
            })
            setCarrierListState([...carrierData])
        } else {
            setCarrierListState([])
        }
    }

    const qusFormFieldList = [
        {
            name: "dropped",
            type: "radio",
            size: "col-sm-12 mt-2 radioButtonText",
            position: 0,
            title: 'Is this Droped trailer ?',

            formValidation: {
                required: true,
            },
            radiconfig: [{
                label: "Yes",
                value: true
            },
            {
                label: "No",
                value: false
            }]

        },
        {
            name: "other_drops",
            type: "radio",
            size: "col-sm-12 mt-2 radioButtonText",
            position: 0,
            title: 'Do you have other stops after this one ?',

            formValidation: {
                required: true,
            },
            radiconfig: [{
                label: "Yes",
                value: true
            },
            {
                label: "No",
                value: false
            }]

        },
        {
            name: "contact_number",
            type: "text",
            size: "col-md-12",
            position: 1,
            title:"Driver Phone Number",
            formValidation: {
                required: true,
                validateContactNum: true,
            },
            placeholder: AppConstant.user.popup.createUser.phone_placeHolder

        },
        {
            name: "first_name",
            type: "text",
            size: "col-sm-6",
            position: 1,
            title: "First Name",
            formValidation: {
                required: true,
            },
        },
        {
            name: "last_name",
            type: "text",
            size: "col-sm-6",
            position: 1,
            title: "Last Name",
            formValidation: {
                required: true,
            },
        },
        {
            name: "load_type",
            type: "radio",
            size: "col-sm-12 mt-2 radioButtonText",
            position: 0,
            title: 'Select one of the following',

            formValidation: {
                required: true,
            },
            radiconfig: [{
                label: "Box Truck",
                value: "BOXTRUCK"
            },
            {
                label: "Trailer/Tractor",
                value: "TRAILERTRACTOR"
            }]

        },
        {
            name: "carrier_name",
            type: "select_with_input_search_add_button",
            size: "col-sm-12",
            parentClass: 'inputTitle',
            componentClassName: 'custom-select',
            inputTitle: 'What is your Carrier Name?',
            position: 1,
            title: AppConstant.reports.scheduledLoadStatus.tableHeaders.carrierName,
            titleIcon: true,
            titleIconClass: 'fa-solid fa-bag-shopping info-label-icon',
            customStylesLight: customStylesLight,
            placeholderText: 'Enter or Add Carrier Name Here',
            onInputChange: onCarrierSelcted,
            onChange: onChangeValue,
            defaultValue: !_.isEmpty(selectedCarrier) ? selectedCarrier : 'Select a carrier from the list',
            isLoading: false,
            isClearable: true,
            isSearchable: true,
            options: carrierListState,
            formatCreateLabel: formatCreateLabel,
            loadingMessage: 'Please wait loading....',
            formValidation: {
                required: true,
            },
        },
        {
            name: "trailer",
            type: "text",
            size: "col-sm-6",
            position: 1,
            title: "Enter Trailer Number",
            formValidation: {
                required: true,
            },
        },
        {
            name: "tractor",
            type: "text",
            size: "col-sm-6",
            position: 1,
            title: "Enter Tractor Number ",
            formValidation: {
                required: true,
            },
        },
       
       
    ]

    const getSummitedFormData = (data) => {
            if (data.po_number) {
                let updatedArray=selectedData.map((el)=>{
                    if (el.po_number===data.po_number) {
                        return {...data}
                    }
                    return el;
                })
                setSelectedData(updatedArray)
                closepopup()
            }
            else{
                showAlert(toast.TYPE.ERROR, "Unable to update!", 'light')
            }
    };
  


    const createForm = () => {
        return (
            <CustomDynamicForm
                formFieldList={formFieldList}
                sort={true}
                cancelBtn={cancelbtndata}
                submitbtn={submitbtnData}
                themeSelected={themeSelected}
                getSummitedFormData={getSummitedFormData}
                loading={isLoading}
                editFormData={editData}
                resetFormData={resetFormData}
            />
        );
    };

    const showEditPopup = () => {
        const popupObj = {
            id: 'showEditPopup',
            modalLabel: 'showEditPopup',
            showClose: true,
            showfooter: false,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title: "Edit",
            bodyText: createForm(),
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.comments.cancelCommentButtonTitle
                },
                btn2: {
                    show: true,
                    text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.saveButtonTitle
                }
            },
        }

        return <Popup {...popupObj} popupBtnHandler={(text) => () => ''} closepopup={closepopup} themeSelected={themeSelected}  showModal={showModal}/>
    }

    const getSummitedFormAddPoData = (data) => {
        if (data) {
            let newPo={...data}
            newPo.po_status="Pending"
            setSelectedData((prev)=>[...selectedData,newPo])
            closeAddPopopup()
        }
        else{
            showAlert(toast.TYPE.ERROR, "Unable to Add!", 'light')
        }
};

const closeAddPopopup=()=>{
    setShowAddPoModal(false)
}

    const createAddPoForm = () => {
        return (
            <CustomDynamicForm
                formFieldList={addPoFormFieldList}
                sort={true}
                cancelBtn={cancelbtndata}
                submitbtn={submitbtnData}
                themeSelected={themeSelected}
                getSummitedFormData={getSummitedFormAddPoData}
                loading={isLoading}
                editFormData={editData}
                resetFormData={resetFormData}
            />
        );
    };

    const showAddPoPopup = () => {
        const popupObj = {
            id: 'showAddPoPopup',
            modalLabel: 'showAddPoPopup',
            showClose: true,
            showfooter: false,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title: "Add PO",
            bodyText: createAddPoForm(),
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.comments.cancelCommentButtonTitle
                },
                btn2: {
                    show: true,
                    text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.saveButtonTitle
                }
            },
        }

        return <Popup {...popupObj} popupBtnHandler={(text) => () => ''} closepopup={closeAddPopopup} themeSelected={themeSelected}  showModal={showAddPoModal}/>
    }

    const showAddPoConfirmPopup = () => {
        const popupObj = {
            id: 'showAddPoConfirmPopup',
            modalLabel: 'showAddPoConfirmPopup',
            showClose: true,
            showfooter: true,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title: "Add PO",
            bodyText: `Do you have additional Purchase Order Number(s) to add to this appointment?`,
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.commonStrings.yes
                },
                btn2: {
                    show: true,
                    text: AppConstant.commonStrings.no
                }
            },
        }

        return <Popup {...popupObj} popupBtnHandler={(text) => {confirmAddPoBtnClickHandler(text)}} closepopup={closeAddPoConfirmPopup} themeSelected={themeSelected}  showModal={showAddPoConfirmModal}/>
    }

    const confirmAddPoBtnClickHandler=(text)=>{
        if (text===AppConstant.commonStrings.no) {
            openQusPopup()
        }
        else{
            setShowAddPoModal(true)
        }
        closeAddPoConfirmPopup()
    }

    const closeAddPoConfirmPopup=()=>{
        setShowAddPoConfirmModal(false)
    }

    const closeDeletepopup=()=>{
        setShowDeleteModal(false)
    }

    const btnClickHandler = (text)=>{
        if (text===AppConstant.commonStrings.no) {
            setSelectedPoObj(null)
            setShowDeleteModal(false)
        }
        else{
                let updatedArray=selectedData?.map((el)=>{
                    if (el.po_number===selectedPoObj.po_number) {
                        return {...el,po_status:"Deleted"}
                    }
                    return el
                });
            setSelectedData(updatedArray)
            setShowDeleteModal(false)
        }
    }

    const showDeletePopup = () => {
        const popupObj = {
            id: 'showDeletePopup',
            modalLabel: 'showDeletePopup',
            showClose: true,
            showfooter: true,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title: "Delete",
            bodyText: AppConstant.commonStrings.areYouSure + " delete Po: "+selectedPoObj.po_number,
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.commonStrings.no
                },
                btn2: {
                    show: true,
                    text: AppConstant.commonStrings.yes
                }
            },
        }

        return <Popup {...popupObj} popupBtnHandler={(text) => {btnClickHandler(text)}} closepopup={closeDeletepopup} themeSelected={themeSelected}  showModal={showDeleteModal}/>
    }

    const openQusPopup=()=>{
        setShowQusModal(true)
    }

    const createQusForm=()=>{
        return (
            <CustomDynamicForm
                formFieldList={qusFormFieldList}
                sort={true}
                cancelBtn={cancelbtndata}
                submitbtn={submitbtnDataForQus}
                themeSelected={themeSelected}
                getSummitedFormData={getSummitedFormDataForQus}
                loading={isLoading}
                editFormData={editData}
                resetFormData={resetFormData}
            />
        );
    }

    const getSummitedFormDataForQus = (params) => {
        let apiParams={...params}
        apiParams.dropped=Boolean(params.dropped);
        apiParams.other_drops=Boolean(params.other_drops);
        apiParams.load_id= data.loads[0]?.load_id;
        apiParams.warehouse_name= data.loads[0]?.warehouse_name;
        apiParams.warehouse_id= data.warehouse_id;
        apiParams.load_control_num= data.loads[0]?.load_control_num;
        apiParams.dock_name= data.loads[0]?.dock_name;
        apiParams.scheduled_time= data.loads[0]?.scheduled_time;
        apiParams.scheduled_time_utc= data.loads[0]?.scheduled_time_utc;
        apiParams.status= data.loads[0]?.status;
        apiParams.po_count=data.loads[0].pos.length;
        apiParams.isSplitLoad= data.loads[0].isSplitLoad;
        apiParams.driver_phone=params.contact_number.replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '').replaceAll('-', '');
        let polist=selectedData.map((el)=>{
            let element=JSON.parse(JSON.stringify(el))
            element.po_status=el.po_status||"Approved";
            element.vendor=el.vendor?el.vendor:"";
            return element
        })
        apiParams.pos=polist;
        apiParams.driver_unload= data.loads[0].driver_unload;
        apiParams.payment_contact= "self";
        apiParams.backhaul= false;
        apiParams.other_drops= false;
        apiParams.dispatch_phone= "";
        apiParams.precheck= false;
        if (apiParams) {
        dispatch({type: sagaActions.ADD_CHECKIN, payload:[apiParams]})
    } else {
        showAlert(toast.TYPE.ERROR, "something went wrong", 'light')
    }

};

    const showQusPopup = () => {
        const popupObj = {
            id: 'showQusPopup',
            modalLabel: 'showQusPopup',
            showClose: true,
            showfooter: false,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title: "Load and Equipment Details",
            bodyText: createQusForm(),
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.commonStrings.no
                },
                btn2: {
                    show: true,
                    text: AppConstant.commonStrings.yes
                }
            },
        }

        return <Popup {...popupObj} popupBtnHandler={(text) => {}} closepopup={closeQuspopup} themeSelected={themeSelected}  showModal={showQusModal}/>
    }

    const closeQuspopup=()=>{
        setEditData({})
        setShowQusModal(false)
    }

    const openDeleteModel=(data)=>{
        setSelectedPoObj(data)
        setShowDeleteModal(true)
    }

    const apptsTitleDD = [
        { id: 'po_number', name: 'Po' },
        { id: 'Vendor', name: 'Vendor' },
        { id: 'Items', name: 'Items' },
        { id: 'Cases', name: 'Cases' },
        { id: 'Status', name: 'Status' },
        { id: 'Actions', name: 'Actions' }
    ]
 

    const formatListDataDrillDown = (dataList) => {

        return dataList.map((el, index) => {
            return {
                id: el.id,
                // 0: index + 1,
                0: el.po_number || "--",
                1: el.vendor || "--",
                2: el.items || "--",
                3: el.cases || "--",
                4: el.po_status || "Approved",
                5: (data?.loads[0]?.check_in_time || el.po_status==="Deleted")?"--":renderActionBtn(el)
            }
        })
    }

    const editHandler=(data)=>{
        setEditData(data)
        setShowModal(true)
    }

    const renderActionBtn=(data)=>{
        return (
          <div className="d-flex gap-2 justify-content-center">
            <CustomButtonSS
              disabled={false}
              isLoading={false}
              className={`carrier-po-btn-small`}
              onClick={() => {editHandler(data)}}
              isIcon={true}
              iconClass={"fa-solid fa-pen-to-square"}
            />
            {selectedData.length>1 && 
            <CustomButtonSS
              disabled={false}
              isLoading={false}
              className={`carrier-po-btn-small color-black`}
              onClick={() => {openDeleteModel(data)}}
              isIcon={true}
              iconClass={"fa-regular fa-trash-can"}
            />
            }
          </div>
        );
    }


    const renderApptTableRows = () => {
        if (data) {
            return (
                <div className='p-2 f-14 fw-500'>
                    <p className='my-2'><span className='fw-800'>Appointment Time:</span> {data.loads[0].scheduled_time}</p>
                    <p className='my-2'><span className='fw-800'>Location:</span> {data.loads[0].warehouse_name}</p>
                    <p className='my-2'><span className='fw-800'>Dock:</span> {data.loads[0].dock_name}</p>
                    {data.loads[0].check_in_time &&
                    <>
                   
                    <p className='my-2'><span className='fw-800'>Check-In Time:</span> {data.loads[0].check_in_time}</p>
                    </>
                }
                </div>
            )
        }}

 
    const showAppointmentsList = () => {
        return (
            <div className={screenTypeClassname} >
                <div className="row m-2" style={{minHeight:"40vh"}}>
                    <div className="col-3 boxShadow-1 px-0 mx-3">
                        <table>
                            <thead />
                            {props.callReset===false && renderApptTableRows()}
                        </table>
                    </div>
                    <div className="col-8 mx-3 boxShadow-1 d-flex flex-column justify-content-between">
                   {props.callReset===false && selectedData&&
                     <DrilldownTable
                     themeSelected={themeSelected}
                     bodyData={formatListDataDrillDown(selectedData)}
                     titleData={apptsTitleDD}
                     showCollapse={false}
                     showInput={false}
                     showSort={true}
                     hasCollapsableContent={false}
                     collapsableData={[]}
                     accordionPrimaryIndex={1}
                     arrangeOrder={null}
                     arrangeKey={null}
                     drillDownReport={selectedData}
                     initiateDrillDownReport={false}
                     loaderArray={4}
                     sortingAction={sagaActions.SORT_SS_USER_TABLE}
                     subRoot={null}
                     showUTCTime={false}
                 />
                   }
                   {!data.loads[0].check_in_time &&
                   <div className='d-flex justify-content-end w-100 align-items-end mb-3'>
                   <CustomButton
                            isLoading={false}
                            title={'Check In'}
                            disabled={data.loads[0].check_in_time?true:false}
                            className={data.loads[0].check_in_time?"btn btn-approve disabled":"btn btn-approve"}
                            onClick={()=>{setShowAddPoConfirmModal(true)}}
                        />
                   </div>
                   }
                    </div>
                </div>
            </div>

        )
    }


    const showNoDataError = () => {
        return (

            <div className='d-flex flex-column align-items-center m-4'>
                <div>

                <div className='bolderText text-danger'>{'We did not find this PO in the system. Please verify the number, or try a different PO.'}</div>
                <div > 
                <span className='bolderText'>Possible Causes: </span>
                    <ul className='subText'>
                        <li>PO number entered is not correct</li>
                        <li>PO is not on the schedule for today</li>
                        <li>PO is too early or a noshow from a previous day</li>
                        <li>If happening frequently (over half of attempts) please contact support 1(844) 586-7729</li>
                    </ul>
                </div>
                </div>
                </div>
        )
    }

    return (
        <div>

            <div className='' />
            { data.loads && data.loads.length ?
                <div>
                    {props.callReset===false ?  <div className='d-flex justify-content-between fs-6'>
                        <div className=''>Great News! We found the PO: </div>
                      
                    </div>: <div  className="mt-5 d-flex justify-content-center">
                        <span style={{color:'green'}} ><span className='d-flex justify-content-center'>Arrival Completed Successfully.</span><span className='d-flex justify-content-center'>Instruct driver to proceed to <span  className='fw-bold mx-2'> Staging Area </span> &  <span  className='fw-bold mx-2'> Check-in </span> using the FreightSmith Mobile App.The driver will receive a text.</span></span> 
                      
                    </div>}
                    <div className='seperator' />

                    {showAppointmentsList()}
                </div>
                :
                <div>{showNoDataError()}</div>
            }
            {showEditPopup()}
            {showDeletePopup()}
            {showQusPopup()}
            {showAddPoPopup()}
            {showAddPoConfirmPopup()}
        </div>
    )
}

export default CheckInApptsListScreen