import _ from 'lodash';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDefaultDashboardSelector } from '../../../../../../../../../redux/reducers/reducerSlices/DefaultDashboardReducer';
import { getUserSelector } from '../../../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { sagaActions } from '../../../../../../../../../sagas/sagaActions';
import { AppConstant } from '../../../../../../../../Assests/AppConstant';
import DrilldownTable from '../../Common/DrilldownTable';

const DrillDownDoorStatus = (props) => {
    const { themeSelected, loaderArray, subRoot, dockIdArr } = props;
    const dispatch = useDispatch();
    const [drilldownData, setDrilldownData] = useState([]);
    const defaultDashboard = useSelector(getDefaultDashboardSelector);
    const { doorStatusDrillDown, arrangeOrder, arrangeKey, initiateDrillDownReport, customDashboardDockSelectedList } = defaultDashboard;
    const [doorStatusSelectedDockState, setDoorStatusSelectedDockState] = useState(null)
    const userPermission = useSelector(getUserSelector);
    const {selectedWarehouse} = userPermission;

    let  unloadingTypeSummaryTitleDD = [
        { id: 'Door', name: 'Door' },
        { id: 'DoorType', name: 'Door Type' },
        { id: 'DoorStatus', name: 'Status' },
        { id: 'DockName', name: 'Dock' },
    ]
    const createPayload = () => {
        return {dock_id: !_.isEmpty(doorStatusSelectedDockState) ? doorStatusSelectedDockState?.dock_id : null, isDrillDown: '1', warehouse_id: selectedWarehouse.ID, drillDownType: subRoot?.subPathName}
    }
    useEffect(() => {
        dispatch({type: sagaActions.CLEAR_DOOR_STATUS_DRILLDOWN})
        setDrilldownData([])
        dispatch({ type: sagaActions.INITIATE_DRILLDOWN_REPORT, payload: true })
        if (_.size(customDashboardDockSelectedList)) {
            const filterDoorStatus = _.filter(customDashboardDockSelectedList, { name: 'Door Status' })
            if (_.size(filterDoorStatus)) {
                setDoorStatusSelectedDockState(filterDoorStatus[0])
            }            
        }
       
    }, [])
    
    useEffect(() => {
        if (!_.isEmpty(doorStatusSelectedDockState)) {
            dispatch({ type: sagaActions.GET_DOOR_STATUS_DRILLDOWN, payload: createPayload() });
        }
    },[doorStatusSelectedDockState])


    useEffect(() => {
        if (doorStatusDrillDown && doorStatusDrillDown.length) {
            let data = formatDrillDownData(doorStatusDrillDown)
            setDrilldownData(data)
            dispatch({ type: sagaActions.INITIATE_DRILLDOWN_REPORT, payload: false})
        } else {
            setDrilldownData([])
        }
    }, [doorStatusDrillDown])

    const formatDrillDownData = (data) => {
        return data.map(el => {
            return {
                id: `${el.Door}_${el.DoorType}`,
                0: el.Door || '-',
                1: el.DoorType || '-',
                2: el.DoorStatus || '-',
                3: el.DockName || '-',
            }
        })
    }

    return (
        <DrilldownTable showUTCTime={true} themeSelected={themeSelected} bodyData={drilldownData} titleData={ unloadingTypeSummaryTitleDD} showCollapse={false} showInput={true} showSort={true} arrangeOrder={arrangeOrder} arrangeKey={arrangeKey} drillDownReport={doorStatusDrillDown} initiateDrillDownReport={initiateDrillDownReport} loaderArray={loaderArray} subRoot={subRoot} sortingAction={sagaActions.SORT_DRILLDOWN_REPORT} />
    );
}

export default DrillDownDoorStatus;