import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './CommonAlerts.css';
// Reusable component to render alert toast notification 
// 3rd party library used - react-toastify
const AlertNotification = (props) => {
    const { theme } = props;

    return (<>
        <ToastContainer
            theme={theme}
        />
    </>
    )
}

export default AlertNotification;