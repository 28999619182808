import React, { useEffect, useState } from "react";
import './CustomPaginationSS.css'
import { useSelector } from "react-redux";
import { getRemoteConfigSelector } from "../../../../redux/reducers/reducerSlices/RemoteConfigReducer";
import { AppConstant } from "../../../Assests/AppConstant";
/**
 * This is reusable pagination component
 * @param {*} param
 *  pageNo = Current Page number
 *  pageSize = page size
 *  totalElements = Total elements on page
 *  totalPages = Total page number
 *  last = if last page
 *  first = if first page
 *  pageChange = page change handler
 *  isLoading=false
 * @returns
 */
export const CustomPaginationSS = ({
  pageNo,
  pageSize,
  totalElements,
  totalPages,
  last,
  first,
  pageChange,
  isLoading = false,
  paginationLimitArray,
  previousText,
  nextText,
  showTotalCount=true,
  showFirstPageBtn=true,
  showLastPageBtn=true
}) => {
  const remoteConfig=useSelector(getRemoteConfigSelector);
  const {allConfigration}=remoteConfig  

  const [list, setList] = useState([]);
  const [nPages, setNPages] = useState(null);
  const [pagerange, setPageRange] = useState({
    start: "",
    end: "",
  });
  const [pageslist, setPagesList] = useState([]);
  const [paginationLimitList, setPaginationLimitList] = useState([])

  /**
   * useEffect for pageNo, pageSize, totalElements
   */
  useEffect(() => {
    const lastIndex = pageNo * pageSize;
    const firstIndex = lastIndex - pageSize;
    const noPages = Math.ceil(totalElements / pageSize);
    setNPages(noPages);
    // if pagination limit array is passed as prop from component
    if (paginationLimitArray && paginationLimitArray.length) {
      setPaginationLimitList([...paginationLimitArray])
    } else {
      // if pagination pagination limit array is coming from DB
      const data =  JSON.parse(allConfigration?.PaginationLimitList)
     setPaginationLimitList([...data])
    }
  }, [pageNo, pageSize, totalElements, paginationLimitArray]);
  /**
   * function to calculate start and last page
   * @param {*} pagesCutCount
   * @param {*} nPages
   * @returns
   */
  const getPagesCut = (pagesCutCount, nPages) => {
    const ceiling = Math.ceil(pagesCutCount / 2);
    const floor = Math.floor(pagesCutCount / 2);

    if (nPages < pagesCutCount) {
      return { start: 1, end: nPages + 1 };
    } else if (pageNo >= 1 && pageNo <= ceiling) {
      return { start: 1, end: pagesCutCount + 1 };
    } else if (pageNo + floor >= nPages) {
      return { start: nPages - pagesCutCount + 1, end: nPages + 1 };
    } else {
      return { start: pageNo - ceiling + 1, end: pageNo + floor + 1 };
    }
  };
  /**
   * function to calculate range for displaying pages
   * @param {*} start
   * @param {*} end
   * @returns
   */
  const range = (start, end) => {
    return [...Array(end - start).keys()].map((el) => el + start);
  };
  /**
   * useEffect for nPages,pageNo
   */
  useEffect(() => {
    let pagesCut = null;
    if (nPages) {
      pagesCut = getPagesCut(5, nPages);
      setPageRange(pagesCut);
    }
  }, [nPages, pageNo]);
  /**
   * useEffect for page range
   */
  useEffect(() => {
    if (pagerange) {
      const pages = range(pagerange.start, pagerange.end);
      setPagesList(pages);
    }
  }, [pagerange]);
  /**
   * useEffect to render page box based on range
   */
  useEffect(() => {
    let items = [];
    for (let num = 0; num < pageslist.length; num++) {
      items.push(
        <li
          key={num}
          className={
            pageNo === pageslist[num] ? "page-active page-item" : "page-item"
          }
          onClick={() => pageChange(pageslist[num], pageSize)}
        >
          <a>{pageslist[num]}</a>
        </li>
      );
    }
    setList(items);
  }, [pageNo, pageSize, totalElements, totalPages, last, first, pageslist]);
  /**
   *
   * @returns "eg - 1 of 10"
   */
  const renderTotalCount = () => {
    if (last) {
      return totalElements + " of " + totalElements
    } else {
      return pageNo * pageSize + " of " + totalElements
    }
  }
  return (
    <>
      <div className="p-2 my-3" data-testid={AppConstant.dataTestId.customPagination}>
        <div className="d-flex justify-content-between flex-wrap">
          <div>
            <span className={!showTotalCount ? "no-visible" :""}>Total Count: </span>
            <span className={!showTotalCount ? "no-visible" :""}>{renderTotalCount()}</span>
          </div>
          <div className="page-left gap-3 d-flex align-items-center">
            <div className="d-flex gap-3">
              {showFirstPageBtn &&
              <button
                type="button"
                title="First"
                className="no-border no-bg pagination-btn-ss"
                disabled={first === true}
                onClick={() => pageChange(1, pageSize)}
              >
                <i className="fa-solid fa-angles-left"></i>
              </button>
              }
              <button
                type="button"
                title="Previous"
                className={`no-border no-bg ${previousText ? "pagination-btn-ss-text" : "pagination-btn-ss"}`}
                disabled={first === true}
                onClick={() => pageChange(pageNo - 1, pageSize)}
              >
                <i className="fa-solid fa-chevron-left"></i> {previousText && previousText}
              </button>
            </div>
            <ul className="page-list d-flex justify-content-between">{list}</ul>
            <div className="d-flex gap-3">
              <button
                type="button"
                title="Next"
                className={`no-border no-bg ${nextText ? "pagination-btn-ss-text" : "pagination-btn-ss"}`}
                disabled={last === true}
                onClick={() => pageChange(pageNo + 1, pageSize)}
              >
                {nextText && nextText} <i className="fa-solid fa-chevron-right"></i>
              </button>
              {showLastPageBtn &&
              <button
                type="button"
                title="Last"
                className="no-border no-bg pagination-btn-ss"
                disabled={last === true}
                onClick={() => pageChange(totalPages, pageSize)}
              >
                 <i className="fa-solid fa-angles-right"></i>
              </button>
              }
            </div>
          </div>
          <div className="page-right d-flex">
            {/* <span>
              Page{" "}
              {pageNo+
                " of " +
                totalPages}
            </span> */}
            <select className={`${isLoading && 'disabled'} pagesize-dropdown`} value={pageSize}
              onChange={(e) => {
                pageChange(1, (Number(e.target.value)));
              }}
              disabled={isLoading}>
              {paginationLimitList.map((el) => {
                return (
                  <option value={Number(el)}>{el} records per page</option>
                )
              })}

            </select>
          </div>
        </div>
      </div>
    </>
  );
};