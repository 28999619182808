import React, { useEffect, useState } from 'react'
import MultiSelectOption from './Components/MultiSelectOption';
import './MultiSelectDropDownNew.css'

/**
 * Reusable component for multi select dropdown
 * @param {*} props 
 * @returns 
 */
const MultiSelectDropDownNew = (props) => {
    const {options,placeholder,id,filterSelected,setFilterSelected} = props;
    const [showDD,setShowDD] = useState(false)
    const [checkStatus,setCheckStatus] = useState([]);

    /**
     * useEffect to form check status data on change filters selected
     */
    useEffect(()=>{
        formCheckStatus()
    },[filterSelected])

    /**
     * function to toggle dropdown
     */
    const toggleDD=()=>{
        setShowDD(!showDD)
    }

    /**
     * Function to check is already selected
     * @param {*} list 
     * @param {*} ele 
     * @returns 
     */
    const isAlreadySelected=(list,ele)=>{
        let filteredData=list?.filter((data)=>data===ele)
        return filteredData.length ? true :false
    }

    /**
     * Fucntion to select filter
     * @param {*} data 
     */
    const onSelected=(data) => {
       if (data==='All') {
        let allFilters= []
        if (isAlreadySelected(filterSelected,data)) {
            allFilters.push(placeholder)
        }else{
            options?.forEach((el)=>{
                allFilters.push(el.name);
            })
        }      
        setFilterSelected(allFilters)
       }else{
        if (isAlreadySelected(filterSelected,data)) {
            let newFilters=filterSelected?.filter((el)=>el!==data && el!=='All')
            setFilterSelected(newFilters)
        }else{
            let newFilters=filterSelected?.filter((el)=>el!=='All')
            if (newFilters.length === options.length-2) {
                let fullFilters=[]
                 options?.forEach((el)=>{
                    fullFilters.push(el.name);
                })
                setFilterSelected(fullFilters)
            }else{
            setFilterSelected([...newFilters,data])
            }
        }
    }
    }

    /**
     * Function to render filter selected text
     * @returns 
     */
    const displayFilterSelected=()=>{        
        let text=""
        if (filterSelected && filterSelected.length) {
            if (!filterSelected.includes("All")) {
                filterSelected.forEach((el)=>{
                    if (el) {
                        text=text+el+", "
                    }
            })
            }else{
                text='All, '
            }
         }
        else{
            text=placeholder
            setFilterSelected([placeholder])
        }    
        text=text.slice(0,-2)
        if (text.length>25) {
            return text.slice(0,25)+"..."
        }
       return text 
    }

    /**
     * Function to form check status data
     */
    const formCheckStatus=()=>{
        let status=[];
        options.forEach((el)=>{
          
                let list=filterSelected.filter((filter)=>filter===el.name)
                if (list && list.length===0) {
                    status.push(false)
                }else{
                    status.push(true)
                }
            
           
        })
        setCheckStatus(status);
    }

  return (
    <div className='multi-select-dropdown' id={id} onClick={toggleDD}>
    {displayFilterSelected()}
    <span className='arrow-container-dd'>{showDD?<i className="fa-solid fa-angle-up"></i>:<i class="fa-solid fa-angle-down"></i>}</span>
    <div  className='multi-select-trigger' >
        {showDD &&
        <div className='multi-select-dd'>
        {options.map((el,index)=>{
            return(
               <MultiSelectOption
               data={el}
               isSelected={checkStatus[index]}
               onSelected={onSelected}
               /> 
            )
        })}
        </div>}
        </div>
    </div>
  )
}

export default MultiSelectDropDownNew