import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AppConstant } from '../../../../../../../../Assests/AppConstant';
import NoData from '../../../DrillDownReports/Common/Nodata';
import CommonChart from '../../../Graphs/CommonChart';

const UnscheduledPosWidget = (props) => {
    const { unScheduledPOdonut, themeSelected } = props;
    const [showUnschuedledPO, setshowUnschuedledPO] = useState(false)

    useEffect(() => {
        if (unScheduledPOdonut) {
            const sum = unScheduledPOdonut?.series.reduce((a, b) => {
                return a + b
            }, 0)
            if (sum > 0) {
                setshowUnschuedledPO(true)
            } else {
                setshowUnschuedledPO(false)
            }
        }
    }, [unScheduledPOdonut])

    return (
        <div className={`col-12 me-2 ${themeSelected} bg-transparent`} style={{ marginRight: '10px' }}>
            <Link to="/analytics/reports/unscheduledpo" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                <div className='col-12 pt-3'>
                    <span className='graphHeading'>{AppConstant.dashboard.defaultdashboard.unscheduledPos.heading}</span><br />
                </div>
                <div className='d-flex flex-row justify-content-around pt-5'>
                    <div className="responsive-chart">
                        {
                            showUnschuedledPO && unScheduledPOdonut ? <CommonChart data={unScheduledPOdonut} /> : <NoData themeSelected={themeSelected}/>
                        }
                        </div>
                </div>
            </Link>
        </div>
    );
}

export default UnscheduledPosWidget;