import React from 'react';
import { AppConstant } from '../../../Assests/AppConstant';
import './RememberMeSS.css'

const RememberMeSS = (props) => {
    const { onChangeText, isChecked } = props
    return (

        <p>
            <input data-testid={AppConstant.dataTestId.rememberMeCheckBox} onChange={(e) => onChangeText(e)}
                className='checkBox-ss check-box-input' defaultChecked={isChecked} type="checkbox" aria-label="Checkbox for following text input"  autoFocus={false}/>
            <span className="btn_title" style={{ fontSize: "15px" }}>
                {AppConstant.login.rememberMe}
            </span>
        </p>
    )
}

export default RememberMeSS
