import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sagaActions } from '../../../../../../sagas/sagaActions';
import SelectedDockStatusFilters from './Components/SelectedDockStatusFiltersSection/SelectedDockStatusFilters';
import { getStatusFilterListSelector, getDockFilterListSelector, getSelectedFiltersSelector } from '../../../../../../redux/reducers/reducerSlices/FiltersReducerSlice';
import POFilterHeader from './Components/POFilterHeader/POFilterHeader';
import PODockFilterSection from './Components/PODockFilterSection/PODockFilterSection';
import { getTruckSelector } from '../../../../../../redux/reducers/reducerSlices/getTruckReducer';
import { AppConstant } from '../../../../../Assests/AppConstant';
import './PODockStatusFilter.css';
import { createDockFilterList, createStatusFilterList } from '../FilterUtilities';
import { Link, useLocation } from 'react-router-dom';
import { getDefaultDashboardSelector } from '../../../../../../redux/reducers/reducerSlices/DefaultDashboardReducer';
import CustomButton from '../../../../Common/CustomButton/CustomButton';
import { getSelectedDateSelector } from '../../../../../../redux/reducers/reducerSlices/DateSelectorReducer';
import { getFormattedDate, YYYY_MM_DD_FORMAT } from '../../../../../../Utils/DateUtils';
import { getUserSelector } from '../../../../../../redux/reducers/reducerSlices/UserReducer';

const PODockStatusFilter = (props) => {
  const dispatch = useDispatch();
  const location = useLocation()
  let dropDown1 = null;
  const appConstantsPath = AppConstant.poManagement.poFiltersSection
  const { themeSelected, showOnlyDockFilters, showDashboardBtn, allowPermissionD, allowPermissionC } = props;
  const [totalPo, setTotalPO] = useState();
  const getTruck = useSelector(getTruckSelector);
  const { truckListMasterData, getListLoading } = getTruck;
  const statusFiltersList = useSelector(getStatusFilterListSelector);
  const dockFiltersList = useSelector(getDockFilterListSelector);
  const selectedFilterList = useSelector(getSelectedFiltersSelector);
  const [filterHeading, setFilterHeading] = useState(AppConstant.dashboard.defaultdashboard.heading)
  const defaultDashboard = useSelector(getDefaultDashboardSelector);
  const { dashboardType } = defaultDashboard;
  const [showCount, setshowCount] = useState(false);
  const getSelectedDateSelectorObj = useSelector(getSelectedDateSelector);
  const { selectedDate } = getSelectedDateSelectorObj;
  const userPermission = useSelector(getUserSelector);
  const { selectedWarehouse } = userPermission;

  useEffect(() => {
    if (location.pathname === '/loadmanagement') {
      setshowCount(true)
      setFilterHeading(appConstantsPath.purchaseOrdersTitleString)
    } else if (location.pathname === '/analytics' && dashboardType === 'Default') {
      setshowCount(false)
      setFilterHeading(AppConstant.dashboard.defaultdashboard.heading)
    } else if (location.pathname === '/user') {
      setshowCount(true)
      setFilterHeading(AppConstant.user.heading)
    }

  }, [location.pathname])

  useEffect(() => {
    createDockStatusFilterList();
  }, [])

  useEffect(() => {
    createDockStatusFilterList();
  }, [truckListMasterData]);

  useEffect(() => {
    //handle drop-down
    dropDown1 = document.getElementById(appConstantsPath.filtersDropDownId);

    dropDown1 && dropDown1.addEventListener(appConstantsPath.showDropDownEventKey, event => {
      dispatch({ type: sagaActions.SET_FILTERS_EXPANDED, payload: AppConstant.commonStrings.true });
    })

    dropDown1 && dropDown1.addEventListener(appConstantsPath.hideDropDownEventKey, event => {
      dispatch({ type: sagaActions.SET_FILTERS_EXPANDED, payload: AppConstant.commonStrings.true });
    })

  })

  const createDockStatusFilterList = () => {
    setTotalPO(truckListMasterData.length);
    // const dockFiltersList = [`All Docks ${totalPo}`, ];
    const dockFiltersList = createDockFilterList(truckListMasterData, location.pathname);
    dispatch({ type: sagaActions.SET_DOCK_FILTERS_LIST, payload: [...dockFiltersList] });

    const statusFilterArr = createStatusFilterList(truckListMasterData);
    dispatch({ type: sagaActions.SET_STATUS_FILTERS_LIST, payload: statusFilterArr });
  }

  const toggleAccordion = () => {
    const isExpanded = (JSON.stringify(document.getElementById(appConstantsPath.accordionButtonId).getAttribute('aria-expanded')));
    dispatch({ type: sagaActions.SET_FILTERS_EXPANDED, payload: isExpanded });
  }

  const clearAllFilters = () => {
    dispatch({ type: sagaActions.RESET_FILTERS, payload: '' });
    toggleAccordion();
    if (location.pathname = "/analytics") {
      dispatch({
        type: sagaActions.GET_UNLOADING_HOURS, payload: {
          "date": getFormattedDate(YYYY_MM_DD_FORMAT, selectedDate), "dock_id": null, "warehouse_id": selectedWarehouse.ID,
          "isDrillDown": "0"
        }
      });
      dispatch({
        type: sagaActions.GET_DETENSTION_STATUS, payload: { "date": getFormattedDate(YYYY_MM_DD_FORMAT, selectedDate), "dock_id": null, "warehouse_id": selectedWarehouse.ID, "isDrillDown": "0" }
      })
      dispatch({
        type: sagaActions.GET_APPOINTMENT_COMPLIANCE,
        payload: { "date": getFormattedDate(YYYY_MM_DD_FORMAT, selectedDate), "dock_id": null, "warehouse_id": selectedWarehouse.ID, "isDrillDown": "0" }
      })

      dispatch({
        type: sagaActions.GET_DASHBOARD_STATUS_TRUCK_LIST, payload: {  "date": getFormattedDate(YYYY_MM_DD_FORMAT, selectedDate), "dock_id": null, "warehouse_id": selectedWarehouse.ID, "isDrillDown": "0" }
      })
    }
  }

  const toggleLoadsData = (loadStr) => {
    if (loadStr !== dashboardType) {
      dispatch({
        type: sagaActions.UPDATE_SELECTED_BUTTON, payload: {
          key: 'dashboardType',
          loadStr: loadStr
        }
      })
      createBtn()
      // dispatch({type: sagaActions.INITIATE_DRILLDOWN_REPORT, payload: true})
      // hitAPI(loadStr)}
    }
  }

  const createBtn = () => {
    return <>
      <CustomButton
        disabled={allowPermissionD ? '' : 'disabled'}
        loadingTitle={AppConstant.dashboard.default}
        isLoading={''}
        title={AppConstant.dashboard.default}
        className={`buttonFunnelDash ${dashboardType === 'Default' ? 'selectedButton' : 'unselectedButton'} ${allowPermissionD ? '' : 'disabled'} mx-2`}
        onClick={() => toggleLoadsData('Default')} />
      <CustomButton
        disabled={allowPermissionC ? '' : 'disabled'}
        loadingTitle={AppConstant.dashboard.custom}
        isLoading={''}
        title={AppConstant.dashboard.custom}
        className={`buttonFunnelDash ${dashboardType === 'Custom' ? 'selectedButton' : 'unselectedButton'} ${allowPermissionC ? '' : 'disabled'} mx-2`}
        onClick={() => toggleLoadsData('Custom')} />

    </>
  }

  return (
    <div className='pODockStatusFilter py-1'>
      {dockFiltersList && dockFiltersList.length > 0 ? (

        <div className={`${themeSelected} m-0 p-0`}>
          <div className="dropdown container-fluid p-0 h-auto d-flex justify-content-between align-items-center"
            id={appConstantsPath.filtersDropDownId} >
            <button className={getListLoading ? 'btn border-0 w-auto m-0 p-0 disabled' : 'btn border-0 w-auto m-0 p-0'}
              type="button" data-bs-toggle="dropdown"
              aria-expanded="false"
              data-bs-auto-close="outside" id={appConstantsPath.accordionButtonId}>
              <span className='d-flex justify-content-start poFilterHeader pt-2'>
                <POFilterHeader showCount={showCount} onClickHandler={() => toggleAccordion()} totalPo={totalPo} themeSelected={themeSelected} showFilters={true} heading={filterHeading} />
                {selectedFilterList.length > 0 ? <div className="d-flex align-items-start fs-6 ms-3 mt-1 clearFilters" onClick={() => clearAllFilters()} >{AppConstant.poManagement.poFiltersSection.clearFilters}</div> : ''}
              </span>
            </button>

            <div className={`dropdown-menu dropdown-menu-dark border-0 w-100 bg-transparent ${themeSelected} po-dock-status-filters`}>
              <PODockFilterSection className='dropdown-item col'
                title={appConstantsPath.chooseDockTitleString}
                poFilterList={dockFiltersList} themeSelected={themeSelected} />
              {
                showOnlyDockFilters ?
                  ''
                  : <PODockFilterSection className='dropdown-item col'
                    title={appConstantsPath.chooseStatusTitleString}
                    poFilterList={statusFiltersList} themeSelected={themeSelected} />
              }
            </div>
            {showDashboardBtn ? <div className='dashboard-toggle-btn'>
              {createBtn()}
            </div> : ''}
          </div>
          <div>
            <SelectedDockStatusFilters themeSelected={themeSelected} />
          </div>
        </div>
      )
        :
        <div className={`${themeSelected} m-0 mb-3 p-0`}>
          <div className='d-flex container-fluid justify-content-between'>
            <div className="btn border-0 w-auto m-0 p-0 ">
              <POFilterHeader showCount={showCount} totalPo={0} themeSelected={themeSelected} showFilters={false} heading={filterHeading} />
            </div>
            {showDashboardBtn ? <div className='dashboard-toggle-btn'>
              {createBtn()}
            </div> : ''}
          </div>
          <div>
            <SelectedDockStatusFilters themeSelected={themeSelected} />
          </div>
        </div>
      }
    </div>
  )
}

export default PODockStatusFilter;