import { configureStore as reduxToolKitStore } from '@reduxjs/toolkit'

import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import rootSagas from '../../sagas/rootSaga';
import rootReducer from '../reducers/rootReducer'


const configureStore = () => {

    const persistConfig = {
        key: 'uchainRoot',
        storage,
       // whitelist:['otpAuthentication']
    }

    const persistedReducer = persistReducer(persistConfig, rootReducer);
    
    // Create sagas middleware
    const sagaMiddleware = createSagaMiddleware();
    const middleware = [sagaMiddleware];

    let store = reduxToolKitStore({reducer : persistedReducer,
        middleware: () => ([...middleware]),
        // devTools: process.env.NODE_ENV !== 'production',
    });

    let persistor = persistStore(store);

    // Running sagas
    sagaMiddleware.run(rootSagas);

    return { store, persistor };
}



export default configureStore;