import { Uchain } from "../redux/api/agent";
import { call, put, takeEvery } from 'redux-saga/effects';
import { sagaActions } from './sagaActions';
import { getScheduleUploadFailure, getScheduleUploadSuccess, initiateLoading, updateTableData } from "../redux/reducers/reducerSlices/ScheduleUploadReducer";

/**
 * Method to hit schedule upload status API and send data to reducer
 * @param {*} data 
 */
function* getScheduleUploadStatus(data) {
    try {
        yield put(initiateLoading());

        const response = yield call(Uchain.getScheduleUploadDetails, data.payload);
        yield put(getScheduleUploadSuccess(response));
    } catch (error) {

        yield put(getScheduleUploadFailure(error));
    }
}

/**
 * Method is used to sort table data
 * @param {*} data 
 */
function* sortScheduleUploadDataSaga(data) {
    try {
        yield put(updateTableData(data.payload))
    } catch (error) {
    }
}

/**
 * Method is used to dispatch GET_SCHEDULE_UPLOAD_STATUS action
 */
export function* getScheduleUpload() {
    yield takeEvery(sagaActions.GET_SCHEDULE_UPLOAD_STATUS, getScheduleUploadStatus)
}

/**
 * Method is used to sort table data
 */
export function* sortScheduleUploadData() {
    yield takeEvery(sagaActions.SORT_SCHEDULE_UPLOAD_DATA, sortScheduleUploadDataSaga)
}

