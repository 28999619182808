import React from 'react'
import { BORDER_HEIGHT } from '../helpers/constansts';
import { differenceInMinutes,setHours } from 'date-fns';

function Break({
 data,
 minuteHeight,
 top,
 step,
 showScheduleModal,
 setShowScheduleModal
}) {
  const height = differenceInMinutes(data.end, data.start) * minuteHeight - BORDER_HEIGHT;
  const slots = height / step;
  const heightBorderFactor = slots * BORDER_HEIGHT;

 
  
  return (
  <>
            <div
                className={" rs__event__item custom__event__ss rs___break pointer"}
                style={{
                    position:"absolute",
                  height: height + heightBorderFactor,
                  top:`${top}px`,
                left:"100%",
                  width:`${705+heightBorderFactor}%`,
                // zIndex: "2"
                }}
              onClick={()=>{setShowScheduleModal(true)}}
              >
                <span className='break__typo'>
        {data.Description}
        </span>
        </div>
    </>
  )
}

export default Break