import React, { useEffect, useState } from 'react'
import DatePickerSS from '../../../../../../DatePickerSS/DatePickerSS';
import './RecommendationSS.css'
import CustomButtonSS from '../../../../../../Common/CustomButton/CustomButtonSS';
import { useDispatch } from 'react-redux';
import { smartScheduleSelector } from '../../../../../../../../redux/reducers/reducerSlices/SmartScheduleReducer';
import { useSelector } from 'react-redux';
import { getUserSelector } from '../../../../../../../../redux/reducers/reducerSlices/UserReducer';
import moment from 'moment';
import { AppConstant } from '../../../../../../../Assests/AppConstant';
import _ from 'lodash';
import { customStylesLight, formParticularFieldValue, isRescheduleCase } from '../../../../../../../Assests/Utility';
import { sagaActions } from '../../../../../../../../sagas/sagaActions';
import RecommendedTimeslotSS from './RecommendedTimeslotSS';
import Loader from '../../../../../../Loader/Loader';
import { CustomDynamicFormSS } from '../../../../../../Common/CustomForm/CustomDynamicFormSS';

export const RecommendationSS = (props) => {
    const { isCarrier, themeSelected,handleOptionChangeParent,rescheduleReason=null } = props;
    const dispatch = useDispatch();
    const [selectedDate, setSelectedDate] = useState(null);
    const [requestedTime, setRequestedTime] = useState(null);
    const [minDate, setMinDate] = useState(null);
    const [maxDate, setMaxDate] = useState(null);
    const [timeSlot, setTimeSlot] = useState(null)
    const ssReducer = useSelector(smartScheduleSelector);
    const { ssRecommendationsArr, poForAppt, appointmentList, intiateAppointmentLoading, carrierList, appointmentConfirmation, dateValidationResponse, appointmentDocumentsList } = ssReducer;
    const [recommendationsArr, setRecommendationsArr] = useState([]);
    const user = useSelector(getUserSelector);
    const { cu_id, userAccountDetails, addCarrierSpro, userType } = user;
    const [loading, setLoading] = useState(false);
    const [SelectReccSlot, setSelectReccSlot] = useState({});
    const [timezone, setTimezone] = useState('')
    const [errorMsg, setErrorMsg] = useState(null);
    const [carrierListState, setCarrierListState] = useState([]);
    const [selectedCarrier, setSelectedCarrier] = useState(null);
    const [particularFieldValue, setParticularFieldValue] = useState("");
    const [timeZoneText, setTimeZoneText] = useState('')
    const todayDate = new Date();
    const [nextStep, setNextStep] = useState(false)
    const [resetFormData, setResetFormData] = useState(0);
    const [searchableSelectFormData, setSearchableSelectFormData] = useState(null)

    // const formTime = (dateStr) => {
    //     // Combine the date and time strings
    //     const combinedDateTimeStr = `${dateStr}`;
    //     const masterPO = poForAppt[0];
    //     // Parse the string into a moment object in MST timezone
    //     const originalMoment = moment.tz(combinedDateTimeStr, AppConstant.commonStrings.MM_DD_YYYY__HH_mm_ss, masterPO?.WarehouseData?.TimeZone);
    //     // Create a JavaScript Date object from the moment object
    //     // const newDate = momentObj.toDate();
    //     return originalMoment.utc();
    // }

    useEffect(() => {
        // setLoading(true)
        setSearchableSelectFormData(null)
        setParticularFieldValue({})
        setSelectedCarrier(null)
        if (!_.isEmpty(addCarrierSpro)) {
            dispatch({type: sagaActions.ADD_CARRIER_SCHEDULE_PRO, payload: {type: 'C'}})
        }
        if (poForAppt && poForAppt.length) {
            const masterPO = poForAppt[0];
            if (masterPO?.IDCarrier) {
                setSelectedCarrier({
                    label: masterPO?.CarrierName,
                        value: masterPO?.IDCarrier,
                })
            setSearchableSelectFormData({
                label: masterPO?.CarrierName,
                    value: masterPO?.IDCarrier,
            })
            setParticularFieldValue({ carrier_name: masterPO?.IDCarrier})
              
            }
            if (isCarrier() && poForAppt.length && poForAppt[0]?.AppointmentBookStartTime) {
                const dateData = moment(poForAppt[0]?.AppointmentBookStartTime).utc();
                const tempMaxDate = moment(poForAppt[0]?.AppointmentBookEndTime).utc();
                setSelectedDate(new Date(dateData.year(), dateData.month(), dateData.date()))
                setMinDate(new Date(dateData.year(), dateData.month(), dateData.date()))
                setMaxDate(new Date(tempMaxDate.year(), tempMaxDate.month(), tempMaxDate.date()))
                setRequestedTime(moment(dateData).format(AppConstant.commonStrings.HH_mm_dateFormat))                
            } else {
                
                const dateData = moment(todayDate).utc();
                const tempMaxDate = moment(masterPO.DueDate).utc();
                setSelectedDate(new Date(dateData.year(), dateData.month(), dateData.date()))
                setMinDate(new Date(dateData.year(), dateData.month(), dateData.date()))
                setMaxDate(new Date(tempMaxDate.year(), tempMaxDate.month(), tempMaxDate.date()))
                setRequestedTime(moment(todayDate).format(AppConstant.commonStrings.HH_mm_dateFormat)) 
            }
            setParticularFieldValue({ carrier_name: masterPO?.IDCarrier })
            if (masterPO?.WarehouseData?.TimeZone) {
                const text = masterPO?.WarehouseData?.TimeZoneDescription + ', ' + masterPO?.WarehouseData?.TimeZone
                setTimeZoneText(text)
                // moment.tz.setDefault(masterPO?.WarehouseData?.TimeZone); // Set Default Timezone
                //setTimezone(masterPO?.WarehouseData?.TimeZone)
            }

        }
        // dispatch({ type: sagaActions.CLEAR_APPOINTMENT });
    }, [])

    useEffect(() => {
        if (_.size(appointmentList) && intiateAppointmentLoading && appointmentConfirmation) {
            props.callSuccessPage();
            dispatch({ type: sagaActions.INTIATE_SS_LOADER, payload: false })
            dispatch({ type: sagaActions.INITIATE_SS_APPOINTMENT_LOADER, payload: false })
              //Added before PROD release sprint -36, 04/18/2024
            dispatch({type: sagaActions.ADD_APPOINTMENT_CONFIRMATION,payload: false});
        }
          //Commebted before PROD release sprint -36, 04/18/2024
        // else if (_.size(appointmentList) && intiateAppointmentLoading) {
        //     dispatch({ type: sagaActions.INTIATE_SS_LOADER, payload: false })
        //     dispatch({ type: sagaActions.INITIATE_SS_APPOINTMENT_LOADER, payload: false })
        // }
    }, [appointmentList])

    useEffect(() => {
        if (!intiateAppointmentLoading && loading) {
            dispatch({ type: sagaActions.INTIATE_SS_LOADER, payload: false })
            dispatch({ type: sagaActions.INITIATE_SS_APPOINTMENT_LOADER, payload: false })
            setLoading(false)
        }
    }, [intiateAppointmentLoading])

    useEffect(() => {
        if (_.size(poForAppt) && selectedDate) {
            setTimeSlot('')
            setRequestedTime(moment(todayDate).utc().format(AppConstant.commonStrings.HH_mm_dateFormat))
            const poObj = poForAppt[0];
            dispatch({type:sagaActions.VALIDATE_DATE_FOR_APPOINTMENT , payload: { "selectedDate": moment(selectedDate).tz(moment.tz.guess()).format(AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss), "IDWarehouse":poObj.IDWarehouse,"IDDock":poObj.IDDock}})
            fetchRecommendationsArr();
        }
    }, [selectedDate, poForAppt])
    useEffect(() => {

        if (ssRecommendationsArr && ssRecommendationsArr.length) {
            setLoading(true)
            setRecommendationsArr(_.sortedUniq(ssRecommendationsArr))
            setErrorMsg(null)
            setLoading(false)
        } else {

            if (ssRecommendationsArr.length === 0) {
                setErrorMsg(AppConstant.smartScheduler.recommendations.noRecommendationWarningText)
                setRecommendationsArr([])
            }
            else {
                setErrorMsg(ssRecommendationsArr.RESULT)
                setRecommendationsArr([])
            }
            setLoading(false)
        }

    }, [ssRecommendationsArr])

     /**
    * useEffect to call fetch Carrier data function
    */
    useEffect(() => {
        if (_.size(carrierList) && !isCarrier()) {
            formCarrierListData();
        }
    }, [carrierList]);

    /**
     * UseEffect of add carrier SPro
     */

    useEffect(() => {
        if (!_.isEmpty(addCarrierSpro)) {
            setCarrierListState([...carrierListState, {value:addCarrierSpro?.carrier_id, label: addCarrierSpro?.carrier_name }])
            setSelectedCarrier({
                value: addCarrierSpro?.carrier_id,
                label: addCarrierSpro?.carrier_name
            })
            setSearchableSelectFormData({
                value: addCarrierSpro?.carrier_id,
                label: addCarrierSpro?.carrier_name
            })
            setParticularFieldValue({ carrier_name: addCarrierSpro?.carrier_id})
            dispatch({type: sagaActions.ADD_CARRIER_SCHEDULE_PRO, payload: {type: 'C'}})
        }

    }, [addCarrierSpro])


    const fetchRecommendationsArr = () => {
        const poObj = poForAppt[0];
        // We need send po with comma separated to BE to handle multiple PO's on BE
        let allPOs = ''
        poForAppt.forEach((el) => {
            allPOs = allPOs + el.PoNumber + ",";
            return;
        });
        const apiParams = {
            "IDWarehouse": poObj.IDWarehouse,
            "IDDock": poObj.IDDock,
            "PoNumber": allPOs,
            // "selectedDate": jsDateObjToUTC(selectedDate),
            // moment.tz.guess() This is added to keep the calendar time same as user timezone and send same data to Backend
            "selectedDate": moment(selectedDate).tz(moment.tz.guess()).format(AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
            "requestedTime": requestedTime,
            "RequestedBy": cu_id
        }
        if (selectedDate && requestedTime) {
            dispatch({ type: sagaActions.SS_RECOMMENDATIONS_LIST_ACTION, payload: { ...apiParams } })
        }
    }

    const bookAppt = (carrier_name) => {
        setLoading(true);
        dispatch({ type: sagaActions.INITIATE_SS_APPOINTMENT_LOADER, payload: true })
        const payload_door = SelectReccSlot.TimeSlotName;
        const payload_IDDock = poForAppt[0].IDDock;
        let CRUDTYPE = 'I';
        const fieldData = formParticularFieldValue(poForAppt);
        let poNum = fieldData?.po_number;
        // if (!poNum.includes(",")) {
            poNum = poNum + ",";
        // }

        const masterPO = poForAppt[0];
        if (_.upperCase(masterPO?.ApptScheStatus) === _.upperCase('Scheduled') || _.upperCase(masterPO?.ApptScheStatus) === _.upperCase('Rescheduled')) {
            CRUDTYPE = 'R'
        }
        // let selectedStartTimeData = formTime(moment(SelectReccSlot.ApptStartTime).utc().format(AppConstant.commonStrings.MM_DD_YYYY__HH_mm_ss))
        let selectedStartTimeData = SelectReccSlot.ApptStartTime

        const newItemPayload = {
            PoNumber: poNum,
            IDWarehouse: masterPO?.IDWarehouse,
            ReceivingZipCode: masterPO?.ReceivingZipCode,
            ApptStartDate: selectedStartTimeData,
            ApptStartTime: selectedStartTimeData,
            ApptEndDate: null,
            ApptEndTime: null,
            ApptStatus: 1,
            Door: payload_door,
            ApptCreatedBy: cu_id,
            IDDock: payload_IDDock,
            IDCarrier: carrier_name ? carrier_name : !_.isEmpty(selectedCarrier) ? selectedCarrier?.value : userAccountDetails?.IDCarrier,
            CRUDTYPE: CRUDTYPE,
            DeletedPos: '',
            isManualOverride: false,
            rescheduleReason: CRUDTYPE === 'R' ? rescheduleReason : null,
            appointmentDocumentsList: appointmentDocumentsList,
            userType: userType
        }
        if(CRUDTYPE==='R'){ 
         newItemPayload.PORelationID=masterPO.PORelationID;
        }
        dispatch({ type: sagaActions.ADD_APPOINTMENT, payload: newItemPayload })
        dispatch({
            type: sagaActions.ADD_SELECTED_DATE_FOR_APPT_UPDATE,
            payload: new Date(),
        });
    }

    const submitHandler = () => {
        if (isCarrier()) {
            bookAppt()
        } else {
            // This is WAREHOUSE FLOW
            setNextStep(true)
            if (_.size(poForAppt)) {
                const masterPO = poForAppt[0];
                if (masterPO?.IDCarrier) {
                    setSelectedCarrier({
                        label: masterPO?.CarrierName,
                        value: masterPO?.IDCarrier,
                    });
                    setParticularFieldValue({ carrier_name: masterPO?.IDCarrier })
                } else {
                    setSelectedCarrier({})
                }
            }
        }
    }

    const displayLoader = (themeSelected, height, count) => {
        return (
            <Loader height={height || 35} count={count || 1} highlightColor={themeSelected === 'dark-mode' ? '#151515' : ''}
                baseColor={themeSelected === 'dark-mode' ? '#575757'
                    : ''}
            />
        )
    }


    const handleTimeSlotClick = (e, data) => {
        setSelectReccSlot(data)
    }

    const formCarrierListData = () => {
        if (_.size(carrierList)) {
            const carrierData = carrierList.map((el) => {
                return {
                    value: el.carrier_id,
                    label: el.carrier_name,
                };
            });
            setCarrierListState([...carrierData]);
        } else {
            setCarrierListState([]);
        }
    };

    const onCarrierSelcted = (props) => {
        if (props.length > 2) {
            dispatch({
                type: sagaActions.SS_GET_CARRIER_LIST,
                payload: { carrier: props },
            });
        }
    };

    const onCreateSelected = (props) => {
        const payload = {
            "name": props,
            "contact_phone": null,
            "first_name": null,
            "last_name": null,
            type: 'A'
        }
        dispatch({type: sagaActions.ADD_CARRIER_SCHEDULE_PRO, payload})  
    }

    const formFieldList = [
        {
            name: "carrier_name",
            type: "select_with_input_search_add_button_temp",
            size: "col-sm-8 mb-3 f-12",
            parentClass: "inputTitle",
            componentClassName: "mt-1 inputDiv border border-1 rounded-1 border-dark",
            inputTitle: "Select Carrier",
            position: 5,
            title: AppConstant.reports.scheduledLoadStatus.tableHeaders.carrierName,
            customStylesLight: customStylesLight,
            placeholderText: "Select a carrier from the list",
            onInputChange: onCarrierSelcted,
            // onChange: onChangeValue,
            defaultValue: !_.isEmpty(selectedCarrier)
                ? selectedCarrier
                : "Select a carrier from the list",
            isLoading: false,
            isClearable: false,
            isSearchable: true,
            options: carrierListState,
            formatCreateLabel: () => { },
            loadingMessage: "Please wait loading....",
            formValidation: {
                required: true,
            },
            handleCreateOption: onCreateSelected,
            searchableSelectFormData: searchableSelectFormData,
        },
    ]
    const submitbtnData = {
        show: true,
        text: AppConstant.commonStrings.submit,
    };
    const custombtnData = {
        show: true,
        text: 'Back',
    };

    const customBtnHandler = (e, type) => {
        if (type === 'customBtn') {
            setNextStep(false)
            setResetFormData(0)
            setSelectedCarrier(null)
            setSelectReccSlot(null)
        }
    }

    const getSummitedFormData = (data) => {
        if (data?.carrier_name) {
            bookAppt(data?.carrier_name)
        }
    }

    const getFormFieldData = (data, name, control, inputs,searchableSelectFormData) => {
        if (name === "carrier_name") {
            setSelectedCarrier(data)
            if (searchableSelectFormData) {
                setSearchableSelectFormData(searchableSelectFormData)
            } 
        }
    };



    const openForm = () => {
        return <CustomDynamicFormSS
            formFieldList={formFieldList}
            sort={true}
            submitbtn={submitbtnData}
            themeSelected={themeSelected}
            getFormFieldData={getFormFieldData}
            getSummitedFormData={getSummitedFormData}
            // editFormData={editData}
            loading={intiateAppointmentLoading}
            resetFormData={() => {
                setResetFormData(0)
                setSelectedCarrier(null)
                setSelectReccSlot(null)
            }}
            setParticularFieldValue={particularFieldValue}
            disclaimer={false}
            disclaimersArray={[]}
            customDisableSave={false}
            customBtn={custombtnData}
            customBtnHandler={customBtnHandler}
        />
    }

    return (<>
        {!nextStep ? <div>
            <div className='d-flex justify-content-between recommendation-wrapper gap-3 w-100'>
                <div className='calender-wrapper'>
                    <p className='fw-semibold p-2 text-start'>Select Date & Time</p>
                    <div className=''>
                        <DatePickerSS minDate={minDate} maxDate={maxDate} setSelectedDate={setSelectedDate} selectedDate={selectedDate} setLoading={false} />
                    </div>
                    {dateValidationResponse && !dateValidationResponse.Leaves.valid ?
                <p className='f-12 color-red fw-400 text-start'>* {dateValidationResponse?.Leaves?.message}</p>
            :""}
                </div>
                <div className='overflowX-hidden w-inherit timeslots-container-ss'>
                    {!intiateAppointmentLoading ?
                        <RecommendedTimeslotSS selectedDate={selectedDate} ssRecommendationsArr={ssRecommendationsArr} loading={loading} errorMsg={errorMsg} handleTimeSlotClick={(e, el) => handleTimeSlotClick(e, el)} timeSlot={SelectReccSlot} />
                        :
                        displayLoader(themeSelected, 30, 10)
                    }

                </div>
            </div>
            <div className='d-flex justify-content-between mt-1'>
                <div>
                    <p className='ss-timezone'>
                        Time Zone:
                    </p>
                    <p className='ss-timezone-sub'>
                        {timeZoneText}
                    </p>
                </div>
                <div>
                {!isCarrier()&&
                <CustomButtonSS
                    isLoading={false}
                    title={'Back'}
                    className={ "btn-unstyled"}
                    onClick={() => { handleOptionChangeParent('') }}
                />}
                <CustomButtonSS
                    isLoading={intiateAppointmentLoading}
                    title={isCarrier() ? AppConstant.commonStrings.submit : AppConstant.commonStrings.next}
                    className={_.isEmpty(SelectReccSlot) ? "btn-submit disabled" : "btn-submit"}
                    onClick={() => { submitHandler() }}
                    disabled={_.isEmpty(SelectReccSlot) || errorMsg ? true : false}
                />
                </div>
            </div>

        </div> : <>
            <div className='p-3'>
                <div className='d-flex justify-content-start boldText'>
                    <div>
                        <p className=''>You are booking an appointment for: </p>

                        <div className='bolderText my-2'><i className="fa-regular fa-clock"></i> {moment(SelectReccSlot.ApptStartTime).utc().format('dddd')}, {moment(SelectReccSlot.ApptStartTime).utc().format(AppConstant.commonStrings.MMMM_Do)} <span className='fw-normal'>&nbsp;{moment(SelectReccSlot.ApptStartTime).utc().format(AppConstant.commonStrings.hh_mm_A_dateFormat)}</span></div>
                    </div>
                </div>
                <div className='min-h-32 d-flex flex-column justify-content-around'>

                    {openForm()}
                </div>
            </div>
        </>
        }
    </>
    )
}
