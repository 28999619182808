import React, { useEffect, useRef, useState } from 'react'
import ScheduleProWrapper from '../../../SmartSchedule/SchedulePro/ScheduleProWrapper'
import { getWhMetaDataReducerSlice } from '../../../../../redux/reducers/reducerSlices/WhMetaDataReducerSlice';
import { useSelector } from 'react-redux';
import DrilldownTableSS from '../../Components/Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTableSS';
import CustomButtonSS from '../../../Common/CustomButton/CustomButtonSS';
import { AppConstant } from '../../../../Assests/AppConstant';
import SSPopup from '../../../Common/Popup/SSPopup/SSPopup';
import { sagaActions } from '../../../../../sagas/sagaActions';
import { displayLoader, formParticularFieldValueForAssociatedPO, formatPOwithPORelationID, showAlert } from '../../../../Assests/Utility';
import { useDispatch } from 'react-redux';
import _ from "lodash"
import { getPoSSselector } from '../../../../../redux/reducers/reducerSlices/POSmartSchedulerReducer';
import { getUserSelector } from '../../../../../redux/reducers/reducerSlices/UserReducer';
import { smartScheduleSelector } from '../../../../../redux/reducers/reducerSlices/SmartScheduleReducer';
import InitalAppointmentSelectionScreen from '../BookAppointments/Components/InitalAppointmentSelectionScreen/InitalAppointmentSelectionScreen';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import './WhMetaDataMainScreen.css'
import SelectRescheuleReason from '../BookAppointments/Components/SelectReasonForRescheduleOrCancel/SelectRescheuleReason';
import { toast } from 'react-toastify';

function ManageAppointments(props) {
    const { themeSelected = "light mode" } = props
    const navigate = useNavigate()
    const whMetaData = useSelector(getWhMetaDataReducerSlice);
    const { preCheckList, lastPreCheckObj } = whMetaData;
    const [showModal, setShowModal] = useState(false)
    const [selectedPoObj, setSelectedPoObj] = useState(null)
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [apptArr, setApptArr] = useState([])
    const dispatch = useDispatch()
    const pOSS = useSelector(getPoSSselector);
    const { arrangeOrder, arrangeKey } = pOSS;
    const ss = useSelector(smartScheduleSelector);
    const { searchedPOData, appointmentList, initateLoading } = ss;
    const user = useSelector(getUserSelector);
    const { userType, cu_id } = user;
    const [selectedRowData, setSelectedRowData] = useState();
    const [scheduleScreenType, setScheduleScreenType] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [triggerRecalculate, setTriggerRecalculate] = useState(false);
    const [showRescheduleModal, setShowRescheduleModal] = useState(false)
    const firstUpdate = useRef(true);
    const [rescheduleReason, setRescheduleReason] = useState(null);
    const [showRescheduleReason, setShowRescheduleReason] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null)

    useEffect(() => {
        if (_.size(preCheckList)) {
            setApptArr(formatPOwithPORelationID(preCheckList))
        }
        else {
            setApptArr([])
        }
    }, [preCheckList])

    useEffect(() => {
        return () => {
            dispatch({ type: sagaActions.UPDATE_PRE_CHECK_LIST, payload: [] })
        }
    }, [])



    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        if (showSuccess) {
            dispatch({ type: sagaActions.INTIATE_SS_SEARCH_PO, payload: { PoNumber: selectedRowData?.PoNumber, ReceivingZipCode: selectedRowData?.ReceivingZipCode, UserType: userType, ID: userType === 'CARRIER' ? cu_id : selectedRowData?.IDWarehouse } })
        }
        if (lastPreCheckObj) {

            dispatch({ type: sagaActions.PRE_CHECK_FOR_ADMIN_OPERATION, payload: lastPreCheckObj })
        }
    }, [showSuccess, appointmentList])

    const apptTitleDD = [
        { id: '', name: 'Serial No.' },
        { id: '', name: 'PO No.' },
        { id: '', name: 'Start Date & Time' },
        { id: '', name: 'End Date & Time' },
        { id: '', name: 'Vender Name' },
        { id: '', name: 'Action' },
        { id: '', name: 'Multi Po' },
    ]

    const formatListDataDrillDown = (data) => {

        return data.map((el, index) => {
            return {
                id: index,
                0: index + 1,
                1: el.PoNumber,
                2: el.ApptStartTime,
                3: el.ApptEndTime,
                4: el.VendorName,
                5: el.masterPO ? actionButtons(el) : "--"
            }
        })
    }

    const actionButtons = (data) => {
        return (
            <div className='d-flex gap-1 align-items-center w-max'>
                <CustomButtonSS
                    isLoading={false}
                    title={"View Details"}
                    className={"btn-action f-12"}
                    onClick={() => { openDetails(data) }}
                    isIcon={false}
                />
                <CustomButtonSS
                    isLoading={false}
                    title={""}
                    className={"carrier-po-btn-small f-12"}
                    onClick={() => { openReschedulePopup(data) }}
                    isIcon={true}
                    iconClass="fa-solid fa-arrow-rotate-right"
                />
                <CustomButtonSS
                    isLoading={false}
                    title={""}
                    className={"carrier-po-btn-small f-12"}
                    onClick={() => { openDeletePopup(data) }}
                    isIcon={true}
                    iconClass={'fa-solid fa-trash'}
                />
            </div>
        )
    }

    const openReschedulePopup = (data) => {
        // dispatch({ type: sagaActions.INTIATE_SS_SEARCH_PO, payload: { PoNumber: data?.PoNumber, ReceivingZipCode: data?.ReceivingZipCode, UserType: userType, ID: userType === 'CARRIER' ? cu_id : data?.IDWarehouse } })
        // dispatch({ type: sagaActions.UPDATE_SS_SEARCH_PO, payload: [data] })
        let ifApptExists = _.find(preCheckList, {
            PoNumber: data?.PoNumber,
        });
        let foundObject = [];
        if (!_.isEmpty(ifApptExists)) {
            let { PORelationID } = ifApptExists;
            foundObject = _.filter(preCheckList, {
                PORelationID
            });
        }
        if (_.size(foundObject)) {
            //Needed when coming from myapointments

            dispatch({ type: sagaActions.UPDATE_SS_SEARCH_PO, payload: _.flatten(formatPOwithPORelationID(foundObject)) })
            ssSchedulehandler(foundObject)
            // dispatch({ type: sagaActions.SET_SS_SELECTED_PO_FOR_APPT, payload: foundObject })
        } else {
            dispatch({ type: sagaActions.INTIATE_SS_SEARCH_PO, payload: { PoNumber: data?.PoNumber, ReceivingZipCode: data?.ReceivingZipCode, UserType: userType, ID: userType === 'CARRIER' ? cu_id : data?.IDWarehouse } })
            dispatch({ type: sagaActions.UPDATE_SS_SEARCH_PO, payload: [data] })
            ssSchedulehandler(data)
        }

        setShowRescheduleModal(true)
    }

    const openDetails = (data) => {

        setSelectedPoObj(data)
        setShowModal(true)

    }

    const closePopup = () => {
        setShowModal(false)
    }

    const createBody = () => {
        return (
            <div>
                {selectedPoObj &&
                    <>
                        <div className='d-flex justify-content-between'><p>Po No.</p><p>{selectedPoObj.PoNumber || "-"}</p></div>
                        <div className='d-flex justify-content-between'><p>Vendor Name</p><p>{selectedPoObj.VendorName || "-"}</p></div>
                        <div className='d-flex justify-content-between'><p>Warehouse</p><p>{selectedPoObj.WarehouseName || "-"}</p></div>
                        <div className='d-flex justify-content-between'><p>Zip</p><p>{selectedPoObj.ReceivingZipCode || "-"}</p></div>
                        <div className='d-flex justify-content-between'><p>Buyer Name</p><p>{selectedPoObj.BuyerName || "-"}</p></div>
                        <div className='d-flex justify-content-between'><p>Commodity</p><p>{selectedPoObj.Commodity || "-"}</p></div>
                        <div className='d-flex justify-content-between'><p>Dock Name</p><p>{selectedPoObj.DockName || "-"}</p></div>
                        <div className='d-flex justify-content-between'><p>Items</p><p>{selectedPoObj.Quantity || "-"}</p></div>
                        <div className='d-flex justify-content-between'><p>Cases</p><p>{selectedPoObj.ItemsCases || "-"}</p></div>
                        <div className='d-flex justify-content-between'><p>Pallets</p><p>{selectedPoObj.Pallets || "-"}</p></div>
                        <div className='d-flex justify-content-between'><p>Appointment No.</p><p>{selectedPoObj.AppointmentNumber || "-"}</p></div>
                        <div className='d-flex justify-content-between'><p>Appointment Start Time</p><p>{moment(selectedPoObj.ApptStartTime).utc().format(AppConstant.commonStrings.MM_DD_YYYY__HH_mm) || '-'}</p></div>
                        <div className='d-flex justify-content-between'><p>Appointment End Time</p><p>{moment(selectedPoObj.ApptEndTime).utc().format(AppConstant.commonStrings.MM_DD_YYYY__HH_mm) || '-'}</p></div>
                        <div className='d-flex justify-content-between'><p>Due Date</p><p>{moment(selectedPoObj.DueDate).utc().format(AppConstant.commonStrings.MM_DD_YYYY_dateFormat) || '-'}</p></div>
                    </>
                }
            </div>
        )
    }

    const showPODetails = () => {
        const popupObj = {
            id: 'podetails',
            modalLabel: 'podetails',
            showClose: true,
            showfooter: false,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title: AppConstant.smartScheduler.apptDetailsStr,
            bodyText: createBody(),
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.commonStrings.viewAllStr,
                },
                btn2: {
                    show: false,
                    text: AppConstant.commonStrings.close
                },

            },
        }

        return <SSPopup {...popupObj} popupBtnHandler={(text) => { }} closepopup={() => { closePopup() }} themeSelected={themeSelected} showModal={showModal} />
    }

    function createWarningText(mPO, associatedPO, arr) {
        return <div className='boldText'>
            <span> Are you sure, you want to Cancel this Appointment for PO: {mPO} </span>
            {arr.length > 1 ? <><span> and its associated POs :  </span>
                <span className='textRed'>{associatedPO} ?</span></> : <span>?</span>}
        </div>; // This will render HTML
    }

    const deletePoAppt = () => {

        const newItemPayload = {
            PoNumber: selectedRowData.PORelationID,
            IDWarehouse: selectedRowData.IDWarehouse,
            ReceivingZipCode: selectedRowData.ReceivingZipCode,
            ApptStartDate: selectedRowData.ApptStartDate,
            ApptStartTime: selectedRowData.ApptStartTime,
            ApptEndDate: selectedRowData.ApptEndDate,
            ApptEndTime: selectedRowData.ApptEndTime,
            ApptStatus: selectedRowData.ApptStatus,
            Door: selectedRowData.Door,
            ApptCreatedBy: selectedRowData.ApptCreatedBy,
            IDDock: selectedRowData.IDDock,
            IDCarrier: selectedRowData.IDCarrier,
            CRUDTYPE: 'D',
            DeletedPos: '',
            rescheduleReason: rescheduleReason,
            userType: userType
        }
        dispatch({ type: sagaActions.ADD_APPOINTMENT, payload: newItemPayload })

    }

    const openDeletePopup = (data) => {

        setSelectedRowData(data)
        setShowDeleteModal(true)
    }

    /**
 * Function to render delete po popup body
 * @param {*} mPO 
 * @param {*} associatedPO 
 * @param {*} arr 
 * @returns 
 */
    function deletePopupBody(mPO, associatedPO, arr) {
        return (
            <>
                {createWarningText(mPO, associatedPO, arr)}
                <br />
                {<SelectRescheuleReason rescheduleReason={rescheduleReason} setRescheduleReason={setRescheduleReason} onSubmit={() => { setShowRescheduleReason(false) }} type='Cancel' errorMsg={errorMsg} />}
            </>
        )
    }

    const showDeletePopup = () => {
        if (_.size(apptArr)) {
            const filteredArray = _.flatten(_.filter(apptArr, subArray => _.some(subArray, { PORelationID: selectedRowData?.PORelationID })));
            if (_.size(filteredArray)) {
                const masterPO = filteredArray[0]
                const fieldDataAssociated = formParticularFieldValueForAssociatedPO(filteredArray)
                const popupObj = {
                    id: 'deleteAppointment',
                    modalLabel: 'deleteEmail',
                    showClose: true,
                    showfooter: true,
                    showHeader: true,
                    sendDriverNotification: false,
                    modalSize: 'md',
                    title: AppConstant.commonStrings.cancelApptTitle,
                    bodyText: deletePopupBody(masterPO?.PoNumber, fieldDataAssociated?.po_number, filteredArray),
                    subText: "",
                    keyboard: false,
                    backdrop: "static",
                    footerBtn: {
                        btn1: {
                            show: true,
                            text: AppConstant.commonStrings.yes,
                            loading: initateLoading
                        },
                        btn2: {
                            show: true,
                            text: AppConstant.commonStrings.no
                        }
                    },
                }

                return <SSPopup {...popupObj} popupBtnHandler={(text) => deletePoPopupHandler(text)} closepopup={() => {
                    setShowDeleteModal(false)
                    setRescheduleReason(null)
                    setErrorMsg(null)
                }} themeSelected={themeSelected} showModal={showDeleteModal} />
            }
        }
    }

    const deletePoPopupHandler = (text) => {

        if (text === AppConstant.commonStrings.yes) {
            if (!rescheduleReason) {
                setErrorMsg(AppConstant.help.required)
            }
            else {
                deletePoAppt()
                setRescheduleReason(null)
                setErrorMsg(null)
            }
        } else {
            setShowDeleteModal(false)
            setSelectedRowData({})
            setRescheduleReason(null)
            setErrorMsg(null)
        }
    }

    const getBodyData = () => {
        if (apptArr.length) {

            return (apptArr.map((data, index) => {
                return (
                    formatListDataDrillDown(data, index)
                )
            }))
        }
        else {
            return []
        }
    }


    const ssSchedulehandler = (rowData) => {
        setShowSuccess(false);
        setSelectedRowData(rowData);
        // dispatch({ type: sagaActions.SET_SS_SELECTED_PO_FOR_APPT, payload: [rowData] })
        dispatch({ type: sagaActions.SET_SS_SELECTED_PO_FOR_APPT, payload: _.isArray(rowData) ? rowData : [rowData] })
        if (_.upperCase(userType) === _.upperCase('USER')) {
            // navigate('/wpscheduleappointments') // Commented to implement for new WP Schedule flow, 
            setScheduleScreenType('initialscreen')
        } else if (_.upperCase(userType) === _.upperCase('CARRIER')) {
            setScheduleScreenType('initialscreen')
        }
    }

    const reschedulePopUpBody = () => {
        return ((searchedPOData && searchedPOData.length) &&
            <InitalAppointmentSelectionScreen showSuccess={showSuccess} setShowSucces={setShowSuccess} rowData={searchedPOData} selectedRowData={selectedRowData} userType={userType} triggerReCalculate={triggerRecalculate} setTriggerRecalculate={setTriggerRecalculate} {...props} showApptBtn={false} />
        )
    }


    const showReschedulePopup = () => {
        const popupObj = {
            id: 'reschedulepopup',
            modalLabel: 'reschedulepopup',
            showClose: true,
            showfooter: false,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'lg',
            title: AppConstant.commonStrings.reschedule,
            bodyText: reschedulePopUpBody(),
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.commonStrings.viewAllStr
                },
                btn2: {
                    show: false,
                    text: AppConstant.commonStrings.close
                },

            },
        }

        return <SSPopup {...popupObj} popupBtnHandler={(text) => { }} closepopup={() => { setShowRescheduleModal(false) }} themeSelected={themeSelected} showModal={showRescheduleModal} />
    }




    return (
        <div>
            <ScheduleProWrapper showAppointments={false} showNavBar={true}>
                <div className="my-subscription-ss-wrapper p-3">
                    <div className='subscriptions-ss-heading my-2 f-16 fw-600'>
                        Manage Appointments
                    </div>
                    <div className='h-100 manage-appt-wrapper'>
                        <br />
                        {apptArr.length > 0 ?
                            <DrilldownTableSS
                                themeSelected={themeSelected}
                                bodyData={getBodyData()}
                                titleData={apptTitleDD}
                                showCollapse={false}
                                showInput={false}
                                showSort={true}
                                hasCollapsableContent={true}
                                collapsableData={apptArr}
                                accordionPrimaryIndex={1}
                                arrangeOrder={arrangeOrder}
                                arrangeKey={arrangeKey}
                                drillDownReport={apptArr}
                                initiateDrillDownReport={false}
                                loaderArray={6}
                                sortingAction={sagaActions.SORT_SS_USER_TABLE}
                                subRoot={{ pathName: 'allApptsList' }}
                                showUTCTime={true}
                            /> :
                            <div className='w-100 h-100 d-flex flex-column justify-content-center align-items-center gap-3'>
                                <p className='text-center'><strong className='f-16 fw-bold my-3'>All disputs resolved</strong>
                                    <br />
                                    <br />
                                    <p className='text-center'>
                                        You can update now.
                                    </p>
                                </p>
                                <br />
                                <div className='d-flex justify-content-center'>
                                    <CustomButtonSS
                                        isLoading={false}
                                        title={"Proceed"}
                                        className={"btn-highlighted f-14"}
                                        onClick={() => { navigate("/whmetadata") }}
                                        isIcon={false}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                    {showPODetails()}
                    {showDeletePopup()}
                    {showReschedulePopup()}
                </div>
            </ScheduleProWrapper>
        </div>
    )
}

export default ManageAppointments