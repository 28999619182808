import React, { useEffect, useState } from 'react'
import DatePickerSS from '../../../../../../../DatePickerSS/DatePickerSS';
import '../../RecommendationSS/RecommendationSS.css'
import CustomButtonSS from '../../../../../../../Common/CustomButton/CustomButtonSS';
import { useDispatch } from 'react-redux';
import { smartScheduleSelector } from '../../../../../../../../../redux/reducers/reducerSlices/SmartScheduleReducer';
import { useSelector } from 'react-redux';
import { getUserSelector } from '../../../../../../../../../redux/reducers/reducerSlices/UserReducer';
import moment from 'moment';
import { AppConstant } from '../../../../../../../../Assests/AppConstant';
import _ from 'lodash';
import { createWPMetaData, customStylesLight, formParticularFieldValue, generateManualOverrideTimeSlot } from '../../../../../../../../Assests/Utility';
import { sagaActions } from '../../../../../../../../../sagas/sagaActions';
import Loader from '../../../../../../../Loader/Loader';
import ManualOverrideTimeSlots from './ManualOverrideTimeSlots';
import { CustomDynamicFormSS } from '../../../../../../../Common/CustomForm/CustomDynamicFormSS';
import { getWhMetaDataReducerSlice } from '../../../../../../../../../redux/reducers/reducerSlices/WhMetaDataReducerSlice';
import { fetchBreaksDocksListCommonFunc } from '../../../../../../../LandingPage/SmartScheduler/WarehouseMetaData/Forms/BreaksForm/Common/FetchBreakList';
import { fetchLeavesDocksListCommonFunc } from '../../../../../../../LandingPage/SmartScheduler/WarehouseMetaData/Forms/LeavesForm/Common/FetchLeavesList';
import { getPSTTimezone } from '../../../../../../../../../Utils/DateUtils';
/**
 * This is a reusable manual override recommendation component
 * with new UI
 * @param {*} props 
 * @returns 
 */
export const ManualOverrideRecommendation = (props) => {
    const { isCarrier, themeSelected, handleOptionChangeParent, rescheduleReason } = props;
    const dispatch = useDispatch();
    const [selectedDate, setSelectedDate] = useState(null);
    const [requestedTime, setRequestedTime] = useState(null);
    const [minDate, setMinDate] = useState(null);
    const [maxDate, setMaxDate] = useState(null);
    const [timeSlot, setTimeSlot] = useState(null)
    const ssReducer = useSelector(smartScheduleSelector);
    const { poForAppt, appointmentList, intiateAppointmentLoading, carrierList, wpSchedulerData,
        wpCapacityData,
        validationType,
        appointmentConfirmation,
        dateValidationResponse,
        appointmentDocumentsList
         } = ssReducer;
    const [recommendationsArr, setRecommendationsArr] = useState([]);
    const user = useSelector(getUserSelector);
    const { cu_id, userAccountDetails, userType, selectedWarehouse, addCarrierSpro } = user;
    const [loading, setLoading] = useState(false);
    const [SelectReccSlot, setSelectReccSlot] = useState({});
    const [errorMsg, setErrorMsg] = useState(null);
    const [carrierListState, setCarrierListState] = useState([]);
    const [selectedCarrier, setSelectedCarrier] = useState(null);
    const [particularFieldValue, setParticularFieldValue] = useState("");
    const [timeZoneText, setTimeZoneText] = useState('')
    const todayDate = new Date();
    const [nextStep, setNextStep] = useState(false)
    const [resetFormData, setResetFormData] = useState(0);
    const [wpCapacityDataState, setWPCapacityDataState] = useState(null);
    const [nextScreenErrorValidationData, setNextScreenErrorValidationData] = useState([])
    const whMetaData = useSelector(getWhMetaDataReducerSlice);
    const { whMetaDocksList, whMetaLeavesList, whMetaBreaksList } = whMetaData;
    const [searchableSelectFormData, setSearchableSelectFormData] = useState(null)
    /**
     * Initial useEffect
     */
    useEffect(() => {
        setSearchableSelectFormData(null)
        setParticularFieldValue({})
        setSelectedCarrier(null)
        if (!_.isEmpty(addCarrierSpro)) {
            dispatch({type: sagaActions.ADD_CARRIER_SCHEDULE_PRO, payload: {type: 'C'}})
        }
        // setLoading(true)
        if (poForAppt && poForAppt.length) {
            const masterPO = poForAppt[0];
            if (masterPO?.IDCarrier) {
                setSelectedCarrier({
                    label: masterPO?.CarrierName,
                        value: masterPO?.IDCarrier,
                })
            setSearchableSelectFormData({
                label: masterPO?.CarrierName,
                    value: masterPO?.IDCarrier,
            })
            setParticularFieldValue({ carrier_name: masterPO?.IDCarrier})
              
            }
            let dateData = moment(todayDate).utc();
            setSelectedDate(new Date(dateData.year(), dateData.month(), dateData.date()))
            setMinDate(new Date(dateData.year(), dateData.month(), dateData.date()))
            const tempMaxDate = moment(masterPO.DueDate).utc();
            setMaxDate(new Date(tempMaxDate.year(), tempMaxDate.month(), tempMaxDate.date()))
            setRequestedTime(moment(todayDate).utc().format(AppConstant.commonStrings.HH_mm_dateFormat))
            setParticularFieldValue({ carrier_name: masterPO?.IDCarrier })
            if (masterPO?.WarehouseData?.TimeZone) {
                const text = masterPO?.WarehouseData?.TimeZoneDescription + ', ' + masterPO?.WarehouseData?.TimeZone
                setTimeZoneText(text)
                // moment.tz.setDefault(masterPO?.WarehouseData?.TimeZone); // Set Default Timezone
                //setTimezone(masterPO?.WarehouseData?.TimeZone)
            }
            let dateTemp = new Date(dateData.year(), dateData.month(), dateData.date())
            const tempArr = generateManualOverrideTimeSlot(moment(dateTemp).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'), 1, 24)
            setRecommendationsArr(tempArr)

        }
        fetchDockList()
        fetchDocksBreaksList();
        fetchDocksList()
        // dispatch({ type: sagaActions.CLEAR_APPOINTMENT });
    }, [])

     const fetchDockListCommonFunc = () => {
        const masterPO = poForAppt[0];
        const apiParams = {
            "DockName": null,
            "PPH": null,
            "DockStatus": null,
            "ID": "",
            "CRUDTYPE": "S",
            "IDWarehouse": masterPO?.IDWarehouse,
            "StartTime": null,
            "EndTime": null,
            "CreatedOn": null,
            "CreatedBy": null,
            "UpdatedBy": null,
            "UpdatedOn": null,
            "operational_days":{
                sunday:null,
                monday:null,
                tuesday:null,
                wednesday:null,
                thursday:null,
                friday:null,
                saturday:null
            }
        }
    
        const operationObj = {
            type: 'Fetch Dock',
            status: ''
        }
        
        return {apiParams : apiParams, operationObj: operationObj}
    }

    const fetchDockList = () => {
        const { apiParams, operationObj } = fetchDockListCommonFunc();
        dispatch({ type: sagaActions.WAREHOUSE_META_DOCK_LIST_ACTION, payload: apiParams, operationObj: operationObj })
    }

    const fetchDocksList = () => {
        const { apiParams, operationObj } = fetchLeavesDocksListCommonFunc(selectedWarehouse);
        dispatch({ type: sagaActions.WAREHOUSE_META_DOCKS_LEAVES_LIST_ACTION, payload: apiParams, operationObj: operationObj })
    }

    const fetchDocksBreaksList = () => {
        const { apiParams, operationObj } = fetchBreaksDocksListCommonFunc(selectedWarehouse);
        dispatch({ type: sagaActions.WAREHOUSE_META_DOCKS_BREAKS_LIST_ACTION, payload: apiParams, operationObj: operationObj })
    }
    /**
  * useEffect to stop loading
  */
    useEffect(() => {
        if (_.size(appointmentList) && intiateAppointmentLoading && appointmentConfirmation) {
            props.callSuccessPage();
            dispatch({ type: sagaActions.INTIATE_SS_LOADER, payload: false })
            dispatch({ type: sagaActions.INITIATE_SS_APPOINTMENT_LOADER, payload: false })
            //Added before PROD release sprint -36, 04/18/2024
            dispatch({type: sagaActions.ADD_APPOINTMENT_CONFIRMATION,payload: false});
        }
        //Commented before PROD release sprint -36, 04/18/2024
        // else if (_.size(appointmentList) && intiateAppointmentLoading) {
        //     dispatch({ type: sagaActions.INTIATE_SS_LOADER, payload: false })
        //     dispatch({ type: sagaActions.INITIATE_SS_APPOINTMENT_LOADER, payload: false })
        // }
    }, [appointmentList])

    /**
   * useEffect to stop loading
   */
    useEffect(() => {
        if (!intiateAppointmentLoading && loading) {
            dispatch({ type: sagaActions.INTIATE_SS_LOADER, payload: false })
            dispatch({ type: sagaActions.INITIATE_SS_APPOINTMENT_LOADER, payload: false })
            setLoading(false)
        }
    }, [intiateAppointmentLoading])

    /**
     * useEffect to create time slot based on selectedDate
     */
    useEffect(() => {
        if (_.size(poForAppt) && selectedDate) {
            setTimeSlot('')
            //Since we are reciving timezone as PST ,moment does not accept PST as timezone 
            const currentDate=moment().tz(selectedWarehouse?.TimeZone==='PST'?getPSTTimezone():selectedWarehouse?.TimeZone).format(AppConstant.commonStrings.YYYY_MM_DD)
            const dateSelected=moment(selectedDate).tz(moment.tz.guess()).format(AppConstant.commonStrings.YYYY_MM_DD)
            let startFrom=0;
            if (currentDate===dateSelected) {
             startFrom=moment().tz(selectedWarehouse?.TimeZone==='PST'?getPSTTimezone():selectedWarehouse?.TimeZone).format('h');
             startFrom++;
            }
            const poObj = poForAppt[0];
            dispatch({type:sagaActions.VALIDATE_DATE_FOR_APPOINTMENT , payload: { "selectedDate": moment(selectedDate).tz(moment.tz.guess()).format(AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss), "IDWarehouse":poObj.IDWarehouse,"IDDock":poObj.IDDock}})
            setRequestedTime(moment(todayDate).format(AppConstant.commonStrings.HH_mm_dateFormat))
            const tempArr = generateManualOverrideTimeSlot(moment(dateSelected).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'), 1, 24,startFrom)
            setRecommendationsArr(tempArr)
        }
    }, [selectedDate, poForAppt])

    /**
    * useEffect to call fetch Carrier data function
    */
    useEffect(() => {
        if (_.size(carrierList) && !isCarrier()) {
            formCarrierListData();
        }
    }, [carrierList]);

     /**
     * UseEffect of add carrier SPro
     */

     useEffect(() => {
        if (!_.isEmpty(addCarrierSpro)) {
            setCarrierListState([...carrierListState, {value:addCarrierSpro?.carrier_id, label: addCarrierSpro?.carrier_name }])
            setSelectedCarrier({
                value: addCarrierSpro?.carrier_id,
                label: addCarrierSpro?.carrier_name
            })
            setSearchableSelectFormData({
                value: addCarrierSpro?.carrier_id,
                label: addCarrierSpro?.carrier_name
            })
            setParticularFieldValue({ carrier_name: addCarrierSpro?.carrier_id})
            dispatch({type: sagaActions.ADD_CARRIER_SCHEDULE_PRO, payload: {type: 'C'}})
        }

    }, [addCarrierSpro])

    /**
    * useEffect to fetch warehouse capacity data
    */
    useEffect(() => {
        if (_.size(wpCapacityData)) {
            if (userType === "USER") {
                let IDWarehouse = selectedWarehouse?.ID;
                const ifSameWPDataExists = _.find(wpCapacityData, { ID: IDWarehouse });

                if (ifSameWPDataExists) {
                    setWPCapacityDataState(ifSameWPDataExists);
                } else {
                    console.log(
                        "Either Warehouse data is not Present of there is mismatch in WP ID"
                    );
                }
            }
        }
    }, [wpCapacityData]);

    /**
    * Function to call book appointment api
    */
    const bookAppt = (carrier_name) => {
        setLoading(true);
        dispatch({ type: sagaActions.INITIATE_SS_APPOINTMENT_LOADER, payload: true })
        const payload_door = SelectReccSlot.TimeSlotName;
        const payload_IDDock = poForAppt[0].IDDock;
        let CRUDTYPE = 'I';
        const fieldData = formParticularFieldValue(poForAppt);
        let poNum = fieldData?.po_number;
        if (!poNum.includes(",")) {
            poNum = poNum + ",";
        }

        const masterPO = poForAppt[0];
        if (_.upperCase(masterPO?.ApptScheStatus) === _.upperCase('Scheduled') || _.upperCase(masterPO?.ApptScheStatus) === _.upperCase('Rescheduled')) {
            CRUDTYPE = 'R'
        }
        // let selectedStartTimeData = formTime(moment(SelectReccSlot.ApptStartTime).utc().format(AppConstant.commonStrings.MM_DD_YYYY__HH_mm_ss))
        let selectedStartTimeData = SelectReccSlot.ApptStartTime

        const newItemPayload = {
            PoNumber: poNum,
            IDWarehouse: masterPO?.IDWarehouse,
            ReceivingZipCode: masterPO?.ReceivingZipCode,
            ApptStartDate: selectedStartTimeData,
            ApptStartTime: selectedStartTimeData,
            ApptEndDate: null,
            ApptEndTime: null,
            ApptStatus: 1,
            Door: payload_door || null,
            ApptCreatedBy: cu_id,
            IDDock: payload_IDDock,
            IDCarrier: carrier_name ? carrier_name : !_.isEmpty(selectedCarrier) ? selectedCarrier?.value : userAccountDetails?.IDCarrier,
            CRUDTYPE: CRUDTYPE,
            DeletedPos: '',
            isManualOverride: true,
            rescheduleReason: CRUDTYPE === 'R' ? rescheduleReason : null,
            appointmentDocumentsList: appointmentDocumentsList,
            userType: userType
        }
        if(CRUDTYPE==='R'){ 
            newItemPayload.PORelationID=masterPO.PORelationID;
           }
        dispatch({ type: sagaActions.ADD_APPOINTMENT, payload: newItemPayload })
        dispatch({
            type: sagaActions.ADD_SELECTED_DATE_FOR_APPT_UPDATE,
            payload: new Date(),
        });
    }

    /**
     * 
     * @param {*} IDDock 
     * @param {*} leaveDate 
     * @returns Function for holiday validation, return status and message
     */

    const holidayValidationHandler = () => {
        if (_.size(whMetaLeavesList)) {
            const masterPO = poForAppt[0];
            const filterdata = _.filter(whMetaLeavesList, { IDDock: masterPO?.IDDock })
            if (_.size(filterdata)) {
                let data = filterdata[0]
                const currentDate = moment.utc(SelectReccSlot?.ApptStartTime).startOf('day');;

                // Check if currentDate falls on or between any holiday range
                const isOnHoliday = filterdata.some(holiday => {
                  const leaveStart = moment.utc(holiday.LeaveStart).startOf('day');;
                  const leaveEnd = moment.utc(holiday.LeaveEnd).startOf('day');;
                  return currentDate.isSameOrAfter(leaveStart) && currentDate.isSameOrBefore(leaveEnd);
                });
                
                if (isOnHoliday) {
                    if (data?.Description) {
                        return {
                            status: true,
                            message: `The chosen date(${moment.utc(SelectReccSlot?.ApptStartTime).format('MM/DD/YYYY')}) is recognized as a holiday due to ${data?.Description}`,
                        };
                    } else {
                        return {
                            status: true,
                            message: `The chosen date(${moment.utc(SelectReccSlot?.ApptStartTime).format('MM/DD/YYYY')}) is recognized as a holiday.`,
                        };
                    }
                    
                } 
            }
        }
        return {
            status: false,
            message: "",
        };
    };
    /**
     * 
     * @param {*} IDDock 
     * @param {*} startTime 
     * @returns Function for dock start and end time validation, return status and message
     */
    const WPCapacityValidationHandler = (IDDock, startTime) => {
        // Check validationType if WP or DOCK
        if (validationType === "WP") {
            const { WHStartTime, WHEndTime } = wpCapacityDataState;
            const dTime = startTime.format("HH:mm:ss");

            const sTime = moment(WHStartTime).utc().format("HH:mm:ss");
            const eTime = moment(WHEndTime).utc().format("HH:mm:ss");
            const isTimeBetween = dTime >= sTime && dTime <= eTime;
            if (isTimeBetween) {
                return {
                    status: false,
                    message: "",
                };
            } else {
                return {
                    status: true,
                    message: `The selected timeslot extends beyond the warehouse start or end time.`,
                };
            }
        } else if (validationType === "DOCK") {

            let ifDockExists = _.find(wpSchedulerData, { dockId: IDDock });
            if (ifDockExists) {
                const { DockStartTime, DockEndTime } = ifDockExists;
                const dTime = startTime.utc().format("HH:mm:ss");
                const sTime = moment(DockStartTime).utc().format("HH:mm:ss");
                const eTime = moment(DockEndTime).utc().format("HH:mm:ss");
                const isTimeBetween = dTime >= sTime && dTime <= eTime;
                if (isTimeBetween) {
                    return {
                        status: false,
                        message: "",
                    };
                } else {
                    return {
                        status: true,
                        message: `The selected timeslot extends beyond the dock start(${ moment(DockStartTime).utc().format("hh:mm A")}) or end time(${moment(DockEndTime).utc().format("hh:mm A")})`,
                    };
                }
            } else {
                console.log("Dock does not exist in dock capacity data");
            }
        }
    };
    /**
     * 
     * @returns Function for dock operational validation, return status and message
     */
    const dockOperationalValidationHandler = () => {
        if (_.size(whMetaDocksList)) {
            const masterPO = poForAppt[0];
            const filterdata = _.filter(whMetaDocksList, { ID: masterPO?.IDDock })
            if (_.size(filterdata)) {
                let data = filterdata[0]
                const momentObject = moment.utc(SelectReccSlot?.ApptStartTime);
                // Get the day using the `format` method
                const dayOfWeek = _.lowerCase(momentObject.format('dddd'));
                // Use object destructuring to create a new object with only the specified key
                const isOperation =  { [dayOfWeek]: data[dayOfWeek]};
                if (!isOperation[dayOfWeek]) {
                    return {
                        status: true,
                        message: `The chosen day(${dayOfWeek}) is designated as a non-operational day`,
                    };
                }
            }
        }
        return {
            status: false,
            message: "",
        };
    }

     /**
     * 
     * @returns Function for dock break validation, return status and message
     */
     const dockBreaksValidationHandler = () => {
        if (_.size(whMetaBreaksList)) {
            const masterPO = poForAppt[0];
            const filterdata = _.filter(whMetaBreaksList, { IDDock: masterPO?.IDDock })
            if (_.size(filterdata)) {
                let data = filterdata[0]

                const currentTime = moment.utc(SelectReccSlot?.ApptStartTime);
               // Extract hours and minutes from the current time
                const currentHours = currentTime.hours();
                const currentMinutes = currentTime.minutes();
                let breakStartTime = null;
                let breakEndTime = null;
                // Check if currentTime falls within any break time range
                const isOnBreak = filterdata.some(breakItem => {
                     // Extract hours and minutes from break time strings
                 breakStartTime = moment.utc(breakItem.BreakStart);
                 breakEndTime = moment.utc(breakItem.BreakEnd);

                const breakStartHours = breakStartTime.hours();
                const breakStartMinutes = breakStartTime.minutes();
                const breakEndHours = breakEndTime.hours();
                const breakEndMinutes = breakEndTime.minutes();

                const isAfterStart = currentHours > breakStartHours || (currentHours === breakStartHours && currentMinutes >= breakStartMinutes);
                const isBeforeEnd = currentHours < breakEndHours || (currentHours === breakEndHours && currentMinutes <= breakEndMinutes);

                return isAfterStart && isBeforeEnd;
                });
                
                if (isOnBreak) {
                    if (data?.Description) {
                        return {
                            status: true,
                            message: `The selected timeslot is within a break period(${moment(breakStartTime).format('hh:mm A')}-${moment(breakEndTime).format('hh:mm A')})`,
                        };
                    } else {
                        return {
                            status: true,
                            message: `The selected timeslot is within a break period(${moment(breakStartTime).format('hh:mm A')}-${moment(breakEndTime).format('hh:mm A')}.`,
                        };
                    }
                    
                } 
            }
        }
        return {
            status: false,
            message: "",
        };
    }

    /**
     * 
     * @param {*} groupId 
     * @param {*} time 
     * @returns Array of error data if Manual Override option was selected
     */
    const appointmentValidationsErrorData = () => {
        const errorDataArray = [];
        const masterPO = poForAppt[0];
        let pStartTime = moment(SelectReccSlot?.ApptStartTime).tz(masterPO?.WarehouseData?.TimeZone); // might be reduadant code
    
        // Check if WPCapacityData exist then perform WP start Start and end time validation
        if (!_.isEmpty(wpCapacityDataState)) {
            const { status, message } = WPCapacityValidationHandler(
                masterPO?.IDDock,
                moment(pStartTime)
            );
            if (status) {
                errorDataArray.push(message)
            }
        }
 // Check if holiday or not
        if (_.size(whMetaLeavesList)) {
            const { status, message } = holidayValidationHandler(
                masterPO?.IDDock,
                moment(pStartTime)
            );
            if (status) {
                errorDataArray.push(message)
            }
        }

        // Check if dock is opertional or not
        if (_.size(whMetaDocksList)) {
            const { status, message } = dockOperationalValidationHandler(
                masterPO?.IDDock,
                moment(pStartTime)
            );
            if (status) {
                errorDataArray.push(message)
            }
        }

        // Check if dock is opertaion or not
        if (_.size(whMetaBreaksList)) {
            const { status, message } = dockBreaksValidationHandler();
            if (status) {
                errorDataArray.push(message)
            }
        }       

        return errorDataArray
    }

    /**
    * Function to handle submit button action in form
    */
    const submitHandler = () => {
        if (isCarrier()) {
            bookAppt()
        } else {
            // This is WAREHOUSE FLOW
            const temp = appointmentValidationsErrorData()
            setNextScreenErrorValidationData(temp)
            setNextStep(true)
            if (_.size(poForAppt)) {
                const masterPO = poForAppt[0];
                if (masterPO?.IDCarrier) {
                    setSelectedCarrier({
                        label: masterPO?.CarrierName,
                        value: masterPO?.IDCarrier,
                    });
                    setParticularFieldValue({ carrier_name: masterPO?.IDCarrier })
                } else {
                    setSelectedCarrier({})
                }
            }
        }
    }

    /**
    * Function to render loader
    */
    const displayLoader = (themeSelected, height, count) => {
        return (
            <Loader height={height || 35} count={count || 1} highlightColor={themeSelected === 'dark-mode' ? '#151515' : ''}
                baseColor={themeSelected === 'dark-mode' ? '#575757'
                    : ''}
            />
        )
    }

    /**
     * Function to handle time slot click
     */
    const handleTimeSlotClick = (e, data) => {
        setSelectReccSlot(data)
    }

    const formCarrierListData = () => {
        if (_.size(carrierList)) {
            const carrierData = carrierList.map((el) => {
                return {
                    value: el.carrier_id,
                    label: el.carrier_name,
                };
            });
            setCarrierListState([...carrierData]);
        } else {
            setCarrierListState([]);
        }
    };

    /**
     * Function to handle carrier selection
     */
    const onCarrierSelcted = (props) => {
        if (props.length > 2) {
            dispatch({
                type: sagaActions.SS_GET_CARRIER_LIST,
                payload: { carrier: props },
            });
        }
    };

    const onCreateSelected = (props) => {
        const payload = {
            "name": props,
            "contact_phone": null,
            "first_name": null,
            "last_name": null,
            type: 'A'
        }
        dispatch({type: sagaActions.ADD_CARRIER_SCHEDULE_PRO, payload})  
    }

    /**
    * JSON form form creation
    */
    const formFieldList = [
        {
            name: "carrier_name",
            type: "select_with_input_search_add_button_temp",
            size: "col-sm-8 mb-3 f-12",
            parentClass: "inputTitle",
            componentClassName: "mt-1 inputDiv border border-1 rounded-1 border-dark",
            inputTitle: "Select Carrier",
            position: 5,
            title: AppConstant.reports.scheduledLoadStatus.tableHeaders.carrierName,
            customStylesLight: customStylesLight,
            placeholderText: "Select a carrier from the list",
            onInputChange: onCarrierSelcted,
            // onChange: onChangeValue,
            defaultValue: !_.isEmpty(selectedCarrier)
                ? selectedCarrier
                : "Select a carrier from the list",
            isLoading: false,
            isClearable: false,
            isSearchable: true,
            options: carrierListState,
            formatCreateLabel: () => { },
            loadingMessage: "Please wait loading....",
            formValidation: {
                required: true,
            },
            handleCreateOption: onCreateSelected,
            searchableSelectFormData: searchableSelectFormData,
        },
    ]
    const submitbtnData = {
        show: true,
        text: AppConstant.commonStrings.submit,
    };
    const custombtnData = {
        show: true,
        text: 'Back',
    };

    const customBtnHandler = (e, type) => {
        if (type === 'customBtn') {
            setNextStep(false)
            setResetFormData(0)
            setSelectedCarrier(null)
            setSelectReccSlot(null)
        }
    }

    const getSummitedFormData = (data) => {
        if (data?.carrier_name) {
            bookAppt(data?.carrier_name)
        }
    }

    const getFormFieldData = (data, name, control, inputs,searchableSelectFormData) => {
        if (name === "carrier_name") {
            setSelectedCarrier(data)
            if (searchableSelectFormData) {
                setSearchableSelectFormData(searchableSelectFormData)
            } 
        }
    };


    /**
   * Function to open form in popup
   */
    const openForm = () => {
        return <CustomDynamicFormSS
            formFieldList={formFieldList}
            sort={true}
            submitbtn={submitbtnData}
            themeSelected={themeSelected}
            getFormFieldData={getFormFieldData}
            getSummitedFormData={getSummitedFormData}
            // editFormData={editData}
            loading={intiateAppointmentLoading}
            resetFormData={() => {
                setResetFormData(0)
                setSelectedCarrier(null)
                setSelectReccSlot(null)
            }}
            setParticularFieldValue={particularFieldValue}
            disclaimer={false}
            disclaimersArray={[]}
            customDisableSave={false}
            customBtn={custombtnData}
            customBtnHandler={customBtnHandler}
        />
    }

    return (<>
        {!nextStep ? <div>
            <div className='d-flex justify-content-between recommendation-wrapper gap-3 w-100'>
                <div className='calender-wrapper'>
                    <p className='fw-semibold p-2 text-start'>Select Date & Time</p>
                    <div className=''>
                        <DatePickerSS minDate={minDate} maxDate={maxDate} setSelectedDate={setSelectedDate} selectedDate={selectedDate} setLoading={false} />
                    </div>
                    {dateValidationResponse && !dateValidationResponse.Leaves.valid ?
                <p className='f-12 color-red fw-400 text-start'>* {dateValidationResponse?.Leaves?.message}</p>
            :""}
                </div>
                <div className='overflowX-hidden w-inherit'>
                    {!intiateAppointmentLoading ?
                        <ManualOverrideTimeSlots selectedDate={selectedDate} ssRecommendationsArr={recommendationsArr} loading={loading} errorMsg={errorMsg} handleTimeSlotClick={(e, el) => handleTimeSlotClick(e, el)} timeSlot={SelectReccSlot} />
                        :
                        displayLoader(themeSelected, 30, 10)
                    }

                </div>
            </div>
            <div className='d-flex justify-content-between'>
                <div>
                    <p className='ss-timezone'>
                        Time Zone:
                    </p>
                    <p className='ss-timezone-sub'>
                        {timeZoneText}
                    </p>
                </div>
                <div>
                    {!isCarrier() &&
                        <CustomButtonSS
                            isLoading={false}
                            title={'Back'}
                            className={"btn-unstyled"}
                            onClick={() => { handleOptionChangeParent('') }}
                        />}
                    <CustomButtonSS
                        isLoading={intiateAppointmentLoading}
                        title={isCarrier() ? AppConstant.commonStrings.submit : AppConstant.commonStrings.next}
                        className={_.isEmpty(SelectReccSlot) ? "btn-submit disabled" : "btn-submit"}
                        onClick={() => { submitHandler() }}
                        disabled={_.isEmpty(SelectReccSlot) || errorMsg ? true : false}
                    />
                </div>
            </div>

        </div> : <>
            <div className='p-3'>
                <div className='d-flex justify-content-start boldText'>
                    <div>
                        <p className=''>You are booking an appointment for: </p>


                        <div className='bolderText my-2'><i className="fa-regular fa-clock"></i> {moment(SelectReccSlot.ApptStartTime).utc().format('dddd')}, {moment(SelectReccSlot.ApptStartTime).utc().format(AppConstant.commonStrings.MMMM_Do)} <span className='fw-normal'>&nbsp;{moment(SelectReccSlot.ApptStartTime).utc().format(AppConstant.commonStrings.hh_mm_A_dateFormat)}</span></div>
                    </div>
                    <div>
                    </div>
                    </div>
                    {_.size(nextScreenErrorValidationData) ? <div className="subText color-red">
                    <span>Alerts -</span>
                    {
                        nextScreenErrorValidationData && nextScreenErrorValidationData.map((control, index) => {
                            return (<>{control && <div className="row" key={index}>
                                <div className="col-12">
                                    <span className="color-red pe-1">{ index + 1}.</span>
                                    <span>{control}</span>
                                </div>
                            </div>}</>)
                        })
                    }
                </div>: ''}
                <div className='min-h-32 d-flex flex-column justify-content-around'>

                    {openForm()}
                </div>
                
            </div>
        </>
        }
    </>
    )
}
