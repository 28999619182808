import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { displayLoader } from '../../../../../../../../Assests/Utility';
import { AppConstant } from '../../../../../../../../Assests/AppConstant';
import CommonChart from '../../../Graphs/CommonChart';

const UnloadingTypeSummary = (props) => {
    const { unloadingTypeSummaryChart, themeSelected, isFullScreen } = props;

    return (
        <div className={`col gridSeparator ms-2 ${themeSelected} ${isFullScreen ? 'col-12' : ''} bg-transparent`}>
            <Link to="/analytics/reports/unloadingtypesummary" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                <div className='col-12 pt-3'>
                    <span className='graphHeading'>{AppConstant.dashboard.defaultdashboard.unloadingTypeSummary.heading}</span><br />
                </div>
                <div className='d-flex flex-row pt-4'>
                    <div className="flex-grow-1 d-flex align-items-center justify-content-center responsive-chart detention-legend">{unloadingTypeSummaryChart
                        ?.series ? <CommonChart data={unloadingTypeSummaryChart} /> : displayLoader(themeSelected)}</div>
                </div>
            </Link>
        </div>
    );
}

export default UnloadingTypeSummary;