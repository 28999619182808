import React, { useState, useEffect, forwardRef, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeDateToGivenFormat, getCurrentDate } from '../../../../../../../Utils/DateUtils';
import { AppConstant } from '../../../../../../Assests/AppConstant';
import WarehouseSearch from '../../../../../SuperWarehouse/Components/SearchWarehouse/WarehouseSearch';
import Popup from '../../../../../Common/Popup/Popup';
import { getUserSelector } from '../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { CustomDynamicForm } from '../../../../../Common/CustomForm/CustomDynamicForm';
import { sagaActions } from '../../../../../../../sagas/sagaActions';
import LeavesMetaList from './LeavesMetaList/LeavesMetaList';
import { fetchDockListCommonFunc } from '../DocksMeta/Common/FetchDockList';
import { showAlert, changeFormatWithoutTz } from '../../../../../../Assests/Utility';
import { toast } from 'react-toastify';
import _ from 'lodash-es';
import { fetchLeavesListCommonFunc, fetchLeavesDocksListCommonFunc } from './Common/FetchLeavesList';
import { getWhMetaDataReducerSlice } from '../../../../../../../redux/reducers/reducerSlices/WhMetaDataReducerSlice';
import CustomDropDown from '../../../../../Common/CustomDropDown/CustomDropDown';
import './LeavesMetaDataForm.css';
import WarehouseSearchSS from '../../../../../SuperWarehouse/Components/SearchWarehouse/WarehouseSearchSS';
import { CustomDynamicFormSS } from '../../../../../Common/CustomForm/CustomDynamicFormSS';
import LeavesMetaListSS from './LeavesMetaList/LeavesMetaListSS';
import SSPopup from '../../../../../Common/Popup/SSPopup/SSPopup';
import CustomDropDownSS from '../../../../../Common/CustomDropDown/CustomDropDownSS';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import moment from 'moment';

const LeavesMetaDataFormSS = (props) => {

    const { themeSelected, allowPermissionD, whObj } = props;
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [searchStr, setSearchStr] = useState('');
    const [editData, setEditData] = useState({});
    const [loading, setLoading] = useState(false);
    const [resetFormData, setResetFormData] = useState(0);
    const [selectedFromDate, setSelectedFromDate] = useState('');
    const [selectedToDate, setSelectedToDate] = useState('');
    const [particularFieldValue, setParticularFieldValue] = useState({ "leavesForWhOrDock": 'Docks' });
    const [startDateState, setStartDateState] = useState(changeDateToGivenFormat(getCurrentDate(), AppConstant.commonStrings.MM_DD_YYYY_dateFormat));
    const user = useSelector(getUserSelector);
    const { selectedWarehouse, cu_id } = user;
    const [endDateState, setEndDateState] = useState(changeDateToGivenFormat(getCurrentDate(), AppConstant.commonStrings.MM_DD_YYYY_dateFormat));
    const whMetaData = useSelector(getWhMetaDataReducerSlice);
    const [isDockHidden, setIsDockHidden] = useState(false);
    const { whMetaDocksList, operationObj,preCheckList } = whMetaData;
    const [currentFilterSelected, setCurrentFilterSelected] = useState({ name: 'Docks' });
    const [showPreCheckModal, setShowPreCheckModal] = useState(false);
    const navigate = useNavigate()
    const firstUpdate = useRef(true);

    useEffect(() => {
        setLoading(false);
        setShowModal(false);
        setEditData({});
        fetchDockList();
        dispatch({type:sagaActions.UPDATE_PRE_CHECK_LIST,payload:[]})
    }, [])

    useEffect(()=>{
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
          }
        if (preCheckList.length>0) {
            setShowModal(false)
            setLoading(false)
            setShowPreCheckModal(true)
        }
    },[preCheckList])

    useEffect(() => {
        if (operationObj.type) {
            switch (operationObj.type) {
                case 'Add Leave':
                    if (operationObj.status) {
                        handleApiResponse(AppConstant.notifications.dataAddedSuccess, AppConstant.smartScheduler.leaves.createLeaveSuccess);
                    } else {
                        handleApiResponse(AppConstant.commonStrings.failure, AppConstant.smartScheduler.leaves.createLeaveFailure);
                    }
                    break;
                case 'Edit Leave':
                    if (operationObj.status) {
                        handleApiResponse(AppConstant.notifications.dataAddedSuccess, AppConstant.smartScheduler.leaves.updateLeaveSuccess);
                    } else {
                        handleApiResponse(AppConstant.commonStrings.failure, AppConstant.smartScheduler.leaves.updateLeaveFailure);
                    }
                    break;
                default:
            }
        }
    }, [operationObj])

    const handleApiResponse = (result, message) => {
        if (result === AppConstant.notifications.dataAddedSuccess) {
            showAlert(toast.TYPE.SUCCESS, message, themeSelected);
            setShowModal(false)
            fetchLeavesList()
        } else {
            showAlert(toast.TYPE.ERROR, message, themeSelected);
        }
        setLoading(false)
        dispatch({ type: sagaActions.RESET_SS_PO_OPERATION_OBJ });
    }

    const fetchDockList = () => {
        const { apiParams, operationObj } = fetchDockListCommonFunc(whObj);
        dispatch({ type: sagaActions.WAREHOUSE_META_DOCK_LIST_ACTION, payload: apiParams, operationObj: operationObj })
    };

    const fetchLeavesList = () => {
        if (currentFilterSelected.name === 'Warehouse') {
            const { apiParams, operationObj } = fetchLeavesListCommonFunc(whObj);
            dispatch({ type: sagaActions.WAREHOUSE_META_LEAVES_LIST_ACTION, payload: apiParams, operationObj: operationObj })
        } else {
            fetchDocksList()
        }
    }

    const fetchDocksList = () => {
        const { apiParams, operationObj } = fetchLeavesDocksListCommonFunc(whObj);
        dispatch({ type: sagaActions.WAREHOUSE_META_DOCKS_LEAVES_LIST_ACTION, payload: apiParams, operationObj: operationObj })
    }

    const TUSDatePickerInput = forwardRef(({ value, onClick }, ref) => {
        return (
            <button className="customDatePickerInput-ss" onClick={onClick} ref={ref}>
                {value}
            </button>
        )
    });

    const getFormFieldData = (data, name) => {

        if (name === 'LeaveStart') {
            setParticularFieldValue({ ...particularFieldValue, LeaveStart: data, LeaveEnd: '' })
            setStartDateState(data)
            setEndDateState('');
        } else if (name === 'LeaveEnd') {
            setParticularFieldValue({ ...particularFieldValue, LeaveEnd: data })
            setEndDateState(data)
        }
        else if (name === 'leavesForWhOrDock') {
            if (data === 'Warehouse') {
                setIsDockHidden(false);
            } else {
                setIsDockHidden(true);
            }
        }
    };

    const getDockList = () => {
        const docksList = whMetaDocksList && whMetaDocksList.length && whMetaDocksList.map((ele, index) => {
            if (ele && ele.DockStatus) {
                return (
                    <option key={ele.Name} value={ele.ID}>
                        {ele.Name}
                    </option>
                );
            } else {
                return null;
            }
        })
        return _.compact(docksList);
    }

    const formFieldList = [
        {
            name: "leavesForWhOrDock",
            type: "radio",
            size: "col-sm-12 mt-2 radioButtonText",
            position: 0,
            title: 'Select for:',

            formValidation: {
                required: true,
            },
            radiconfig: [{
                label: "Warehouse",
                value: "Warehouse"
            },
            {
                label: "Docks",
                value: 'Docks'
            }]

        },
        {
            name: "IDDock",
            type: "select",
            size: "col-sm-12",
            position: 4,
            title: 'Dock',
            hideElement: {
                name: 'IDDock',
                isHidden: isDockHidden,
            },
            formValidation: {
                required: isDockHidden,
            },
            getSelectData: getDockList,
        },
        {
            size: "col-sm-6 mt-1",
            name: "LeaveStart",
            type: "date",
            customDatePickerConfig: {
                calendarType: 'Today',
                placeholder: '',
                selectedDate: startDateState,
                maxDate: 'NA',
                name: "LeaveStart",
                customInput: <TUSDatePickerInput />,
            },
            position: 1,
            value: startDateState,
            title:
                AppConstant.reports.adfatReport.dropdownLabels.startDate,
            formValidation: {
                required: true,
            }
        },
        {
            size: "col-sm-6 mt-1",
            name: "LeaveEnd",
            type: "date",
            customDatePickerConfig: {
                calendarType: 'Today',
                placeholder: '',
                selectedDate: endDateState,
                startDate: startDateState,
                maxDate: 'NA',
                name: "LeaveEnd",
                customInput: <TUSDatePickerInput />,
            },
            position: 2,
            value: endDateState,
            title:
                AppConstant.reports.adfatReport.dropdownLabels.endDate,
            formValidation: {
                required: true,
            }
        },
        {
            name: "Purpose",
            type: "textarea",
            size: "col-sm-12",
            position: 4,
            title: 'Description',
            formValidation: {
                required: true,
            },
        },
    ]

    const formFieldEditList = [
        {
            name: "IDDock",
            type: "select",
            size: "col-sm-12",
            position: 4,
            title: 'Dock',
            hideElement: {
                name: 'IDDock',
                isHidden: isDockHidden,
            },
            formValidation: {
                required: isDockHidden,
            },
            getSelectData: getDockList,
        },
        {
            size: "col-sm-12 mt-1",
            name: "LeaveStart",
            type: "date",
            customDatePickerConfig: {
                calendarType: 'Today',
                placeholder: '',
                selectedDate: startDateState,
                maxDate: 'NA',
                name: "LeaveStart",
                customInput: <TUSDatePickerInput />,
            },
            position: 1,
            value: startDateState,
            title:
                AppConstant.reports.adfatReport.dropdownLabels.startDate,
            formValidation: {
                required: true,
            }
        },
        {
            size: "col-sm-12 mt-1",
            name: "LeaveEnd",
            type: "date",
            customDatePickerConfig: {
                calendarType: 'Today',
                placeholder: '',
                selectedDate: endDateState,
                startDate: startDateState,
                maxDate: 'NA',
                name: "LeaveEnd",
                customInput: <TUSDatePickerInput />,
            },
            position: 2,
            value: endDateState,
            title:
                AppConstant.reports.adfatReport.dropdownLabels.endDate,
            formValidation: {
                required: true,
            }
        },
        {
            name: "Purpose",
            type: "textarea",
            size: "col-sm-12",
            position: 3,
            title: 'Description',
            formValidation: {
                required: true,
            },
        },
    ]

    const createForm = () => {
        return (
            <CustomDynamicFormSS
                formFieldList={Object.keys(editData) && Object.keys(editData).length ? formFieldEditList : formFieldList}
                sort={true}
                cancelBtn={cancelbtndata}
                submitbtn={submitbtnData}
                themeSelected={themeSelected}
                getSummitedFormData={getSummitedFormData}
                getFormFieldData={getFormFieldData}
                editFormData={editData}
                loading={loading}
                resetFormData={resetFormData}
                setParticularFieldValue={particularFieldValue}
            />
        );
    };

    const editLeave = (data) => {
        const editData1 = {
            "IDWHLeave": data.IDWHLeave,
            "ID": data.ID,
            "Purpose": data.Purpose || data.Description,
            "LeaveStart": moment(data.LeaveStart).utc().format(AppConstant.commonStrings.YYYY_MM_DD),
            "LeaveEnd": moment(data.LeaveEnd).utc().format(AppConstant.commonStrings.YYYY_MM_DD),
            "Status": data.Status,
            "CreatedOn": data.CreatedOn || null,
            "CreatedBy": cu_id,
            "UpdatedOn": data.UpdatedOn,
            "UpdatedBy": data.UpdatedBy,
            "IDWarehouse": data.IDWarehouse,
            "IDDockLeave": data.IDDockLeave,
            "IDDock": data.IDDock,
        }
        const tempData = {}
        if (currentFilterSelected.name === 'Warehouse') {
            tempData.leavesForWhOrDock = 'Warehouse';
        } else {
            tempData.leavesForWhOrDock = 'Docks';
            setIsDockHidden(true);
        }
        setShowModal(true);
        setEditData({ ...editData1, ...tempData });
        setParticularFieldValue({ ...editData1, ...tempData });
    }

    const createPayload=(data,type) => {
        if (type==="Edit") {
            return {
                "IDDockLeave": data.leavesForWhOrDock === "Docks" ? data.IDDockLeave : null,
                "IDWHLeave": data.leavesForWhOrDock === "Warehouse" ? data.IDWHLeave : null,
                "IDWarehouse": data.IDWarehouse,
                "Purpose": data.Purpose,
                "LeaveStart": data.LeaveStart,
                "LeaveEnd": data.LeaveEnd,
                "Status": 1,
                "CRUDTYPE": "U",
                "Description": data.Purpose,
                "IDDock": data.leavesForWhOrDock === "Docks" ? data.IDDock : data.IDWHLeave,
                "UpdatedBy": cu_id,
                "UpdatedOn": changeFormatWithoutTz(getCurrentDate(), AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
            }
        }
        else{
            return {
                "IDDockLeave": null,
                "IDWarehouse": whObj.ID,
                "IDWHLeave": null,
                "Description": data.Purpose,
                "Purpose": data.Purpose,
                "LeaveStart": data.LeaveStart,
                "LeaveEnd": data.LeaveEnd,
                "Status": 1,
                "UpdatedBy": cu_id,
                "CRUDTYPE": "I",
                "IDDock": data.leavesForWhOrDock === "Docks" ? data.IDDock : null,
                "CreatedOn": changeFormatWithoutTz(getCurrentDate(), AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
                "CreatedBy": cu_id,
                "UpdatedOn": changeFormatWithoutTz(getCurrentDate(), AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
            }
        }
    }

    const getSummitedFormData = (data) => {
        setLoading(true);
        let apiParams = {}
        const operationObj = {
            type: '',
            status: '',
        }
        if (Object.keys(editData) && Object.keys(editData).length) {
            const apiTempParams = createPayload(data,"Edit")
            operationObj.type = 'Edit Leave'
            apiParams = { ...data, ...apiTempParams }
        } else {
            apiParams = createPayload(data,"Create")
            operationObj.type = 'Add Leave'
        }
        if (data.leavesForWhOrDock === "Warehouse") {
            dispatch({ type: sagaActions.WAREHOUSE_META_LEAVES_LIST_ACTION, payload: apiParams, operationObj: operationObj });
        } else {
            dispatch({ type: sagaActions.WAREHOUSE_META_DOCKS_LEAVES_LIST_ACTION, payload: apiParams, operationObj: operationObj });
        }
    };

    const cancelbtndata = {
        show: true,
        text: AppConstant.comments.resetbutton,
    };

    const submitbtnData = {
        show: true,
        text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm
            .saveButtonTitle,
    };

    const closeCurrentpopup = () => {
        setEditData({});
        setParticularFieldValue({});
        setShowModal(false);
    }

    const showCreateLeavePopup = () => {
        const popupObj = {
            id: 'createCompanyGroupFormPopup1',
            modalLabel: 'createCompanyGroupFormPopup1',
            showClose: true,
            showfooter: false,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title: !Object.keys(editData).length ? AppConstant.smartScheduler.leaves.addLeaveTitle : AppConstant.smartScheduler.leaves.editLeaveTitle,
            bodyText: createForm(),
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.comments.cancelCommentButtonTitle
                },
                btn2: {
                    show: true,
                    text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.saveButtonTitle
                }
            },
        }

        return <SSPopup {...popupObj} popupBtnHandler={(text) => () => ''} closepopup={closeCurrentpopup} themeSelected={themeSelected} showModal={showModal} />
    }

    const openCreateForm = () => {
        setEditData({});
        setParticularFieldValue({})
        setShowModal(true);
    }

    const showCustomDropDownForFilters = () => {
        const filterDataArr = [
            {
                name: 'Warehouse',
            },
            {
                name: 'Docks',
            }
        ]
        return (
            <div className='d-flex align-items-center'>
                {/* <div className='me-2 titleFiltersLeaveMeta'>{AppConstant.commonStrings.select} :</div> */}
                <CustomDropDownSS themeSelected={themeSelected} data={filterDataArr} placeHolder={currentFilterSelected.name} onSelect={(event) => setCurrentFilterSelected(event)} keyStr={'name'} />
            </div>
        )
    }
    const closePreCheckpopup = () => {
        setShowPreCheckModal(false)
    }

    const popupBtnHandler=(text)=>{
        if (text===AppConstant.commonStrings.viewDetailsStr) {
            navigate("/manageappointments")
        }
        else{
            setShowPreCheckModal(false)
        }
    }

    const showPreCheck = () => {
        const popupObj = {
            id: 'adminUpdateOperationPreCheck',
            modalLabel: 'adminUpdateOperationPreCheck',
            showClose: true,
            showfooter: true,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title:  AppConstant.smartScheduler.leaves.editLeaveTitle,
            bodyText: AppConstant.superUserAdmin.warehouseAdmin.preCheckBockerMsg,            
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.commonStrings.viewDetailsStr
                },
                btn2: {
                    show: false,
                }
            },
        }

        return <SSPopup {...popupObj} popupBtnHandler={(text) => {popupBtnHandler(text)} } closepopup={()=>{closePreCheckpopup()}} themeSelected={themeSelected} showModal={showPreCheckModal} />
    }

    return (
        <div>
            <div className='container-fluid d-flex py-2 text-start justify-content-between mt-1'>
                <button
                    className={'btn-highlighted f-12'}
                    data-bs-toggle="modal"
                    onClick={() => openCreateForm()}
                >
                    {AppConstant.smartScheduler.leaves.addLeaveTitle}
                </button>
                <div className='d-flex w-50 justify-content-end'>
                    {
                        showCustomDropDownForFilters()
                    }
                    <div className='ms-5'>
                        <WarehouseSearchSS
                            placeholder={'Search Facility Closures'}
                            inputValue={searchStr}
                            cancelSearch={() => setSearchStr('')}
                            onChangeHandler={(text) => setSearchStr(text)} />
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center metaLeavesList">
                <LeavesMetaListSS currentFilterSelected={currentFilterSelected} whObj={whObj} {...props} allowPermissionE={true} searchStr={searchStr} editLeaveAction={(groupObj) => editLeave(groupObj)} showPopup={() => showCreateLeavePopup()} themeSelected={themeSelected} allowPermissionD={allowPermissionD} />
                {showCreateLeavePopup()}
                {showPreCheck()}
            </div>
        </div>
    )
}

export default LeavesMetaDataFormSS;