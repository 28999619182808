import React, { useEffect, useState, useRef } from 'react';
import { AppConstant } from '../../../../../../Assests/AppConstant';
import { ReactComponent as CrossIcon } from '../../../../../../Assests/Svg/cross-icon-black.svg';
import { ReactComponent as CrossIconWhite } from '../../../../../../Assests/Svg/cross-icon-white.svg';
import WidgetsWrapper from '../../WidgetsWrapper/WidgetsWrapper';
import { useContainerDimensions } from '../../../../../Common/CustomHooks/useContainerDimensions';
import { getDockListSelector } from '../../../../../../../redux/reducers/reducerSlices/DockListReducer';
import { useSelector } from 'react-redux';
import _ from 'lodash-es'
import { useDispatch } from 'react-redux';
import { sagaActions } from '../../../../../../../sagas/sagaActions';
import { getDefaultDashboardSelector } from '../../../../../../../redux/reducers/reducerSlices/DefaultDashboardReducer';

const SectionColumn = (props) => {
    const { cEl, el, themeSelected, deleteSection, addChildren, widgetList, selectWidgetsItems, selectedWidgetsArr } = props;
    const dispatch = useDispatch();
    const getDockList = useSelector(getDockListSelector);
    const defaultDashboard = useSelector(getDefaultDashboardSelector);
    const { customDashboardDockSelectedList } = defaultDashboard;
    const { dockList } = getDockList;
    const [currentWidget, setCurrentWidget] = useState('');
    const componentRef = useRef();
    const { width, height } = useContainerDimensions(componentRef);

    useEffect(() => {
        showWidgets();
    }, [el.children.length])

    useEffect(() => {
        if (cEl && !_.isEmpty(cEl) && (cEl?.name === "Door Status" || cEl?.name === 'Door Usage Summary')) {
            showDockDropDown()
        }
    },[dockList])

    useEffect(() => {
        showSelectedWidget()
    }, [selectedWidgetsArr])

    const showSelectedWidget = () => {
       selectedWidgetsArr.forEach((widgetObj) => {
            if (widgetObj.parent === el.id && widgetObj.child === cEl.id) {
                setCurrentWidget(widgetObj)
            }
        })
    }

    // Function to filter and update the array
function filterAndUpdateData(arr, names, itemsToBeUpdated) {
    return _.map(arr, (item) => {
      if (names.includes(item.name)) {
        // If the name is in the list of names to update
        if (item.name === 'Door Status') {
          // Update id and status for name 'xyz'
          return _.assign({}, item, { dock_id: itemsToBeUpdated?.dock_id, dock_name: itemsToBeUpdated?.dock_name
             });
        } else if (item.name === 'Door Usage Summary') {
          // Update id and status for name 'abc'
          return _.assign({}, item, { dock_id: itemsToBeUpdated?.dock_id, dock_name: itemsToBeUpdated?.dock_name
            });
        }else if (item.name === 'Door Assignment') {
            // Update id and status for name 'abc'
            return _.assign({}, item, { dock_id: itemsToBeUpdated?.dock_id, dock_name: itemsToBeUpdated?.dock_name
              });
          }
      }
      return item; // Return unmodified items
    });
  }

    const onDockSelectHandler = (dockItem, name) => {
        let updatedData = [];

        const tempPayload = { ...dockItem, name: name }

        if (!_.size(customDashboardDockSelectedList)) {
            updatedData.push(tempPayload)
        } else {

            const checkGraphDockAlreadyExists = _.filter(customDashboardDockSelectedList, { name: name })
            if (!_.size(checkGraphDockAlreadyExists)) {
                updatedData = [...customDashboardDockSelectedList, tempPayload]
            } else {  
                updatedData = filterAndUpdateData(customDashboardDockSelectedList, [name], dockItem);
            }
        }
        dispatch({
            type: sagaActions.CUSTOM_DASHBOARD_DOCK_SELECTED, payload: updatedData
        })
        showDropDownvalue(tempPayload)
    }
    const showDropDownvalue = (val) => {
        if (!_.size(customDashboardDockSelectedList)) {
           return AppConstant.poManagement.poApproval.onlySelectDock
        } else {
            const filterDockIfExists = _.filter(customDashboardDockSelectedList, {name: val?.name})

            switch (val?.name) {
                case 'Door Status':
                    if (_.size(filterDockIfExists)) {
                        return filterDockIfExists[0]?.dock_name
                    } else {
                        if (val?.dock_name) {
                            return val?.dock_name
                        }
                    }
                case 'Door Usage Summary':
                    if (_.size(filterDockIfExists)) {                        
                        return filterDockIfExists[0]?.dock_name
                    }else {
                        if (val?.dock_name) {
                            return val?.dock_name
                        }
                    }
                    case 'Door Assignment':
                        if (_.size(filterDockIfExists)) {                        
                            return filterDockIfExists[0]?.dock_name
                        }else {
                            if (val?.dock_name) {
                                return val?.dock_name
                            }
                        }
            
                default:
                    return AppConstant.poManagement.poApproval.onlySelectDock;
            }
       }
    }

    const showDockDropDown = () => {
        if (cEl && !_.isEmpty(cEl) &&( cEl?.name === "Door Status" || cEl?.name === 'Door Usage Summary' || cEl?.name === 'Door Assignment')) {
            return (
                <>
                    <div className={`dropdown px-2`}>
                        <button className={`btn btn-secondary dropdown-toggle ${themeSelected}`} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {showDropDownvalue(cEl)}
                        </button>
                        <ul className="dropdown-menu">
                            {
                                dockList?.length ? dockList.map(dockItem => {
                                    return <li><a className="dropdown-item" href="#" onClick={() => onDockSelectHandler(dockItem, cEl?.name)}>{dockItem.dock_name}</a></li>
                                }) : ''
                            }

                        </ul>
                    </div>
                </>
            )
        }
    }

    const showSettings = () => {
        // Dropdown values needs to be fetched from API
        // Contain the logic into separate function
        return (<div className='align-self-end d-flex align-items-center closeSection p-3'>
            {el.children.length === 1 ? <span onClick={() => addChildren(el)}><i className="fa fa-thin fa-plus"></i></span> : ''}
            <>{showDockDropDown()}</>
            <>{createDropDown()}</>
            <span className='px-2' onClick={() => deleteSection(el, cEl)}>{themeSelected === 'dark-mode' ? <CrossIconWhite /> : <CrossIcon />}</span>
        </div>)
    }

    const showWidgets = () => {
        if (currentWidget) {
            return (
                <WidgetsWrapper
                    themeSelected={themeSelected}
                    selectedWidget={currentWidget}
                    height={height}
                    width={el.children.length === 1 ? window.innerWidth : width}
                />
            )
        }
    }

    const createDropDown = () => {
        return (
            <>
                <div className={`dropdown px-2`}>
                    <button className={`btn btn-secondary dropdown-toggle ${themeSelected}`} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {currentWidget.widget === '' ?  AppConstant.dashboard.customDashboard.selectGraphTitle : currentWidget.widget}
                    </button>
                    <ul className="dropdown-menu">
                        {
                            widgetList?.length ? widgetList.map(widgetItem => {
                                return <li><a className="dropdown-item" href="#" onClick={() => selectWidgetsItems(el, cEl, widgetItem.name)}>{widgetItem.name}</a></li>
                            }) : ''
                        }

                    </ul>
                </div>
            </>
        )
    }
    return (

        <div className={`position-relative customDashboardPlaceholder col mx-2 ${themeSelected} `} ref={componentRef} key={cEl.id} id={cEl.id} >
            <div className='position-absolute top-0 end-0'>
                {showSettings()}
            </div>
            {/* <div>{`${cEl.name} - ${el.order}`}</div> */}
                {showWidgets()}

        </div>

    );
}

export default SectionColumn;