import _ from 'lodash';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDefaultDashboardSelector } from '../../../../../../../redux/reducers/reducerSlices/DefaultDashboardReducer';
import { sagaActions } from '../../../../../../../sagas/sagaActions';
import { AppConstant } from '../../../../../../Assests/AppConstant';
import { createChartsProps, donutType, numberWithCommas } from '../../../../../../Assests/DashboardUtility';
import StatusOverviewDash from '../StatusOverviewDash/StatusOverviewDash';
import AppointmentCompliance from './Children/AppointmentCompliance/AppointmentCompliance';
import DetentionStatus from './Children/DetentionStatus/DetentionStatus';
import UnloadingHours from './Children/UnloadingHours/UnloadingHours';
import TaskItemsDue from '../TaskItemsDueComponent/TaskItemsDue';
import './DefaultDashboardWrapper.css';
import { getSelectedDateSelector } from '../../../../../../../redux/reducers/reducerSlices/DateSelectorReducer';
import { getFormattedDate, YYYY_MM_DD_FORMAT, convertGmtToDateRange, getCurrentDate } from '../../../../../../../Utils/DateUtils';
import { getSelectedDockFilterListSelector } from '../../../../../../../redux/reducers/reducerSlices/FiltersReducerSlice';
import { getDockListSelector } from '../../../../../../../redux/reducers/reducerSlices/DockListReducer';
import { createDocksIdArr } from '../../../POFilters/FilterUtilities';
import { unloadingHoursDonut, unloadingHoursLineTruck, unloadingHoursLinePallet, detentionLineData, detentionDonutdata, appointmentComplianceData } from '../../../../../../Assests/GraphUtilities';
import { getUserSelector } from '../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { usePrevious } from '../../../../../Common/CustomHooks/usePrevious';
import moment from 'moment';
import { closeUpdatesSocketConnection, initializeUpdatesSocketConnection } from '../../../../../../../Utils/updateSocket';

const DefaultDashboardWrapper = (props) => {
    const { themeSelected } = props;
    const [unloadingHoursData, setUnloadingHoursData] = useState({ ...props, unloadingHoursDonut, unloadingHoursLineTruck, unloadingHoursLinePallet })
    const [detentionStatusData, setDetentionStatusData] = useState({ ...props, detentionDonutdata, detentionLineData })
    const [appointmentComplianceState, setAppointmentComplianceState] = useState({ ...props, appointmentComplianceData })
    const defaultDashboard = useSelector(getDefaultDashboardSelector);
    const { unloadingHours, detentionStatus, appointmentCompliance, autoFetch, dateRangeObj } = defaultDashboard;
    const getSelectedDateSelectorObj = useSelector(getSelectedDateSelector);
    const selectedDockFiltersList = useSelector(getSelectedDockFilterListSelector);
    const getDockList = useSelector(getDockListSelector);
    const { dockList } = getDockList;
    const [dockIdArr, setDockIdArr] = useState([]);
    const { selectedDate } = getSelectedDateSelectorObj;
    const dispatch = useDispatch();
    const userPermission = useSelector(getUserSelector);
    const { selectedWarehouse } = userPermission;
    const previousDateRangeObj = usePrevious(dateRangeObj);

    useEffect(() => {
        setDockIdArr([]);
        if (selectedDockFiltersList.length) {
            setDockIdArr(createDocksIdArr(selectedDockFiltersList, dockList));
        }
    }, [selectedDockFiltersList])

    useEffect(() => {
        //Implemented realtime refresh
        // autoFetchAPI()
        dispatch({ type: sagaActions.RESET_FILTERS, payload: '' });
    }, [])

    useEffect(() => {
        const socketRef = initializeUpdatesSocketConnection(selectedWarehouse.ID, AppConstant.commonStrings.truckUpdateSocketUrl, onUpdateSocketReceived, onUpdateSocketError, onUpdateSocketClose)

        return () => closeUpdatesSocketConnection(socketRef, selectedWarehouse.ID)
    }, [])

    /**
     * Method is used to handle truck update and fetch latest truck data
     */
    const onUpdateSocketReceived = (message) => {
        let parsedMessage = JSON.parse(message)
        if (typeof parsedMessage === 'string') {
            parsedMessage = JSON.parse(parsedMessage)
        }
        if (!_.isEmpty(parsedMessage)) {
            const { type } = parsedMessage;
            switch (type) {
                case 'CHECK_IN_TIME':
                    fetchAnalyticsData();
                    break;
                case 'LUMP_START_TIME':
                    fetch_DASHBOARD_STATUS_TRUCK_LIST();
                    fetch_GET_TASK_ITEMS_DUE_LIST()
                    break;
                case 'LUMP_END_TIME':
                    fetch_DASHBOARD_STATUS_TRUCK_LIST();
                    fetch_GET_TASK_ITEMS_DUE_LIST()
                    break;
                case 'IN_DOOR_TIME':
                    fetch_DASHBOARD_STATUS_TRUCK_LIST();
                    fetch_GET_TASK_ITEMS_DUE_LIST()
                    break;
                // case 'CHECK_OUT_TIME':
                //     fetch_DASHBOARD_STATUS_TRUCK_LIST();
                //     fetch_GET_TASK_ITEMS_DUE_LIST();
                //     break;
                case 'BILLS_IN_TIME':
                    fetch_DASHBOARD_STATUS_TRUCK_LIST();
                    fetch_GET_TASK_ITEMS_DUE_LIST();
                    break;
                case 'BILLS_OUT_TIME':
                    fetchAnalyticsData();
                    break;
                case 'SCHEDULED_TIME':
                    fetchAnalyticsData();
                    break;
                default:
                    fetchAnalyticsData()
                    break;
            }
        }
        
        // fetchAnalyticsData()
    }

    /**
     * Method is used to captcure socket error and re initate the connection 
     * @param {*} error 
     */
    const onUpdateSocketError = (error) => {
        initializeUpdatesSocketConnection(selectedWarehouse.ID, AppConstant.commonStrings.truckUpdateSocketUrl, onUpdateSocketReceived, onUpdateSocketError, onUpdateSocketClose)
    }

    /**
     * Method is used to capture socket close
     * @param {*} data 
     */
    const onUpdateSocketClose = (data) => { }

    useEffect(() => {
        if (dockIdArr && dockIdArr.length) {
            // dispatch({
            //     type: sagaActions.GET_UNLOADING_HOURS, payload: {
            //         "date": getFormattedDate(YYYY_MM_DD_FORMAT, selectedDate), "dock_id": dockIdArr.toString() === '' ? null : dockIdArr.toString(), "warehouse_id": selectedWarehouse.ID,
            //         "isDrillDown": "0"
            //     }
            // });
            dispatch({
                type: sagaActions.GET_DETENSTION_STATUS, payload: { "date": getFormattedDate(YYYY_MM_DD_FORMAT, selectedDate), "dock_id": dockIdArr.toString() === '' ? null : dockIdArr.toString(), "warehouse_id": selectedWarehouse.ID, "isDrillDown": "0" }
            })
            dispatch({
                type: sagaActions.GET_APPOINTMENT_COMPLIANCE,
                payload: { "date": getFormattedDate(YYYY_MM_DD_FORMAT, selectedDate), "dock_id": dockIdArr.toString() === '' ? null : dockIdArr.toString(), "warehouse_id": selectedWarehouse.ID, "isDrillDown": "0" }
            })

            dispatch({
                type: sagaActions.GET_DASHBOARD_STATUS_TRUCK_LIST, payload: { "date": getFormattedDate(YYYY_MM_DD_FORMAT, selectedDate), "dock_id": dockIdArr.toString() === '' ? null : dockIdArr.toString(), "warehouse_id": selectedWarehouse.ID, "isDrillDown": "0" }
            })

            // dispatch({
            //     type: sagaActions.GET_DASHBOARD_STATUS_TRUCK_LIST, payload: { "date": getFormattedDate(YYYY_MM_DD_FORMAT, selectedDate), "dock_id": dockIdArr.toString() === '' ? null : dockIdArr.toString(), "warehouse_id": selectedWarehouse.ID, "isDrillDown": "0" }
            // })
            const d = new Date()
            const start = new Date(d.getFullYear(), d.getMonth(), 1)
            dispatch({
                type: sagaActions.UPDATE_FACILITY_RATINGS, payload: {
                    "warehouse_id": selectedWarehouse.ID,
                    "startDate": moment(start).format(AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
                    "endDate": getCurrentDate(),
                    "currentPage": 1,
                    "itemsPerPage": 10,
                    "rating": null,
                    "type": 'G'
                }
            })
        }
    }, [dockIdArr])

    useEffect(() => {
        createUnloadingHoursSection()
    }, [unloadingHours, detentionStatus, appointmentCompliance, themeSelected])

    useEffect(() => {
        if (autoFetch) {
            fetch_GET_DETENSTION_STATUS();
            fetch_GET_TASK_ITEMS_DUE_LIST();
            // autoFetchAPI()
        }
    }, [autoFetch])

    useEffect(() => {
        if (dateRangeObj === {} || previousDateRangeObj === undefined) {
            return
        } else if (dateRangeObj && dateRangeObj.start) {
            fetchAnalyticsData()
        }

    }, [dateRangeObj])

    const autoFetchAPI = () => {
        dispatch({
            type: sagaActions.GET_DETENSTION_STATUS,
            payload: {
                "date": getFormattedDate(YYYY_MM_DD_FORMAT, selectedDate),
                "dock_id": dockIdArr.toString() === '' ? null : dockIdArr.toString(),
                "warehouse_id": selectedWarehouse.ID,
                "isDrillDown": "0",
            }
        })

        dispatch({
            type: sagaActions.GET_TASK_ITEMS_DUE_LIST, payload: {
                "date": getFormattedDate(YYYY_MM_DD_FORMAT, selectedDate),
                "dock_id": dockIdArr.toString() === '' ? null : dockIdArr.toString(),
                "warehouse_id": selectedWarehouse.ID
            }
        });
    }

    /**
     * Method is used to fetch load status overview
     */

    const fetch_DASHBOARD_STATUS_TRUCK_LIST = () => {
        dispatch({
            type: sagaActions.GET_DASHBOARD_STATUS_TRUCK_LIST, payload: {
                "dock_id": dockIdArr.toString() === '' ? null : dockIdArr.toString(),
                "warehouse_id": selectedWarehouse.ID,
                "date": getFormattedDate(YYYY_MM_DD_FORMAT, selectedDate),
                "isDrillDown": "0",
            }
        });
    }
    /**
     * Method is used to fetch Task Item Due
     */
    const fetch_GET_TASK_ITEMS_DUE_LIST = () => {
        
        dispatch({
            type: sagaActions.GET_TASK_ITEMS_DUE_LIST, payload: {
                "date": getFormattedDate(YYYY_MM_DD_FORMAT, selectedDate),
                "dock_id": dockIdArr.toString() === '' ? null : dockIdArr.toString(),
                "warehouse_id": selectedWarehouse.ID
            }
        });
    }
     /**
     * Method is used to fetch Detention Status
     */
    const fetch_GET_DETENSTION_STATUS = () => {
        
        dispatch({
            type: sagaActions.GET_DETENSTION_STATUS,
            payload: {
                "date": getFormattedDate(YYYY_MM_DD_FORMAT, selectedDate),
                "dock_id": dockIdArr.toString() === '' ? null : dockIdArr.toString(),
                "warehouse_id": selectedWarehouse.ID,
                "isDrillDown": "0",
            }
        })
    }
     /**
     * Method is used to fetch Appointment Compliance
     */
    const fetch_GET_APPOINTMENT_COMPLIANCE = () => {
        
        dispatch({
            type: sagaActions.GET_APPOINTMENT_COMPLIANCE,
            payload: {
                "date": getFormattedDate(YYYY_MM_DD_FORMAT, selectedDate),
                "dock_id": dockIdArr.toString() === '' ? null : dockIdArr.toString(),
                "warehouse_id": selectedWarehouse.ID,
                "isDrillDown": "0",
            }
        })
    }
   /**
     * Method is used to fetch All Default analytics data at once
     */
    const fetchAnalyticsData = () => {
    
        fetch_DASHBOARD_STATUS_TRUCK_LIST()
        fetch_GET_TASK_ITEMS_DUE_LIST()
        fetch_GET_DETENSTION_STATUS()
        fetch_GET_APPOINTMENT_COMPLIANCE()
       
       
        
        //Implemented realtime refresh
        // dispatch({ type: sagaActions.INTIATE_AUTO_FETCH, payload: false })
    }

    const createUnloadingHoursSection = (stop) => {
        if (!_.isEmpty(unloadingHours)) {
            const { truck_details, pallet_details, arrowSign, perHrPalletsCount, perHrTruckCount } = unloadingHours;
            const donutData = createChartsProps([_.sum(truck_details), _.sum(pallet_details)], donutType.unloadingHoursdonut, themeSelected, unloadingHoursDonut)
            const lineDataTruck = createChartsProps(truck_details, donutType.unloadingHoursTruck, themeSelected, unloadingHoursLineTruck)
            const lineDataPallet = createChartsProps(pallet_details, donutType.unloadingHoursPallet, themeSelected, unloadingHoursLinePallet)
            const newUnload = { ...props, donutData, lineDataTruck, lineDataPallet, arrowSign, perHrPalletsCount, perHrTruckCount }
            setUnloadingHoursData(newUnload)
        }
        if (!_.isEmpty(detentionStatus)) {
            const datadonut = detentionStatus.map(el => _.sum(el.data))
            const detentionDonut = createChartsProps(datadonut, donutType.detentionStatusDonut, themeSelected, detentionDonutdata)
            const detentionLine = createChartsProps(detentionStatus, donutType.detentionStatusLine, themeSelected, detentionLineData)
            let newdetentionStatusData = { ...props, detentionDonut, detentionLine }
            setDetentionStatusData(newdetentionStatusData)
        }
        if (_.size(appointmentCompliance)) {
            // let { appointmentComplianceChart } = appointmentComplianceState;
            if (appointmentComplianceData) {
                const appointmentComplianceChart = createChartsProps(appointmentCompliance, donutType.appointmentCompliance, themeSelected, appointmentComplianceData)
                let setData = { ...props, appointmentComplianceChart };
                setData.themeSelected = themeSelected
                setAppointmentComplianceState(setData)
            }
        }
    }
    return (
        <div className={`container-fluid dashboard defaultDashboard ${themeSelected} m-0 p-0`}>
            <div className={`d-flex flex-row mb-3 mt-3 ms-0 me-0 ps-0 pe-0 ${themeSelected}`}>
                <StatusOverviewDash themeSelected={props.themeSelected} />
                <TaskItemsDue themeSelected={props.themeSelected} />
            </div>
            {/* commented as per requirement wp-892. Need to redesigned  */}
            {/* <div className={`row mt-3 unloadingHoursWrapper ${themeSelected}`}>
                <UnloadingHours {...unloadingHoursData} />
            </div> */}
            <div className={`d-flex flex-row flex-wrap mb-3 mt-3 ms-0 me-0 ps-0 pe-0 ${themeSelected}`}>
                <DetentionStatus {...detentionStatusData} />
                <AppointmentCompliance {...appointmentComplianceState} />
            </div>
        </div>
    )
}

export default DefaultDashboardWrapper;