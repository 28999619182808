import {Uchain} from "../redux/api/agent";
import { call, put, takeEvery } from 'redux-saga/effects';
import { sagaActions } from './sagaActions';
import { addSuccess, addFailure,initiateLoading,resetData } from '../redux/reducers/reducerSlices/CompanyGroupReducer';
  function* addCompanyGroupRequestHandler(data){
    try{
     
      yield put(initiateLoading());
     const response = yield call(Uchain.companyGroupData, data.payload);
      yield put(addSuccess(response));
    }catch(error){
     yield put(addFailure(error));
    }
}
export function* CompanyGroupRequest() {
  yield takeEvery(sagaActions.COMPANY_GROUP, addCompanyGroupRequestHandler)
}

function* resetFormHandler() {
 yield put(resetData())
 
}

export function* resetCompanyGroupFormRequest() {
    yield takeEvery(sagaActions.RESET_COMPANY_GROUP, resetFormHandler)
  }
  

