import React from 'react';
import { dateAndTimeUTCFormat } from '../../../../../../../../../Assests/Utility';
import CustomTooltip from '../../../../../../../../Common/Tooltip/CustomTooltip'
import { AppConstant } from '../../../../../../../../../Assests/AppConstant';
import { Link, useNavigate } from 'react-router-dom';
import _ from 'lodash-es';

const Top10LateLoadsRow = (props) => {
    const { load, index, themeSelected, top10LoadSelection } = props;
    const navigate = useNavigate();

    const lateLoadInfo = () => {
        if (load.AlwaysLate === 1) {
            return {lateStatus : AppConstant.dashboard.defaultdashboard.top10LateLoads.always,
                toolTipInfo : `${AppConstant.dashboard.defaultdashboard.top10LateLoads.lateToolTipParentText} : ${AppConstant.dashboard.defaultdashboard.top10LateLoads.alwaysLateTooltip}`}
        } else if (load.SomeTimesLate === 1) {
            return {lateStatus : AppConstant.dashboard.defaultdashboard.top10LateLoads.sometimes,
                toolTipInfo : `${AppConstant.dashboard.defaultdashboard.top10LateLoads.lateToolTipParentText} : ${AppConstant.dashboard.defaultdashboard.top10LateLoads.sometimesLateTooltip}`}
        } else {
            return {lateStatus : AppConstant.dashboard.defaultdashboard.top10LateLoads.firstTime,
                toolTipInfo : `${AppConstant.dashboard.defaultdashboard.top10LateLoads.lateToolTipParentText} : ${AppConstant.dashboard.defaultdashboard.top10LateLoads.firstTiemLateTooltip}`}
        }
    }

    const setTheme = () => {
        if (load.AlwaysLate === 1) {
            return 'alwaysLate subText'
        } else if (load.SomeTimesLate === 1) {
            return 'sometimesLate subText'
        } else {
            return 'firstTimeLate subText'
        }
    }
    const rowDataClickHandler = () => {
        if (top10LoadSelection === "VENDOR") {
            navigate(`/analytics/reports/toplateloads/${_.lowerCase(top10LoadSelection)}/${load.VendorId}`)
        }else if (top10LoadSelection === "CARRIER") {
            navigate(`/analytics/reports/toplateloads/${_.lowerCase(top10LoadSelection)}/${load.CarrierId}`)
        }
    }
    return (<>
        <tr className='text-center' onClick={rowDataClickHandler} style={{cursor: 'pointer'}}>
            <td scope="col">
                {index + 1}
            </td>
            <td scope="col">{top10LoadSelection === 'VENDOR' ? load.Vendor : load.CarrierName}</td>
            {/* Asked by Rajesh to comment - 10/13/2023 - EOD Call */}
            {/* <td scope="col">
                {load.Appointment ?
                    <div className="d-flex flex-column">
                        <span >
                            {dateAndTimeUTCFormat(load.Appointment).date}
                        </span>
                        <span >
                            {dateAndTimeUTCFormat(load.Appointment).time}
                        </span>
                    </div>
                    : ''}
            </td>
            <td scope="col" >{load.CheckedIn ?
                <div className="d-flex flex-column">
                    <span >
                        {dateAndTimeUTCFormat(load.CheckedIn).date}
                    </span>
                    <span >
                        {dateAndTimeUTCFormat(load.CheckedIn).time}
                    </span>
                </div> : ''
            }</td> */}
            <td scope="col">
                <div data-tip={`${lateLoadInfo().toolTipInfo}`} data-for={`${lateLoadInfo().lateStatus}_${index}`}>
                    <div className={`${setTheme()}`} id={`${lateLoadInfo().lateStatus}_${index}`}>
                        {lateLoadInfo().lateStatus}
                    </div>
                    <CustomTooltip
                        id={`${lateLoadInfo().lateStatus}_${index}`}
                        position='top'
                        type={themeSelected === AppConstant.commonStrings.lightModeFilterString ? 'light' : 'dark'}
                        multiline={false} />
                </div>

            </td>
        </tr>
        </>
    )
}

export default Top10LateLoadsRow;