import React from 'react'
import Seperator from '../../../Common/Seperator/Seperator';
import FeedbackAndHelp from '../../components/FeedbackAndHelp';
import ForgotPassword from '../../components/ForgotPassword';
import CustomButton from '../../../Common/CustomButton/CustomButton';
import RememberMe from '../../components/RememberMe';
import PasswordInput from '../../../Common/PasswordInput/PasswordInput';
import InputWithTtile from '../../../Common/CustomInput/InputWithTtile';
import { AppConstant } from '../../../../Assests/AppConstant';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

// This component renders IMS login screen - OLD
const LoginIMS = (props) => {
    const { emailError, loginError, email, onEmailChange, initiateSignIn, getSignInLoading, setPassword, password, isRememberMeChecked, bgImage, backgroundImage, isUserRemembered, warehouseLogo,branding } = props;
    const navigate = useNavigate();
    return (  
        <div className='d-flex justify-content-start align-items-center bgContainer px-0 mx-0'>
                <img className={'bgimage'} src={bgImage ? bgImage : backgroundImage} alt='Background Image' />
                <div className='d-flex parentContainer justify-content-center align-items-center'>
                    <div className='d-flex flex-column loginContainer justify-content-center align-items-center'>
                        <div data-testid={AppConstant.dataTestId.logoContainer}
                            className='d-flex justify-content-center align-items-center logoContainer'>
                            <img className="login-logo me-2" src={warehouseLogo ? warehouseLogo : AppConstant.commonStrings.logo_small_wordMark} />
                            {/* <img className="login-logo" src={AppConstant.commonStrings.schedulerProLogo} /> */}
                        </div>
                        {/* <span data-testid={AppConstant.dataTestId.title}>
                            {formatFirstCharBold(AppConstant.login.loginTitle)}
                        </span> */}
                        <div className='emailDiv'>
                            <InputWithTtile
                                errorMessage={AppConstant.login.emailIncorrect}
                                showError={emailError}
                                value={email ? email : ''}
                                title={AppConstant.login.email} placeHolder={AppConstant.login.enterEmail}
                                onEmailChange={onEmailChange} />
                        </div>
                        <div className='passwordDiv'>
                            <PasswordInput
                                errorMessage={AppConstant.login.passwordIncorrect}
                                showError={loginError}
                                value={password ? password : ''}
                                title={AppConstant.login.password} placeHolder={AppConstant.login.enterPassword}
                                onPasswordChange={setPassword} />
                        </div>
                        <div className='rememberMeContainer'>
                            <RememberMe isChecked={isUserRemembered} onChangeText={(e) => isRememberMeChecked(e)} />
                        </div>
                        <div className='d-flex justify-content-end loginButtonContainer'>
                            <CustomButton
                                isLoading={getSignInLoading}
                                title={AppConstant.login.loginButtonTitle} className={"loginButton"}
                                onClick={() => initiateSignIn()} />
                        </div>
                        <Seperator seperatorStyles={
                            { marginTop: "8%", width: "90%", height: "1px", backgroundColor: "#7E7E7E" }} />
                        <div className='d-flex flex-row justify-content-between align-items-center bottomContainer'>
                            <ForgotPassword />
                            <FeedbackAndHelp />
                        </div>
                         {!_.isEmpty(branding) && (branding?.Subdomain?.includes('schedulepro'))&&
                         <>
                       <Seperator seperatorStyles={{ marginTop: "4%", width: "90%", height: "1px", backgroundColor: "#7E7E7E" }} /><div className='d-flex flex-row justify-content-between align-items-center bottomContainer'>
                            <div className='forgotPasswordText cursor-pointer' onClick={() => navigate("/carriersignup")}>{AppConstant.login.CarrierRegistration}</div>
                        </div>
                        </>
                        }
                    </div>
                </div>
            </div >
    );
}
 
export default LoginIMS;