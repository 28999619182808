import React from 'react';
import { useLocation } from 'react-router-dom';
import { AppConstant } from '../../../../Assests/AppConstant';
import Header from '../../../Header/Header';
import Navigationbar from '../../../Navigationbar/Navigationbar';
import PODockStatusFilter from '../../Components/POFilters/PODockStatusFilter/PODockStatusFilter';
import POListFilters from '../../Components/POFilters/POListFilters/POListFilters';
import TruckSection from '../../Components/TruckSection/TruckSection';

import './LoadManagement.css';

const SPLoadManagement = (props) => {
    const {themeSelected} = props
    const location = useLocation();

    return (
        <div className='container-fluid executor'>
            <Header {...props} />
            <Navigationbar {...props} />
            <PODockStatusFilter themeSelected={themeSelected} />
            <POListFilters themeSelected={themeSelected} />

            <TruckSection themeSelected={themeSelected} type={ 'IMSSP'} />
        </div>
    )
}

export default SPLoadManagement;