import React from 'react';
import moment from 'moment';
import 'moment-timezone';
import _ from 'lodash';
import { dateAndTimeLocal, dateAndTimeUTCFormat } from '../../../../../../../Assests/Utility';
import { useState } from 'react';
import Loader from '../../../../../../Loader/Loader';
import { } from 'date-fns'
import './DrilldownTableSS.css'
import NoDataSS from './NodataSS';
import { AppConstant } from '../../../../../../../Assests/AppConstant';

const DrilldownTableBodySS = (props) => {
    const { themeSelected, data, showCollapse, showInput, initiateDrillDownReport, loaderArray, sortKey, showUTCTime, collapsableData, hasCollapsableContent, accordionPrimaryIndex } = props;
    const [collapseIcon, setCollpaseIcon] = useState('down')
    const [accordion, setAccordion] = useState("");
    const loaderArrayRow = [0, 1, 2, 3, 4, 5, 6, 7, 8];
    let loaderArrayColumn = [...Array(loaderArray).keys()]

    const toggleAccordion = (data) => {

        if (accordion === data) {
            setAccordion("")

        }
        else {
            setAccordion(data);

        }
    }


    const createRow = () => {
        return data.map((el, i) => {


            return (
                <tr className=" text-center align-middle" data-bs-toggle="collapse" href={`#`} key={el.id}>

                    {showCollapse && <th scope="col" className={`col px-0 ${themeSelected}`}><i className={`fa fa-thin fa-angle-${collapseIcon} collapseicon ${themeSelected}`}></i></th>}
                    {
                        displayRowData(el)
                    }
                </tr>
            )

        })

    }

    const setDrillDownTime = (apiTime) => {
        if (showUTCTime) {
            //showing only UTC
            return dateAndTimeUTCFormat(apiTime)
        } else {
            //converting to local
            let m = moment.tz(apiTime, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]', "America/Denver");
            m.tz(moment.tz.guess()).format();
            return dateAndTimeLocal(m.toString())
        }
    }

    const displayRowData = (data, type, index) => {

        let getKeys = Object.keys(data);

        if (!sortKey) {
            getKeys = _.sortBy(getKeys, item => isNaN(item) ? item : Number(item));
        }

        return getKeys.map((key, i) => {
            if (key !== 'id') {
                const utc = moment(data[key], 'YYYY-MM-DDTHH:mm:ss.SSS[Z]', true);
                const isUTC = utc.isValid();
                if (isUTC) {

                    const defaultObj = {
                        date: '-',
                        time: '-'
                    }

                    const time_formated = isUTC ? moment(data[key]).utc().format(AppConstant.commonStrings.MM_DD_YYYY_dateFormat) : defaultObj
                    return <td className={`col-md-auto f-12 ${themeSelected}`} key={i}>
                        <div className="d-flex flex-column">
                            <span className=''>
                                {moment(data[key]).utc().format(AppConstant.commonStrings.MM_DD_YYYY_dateFormat) || '--'}
                            </span>
                            <span className=''>
                                {moment(data[key]).utc().format(AppConstant.commonStrings.HH_mm_dateFormat) || '--'}
                            </span>
                        </div>
                    </td>
                } else {
                    if (type === 'child' && key == 0) {
                        return <td key={i} className={`col-md-auto ${<i class="fa-solid fa-sort"></i>} ${themeSelected}`}></td>
                    }
                    else if (type === 'parent' && key == 0) {
                        return <td key={i} className={`col-md-auto f-12 ${themeSelected}`}>{index + 1}</td>
                    }
                    else {
                        return <td key={i} className={`col-md-auto f-12 ${themeSelected}`}>{data[key]}</td>
                    }
                }
            }
        })
    }

    const showLoader = () => {
        return loaderArrayRow.map((_, i) => {
            return (
                <tr key={i}>

                    {
                        loaderArrayColumn.map((__, j) => {
                            return (
                                <td key={j}>
                                    <Loader height={35} count={1} highlightColor={themeSelected === 'dark-mode' ? '#151515' : ''}
                                        baseColor={themeSelected === 'dark-mode' ? '#575757'
                                            : ''}
                                    />
                                </td>
                            )

                        })
                    }

                </tr>
            )
        })
    }

    const createAccordion = () => {
        return data.map((element, index) => {
            return [element[0]].map((ele) => {
                return (
                    <>
                        <tr className="text-center align-middle" data-bs-toggle="collapse" href={`#`} key={ele.id}>
                            {displayRowData(ele, 'parent', index)}
                            {element.length > 1 ? <td scope="col" className={`col px-0 f-12 ${themeSelected} width-50px font-default`} onClick={() => toggleAccordion(ele[accordionPrimaryIndex])}><i className={ele[accordionPrimaryIndex] === accordion ? "fa fa-chevron-up text-dark" : "fa fa-chevron-down text-dark"}></i></td> : <td className='width-50px'>-</td>}
                        </tr>
                        {ele[accordionPrimaryIndex] === accordion && element.slice(1, element.length).map((el) => {
                            return (
                                <tr className="accordion-toggle text-center align-middle accordion-child " data-bs-toggle="collapse" href={`#`} key={el.id}>
                                    {
                                        displayRowData(el, 'child')
                                    }
                                    <td scope="col" className={`col px-0 f-12 ${themeSelected} `}></td>
                                </tr>
                            )
                        })
                        }
                    </>
                )
            })
        })

    }

    return (
        <tbody className='SS-drilldown-body'>
            {
                !initiateDrillDownReport ? data.length ? !hasCollapsableContent ? createRow() :
                    hasCollapsableContent &&
                    createAccordion() :
                    <NoDataSS themeSelected={themeSelected} /> : showLoader()
            }
        </tbody>
    );
}

export default DrilldownTableBodySS;