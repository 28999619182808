import React from "react";
import DatePicker from "react-datepicker";
import { getFormattedDate } from '../../../../../Utils/DateUtils'
import SchedulerDateInput from '../SchedulerDateInput/SchedulerDateInput';

const SchedulerDatePicker = (props) => {
    const { themeSelected, disabledProps, maxDateProps, minDateProps, datePickerSelectedDate,
        selectedDate, onDateChange, startDateProps, endDateProps, selectsRangeProps, classNameProps, selectedDateFormat } = props;

    return (
        <div className={`${classNameProps}`}>
            {
                <DatePicker
                    disabled={disabledProps}
                    maxDate={maxDateProps}
                    minDate={minDateProps}
                    calendarClassName={`datepicker ${themeSelected}`}
                    customInput={<SchedulerDateInput selectedDate={selectedDate} selectedDateFormat={selectedDateFormat}/>}
                    selected={selectedDate}
                    onChange={onDateChange}
                    startDate={startDateProps}
                    endDate={endDateProps}
                    selectsRange={selectsRangeProps}
                />
            }
        </div>
    )
}

export default SchedulerDatePicker