import React, { useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import { getCurrentDate } from '../../../Utils/DateUtils';
import "./DatePicker.css"
import "react-datepicker/dist/react-datepicker.css";
import _ from 'lodash';
import { AppConstant } from '../../Assests/AppConstant';
import { getFormattedDate } from "../../../Utils/DateUtils"
import CalenderIcon from '../../Assests/Svg/CalenderIcon';
import { sagaActions } from '../../../sagas/sagaActions';
import { useDispatch } from 'react-redux';


const SimpleDatePicker = (props) => {
    const { themeSelected } = props
    const [startDate, setStartDate] = useState(getCurrentDate());
    const [endDate, setEndDate] = useState('');
    const dispatch = useDispatch()

    /**
     * Method is used to capture date change
     * @param {*} dates 
     */
    const onDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        const dateRangeObjTemp = {
            start: start,
            end: end
        }
        dispatch({ type: sagaActions.UPDATE_DATE_RANGE, payload: dateRangeObjTemp })
    }

    /**
     * Useeffect is used to clear date selection on unload
     */
    useEffect(() => {
        window.addEventListener("beforeunload", clearDateSelection);
        return () => {
            window.removeEventListener("beforeunload", clearDateSelection);
        };
    }, []);

    /**
     * Method is used to clear date selection and assing current date
     */
    const clearDateSelection = (e) => {
        e.preventDefault();
        const dateRangeObjTemp = {
            'start': getCurrentDate(),
            'end': getCurrentDate()
        }
        dispatch({ type: sagaActions.UPDATE_DATE_RANGE, payload: dateRangeObjTemp })
    }

    const DateInput = ({ onClick, startDate, endDate }, ref) => {
        return <button onClick={onClick}
            className="d-flex flex-row justify-content-center align-items-center customInput border-1">
            {
                <span data-testid={AppConstant.dataTestId.dayLabel}
                    className={`dayText ${themeSelected}`}>
                    {
                        !endDate
                            ? getFormattedDate("ddd, MMM DD", startDate)
                            : `${getFormattedDate("ddd, MMM DD", startDate)} to ${getFormattedDate("ddd, MMM DD", endDate)}`
                    }
                </span>
            }

            <div className="calenderContainer">
                <CalenderIcon themeSelected={themeSelected} />
            </div>
        </button>
    }
    return (
        <div className='p-1 border border-3 rounded'>
            {
                <DatePicker
                    maxDate={getCurrentDate()}
                    // minDate={getCalenderLimit()}
                    calendarClassName={`datepicker ${themeSelected}`}
                    customInput={<DateInput {...props} startDate={startDate} endDate={endDate} />}
                    selected={startDate}
                    onChange={onDateChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                />
            }
        </div>
    )
}

export default SimpleDatePicker
