import _ from 'lodash';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DateSelectorReducer, { getSelectedDateSelector } from '../../../../../../../../../redux/reducers/reducerSlices/DateSelectorReducer';
import { getDefaultDashboardSelector } from '../../../../../../../../../redux/reducers/reducerSlices/DefaultDashboardReducer';
import { getUserSelector } from '../../../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { sagaActions } from '../../../../../../../../../sagas/sagaActions';
import { getFormattedDate, YYYY_MM_DD_FORMAT } from '../../../../../../../../../Utils/DateUtils';
import { AppConstant } from '../../../../../../../../Assests/AppConstant';
import { modifyPODisplay } from '../../../../../../../../Assests/Utility';
import DrilldownTable from '../../Common/DrilldownTable';
import { getRemoteConfigSelector } from '../../../../../../../../../redux/reducers/reducerSlices/RemoteConfigReducer';
import { CustomPaginationSS } from '../../../../../../../Common/CustomPaginationSS/CustomPaginationSS';

const DrillDownAppointmentCompliance = (props) => {
    const { themeSelected, loaderArray, subRoot, dockIdArr } = props;
    const dispatch = useDispatch();
    const [drilldownData, setDrilldownData] = useState([]);
    const defaultDashboard = useSelector(getDefaultDashboardSelector);
    const { appointmentCompliancesDrillDownReportResponse, arrangeOrder, arrangeKey, initiateDrillDownReport, dateRangeObj } = defaultDashboard;
    const getSelectedDateSelectorObj = useSelector(getSelectedDateSelector);
    const { selectedDate } = getSelectedDateSelectorObj;

    const userPermission = useSelector(getUserSelector);
    const { selectedWarehouse } = userPermission;
    //Get Configration from store
    const remoteConfig=useSelector(getRemoteConfigSelector);
    const {allConfigration}=remoteConfig;
    const {PaginationDefaultLimit}=allConfigration
    let appointmentComplianceTitleDD = [
        { id: 'PoNum', name: 'PO' },
        { id: 'CarrierName', name: 'Carrier' },
        { id: 'VendorName', name: 'Vendor' },
        { id: 'Appointment', name: 'Appt Time' },
        { id: 'CheckedIn', name: 'Checked in' },
        { id: 'Compliance', name: 'Compliance (hr)' }
    ]
    const createPayload = (pageNo,limit) => {
        return { date: getFormattedDate(YYYY_MM_DD_FORMAT, selectedDate), dock_id: dockIdArr.toString() === '' ? null : dockIdArr.toString(), isDrillDown: '1', warehouse_id: selectedWarehouse.ID, drillDownType: subRoot.subPathName ,pageNo,limit }
    }
    // useEffect(() => {  
    //     debugger
    //     dispatch({ type: sagaActions.CLEAR_APPOINTMENT_COMPLIANCE_DRILLDOWN, payload: null })
    //     dispatch({ type: sagaActions.INITIATE_DRILLDOWN_REPORT, payload: true })
    //     dispatch({
    //         type: sagaActions.GET_APPOINTMENT_COMPLIANCE_DRILLDOWN, payload: createPayload()
    //     });
    // }, [])

    useEffect(() => {
        fetchApptComp()
    }, [dateRangeObj])

    //Call API for Appointment Compliances
    const fetchApptComp=(pageNo=1,limit=PaginationDefaultLimit)=>{
        dispatch({ type: sagaActions.INITIATE_DRILLDOWN_REPORT, payload: true })
        dispatch({
            type: sagaActions.GET_APPOINTMENT_COMPLIANCE_DRILLDOWN, payload: createPayload(pageNo,limit)
        });
    }

    useEffect(() => {
        setDrilldownData([])
        if (appointmentCompliancesDrillDownReportResponse && appointmentCompliancesDrillDownReportResponse?.data?.length) {
            let data = formatDrillDownData(appointmentCompliancesDrillDownReportResponse?.data)
            setTimeout(() => {
            setDrilldownData(data)
            }, 10)
        }
    }, [appointmentCompliancesDrillDownReportResponse?.data])

    const formatDrillDownData = (data) => {
        return data.map(el => {
            let splitArray = []
            if (el.PoNum) {
                splitArray = el.PoNum.split(',')
            }
            return {
                id: el.Id,
                0: modifyPODisplay(splitArray) || '-',
                1: el.CarrierName || '-',
                2: el.VendorName || '-',
                3: el.Appointment || '-',
                4: el.CheckedIn || '-',
                5: el.Compliance || '-',
            }
        })
    }
    const pageChange=(pageNo,limit)=>{
        fetchApptComp(pageNo,limit)
    }

    return (
        <>
        <DrilldownTable
            key={Math.random()}
            themeSelected={themeSelected}
            bodyData={drilldownData}
            titleData={appointmentComplianceTitleDD}
            showCollapse={false}
            showInput={true}
            showSort={true}
            arrangeOrder={arrangeOrder}
            arrangeKey={arrangeKey}
            drillDownReport={appointmentCompliancesDrillDownReportResponse?.data}
            initiateDrillDownReport={initiateDrillDownReport}
            loaderArray={6}
            subRoot={subRoot}
            sortingAction={sagaActions.SORT_DRILLDOWN_REPORT}
            showUTCTime={true}
        />
        {!_.isEmpty(appointmentCompliancesDrillDownReportResponse) && appointmentCompliancesDrillDownReportResponse?.data?.length ?
            <div className='f-14 detention-status-pagination'>
                <CustomPaginationSS
                pageNo={appointmentCompliancesDrillDownReportResponse.pageNo}
                pageSize={appointmentCompliancesDrillDownReportResponse.limit}
                first={appointmentCompliancesDrillDownReportResponse.first}
                last={appointmentCompliancesDrillDownReportResponse.last}
                totalElements={appointmentCompliancesDrillDownReportResponse.totalElements}
                totalPages={appointmentCompliancesDrillDownReportResponse.totalPages}
                pageChange={(pageNo,limit)=>{pageChange(pageNo,limit)}}
            />
        </div>:""
            }
            </>
    );
}

export default DrillDownAppointmentCompliance;