// import { Auth } from 'aws-amplify';
import { call, put, takeEvery, select, takeLatest, takeLeading } from 'redux-saga/effects';
import { AppConstant } from '../App/Assests/AppConstant';
import { modifyTruckData } from '../App/Assests/Utility';
import { convertGmtToDateRange } from '../Utils/DateUtils'
import { Uchain } from '../redux/api/agent';
import {
    initiateGetlist, getListSuccess, getListFailure, updateMainTruckList, getUpdateListSuccess, autoFetchPoListSuccess, updateTruckListSortOrder
} from '../redux/reducers/reducerSlices/getTruckReducer';
import { getFormattedDate, YYYY_MM_DD_FORMAT } from '../Utils/DateUtils';
import { sagaActions } from './sagaActions';

function* getTruckData() {

    const state = yield select();

    const dateRangeObj = state.defaultDashboardReducer.dateRangeObj;
    const start = convertGmtToDateRange(dateRangeObj).start;
    const end = convertGmtToDateRange(dateRangeObj).end;

    const data = {

        "warehouse_id": state.userReducer.selectedWarehouse.ID,

        "dock_id": null,

        "carrier": null,

        "vendor": null,

        "is_task_item": state.defaultDashboardReducer.selectedTaskItem,

        "fromDate": start !== "Invalid date" ? start : null,
        
        "toDate": end !== "Invalid date" ? end : null,

    }

    try {
        const { arrangeOrder, arrangeKey } = state.getTruckReducer
        const arrange = {
            order: arrangeOrder,
            key: arrangeKey
        }
        // console.log('AdminConfirmSignUp', process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID)
        // const result1 =  Auth.ConfirmSignUp({
        //     "Username": "ayadav@uchaininc.com",
        //     "UserPoolId": process.env.REACT_APP_AWS_USER_POOLS_ID
        // })
        // console.log("🚀 ~ file: getTruckSaga.js:48 ~ function*getTruckData ~ result1", result1)
        const response = yield call(Uchain.getTruck, data);
        let modifyTruckList = modifyTruckData(response, arrange);
        const newResponse = {
            truckList: modifyTruckList,
            truckListMasterData: modifyTruckList
        }
        yield put(updateMainTruckList(newResponse));
        yield put(getListSuccess(newResponse));

    } catch (error) {
        /* Auto Fetch PO-List - if Auto Fetch Fails then it shoud not clear data already on screen*/
        const autoFetchPolist = state.getTruckReducer.autoFetchPolist
        if (!autoFetchPolist) {
            yield put(getListFailure());
        }
    }
}


/* function* loadMoreTruckList() {
    try {
        const newData = poData;
        newData.currentPage = newData.currentPage + 1
        newData.poList = [...newData.truckList, ...newData.truckList]
        const response = yield call(data);
        yield put(getListSuccess(poData));

    } catch (error) {
        //   yield put(clearList());
    }
}
 */
function* initateLoader() {
    try {
        yield put(initiateGetlist())
    } catch (error) {
        yield put(getListFailure());

    }
}

function* updateTruck(data) {
    try {
        const newResponse = {
            truckList: data.payload,
        }
        yield put(getUpdateListSuccess(newResponse));
    } catch (error) {
        yield put(getListFailure());
    }
}

function* autoFetchPoListHandler(data) {
    try {
        yield put(autoFetchPoListSuccess(data.payload));
    } catch (error) {
        yield put(autoFetchPoListSuccess(false));
    }
}

function* sortPoListHandler(data) {
    try {
        yield put(updateTruckListSortOrder(data.payload));
    } catch (error) {
    }
}

export function* initiateTruckLoader() {
    yield takeLatest(sagaActions.INTAITE_GET_TRUCK, initateLoader)
}

export function* getTruck() {
    yield takeLatest(sagaActions.FETCH_TRUCK_LIST, getTruckData)
}

export function* updateTruckList() {

    yield takeLatest(sagaActions.UPDATE_TRUCK, updateTruck)
}

export function* autoFetchPoList() {
    yield takeLatest(sagaActions.AUTO_FETCH_PO_LIST, autoFetchPoListHandler)
}

export function* sortPoList() {
    yield takeLatest(sagaActions.SORT_TRUCK_LIST, sortPoListHandler)
}

/* export function* loadMoreTruck() {
    yield takeEvery(sagaActions.LOAD_MORE_TRUCK_LIST, loadMoreTruckList)
} */