
import React, { useEffect, useState } from 'react'
import { AppConstant } from '../../../Assests/AppConstant'
import './InputWithTtileSS.css'
/**
 * This is a reusable component to render input HTML based on type with new UI
 * @param {*} props 
 * title = title for input element
 * placeHolder = placeholder text
 * showError = 
 * errorMessage
 * value = value for HTML input element
 * name = re
 * required = if element is required
 * disabled = if element is disabled
 * inputType = type of input element
 * superScriptText = subtext
 * titleToolTipText = Tooltip for element title
 * toolTipId = tooltip ID
 * type = ?
 * onEmailChange = ?
 * customClass = user defined class
 * titleIcon = 
 * titleIconClass = user defined icon class
 * @returns 
 */
const InputWithTtileSS = (props) => {
    const { title, placeHolder, showError, errorMessage, value, name, required, disabled, inputType, superScriptText, titleToolTipText, toolTipId, type, onEmailChange, customClass, titleIcon, titleIconClass } = props
    const [error, seterror] = useState(showError)

    /**
     * useEffect for error
     */
    useEffect(() => {
        seterror(showError)
    }, [showError])
    /**
     * function for input class if error
     */
    const getInputClass = () => error ? `customTextInputError ${customClass}` : `${customClass}`

    return (

        <div className='w-100' data-testid={AppConstant.dataTestId.customInputTitle}>
            <label htmlFor="email">
                {titleIcon &&
                    <i className={titleIconClass} data-testid={AppConstant.dataTestId.titleIcon}></i>
                }
                <span className="btn_title">{title}</span>&nbsp;{required && <span className='color-red'>*</span>}
            </label>{" "}
            {/* <br /> */}
            <div className={customClass ? '' : "input-container"}>
                <input
                    data-testid={AppConstant.dataTestId.customInput}
                    type={inputType ? inputType : 'text'}
                    value={value}
                    name={name}
                    className={getInputClass()}
                    placeholder={placeHolder}
                    onBlur={(data) => props?.handleErrors(data.target.name)}
                    onInput={(data) => props?.onEmailChange(data.target.value, data.target.name)}
                    disabled={disabled || ''}
                    autoFocus={false}
                />
            </div>
            {
                error &&
                <span className='errorMessage' data-testid={AppConstant.dataTestId.validationError}>
                    {errorMessage ? errorMessage : error}
                </span>
            }
        </div>
    )
}

export default InputWithTtileSS
