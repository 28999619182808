import React from 'react'
import './TinyLoader.css'

const TinyLoader = () => {
  return (
    <div className=' d-flex justify-content-center'>
    <div className='tiny-loader'></div>
    </div>
  )
}

export default TinyLoader