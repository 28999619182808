import React from 'react';
import { ReactComponent as CrossIcon } from '../../../../../../../Assests/Svg/cross-primary-blue.svg';
import { ReactComponent as CrossIconDark } from '../../../../../../../Assests/Svg/cross-icon-primary-dark.svg';


import './TaskItemsDueLabel.css'
const TaskItemsDueLabel = (props) => {
    const {title, themeSelected, onCancelTaskItem} = props;

    return (
        <div className={`d-flex justify-content-center align-items-start graphHeading w-50 text-center taskItemTitle ${themeSelected}`}>
            {title}

            <div className='m-0 p-0 ms-3 my-1 cancelButton' onClick={onCancelTaskItem}>
                { themeSelected === 'light-mode' ? <CrossIcon /> : <CrossIconDark /> }
            </div>
        </div>
    )
}

export default TaskItemsDueLabel