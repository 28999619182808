import React, { Children, useState } from 'react';
import './ScheduleProWrapper.css'
import { NavigationBarUpdated } from '../../Navigationbar/NavigationBarUpdated';
import { DashBoardSS } from './Components/DashBoard/Children/DashboardFlowRouting/Carrier/DashBoardSS';
import  SSAppointments  from './Components/DashBoard/Children/SSAppointments/SSAppointments';
import { useLocation } from 'react-router-dom';
import { NavigationBarUpdatedMobile } from '../../Navigationbar/NavigationBarUpdatedMobile';

const ScheduleProWrapper = (props) => {
    const { children, showAppointments, isHighLighted, setIsHighLighted, showNavBar = true } = props;

    const workStyle = {
        minWidth: showAppointments ? '60%' : '85%',
        width: showAppointments ? '60%' : '100%',
    }

    const navStyle = {
        width: showAppointments ? '20%' : '15%'
    }

    return (
        <>
         <NavigationBarUpdatedMobile />
        <div className='scedule-pro-wrapper gap-2'>
            {showNavBar &&
                <div style={navStyle}><NavigationBarUpdated /></div>}
            <div style={workStyle} className='schedule-pro-body'>{children}</div>

            {showAppointments && <SSAppointments isHighLighted={isHighLighted} setIsHighLighted={setIsHighLighted} />}
        </div>
        </>
    );
}

export default ScheduleProWrapper;