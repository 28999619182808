import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import { put, takeEvery } from "redux-saga/effects";
import { AppConstant } from "../App/Assests/AppConstant";
import { encryptString, setCookies, showAlert } from "../App/Assests/Utility";
import { clearError, getSignFailure, getSignSuccess, signin, clearUser, passwordRecoverSuccess, passwordRecoverInitiated, passwordRecoverFailure, setPasswordInt, setPasswordSuccess, setPasswordFailure, clearSetPassword, passwordResetInitiated, passwordResetSuccess, rememberUserReducer, changePasswordUserReducer, changeErrorPasswordUserReducer } from "../redux/reducers/reducerSlices/AuthReducer";
import { sagaActions } from "./sagaActions";

function* initiateSignIn(data) {
    const { username, password } = data.payload
    try {
        yield put(signin());
        const response = yield Auth.signIn(username, password)
        const data = {
            response,
            email: username
        }
        yield put(getSignSuccess(data));
    } catch (error) {
        showAlert(toast.TYPE.ERROR, error?.message);
        yield put(getSignFailure(error));
    }
}

function* initiateSetPassword(data) {
    const { user, newPassword } = data.payload
    try {
        yield put(setPasswordInt());
        const response = yield Auth.completeNewPassword(user, newPassword,[] )
        yield put(setPasswordSuccess(response));
    } catch (error) {
        yield put(setPasswordFailure(error));
    }
}

function* clearErrorObject() {
    yield put(clearError());
}

function* clearUserObject() {
    // yield Auth.signOut();
    yield put(clearUser());
}

function* recoverPasswordInitiatedSaga(data) {
    try {
        const result = yield Auth.forgotPassword(data.payload);
        if (result) {
            yield put(passwordRecoverInitiated(data.payload))
        }

    } catch (e) {
        yield put(passwordRecoverFailure(e))
    };
}
function* recoverPasswordSuccessSaga() {
    try {
        yield put(passwordRecoverSuccess())

    } catch (e) {
    };
}
function* clearSetPasswordObject() {
    yield put(clearSetPassword());
}

function* resetPasswordInitiatedSaga(data){
    const {email, code, password, themeSelected} = data.payload;
    try {
    const result = yield Auth.forgotPasswordSubmit(email, code, password);
    if(result === 'SUCCESS'){
        yield put(passwordResetInitiated())
    }
    } catch (error) {
        if (error.code === "CodeMismatchException") {
            showAlert(toast.TYPE.ERROR, AppConstant.notifications.login.verificationCodeMismatch, themeSelected)
        } else {
            showAlert(toast.TYPE.ERROR, AppConstant.notifications.login.wentWrong, themeSelected)
        }        
    }
}

function* userChangePasswordSaga(data) {
    const { oldPassword, newPassword} = data.payload
    try {
        const response = yield Auth.currentAuthenticatedUser() 
        const response1 = yield Auth.changePassword(response, oldPassword, newPassword)
        yield put(changePasswordUserReducer(true))
        const encryptedPassword = encryptString('WHP', newPassword, 'password');
        setCookies('password', encryptedPassword, '/');
    } catch (error) {
        showAlert(toast.TYPE.ERROR, error?.message)
        yield put(changeErrorPasswordUserReducer(true))
    }
}

function* userChangePassworSuccessSaga(data) {
    try {
        yield put(changePasswordUserReducer(data.payload))
    } catch (error) {
        
    }
}

function* userChangePassworErrorSaga(data) {
    try {
        yield put(changeErrorPasswordUserReducer(data.payload))
    } catch (error) {
        
    }
}

function* saveDataFromSignUpHubSaga(data) {
    try {
        yield put(getSignSuccess(data.payload));
    } catch (error) {
    }
}

function* resetPasswordSuccessSaga(data){
    yield put(passwordResetSuccess())
}
function* rememberUser(isUserRemembered) {
    yield put(rememberUserReducer(isUserRemembered.payload));
}

export function* signIn() {
    yield takeEvery(sagaActions.SIGNIN, initiateSignIn)
}

export function* clearSignInError() {
    yield takeEvery(sagaActions.CLEAR_SIGNIN_ERROR, clearErrorObject)
}

export function* clearSignedInUser() {
    yield takeEvery(sagaActions.CLEAR_SIGNEDIN_USER, clearUserObject)
}

export function* recoverPasswordInitiated() {
    yield takeEvery(sagaActions.RECOVER_PASSWORD_INITIATED, recoverPasswordInitiatedSaga)
}

export function* recoverPasswordSuccess() {
    yield takeEvery(sagaActions.RECOVER_PASSWORD_SUCCESS, recoverPasswordSuccessSaga)
}
export function* setPassword() {
    yield takeEvery(sagaActions.SET_PASSWORD, initiateSetPassword)
}

export function* clearSetPasswordData() {
    yield takeEvery(sagaActions.CLEAR_SET_PASSWORD, clearSetPasswordObject)
}

export function* resetPasswordInitiated() {
    yield takeEvery(sagaActions.RESET_PASSWORD_INTIATED, resetPasswordInitiatedSaga)
}

export function* resetPasswordSuccess() {
    yield takeEvery(sagaActions.RESET_PASSWORD_SUCCESS, resetPasswordSuccessSaga)
}
export function* rememberUserSaga() {
    yield takeEvery(sagaActions.REMEMBER_USER, rememberUser)
}

export function* userChangePasswordWatcher() {
    yield takeEvery(sagaActions.USER_CHANGE_PASSWORD, userChangePasswordSaga)
}

export function* userChangePassworSuccessdWatcher() {
    yield takeEvery(sagaActions.UPDATE_CHANGE_PASSWORD_SUCCESS, userChangePassworSuccessSaga)
}

export function* userChangePassworErrorWatcher() {
    yield takeEvery(sagaActions.UPDATE_CHANGE_PASSWORD_ERROR, userChangePassworErrorSaga)
}

export function* saveDataFromSignUpHubWatcher() {
    yield takeEvery(sagaActions.SAVE_DATA_FROM_SIGNUP_LISTNER_TO_REDUX, saveDataFromSignUpHubSaga)
}

