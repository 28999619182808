import {Uchain} from "../redux/api/agent";
import { call, put, takeEvery } from 'redux-saga/effects';
import { sagaActions } from './sagaActions';
import { addSuccess, addFailure,initiateLoading,resetData } from '../redux/reducers/reducerSlices/CompanyReducer';
  function* addCompanyRequestHandler(data){
    try{
      yield put(initiateLoading());
     const response = yield call(Uchain.companyData, data.payload);
      yield put(addSuccess(response));
    }catch(error){
     yield put(addFailure(error));
    }
}
export function* CompanyRequest() {
  yield takeEvery(sagaActions.COMPANY_DATA, addCompanyRequestHandler)
}

function* resetFormHandler() {
 yield put(resetData())
 
}

export function* resetCompanyFormRequest() {
    yield takeEvery(sagaActions.RESET_COMPANY_DATA, resetFormHandler)
  }
  

