import React from 'react';
import ReactTooltip from 'react-tooltip';
/**
 * This is a reusable component for rendering tooltip
 * @param {*} props 
 * id = ID for tooltip
 * position = Tooltip position
 * type = Tooltip type
 * multiline = Is tooltip multi line
 * html = Tool HTML default value false
 * This component uses 3rd party ReactTooltip library
 * @returns 
 */
const CustomTooltip = (props) => {
    const { id, position, type, multiline, html, title = '' } = props;
    return (
        <ReactTooltip className={'custom-tooltip'} id={id} place={position} type={type} multiline={multiline} scrollHide={true} html={html || false}>
            {title && <span>{title}</span>}
        </ReactTooltip>
    );
}

export default CustomTooltip;