import React from "react";
import './DatePickerSS.css'
import { getTimeFromStart } from "./DatePickerSSUtils";
function CustomDate(props) {
    const {day,minDate,maxDate,selectedDate,currentMonth,currentYear,handleSelction}=props;
    if (!day) {
        return <span
        id="day"
        className="picker-day-disable"
        ></span>
    }
  return (
    <button
      id="day"
      data-day={day}
      disabled={
        (minDate && minDate?.getTime() > getTimeFromStart(day,currentMonth,currentYear)) ||
        (maxDate && maxDate?.getTime() < getTimeFromStart(day,currentMonth,currentYear))
      }
      className={
        (minDate && minDate?.getTime() > getTimeFromStart(day,currentMonth,currentYear)) ||
        (maxDate && maxDate?.getTime() < getTimeFromStart(day,currentMonth,currentYear))
          ? "picker-day-disable"
          : selectedDate?.getTime() ===
            new Date(currentYear, currentMonth, day).getTime()
          ? "day-active picker-day"
          : "picker-day"
      }
      onClick={(e) => handleSelction(e)}
    >
      {day}
    </button>
  );
}

export default CustomDate;
