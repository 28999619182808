import { differenceInMinutes,setHours, format } from 'date-fns'
import React, { Fragment } from 'react'
import { BORDER_HEIGHT } from '../helpers/constansts'
import Break from './Break'

function Hour({
    CELL_HEIGHT,
    h,
    hFormat,
    locale,
    breakstart,
    breakend,
    today,
    startHour,
    step,
    minuteHeight,
    breakList,
    selectedDate,
    showScheduleModal,
    setShowScheduleModal
}) {
    const hourTypo=format(h, hFormat, { locale })
  
    

    //calc

    // const height = differenceInMinutes(breakend, breakstart) - BORDER_HEIGHT;
    //     const minituesFromTop = differenceInMinutes(breakstart, setHours(today, startHour));
    //     const topSpace = minituesFromTop * minuteHeight;
    //     const slots = height / 60;
    //     const heightBorderFactor = slots * BORDER_HEIGHT;

    //     const slotsFromTop = minituesFromTop / step;
    //     const top = breakstart.getMinutes();
    const nextTime=new Date(h.getTime() + step *60000)
     const isBetween=(data,start,end)=>{
        if (data>=start && data<end) {
            return true
        }
        else{
        return false;
      }
      }
  return (
    <>
    {breakList.map((data,i)=>{
        let breakDate= new Date(`${format(selectedDate, "yyyy/MM/dd")} ${format(data.start, hFormat)}`)
        if (h.getHours()===data.start.getHours() && isBetween(breakDate.getTime(),h.getTime(),nextTime)) {
            let top=breakDate.getTime()===h.getTime()?0:differenceInMinutes(breakDate,h)*minuteHeight

            return(
                <Fragment key={i}>
            <Break data={data} minuteHeight={minuteHeight} top={top} step={step}
             showScheduleModal={showScheduleModal}
             setShowScheduleModal={setShowScheduleModal}
            />
            </Fragment>
            )
        }
    })}

    <span style={{ height: CELL_HEIGHT }} className="rs__cell rs__header rs__time">
    <div className='f-12'>{hourTypo}

    </div>
  </span>
  </>
  )
}

export default Hour