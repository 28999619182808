import React from 'react';
import _ from 'lodash';
import Reportlogo from '../../../../../../Assests/Images/reportlogo.png';

const ReportsInput = (props) => {

    const { apiParams } = props;

    const getEle = (ele) => (`${Object.keys(ele)} : ${Object.values(ele)}`)

    const showInputs = () => {
        const filteredArr = apiParams.map((ele) => {
            const eleArr = Object.values(ele);
            if (!_.isEmpty(eleArr[0])) {
                return (
                    <tr>
                        <td>
                            <div>
                                <div className='mx-2 reportsInput'>{`${getEle(ele)}`}</div>
                            </div>
                        </td>
                    </tr>

                )
            }
        })
        return _.compact(filteredArr);
    }

    return (
        <div>
            {(apiParams && apiParams.length) ?
                <div className='d-flex justify-content-between mx-5'>
                    <table>
                        <thead />
                        <tbody>
                            <tr>
                                {showInputs()}
                            </tr>
                        </tbody>
                    </table>
                    {/* <FrieghtSmithLogoIcon className='reportsLogo' /> */}
                    <div className='reportLogoContainer'>
                    <img className='report-logo' src={Reportlogo} alt="logo" />
                    </div>
                    
                </div>
                : <div />
            }
        </div>
    )
}

export default ReportsInput;