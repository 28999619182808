import React,{ useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getFilterViewExpanded } from '../../../../../../../../redux/reducers/reducerSlices/FiltersReducerSlice';
import { ReactComponent as ChooseFiltersIcon } from '../../../../../../../Assests/Svg/choose-filters.svg';
import { ReactComponent as ChooseFiltersIconDark } from '../../../../../../../Assests/Svg/choose-filter-icon-dark.svg';
import { ReactComponent as CrossIcon } from '../../../../../../../Assests/Svg/cross-icon-black.svg';
import { ReactComponent as CrossIconWhite } from '../../../../../../../Assests/Svg/cross-icon-white.svg';
import { AppConstant} from '../../../../../../../Assests/AppConstant';
import './POFilterHeader.css';

const POFilterHeader = (props) => {
    const { totalPo, themeSelected, showFilters, onClickHandler, heading, showCount } = props
    const {isFilterViewExpanded}  = useSelector(getFilterViewExpanded);
    const [isExpanded, setIsExpanded] = useState(false)
    const appConstantsPath = AppConstant.poManagement.poFiltersSection

    useEffect(() => {
        const str = Array.from(isFilterViewExpanded)[1];
        str === 't' ? setIsExpanded(true) : setIsExpanded(false);
    }, [isFilterViewExpanded])

    const selectIcon = () => {
        if(themeSelected === AppConstant.commonStrings.darkModeFilterString){
            return (isExpanded ? <CrossIconWhite /> : <ChooseFiltersIconDark />)
        } else {
            return (isExpanded ? <CrossIcon /> : <ChooseFiltersIcon />)
        }
    }

    const getTitle = () => {
        if (totalPo > 0) {
            if (showCount) {
                return <p className="m-0 p-0">{`${heading} (${totalPo})`}</p>
            } else {
                return <p className="m-0 p-0">{`${heading}`}</p>
            }
        } else {
            if (showCount) {
                return <p className="m-0 p-0">{`${heading} (0)`}</p>
            } else {
                return <p className="m-0 p-0">{`${heading}`}</p>
            }
        }
    }

    return (
        <span className={`d-flex justify-content-start poFilterHeader  ${themeSelected}`}>
            {getTitle()}
            {showFilters ?
                <span className='d-flex justify-content-start m-0  py-2 chooseFiltersText'>
                    <p onClick={onClickHandler} className="px-2">{`${AppConstant.poManagement.poFiltersSection.chooseFiltersTitleString}`}</p>
                    <div onClick={onClickHandler} className='chooseFiltersIcon'>
                        {selectIcon()}
                    </div>
                </span>
                :
                null
            }

        </span>
    )
}

export default POFilterHeader;

