import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    arrangeOrder: 'asc',
    arrangeKey: 'CreatedOn',
    getFsWarehouseData: null,
    isLoading: false,
    operationObj: {},
    fsPayWarehouseData: null
}

export const FsWarehouseReducer = createSlice({
    name: 'FsWarehouseReducer',
    initialState,
    reducers: {
        getFsWarehouseListSuccess: (state, action) => {
            state.isLoading = true;
            state.getFsWarehouseData = action.payload;
            state.initateLoading = false;
        },
        getFsWarehouseListFailure: (state, action) => {
            state.isLoading = false;
            state.getFsWarehouseData = null;
        },
        updateIsLoading: (state, action) => {
            state.isLoading = action.payload
        },
        updateOperationObj: (state, action) => {
            state.operationObj = action.payload
        },
        fsPayWarehouseListSuccess: (state, action) => {
            state.isLoading = true;
            state.fsPayWarehouseData = action.payload;
            state.initateLoading = false;
        },
        fsPayWarehouseListFailure: (state, action) => {
            state.isLoading = false;
            state.fsPayWarehouseData = null;
        },
    }
});

export const { getFsWarehouseListSuccess, updateIsLoading, getFsWarehouseListFailure, updateOperationObj,fsPayWarehouseListSuccess,fsPayWarehouseListFailure } = FsWarehouseReducer.actions;

// Selectors
export const getFsWarehouseselector = (state) => {
    return state.FsWarehouseReducer;
};

export default FsWarehouseReducer.reducer;