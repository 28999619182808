import React, { useEffect, useState } from 'react';
import { isValid } from '../../../Login/utils';
import { AppConstant } from '../../../../Assests/AppConstant';
import CustomButton from '../../../Common/CustomButton/CustomButton';
import PasswordInput from '../../../Common/PasswordInput/PasswordInput';
import './SettingChangePassword.css'
import { useSelector } from 'react-redux';
import { getAuthReducer } from '../../../../../redux/reducers/reducerSlices/AuthReducer';
import { sagaActions } from '../../../../../sagas/sagaActions';
import { useDispatch } from 'react-redux';
import { showAlert } from '../../../../Assests/Utility';
import { toast } from 'react-toastify';
/**
 * This component provides user functionality to change his own password.
 * @param {*} props 
 * @returns 
 */
const SettingChangePassword = (props) => {
    const { themeSelected } = props;
    const dispatch = useDispatch();
    const [oldPassword, setOldPassword] = useState("")
    const [newPassword, setnewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [error, setError] = useState(false)
    const [isloading, setisloading] = useState(false)
    const [passwordError, setpasswordError] = useState(null)
    const [oldpasswordError, setoldpasswordError] = useState(null)
    const auth = useSelector(getAuthReducer);
    const { changePasswordSuccess, changePasswordError } = auth;
    /**
     * useEffect for newPassword and confirmPassword
     */
    useEffect(() => {
        if (isValid('password', newPassword) && isValid('password', confirmPassword)) {
            setError(false)
        } else {
            setError(true)
        }
    }, [newPassword, confirmPassword])
    /**
     * useEffect for changePasswordSuccess and changePasswordError
     */
    useEffect(() => {
        if (changePasswordSuccess) {
            showAlert(toast.TYPE.SUCCESS, AppConstant.login.userChangePassword, themeSelected)
            setOldPassword('')
            setnewPassword('')
            setConfirmPassword('')
            dispatch({ type: sagaActions.UPDATE_CHANGE_PASSWORD_SUCCESS, payload: false })
            setisloading(false)
        }
        if (changePasswordError) {
            dispatch({ type: sagaActions.UPDATE_CHANGE_PASSWORD_ERROR, payload: false })
            setisloading(false)
        }
    }, [changePasswordSuccess, changePasswordError])

    /**
     * This function handler function for SUBMIT change password functionality
     * @returns 
     */
    const initiateChangePassword = () => {
        if (oldPassword === newPassword) {
            setError(true)
            setoldpasswordError(AppConstant.login.oldvsnewMismatch)
        }
        if (newPassword !== confirmPassword) {
            setError(true)
            return setpasswordError(AppConstant.login.passwordMismatch)
        }

        setError(false)
        setisloading(true)
        const data = {
            oldPassword: oldPassword,
            newPassword: newPassword
        }
        dispatch({ type: sagaActions.USER_CHANGE_PASSWORD, payload: data })
    }

    return (
        <div className='changePasswordWrapper'>
            <div className='ms-4 heading'>{AppConstant.login.changePassword}</div>
            <div className="row">
                <div className="col-6">
                    <div className="passwordDivWrapper verfication_screen_passwordDivWrapper ms-4">
                        <div className='passwordDiv changePassword'>
                            <PasswordInput
                                errorMessage={AppConstant.login.oldvsnewMismatch}
                                showError={oldpasswordError}
                                title={AppConstant.login.oldPassword}
                                placeHolder={AppConstant.login.enterHere}
                                onPasswordChange={(e) => setOldPassword(e)}
                                value={oldPassword}
                            />
                        </div>
                        <div className='passwordDiv changePassword verfication_passwordDiv'>
                            <PasswordInput
                                errorMessage={AppConstant.login.oldvsnewMismatch}
                                showError={passwordError}
                                title={AppConstant.login.newPassword}
                                placeHolder={AppConstant.login.enterHere}
                                onPasswordChange={(e) => setnewPassword(e)}
                                passwordInfo={AppConstant.login.recover.passwordInfoTooltip}
                                value={newPassword}
                            />
                        </div>
                        <div className='passwordDiv changePassword verfication_passwordDiv'>
                            <PasswordInput
                                errorMessage={AppConstant.login.passwordValidation}
                                showError={passwordError}
                                title={AppConstant.login.confirmNewPassword}
                                placeHolder={AppConstant.login.enterHere}
                                onPasswordChange={(e) => setConfirmPassword(e)}
                                value={confirmPassword}
                            />
                        </div>
                        <div className='d-flex justify-content-end loginButtonContainer mt-4 pe-2'>
                            <CustomButton
                                isLoading={isloading}
                                title={AppConstant.commonStrings.update} className={"loginButton"}
                                onClick={() => initiateChangePassword()}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-6 d-flex flex-column justify-content-center">
                    <div className='passwordInfo p-3'>
                        <div className='bolderText p-1'><b>Password requirements</b></div>
                        <div className='subText p-1' style={{ color: 'green' }}>At least 8 characters long</div>
                        <div className='subText p-1'>Must contain a number</div>
                        <div className='subText p-1'>Must contain a special character</div>
                        <div className='subText p-1'>Must contain atleast one uppercase and lowercase</div>
                    </div>
                </div>

            </div>

        </div>
    );
}

export default SettingChangePassword;