import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { sagaActions } from '../../../sagas/sagaActions';
import _ from 'lodash';
import CustomDatePicker from '../DatePicker/CustomDatePicker';
import SearchPO from './HeaderComponents/SearchPO/SearchPO';
import "./Header.css";
import { getUserSelector } from '../../../redux/reducers/reducerSlices/UserReducer';
import { getSuperAdminSelector } from '../../../redux/reducers/reducerSlices/SuperAdminReducerSlice';
import { shouldHavePermission } from '../../Assests/Utility';
import ProfileMenu from './HeaderComponents/ProfileMenu/ProfileMenu';
import { ReactComponent as NotificationsIcon } from '../../Assests/Svg/notifications-icon.svg';
import { getRemoteConfigSelector } from '../../../redux/reducers/reducerSlices/RemoteConfigReducer';
import { AppConstant } from '../../Assests/AppConstant';
import { getReportsSelector } from '../../../redux/reducers/reducerSlices/ReportsReducerSlice';
/**
 * This is a reusable component for Page Header
 * @param {*} props 
 * @returns 
 */
const Header = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const userPermission = useSelector(getUserSelector);
    const { permissions, branding, brandingSuccess, userType, selectedWarehouse } = userPermission;
    const [notificationCount, setNotificationCount] = useState(0);
    const [showNotificationBellIcon, setShowNotificationBellIcon] = useState(false);
    let allowPermission = false;
    const superAdmin = useSelector(getSuperAdminSelector);
    const { openNotificationObj } = superAdmin;
    const [openNotifObj, setOpenNotifObj] = useState({});
    const remoteConfig = useSelector(getRemoteConfigSelector);
    const { autofetchNotificatioInterval } = remoteConfig;
    const reportsSelector = useSelector(getReportsSelector);
    const { selectedReportObj } = reportsSelector

    /**
     * Handler function for auto notification fetch
     * @returns 
     */
    const initiateNotificationAutoFech = () => {
        let timer = setInterval(() => {
            dispatch({ type: sagaActions.GET_OPEN_NOTIFICATION_LIST, payload: {} });
        }, 3600000);
        return () => clearInterval(timer);
    }
    /**
     * useEffect for openNotificationObj
     */
    useEffect(() => {
        const feedbackCount = openNotificationObj?.Feedback?.Open;
        const issueCount = openNotificationObj?.Issue?.Open;
        openNotificationObj && feedbackCount && issueCount && setNotificationCount(parseInt(feedbackCount) + parseInt(issueCount))
        openNotificationObj && setOpenNotifObj(openNotificationObj);
    }, [openNotificationObj])
    /** Initial useEffect */
    useEffect(() => {
        if (permissions.length) {
            initiateNotificationAutoFech();
            checkRequestReceivedPermissions()
            allowPermission = shouldHavePermission(permissions, 'Change Theme');
            allowPermission = false
        }
    }, [])
    /** Handler function for fetching notification */
    const checkRequestReceivedPermissions = () => {
        // get permissions from request received
        const requestReceivedManagementPermission = _.find(permissions, { 'FeatureID': '11', 'PageAccess': true });

        if (requestReceivedManagementPermission && !_.isEmpty(requestReceivedManagementPermission)) {
            setShowNotificationBellIcon(true)
            dispatch({ type: sagaActions.GET_OPEN_NOTIFICATION_LIST, payload: {} });
        } else {
            setShowNotificationBellIcon(false)
        }

    }
    /**
     * Handler function to show/hide Date picker
     * @returns true/false
     */
    const isDatePickerDisabled = () => {
        const path = window.location.href;
        if (_.includes(path, 'analytics') || _.includes(path, 'loadmanagement') || (_.includes(path, 'report') && selectedReportObj && (selectedReportObj.ElementAccessID == '959'  || selectedReportObj.ElementAccessID == '956' || selectedReportObj && selectedReportObj.ElementAccessID == '961'))) {
            return false;
        } else {
            return true
        }
    }
    /**
     * Handler function to navigate to request received
     */
    const goToRequestsReceivedSection = () => {
        const location = window.location.href;
        if (location.split('/').pop() !== 'requestreceived') {
            navigate("/requestreceived")
        }
    }
    /**
     * 
     * @returns JSX for Notification icon
     */
    const notificationBellIcon = () => {
        return (
            <div className='d-flex justify-content-end align-items-top notificationIndicator dropdown me-2'>
                <button type="button" className="dropdown-toggle position-relative notificationIndicatorChild" data-bs-toggle="dropdown" aria-expanded="false">
                    <NotificationsIcon />
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                        {notificationCount && notificationCount}
                    </span>
                </button>
                <ul className="dropdown-menu">
                    <li className="dropdown-item">{AppConstant.commonStrings.notificationsTitle} ({notificationCount})</li>
                    <li><hr className="dropdown-divider" /></li>
                    {openNotificationObj &&
                        <li className="dropdown-item">{openNotifObj?.Issue?.Open} {openNotifObj?.Issue?.Open && "issues received."}</li>
                    }
                    {openNotificationObj &&
                        <li className="dropdown-item">{openNotifObj?.Feedback?.Open} {openNotifObj?.Feedback?.Open && "feedbacks received."}</li>
                    }
                    <li><hr className="dropdown-divider" /></li>
                    <li className="dropdown-item footer-text">
                        {
                            window.location.href.split('/').pop() === 'requestreceived' ?
                                '' :
                                <button className='footer-text' onClick={() => goToRequestsReceivedSection()}>{AppConstant.commonStrings.viewAllStr}</button>
                        }
                    </li>
                </ul>
            </div>
        )
    }
    /**
     * Handler function to display logo
     * @returns logo
     */
    const displayLogo = () => {
        if (location.pathname === '/') {
            if (brandingSuccess) {
                if (!_.isEmpty(branding) && branding.GroupLogo) {
                    return branding.GroupLogo
                } else {
                    return AppConstant.commonStrings.logo_small_wordMark
                }
            } else {
                return AppConstant.commonStrings.logo_small_wordMark
            }
        } else {
            if (!_.isEmpty(selectedWarehouse) && selectedWarehouse?.IsBranding && selectedWarehouse?.WarehouseLogo) {
                return selectedWarehouse?.WarehouseLogo
            } else if ((_.upperCase(selectedWarehouse?.WHType)) === 'IMS') {
                return AppConstant.commonStrings.logo_small_wordMark
            } else if ((_.upperCase(selectedWarehouse?.WHType)) === 'SS') {
                return AppConstant.commonStrings.schedulerProLogo
            } else {
                return AppConstant.commonStrings.logo_small_wordMark
            }
            
            // if (brandingSuccess) {
            //     if (!_.isEmpty(branding) && branding.WarehouseLogo) {
            //         return branding.WarehouseLogo
            //     } else {
            //         return AppConstant.commonStrings.logo_small_wordMark
            //     }
            // } else {
            //     return AppConstant.commonStrings.logo_small_wordMark
            // }
        }

    }
    /**
     * Handler function for returing logo for CARRIER or IMS
     * @returns logo
     */
    const setHeaderLogo = () => {
        
        if(userType === AppConstant.userPermission.usertypes.carrier){
            return(
                <img className='logoHeader'  src={AppConstant.commonStrings.schedulerProLogo} alt="Primary Logo" />
            )
        } else if(selectedWarehouse && selectedWarehouse.WHType && selectedWarehouse.WHType.toString().toLowerCase() === 'ims') {
            return (
                <div className='d-flex'>
                    <img className='logoHeader'  src={displayLogo()} alt="Primary Logo" />
                </div>
            )
        } 
        else if(selectedWarehouse && selectedWarehouse.WHType && selectedWarehouse.WHType.toString().toLowerCase() === 'ss') {
            return (
                <div className='d-flex'>
                    <img className='logoHeader'  src={AppConstant.commonStrings.schedulerProLogo} alt="Primary Logo" />
                </div>
            )
        } 
        else if(selectedWarehouse && selectedWarehouse.WHType && selectedWarehouse.WHType.toString().toLowerCase() === 'ims,ss') {
            return (
                <div className='d-flex'>
                    <img className='logoHeader me-3'  src={displayLogo()} alt="Primary Logo" />
                    <img className='logoHeader'  src={AppConstant.commonStrings.schedulerProLogo} alt="Primary Logo" />
                </div>
            )
        } else {
            return(
                <img className='logoHeader'  src={AppConstant.commonStrings.logo_small_wordMark} alt="Primary Logo" />
            )
        }
         
    }

    return (
        <div className='d-flex flex-row m-0 p-0 align-items-center justify-content-between header'>
            <div className='logoContainer d-flex justify-content-start align-items-center'>
                <div className='d-flex mx-1 ' style={{ width: '300px' }}>
                    {setHeaderLogo()}
                </div>
                <div className='mx-1' style={{ width: '100px' }}>
                    {/* Uncomment this when img source */}
                    {/* {userType !== 'SUPERADMIN' ? <img src="" alt="Secondary Logo" /> : ''} */}
                </div>
            </div>
            <div className='itemsContainer d-flex justify-content-end'>
                {
                    location.pathname === '/loadmanagement' ? <SearchPO {...props} /> : ''
                }
                {isDatePickerDisabled() ? '' : <CustomDatePicker {...props} />}
                <div className='mx-2 d-flex justify-content-center align-items-center'>
                    {showNotificationBellIcon ? notificationBellIcon() : ''}
                    <ProfileMenu {...props} />
                </div>
            </div>
        </div>
    )
}

export default Header;