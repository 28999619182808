import React, { useEffect, useState } from 'react'
import "./PasswordInputSS.css"
import { AppConstant } from '../../../Assests/AppConstant'

/**
 * function to set input type element and icon for password
 * @returns InputType, Icon
 */
const usePasswordToggle = () => {
    const [visible, setVisible] = useState(false);

    const handleClick = () => {
        setVisible((visibility) => !visibility);
    };

    const IconStyle = { height: "20px", width: "20px" };

    const Icon = !visible ? (
        <i
            className="fa-regular fa-eye-slash"
            onClick={handleClick}
            style={IconStyle}
            data-testid={AppConstant.dataTestId.passwordEyeButton}
        ></i>
    ) : (
        <i
            className="fa-regular fa-eye"
            onClick={handleClick}
            style={IconStyle}
            data-testid={AppConstant.dataTestId.passwordEyeButton}
        ></i>
    );

    const InputType = visible ? "text" : "password";

    return [InputType, Icon];
};

/**
 * This is a reusable component for Password with new UI
 * @param {*} props 
 * title = Password title
 * placeHolder = Placeholder text
 * showError = show/hide error variable
 * errorMessage = variable to display error message
 * disabled = if element is disabled
 * showInfo = show password info
 * passwordInfo = data tip password info
 * value = password value
 * @returns 
 */

const PasswordInputSS = (props) => {
    const { title, placeHolder, showError, errorMessage, disabled, showInfo, passwordInfo, value, required, customClass } = props
    const [PasswordInputType, ToggleIcon] = usePasswordToggle();
    const [error, seterror] = useState(showError)

     /**
     * useEffect for error
     */
    useEffect(() => {
        seterror(showError)
    }, [showError])
    /**
     * function for input class if error
     */
    const getInputClass = () => error ? "passwordTextInput passwordTextInputError" : "passwordTextInput"

    return (
        <div className="d-flex flex-column w-100">
            <div className='w-100'>
                <label htmlFor="password">
                    <i className="fa-solid fa-star-of-life fa-xs"></i>
                    <span className="btn_title">{title}</span>&nbsp;{required && <span className='color-red'>*</span>}
                </label>{" "}

                <div className="pass-container">
                    <input
                        data-testid={AppConstant.dataTestId.passwordInput}
                        type={PasswordInputType}
                        name='password'
                        className={getInputClass()}
                        placeholder={placeHolder}
                        value={value}
                        onInput={(data) => props.onPasswordChange(data.target.value)}
                        autoFocus={false}
                    />{" "}
                    <span id="pass-eye">{ToggleIcon}</span>
                </div>
            </div>


            {
                error &&
                <span className='errorMessage' data-testid={AppConstant.dataTestId.validationError}>
                    {errorMessage}
                </span>
            }

        </div>
    )
}

export default PasswordInputSS
