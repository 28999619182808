import moment from "moment"

const YYYY_MM_DD_FORMAT = "YYYY-MM-DD"
const MM_DD_YYYY_FORMAT = "MM/DD/YYYY"

const getDayFromDate = (date) => moment(date).format('ddd')

const getMonthFromDate = (date) => moment(date).format('MMM')

const getPlainDate = (date) => moment(date).format('D')

const getCurrentDate = () => new Date()

const getFormattedDate = (format, date) => moment(date).format(format)

const getCurrentTime = () => Date.now();

const isDateSameOrBefore = (startDate, endDate, companreDate) => {

    const start = moment(startDate);
    const end = moment(endDate)
    const date = moment(companreDate);

    return date.isSameOrBefore(start, end);
}

const convertGmtToDateRange = (dateObj) => {
    const start = dateObj && dateObj.start;
    const end = dateObj && dateObj.end;
    let fmt = 'YYYY-MM-DD';
    const formatObj = {
        start: moment(start).format(fmt),
        end: moment(end).format(fmt)
    }
    return formatObj;
}

const calculateMonthDifference = (date1, date2) => {
    return (
      date2.getMonth() -
      date1.getMonth() +
      12 * (date2.getFullYear() - date1.getFullYear())
    );
  };
const changeDateToGivenFormat = (dateStr, formatStr) => moment(dateStr).format(formatStr)

const getTimeDiffInMinutes = (startDate, endDate) => {
  const duration = moment(endDate, "MMM DD YYYY h:mmA").diff(moment(startDate, "MMM DD YYYY h:mmA"), 'minutes')
  return duration
}

/**
 * Function to format dock start time and end time
 * @param {*} data 
 * @returns 
 */
const getDockOperationalTime=(data)=>{
  if (data.includes('GMT')) {
    return changeDateToGivenFormat(data,'HH:mm')
  }
  else{
    let time=data.split('T')[1];
    let hours=time.split(':')[0]
    let min=time.split(':')[1]
    return `${hours}:${min}`
  }
}

const getPSTTimezone=()=>{
  return 'America/Los_Angeles'
}

function addDaysToDate(date, daysToAdd) {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + daysToAdd);
  return newDate;
}

export { getDayFromDate, getPlainDate, getCurrentDate, YYYY_MM_DD_FORMAT, getFormattedDate, getCurrentTime, convertGmtToDateRange, isDateSameOrBefore, getMonthFromDate, MM_DD_YYYY_FORMAT, changeDateToGivenFormat, calculateMonthDifference,getTimeDiffInMinutes,getDockOperationalTime,getPSTTimezone, addDaysToDate }