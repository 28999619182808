import React, { useEffect, useState } from 'react';
import Header from '../../../Header/Header';
import Navigationbar from '../../../Navigationbar/Navigationbar';
import PODockStatusFilter from '../../Components/POFilters/PODockStatusFilter/PODockStatusFilter';
import UserList from '../../Components/UserWrapper/Wrapper/UserList';


const UserWrapper = (props) => {
    const {themeSelected} = props
    return (
                <div className='container-fluid executor'>
                    <Header {...props}/>
                    <Navigationbar {...props}/>
                    {/* <PODockStatusFilter themeSelected={themeSelected} /> */}
                    <UserList themeSelected={themeSelected} />
                    
                    
                </div>
    )
}

export default UserWrapper;