import React from 'react'
import './SettingsWrapper.css'
import AccountSettings from '../Children/AccountSettings/AccountSettings'
/**
 * Wrapper component
 * @param {*} props 
 * @returns 
 */
const SettingsWrapper = (props) => {
    return ( 
        <AccountSettings {...props} />
     );
}
 
export default SettingsWrapper;