import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppConstant } from '../../../../../Assests/AppConstant';
import Header from '../../../../Header/Header';
import Navigationbar from '../../../../Navigationbar/Navigationbar';
import { sagaActions } from '../../../../../../sagas/sagaActions';
import WarehouseSearch from '../../../../SuperWarehouse/Components/SearchWarehouse/WarehouseSearch';
import { getSuperAdminSelector } from '../../../../../../redux/reducers/reducerSlices/SuperAdminReducerSlice';
import RequestsReceivedTableSection from './RequestsReceivedTableSection/RequestsReceivedTableSection';
import './RequestsReceivedAdmin.css';

const RequestsReceivedAdmin = (props) => {

    const dispatch = useDispatch();
    const [selectedSection, setSelectedSection] = useState('Feedback');
    const [searchStr, setSearchStr] = useState('');
    const superAdminSagaObj = useSelector(getSuperAdminSelector);
    const { issuesList, feedbackList } = superAdminSagaObj;
    const [feedbackApiList, setFeedbackApiList] = useState([]);
    const [issuesApiList, setIssuesApiList] = useState([]);

    const selectSection = (sectionStr) => {
        setSelectedSection(sectionStr)
    }

    useEffect(() => {
        getFeedbacksAndIssuesList();
    }, [selectedSection])

    useEffect(() => {
        if (feedbackList && feedbackList.length) {
            setFeedbackApiList(feedbackList)
        }
    }, [feedbackList])

    useEffect(() => {
        if (issuesList && issuesList.length) {
            setIssuesApiList(issuesList)
        }
    }, [issuesList])

    const getFeedbacksAndIssuesList = () => {
        const apiIssuesParams = {
            "Id": null,
            "name": null,
            "email": null,
            "phoneNumber": null,
            "issue": null,
            "feedback": null,
            "fileOne": null,
            "fileTwo": null,
            "ownedBy": null,
            "status": null,
            "type": "Issue",
            "crud_type": "S"
        }
        dispatch({ type: sagaActions.UPDATE_ISSUES_LIST, payload: apiIssuesParams });

        const apiFeedbackParams = {
            "Id": null,
            "name": null,
            "email": null,
            "phoneNumber": null,
            "issue": null,
            "feedback": null,
            "fileOne": null,
            "fileTwo": null,
            "ownedBy": null,
            "status": null,
            "type": "Feedback",
            "crud_type": "S"
        }
        dispatch({ type: sagaActions.UPDATE_FEEDBACK_LIST, payload: apiFeedbackParams });
    }

    return (
        <div className='container-fluid m-1'>
            <Header {...props} />
            <Navigationbar {...props} />

            <div className='mt-2 graphHeading'>{`${AppConstant.requestsReceived.requestsReceivedTitle} (${(feedbackApiList.length + issuesApiList.length)})`}</div>
            <div className='seperator' />
            <div className='d-flex justify-content-between'>
                <div className='d-flex justify-content-start m-2'>
                    <div
                        className={`buttonSectionControl mx-2 ${selectedSection === 'Feedback' ? 'selectedButton' : 'unselectedButton'}`}
                        onClick={() => selectSection('Feedback')}>{`${AppConstant.requestsReceived.feedbackTitle} (${feedbackApiList.length})`}</div>
                    <div
                        className={`buttonSectionControl mx-2 ${selectedSection === 'Issues' ? 'selectedButton' : 'unselectedButton'}`}
                        onClick={() => selectSection('Issues')}
                    >{`${AppConstant.requestsReceived.issuesTitle} (${issuesApiList.length})`}</div>
                </div>
                <div className='w-25'>
                    <WarehouseSearch
                        placeholder={selectedSection === 'Feedback' ? 'Search Feedback' : 'Search Issues'}
                        inputValue={searchStr}
                        cancelSearch={() => setSearchStr('')}
                        onChangeHandler={(text) => setSearchStr(text)} />
                </div>
            </div>

            <div className='seperator' />
            <div>
                {
                    <RequestsReceivedTableSection
                        searchStr={searchStr}
                        feedbacksList={feedbackApiList}
                        issuesList={issuesApiList}
                        currentSection={selectedSection}
                        {...props} />
                }
            </div>
        </div>
    )
}

export default RequestsReceivedAdmin