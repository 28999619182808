import React, { useState } from "react";
import TruckImage from "../../../../../../../Assests/Images/truck.png";
import TruckOverlay from "../../../../../../../Assests/Images/truck_overlay.png";
import { AppConstant } from "../../../../../../../Assests/AppConstant";
import HelpAndFeedbackSS from "./HelpAndFeedbackSS/HelpAndFeedbackSS";

// This is reusable component for help and feedbak form
const TruckComponent = (props) => {
  // const { toggleModal, setFormName, setModalHeadingObj } = props;
  const [showHelpAndFeedback, setShowHelpAndFeedback] = useState(false);
  const [formName, setFormName] = useState(false)
  const [modalHeadingObj, setModalHeadingObj] = useState({
    heading: AppConstant.help.titleTextForHelp,
    isIcon: true,
    iconClass: 'fa-solid fa-dharmachakra'
  })
  // Toggle(open/close) modal handler
  const toggleModal = () => {
    setShowHelpAndFeedback(!showHelpAndFeedback)
  }

  return (
    <div className="inner_blocks" id="left-block">
      {showHelpAndFeedback &&
        <HelpAndFeedbackSS toggleModal={() => { setShowHelpAndFeedback(!showHelpAndFeedback) }} {...modalHeadingObj} formName={formName} />
      }
      <img src={AppConstant.commonStrings.scheduleProBG} alt="a truck" id="truck_img" />
      <img src={TruckOverlay} alt="a truck" id="truck_img_overlay" />
      <div id="bottom-section">
        <div>
          Hey, looking for something specific ?
        </div>
        <div id="btn-container">
          <button
            className="modal_btns"
            onClick={() => {
              setModalHeadingObj({
                heading: AppConstant.help.titleTextForHelp,
                isIcon: true,
                iconClass: 'fa-solid fa-dharmachakra'
              })
              setFormName(AppConstant.help.formName);
              toggleModal()
            }}
          >
            <i className="fa-solid fa-dharmachakra"></i>
            <span className="btn_title">{AppConstant.login.help_text}</span>
          </button>
          <button
            className="modal_btns"
            onClick={() => {
              setModalHeadingObj({
                heading: AppConstant.help.titleTextForFeedback,
                isIcon: true,
                iconClass: 'fa-regular fa-message'
              })
              setFormName(AppConstant.feedback_text)
              toggleModal()
            }}
          >
            <i className="fa-regular fa-message"></i>
            <span className="btn_title">{AppConstant.login.feedback_text}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default TruckComponent;
