import React from 'react'
import { useSelector } from 'react-redux';
import { getUserSelector } from '../../../redux/reducers/reducerSlices/UserReducer';
import Header from '../Header/Header';
import Navigationbar from '../Navigationbar/Navigationbar';
import SettingHeading from './Children/SettingsHeading/SettingsHeading';
import SettingsWrapper from './Wrapper/SettingsWrapper';

/**
 * This component the main wrapper component for settings feature
 * @param {*} props 
 * @returns 
 */
const Settings = (props) => {
    const userPermission = useSelector(getUserSelector);
    const {navigationBarList} = userPermission;
    return ( 
        <div className='container-fluid executor'>
        <Header {...props}/>
        <Navigationbar {...props}/>
        {!navigationBarList.length ? <SettingHeading {...props}/> : ''}
        <SettingsWrapper {...props} />
        
    </div>
     );
}
 
export default Settings;