import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppConstant } from '../../../../../Assests/AppConstant';
import { sagaActions } from '../../../../../../sagas/sagaActions';
import Popupold from '../../../../Common/Popup/Popupold';
import FormComponent from '../../SuperUserAdmin/FormComponent/FormComponent';
import CustomButton from '../../../../Common/CustomButton/CustomButton';
import { getSuperAdminSelector } from '../../../../../../redux/reducers/reducerSlices/SuperAdminReducerSlice';
import UserAssignRoleTable from '../Children/UserAssignRoleTable';
import { Link } from 'react-router-dom';
import { getUserSelector } from '../../../../../../redux/reducers/reducerSlices/UserReducer';
import _ from 'lodash';
import { showAlert, sortArrByKey } from '../../../../../Assests/Utility';
import { toast } from 'react-toastify';

const UserAssign = (props) => {

    const dispatch = useDispatch();
    const { themeSelected } = props;
    const user = useSelector(getUserSelector);
    const superAdminUser = useSelector(getSuperAdminSelector);
    const { rolesList } = superAdminUser;
    const { companyGroupList, companyList, allWarehouseList, selectedUser, userType, cu_id, parentWarehouse } = user;
    const [companyGroupListState, setCompanyGroupListState] = useState([]);
    const [companyListState, setCompanyListState] = useState([]);
    const [warehouseListState, setWarehouseListState] = useState([]);
    const [roleListState, setRoleListState] = useState([]);
    const [selectedCompanyGroup, selectCompanyGroup] = useState('');
    const [selectedCompany, selectCompany] = useState('');
    const [warehouseName, setWarehouseName] = useState('');
    const [roleName, setRoleName] = useState('');
    const [selectedUserAssign, setSelectedUserAssign] = useState({})

    useEffect(()=> {
        dispatch({type: sagaActions.UPDATE_COMPANY_GROUP, payload: createCompanyGroupPayload()}) 
    },[])

    useEffect(() => {
        if (selectedCompanyGroup) {
            dispatch({ type: sagaActions.UPDATE_COMPANY, payload: createCompanyPayload() })
        }

    }, [selectedCompanyGroup])

    useEffect(() => {
        if (selectedCompany) {
            dispatch({ type: sagaActions.UPDATE_ALL_WAREHOUSE, payload: { user_type: userType, cu_id: userType === AppConstant.userPermission.usertypes.user ? cu_id: null, company_id: selectedCompany.CompanyId || selectedCompany.CompanyID, warehouseId: userType === AppConstant.userPermission.usertypes.superAdmin ? null :parentWarehouse.ID } })
        }

    }, [selectedCompany])

    useEffect(() => {
        if (warehouseName) {
            dispatch({ type: sagaActions.GET_ROLES_LIST, payload: createRoleListPayload() });
        }
    }, [warehouseName])

    useEffect(() => {
        if(_.size(companyGroupList)){
            let tempcompanyGroupListState = companyGroupList.filter(ele => ele.EnableStatus === true)
            setCompanyGroupListState(tempcompanyGroupListState)
        }
    },[companyGroupList])

    useEffect(() => {
        if(_.size(companyList)){
            let tempcompanyList = companyList.filter(ele => ele.EnableStatus === true)
            setCompanyListState(tempcompanyList)
        }
    },[companyList])

    useEffect(() => {
        if(_.size(allWarehouseList)){
            setWarehouseListState(allWarehouseList)
        }
    },[allWarehouseList])

    useEffect(() => {
        if(_.size(rolesList)){
            setRoleListState(rolesList)
        }
    },[rolesList])

    const createCompanyGroupPayload = () => {
        return {
            company_group_id: null, group_name: null, group_logo: null, contact_number: null, address: null, state: null, zip: null, country: null, locale: null, email: null, enable_status: 0, crud_type: 'S', cu_id: userType === AppConstant.userPermission.usertypes.user ? cu_id: null,
            warehouseId: userType === AppConstant.userPermission.usertypes.superAdmin ? null :parentWarehouse.ID
        }
    }
    const createCompanyPayload = () => {
        return {
            company_group_id: selectedCompanyGroup.CompanyGroupId, company_id: null, company_name: null, company_logo: null, contact_number: null, address: null, state: null, zip: null, country: null, locale: null, email: null, enable_status: 0, crud_type: 'S', cu_id: userType === AppConstant.userPermission.usertypes.user ? cu_id: null,
            warehouseId: userType === AppConstant.userPermission.usertypes.superAdmin ? null :parentWarehouse.ID
        }
    }
    const createRoleListPayload = () => {
        return {
            role_id: null,
            role_name: null,
            role_des: null,
            enable_status: null,
            crud_type: "S"
        }

    }

    const updateUserRolePayload = (key) => {

        let obj = { enable_status: true, cu_id: null, wh_id: null, role_id: null, crud_type: null, updated_object: null, comp_group_id: null, comp_id: null, user_wh_role_id: null }
        switch (key) {
            case "S":
                obj.cu_id = selectedUser.CUID
                obj.crud_type = 'S'
                return obj
            case 'U':
                obj.cu_id = selectedUser.CUID;
                obj.wh_id = warehouseName.ID;
                obj.role_id = roleName.RoleID;
                obj.crud_type = 'U';
                obj.comp_group_id = selectedCompanyGroup.CompanyGroupId;
                obj.comp_id = selectedCompany.CompanyId || selectedCompany.CompanyID;
                obj.user_wh_role_id = selectedUserAssign.UserWHRoleID
                return obj
            case 'I':
                obj.cu_id = selectedUser.CUID;
                obj.wh_id = warehouseName.ID;
                obj.role_id = roleName.RoleID;
                obj.crud_type = 'I';
                obj.comp_group_id = selectedCompanyGroup.CompanyGroupId;
                obj.comp_id = selectedCompany.CompanyId || selectedCompany.CompanyID;
                obj.user_wh_role_id = null
                return obj
            // case "D":
            //     obj.cu_id = selectedUser.CUID
            //     obj.crud_type = 'D'
            //     obj.wh_id= selectedUserAssign.WarehouseID
            //     obj.enable_status = false
            //     return obj
            default:
                break;

        }
    }

    const createComapnyGroupList = () => {

        if (_.isEmpty(companyGroupListState)) {

            return checkDropDownIsEmpty()
        }

        const companyGroupArr = companyGroupListState && sortArrByKey(companyGroupListState, 'GroupName').map((ele) => {
            return <li><a className="dropdown-item" onClick={() => {
                selectCompanyGroup(ele)
                selectCompany('')
                setWarehouseName('')
            }
            }>{ele.GroupName}</a></li>  
        })
        return [...companyGroupArr]
    }
    const createComapnyList = () => {
        if (_.isEmpty(companyListState)) {

            return checkDropDownIsEmpty()
        }
        const companyArr = companyListState && sortArrByKey(companyListState, 'CompanyName').map((ele) => {
            return <li><a className="dropdown-item" onClick={() => {
                selectCompany(ele)
                setWarehouseName('')
            }}>{ele.CompanyName}</a></li>
        })

        return [...companyArr]
    }

    const createWarehouseList = () => {
        if (_.isEmpty(warehouseListState)) {

            return checkDropDownIsEmpty()
        }
        const warehouseArr = warehouseListState && sortArrByKey(warehouseListState, 'WarehouseName').map((ele) => {
            return <li><a className="dropdown-item" onClick={() => setWarehouseName(ele)}>{ele.WarehouseName}</a></li>
        })

        return [...warehouseArr]
    }
    const filterRolesArr = () => {
        let filteredRoleList = [];
        if(userType === AppConstant.userPermission.usertypes.user){
            filteredRoleList = rolesList.filter(el =>  {
                let roleName = el.RoleName.toLowerCase();
                if(roleName.includes('manager') || roleName.includes('basic') || roleName.includes('executor')) return el;
            })
        }else{
            filteredRoleList = rolesList
        }
        return filteredRoleList
    }

    const createRoleList = () => {
        let tempRoleList = [];
        if (_.isEmpty(roleListState)) {
            return checkDropDownIsEmpty()
        }else{
            tempRoleList = filterRolesArr()
        }
        
        const roleArr = tempRoleList && sortArrByKey(tempRoleList, 'RoleName').map((ele) => {
            return ele.EnableStatus ? <li><a className="dropdown-item" onClick={() => setRoleName(ele)}>{ele.RoleName}</a></li> : ''
        })

        return [...roleArr]
    }

    const checkDropDownIsEmpty = () => {
        return <div className="text-center">
            <span>{AppConstant.poManagement.emptyTable.text1}</span>
      </div>
    }

    const getWarehouseFormContent = () => {
        return ([
            {
                type: 'dropDown',
                dataArr: createComapnyGroupList(),
                selectedItem: selectedCompanyGroup?.GroupName || AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.selectCompanyGroupTitle,
                rowNum: 0,
                extraComponent: null
            },
            {
                type: 'dropDown',
                themeSelected: themeSelected,
                dataArr: createComapnyList(),
                selectedItem: selectedCompany?.CompanyName || AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.selectCompanyTitle,
                rowNum: 1,
                extraComponent: null
            },
            {
                type: 'dropDown',
                themeSelected: themeSelected,
                dataArr: createWarehouseList(),
                selectedItem: warehouseName?.WarehouseName || AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.selectWarehouseTitle,
                rowNum: 2,
                extraComponent: null
            },
            {
                type: 'dropDown',
                themeSelected: themeSelected,
                dataArr: createRoleList(),
                selectedItem: roleName?.RoleName || AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.selectRolesTitle,
                rowNum: 3,
                extraComponent: null
            },
        ]
        )
    }

    const saveUserRole = (text) => {
        if (text === AppConstant.commonStrings.save) {
            if (!_.isEmpty(selectedCompanyGroup) && !_.isEmpty(selectedCompany) && !_.isEmpty(warehouseName) && !_.isEmpty(roleName)) {
                    dispatch({ type: sagaActions.UPDATE_USER_ROLE_ASSIGN, payload: updateUserRolePayload('I') })
                dispatch({ type: sagaActions.INITIATE_LOADING, payload: true })

            } else {
                showAlert(toast.TYPE.ERROR, AppConstant.user.toast.updateUserRoleFailed, themeSelected)
            }
        } else if (text === AppConstant.commonStrings.update) {
            if (!_.isEmpty(selectedCompanyGroup) && !_.isEmpty(selectedCompany) && !_.isEmpty(warehouseName) && !_.isEmpty(roleName)) {
                dispatch({ type: sagaActions.UPDATE_USER_ROLE_ASSIGN, payload: updateUserRolePayload('U', selectedUserAssign) })
                dispatch({ type: sagaActions.INITIATE_LOADING, payload: true })

            } else {
                showAlert(toast.TYPE.ERROR, AppConstant.user.toast.editUserRoleFailed, themeSelected)
            }
        }

    }
    const resetState = () => {
        selectCompanyGroup('')
        selectCompany('')
        setWarehouseName('')
        setRoleName('')
        setCompanyListState([])
        setRoleListState([])
        setWarehouseListState([])
    }
    const showUserAssignRolePopup = () => {

        const createForm = () => {
            return (
                <div className='container-fluid py-2' themeSelected={themeSelected}>
                    <FormComponent contentArr={getWarehouseFormContent()} />
                </div>
            )
        }

        const popupObj = {
            id: 'userAssignRolesFormPopup',
            modalLabel: 'userAssignRolesFormPopup',
            showClose: true,
            showfooter: true,
            showHeader: true,
            sendDriverNotification: false,
            className: "createUserPopup",
            modalSize: 'md',
            title: AppConstant.commonStrings.assign,
            bodyText: createForm(),
            subText: "",
            keyboard: true,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.comments.cancelCommentButtonTitle
                },
                btn2: {
                    show: true,
                    text: AppConstant.commonStrings.save
                }
            },
        }

        return <Popupold {...popupObj} popupBtnHandler={(text) => saveUserRole(text)} themeSelected={themeSelected} closepopup={closepopup} />
    }
    const closepopup = () => {
        // setResetFormData(new random());
    }
    const editUserAssignRolePopup = () => {
        const createForm = () => {
            return (
                <div className='container-fluid py-2' themeSelected={themeSelected}>
                    <FormComponent contentArr={getWarehouseFormContent()} />
                </div>
            )
        }

        const popupObj = {
            id: 'editUserAssignRolesFormPopup',
            modalLabel: 'editUserAssignRolesFormPopup',
            showClose: true,
            showfooter: true,
            showHeader: true,
            sendDriverNotification: false,
            className: "createUserPopup",
            modalSize: 'md',
            title: AppConstant.commonStrings.edit,
            bodyText: createForm(),
            subText: "",
            keyboard: true,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.comments.cancelCommentButtonTitle
                },
                btn2: {
                    show: true,
                    text: AppConstant.commonStrings.update
                }
            },
        }

        return <Popupold {...popupObj} popupBtnHandler={(text) => saveUserRole(text)} themeSelected={themeSelected} />
    }
    return (
        <div className='mt-2'>
            <Link to="/user" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                <span className='p-2 cursor-pointer'><i className='fa fa-arrow-left'></i></span>
            </Link>
            <CustomButton
                title={AppConstant.user.userAsssignRoleBtnTitle}
                className={'addButton'}
                isLoading={false}
                disabled={false}
                dataBsToggle="modal"
                dataBsTarget={`#userAssignRolesFormPopup`}
                onClick={() => { resetState() }}
            >
            </CustomButton>
            <div className="roleListTable">
                <UserAssignRoleTable {...props} updateUserRolePayload={(key, user) => updateUserRolePayload(key, user)}
                    setSelectedUserAssign={setSelectedUserAssign}
                    selectCompanyGroup={selectCompanyGroup}
                    selectCompany={selectCompany}
                    setWarehouseName={setWarehouseName}
                    setRoleName={setRoleName} />
            </div>
            {showUserAssignRolePopup()}
            {editUserAssignRolePopup()}
        </div>
    )
}

export default UserAssign;