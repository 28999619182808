import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash-es'
import { AppConstant } from '../../Assests/AppConstant';
import { getUserSelector } from '../../../redux/reducers/reducerSlices/UserReducer';
import { sagaActions } from '../../../sagas/sagaActions';
import '../ChooseWarehouse/ChooseWarehouse.css'
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../Loader/Loader';
import NoData from '../LandingPage/Components/Dashboard/DefaultDashboard/DrillDownReports/Common/Nodata';
import './UserWarehouseSS.css'
import CustomButtonSS from '../Common/CustomButton/CustomButtonSS';
import SSPopup from '../Common/Popup/SSPopup/SSPopup';
import { removeInterceptor } from '../../../redux/api/Interceptor';
import { showAlert } from '../../Assests/Utility';
import { toast } from 'react-toastify';

/**
 * This component renders warehouse card with new UI to which user has access.
 * User can access any warehouse to login if have access to multiple warehouse
 * @param {*} props 
 * @returns 
 */
const UserWarehouseSS = (props) => {
    const { themeSelected } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const userPermission = useSelector(getUserSelector);
    const { userWarehouseList, userWarehouseEmptyList, userType, initateLoading, parentWarehouse } = userPermission;
    const loaderArrayRow = [0, 1, 2];
    let loaderArrayColumn = [...Array(3).keys()]
    const [userWarehouseListState, setUserWarehouseListState] = useState([])
    const [showModal, setShowModal] = useState(false);

    /**
    * useEffect for warehouse management data and set data to state variable allWarehouseListState
    */
    useEffect(() => {
        if (userWarehouseList.length > 1) {
            setUserWarehouseListState(userWarehouseList)
        }

    }, [userWarehouseList])

    /**
     * Proceed button click handler function
     * @param {*} ele 
     */
    const onBtnClickHandler = (ele) => {
        const tempUserWarehouseList = userWarehouseListState.map(el => {
            let tempEl = { ...el }
            if (ele.ID === el.ID) {
                tempEl.isloading = !ele.isloading
            }
            return tempEl
        })
        setUserWarehouseListState(tempUserWarehouseList)
        if (userType === AppConstant.userPermission.usertypes.user && _.isEmpty(parentWarehouse)) {
            dispatch({ type: sagaActions.PARENT_WAREHOUSE, payload: ele })
        }
        dispatch({ type: sagaActions.UPDATE_SELECTED_WAREHOUSE, payload: ele })
    }
    /**
    * 
    * @returns JSX for warehouse card
    */
    const createWarehouseCards = () => {
        const warehouseArr = userWarehouseListState.map((ele, index) => {
            return (
                <div key={ele + index} className='warehosue-card-wrapper p-1 m-2 justify-content-start text-start shadow ' >
                    <div className=' warehouse-header-ss p-2'>
                        🏭 {_.upperCase(ele.RoleName) || '-'}

                    </div>

                    <div className='warehouseCardText-ss p-3'>
                        <div className='m-0 p-0 text-grey f-12'><span className='font-default'><i className="fa-solid fa-warehouse text-grey"></i></span> {AppConstant.commonStrings.warehouse}</div>
                        <div className='my-2 p-0 '>{ele.Name || '-'}</div>

                        <div className='mt-4 p-0 text-grey f-12'><span className='font-default'><i className="fa-solid fa-location-dot text-grey"></i></span> {AppConstant.commonStrings.location}</div>
                        <div className='my-2 p-0 '>{ele.Address || '-'}</div>
                        {ele.City && ele.Zip && ele.State && ele.Country ? <div className='m-0 p-0 text-truncate'>{ele.City || '-'} ,{ele.Zip},{ele.State || '-'},{ele.Country || '-'}</div> : <div className='m-0 p-0 text-truncate'>-</div>}
                        <br />
                        <br />

                        <CustomButtonSS
                            isLoading={ele.isloading}
                            title={AppConstant.commonStrings.proceed}
                            className={`btn-highlighted w-100 f-14 mt-3`}
                            onClick={() => onBtnClickHandler(ele)}
                        />
                    </div>
                </div>
            )
        })
        return [...warehouseArr]
    }
    /**
    * 
    * @returns JSX for list warehouse card
    */
    const addWarehouseCards = () => {
        return (
            <div>
                <div className="d-flex flex-row flex-wrap w-100 justify-content-center">
                    {createWarehouseCards()}
                </div>
            </div>

        )
    }
    /**
     * 
     * @returns JSX for loader
     */
    const showLoader = () => {
        return loaderArrayRow.map((_, i) => {
            return (
                <tr key={i}>

                    {
                        loaderArrayColumn.map((__, j) => {
                            return (
                                <td key={j} className='p-2'>
                                    <Loader height={350} count={1} highlightColor={themeSelected === 'dark-mode' ? '#151515' : ''}
                                        baseColor={themeSelected === 'dark-mode' ? '#575757'
                                            : ''}
                                    />
                                </td>
                            )

                        })
                    }

                </tr>
            )
        })
    }
    /**
     * 
     * @returns JSX for loader/no data/list of warehouse card
     */
    const renderWarehouse = () => {
        if (userWarehouseEmptyList) {
            return <div className='d-flex justify-content-center'><NoData themeSelected={themeSelected} /></div>
        } else if (initateLoading) {
            return (
                <table className='w-80vw' data-testid="loader">
                    <thead></thead>
                    <tbody>{showLoader()}</tbody>
                </table>
            )
        } else if (!initateLoading && userWarehouseList.length) {
            return addWarehouseCards()
        }
    }

    const logoutPopupHandler = (text) => {
        if (text === AppConstant.commonStrings.yes) {
            removeInterceptor()
            dispatch({ type: sagaActions.CLEAR_SIGNEDIN_USER })
            dispatch({ type: sagaActions.RESET_USER })
            dispatch({type: sagaActions.CLEAR_SMART_SCHEDULE_REDUCER_DATA})
            dispatch({ type: sagaActions.RESET_PAYTRACE_DATA });
            const dateRangeObjTemp = { 'start': '', 'end': '' }
            dispatch({ type: sagaActions.UPDATE_DATE_RANGE, payload: dateRangeObjTemp })
            showAlert(toast.TYPE.INFO, AppConstant.login.logout, 'light')
            window.localStorage.clear();
            navigate("/")
            // If logging out from UserWarehouse Page then trigger branding API again
            if (location.pathname === '/') {
                dispatch({ type: sagaActions.INITIATE_BRANDING_LOADER, payload: true })
                const subdomain = window.location.hostname.split(".")[0];
                dispatch({
                    type: sagaActions.INITIATE_BRANDING, payload: {
                        subdomain: subdomain
                    }
                });
            }

        }else closepopup();
    }


    const closepopup = () => {
        setShowModal(false);
    }

    const showLogoutPopup = () => {
        return <SSPopup {...AppConstant.settings.logoutPopupAttr} popupBtnHandler={(text) => {
            setShowModal(true);
            logoutPopupHandler(text)
        }} themeSelected={themeSelected} showModal={showModal} closepopup={closepopup} />
    }

    const clickHandler = () => {
        setShowModal(true);
    }

    return (
        <div className='p-3' data-testid="chooseWarehouse">
            <div className='d-flex justify-content-end'>
            <CustomButtonSS
                title={AppConstant.settings.dropdownMenu.logout}
                className={"btn-logout mb-2"}
                onClick={clickHandler}
                isIcon={true}
                iconClass={'fa-regular fa-power-off'}
            />
            </div>
            {showLogoutPopup()}
            <div className={`container-fluid executor-ss d-flex justify-content-center align-items-center  ${themeSelected}`}>
                <div>
                    <div className='container-fluid d-flex flex-column justify-content-center align-items-center'>
                        <div className=' text-center f-20 fw-bold'>{AppConstant.userPermission.selectWarehouse}</div>
                        <div className=' text-center f-14 text-grey'>{AppConstant.userPermission.selectWarehouseSubText}</div>

                        <div className='m-0 p-0 warehouseListParent superwarehouse w-100'>
                            {renderWarehouse()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserWarehouseSS;