import React from 'react';
import './AdjustableTextComponent.css'
import { AppConstant } from '../../../Assests/AppConstant';
/**
 * This is a reusable component that adjust long text with Show more or Shor less button
 * @param {*} props 
 * className - user defined class
 * text - data text
 * wordlLimit - number of words to be displayed
 * onClickHandler - Button Click handler
 * showAll, indexFaq, showAllButton
 * @returns 
 */
const AdjustableTextComponent = (props) => {

    const { className, text, wordlLimit, onClickHandler, showAll, indexFaq, showAllButton } = props;

    return (
        <div data-testid='adjustable_text_component' className={`mx-2 p-0 ${ showAll[indexFaq].showExpanded &&  text.length > 100 ? 'text-start' : 'text-center'} ${className}`}>
            {
                showAll[indexFaq].id === indexFaq && showAll[indexFaq].showExpanded ?
                    <div>{text}</div>
                    :
                    <div>{`${text.substring(0, wordlLimit)}${showAll[indexFaq].showExpanded ? '...' : ''}`}</div>
            }
            {
                showAllButton ?
                    <div
                        className={`d-flex justify-content-end showMoreButton`}
                        onClick={() => onClickHandler(indexFaq)}
                        data-testid={AppConstant.dataTestId.textToggle}
                    >
                        {
                            showAll[indexFaq].id === indexFaq && showAll[indexFaq].showExpanded ? 'show less...' : 'show more...'
                        }
                    </div>
                    :
                    <div
                        className={`d-flex justify-content-end showMoreButton`}
                        onClick={() => onClickHandler(indexFaq)}>
                        {
                            <br />
                        }
                    </div>
            }
        </div>
    )

}

export default AdjustableTextComponent;