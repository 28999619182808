import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import CommonChart from '../../../../../Dashboard/DefaultDashboard/Graphs/CommonChart';
import { AppConstant } from '../../../../../../../../Assests/AppConstant';
import { formatDate_MM_DD_YYYY } from '../../../../../../../../Assests/Utility';
import DatePickerSS from '../../../../../../../DatePickerSS/DatePickerSS';

/**
 * component for appointment report graph / chart
 * @param {*} props 
 * @returns 
 */
const AppointmentReportSummaryChart = (props) => { 
    const {getSubRoot} = props;
   
    const { apptReportSummarydonut, themeSelected } = props;
   
    

    return ( 
        <div className={` me-2 ${themeSelected} bg-transparent`} style={{ marginRight: '10px' }}>
            <div style={{ color: 'inherit', textDecoration: 'inherit', cursor: 'pointer'}}>
            {/* <Link to="/analytics/reports/doorstatus" style={{ color: 'inherit', textDecoration: 'inherit'}}> */}
            
                    <div className='d-flex flex-row justify-content-around doorStatusChart' onClick={getSubRoot}>
                        <div className="responsive-chart">{apptReportSummarydonut ? <CommonChart data={apptReportSummarydonut} /> : ''}</div>
                </div>
                </div>
                {/* </Link> */}
                </div>
     );
}
 
export default AppointmentReportSummaryChart;