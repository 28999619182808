import React from 'react';
import { Link } from 'react-router-dom';
import DownloadSection from '../../LandingPage/Components/DownloadSection/DownloadSection';
import '../Navigationbar.css';
import DownloadSectionBE from '../../LandingPage/Components/DownloadSection/DownloadSectionBE';
import DownloadSectionBECustomFeilds from '../../LandingPage/Components/DownloadSection/DownloadSectionBECustomFeilds';
import { sagaActions } from '../../../../sagas/sagaActions';
import { useDispatch } from 'react-redux';
import CustomButtonSS from '../../Common/CustomButton/CustomButtonSS';
const DrillDownSubNavigation = (props) => {
  const dispatch = useDispatch();

  const {
    themeSelected,
    root,
    subRoot,
    currentTable,
    currentTableRef,
    downloadDetentionStatusReport,
    isDownloadLoading,
    manualRefresh } = props;
  //Colums List for download column specific report
  const getLoadStatusSubRootColumnList = () => {
    const { subPathName } = subRoot;
    if (subPathName === "Not Arrived") {
      return [
        { value: "PoNum", name: "PO" },
        { value: "CarrierName", name: "Carrier" },
        { value: "Vendorname", name: "Vendor" },
        {
          value: "Appointment",
          name: "Appt Time",
          isDate: true,
          isTime: true,
        },
      ];
    } else if (subPathName === "Staging") {
      return [
        { value: "PoNum", name: "PO" },
        { value: "CarrierName", name: "Carrier" },
        { value: "Vendorname", name: "Vendor" },
        {
          value: "Appointment",
          name: "Appt Time",
          isDate: true,
          isTime: true,
        },
        {
          value: "CheckedIn",
          name: "Checked in",
          isDate: true,
          isTime: true,
        },
        { value: "StagingDwell", name: "Staging Dwl (mins)" },
      ];
    } else if (subPathName === "Docking") {
      return [
        { value: "PoNum", name: "PO" },
        { value: "CarrierName", name: "Carrier" },
        { value: "Vendorname", name: "Vendor" },
        {
          value: "Appointment",
          name: "Appt Time",
          isDate: true,
          isTime: true,
        },
        {
          value: "CheckedIn",
          name: "Checked in",
          isDate: true,
          isTime: true,
        },
        {
          value: "DoorAssigned",
          name: "Door Assigned",
          isDate: true,
          isTime: true,
        },
        { value: "DockingDwell", name: "Dock Dwl (mins)" },
      ];
    } else if (subPathName === "Unloading") {
      return [
        { value: "PoNum", name: "PO" },
        { value: "CarrierName", name: "Carrier" },
        { value: "Vendorname", name: "Vendor" },
        {
          value: "Appointment",
          name: "Appt Time",
          isDate: true,
          isTime: true,
        },
        {
          value: "CheckedIn",
          name: "Checked in",
          isDate: true,
          isTime: true,
        },
        {
          value: "DoorAssigned",
          name: "Door Assigned",
          isDate: true,
          isTime: true,
        },
        {
          value: "UnloadingStart",
          name: "Unloading Start",
          isDate: true,
          isTime: true,
        },
        { value: "UnloadingDwell", name: "Unloading Dwl (mins)" },
      ];
    } else if (subPathName === "Paperwork") {
      return [
        { value: "PoNum", name: "PO" },
        { value: "CarrierName", name: "Carrier" },
        { value: "Vendorname", name: "Vendor" },
        {
          value: "Appointment",
          name: "Appt Time",
          isDate: true,
          isTime: true,
        },
        {
          value: "CheckedIn",
          name: "Checked in",
          isDate: true,
          isTime: true,
        },
        {
          value: "DoorAssigned",
          name: "Door Assigned",
          isDate: true,
          isTime: true,
        },
        {
          value: "UnloadingStart",
          name: "Unloading Start",
          isDate: true,
          isTime: true,
        },
        {
          value: "UnloadingEnd",
          name: "Unloading End",
          isDate: true,
          isTime: true,
        },
        { value: "PaperWorkDwell", name: "Paperwork Dwl (mins)" },
      ];
    } else {
      return [
        { value: "PoNum", name: "PO" },
        { value: "CarrierName", name: "Carrier" },
        { value: "Vendorname", name: "Vendor" },
        {
          value: "Appointment",
          name: "Appt Time",
          isDate: true,
          isTime: true,
        },
        {
          value: "CheckedIn",
          name: "Checked in",
          isDate: true,
          isTime: true,
        },
        {
          value: "DoorAssigned",
          name: "Door Assigned",
          isDate: true,
          isTime: true,
        },
        {
          value: "UnloadingStart",
          name: "Unloading Start",
          isDate: true,
          isTime: true,
        },
        {
          value: "UnloadingEnd",
          name: "Unloading End",
          isDate: true,
          isTime: true,
        },
        { value: "Released", name: "Released" },
        { value: "StagingDwell", name: "Staging Dwl (mins)" },
        { value: "DockingDwell", name: "Dock Dwl (mins)" },
        { value: "UnloadingDwell", name: "Unloading Dwl (mins)" },
        { value: "PaperWorkDwell", name: "Paperwork Dwl (mins)" },
      ];
    }
  };

  //Get Detention Status Column list
  const getDetentionStatusColumnList = () => {
    return [
      { value: "PoNumbers", name: "PO" },
      {
        value: "AppointmentDateTime",
        name: "Appt Time",
        isDate: true,
        isTime: true,
      },
      { value: "CheckInTime", name: "Checked in", isDate: true, isTime: true },
      {
        value: "BillsOutTime",
        name: "Bills Out Time",
        isDate: true,
        isTime: true,
      },
      { value: "CarrierName", name: "Carrier Name" },
      { value: "Vendor", name: "Vendor" },
      { value: "DockName", name: "Dock" },
      { value: "Trailer", name: "Trailer" },
      { value: "Items", name: "Items" },
      { value: "Cases", name: "Cases" },
      { value: "Detention_Time", name: "Detention (hr)" },
    ];
  };

  //Get Detention Status Column list
  const getAppointmentComplianceColumnList = () => {
    return [
      { value: "PoNum", name: "PO" },
      {
        value: "Appointment",
        name: "Appt Time",
        isDate: true,
        isTime: true,
      },
      { value: "CheckedIn", name: "Checked in", isDate: true, isTime: true },
      { value: "CarrierName", name: "Carrier Name" },
      { value: "VendorName", name: "Vendor" },
      {
        value: "Compliance", name: "Compliance (hr)",
        isCustomValue: true
      },
    ];
  };

  //Get Column List
  const getColumnList = () => {
    switch (subRoot?.pathName) {
      case "loadstatus":
        return getLoadStatusSubRootColumnList();
        break;
      case "detentionstatus":
        return getDetentionStatusColumnList();
        break;
      case "appointmentcompliance":
        return getAppointmentComplianceColumnList();
        break;
      default:
        break;
    }
  };

  //Call Download Report API
  const downloadReport = (payload) => {
    dispatch({ type: sagaActions.DOWNLOAD_REPORT_COMMON, payload });
  };

  return (
    <div className="px-3 py-1 setBorderBottom sub dd-subnav">
      <div className="pt-2 d-flex justify-content-between">
        <div>
          <Link
            to="/analytics"
            style={{ color: "inherit", textDecoration: "inherit" }}
          >
            <span>
              <i className="fa fa-arrow-left"></i>
            </span>
            <span className="ps-2">{root || "--"}</span>
          </Link>
          <span> / </span>
          <span className="boldText">{subRoot.name || "--"}</span>
          {subRoot?.subPathName ? (
            <>
              <span> / </span>
              <span className="boldText">{subRoot.subPathName || "--"}</span>
            </>
          ) : (
            ""
          )}
        </div>
        <div className='d-flex justify-content-between'>
          {subRoot.pathName === "detentionstatus" ||
            subRoot.pathName === "loadstatus" || subRoot.pathName === "appointmentcompliance" ? (
            <DownloadSectionBECustomFeilds
              downloadData={downloadDetentionStatusReport}
              isLoading={isDownloadLoading}
              isDisabled={isDownloadLoading}
              columnList={getColumnList()}
              downloadReport={downloadReport}
            />
          ) : subRoot.pathName === "doorAssignment" ? (
            <DownloadSectionBE
              downloadData={downloadDetentionStatusReport}
              isLoading={isDownloadLoading}
              isDisabled={isDownloadLoading}
              downloadReport={downloadReport}
            />
          ) : (
            <DownloadSection
              root={root}
              subRoot={subRoot}
              themeSelected={themeSelected}
              currentTable={currentTable}
              currentTableRef={currentTableRef}
            />
          )}


          {(subRoot.pathName === "detentionstatus" || subRoot.pathName === "loadstatus" || subRoot.pathName === "appointmentcompliance") ?
            <div className='mx-2'>
              <CustomButtonSS
                title=''
                isLoading={false}
                isToolTip={true}
                dataTip={"Refresh"}
                dataFor={"tooltip_id"}
                className={`carrier-po-btn-small`}
                iconClass={"fa-solid fa-rotate-right"}
                onClick={() => {
                  manualRefresh()
                }}
                isIcon={true}
              />
            </div> : ""
          }

        </div>
      </div>
    </div>
  );
}

export default DrillDownSubNavigation;