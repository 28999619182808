import './TruckLoadSectionHeaders.css';
/**
 * This is a reusable component that render headers for table
 * @param {*} props
 * title = Heading title 
 * @returns 
 */
const Header = (props) => {
    return (
        <div className="header">
            {props.title}
        </div>
    )
}

export default Header;