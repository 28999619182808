import _ from 'lodash';
import { call, put, takeEvery } from 'redux-saga/effects';
import { Uchain } from '../redux/api/agent';
import { assignDockFailure, assignDockSuccess, clearAssignedDock, clearError, initiateAssignDoor } from '../redux/reducers/reducerSlices/AssignDockReducer';
import { sagaActions } from './sagaActions';

function* assignDockSaga(data) {
    try {
        yield put(initiateAssignDoor());
        const response = yield call(Uchain.assignDock, data.payload);
        yield put(assignDockSuccess(response));
    } catch {
        yield put(assignDockFailure());
    }
}

function* clearAssignDockSaga() {
    try {
        yield put(clearAssignedDock());
    } catch { }
}

function* clearAssignDockErrorSaga() {
    try {
        yield put(clearError());
    } catch { }
}

export function* assignDock() {
    yield takeEvery(sagaActions.ASSIGN_DOCK, assignDockSaga)
}

export function* clearAssignDock() {
    yield takeEvery(sagaActions.CLEAR_ASSIGN_DOCK, clearAssignDockSaga)
}

export function* clearAssignDockError() {
    yield takeEvery(sagaActions.CLEAR_ASSIGN_DOCK_ERROR, clearAssignDockErrorSaga)
}

