import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    error: null,
    loading: false,
    data:''
} 

export const FileUploadReducer = createSlice({
    name: 'fileUploadReducer',
    initialState,
    reducers: {
        initiateLoading: (state) => {
          state.loading = true
         },
         addFileUploadSuccess: (state, action) => {
           state.loading = false;
            state.data = action.payload;
            state.error = null;
        },
        addFileUploadFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.data = '';
        },
        resetData:(state,action)=>{
            state.loading = false;
            state.data = ''; 
            state.error = null;
        }
    }
});

export const { addFileUploadSuccess, addFileUploadFailure,initiateLoading,resetData} = FileUploadReducer.actions;

// Selectors
export const getFileUploadReducer = (state) => {
    return state.fileUploadReducer;
};

export default FileUploadReducer.reducer;
