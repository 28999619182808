import React, { useEffect, useState } from "react"
import _ from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { getReportsSelector } from '../../../../../../../../redux/reducers/reducerSlices/ReportsReducerSlice';
import { sagaActions } from '../../../../../../../../sagas/sagaActions';
import DrilldownTable from '../../../../Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTable';
import DrilldownTableBody from '../../../../Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTableBody'
import { AppConstant } from "../../../../../../../Assests/AppConstant";

const ScheduledLoadStatusList = (props) => {
    const dispatch = useDispatch();
    const { selectedLumpingFacility, selectedWarehouse, selectedDock } = props;
    const { themeSelected } = props;
    const reportsSelector = useSelector(getReportsSelector);
    const { scheduledLoadStatusList } = reportsSelector;
    const [scheduledLoadStatusArr, setScheduledLoadStatusArr] = useState([]);

    const scheduledLoadTitleDD = [
        { id: 'BillingCode', name: AppConstant.reports.scheduledLoadStatus.tableHeaders.billingCode },
        { id: 'ScheduledCarrier', name: AppConstant.reports.scheduledLoadStatus.tableHeaders.scheduledCarrier },
        { id: 'Carrier', name: AppConstant.reports.scheduledLoadStatus.tableHeaders.carrierName },
        { id: 'ScheduledTime', name: AppConstant.reports.scheduledLoadStatus.tableHeaders.scheduledTime },
        { id: 'CheckInTime', name: AppConstant.reports.scheduledLoadStatus.tableHeaders.checkInTime },
        { id: 'POs', name: AppConstant.reports.scheduledLoadStatus.tableHeaders.pos },
    ]

    useEffect(() => {
        if (scheduledLoadStatusList && scheduledLoadStatusList.length) {

            setScheduledLoadStatusArr(scheduledLoadStatusList);
        }
    }, [scheduledLoadStatusList])

    const formatListDataDrillDown = (data) => {
        return data.map((el, index) => {
            return {
                id: index,
                0: el.BillingCode,
                1: el.ScheduledCarrier,
                2: el.Carrier,
                3: el.ScheduledTime,
                4: el.CheckInTime,
                5: el.POs,
            }
        })
    }

    return (
        <div className="mx-2" id="">
            
            <DrilldownTable
                themeSelected={themeSelected}
                bodyData={scheduledLoadStatusArr.length && formatListDataDrillDown(scheduledLoadStatusArr)}
                titleData={scheduledLoadTitleDD}
                showCollapse={false}
                showInput={false}
                showSort={false}
                arrangeOrder={''}
                arrangeKey={''}
                drillDownReport={scheduledLoadStatusArr}
                initiateDrillDownReport={false}
                loaderArray={6}
                sortingAction={''}
                subRoot={{ pathName: '' }}
                showUTCTime={true}
            />
        </div>
    )
}

export default ScheduledLoadStatusList;