import React from 'react'
import Countdown from 'react-countdown';
/**
 * This is reusable component to display count down timer
 * It uses 3rd party Countdown timer library react-countdown
 * @param {*} props 
 * renderer, countDown, from, setRef, onStart, onTick, key props are required by library
 * @returns 
 */
const CountDownTimer = (props) => {
    const { renderer, countDown, from, setRef, onStart, onTick, key } = props
    return (
        <Countdown key={key} onTick={onTick} ref={setRef} autoStart={false} date={from + countDown} renderer={renderer} />
    )
}

export default CountDownTimer
