import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash-es';
import { formDropDownData, showAlert, convertHrOrMinToMiliSeconds, convertMiliSecondsToHrOrMin } from '../../../../../Assests/Utility';
import { AppConstant } from '../../../../../Assests/AppConstant'
import { sagaActions } from '../../../../../../sagas/sagaActions';
import WarehouseSearch from '../../../../SuperWarehouse/Components/SearchWarehouse/WarehouseSearch';
import "react-datepicker/dist/react-datepicker.css";
import "../../../../../Components/DatePicker/DatePicker.css"
import { CustomPaginationSS } from '../../../../Common/CustomPaginationSS/CustomPaginationSS';
import { getRemoteConfigSelector } from '../../../../../../redux/reducers/reducerSlices/RemoteConfigReducer';
import DrilldownTableSS from '../../Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTableSS';
import { getFsWarehouseselector } from '../../../../../../redux/reducers/reducerSlices/FsWarehouseReducer';
import { toast } from 'react-toastify';
import "./FsWarehouse.css";
import CustomButtonSS from '../../../../Common/CustomButton/CustomButtonSS';
import Popup from '../../../../Common/Popup/Popup';
import { CustomDynamicForm } from '../../../../Common/CustomForm/CustomDynamicForm';
import moment from 'moment-timezone';


/**
 * This component is used to get the FS warehouse details and perform actions like create, edit, delete and select. 
 * And also this screen has search with warehouse name and pagination
 * @param {*} props 
 * @returns 
 */
const FsWarehouse = (props) => {
    const { themeSelected = 'light-mode' } = props;
    const dispatch = useDispatch();
    const [searchStr, setSearchStr] = useState('');
    const divRef = useRef(null);
    const remoteConfig = useSelector(getRemoteConfigSelector);
    const { allConfigration } = remoteConfig;
    // const { poTypeList } = remoteConfig;
    const { PaginationDefaultLimit } = allConfigration
    const fsWarehouseReducer = useSelector(getFsWarehouseselector)
    const { getFsWarehouseData, isLoading, operationObj } = fsWarehouseReducer;
    const [fsWarehouseLoadArr, setFsWarehouseLoadArr] = useState([]);
    const [selectedObj, setSelectedObj] = useState(null)
    const [selectedIndex, setSelectedIndex] = useState(null)
    const [showDeleteModel, setShowDeleteModel] = useState(false);
    const [currentItemIndex, setCurrentItemIndex] = useState('NA');
    const [currentDeleteIndex, setCurrentDeleteIndex] = useState('NA');

    const [editData, setEditData] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [particularFieldValue, setParticularFieldValue] = useState(null);
    const [resetFormData, setResetFormData] = useState(0);
    const [isListLoading, setListLoading] = useState(false);
    const [selectedCreatedOnDate, setSelectedCreatedOnDate] = useState(null);
    const [poTypeModalObject, setPoTypeModalObject] = useState([]);


    /**
     * Tabular array of headers to display
     */
    const titleDD = [
        { id: 'warehouseName', name: 'WH Name' },
        { id: 'warehouseID', name: 'WH ID (Relay Site ID)' },
        { id: 'comdataLocID', name: 'Comdata Loc ID' },
        { id: 'terminalID', name: 'EFS/TCHEK (Terminal ID)' },
        { id: 'coords', name: 'Co-ordinates' },
        { id: 'createdOn', name: 'Created On' },
        { id: 'updatedOn', name: 'Updated On' },
        { id: '', name: AppConstant.superUserAdmin.warehouseAdmin.warehouseTable.actionsColTitle },
    ]

    /**
     * To handle API response types
     */
    useEffect(() => {
        if (operationObj.type) {
            switch (operationObj.type) {
                case 'Delete Warehouse':
                    if (operationObj.status) {
                        handleApiResponse(AppConstant.notifications.dataAddedSuccess, AppConstant.fsWarehouse.deleteSucess);
                    } else {
                        handleApiResponse(AppConstant.commonStrings.failure, AppConstant.fsWarehouse.deleteFail);
                    }
                    break;
                case 'Edit Warehouse':
                    if (operationObj.status) {
                        handleApiResponse(AppConstant.notifications.dataAddedSuccess, AppConstant.fsWarehouse.updateSucess);
                    } else {
                        handleApiResponse(AppConstant.commonStrings.failure, AppConstant.fsWarehouse.updateFail);
                    }
                    break;
                case 'Create Warehouse':
                    if (operationObj.status) {
                        handleApiResponse(AppConstant.notifications.dataAddedSuccess, AppConstant.fsWarehouse.insertSucess);
                    } else {
                        handleApiResponse(AppConstant.commonStrings.failure, AppConstant.fsWarehouse.insertFail);
                    }
                    break;
                case 'Fetch Warehouse':
                    if (!operationObj.status) {
                        setListLoading(false);
                        setFsWarehouseLoadArr([]);
                    }
                    break;
                default:
            }
        }
    }, [operationObj])


    /**
     * Helper to handle to the API response
     * @param {*} result 
     * @param {*} message 
     */
    const handleApiResponse = (result, message) => {
        setListLoading(false);
        setCurrentItemIndex('NA')
        setCurrentDeleteIndex('NA')
        if (result === AppConstant.notifications.dataAddedSuccess) {
            showAlert(toast.TYPE.SUCCESS, message, themeSelected);
            closeCurrentpopup();

            fetchData();

        } else {
            showAlert(toast.TYPE.ERROR, message, themeSelected);

        }
        setLoading(false);
    }

    /**
   * used to filter and set the data to list if the search string is greater than 3 characters
   */
    useEffect(() => {
        if (searchStr.length > 3) {
            fetchData();
        } else if (searchStr === '') {
            fetchData();
        }
    }, [searchStr])

    /**
     * Used to set the fs warehouse data to the state variable
     */
    useEffect(() => {
        if (getFsWarehouseData && getFsWarehouseData?.data?.Items?.length) {
            setFsWarehouseLoadArr(getFsWarehouseData)
        } else {
            setFsWarehouseLoadArr([])
        }

    }, [getFsWarehouseData?.data?.Items])

    const getReasonsList = () => formDropDownData(allConfigration, 'POType')

    /**
     * List of Items to show on the create and edit popup 
     */
    const formFieldList = [
        {
            name: "whName",
            type: "text",
            size: "col-sm-6",
            position: 1,
            placeholder: '',
            title: AppConstant.fsWarehouse.whName,
            formValidation: {
                required: true,
            },
            disabledField: Object.keys(editData).length ? true : false
        },
        {
            name: "whID",
            type: "text",
            size: "col-sm-6",
            position: 2,
            title: AppConstant.fsWarehouse.whId,
            formValidation: {
                required: true,
            },
            disabledField: Object.keys(editData).length ? true : false
        },
        {
            name: "city",
            type: "text",
            size: "col-sm-6",
            position: 3,
            title: AppConstant.fsWarehouse.city,
            formValidation: {
                required: true,
            },
            disabledField: Object.keys(editData).length ? true : false
        },
        {
            name: "state",
            type: "text",
            size: "col-sm-6",
            position: 4,
            title: AppConstant.fsWarehouse.state,
            formValidation: {
                required: true,
            },
            disabledField: Object.keys(editData).length ? true : false
        },
        {
            name: "street",
            type: "text",
            size: "col-sm-6",
            position: 5,
            title: AppConstant.fsWarehouse.street,
            formValidation: {
                required: true,
            },
            disabledField: Object.keys(editData).length ? true : false
        },
        {
            name: "zip",
            type: "text",
            size: "col-sm-6",
            position: 6,
            title: AppConstant.fsWarehouse.zip,
            formValidation: {
                required: true,
                allowOnlyNumber: true,
                lengthValidation: {
                    minLength: 1,
                    maxLength: 7,
                    customError: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.zipCodeErrorMessage
                },
            },
            disabledField: Object.keys(editData).length ? true : false
        },
        {
            name: "poLength",
            type: "text",
            size: "col-sm-6",
            position: 7,
            title: AppConstant.fsWarehouse.poLength,
            formValidation: {
                required: true,
            },
        },
        {
            name: "poType",
            type: "select",
            size: "col-sm-6",
            position: 8,
            title: AppConstant.fsWarehouse.poType,
            formValidation: {
                required: true,
            },
            getSelectData: getReasonsList
        },
        {
            name: "comdataLocationId",
            type: "text",
            size: "col-sm-6",
            position: 9,
            title: AppConstant.fsWarehouse.comdataLocationId,
            formValidation: {
                required: true,
            },
        },
        {
            name: "terminalId",
            type: "text",
            size: "col-sm-6",
            position: 10,
            title: AppConstant.fsWarehouse.terminalId,
            formValidation: {
                required: true,
            },
        },
        {
            name: "checkInTime",
            type: "text",
            size: "col-sm-6",
            position: 11,
            title: AppConstant.fsWarehouse.checkInTime,
            formValidation: {
                required: true,
                allowOnlyNumber: true,
                lengthValidation: {
                    minLength: 1,
                    customError: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.zipCodeErrorMessage
                },
            },
        },
        {
            name: "checkInRadiusEnabled",
            type: "radio",
            size: "col-sm-6",
            position: 12,
            title: AppConstant.fsWarehouse.checkInRadiusEnabled,
            formValidation: {
                required: true,
            },
            radiconfig: [{
                label: "True",
                value: true
            },
            {
                label: "False",
                value: false
            }]

        },
        {
            name: "checkInRadius",
            type: "text",
            size: "col-sm-6",
            position: 13,
            title: AppConstant.fsWarehouse.checkInRadius,
            formValidation: {
                required: true,
            },
        },
        
        {
            name: "preCheckInRadiusEnabled",
            type: "radio",
            size: "col-sm-6",
            position: 14,
            title: AppConstant.fsWarehouse.preCheckInRadiusEnabled,
            formValidation: {
                required: true,
            },
            radiconfig: [{
                label: "True",
                value: true
            },
            {
                label: "False",
                value: false
            }]

        },
        {
            name: "preCheckInRadius",
            type: "text",
            size: "col-sm-6",
            position: 15,
            title: AppConstant.fsWarehouse.preCheckInRadius,
            formValidation: {
                required: true,
            },
        },
        {
            name: "preCheckInTime",
            type: "text",
            size: "col-sm-6",
            position: 16,
            title: AppConstant.fsWarehouse.preCheckInTime,
            formValidation: {
                required: true,
                allowOnlyNumber: true,
                lengthValidation: {
                    minLength: 1,
                    customError: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.zipCodeErrorMessage
                },
            },
        },
        {
            name: "lat",
            type: "text",
            size: "col-sm-6",
            position: 17,
            title: AppConstant.fsWarehouse.lat,
            formValidation: {
                required: true,
            },
        },
        {
            name: "lng",
            type: "text",
            size: "col-sm-6",
            position: 18,
            title: AppConstant.fsWarehouse.lng,
            formValidation: {
                required: true,
            },
        },
    ]


    const getFormFieldData = () => {

    }

    /**
     * This function used to get FS warehouse list data by connecting with API
     */
    const fetchData = (pageNo = 1, limit = PaginationDefaultLimit) => {
        setTimeout(() => {
            const operationObj = {
                type: '',
                status: ''
            }

            const params = {
                "currentPage": pageNo,
                "itemsPerPage": limit,
                "searchParam": searchStr,
                "crudType": 'S'
            }

            operationObj.type = 'Fetch Warehouse'

            dispatch({ type: sagaActions.GET_FS_WAREHOUSE_DETAILS, payload: params, operationObj: operationObj });
        }, 0)
    }

    /**
     * Helper to delete
     * @param {*} data 
     * @param {*} index 
     */
    const deleteHandler = (data, index) => {
        setSelectedObj(data, index)
        setSelectedIndex(index)
        setShowDeleteModel(true)
    }

    /**
     * delete popup handler to delete the records based on the confirmation
     * @param {*} text 
     */
    const popupBtnHandler = (text) => {
        if (text === AppConstant.commonStrings.no) {
            setShowDeleteModel(false)
            setSelectedObj(null)
            setSelectedIndex(null)
        }
        else {
            setCurrentDeleteIndex(selectedIndex)
            const apiParams = {
                "warehouse_id": selectedObj.warehouse_id,
                "crudType": 'D'
            }

            const operationObj = {
                type: 'Delete Warehouse',
                status: ''
            }

            dispatch({ type: sagaActions.GET_FS_WAREHOUSE_DETAILS, payload: apiParams, operationObj: operationObj })
        }
        setShowDeleteModel(false)
    }

    /**
     * Helper to show the delete conformation pop-up
     * @returns 
     */
    const showDeleteConfirmation = () => {
        const popupObj = {
            id: 'showDeleteConfirmation',
            modalLabel: 'showDeleteConfirmation',
            showClose: true,
            showfooter: true,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title: AppConstant.fsWarehouse.deleteTimeslotTitle,
            bodyText: `${AppConstant.commonStrings.areYouSure} ${AppConstant.fsWarehouse.deleteTimeslotTitle}`,
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.commonStrings.yes
                },
                btn2: {
                    show: true,
                    text: AppConstant.commonStrings.no
                }
            },
        }

        return <Popup {...popupObj} popupBtnHandler={(text) => { popupBtnHandler(text) }} closepopup={() => { setShowDeleteModel(false) }} themeSelected={themeSelected} showModal={showDeleteModel} />
    }

    /**
     * Helper function to get the submited data and forms it to create or update
     * @param {*} data 
     */
    const getSummitedFormData = (data) => {
        setLoading(true);
        let apiParams = {}
        const operationObj = {
            type: '',
            status: ''
        }

        // if (data.checkInRadiusEnabled) {
        //     if (!data.checkInRadius) {
        //         showAlert(toast.TYPE.WARNING, 'Check in radius field is mandatory', themeSelected);
        //         setLoading(false);

        //         return
        //     }
        // }
        // if (data.preCheckInRadiusEnabled) {
        //     if (!data.preCheckInRadius) {
        //         showAlert(toast.TYPE.WARNING, 'Pre-Check in radius field is mandatory', themeSelected);
        //         setLoading(false);
                
        //         return
        //     }
        // }
        if (Object.keys(editData).length) {
            const apiTempParams1 = {
                "warehouse_id": data.whID,
                "address": {
                    "city": data.city,
                    "state": data.state,
                    "street": data.street,
                    "zip": data.zip
                },
                "checkInRadius": data.checkInRadius,
                "checkInRadiusEnabled": data.checkInRadiusEnabled,
                "checkInTime": convertHrOrMinToMiliSeconds(data.checkInTime,'MINUTES'),
                "comdata_location_id": data.comdataLocationId,
                "createdOn": selectedCreatedOnDate ? selectedCreatedOnDate.trim() : null,
                "coords": {
                    "lat": data.lat,
                    "lng": data.lng
                },
                "name": data.whName,
                "po_validation": {
                    "length": data.poLength,
                    "type": data.poType
                },
                "preCheckInRadius": data.preCheckInRadius,
                "preCheckInRadiusEnabled": data.preCheckInRadiusEnabled,
                "preCheckInTime": convertHrOrMinToMiliSeconds(data.preCheckInTime,'HOUR'),
                "terminal_id": data.terminalId
            }
            operationObj.type = 'Edit Warehouse'

            apiParams = {
                "crudType": 'U',
                "data": apiTempParams1
            }
        } else {
            const apiTempParams2 = {
                "warehouse_id": data.whID,
                "address": {
                    "city": data.city,
                    "state": data.state,
                    "street": data.street,
                    "zip": data.zip
                },
                "checkInRadius": data.checkInRadius,
                "checkInRadiusEnabled": data.checkInRadiusEnabled,
                "checkInTime": convertHrOrMinToMiliSeconds(data.checkInTime,'MINUTES'),
                "comdata_location_id": data.comdataLocationId,
                "coords": {
                    "lat": data.lat,
                    "lng": data.lng
                },
                "name": data.whName,
                "po_validation": {
                    "length": data.poLength,
                    "type": data.poType
                },
                "preCheckInRadius": data.preCheckInRadius,
                "preCheckInRadiusEnabled": data.preCheckInRadiusEnabled,
                "preCheckInTime": convertHrOrMinToMiliSeconds(data.preCheckInTime,'HOUR'),
                "terminal_id": data.terminalId
            }

            operationObj.type = 'Create Warehouse'

            apiParams = {
                "crudType": 'I',
                "data": apiTempParams2
            }
        }
        dispatch({ type: sagaActions.GET_FS_WAREHOUSE_DETAILS, payload: apiParams, operationObj: operationObj })
    };

    
    const closeCurrentpopup = () => {
        setShowModal(false);
    }

    /**
     * Helper function to show to popup to create the warehouse
     * @returns 
     */
    const showCreatePOPopup = () => {
        const popupObj = {
            id: 'createFsWarehouseFormPopup',
            modalLabel: 'createFsWarehouseFormPopup',
            showClose: true,
            showfooter: false,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'xl',
            title: !Object.keys(editData).length ? AppConstant.fsWarehouse.createFsWarehouse : AppConstant.fsWarehouse.editFsWarehouse,
            bodyText: createForm(),
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.comments.cancelCommentButtonTitle
                },
                btn2: {
                    show: true,
                    text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.saveButtonTitle
                }
            },
        }

        return <Popup {...popupObj} popupBtnHandler={(text) => () => ''} closepopup={closeCurrentpopup} themeSelected={themeSelected} showModal={showModal} />
    }

    const openCreateForm = () => {
        setShowModal(true);
        setEditData({});
    }
    const cancelbtndata = {
        show: true,
        text: AppConstant.comments.resetbutton,
    };
    const submitbtnData = {
        show: true,
        text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm
            .saveButtonTitle,
    };

    /**
     * function to create form
     * @returns 
     */
    const createForm = () => {
        return (
            <CustomDynamicForm
                formFieldList={formFieldList}
                sort={true}
                // cancelBtn={cancelbtndata}
                submitbtn={submitbtnData}
                themeSelected={themeSelected}
                getSummitedFormData={getSummitedFormData}
                getFormFieldData={getFormFieldData}
                editFormData={editData}
                loading={loading}
                resetFormData={resetFormData}
                setParticularFieldValue={particularFieldValue}
            />
        );
    };

    /**
     * Helper to get the data and populate on the screen while editing
     * @param {*} data 
     */
    const editClicked = (data) => {
        let editData1 = {
            "whID": data?.warehouse_id,
            "city": data?.address?.city,
            "state": data?.address?.state,
            "street": data?.address?.street,
            "zip": data?.address?.zip,
            "checkInRadius": data?.checkInRadius,
            "checkInRadiusEnabled": data?.checkInRadiusEnabled,
            "checkInTime": convertMiliSecondsToHrOrMin(data?.checkInTime, 'MINUTES'),
            "comdataLocationId": data?.comdata_location_id,
            "lat": data?.coords?.lat,
            "lng": data?.coords?.lng,
            "whName": data?.name,
            "poLength": data?.po_validation?.length,
            "poType": data?.po_validation?.type,
            "preCheckInRadius": data?.preCheckInRadius,
            "preCheckInRadiusEnabled": data?.preCheckInRadiusEnabled,
            "preCheckInTime": convertMiliSecondsToHrOrMin(data?.preCheckInTime, 'HOUR'),
            "terminalId": data?.terminal_id
        }
        setSelectedCreatedOnDate(data.createdOn);
        setEditData(editData1);
        setShowModal(true);
    }

    
    /**
     * Function to create action buttons
     * @param {*} groupObj 
     * @param {*} index 
     * @returns 
     */
    const actionsButton = (groupObj, index) => {
        return (
            <div>
                <span className='d-flex justify-content-center align-items-center gap-2' >
                    <button
                        className={'addButton'}
                        data-bs-toggle="modal"
                        data-bs-target={`#createFsWarehouseFormPopup`}
                        onClick={() => editClicked(groupObj)}
                    >
                        {AppConstant.commonStrings.edit}
                    </button>
                    {/* <CustomButtonSS
                        disabled={false}
                        isLoading={currentDeleteIndex !== 'NA' && currentDeleteIndex === index ? true : false}
                        className={`no-border no-bg`}
                        onClick={() => { deleteHandler(groupObj, index) }}
                        isIcon={true}
                        iconClass={"fa-regular fa-trash-can fa-lg"}
                    /> */}
                </span>
            </div>
        )
    }

    /**
     * function to show the formated list data on the screen
     * @param {*} data 
     * @returns 
     */
    const formatListDataDrillDown = (data) => {

        return data.map((el, index) => {
            return {
                0: el.name || '-',
                1: el.warehouse_id || '-',
                2: el.comdata_location_id || '-',
                3: el.terminal_id || '-',
                4: el.coords ? `lat: ${el.coords.lat} \n lng: ${el.coords.lng}` : '-',
                5: el.createdOn ? `${moment(el.createdOn).format(AppConstant.commonStrings.MM_DD_YYYY_dateFormat)}\n${moment(el.createdOn).format(AppConstant.commonStrings.HH_mm_dateFormat)}` : AppConstant.commonStrings.hypen,
                6: el.updatedOn ? `${moment(el.updatedOn).format(AppConstant.commonStrings.MM_DD_YYYY_dateFormat)}\n${moment(el.updatedOn).format(AppConstant.commonStrings.HH_mm_dateFormat)}` : AppConstant.commonStrings.hypen,
                7: actionsButton(el, index),
            }
        })
    }

    /**
     * This function is used to get the data and validate
     * @returns 
     */
    const getBodyData = () => {
        if (fsWarehouseLoadArr && fsWarehouseLoadArr?.data?.Items?.length) {
            return formatListDataDrillDown(fsWarehouseLoadArr?.data?.Items)
        }
        else {
            return []
        }
    }

    /**
     * Function used when page is changed in pagination then it fetches user rating deails
     * @param {*} pageNo 
     * @param {*} limit 
     */
    const pageChange = (pageNo, limit) => {
        fetchData(pageNo, limit)
    }

    return (
        <div className=''>
            <div className='d-flex justify-content-between my-2 mx-3'>
                {/* <button
                    className={'addButton'}
                    data-bs-toggle="modal"
                    onClick={() => openCreateForm()}
                >
                    {AppConstant.fsWarehouse.addFsWarehouseTitle}
                </button> */}
                <div className='fw-bold'>{AppConstant.fsWarehouse.ttwarehouse}</div>
                <div className='d-flex justify-content-end align-items-end'>
                    <div className='ms-2 w-100'>
                        <WarehouseSearch
                            placeholder={AppConstant.poManagement.searchPOSection.searchTitleWarehouseStr}
                            inputValue={searchStr}
                            cancelSearch={() => setSearchStr('')}
                            onChangeHandler={(text) => setSearchStr(text)} />
                    </div>

                </div>

            </div>
            <div className='fswarehouseContainer' ref={divRef}>
                <DrilldownTableSS
                    key={Math.random()}
                    themeSelected={themeSelected}
                    bodyData={getBodyData()}
                    titleData={titleDD}
                    showCollapse={false}
                    showInput={false}
                    showSort={true}
                    hasCollapsableContent={false}
                    collapsableData={[]}
                    accordionPrimaryIndex={1}
                    drillDownReport={fsWarehouseLoadArr?.data?.Items}
                    initiateDrillDownReport={isLoading}
                    loaderArray={8}
                    sortingAction={sagaActions.SORT_SS_USER_TABLE}
                    subRoot={{ pathName: 'FsWarehouseObj' }}
                    showUTCTime={true}
                />
                {showDeleteConfirmation()}

            </div>
            {showCreatePOPopup()}

            {!_.isEmpty(fsWarehouseLoadArr) && fsWarehouseLoadArr?.data?.Items?.length ?
                <div className='f-14'>
                    <CustomPaginationSS
                        pageNo={fsWarehouseLoadArr.data.currentPage}
                        pageSize={fsWarehouseLoadArr.data.itemsPerPage}
                        first={(Number(fsWarehouseLoadArr.data.currentPage) === 1) ? true : false}
                        last={Math.ceil(fsWarehouseLoadArr.data.totalCount / Number(fsWarehouseLoadArr.data.itemsPerPage)) === (Number(fsWarehouseLoadArr.data.currentPage)) ? true : false}
                        totalElements={fsWarehouseLoadArr.data.totalCount}
                        totalPages={fsWarehouseLoadArr.data.totalPages}
                        pageChange={(pageNo, limit) => { pageChange(pageNo, limit) }}
                    />
                </div> : ""
            }

        </div>
    )
}

export default FsWarehouse