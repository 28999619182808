/*
 * Copyright © 2022-Present Universal Chain All rights reserved.
 * Author: Adarsh Yadav
 */

import React, { Component } from 'react'
import CustomButtonSS from '../Common/CustomButton/CustomButtonSS';
import { AppConstant } from '../../Assests/AppConstant';
import { sagaActions } from '../../../sagas/sagaActions';
// This components renders error page in case of error
class ErrorBoundary extends Component {
    // Temporary Image has been added to encounter error. This will be changed when design team provides
    // mock ups for the same.

    constructor(props) {
        super(props)

        this.state = {
            hasError: false,
        }
        
    }


    componentWillUpdate(p,c){
        if (c.hasError) {
            setTimeout(() => {
                this.goToLogin()
            }, 5000);
        }
    }
    
    static getDerivedStateFromError(error) {
        return {
            hasError: true
        }
    }
    
     goToLogin() {
        this.props.dispatch({type: sagaActions.CLEAR_SMART_SCHEDULE_REDUCER_DATA});
        this.props.dispatch({ type: sagaActions.CLEAR_SIGNEDIN_USER })
        this.props.dispatch({ type: sagaActions.RESET_USER })
        this.props.removeInterceptor();
        window.localStorage.clear();
        // Navigate to login page when the button is clicked
       this.props.navigate('/');
       if (window.location.pathname === '/') {
        this.props.dispatch({ type: sagaActions.INITIATE_BRANDING_LOADER, payload: true })
        const subdomain = window.location.hostname.split(".")[0];
        this.props.dispatch({
            type: sagaActions.INITIATE_BRANDING, payload: {
                subdomain: subdomain
            }
        });
    }
    window.location.reload();
    }

    render() {

        if (this.state.hasError) {
            return (
                <React.Fragment>
            <div style={{
                minHeight: '100vh',
                minWidth: '1vw'
            }}>
                <img src="/errorpageimage.jpg" alt="Error occured" className='my-2' style={{
                    display: "block",
                      margin: "auto",
                      height: '500px',
                    //   width: '500px',
                    minHeight: "100%",
                }} />
                <div className='d-flex flex-column justify-content-center align-items-center my-3 f-16'>
                      <span>Please wait, We are redirecting you to the login page...</span>
                      <div className='d-flex flex-column justify-content-center align-items-center'>
                          <span className='me-2'>Or click to go to</span>
                          <CustomButtonSS
                                    isLoading={false}
                                    title={AppConstant.login.loginButtonTitle} className={"btn-submit mt-1"}
                                    onClick={() => {
                                       this.goToLogin()
                                    }}
                                />
                      </div>
                </div>
            </div>
        </React.Fragment>
            )
        }
        return this.props.children
    }
}

export default ErrorBoundary;
